<template>
  <Modal
    v-if="visible"
    :title="getDictionaryEntry('ProductSheet.CatalogueOrder')"
    :visible="visible"
    @close="handleClose"
  >
    <template #body>
      <draggable
        v-model="internalBasketLines"
        class="basketLine__entries"
        tag="ul"
        v-bind="dragOptions"
        @start="handleDragStart"
        @end="handleDragEnd"
        @change="handleDragChange"
      >
        <li
          v-for="basketLine in internalBasketLines"
          :key="basketLine.variantId"
          class="basketLine__entry"
        >
          <div
            v-if="variants[basketLine.variantId]"
            class="basketLine"
            :data-variant="basketLine.variantId"
          >
            <div class="basketLine__icon">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M0 96c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm0 160c0-17.7 14.3-32 32-32h384c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zm448 160c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32h384c17.7 0 32 14.3 32 32z"
                />
              </svg>
            </div>

            <div class="basketLine__information">
              <div class="basketLine__text">
                <Stack
                  v-if="
                    variants[basketLine.variantId].variantName ||
                      variants[basketLine.variantId].variantId
                  "
                  :quarter="true"
                >
                  <span
                    v-if="variants[basketLine.variantId].variantName"
                    class="basketLine__product-info"
                  >
                    {{ basketLine.variantId }}
                  </span>
                  <span
                    v-if="variants[basketLine.variantId].variantName"
                    class="basketLine__product-info"
                  >
                    - {{ variants[basketLine.variantId].variantName }}
                  </span>
                </Stack>
                <Stack
                  v-if="variants[basketLine.variantId].productName"
                  :quarter="true"
                >
                  <h1 class="basketLine__title">
                    {{ variants[basketLine.variantId].productName }}
                  </h1>
                </Stack>
              </div>
              <div
                v-if="doesVariantImageExist(variants[basketLine.variantId])"
                class="basketLine__image-container"
              >
                <ProductImage
                  :variant="variants[basketLine.variantId]"
                  class="basketLine__image"
                />
              </div>
            </div>
          </div>
        </li>
      </draggable>
    </template>
    <template #footer>
      <Button
        :limited-width="true"
        :show-loader="isSaving"
        @click="handleSortCatalogue"
      >
        Gem rækkefølge
      </Button>
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Modal from "@/components/Modal";
import draggable from "vuedraggable";
import ProductImage from "@/components/ProductImage";
import Stack from "@/components/Stack";
import Button from "@/components/Button";

export default {
  name: "CatalogueSortingModal",
  components: {
    Modal,
    draggable,
    ProductImage,
    Stack,
    Button,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    handleClose: {
      default: () => {},
      type: Function,
    },
  },
  data() {
    return {
      internalBasketLines: [],
      isSaving: false,
    };
  },
  computed: {
    ...mapGetters("catalogues", ["currentCatalogue"]),
    ...mapGetters("products", ["variantsForProductSheetByIds"]),
    dragOptions() {
      return {
        animation: 200,
        forceFallback: true,
        ghostClass: "basketLine--ghost",
        scrollSensitivity: 200,
      };
    },
    variants() {
      const { internalBasketLines, variantsForProductSheetByIds } = this;

      if (!internalBasketLines || !internalBasketLines.length) {
        return [];
      }

      const variantsForProductSheet = variantsForProductSheetByIds(
        internalBasketLines.map(basketLine => basketLine.variantId),
      );

      const variantDictionary = {};

      variantsForProductSheet.forEach(variant => {
        variantDictionary[variant.variantId] = variant;
      });

      return variantDictionary;
    },
  },
  watch: {
    visible(newVal) {
      if (newVal)
        return (this.internalBasketLines = [
          ...this.currentCatalogue?.basketLines,
        ]);
      else this.internalBasketLines = [];
    },
  },
  methods: {
    ...mapActions("catalogues", ["SORT_CATALOGUE"]),
    handleDragEnd() {
      const className = "grabbing";
      const html = document.querySelector("html");
      if (html && new RegExp(className).test(html.className) === true) {
        html.className = html.className.replace(
          new RegExp(" " + className),
          "",
        );
        html.className = html.className.replace(new RegExp(className), "");
      }
    },
    handleDragStart() {
      const className = "grabbing";
      const html = document.querySelector("html");
      if (html && new RegExp(className).test(html.className) === false) {
        html.className += " " + className;
      }
    },
    handleSortCatalogue() {
      this.isSaving = true;
      const basketLines = this.internalBasketLines.map(
        (basketLine, orderNum) => {
          const { variantId } = basketLine;
          return { variantId, orderNum };
        },
      );

      this.SORT_CATALOGUE({ basketLines }).then(() => {
        this.isSaving = false;
        this.handleClose();
      });
    },
    handleDragChange({ moved: { element } }) {
      const { variantId } = element;
      const movedElement = document.querySelector(
        `[data-variant="${variantId}"]`,
      );

      if (movedElement.classList.contains("basketLine--moved"))
        movedElement.classList.remove("basketLine--moved");

      movedElement.classList.add("basketLine--moved");
      setTimeout(() => {
        movedElement.classList.remove("basketLine--moved");
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
.grabbing * {
  cursor: grabbing !important;
}

.basketLine {
  align-items: center;
  border: 1px solid #ddd;
  background-color: #fff;
  cursor: grab;
  display: flex;
  justify-content: space-between;
  padding: calc(var(--spacing-stack) * 0.3);
  will-change: transform, background-color;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;

  &--moved {
    animation: moved forwards 1000ms;
  }

  &:active {
    cursor: grabbing;
  }

  &--ghost {
    visibility: hidden;
    opacity: 0;
  }

  &__entries {
    @include reset-list();
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }

  &__product-info {
    font-size: 1rem;
  }

  &__image-container {
    height: 3.125rem;
    position: relative;
  }

  &__information {
    display: flex;
    gap: 0.5rem;
  }

  &__icon {
    width: 1.4375rem;
    height: 1.4375rem;
    display: flex;
    margin: 0 calc(var(--spacing-stack) * 0.5);
  }

  &__text {
    display: flex;
    flex-direction: column;
    text-align: right;
    justify-content: center;
  }

  &__image {
    display: block;
    height: 100%;
    object-fit: contain;
    width: 100%;
  }

  &__title {
    font-size: 1rem;
    margin: 0;
  }
}

@keyframes moved {
  from {
    background-color: var(--color-import-status-finished);
  }

  to {
    background-color: #fff;
  }
}
</style>
