var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"care-instructions"},[_c('Stack',[(
        _vm.instructions.washCareEntriesWithoutSymbols &&
          _vm.instructions.washCareEntriesWithoutSymbols.length
      )?_c('ul',{staticClass:"care-instructions__list"},_vm._l((_vm.instructions.washCareEntriesWithoutSymbols),function(washCare){return _c('li',{key:washCare,staticClass:"care-instructions__item"},[_vm._v(" "+_vm._s(washCare)+" ")])}),0):_vm._e()]),_c('Stack',[(
        _vm.instructions.washCareEntriesWithSymbols &&
          _vm.instructions.washCareEntriesWithSymbols.length
      )?_c('ul',{staticClass:"care-instructions__list care-instructions__list--icons"},_vm._l((_vm.instructions.washCareEntriesWithSymbols),function(washCare){return _c('li',{key:washCare.id,staticClass:"care-instructions__item care-instructions__item--icon"},[_c('i',{staticClass:"care-instructions__icon"},[_vm._v(" "+_vm._s(washCare.font)+" ")]),_vm._v(" "+_vm._s(washCare.description)+" ")])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }