<template>
  <FocusTrap v-model="isOpen" :escape-deactivates="false">
    <transition
      name="fade"
      appear=""
      @enter="handleEnter"
      @after-leave="$emit('close')"
    >
      <div v-if="isOpen" class="modal" role="dialog">
        <transition name="fade-scale-subtle" @leave="isOpen = false">
          <div v-if="isBoxVisible" class="modal__box">
            <div
              class="modal__header"
              :class="{ 'modal__header--has-scrollbar': hasScrollbar }"
            >
              <div class="modal__header-block">
                <h2 class="modal__header-title">{{ title }}</h2>
                <button
                  v-if="!required"
                  class="modal__header-button"
                  :aria-label="getDictionaryEntry('Common.Close')"
                  @click="handleClose"
                >
                  <svg
                    v-if="arrowAsCloseIcon"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    class="modal__header-icon"
                  >
                    <path d="M19 12H5M12 19l-7-7 7-7" />
                  </svg>
                  <svg
                    v-else
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 21 21"
                    class="modal__header-icon"
                  >
                    <defs />
                    <g>
                      <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
                    </g>
                  </svg>
                </button>
              </div>
              <div v-if="hasHeaderSlot" class="modal__header-slot">
                <slot name="header" />
              </div>
            </div>
            <div
              ref="content"
              class="modal__body"
              :class="{ 'modal__body--selection': selection }"
            >
              <slot name="body" />
            </div>
            <div
              v-if="hasFooterSlot"
              class="modal__footer"
              :class="{ 'modal__footer--has-scrollbar': hasScrollbar }"
            >
              <slot name="footer" />
            </div>
          </div>
        </transition>
        <div
          class="modal__underlay"
          v-on="!required ? { click: () => handleClose() } : {}"
        ></div>
      </div>
    </transition>
  </FocusTrap>
</template>

<script>
import { FocusTrap } from "focus-trap-vue";

export default {
  name: "Modal",
  components: {
    FocusTrap,
  },
  props: {
    arrowAsCloseIcon: {
      default: false,
      type: Boolean,
    },
    closeFromParent: {
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      type: Boolean,
    },
    selection: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      hasScrollbar: false,
      isBoxVisible: false,
      isOpen: false,
      observer: null,
    };
  },
  computed: {
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
  watch: {
    closeFromParent(newValue) {
      if (newValue) {
        this.handleClose();
      }
    },
    visible(newValue) {
      this.isOpen = newValue;
    },
  },
  created() {
    if (!window.modals) window.modals = [];

    const componentId = this._uid;
    window.modals.push(componentId);

    this.isOpen = this.visible;

    if (this.isOpen) this.handleBackgroundScrolling(true);

    if (this.required) return;

    const handleEscape = event => {
      if (
        this.isOpen &&
        event.keyCode === 27 &&
        window.modals[window.modals.length - 1] === componentId
      ) {
        this.handleClose();
      }
    };

    document.addEventListener("keydown", handleEscape);

    this.$once("hook:destroyed", () => {
      this.handleBackgroundScrolling();
      document.removeEventListener("keydown", handleEscape);
    });
  },
  methods: {
    handleClose() {
      this.observer.unobserve(this.$refs.content);
      this.isBoxVisible = false;

      const componentId = this._uid;
      window.modals = window.modals.filter(modal => modal !== componentId);
    },
    handleEnter() {
      this.isBoxVisible = true;

      const animationDuration = parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--transition-fade",
        ) +
          parseInt(
            getComputedStyle(document.documentElement).getPropertyValue(
              "--transition-fade-scale-subtle",
            ),
          ),
      );

      window.setTimeout(() => {
        const {
          $refs: { content },
        } = this;

        this.observer = new ResizeObserver(() => {
          if (content.scrollHeight > content.clientHeight) {
            this.hasScrollbar = true;
          } else {
            this.hasScrollbar = false;
          }
        });

        this.observer.observe(content);
      }, animationDuration);
    },

    handleBackgroundScrolling(disableScroll = false) {
      const scrollableElements = document.querySelectorAll("body");
      scrollableElements.forEach(el =>
        disableScroll
          ? el.classList.add("modal-open")
          : el.classList.remove("modal-open"),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  @include reset-list();
  align-items: center;
  bottom: 0;
  display: flex;
  justify-content: center;
  left: 0;
  min-height: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  will-change: transform;
  z-index: 99;

  .has-service-bar .product-details & {
    @media (min-width: 64rem) {
      top: calc(var(--size-service-bar) + var(--size-header-fixed));
    }
  }

  .product-details & {
    z-index: 95;

    @media (min-width: 64rem) {
      top: var(--size-header-fixed);
    }
  }

  &__body {
    -webkit-overflow-scrolling: touch;
    flex: 1 1 auto;
    overflow-x: hidden;
    overflow-y: auto;
    padding: var(--spacing-modal);
    position: relative;
    z-index: 1;

    @media (min-width: 48rem) {
      padding: var(--spacing-modal-large);
    }

    .product-details & {
      overscroll-behavior: contain;
      padding: 0 0 calc(var(--spacing-stack) * 2);
    }

    .product-details--no-scroll & {
      @media (min-width: 64rem) {
        overflow: visible;
      }
    }

    .products__filter--mobile & {
      z-index: 2;
    }

    .saved-adresses & {
      padding-bottom: 0;
      padding-top: 0;
    }

    .wardrobe-product & {
      padding-top: 0;
    }

    &--selection {
      padding: 0;
    }

    img {
      position: relative;
      z-index: -1;
    }
  }

  &__box {
    background-color: var(--color-modal-background);
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    min-height: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    @media (min-width: 64rem) {
      border-radius: 0.25rem;
      bottom: auto;
      left: auto;
      max-height: 100vh;
      position: relative;
      right: auto;
      top: auto;
      width: 50rem;
    }

    &:focus {
      outline: none;
    }

    .product-details & {
      @media (min-width: 64rem) {
        border-radius: 0;
        height: calc(100vh - var(--size-header-fixed));
        max-height: none;
        max-width: none;
        position: absolute;
        top: 0;
        width: 100%;
      }
    }

    .quick-buy & {
      @media (min-width: 48rem) {
        max-height: 100vh;
      }

      @media (min-width: 64rem) {
        max-width: var(--width-container);
        width: calc(100% - var(--spacing-main-content));
      }
    }

    .saved-exports & {
      @media (min-width: 64rem) {
        min-height: 50vh;
      }
    }

    .scheduled-export & {
      @media (min-width: 64rem) {
        min-height: 75vh;
      }
    }
  }

  &__footer {
    align-items: center;
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    display: flex;
    flex: 0 0 auto;
    justify-content: flex-end;
    padding: var(--spacing-modal);
    position: relative;
    text-transform: lowercase;
    z-index: 2;

    @media (min-width: 48rem) {
      padding: var(--spacing-modal-large);
    }

    .addresses &,
    .saved-exports &,
    .shop-as & {
      justify-content: space-between;
    }

    .products__filter--mobile & {
      z-index: 1;
    }

    .quick-buy & {
      @media (min-width: 48rem) {
        justify-content: space-between;
      }
    }

    &--has-scrollbar {
      box-shadow: 0px -2px 6px rgba(0, 0, 0, 0.1);
      z-index: 999;
    }
  }

  &__header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    padding: var(--spacing-modal);

    @media (min-width: 48rem) {
      padding: var(--spacing-modal-large);
    }

    .product-details & {
      @media (min-width: 64rem) {
        display: none;
      }
    }

    .products__filter--mobile & {
      position: relative;
      z-index: 3;
    }

    &--has-scrollbar {
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
      z-index: 3;
    }

    &-block {
      align-items: center;
      display: flex;
      flex: 0 0 auto;
      justify-content: space-between;
      position: relative;
    }

    &-button {
      @include reset-button();
    }

    &-button,
    &-icon {
      height: var(--size-modal-icon);
      width: var(--size-modal-icon);

      @media (min-width: 64rem) {
        height: var(--size-modal-icon-large);
        width: var(--size-modal-icon-large);
      }
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
    }

    &-title {
      font-size: 1.25em;
      margin: 0 var(--size-modal-icon) 0 0;

      @media (min-width: 64rem) {
        font-size: 1.5em;
      }
    }
  }

  &__underlay {
    background-color: rgba(0, 0, 0, 0.25);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
