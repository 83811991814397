<template>
  <div class="matrix">
    <div
      v-if="hasBlurEffect && hasHorizontalScroll && !isScrolledToRight"
      class="matrix__blur"
    ></div>
    <div ref="fixedCol" class="matrix__fixed-col">
      <div class="matrix__cell matrix__cell-header">
        <p>
          {{ getDictionaryEntry("Common.Labels.Size") }}
        </p>
      </div>
      <div class="matrix__cell matrix__cell--quantity">
        <p>
          {{ getDictionaryEntry("Common.Labels.Quantity") }}
        </p>
      </div>
      <div class="matrix__cell">
        <p>{{ getDictionaryEntry("Common.Labels.Price") }}</p>
      </div>
      <div v-if="hasRetailPrice" class="matrix__cell">
        <p>
          {{
            shouldShowPricesWithVat
              ? getDictionaryEntry("Common.Labels.ApproximatePriceWithVat")
              : getDictionaryEntry("Common.Labels.ApproximatePriceWithoutVat")
          }}
        </p>
      </div>

      <div class="matrix__cell">
        <p>{{ getDictionaryEntry("Common.Labels.Stock") }}</p>
      </div>
    </div>

    <div
      ref="scrollableElement"
      class="matrix__content"
      :style="{
        marginLeft: `${fixedColSize}px`,
      }"
      @scroll="handleScroll"
    >
      <div
        v-for="(size, index) in selectedSizeGroup?.sizes"
        :key="`size-${index}-${size.sku}`"
        class="matrix__col"
      >
        <div
          class="matrix__cell matrix__cell-header matrix__cell-header--content"
        >
          <p>
            {{ getPreferredSizeChartValue(size) }}
          </p>
        </div>
        <div class="matrix__cell matrix__cell--quantity">
          <div class="matrix__content__input-container">
            <input
              class="matrix__content__input-container--input"
              min="0"
              placeholder="0"
              type="number"
              :class="{
                'matrix__content__input-container--input--in-stock': checkInStock(
                  index,
                ),
                'matrix__content__input-container--input--saving': isUpdatingBasket,
              }"
              :value="size.quantity"
              @input="updateSizeQuantity(index, $event)"
            />

            <div
              v-if="isUpdatingBasket && updatedSizeIndex === index"
              class="matrix__content__input-container--input-loader"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="10"
                viewBox="0 0 10 10"
                fill="none"
              >
                <path
                  d="M2.66895 2.00617C3.26026 1.59219 3.95752 1.35572 4.67868 1.3246C5.39983 1.29348 6.11488 1.46899 6.73966 1.83049C7.60097 2.32777 8.22947 3.14684 8.48688 4.10751C8.74429 5.06819 8.60953 6.09177 8.11225 6.95309C7.61497 7.8144 6.7959 8.4429 5.83523 8.70031C4.87456 8.95772 3.85097 8.82296 2.98966 8.32568C2.3642 7.96535 1.85468 7.43387 1.52105 6.79377C1.18743 6.15367 1.04358 5.43159 1.10645 4.7125"
                  stroke="white"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
        </div>
        <div class="matrix__cell ">
          <div
            v-if="isPriceFromSizeWithDiscount(size)"
            class="matrix__cell__price-container"
          >
            <span class="matrix__cell--previous-price">
              {{ getNormalPriceFromSize(size) }}
            </span>

            <span class="matrix__cell--new-price">
              {{ getPriceFromSize(size) }}
            </span>
          </div>
          <p v-else>
            {{ getPriceFromSize(size) }}
          </p>
        </div>

        <div v-if="hasRetailPrice" class="matrix__cell">
          <p>
            {{ getRetailPriceFromSize(size) }}
          </p>
        </div>
        <div
          class="matrix__cell matrix__cell--stock"
          :class="{
            'matrix__cell--stock-info':
              shouldShowRestockDate(size.inventory) ||
              shouldShowMessage(size.inventory),
          }"
        >
          <p v-if="size.inventory">
            <StockIndicatorBar :status="getStockStatus(size.inventory.level)" />
            <template
              v-if="size.inventory.showStock && size.inventory.stock > 0"
            >
              {{ size.inventory.stock || 0 }}
            </template>
            <template v-else-if="shouldShowRestockDate(size.inventory)">
              {{ size.inventory.restockDate }}
            </template>
            <template v-else-if="shouldShowMessage(size.inventory)">
              {{ getDictionaryEntry(size.inventory.stockMessage) }}
            </template>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StockIndicatorBar from "@/components/StockIndicatorBar.vue";
import { stockLevels } from "@/helpers/stockLevels";
import { mapGetters } from "vuex";
import {
  checkIfIsScrolledToRight,
  hasHorizontalScroll as hasHorizontalScrollFn,
} from "@/helpers/scroll";

export default {
  name: "ScrollableSizesMatrix",
  components: { StockIndicatorBar },
  props: {
    currentVariant: {
      required: true,
      type: Object,
    },
    activeSizeGroupIndex: {
      default: 0,
      required: true,
      type: Number,
    },
    isUpdatingBasket: {
      default: false,
      type: Boolean,
    },
    initialInternalSizeGroups: {
      required: true,
      type: Array,
    },
    internalSizeGroups: {
      required: true,
      type: Array,
    },
    updatedSizeIndex: {
      default: 0,
      type: Number,
    },
    hasBlurEffect: {
      default: true,
      type: Boolean,
    },
  },
  data() {
    return {
      fixedColSize: 0,
      isScrolledToRight: false,
      hasHorizontalScroll: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["divisionCode"]),
    shouldShowPricesWithVat() {
      return !(!this.divisionCode || this.divisionCode.toLowerCase() === "fe");
    },
    hasRetailPrice() {
      return !!this.currentVariant?.minPrice?.retailPriceValue;
    },
    selectedSizeGroup() {
      return this.internalSizeGroups[this.activeSizeGroupIndex];
    },
  },
  mounted() {
    this.updateSizes();
    this.handleScroll();
  },
  methods: {
    updateSizes() {
      this.$nextTick(() => {
        if (this.$refs.fixedCol) {
          this.fixedColSize = this.$refs.fixedCol.offsetWidth;
        }
      });
    },
    getSelectedSize(index) {
      const initialSize = this.initialInternalSizeGroups[
        this.activeSizeGroupIndex
      ].sizes[index];
      const currentQuantity = this.internalSizeGroups[this.activeSizeGroupIndex]
        .sizes[index].quantity;
      const initialQuantity = +initialSize.quantity || 0;

      const stock = initialSize.inventory.stock;

      return {
        initialSize,
        currentQuantity,
        initialQuantity,
        stock,
      };
    },
    checkInStock(index) {
      const { initialSize, currentQuantity, stock } = this.getSelectedSize(
        index,
      );

      if (!currentQuantity || !initialSize.inventory.stock) {
        return false;
      }

      return currentQuantity <= stock;
    },
    getStockStatus(level) {
      switch (level) {
        case stockLevels.GREY:
        case stockLevels.ORANGE:
        case stockLevels.RED:
          return level;
        default:
          return stockLevels.GREEN;
      }
    },
    shouldShowRestockDate(inventory) {
      if (!inventory) return false;

      return !!inventory.restockDate;
    },
    shouldShowMessage(inventory) {
      if (!inventory) return false;

      return !this.shouldShowRestockDate() && !!inventory.stockMessage;
    },
    updateSizeQuantity(index, event) {
      const quantity = parseInt(event.target.value);
      const size = this.selectedSizeGroup.sizes[index];

      if (quantity < 0) {
        event.target.value = 0;
        return;
      }

      if (quantity === size.quantity) return;

      this.$emit("update:sizeQuantity", {
        index,
        quantity,
      });
    },
    isPriceFromSizeWithDiscount(size) {
      if (!size.price) return false;

      return size.price.isDiscounted;
    },
    getNormalPriceFromSize(size) {
      if (!size.price) return "";

      return this.formatPriceWithCurrency(
        size.price.normalPrice,
        size.price.currency,
      );
    },
    getRetailPriceFromSize(size) {
      if (!size.price) return "";

      return this.formatPriceWithCurrency(
        size.price.retailPrice,
        size.price.currency,
      );
    },
    getPriceFromSize(size) {
      if (!size.price) return "";

      return this.formatPriceWithCurrency(
        size.price.price,
        size.price.currency,
      );
    },
    formatPriceWithCurrency(price, currency) {
      return `${price} ${currency}`;
    },
    handleScroll() {
      this.$nextTick(() => {
        const el = this.$refs.scrollableElement;
        this.hasHorizontalScroll = hasHorizontalScrollFn(el);
        this.isScrolledToRight = checkIfIsScrolledToRight(el);
      });
    },
  },
};
</script>
<style scoped lang="scss">
.matrix {
  display: flex;
  flex: 1 1 auto;
  margin-right: 1.2rem;

  &__blur {
    position: absolute;
    right: 0;
    width: 4.4375rem;
    height: 100%;
    z-index: 5;
    background: linear-gradient(
      270deg,
      var(--color-white) 0%,
      rgba(255, 255, 255, 0) 93.02%
    );
  }

  &__cell {
    width: 110px;
    height: 3.813rem;
    padding: 0.5rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--color-neutral-15);
    flex-flow: row wrap;
    position: relative;

    &__price-container {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.25rem;
    }

    p {
      color: var(--color-matrix-title-text);
      font-family: var(--font-family-roboto);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 0.875rem */
      word-break: break-word;
      text-align: center;
    }

    &--previous-price {
      color: var(--color-neutral-70);
      font-family: var(--font-family-roboto);
      font-size: 0.75rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 0.75rem */
      text-decoration-line: line-through;
    }

    &--new-price {
      color: var(--color-signal-red);
      font-family: var(--font-family-roboto);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 700;
      line-height: 100%; /* 0.875rem */
    }

    &--stock {
      p {
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 0.75rem */
      }

      &-info {
        p {
          color: #707070;
        }
      }
    }

    &--quantity {
      height: 3.813rem;
      position: relative;
    }

    &:nth-child(odd) {
      background-color: var(--color-neutral-10);
    }

    &-header {
      height: 2.375rem !important;
      background-color: var(--color-matrix-title-background) !important;
      color: var(--color-matrix-title-text);

      &--content {
        p {
          color: var(--color-matrix-title-text);
          font-weight: 700;
        }
      }
    }
  }

  &__fixed-col {
    color: var(--color-matrix-total-text);
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    position: absolute;
    left: 0;
    top: 0;

    &-quantity {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      font-size: 1rem;
      font-weight: 400;
      justify-content: center;
    }

    &-quantity,
    &-title {
      position: relative;
      z-index: 2;
    }

    &-title {
      font-size: 0.875rem;
      font-weight: 500;
      overflow: hidden;
      padding: 0.6rem;
      text-align: center;
      text-overflow: ellipsis;
      text-transform: uppercase;
      white-space: nowrap;
    }
  }

  &__content {
    display: flex;
    padding-bottom: 1rem;
    width: 100px;
    overflow-x: auto;
    flex: 1 1 auto;

    @include scrollbars($height: 8px);

    &__input-container {
      position: relative;
      width: 3.5rem;
      height: 2.8125rem;

      &--input {
        appearance: none;
        margin: 0;
        outline: none;
        text-align: center;
        border-radius: 0.25rem;
        border: 1px solid #dedede;
        background: var(--color-white);
        width: 3.5rem;
        height: 2.8125rem;
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 0.875rem */

        &--saving {
          pointer-events: none !important;
        }

        &--in-stock {
          border: 1px solid var(--color-stock-indicator-green);

          &:focus {
            border-color: var(--color-stock-indicator-green) !important;
          }
        }

        &-loader {
          position: absolute;
          top: 0;
          right: 0;
          width: 0.875rem;
          height: 0.875rem;
          border-radius: 0 0.25rem 0 0.25rem;
          background-color: var(--color-black);
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            animation-name: spin;
            animation-duration: 2000ms;
            animation-iteration-count: infinite;
            animation-timing-function: linear;
          }

          @keyframes spin {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        }

        &:focus {
          border-color: var(--color-button-background);
          outline: none;

          &::placeholder {
            color: transparent;
          }

          &:-ms-input-placeholder {
            color: transparent;
          }

          &::-ms-input-placeholder {
            color: transparent;
          }
        }

        &[type="number"] {
          -moz-appearance: textfield;
        }

        &[type="number"]::-webkit-inner-spin-button,
        &[type="number"]::-webkit-outer-spin-button {
          -webkit-appearance: none;
        }
      }
    }
  }
}
</style>
