import customerUserGroup from "./customerUserGroup";

export default [
  {
    path: "/administration/customers",
    name: "customers",
    component: () =>
      import(/* webpackChunkName: "customers" */ "../views/Customers.vue"),
    meta: {
      breadcrumbs: [{ title: "_Navigation.Customers" }],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/customers/:customerId",
    name: "customer",
    component: () =>
      import(/* webpackChunkName: "customer" */ "../views/Customer.vue"),
    meta: {
      breadcrumbs: [
        {
          path: { name: "customers" },
          title: "_Navigation.Customers",
        },
        { title: "customers/nameOfCurrentCustomer" },
      ],
      isAuthenticationRequired: true,
    },
    redirect: {
      name: "customer.details",
    },
    children: [
      {
        path: "addresses",
        name: "customer.addresses",
        component: () =>
          import(
            /* webpackChunkName: "customerAddresses" */ "../views/CustomerAddresses.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.DeliveryAddresses" },
          ],
          isAuthenticationRequired: true,
        },
        children: [
          {
            path: ":addressId",
            name: "customer.address",
            component: () =>
              import(
                /* webpackChunkName: "customerAddress" */ "../views/CustomerAddress.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.addresses" },
                  title: "_Navigation.DeliveryAddresses",
                },
                { title: "_Administration.Address" },
              ],
              isAuthenticationRequired: true,
            },
          },
        ],
      },
      {
        path: "details",
        name: "customer.details",
        component: () =>
          import(
            /* webpackChunkName: "customer" */ "../views/CustomerDetails.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.Details" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "end-customers",
        name: "customer.endCustomers",
        component: () =>
          import(
            /* webpackChunkName: "customerEndCustomers" */ "../views/CustomerEndCustomers.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.EndCustomers" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "external-products",
        name: "customer.externalProducts",
        component: () =>
          import(
            /* webpackChunkName: "customerExternalProducts" */ "../views/CustomerExternalProducts.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.ExternalProducts" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "external-products/:externalProductId",
        name: "customer.externalProduct",
        component: () =>
          import(
            /* webpackChunkName: "customerExternalProduct" */ "../views/CustomerExternalProduct.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            {
              path: {
                name: "customer.externalProducts",
                params: { customerId: null },
              },
              title: "_Navigation.ExternalProducts",
            },
            { title: "externalProducts/nameOfCurrentExternalProduct" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "orders/:orderId",
        name: "customer.order",
        component: () =>
          import(
            /* webpackChunkName: "customerOrder" */ "../views/CustomerOrder.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { path: { name: "customer.orders" }, title: "_Navigation.Orders" },
            { title: "orders/currentOrderNumber" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "orders",
        name: "customer.orders",
        component: () =>
          import(
            /* webpackChunkName: "customerOrders" */ "../views/CustomerOrders.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.Orders" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "products",
        name: "customer.products",
        component: () =>
          import(
            /* webpackChunkName: "customerProducts" */ "../views/CustomerProducts.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.Prices" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "products/:productId",
        name: "customer.product",
        component: () =>
          import(
            /* webpackChunkName: "customerProduct" */ "../views/CustomerProduct.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            {
              path: { name: "customer.products" },
              title: "_Navigation.Prices",
            },
            { title: "products/nameOfCurrentProduct" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "roles",
        name: "customer.roles",
        component: () =>
          import(
            /* webpackChunkName: "customerRoles" */ "../views/CustomerRoles.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.Roles" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "roles/:roleId",
        name: "customer.role",
        component: () =>
          import(
            /* webpackChunkName: "customerRole" */ "../views/CustomerRole.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { path: { name: "customer.roles" }, title: "_Navigation.Roles" },
            { title: "customers/nameOfCurrentCustomerRole" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "salespersons",
        name: "customer.salespersons",
        component: () =>
          import(
            /* webpackChunkName: "customerSalespersons" */ "../views/CustomerSalespersons.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.Salespersons" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "user-groups",
        name: "customer.userGroups",
        component: () =>
          import(
            /* webpackChunkName: "customerUserGroups" */ "../views/CustomerUserGroups.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.UserGroups" },
          ],
          isAuthenticationRequired: true,
        },
      },
      ...customerUserGroup,
      {
        path: "users",
        name: "customer.users",
        component: () =>
          import(
            /* webpackChunkName: "customerUsers" */ "../views/CustomerUsers.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.Users" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "users/:userId",
        name: "customer.user",
        component: () =>
          import(
            /* webpackChunkName: "customerUser" */ "../views/CustomerUser.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { path: { name: "customer.users" }, title: "_Navigation.Users" },
            { title: "users/nameOfCurrentUser" },
          ],
          isAuthenticationRequired: true,
        },
        redirect: {
          name: "customer.user.personalInformation",
        },
        children: [
          {
            path: "addresses",
            name: "customer.user.addresses",
            component: () =>
              import(
                /* webpackChunkName: "customerUserAddresses" */ "../views/CustomerUserAddresses.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.users" },
                  title: "_Navigation.Users",
                },
                { title: "users/nameOfCurrentUser" },
                { title: "_Navigation.Addresses" },
              ],
              isAuthenticationRequired: true,
            },
            children: [
              {
                path: ":addressId",
                name: "customer.user.address",
                component: () =>
                  import(
                    /* webpackChunkName: "customerUserAddress" */ "../views/CustomerUserAddress.vue"
                  ),
                meta: {
                  breadcrumbs: [
                    {
                      path: { name: "customers" },
                      title: "_Navigation.Customers",
                    },
                    {
                      path: {
                        name: "customer",
                        params: { customerId: null },
                      },
                      title: "customers/nameOfCurrentCustomer",
                    },
                    {
                      path: { name: "customer.users" },
                      title: "_Navigation.Users",
                    },
                    { title: "users/nameOfCurrentUser" },
                    { title: "_Navigation.Addresses" },
                    { title: "_Administration.Address" },
                  ],
                  isAuthenticationRequired: true,
                },
              },
            ],
          },
          {
            path: "orders/:orderId",
            name: "customer.user.order",
            component: () =>
              import(
                /* webpackChunkName: "customerUserOrder" */ "../views/CustomerUserOrder.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.users" },
                  title: "_Navigation.Users",
                },
                { title: "users/nameOfCurrentUser" },
                {
                  path: { name: "customer.user.orders" },
                  title: "_Navigation.Orders",
                },
                { title: "orders/currentOrderNumber" },
              ],
              isAuthenticationRequired: true,
            },
          },
          {
            path: "orders",
            name: "customer.user.orders",
            component: () =>
              import(
                /* webpackChunkName: "customerUserOrders" */ "../views/CustomerUserOrders.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.users" },
                  title: "_Navigation.Users",
                },
                { title: "users/nameOfCurrentUser" },
                { title: "_Navigation.Orders" },
              ],
              isAuthenticationRequired: true,
            },
          },
          {
            path: "personal-information",
            name: "customer.user.personalInformation",
            component: () =>
              import(
                /* webpackChunkName: "customerUser" */ "../views/CustomerUserPersonalInformation.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.users" },
                  title: "_Navigation.Users",
                },
                { title: "users/nameOfCurrentUser" },
                { title: "_Administration.PersonalInformation" },
              ],
              isAuthenticationRequired: true,
            },
          },
          {
            path: "settings",
            name: "customer.user.settings",
            component: () =>
              import(
                /* webpackChunkName: "customerUserSettings" */ "../views/CustomerUserSettings.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.users" },
                  title: "_Navigation.Users",
                },
                { title: "users/nameOfCurrentUser" },
                { title: "_Administration.Settings" },
              ],
              isAuthenticationRequired: true,
            },
          },
          {
            path: "sizes-and-measurements",
            name: "customer.user.sizesAndMeasurements",
            component: () =>
              import(
                /* webpackChunkName: "customerUserSizesAndMeasurements" */ "../views/CustomerUserSizesAndMeasurements.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.users" },
                  title: "_Navigation.Users",
                },
                { title: "users/nameOfCurrentUser" },
                { title: "_Administration.SizesAndMeasurements" },
              ],
              isAuthenticationRequired: true,
            },
          },
          {
            path: "transactions",
            name: "customer.user.transactions",
            component: () =>
              import(
                /* webpackChunkName: "customerUserTransactions" */ "../views/CustomerUserTransactions.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.users" },
                  title: "_Navigation.Users",
                },
                { title: "users/nameOfCurrentUser" },
                { title: "_Wallet.Transactions" },
              ],
              isAuthenticationRequired: true,
            },
            children: [
              {
                path: ":transactionId",
                name: "customer.user.transaction",
                component: () =>
                  import(
                    /* webpackChunkName: "customerUserTransaction" */ "../views/CustomerUserTransaction.vue"
                  ),
                meta: {
                  breadcrumbs: [
                    {
                      path: { name: "customers" },
                      title: "_Navigation.Customers",
                    },
                    {
                      path: {
                        name: "customer",
                        params: { customerId: null },
                      },
                      title: "customers/nameOfCurrentCustomer",
                    },
                    {
                      path: { name: "customer.users" },
                      title: "_Navigation.Users",
                    },
                    { title: "users/nameOfCurrentUser" },
                    { title: "_Wallet.Transactions" },
                    { title: "_Wallet.Transaction" },
                  ],
                  isAuthenticationRequired: true,
                },
              },
            ],
          },
        ],
      },
      {
        path: "wardrobes",
        name: "customer.wardrobes",
        component: () =>
          import(
            /* webpackChunkName: "customerWardrobes" */ "../views/CustomerWardrobes.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            { title: "_Navigation.Wardrobes" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "wardrobes/:wardrobeId",
        name: "customer.wardrobe",
        component: () =>
          import(
            /* webpackChunkName: "customerWardrobe" */ "../views/CustomerWardrobe.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            {
              path: { name: "customer.wardrobes" },
              title: "_Navigation.Wardrobes",
            },
            { title: "wardrobes/nameOfCurrentWardrobe" },
          ],
          isAuthenticationRequired: true,
        },
      },
    ],
  },
  {
    path: "/administration/customer-service-employees",
    name: "customerServiceRepresentatives",
    component: () =>
      import(
        /* webpackChunkName: "customerServiceRepresentatives" */ "../views/CustomerServiceRepresentatives.vue"
      ),
    meta: {
      breadcrumbs: [{ title: "_Navigation.CustomerService" }],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/customer-service-employees/:id",
    name: "customerService",
    component: () =>
      import(
        /* webpackChunkName: "customerServiceRepresentatives" */ "../views/CustomerServiceRepresentative.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          path: { name: "customerService" },
          title: "_Navigation.CustomerService",
        },
        { title: "customerService/nameOfCurrentCustomerServiceRepresentative" },
      ],
      isAuthenticationRequired: true,
    },
    redirect: {
      name: "customerService.personalInformation",
    },
    children: [
      {
        path: "personal-information",
        name: "customerService.personalInformation",
        component: () =>
          import(
            /* webpackChunkName: "customerServiceRepresentatives" */ "../views/CustomerServicePersonalInformation.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "customerService" },
              title: "_Navigation.CustomerService",
            },
            {
              title:
                "customerService/nameOfCurrentCustomerServiceRepresentative",
            },
            { title: "_Administration.PersonalInformation" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "settings",
        name: "customerService.settings",
        component: () =>
          import(
            /* webpackChunkName: "customerServiceRepresentatives" */ "../views/CustomerServiceSettings.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "customerService" },
              title: "_Navigation.CustomerService",
            },
            {
              title:
                "customerService/nameOfCurrentCustomerServiceRepresentative",
            },
            { title: "_Administration.Settings" },
          ],
          isAuthenticationRequired: true,
        },
      },
    ],
  },
  {
    path: "/administration/no-access",
    name: "administration.noAccess",
    component: () =>
      import(/* webpackChunkName: "noAccess" */ "../views/NoAccess.vue"),
    meta: {
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/orders/:orderId",
    name: "order",
    component: () =>
      import(/* webpackChunkName: "order" */ "../views/Order.vue"),
    meta: {
      breadcrumbs: [
        { path: { name: "orders" }, title: "_Navigation.Orders" },
        { title: "orders/currentOrderNumber" },
      ],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/orders",
    name: "orders",
    component: () =>
      import(/* webpackChunkName: "orders" */ "../views/Orders.vue"),
    meta: {
      breadcrumbs: [{ title: "_Navigation.Orders" }],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/profile",
    name: "administrationProfile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      isAuthenticationRequired: true,
    },
    redirect: {
      name: "administrationProfile.personalInformation",
    },
    children: [
      {
        path: "addresses",
        name: "administrationProfile.addresses",
        component: () =>
          import(
            /* webpackChunkName: "profileAddresses" */ "../views/ProfileAddresses.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "administrationProfile" },
              title: "_Navigation.MyProfile",
            },
            { title: "_Navigation.Addresses" },
          ],
          isAuthenticationRequired: true,
        },
        children: [
          {
            path: ":addressId",
            name: "administrationProfile.address",
            component: () =>
              import(
                /* webpackChunkName: "profileAddress" */ "../views/ProfileAddress.vue"
              ),
            meta: {
              breadcrumbs: [
                {
                  path: { name: "administrationProfile" },
                  title: "_Navigation.MyProfile",
                },
                {
                  path: { name: "administrationProfile.addresses" },
                  title: "_Navigation.Addresses",
                },
                { title: "_Administration.Address" },
              ],
              isAuthenticationRequired: true,
            },
          },
        ],
      },
      {
        path: "orders/:orderId",
        name: "administrationProfile.order",
        component: () =>
          import(
            /* webpackChunkName: "profileOrder" */ "../views/ProfileOrder.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "administrationProfile" },
              title: "_Navigation.MyProfile",
            },
            {
              path: { name: "administrationProfile.orders" },
              title: "_Navigation.Orders",
            },
            { title: "orders/currentOrderNumber" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "orders",
        name: "administrationProfile.orders",
        component: () =>
          import(
            /* webpackChunkName: "profileOrders" */ "../views/ProfileOrders.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "administrationProfile" },
              title: "_Navigation.MyProfile",
            },
            { title: "_Navigation.Orders" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "personal-information",
        name: "administrationProfile.personalInformation",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/ProfilePersonalInformation.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "administrationProfile" },
              title: "_Navigation.MyProfile",
            },
            { title: "_Administration.PersonalInformation" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "settings",
        name: "administrationProfile.settings",
        component: () =>
          import(
            /* webpackChunkName: "profileSettings" */ "../views/ProfileSettings.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "administrationProfile" },
              title: "_Navigation.MyProfile",
            },
            { title: "_Administration.Settings" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "sizes-and-measurements",
        name: "administrationProfile.sizesAndMeasurements",
        component: () =>
          import(
            /* webpackChunkName: "profileSizesAndMeasurements" */ "../views/ProfileSizesAndMeasurements.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "administrationProfile" },
              title: "_Navigation.MyProfile",
            },
            { title: "_Administration.SizesAndMeasurements" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "transactions",
        name: "administrationProfile.transactions",
        component: () =>
          import(
            /* webpackChunkName: "profileTransactions" */ "../views/ProfileTransactions.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "administrationProfile" },
              title: "_Navigation.MyProfile",
            },
            { title: "_Wallet.Transactions" },
          ],
          isAuthenticationRequired: true,
        },
        children: [
          {
            path: ":transactionId",
            name: "administrationProfile.transaction",
            component: () =>
              import(
                /* webpackChunkName: "profileTransaction" */ "../views/ProfileTransaction.vue"
              ),
            meta: {
              breadcrumbs: [
                {
                  path: { name: "administrationProfile" },
                  title: "_Navigation.MyProfile",
                },
                {
                  path: { name: "administrationProfile.transactions" },
                  title: "_Wallet.transactions",
                },
                { title: "_Wallet.Transaction" },
              ],
              isAuthenticationRequired: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/administration/role-templates",
    name: "roleTemplates",
    component: () =>
      import(
        /* webpackChunkName: "roleTemplates" */ "../views/RoleTemplates.vue"
      ),
    meta: {
      breadcrumbs: [{ title: "_Navigation.RoleTemplates" }],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/role-templates/:id",
    name: "roleTemplate",
    component: () =>
      import(
        /* webpackChunkName: "roleTemplate" */ "../views/RoleTemplate.vue"
      ),
    meta: {
      breadcrumbs: [
        { path: { name: "roleTemplates" }, title: "_Navigation.RoleTemplates" },
        { title: "roleTemplates/nameOfCurrentRoleTemplate" },
      ],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/salespersons",
    name: "salespersons",
    component: () =>
      import(
        /* webpackChunkName: "salespersons" */ "../views/Salespersons.vue"
      ),
    meta: {
      breadcrumbs: [{ title: "_Navigation.Salespersons" }],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/salespersons/:id",
    name: "salesperson",
    component: () =>
      import(/* webpackChunkName: "salespersons" */ "../views/Salesperson.vue"),
    meta: {
      breadcrumbs: [
        {
          path: { name: "salespersons" },
          title: "_Navigation.Salespersons",
        },
        { title: "salespersons/nameOfCurrentSalesperson" },
      ],
      isAuthenticationRequired: true,
    },
    redirect: {
      name: "salesperson.personalInformation",
    },
    children: [
      {
        path: "customers",
        name: "salesperson.customers",
        component: () =>
          import(
            /* webpackChunkName: "salespersons" */ "../views/SalespersonCustomers.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "salespersons" },
              title: "_Navigation.Salespersons",
            },
            { title: "salespersons/nameOfCurrentSalesperson" },
            { title: "_Navigation.Customers" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "personal-information",
        name: "salesperson.personalInformation",
        component: () =>
          import(
            /* webpackChunkName: "salespersons" */ "../views/SalespersonPersonalInformation.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "salespersons" },
              title: "_Navigation.Salespersons",
            },
            { title: "salespersons/nameOfCurrentSalesperson" },
            { title: "_Administration.PersonalInformation" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "settings",
        name: "salesperson.settings",
        component: () =>
          import(
            /* webpackChunkName: "salespersons" */ "../views/SalespersonSettings.vue"
          ),
        meta: {
          breadcrumbs: [
            {
              path: { name: "salespersons" },
              title: "_Navigation.Salespersons",
            },
            { title: "salespersons/nameOfCurrentSalesperson" },
            { title: "_Administration.Settings" },
          ],
          isAuthenticationRequired: true,
        },
      },
    ],
  },
  {
    path: "/administration/systemadministrators",
    name: "systemadministrators",
    component: () =>
      import(
        /* webpackChunkName: "systemadministrators" */ "../views/Systemadministrators.vue"
      ),
    meta: {
      breadcrumbs: [{ title: "_Navigation.Systemadministrators" }],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/administration/systemadministrators/:id",
    name: "systemadministrator",
    component: () =>
      import(
        /* webpackChunkName: "systemadministrator" */ "../views/Systemadministrator.vue"
      ),
    meta: {
      breadcrumbs: [
        {
          path: { name: "systemadministrators" },
          title: "_Navigation.Systemadministrators",
        },
        { title: "systemadministrators/nameOfCurrentSystemadministrator" },
      ],
      isAuthenticationRequired: true,
    },
  },
];
