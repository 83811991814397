<template>
  <div
    class="toggle-switch"
    :class="{
      'toggle-switch--disabled': disabled,
      'toggle-switch--label-on-right-side': labelOnRightSide,
    }"
  >
    <input
      :id="id"
      :checked="value"
      :disabled="disabled"
      class="toggle-switch__input"
      type="checkbox"
      @change="$emit('input', $event.target.checked)"
    />
    <label :for="id" class="toggle-switch__label" :class="{}">
      <span
        class="toggle-switch__label-text"
        :class="{ 'toggle-switch__label-text--disabled': secondLabel && value }"
        >{{ label
        }}<span v-if="description" class="toggle-switch__label-description">{{
          description
        }}</span></span
      >
      <span
        :class="{
          'toggle-switch__knob--multiple-texts': secondLabel,
        }"
        class="toggle-switch__knob"
      ></span>
      <span
        v-if="secondLabel"
        class="toggle-switch__label-text"
        :class="{
          'toggle-switch__label-text--disabled': secondLabel && !value,
        }"
        >{{ secondLabel }}</span
      >
    </label>
  </div>
</template>

<script>
import { uuid } from "@/helpers/uuid";

export default {
  name: "ToggleSwitch",
  model: {
    event: "input",
  },
  props: {
    description: {
      default: "",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    labelOnRightSide: {
      default: false,
      type: Boolean,
    },
    secondLabel: {
      default: "",
      type: String,
    },
    value: {
      default: null,
      type: Boolean,
    },
  },
  data() {
    return {
      id: uuid(),
    };
  },
};
</script>

<style lang="scss" scoped>
.toggle-switch {
  position: relative;

  &__input {
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  &__knob {
    flex: 0 0 auto;
    height: 100%;
    margin-left: calc(var(--size-toggle-switch) * 0.75);
    position: relative;
    width: calc(var(--size-toggle-switch) * 2);

    .addresses__column & {
      display: block;
      height: calc(var(--size-toggle-switch) * 1.25);
      margin: 1rem 0 0;
    }

    .filters & {
      margin-left: calc(var(--size-toggle-switch) * 0.5);
    }

    .toggle-switch--label-on-right-side & {
      margin-left: 0;
      margin-right: calc(var(--size-toggle-switch) * 0.75);
    }

    &--multiple-texts {
      margin-right: calc(var(--size-toggle-switch) * 0.75);

      &::before {
        .toggle-switch__input:checked + .toggle-switch__label & {
          background-color: var(--color-toggle-switch-background) !important;
        }
      }
    }

    &::before,
    &::after {
      content: "";
      left: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    &::before {
      background-color: var(--color-toggle-switch-background);
      border-radius: var(--size-toggle-switch);
      height: calc(var(--size-toggle-switch) * 1.25);
      transition: background-color 300ms ease;
      width: calc(var(--size-toggle-switch) * 2);

      .toggle-switch--disabled & {
        opacity: 0.5;
      }

      .toggle-switch__input:checked + .toggle-switch__label & {
        background-color: var(--color-toggle-switch-background-active);
      }
    }

    &::after {
      background-color: var(--color-toggle-switch-knob-background);
      border-radius: 50%;
      box-shadow: var(--elevation-4);
      height: var(--size-toggle-switch);
      transform: translate(
        0.1625rem,
        -50%
      ); //fixed value due to calc-issues in transforms in IE11
      transition: transform 300ms ease;
      width: var(--size-toggle-switch);

      .toggle-switch__input:checked + .toggle-switch__label & {
        transform: translateX(100%) translateX(-0.195rem) translateY(-50%); //fixed value due to calc-issues in transforms in IE11
      }
    }
  }

  &__label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    font-weight: 700;
    min-height: 3.3125rem; //match height of text inputs
    position: relative;
    z-index: 2;

    .addresses__column & {
      display: block;
      text-decoration: none !important;
    }

    .filters & {
      font-size: 1rem;
      font-weight: 400;
      min-height: 2.9375rem; //match height of dropdown text
    }

    .service-bar & {
      height: 100%;
      min-height: 2.5rem;
    }

    .table & {
      min-height: var(--size-toggle-switch);
    }

    .toggle-switch--disabled & {
      cursor: not-allowed;
    }

    &-description {
      display: block;
      font-weight: 400;
      margin-top: 0.25rem;
      opacity: 0.75;
    }

    &-text {
      flex: 1 1 auto;
      transition: opacity 300ms ease;

      .addresses__column & {
        display: block;
        margin-bottom: 0.5rem;
        opacity: 1 !important;
      }

      .toggle-switch--label-on-right-side & {
        order: 1;
      }

      &--disabled {
        opacity: 0.5;
      }
    }
  }
}
</style>
