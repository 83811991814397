import { mapGetters } from "vuex";

const currencySpecialCases = ["GBP", "USD"];

export default {
  computed: {
    ...mapGetters("authentication", ["userConfiguration"]),
    ...mapGetters("basket", ["basketCurrency"]),
  },
  methods: {
    convertNumberToLocaleString(number) {
      const { userConfiguration } = this;

      if (!Number.isFinite(number)) {
        console.error("A number has to be provided.");
        return number;
      }

      let locale;

      switch (userConfiguration.contextSiteLanguage) {
        case "da":
          locale = "da-DK";
          break;
        case "de":
          locale = "de-DE";
          break;
        case "en":
          locale = "en-GB";
          break;
        case "es":
          locale = "es-ES";
          break;
        case "fr":
          locale = "fr-FR";
          break;
        case "nb":
          locale = "nb-NO";
          break;
        case "nl":
          locale = "nl-NL";
          break;
        default:
          locale = undefined;
          break;
      }

      return number.toLocaleString(locale, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    },
    convertPriceStringToFloat(string) {
      const { userConfiguration } = this;

      if (typeof string !== "string") {
        console.error("A string has to be provided.");
        return string;
      }

      let stringWithConvertedSeparators;

      switch (userConfiguration.contextSiteLanguage) {
        case "en":
          stringWithConvertedSeparators = string.replace(/,/g, "");
          break;
        default:
          stringWithConvertedSeparators = string
            .replace(/\./g, "")
            .replace(/\,/g, ".");
          break;
      }

      const stringWithoutSpaces = stringWithConvertedSeparators.replace(
        /\s/g,
        "",
      );

      return parseFloat(stringWithoutSpaces);
    },
    /**
     * Will transform the price based on the rules defined by F-Engel
     * For all the cases should be something like: 1.394,40 EUR
     * If Pounds (GBP) or Dollars (USD) should be something like: 1,394.40 GBP
     * IF Points (PT) should be something like: 1394 PT
     * @param value
     * @param currency
     * @return {string}
     */
    formatPriceCurrency(value, currency) {
      const contextCurrency = currency ?? this.basketCurrency;

      // this means that the currency is Points and this logic is not applied
      if (contextCurrency === "PT") {
        return value;
      }

      if (typeof value === "number") {
        value = this.convertNumberToLocaleString(value);
      }

      if (typeof value !== "string") {
        console.error("A string has to be provided.");
        return value;
      }

      const indexOfDecimalSeparator = this.getDecimalSeparatorIndex(value);

      // probably we need to handle the billions and millions case,
      // but let's see if that is a real case
      return value.replace(/[.,]/g, (match, index) => {
        if (currencySpecialCases.includes(contextCurrency)) {
          return this.handleSpecialCases(indexOfDecimalSeparator, index, match);
        } else {
          return this.handleNonSpecialCases(
            indexOfDecimalSeparator,
            index,
            match,
          );
        }
      });
    },
    handleSpecialCases(indexOfDecimalSeparator, index, match) {
      if (indexOfDecimalSeparator !== -1) {
        return index === indexOfDecimalSeparator ? "." : ",";
      } else {
        return match;
      }
    },
    handleNonSpecialCases(indexOfDecimalSeparator, index, match) {
      if (indexOfDecimalSeparator !== -1) {
        return index === indexOfDecimalSeparator ? "," : ".";
      } else {
        return match;
      }
    },
    getDecimalSeparatorIndex(str) {
      return str.length >= 3 ? str.indexOf(str[str.length - 3]) : -1;
    },
  },
};
