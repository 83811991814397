<template>
  <div class="background">
    <Loader :large="true" :light="true" :visible="!isUserAuthenticated" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Loader from "@/components/Loader";

export default {
  name: "Callback",
  components: {
    Loader,
  },
  computed: {
    ...mapGetters("authentication", [
      "isUserAuthenticated",
      "isUserSystemadministrator",
    ]),
  },
  created() {
    if (this.isUserAuthenticated) {
      this.$router.push({
        name: this.getStartPageForCurrentUser(),
      });
    } else {
      this.LOGIN_REDIRECT_CALLBACK();
    }
  },
  methods: {
    ...mapActions("authentication", ["LOGIN_REDIRECT_CALLBACK"]),
  },
};
</script>

<style lang="scss" scoped>
.background {
  background-color: var(--theme-secondary);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
}
</style>
