var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.blockData)?_c('Container',{attrs:{"medium":true}},[_c('div',{staticClass:"block block--offset block--tall",class:{
      'block--dark-background': _vm.blockData.darkBackground,
      'block--use-right-side-layout': _vm.shouldImageBePlacedToTheRight,
    }},[_c('div',{staticClass:"block__40-percent block__media-container"},[(_vm.blockData.image)?_c('ImageFromUmbraco',{staticClass:"block__image",attrs:{"id":_vm.blockData.image._system.id,"alt":_vm.blockData.image.alternateText,"height":_vm.blockData.image.umbracoHeight,"keys-and-sizes":{
          s: 'block-regular-small',
          m: 'block-tall-medium',
          l: 'block-extra-tall-medium',
          xl: 'block-wide-medium',
        },"width":_vm.blockData.image.umbracoWidth}}):_vm._e()],1),_c('div',{staticClass:"block__60-percent"},[_c('div',{staticClass:"block__content block__content--limited-width block__content--middle"},[_c('div',[(_vm.blockData.caption)?_c('div',{staticClass:"block__keywords"},[_vm._v(" "+_vm._s(_vm.blockData.caption)+" ")]):_vm._e(),(_vm.blockData.headline)?_c('Heading',{staticClass:"block__title",attrs:{"tag":_vm.blockData.headlineTag}},[_vm._v(" "+_vm._s(_vm.blockData.headline)+" ")]):_vm._e(),(_vm.blockData.description)?_c('p',{staticClass:"block__description"},[_vm._v(" "+_vm._s(_vm.blockData.description)+" ")]):_vm._e(),(_vm.blockData.cTA && _vm.blockData.cTA.name && _vm.blockData.cTA.url)?_c('ConditionalRouterLink',{staticClass:"block__button",class:{ 'block__button--ghost': !_vm.blockData.darkBackground },attrs:{"target":_vm.blockData.cTA.target,"to":_vm.blockData.cTA.url,"type":_vm.blockData.cTA.type}},[_vm._v(_vm._s(_vm.blockData.cTA.name))]):_vm._e()],1)])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }