import axios from "axios";
import uniqBy from "lodash.uniqby";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  addresses: [],
  addressesForCheckout: null,
  currentAddressId: null,
  status: "",
});

const getters = {
  addressGroupsForCheckout: state => {
    if (!state.addressesForCheckout) return [];

    const {
      dealerAddresses,
      endCustomerAddresses,
      userAddresses,
      userGroupAddresses,
    } = state.addressesForCheckout;

    const addresses = [];

    if (userAddresses) {
      addresses.push({
        title: "Checkout.AddressTypes.UserAddresses",
        values: userAddresses,
      });
    }

    if (userGroupAddresses) {
      addresses.push({
        title: "Checkout.AddressTypes.UserGroupAddresses",
        values: userGroupAddresses,
      });
    }

    if (endCustomerAddresses) {
      addresses.push({
        title: "Checkout.AddressTypes.EndCustomerAddresses",
        values: endCustomerAddresses,
      });
    }

    if (dealerAddresses) {
      addresses.push({
        title: "Checkout.AddressTypes.DealerAddresses",
        values: dealerAddresses,
      });
    }

    return addresses;
  },
  addresses: state => state.addresses,
  addressesForCheckout: state => state.addressesForCheckout,
  currentAddress: state => {
    if (!state.currentAddressId) return {};

    const addressesForCheckout = state.addressesForCheckout || [];

    const allAddresses = uniqBy(
      [
        ...state.addresses,
        ...Object.values(addressesForCheckout)
          .filter(address => address)
          .flat(),
      ],
      "id",
    );

    return allAddresses.find(address => address.id === state.currentAddressId);
  },
  currentAddressId: state => state.currentAddressId,
  statusOfAddresses: state => state.status,
};

const actions = {
  CREATE_ADDRESS_FROM_CHECKOUT({ commit }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.SAVING });

    return axios
      .post(`${endpoints.ECOMMERCE}/address/createFromCheckout`, {
        ...payload.address,
      })
      .then(({ data }) => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
        return data;
      })
      .catch(error => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  CREATE_CUSTOMER_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/customers/${payload.id}/addresses/`, {
          ...payload.address,
        })
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_CUSTOMER", { id: payload.id });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  CREATE_USER_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/${payload.id}/addresses/`, {
          ...payload.address,
        })
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_USER", { id: payload.id });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  CREATE_USER_GROUP_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/usergroups/${payload.id}/addresses/`, {
          ...payload.address,
        })
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_USER_GROUP", { id: payload.id });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_CUSTOMER_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${endpoints.ECOMMERCE}/customers/${payload.customerId}/addresses/${payload.addressId}`,
        )
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_CUSTOMER", { id: payload.customerId });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_USER_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${endpoints.ECOMMERCE}/users/${payload.userId}/addresses/${payload.addressId}`,
        )
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_USER", { id: payload.userId });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_USER_GROUP_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .delete(
          `${endpoints.ECOMMERCE}/usergroups/${payload.userGroupId}/addresses/${payload.addressId}`,
        )
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_USER_GROUP", { id: payload.userGroupId });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  GET_ADDRESSES_FOR_CHECKOUT({ commit }) {
    commit("SET_ADDRESSES_FOR_CHECKOUT", { addresses: null });
    commit("SET_ADDRESSES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/checkout/addresses`)
      .then(({ data }) => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
        commit("SET_ADDRESSES_FOR_CHECKOUT", { addresses: data });
      })
      .catch(error => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ADDRESSES_OF_CUSTOMER({ commit }, payload) {
    commit("SET_ADDRESSES", { addresses: [] });
    commit("SET_ADDRESSES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${payload.id}/addresses`)
      .then(({ data }) => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
        commit("SET_ADDRESSES", { addresses: data });
      })
      .catch(error => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ADDRESSES_OF_USER({ commit }, payload) {
    commit("SET_ADDRESSES", { addresses: [] });
    commit("SET_ADDRESSES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/users/${payload.id}/addresses`)
      .then(({ data }) => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
        commit("SET_ADDRESSES", { addresses: data });
      })
      .catch(error => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ADDRESSES_OF_USER_GROUP({ commit }, payload) {
    commit("SET_ADDRESSES", { addresses: [] });
    commit("SET_ADDRESSES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/usergroups/${payload.id}/addresses`)
      .then(({ data }) => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
        commit("SET_ADDRESSES", { addresses: data });
      })
      .catch(error => {
        commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_ADDRESSES({ commit }) {
    commit("SET_ADDRESSES", { addresses: [] });
    commit("SET_ADDRESSES_FOR_CHECKOUT", { addresses: null });
  },
  UPDATE_CHECKOUT_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/address/${payload.addressId}/updateFromCheckout`,
          {
            ...payload.address,
          },
        )
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_FOR_CHECKOUT");
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  UPDATE_CURRENT_ADDRESS_ID({ commit }, payload) {
    commit("SET_CURRENT_ADDRESS_ID", payload);
  },
  UPDATE_CUSTOMER_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/customers/${payload.customerId}/addresses/${payload.addressId}`,
          {
            ...payload.address,
          },
        )
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_CUSTOMER", { id: payload.customerId });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  UPDATE_USER_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/users/${payload.userId}/addresses/${payload.addressId}`,
          {
            ...payload.address,
          },
        )
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_USER", { id: payload.userId });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  UPDATE_USER_GROUP_ADDRESS({ commit, dispatch }, payload) {
    commit("SET_ADDRESSES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/usergroups/${payload.userGroupId}/addresses/${payload.addressId}`,
          {
            ...payload.address,
          },
        )
        .then(({ data }) => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_ADDRESSES_OF_USER_GROUP", { id: payload.userGroupId });
          resolve(data);
        })
        .catch(error => {
          commit("SET_ADDRESSES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  SET_ADDRESSES(state, payload) {
    state.addresses = payload.addresses;
  },
  SET_ADDRESSES_FOR_CHECKOUT(state, payload) {
    state.addressesForCheckout = payload.addresses;
  },
  SET_ADDRESSES_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_CURRENT_ADDRESS_ID(state, payload) {
    state.currentAddressId = payload.id;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
