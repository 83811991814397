<template>
  <button
    class="trigger"
    :aria-label="getDictionaryEntry('Common.Edit')"
    @click="edit"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="trigger__icon"
      viewBox="0 0 24 24"
    >
      <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" />
      <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z" />
    </svg>
  </button>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "EditorTrigger",
  props: {
    contentKey: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters("authentication", ["siteLanguage"]),
  },
  methods: {
    ...mapActions("content", ["OPEN_MODAL_FOR_EDITING_CONTENT"]),
    edit() {
      this.OPEN_MODAL_FOR_EDITING_CONTENT({
        contentKey: this.contentKey,
        language: this.siteLanguage,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger {
  @include reset-button();

  &__icon {
    fill: none;
    height: var(--size-edit-icon);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-text-primary);
    width: var(--size-edit-icon);
  }
}
</style>
