<template>
  <fieldset class="radio-group" :class="{ 'radio-group--error': hasError }">
    <legend v-if="label" class="radio-group__title" v-bind="$attrs">
      {{ label }}
    </legend>
    <template v-if="grouped">
      <ul class="radio-group__group">
        <li
          v-for="option in options"
          :key="option.id"
          class="radio-group__group-item"
        >
          <button
            :class="{
              'radio-group__group-toggle--active': isAccordionOpen(option.id),
            }"
            class="radio-group__group-toggle"
            @click="toggle(option.id)"
          >
            <h3 class="radio-group__group-title">
              {{
                option.id === "null"
                  ? getDictionaryEntry("Administration.UsersWithoutUserGroup")
                  : option.label
              }}
              ({{ option.users ? option.users.length : 0 }})
            </h3>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="radio-group__group-icon"
              viewBox="0 0 24 24"
            >
              <path d="m6 9 6 6 6-6" />
            </svg>
          </button>
          <div
            v-if="option.users && option.users.length"
            :ref="option.id"
            class="radio-group__content"
          >
            <ul class="radio-group__list">
              <li
                v-for="(user, index) in option.users"
                :key="user.value"
                class="radio-group__item"
              >
                <div class="radio">
                  <input
                    :id="`${index}-${user.value}`"
                    v-model="internalValue"
                    :value="user.value"
                    class="radio__input"
                    :name="name"
                    type="radio"
                  />
                  <label :for="`${index}-${user.value}`" class="radio__label">
                    <span v-html="user.label" />
                    <!-- eslint-disable-line -->
                  </label>
                </div>
              </li>
            </ul>
          </div>
          <div v-if="option.children && option.children.length">
            <ul class="radio-group__group radio-group__group--child">
              <li
                v-for="child in option.children"
                :key="child.id"
                class="radio-group__group-item"
              >
                <button
                  :class="{
                    'radio-group__group-toggle--active': isAccordionOpen(
                      child.id,
                    ),
                  }"
                  class="radio-group__group-toggle"
                  @click="toggle(child.id)"
                >
                  <h3 class="radio-group__group-title">
                    {{
                      child.id === "null"
                        ? getDictionaryEntry(
                            "Administration.UsersWithoutUserGroup",
                          )
                        : child.label
                    }}
                    ({{ child.users ? child.users.length : 0 }})
                  </h3>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="radio-group__group-icon"
                    viewBox="0 0 24 24"
                  >
                    <path d="m6 9 6 6 6-6" />
                  </svg>
                </button>
                <div
                  v-if="child.users && child.users.length"
                  :ref="child.id"
                  class="radio-group__content"
                >
                  <ul class="radio-group__list">
                    <li
                      v-for="(user, index) in child.users"
                      :key="user.value"
                      class="radio-group__item"
                    >
                      <div class="radio">
                        <input
                          :id="`${index}-${user.value}`"
                          v-model="internalValue"
                          :value="user.value"
                          class="radio__input"
                          :name="name"
                          type="radio"
                        />
                        <label
                          :for="`${index}-${user.value}`"
                          class="radio__label"
                        >
                          <span v-html="user.label" />
                          <!-- eslint-disable-line -->
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </template>
    <template v-else>
      <ul class="radio-group__list">
        <li
          v-for="(option, index) in options"
          :key="option.value"
          class="radio-group__item"
        >
          <div class="radio">
            <input
              :id="`${index}-${id}`"
              v-model="internalValue"
              :value="option.value"
              class="radio__input"
              :name="name"
              type="radio"
            />
            <label :for="`${index}-${id}`" class="radio__label">
              <span v-html="option.label" />
              <!-- eslint-disable-line -->
            </label>
          </div>
        </li>
      </ul>
    </template>
    <Error :message="errorMessage" :visible="hasError" />
  </fieldset>
</template>

<script>
import Error from "@/components/Error";
import { slideDown, slideUp } from "@/helpers/slide";
import { uuid } from "@/helpers/uuid";

export default {
  name: "RadioGroup",
  components: {
    Error,
  },
  inheritAttrs: false,
  model: {
    event: "input",
  },
  props: {
    errorMessage: {
      default: "",
      type: String,
    },
    grouped: {
      default: false,
      type: Boolean,
    },
    hasError: {
      default: false,
      type: Boolean,
    },
    label: {
      default: "",
      type: String,
    },
    name: {
      required: true,
      type: String,
    },
    options: {
      required: true,
      type: Array,
    },
    value: {
      default: null,
      validator: prop => {
        if (prop === null) return true;
        return (
          typeof prop === "number" ||
          typeof prop === "object" ||
          typeof prop === "string"
        );
      },
    },
  },
  data() {
    return {
      id: uuid(),
      internalValue: this.value,
      openAccordions: [],
    };
  },
  watch: {
    options(newValue) {
      if (!newValue?.length) return;

      newValue.forEach(({ children, id }) => {
        if (this.isAccordionOpen(id)) {
          this.$nextTick(() => {
            if (this.$refs[id][0]) return;

            slideDown(this.$refs[id][0], () => {}, 0);
          });
        }
        if (!children?.length) return;

        children.forEach(({ id }) => {
          if (this.isAccordionOpen(id)) {
            this.$nextTick(() => {
              if (this.$refs[id][0]) return;

              slideDown(this.$refs[id][0], () => {}, 0);
            });
          }
        });
      });
    },
    internalValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.internalValue = newValue;
    },
  },
  methods: {
    close(id) {
      if (!id) return;

      slideUp(this.$refs[id][0]);

      this.openAccordions = this.openAccordions.filter(
        accordionId => accordionId !== id,
      );
    },
    isAccordionOpen(id) {
      if (!id) return false;

      return this.openAccordions.includes(id);
    },
    open(id) {
      if (!id) return;

      slideDown(this.$refs[id][0]);

      this.openAccordions.push(id);
    },
    toggle(id) {
      if (!id) return;

      if (this.isAccordionOpen(id)) {
        this.close(id);
      } else {
        this.open(id);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width-wide: 43rem;

.radio {
  position: relative;

  &__input {
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  &__label {
    align-items: center;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    line-height: 1.45;
    min-height: var(--size-radio);
    min-width: var(--size-radio);
    padding: calc(var(--size-radio) * 0.75) 0 calc(var(--size-radio) * 0.75)
      calc(var(--size-radio) * 1.5);
    position: relative;
    white-space: pre-line;

    &--background-image {
      background-position: center;
      background-repeat: no-repeat;
      background-size: calc(var(--size-radio) * 0.5);
    }

    &::before,
    &::after {
      border-radius: 50%;
      content: "";
      left: 0;
      position: absolute;
      top: 50%;
    }

    &::before {
      border: 1px solid var(--color-radio-border);
      height: var(--size-radio);
      margin-top: calc(var(--size-radio) * -0.5);
      transition: border-color 300ms ease, box-shadow 300ms ease;
      width: var(--size-radio);

      .radio-group--error & {
        border-color: var(--color-error);
      }

      .radio__input:checked + & {
        border-color: var(--color-radio-border-active);
      }

      .radio__input:focus + & {
        box-shadow: 0 0 0 2px var(--color-radio-box-shadow);
      }
    }

    &::after {
      background-color: var(--color-radio-background-active);
      opacity: 0;
      height: calc(var(--size-radio) * 0.5);
      margin: calc(var(--size-radio) * -0.5 * 0.5) 0 0
        calc(var(--size-radio) * 0.5 * 0.5);
      transition: opacity 300ms ease, transform 300ms ease;
      transform: scale(0);
      transform-origin: center;
      width: calc(var(--size-radio) * 0.5);

      .radio__input:checked + & {
        opacity: 1;
        transform: scale(1);
      }
    }

    .radio-group__item:not(:last-child) & {
      border-bottom: 1px solid var(--color-table-border);
    }
  }

  &-group {
    border: none;
    margin: 0;
    padding: 0;
    position: relative;
    transition: padding 300ms ease;

    &--error {
      padding-bottom: var(--spacing-error-message);
    }

    &__content {
      display: none;
      overflow: hidden;
    }

    &__group {
      @include reset-list();

      &--child {
        margin: calc(var(--spacing-stack) * 0.75) 0 0
          calc(var(--spacing-stack) * 0.75);
      }

      &-item {
        &:not(:last-child) {
          margin-bottom: calc(var(--spacing-stack) * 0.75);
        }
      }

      &-icon {
        fill: none;
        flex: 0 0 auto;
        height: var(--size-dropdown-icon);
        margin-left: 1rem;
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2;
        stroke: var(--color-text-primary);
        transition: transform 300ms ease;
        width: var(--size-dropdown-icon);

        .radio-group__group-toggle--active & {
          transform: rotate(180deg);
        }
      }

      &-title {
        flex: 1 1 auto;
        font-size: 1.25rem;
        margin: 0;
        text-align: left;
      }

      &-toggle {
        @include reset-button();

        align-items: center;
        display: flex;
        gap: 1rem;
        padding-bottom: 0.5rem;
        width: 100%;
      }
    }

    &__item {
      position: relative;

      &:hover {
        background-color: var(--color-table-background-hover);

        &::after,
        &::before {
          opacity: 1;
        }
      }

      .card & {
        padding: 0 var(--spacing-card);

        @media (min-width: 48rem) {
          padding: 0 var(--spacing-card-large);
        }
      }

      .modal & {
        padding: 0 var(--spacing-modal);

        @media (min-width: 48rem) {
          padding: 0 var(--spacing-modal-large);
        }
      }

      .sidebar & {
        padding: 0 var(--spacing-sidebar);

        @media (min-width: $sidebar-width-wide) {
          padding: 0 var(--spacing-sidebar-large);
        }
      }

      &::after {
        border-bottom: 1px solid var(--color-table-border);
        bottom: 0;
      }

      &::after,
      &::before {
        content: "";
        height: 1px;
        left: 0;
        opacity: 0;
        position: absolute;
        right: 0;
      }

      &::before {
        border-top: 1px solid var(--color-table-border);
        top: -1px;
      }
    }

    &__list {
      @include reset-list;

      background-color: #ffffff;
      position: relative;
      z-index: 2;

      .card & {
        margin-left: calc((var(--spacing-card) * -1));
        width: calc(100% + (var(--spacing-card) * 2));

        @media (min-width: 48rem) {
          margin-left: calc((var(--spacing-card-large) * -1));
          width: calc(100% + (var(--spacing-card-large) * 2));
        }
      }

      .delivery__address-list & {
        max-height: 50vh;
        overflow-y: auto;
      }

      .modal & {
        margin-left: calc((var(--spacing-modal) * -1));
        width: calc(100% + (var(--spacing-modal) * 2));

        @media (min-width: 48rem) {
          margin-left: calc((var(--spacing-modal-large) * -1));
          width: calc(100% + (var(--spacing-modal-large) * 2));
        }
      }

      .sidebar & {
        margin-left: calc((var(--spacing-sidebar) * -1));
        width: calc(100% + (var(--spacing-sidebar) * 2));

        @media (min-width: $sidebar-width-wide) {
          margin-left: calc((var(--spacing-sidebar-large) * -1));
          width: calc(100% + (var(--spacing-sidebar-large) * 2));
        }
      }
    }

    &__title {
      cursor: pointer;
      display: inline-block;
      font-size: 0.875rem;
      font-weight: 700;
      margin: 0 0 calc(var(--size-radio) * 0.25);
      width: 100%;

      &[required] {
        &::after {
          content: "*";
          margin-left: 0.125rem;
        }
      }
    }
  }
}
</style>
