<template>
  <div v-if="files.length" class="files">
    <ul class="files__list">
      <li v-for="file in files" :key="file.fileSource" class="files__item">
        <a :href="file.fileSource" target="_blank">{{ file.fileName }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ProductFiles",
  props: {
    files: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.files {
  &__item {
    &:not(:last-child) {
      margin-bottom: calc(var(--spacing-stack) * 1.5);
    }
  }

  &__list {
    list-style-type: disc;
    margin: 0;
    padding: 0 0 0 1.25rem;
  }
}
</style>
