import isEqual from "lodash.isequal";

const state = () => ({
  canEditSelectedDeliveryAddress: false,
  deliveryInformation: {
    attention: "",
    city: "",
    countryCode: "",
    email: "",
    name: "",
    name2: "",
    phone: "",
    parcelShopId: null,
    postalCode: "",
    primary: false,
    street: "",
    street2: "",
    type: "",
    validForCheckout: false,
  },
  isModalForAlternativeAddressOpen: false,
  isModalForSavedAddressesOpen: false,
  personalInformation: {
    comment: "",
    email: "",
    reference: "",
    requisitionNumber: "",
  },
  steps: {
    delivery: {
      isActive: false,
      isConfirmed: false,
    },
    information: {
      isActive: false,
      isConfirmed: false,
    },
    namesForPrint: {
      isActive: false,
      isConfirmed: false,
    },
  },
});

const getters = {
  canEditSelectedDeliveryAddress: state => state.canEditSelectedDeliveryAddress,
  deliveryInformation: state => state.deliveryInformation,
  personalInformation: state => state.personalInformation,
  isDeliveryAddressSelected: state => {
    const emptyDeliveryInformation = {
      attention: "",
      city: "",
      countryCode: "",
      email: "",
      name: "",
      name2: "",
      phone: "",
      parcelShopId: null,
      postalCode: "",
      primary: false,
      street: "",
      street2: "",
      type: "",
      validForCheckout: false,
    };

    return !isEqual(emptyDeliveryInformation, state.deliveryInformation);
  },
  isModalForAlternativeAddressOpen: state =>
    state.isModalForAlternativeAddressOpen,
  isModalForSavedAddressesOpen: state => state.isModalForSavedAddressesOpen,
  steps: state => state.steps,
};

const actions = {
  CLOSE_MODAL_FOR_ALTERNATIVE_ADDRESS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ALTERNATIVE_ADDRESS", { value: false });
  },
  CLOSE_MODAL_FOR_SAVED_ADDRESSES({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_SAVED_ADDRESSES", { value: false });
  },
  OPEN_MODAL_FOR_ALTERNATIVE_ADDRESS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ALTERNATIVE_ADDRESS", { value: true });
  },
  OPEN_MODAL_FOR_SAVED_ADDRESSES({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_SAVED_ADDRESSES", { value: true });
  },
  RESET_DELIVERY_INFORMATION({ commit }) {
    commit("SET_DELIVERY_INFORMATION", {
      information: {
        attention: "",
        city: "",
        countryCode: "",
        email: "",
        name: "",
        name2: "",
        phone: "",
        parcelShopId: null,
        postalCode: "",
        primary: false,
        street: "",
        street2: "",
        type: "",
        validForCheckout: false,
      },
    });
    commit("SET_STATE_OF_ABILITY_TO_EDIT_ADDRESS", {
      value: false,
    });
  },
  RESET_PERSONAL_INFORMATION({ commit }) {
    commit("SET_PERSONAL_INFORMATION", {
      information: {
        comment: "",
        email: "",
        reference: "",
        requisitionNumber: "",
      },
    });
  },
  RESET_STEPS({ commit }) {
    commit("SET_STEPS", {
      steps: {
        delivery: {
          isActive: false,
          isConfirmed: false,
        },
        information: {
          isActive: true,
          isConfirmed: false,
        },
        namesForPrint: {
          isActive: false,
          isConfirmed: false,
        },
      },
    });
  },
  UPDATE_DELIVERY_INFORMATION({ commit }, payload) {
    commit("SET_DELIVERY_INFORMATION", { information: payload.information });
    commit("SET_STATE_OF_ABILITY_TO_EDIT_ADDRESS", {
      value: payload.canEditSelectedDeliveryAddress,
    });
  },
  UPDATE_PERSONAL_INFORMATION({ commit }, payload) {
    commit("SET_PERSONAL_INFORMATION", { information: payload.information });
  },
  UPDATE_STEPS({ commit }, payload) {
    commit("SET_STEPS", { steps: payload.steps });
  },
};

const mutations = {
  SET_DELIVERY_INFORMATION(state, payload) {
    state.deliveryInformation = payload.information;
  },
  SET_PERSONAL_INFORMATION(state, payload) {
    state.personalInformation = payload.information;
  },
  SET_STATE_OF_ABILITY_TO_EDIT_ADDRESS(state, payload) {
    state.canEditSelectedDeliveryAddress = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_ALTERNATIVE_ADDRESS(state, payload) {
    state.isModalForAlternativeAddressOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_SAVED_ADDRESSES(state, payload) {
    state.isModalForSavedAddressesOpen = payload.value;
  },
  SET_STEPS(state, payload) {
    state.steps = payload.steps;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
