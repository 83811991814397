<template>
  <Container v-if="blockData" :small="true">
    <DynamicData :loading="loading">
      <div
        v-if="blockData.headline || blockData.description"
        class="block block--title"
        :class="{ 'block--indent-text': blockData.indentText }"
      >
        <div class="block__content">
          <div>
            <Heading
              v-if="blockData.headline"
              :tag="blockData.headlineTag"
              class="block__title block__title--with-line"
            >
              {{ blockData.headline }}
            </Heading>
            <p v-if="blockData.description" class="block__description">
              {{ blockData.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="block block--multiple-rows block--products">
        <div
          v-for="variant in variants"
          :key="variant.variantId"
          class="block__one-third block__media-container"
        >
          <div class="block__content block__content--product-image">
            <router-link
              :to="variantLink(variant)"
              class="block__product block__product--link"
            >
              <Stack class="block__product-image-container">
                <ProductImage :variant="variant" class="block__product-image" />
              </Stack>
              <Stack>
                <h2 v-if="variant.productName" class="block__product-title">
                  {{ variant.productName }}
                </h2>
                <h3
                  v-if="variant.minPrice && variant.minPrice.price"
                  class="block__product-price"
                >
                  {{ getDictionaryEntry("Product.PricesFrom") }}
                  {{ variant.minPrice.price }}
                  {{ variant.minPrice.currency }}
                </h3>
              </Stack>
              <!-- <div class="block__product-splash">
              Nyhed (TODO)
            </div> -->
            </router-link>
          </div>
        </div>
      </div>
    </DynamicData>
  </Container>
</template>

<script>
import Container from "@/components/Container";
import DynamicData from "@/components/DynamicData";
import Heading from "@/components/Heading";
import ProductImage from "@/components/ProductImage";
import Stack from "@/components/Stack";

export default {
  name: "ProductRowGridGridElement",
  components: {
    Container,
    DynamicData,
    Heading,
    ProductImage,
    Stack,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    variants: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    variantLink(variant) {
      if (!variant || !variant.productId || !variant.variantId) return null;

      return {
        query: {
          mode: "details",
          productId: variant.productId,
          variantId: variant.variantId,
        },
      };
    },
  },
};
</script>
