<template>
  <table class="compositions">
    <thead class="compositions__head">
      <th v-if="compositionsCanBeSplitted">
        {{ getDictionaryEntry("Product.CompositionPart") }}
      </th>
      <th>
        {{ getDictionaryEntry("Product.CompositionMaterials") }}
      </th>
    </thead>
    <tbody>
      <tr
        v-for="composition in compositions"
        :key="composition.description"
        class="composition"
      >
        <td v-if="compositionsCanBeSplitted" class="composition__description">
          {{ composition.description }}
        </td>
        <td class="composition__value">
          {{ composition.value }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: "Compositions",
  props: {
    compositions: {
      required: true,
      type: Array,
    },
  },
  computed: {
    compositionsCanBeSplitted() {
      const { compositions } = this;

      if (!compositions || !compositions.length) return false;

      return compositions.some(composition => composition.description);
    },
  },
};
</script>

<style lang="scss" scoped>
.composition {
  &__description {
    padding-right: 1rem;
  }
}

.compositions {
  border-collapse: collapse;

  &__head {
    @include screen-reader-only();
  }
}
</style>
