export const keysForInitialFacetFilterParams = [
  "brand",
  "category",
  "color",
  "feature",
  "fit",
  "gender",
  "series",
  "size",
];
