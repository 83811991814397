<template>
  <article
    v-if="blockData"
    class="block block--dashboard block--dashboard-hero block__media-container block__media-container--overlay"
  >
    <div class="block__100-percent">
      <ImageFromUmbraco
        v-if="blockData.image"
        :id="blockData.image._system.id"
        :alt="blockData.image.alternateText"
        :height="blockData.image.umbracoHeight"
        :keys-and-sizes="{
          s: 'block-regular-small',
          m: 'block-tall-large',
          l: 'block-tall-extra-large',
          xl: 'block-tall-extra-large',
        }"
        :width="blockData.image.umbracoWidth"
        class="block__image"
      />
      <div class="block__content block__content--bottom">
        <article class="block__content-text">
          <div v-if="blockData.caption" class="block__subtitle">
            {{ blockData.caption }}
          </div>
          <Heading
            v-if="blockData.headline"
            :tag="blockData.headlineTag"
            class="block__title"
          >
            {{ blockData.headline }}
          </Heading>
          <p v-if="blockData.description" class="block__description">
            {{ blockData.description }}
          </p>
          <ConditionalRouterLink
            v-if="blockData.cTA && blockData.cTA.name && blockData.cTA.url"
            :target="blockData.cTA.target"
            :to="blockData.cTA.url"
            :type="blockData.cTA.type"
            class="block__button"
            >{{ blockData.cTA.name }}</ConditionalRouterLink
          >
        </article>
      </div>
    </div>
  </article>
</template>

<script>
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "DashboardHeroGridElement",
  components: {
    ConditionalRouterLink,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>
