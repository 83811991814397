<template>
  <div class="previous-catalogues">
    <RadioGroup
      v-model.trim="previous.selected.$model"
      :label="getDictionaryEntry('ProductSheet.ExistingCatalogues')"
      :options="cataloguesAsOptions"
      name="catalogue"
    />
  </div>
</template>

<script>
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";
import RadioGroup from "@/components/RadioGroup";

export default {
  name: "CataloguesPrevious",
  components: {
    RadioGroup,
  },
  props: {
    previous: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters("catalogues", ["catalogues"]),
    cataloguesAsOptions() {
      const { catalogues } = this;

      if (!catalogues || !catalogues.length) return [];

      return catalogues.map(({ id, name }) => ({
        label: name,
        value: id,
      }));
    },
  },
  watch: {
    catalogues(newValue, oldValue) {
      if (isEqual(newValue, oldValue)) return;
      this.setSelected();
    },
  },
  created() {
    this.setSelected();
  },
  methods: {
    ...mapActions("catalogues", ["GET_CATALOGUES"]),
    setSelected() {
      const { catalogues } = this;

      if (catalogues && catalogues.length) {
        this.previous.selected.$model = catalogues[0].id;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.previous-catalogues {
  padding: var(--spacing-stack) var(--spacing-sidebar) 0;

  @media (min-width: 64rem) {
    padding: var(--spacing-stack) var(--spacing-sidebar-large) 0;
  }
}
</style>
