function getHeightOfElement(element) {
  /*
    Assumes that no necessary inline styling have been applied.
    Avoids having to get initial values and reset them afterwards.
*/

  let elementHeight = 0;

  element.style.position = "absolute";
  element.style.visibility = "hidden";
  element.style.display = "block";
  element.style.height = "auto";
  element.style.width = element.parentNode.offsetWidth + "px";

  elementHeight = element.offsetHeight;

  element.removeAttribute("style");

  return elementHeight;
}

export function slideDown(element, callback, duration = 200) {
  if (!element) return;
  function easing(
    currentIteration,
    startValue,
    changeInValue,
    totalIterations,
  ) {
    return (
      changeInValue *
        (Math.pow(currentIteration / totalIterations - 1, 3) + 1) +
      startValue
    );
  }

  let currentIteration = 0;
  const totalIterations = (duration / 1000) * 60;

  const startHeight = 0;
  let currentHeight = startHeight;
  const endHeight = getHeightOfElement(element);
  const heightDifference = endHeight - startHeight;

  function animate() {
    currentHeight = easing(
      currentIteration,
      startHeight,
      heightDifference,
      totalIterations,
    );
    element.style.height = currentHeight + "px";

    currentIteration++;

    if (currentIteration <= totalIterations) {
      window.requestAnimationFrame(animate);
    } else {
      element.style.height = "auto";

      if (callback) {
        callback();
      }
    }
  }

  element.style.display = "block";
  window.requestAnimationFrame(animate);
}

export function slideUp(element, callback, duration = 200) {
  if (!element) return;
  function easing(
    currentIteration,
    startValue,
    changeInValue,
    totalIterations,
  ) {
    return (
      changeInValue *
        (Math.pow(currentIteration / totalIterations - 1, 3) + 1) +
      startValue
    );
  }

  let currentIteration = 0;
  const totalIterations = (duration / 1000) * 60;

  const startHeight = element.offsetHeight;
  let currentHeight = startHeight;
  const endHeight = 0;
  const heightDifference = startHeight - endHeight;

  function animate() {
    currentHeight = easing(
      currentIteration,
      startHeight,
      -heightDifference,
      totalIterations,
    );
    element.style.height = currentHeight + "px";

    currentIteration++;

    if (currentIteration <= totalIterations) {
      window.requestAnimationFrame(animate);
    } else {
      element.style.display = "none";

      if (callback) {
        callback();
      }
    }
  }

  window.requestAnimationFrame(animate);
}
