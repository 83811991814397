import { alertTypes } from "@/helpers/alertTypes";
import { uuid } from "@/helpers/uuid";

const state = () => ({
  alerts: [],
});

const getters = {
  alerts: state => state.alerts,
};

const actions = {
  CLOSE_ALERT({ commit }, payload) {
    commit("REMOVE_ALERT", { id: payload.id });
  },
  OPEN_ALERT({ commit, rootGetters }, payload) {
    const { data, key, type } = payload;
    const dictionaryEntries = rootGetters["siteSettings/dictionaryEntries"];

    let dictionaryKey;

    if (payload && key) {
      dictionaryKey = key;
    }

    commit("SET_ALERT", {
      data: data || null,
      id: uuid(),
      message: dictionaryEntries[dictionaryKey],
      type: type || alertTypes.ERROR,
    });
  },
  PAUSE_ALERT({ commit }, payload) {
    commit("SET_PAUSED_STATE_OF_ALERT", {
      id: payload.id,
      isPaused: true,
    });
  },
  RESUME_ALERT({ commit }, payload) {
    commit("SET_PAUSED_STATE_OF_ALERT", {
      id: payload.id,
      isPaused: false,
    });
  },
};

const mutations = {
  REMOVE_ALERT(state, payload) {
    state.alerts = state.alerts.filter(alert => alert.id !== payload.id);
  },
  SET_ALERT(state, payload) {
    const { data, id, message, type } = payload;

    if (state.alerts.some(alert => alert.id === id)) {
      return;
    }

    state.alerts.push({ data, id, isPaused: false, message, type });
  },
  SET_PAUSED_STATE_OF_ALERT(state, payload) {
    const indexOfAffectedAlert = state.alerts.findIndex(
      alert => alert.id === payload.id,
    );

    if (indexOfAffectedAlert === -1) return;

    Object.assign(state.alerts[indexOfAffectedAlert], {
      isPaused: payload.isPaused,
    });
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
