<template>
  <img
    v-if="image"
    :attrs="$attrs"
    :data-src="src"
    :data-srcset="srcset"
    :height="image.height"
    :width="image.width"
    class="lazyload"
    data-sizes="auto"
    decoding="async"
    loading="lazy"
    v-bind="$attrs"
  />
</template>

<script>
export default {
  name: "ProductImage",
  props: {
    variant: {
      required: true,
      type: Object,
    },
  },
  computed: {
    image() {
      const { variant } = this;

      if (!variant || !variant.images) return null;

      return variant.images[0];
    },
    src() {
      const { image } = this;

      if (!image) return null;

      const isDisplayHighDensity = window.devicePixelRatio > 1.5;

      return isDisplayHighDensity ? image.large.src : image.thumbnail.src;
    },
    srcset() {
      const { image } = this;

      if (!image) return null;

      const isDisplayHighDensity = window.devicePixelRatio > 1.5;
      const largeImage = image.large;
      const thumbnailImage = image.thumbnail;

      const parts = [];

      if (thumbnailImage) {
        parts.push(
          `${thumbnailImage.src} ${
            isDisplayHighDensity
              ? thumbnailImage.width * 2
              : thumbnailImage.width
          }w`,
        );
      }

      if (largeImage) {
        parts.push(
          `${largeImage.src} ${
            isDisplayHighDensity ? largeImage.width * 2 : largeImage.width
          }w`,
        );
      }

      if (!parts.length) return null;

      return parts.join(",");
    },
  },
};
</script>
