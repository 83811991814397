<template>
  <div class="stock__indicator" :class="`stock__indicator--${status}`"></div>
</template>
<script>
export default {
  name: "StockIndicatorBar",
  props: {
    status: {
      type: String,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.stock {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-right: 1rem;

  &__indicator {
    border-radius: 0;
    bottom: 0;
    flex: 0 0 auto;
    height: var(--size-stock-indicator-in-size-matrix);
    left: 0;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;

    &--green {
      background-color: var(--color-stock-indicator-green);
    }

    &--grey {
      background-color: var(--color-stock-indicator-grey);
    }

    &--orange {
      background-color: var(--color-stock-indicator-orange);
    }

    &--red {
      background-color: var(--color-stock-indicator-red);
    }
  }
}
</style>
