import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  brand: null,
  brands: [],
  divisions: [],
  status: "",
});

const getters = {
  brand: state => state.brand,
  brands: state => state.brands,
  canonicalWebsiteUrl: state =>
    state.brand ? state.brand.canonicalWebsiteUrl : null,
  contentRoot: state => (state.brand ? state.brand.contentRoot : null),
  divisionId: state =>
    state.brand ? state.brand?.divisionId?.toLowerCase() : null,
  divisions: state => state.divisions,
  productFallbackImage: state =>
    state.brand ? state.brand.productFallbackImage : "",
  shopMoreLink: state => (state.brand ? state.brand.shopMoreLink : ""),
  statusOfBranding: state => state.status,
};

const actions = {
  GET_BRANDS({ commit }) {
    commit("SET_BRANDING_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/brand`)
      .then(({ data }) => {
        commit("SET_BRANDING_STATUS", { status: statuses.SUCCESS });

        const matchingBrand = data.find(brand =>
          brand.hostNames.includes(window.location.host),
        );

        if (matchingBrand) {
          commit("SET_BRAND", { brand: matchingBrand });
        }

        commit("SET_BRANDS", { brands: data });
      })
      .catch(error => {
        commit("SET_BRANDING_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_BRAND_BY_DIVISION({ commit }, payload) {
    commit("SET_BRANDING_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.ECOMMERCE}/brand/bydivision/${payload.division}`)
        .then(({ data }) => {
          commit("SET_BRANDING_STATUS", { status: statuses.SUCCESS });
          resolve(data);
        })
        .catch(error => {
          commit("SET_BRANDING_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  GET_DIVISIONS({ commit }) {
    commit("SET_BRANDING_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/brand/divisionscodes`)
      .then(({ data }) => {
        commit("SET_BRANDING_STATUS", { status: statuses.SUCCESS });
        commit("SET_DIVISIONS", { divisions: data });
      })
      .catch(error => {
        commit("SET_BRANDING_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_BRAND(state, payload) {
    state.brand = payload.brand;

    const {
      divisionId,
      favicon,
      pageTitle,
      primaryColor,
      secondaryColor,
    } = payload.brand;

    const colors = {
      primary: primaryColor,
      secondary: secondaryColor,
    };

    const variables = {};

    for (const [key, value] of Object.entries(colors)) {
      variables[`--theme-${key}`] = value;
    }

    for (const [property, value] of Object.entries(variables)) {
      document.documentElement.style.setProperty(property, value);
    }

    document.getElementById("favicon").href = favicon;
    document.title = pageTitle;

    axios.defaults.headers.common["UI-Division-Code"] = divisionId;
  },
  SET_BRANDS(state, payload) {
    state.brands = payload.brands;
  },
  SET_BRANDING_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_DIVISIONS(state, payload) {
    state.divisions = payload.divisions;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
