<template>
  <DynamicData
    class="basket-line-list"
    :class="{ 'basket-line-list--read-only': readOnly }"
    :loading="isLoading"
  >
    <transition-group
      tag="ul"
      class="basket-line-list__list"
      name="fade-slide"
      appear=""
    >
      <li
        v-for="(basketLine, index) in groupedBasketLines"
        :key="basketLine.id"
        class="basket-line-list__item"
        :style="{ '--index': index }"
      >
        <article v-if="basketLine.removable" class="product">
          <Stack class="product__information">
            <div v-if="basketLine.image" class="product__image-container">
              <img
                :src="basketLine.image.src"
                :alt="basketLine.image.alt"
                class="product__image"
                decoding="async"
                loading="lazy"
              />
            </div>
            <div class="product__text">
              <router-link
                class="product__link"
                :to="{
                  query: {
                    mode: 'details',
                    productId: basketLine.productId,
                    variantId: basketLine.variantId,
                  },
                }"
              >
                <Stack :quarter="true">
                  <h1 class="product__title">{{ basketLine.productName }}</h1>
                </Stack>
                <Stack :quarter="true">
                  <InformationList :information="basketLine" />
                </Stack>
                <Stack v-if="basketLine.swatch" :quarter="true">
                  <div class="product__swatch">
                    <img
                      v-if="basketLine.swatch.src"
                      :src="basketLine.swatch.src"
                      :alt="basketLine.swatch.alt"
                      class="product__swatch-image"
                      height="50"
                      width="50"
                    />
                    <span class="product__swatch-text">{{
                      basketLine.swatch.alt
                    }}</span>
                  </div>
                </Stack>
                <Stack :quarter="true">
                  {{ getDictionaryEntry("Common.Labels.Size") }}:
                  {{ getPreferredSizeChart(basketLine.sizeChart) }}
                </Stack>
              </router-link>
            </div>
            <div class="product__numbers">
              <Stack
                v-if="!readOnly"
                class="product__numbers-stack"
                :half="true"
              >
                <Counter :sku="basketLine.sku" />
              </Stack>
              <Stack :half="true" class="product__numbers-stack">
                <div v-if="basketLine.unitPrice" class="product__price">
                  {{ basketLine.quantity }}×{{
                    formatPriceCurrency(basketLine.unitPrice.price)
                  }}
                  {{ basketLine.unitPrice.currency }}
                </div>
                <div
                  v-if="basketLine.totalLinePrice"
                  class="product__price product__price--sum"
                >
                  <span
                    v-if="basketLine.totalLinePrice.isDiscounted"
                    class="product__price product__price--previous"
                    >{{
                      formatPriceCurrency(basketLine.totalLinePrice.normalPrice)
                    }}
                    {{ basketLine.totalLinePrice.currency }}</span
                  >
                  {{ formatPriceCurrency(basketLine.totalLinePrice.price) }}
                  {{ basketLine.totalLinePrice.currency }}
                </div>
              </Stack>
            </div>
          </Stack>
          <Stack :half="true">
            <ol class="product__references">
              <li
                v-for="reference in basketLine.references"
                :key="basketLine.id + reference.id"
                class="product__reference"
              >
                + {{ reference.sku }} {{ reference.productName }}
              </li>
            </ol>
          </Stack>
        </article>

        <article v-else class="product">
          <div v-if="basketLine.image" class="product__icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="product__icon"
              viewBox="0 0 24 24"
            >
              <path
                d="m16.5 9.4-9-5.19M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
              />
              <path d="M3.27 6.96 12 12.01l8.73-5.05M12 22.08V12" />
            </svg>
          </div>
          <div class="product__text">
            <div>
              <Stack :quarter="true">
                <h1 class="product__title">{{ basketLine.productName }}</h1>
              </Stack>
              <Stack :quarter="true">
                <InformationList :information="basketLine" />
              </Stack>
            </div>
          </div>
          <div class="product__numbers">
            <Stack :half="true" class="product__numbers-stack">
              <div
                v-if="basketLine.totalLinePrice"
                class="product__price product__price--sum"
              >
                {{ formatPriceCurrency(basketLine.totalLinePrice.price) }}
                {{ basketLine.totalLinePrice.currency }}
              </div>
            </Stack>
          </div>
        </article>
      </li>
    </transition-group>
  </DynamicData>
</template>

<script>
import { mapGetters } from "vuex";
import Counter from "@/components/Counter";
import DynamicData from "@/components/DynamicData";
import InformationList from "@/components/InformationList";
import Stack from "@/components/Stack";
import { statuses } from "@/helpers/statuses";

export default {
  name: "BasketLineListForEndCustomers",
  components: {
    Counter,
    DynamicData,
    InformationList,
    Stack,
  },
  props: {
    basketLines: {
      required: true,
      type: Array,
    },
    readOnly: {
      defualt: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("basket", ["statusOfBasket"]),
    groupedBasketLines() {
      const { basketLines } = this;

      if (!basketLines || !basketLines.length) return [];

      const nonReferenceLines = basketLines.filter(
        basketLine => !basketLine.referenceId,
      );
      const referenceLines = basketLines.filter(
        basketLine => basketLine.referenceId,
      );

      const linesWithGroupedReferences = nonReferenceLines.map(line => ({
        ...line,
        references: referenceLines.filter(
          referenceLine => referenceLine.referenceId === line.id,
        ),
      }));

      return linesWithGroupedReferences
        .map(basketLine => {
          return {
            ...basketLine,
            sortableSku: parseFloat(basketLine.sku.replace(/\D/g, "")),
          };
        })
        .sort((a, b) => a.sortableSku - b.sortableSku)
        .sort((a, b) => b.removable - a.removable);
    },
    isLoading() {
      if (this.readOnly) return false;

      return this.statusOfBasket === statuses.LOADING;
    },
  },
};
</script>

<style lang="scss" scoped>
.basket-line-list {
  &__item {
    position: relative;

    .receipt & {
      padding: calc(var(--spacing-stack) * 0.5) 0;
    }

    .receipt &,
    .sidebar & {
      border-bottom: 1px solid rgba(0, 0, 0, 0.075);
    }

    &:first-child {
      .receipt & {
        border-top: 1px solid rgba(0, 0, 0, 0.075);
      }
    }

    &:hover {
      background-color: var(--color-table-background-hover);

      .receipt & {
        background-color: transparent;
      }

      &::after,
      &::before {
        opacity: 1;
      }
    }

    &:last-child {
      .receipt & {
        margin-bottom: calc(var(--spacing-stack) * 0.75);
      }
    }

    &::after {
      border-bottom: 1px solid var(--color-table-border);
      bottom: -1px;
    }

    &::after,
    &::before {
      content: "";
      height: 1px;
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;

      .receipt &,
      .sidebar & {
        display: none;
      }
    }

    &::before {
      border-top: 1px solid var(--color-table-border);
      top: 0;
    }
  }

  &__list {
    @include reset-list();

    .card & {
      margin-left: calc(var(--spacing-card) * -1);
      width: calc(100% + var(--spacing-card) * 2);

      @media (min-width: 48rem) {
        margin-left: calc(var(--spacing-card-large) * -1);
        width: calc(100% + var(--spacing-card-large) * 2);
      }
    }
  }
}

.fade-slide {
  &-enter-active {
    transition-delay: calc(50ms * var(--index)) !important;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease, transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.product {
  color: inherit;
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
  text-decoration: none;

  .basket-line-list--read-only & {
    font-size: 0.8125rem;
  }

  .card .basket-line-list__item:first-child & {
    &::before {
      border-top: 1px solid var(--color-table-border);
      content: "";
      height: 1px;
      left: var(--spacing-card);
      position: absolute;
      right: var(--spacing-card);
      top: 0;

      @media (min-width: 48rem) {
        left: var(--spacing-card-large);
        right: var(--spacing-card-large);
      }
    }
  }

  .card & {
    padding: calc(var(--spacing-stack) * 0.75) var(--spacing-card);

    @media (min-width: 48rem) {
      padding-left: var(--spacing-card-large);
      padding-right: var(--spacing-card-large);
    }

    &::after {
      border-bottom: 1px solid var(--color-table-border);
      bottom: -1px;
      content: "";
      height: 1px;
      left: var(--spacing-card);
      position: absolute;
      right: var(--spacing-card);

      @media (min-width: 48rem) {
        left: var(--spacing-card-large);
        right: var(--spacing-card-large);
      }
    }
  }

  .sidebar & {
    padding: calc(var(--spacing-stack) * 0.75) var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding-left: var(--spacing-sidebar-large);
      padding-right: var(--spacing-sidebar-large);
    }
  }

  &__icon {
    fill-rule: evenodd;
    fill: none;
    height: 3rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke: var(--color-text-primary);
    width: 3rem;

    &-container {
      display: flex;
      flex: 0 0 5rem;
      height: 3rem;
      justify-content: center;

      .basket-line-list--read-only & {
        flex-basis: 4rem;
      }
    }
  }

  &__image {
    display: block;
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &-container {
      background-color: var(--color-products-image-background);
      flex: 0 0 5rem;
      position: relative;

      .basket-line-list--read-only & {
        flex-basis: 4rem;
      }

      &::before {
        content: "";
        display: block;
        padding-top: calc(1 / 1) * 100%;
        width: 100%;
      }
    }
  }

  &__information {
    align-items: flex-start;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    @media (min-width: 30rem) {
      flex-flow: nowrap;
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;
  }

  &__numbers {
    align-items: center;
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    margin-top: calc(var(--spacing-stack) * 0.5);
    order: 1;
    text-align: right;

    @media (min-width: 30rem) {
      display: block;
      flex: 0 0 auto;
      margin: 0 0 0 auto;
      order: 0;
    }

    &-stack {
      @media (max-width: 29.9375rem) {
        margin: 0 !important;
      }
    }
  }

  &__price {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 500;

    &--previous {
      color: var(--color-error);
      display: block;
      margin-bottom: calc(var(--spacing-stack) * 0.1);
      text-decoration: line-through;

      .checkout & {
        @media (min-width: 48rem) {
          display: inline;
          margin: 0 0.5rem 0 0;
        }
      }

      .sidebar & {
        display: none;
      }
    }

    &--sum {
      color: var(--color-text);
      display: block;
      font-size: 0.875rem;
      font-weight: 700;

      @media (min-width: 23.4375rem) {
        font-size: 1rem;
      }
    }
  }

  &__reference {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__references {
    @include reset-list();

    font-size: 0.8125rem;
    margin-top: var(--spacing-stack);

    @media (min-width: 30rem) {
      margin: 0 0 0 calc(5rem + var(--spacing-stack));
    }

    .basket-line-list--read-only & {
      @media (min-width: 30rem) {
        margin: 0 0 0 calc(4rem + var(--spacing-stack) * 0.5);
      }
    }
  }

  &__size {
    text-align: center;

    &:not(:last-child) {
      margin: 0 0.5rem 0.75rem 0;
      padding-right: 0.5rem;
      position: relative;

      &::after {
        background-color: rgba(0, 0, 0, 0.075);
        bottom: 0;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }
    }

    &-title {
      font-weight: 700;
    }
  }

  &__sizes {
    @include reset-list();

    display: flex;
    flex-flow: row wrap;
    position: relative;
  }

  &__swatch {
    align-items: center;
    display: flex;

    &-image {
      border-radius: calc(var(--size-product-swatch) * 0.2);
      height: var(--size-product-swatch);
      margin-right: calc(var(--size-product-swatch) * 0.35);
      width: var(--size-product-swatch);
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__text {
    flex-basis: calc(100% - 5.5rem);
    flex-grow: 0;
    flex-shrink: 0;
    overflow: hidden;

    @media (min-width: 30rem) {
      flex: 1 1 auto;
      margin: 0 var(--spacing-stack);

      .basket-line-list--read-only & {
        margin: 0 calc(var(--spacing-stack) * 0.5);
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  &__title {
    font-size: 1rem;
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .basket-line-list--read-only & {
      font-size: 0.875rem;
    }
  }
}
</style>
