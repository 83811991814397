<template>
  <ol
    v-if="sizesWithQuantities"
    class="sizes"
    :class="{
      'sizes--desktop-only': desktopOnly,
      'sizes--mobile-only': mobileOnly,
    }"
  >
    <li
      v-for="{ quantity, size } in uniqueSizesWithCombinedQuantities"
      :key="size"
      class="size"
    >
      <div class="size__title">
        {{ size }}
      </div>
      <div class="size__quantity">
        {{ quantity }}
      </div>
    </li>
  </ol>
</template>

<script>
export default {
  name: "BasketLineSizes",
  props: {
    desktopOnly: {
      default: false,
      type: Boolean,
    },
    mobileOnly: {
      default: false,
      type: Boolean,
    },
    sizesWithQuantities: {
      required: true,
      type: Array,
    },
  },
  computed: {
    uniqueSizesWithCombinedQuantities() {
      const { sizesWithQuantities } = this;

      if (!sizesWithQuantities || !sizesWithQuantities.length) return [];

      const uniqueAndCombined = [];

      sizesWithQuantities.forEach(({ quantity, size }) => {
        const indexOfMatchingEntry = uniqueAndCombined.findIndex(
          entry => entry.size === size,
        );

        if (indexOfMatchingEntry !== -1) {
          uniqueAndCombined[indexOfMatchingEntry].quantity += quantity;
        } else {
          uniqueAndCombined.push({ quantity, size });
        }
      });

      return uniqueAndCombined;
    },
  },
};
</script>

<style lang="scss" scoped>
.size {
  margin-bottom: 0.75rem;
  text-align: center;
  text-transform: uppercase;

  &:not(:last-child) {
    margin-right: 0.5rem;
    padding-right: 0.5rem;
    position: relative;

    &::after {
      background-color: rgba(0, 0, 0, 0.075);
      bottom: 0;
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      width: 1px;
    }
  }

  &__title {
    font-weight: 700;
  }
}

.sizes {
  @include reset-list();

  display: flex;
  flex-flow: row wrap;
  margin-bottom: -0.75rem;
  position: relative;

  .customers & {
    margin-top: 0;
  }

  &--desktop-only {
    display: none;

    @media (min-width: 48rem) {
      display: flex;
    }
  }

  &--mobile-only {
    @media (min-width: 48rem) {
      display: none;
    }
  }
}
</style>
