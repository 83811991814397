<template>
  <div class="page">
    <transition :name="transitionName" :mode="mode">
      <slot></slot>
    </transition>
    <div class="overlay"></div>
  </div>
</template>

<script>
export default {
  name: "PageTransition",
  props: {
    mode: {
      default: "out-in",
      type: String,
    },
  },
  data() {
    return {
      pageTransitionDuration: parseInt(
        getComputedStyle(document.documentElement).getPropertyValue(
          "--transition-sliding-overlay",
        ),
      ),
      transitionName: "slide-right",
    };
  },
  watch: {
    $route(to, from) {
      const isFirstRouteAfterLogin = from.path
        .toLowerCase()
        .includes("callback");

      if (isFirstRouteAfterLogin) {
        window.setTimeout(() => {
          this.$emit("updateWaitingState", false);
        }, this.pageTransitionDuration);
      }

      this.transitionName = isFirstRouteAfterLogin
        ? "slide-right"
        : "fade-slide";
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay {
  background-color: var(--theme-secondary);
  bottom: 0;
  height: 100vh;
  left: 0;
  opacity: 0;
  position: fixed;
  right: 0;
  top: 0;
  transform: translateX(-100%);
  transition-duration: var(--transition-sliding-overlay);
  transition-property: transform;
  width: 100vw;
  z-index: 99;
}

.page {
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
}

.slide-right-leave-active ~ .overlay,
.slide-right-leave-to ~ .overlay {
  opacity: 1;
  transform: translateX(0);
}

.slide-right-enter-active ~ .overlay,
.slide-right-enter-to ~ .overlay {
  opacity: 1;
  transform: translateX(100%);
}

.slide-right-enter-active ~ .overlay {
  transition-timing-function: ease-in;
}

.slide-right-leave-active ~ .overlay {
  transition-timing-function: ease-out;
}

.slide-right-enter-active,
.slide-right-leave-active {
  transition-duration: var(--transition-sliding-overlay);
}
</style>
