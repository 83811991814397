<template>
  <nav class="actions">
    <ul class="actions__list">
      <li
        v-if="hasAccessToOrderNotifications"
        class="actions__item actions__item--notifications"
      >
        <button
          class="actions__button"
          :aria-label="getDictionaryEntry('Navigation.Notifications')"
          @click="openNotifications"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="actions__icon"
            viewBox="0 0 24 24"
          >
            <path
              d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 0 1-3.46 0"
            />
          </svg>
          <span
            v-if="numberOfOrdersForApproval"
            class="actions__button-count"
            >{{ numberOfOrdersForApproval }}</span
          >
        </button>
        <transition name="fade-scale">
          <div v-if="isNotificationsOpen" class="popout">
            <ul class="popout__list">
              <li class="popout__item" @click="closeNotifications">
                <router-link class="popout__action" :to="{ name: 'orders' }">
                  {{ getDictionaryEntry("Notifications.OrdersForApproval") }}
                  <span
                    v-if="numberOfOrdersForApproval"
                    class="popout__action-count"
                    >({{ numberOfOrdersForApproval }})</span
                  >
                </router-link>
              </li>
            </ul>
            <div class="popout__underlay" @click="closeNotifications"></div>
          </div>
        </transition>
      </li>
      <li class="actions__item actions__item--profile">
        <button
          class="actions__button"
          :aria-label="getDictionaryEntry('Navigation.Profile')"
          @click="openProfile"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="actions__icon"
            viewBox="0 0 24 24"
          >
            <title>{{ getDictionaryEntry("Navigation.Profile") }}</title>
            <defs />
            <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2" />
            <circle cx="12" cy="7" r="4" />
          </svg>
        </button>
        <transition name="fade-scale">
          <div v-if="isProfileOpen" class="popout">
            <ul class="popout__list">
              <li class="popout__item" @click="closeProfile">
                <router-link class="popout__action" :to="{ name: 'profile' }">{{
                  getDictionaryEntry("Navigation.MyProfile")
                }}</router-link>
              </li>
              <li
                v-if="!isUserSystemadministrator"
                class="popout__item"
                @click="closeProfile"
              >
                <router-link
                  class="popout__action"
                  :to="{ name: 'profile.orders' }"
                  >{{ getDictionaryEntry("Navigation.MyOrders") }}</router-link
                >
              </li>
              <li
                v-if="administrationLink"
                class="popout__item"
                @click="closeProfile"
              >
                <router-link
                  class="popout__action"
                  :to="administrationLink.path"
                  >{{
                    getDictionaryEntry("Navigation.Administration")
                  }}</router-link
                >
              </li>
              <li class="popout__item">
                <button class="popout__action" @click="LOGOUT">
                  {{ getDictionaryEntry("Common.Logout") }}
                </button>
              </li>
            </ul>
            <div class="popout__underlay" @click="closeProfile"></div>
          </div>
        </transition>
      </li>
      <li
        v-if="hasAccessToProductSheet && !onBehalfUserName"
        class="actions__item actions__item--catalogue"
      >
        <button
          class="actions__button actions__button--catalogue"
          :aria-label="getDictionaryEntry('Common.Catalogue')"
          @click="openCatalogues"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="actions__icon"
            viewBox="0 0 24 24"
          >
            <path
              d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2zM22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"
            />
          </svg>
          <span v-if="numberOfItemsInCatalogue" class="actions__button-count">{{
            numberOfItemsInCatalogue
          }}</span>
        </button>
      </li>
      <li v-if="hasAccessToShop" class="actions__item actions__item--basket">
        <button
          class="actions__button actions__button--basket"
          :aria-label="getDictionaryEntry('Common.Basket')"
          @click="openQuickBasket"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="actions__icon"
            viewBox="0 0 24 24"
          >
            <title>{{ getDictionaryEntry("Common.Basket") }}</title>
            <defs />
            <circle cx="9" cy="21" r="1" />
            <circle cx="20" cy="21" r="1" />
            <path
              d="M1 1h4l2.68 13.39a2 2 0 002 1.61h9.72a2 2 0 002-1.61L23 6H6"
            />
          </svg>
          <span v-if="numberOfItemsInBasket" class="actions__button-count">{{
            numberOfItemsInBasket
          }}</span>
        </button>
      </li>
    </ul>
  </nav>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NavigationActions",
  props: {
    closeNotifications: {
      required: true,
      type: Function,
    },
    closeProfile: {
      required: true,
      type: Function,
    },
    isNotificationsOpen: {
      default: false,
      type: Boolean,
    },
    isProfileOpen: {
      default: false,
      type: Boolean,
    },
    openCatalogues: {
      required: true,
      type: Function,
    },
    openNotifications: {
      required: true,
      type: Function,
    },
    openProfile: {
      required: true,
      type: Function,
    },
    openQuickBasket: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters("authentication", [
      "customerId",
      "isUserSystemadministrator",
      "onBehalfUserName",
    ]),
    ...mapGetters("basket", ["numberOfItemsInBasket"]),
    ...mapGetters("catalogues", ["numberOfItemsInCatalogue"]),
    ...mapGetters("orders", ["numberOfOrdersForApproval"]),
    administrationLink() {
      const {
        customerId,
        hasAccessToCustomer,
        hasAccessToCustomerAddresses,
        hasAccessToCustomerGroups,
        hasAccessToCustomerOrders,
        hasAccessToCustomerPrices,
        hasAccessToCustomerRoles,
        hasAccessToCustomerUsers,
        hasAccessToCustomerWardrobes,
        hasAccessToEndCustomers,
        hasAccessToExternalProducts,
        hasAccessToSalespersons,
        isUserSystemadministrator,
      } = this;

      if (isUserSystemadministrator || !customerId) {
        return {
          path: { name: "customers" },
        };
      } else if (hasAccessToCustomer) {
        return {
          path: {
            name: "customer.details",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToCustomerUsers) {
        return {
          path: {
            name: "customer.users",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToCustomerRoles) {
        return {
          path: {
            name: "customer.roles",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToCustomerGroups) {
        return {
          path: {
            name: "customer.userGroups",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToCustomerPrices) {
        return {
          path: {
            name: "customer.products",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToCustomerWardrobes) {
        return {
          path: {
            name: "customer.wardrobes",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToCustomerAddresses) {
        return {
          path: {
            name: "customer.addresses",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToCustomerOrders) {
        return {
          path: {
            name: "customer.orders",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToSalespersons) {
        return {
          path: {
            name: "customer.salespersons",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToEndCustomers) {
        return {
          path: {
            name: "customer.endCustomers",
            params: {
              customerId,
            },
          },
        };
      } else if (hasAccessToExternalProducts) {
        return {
          path: {
            name: "customer.externalProducts",
            params: {
              customerId,
            },
          },
        };
      } else {
        return null;
      }
    },
  },
  created() {
    if (this.hasAccessToOrderNotifications) {
      this.GET_NUMBER_OF_ORDERS_FOR_APPROVAL();
    }
  },
  methods: {
    ...mapActions("authentication", ["LOGOUT"]),
    ...mapActions("orders", ["GET_NUMBER_OF_ORDERS_FOR_APPROVAL"]),
  },
};
</script>

<style lang="scss" scoped>
$breakpoint-xsmall: 23.4375rem;
$breakpoint-small: 40rem;
$breakpoint-large: 64rem;

.actions {
  &__button {
    @include reset-button;

    display: block;
    padding: var(--spacing-header-actions);
    position: relative;
    text-align: center;
    text-decoration: none;

    &--menu {
      margin-left: calc(var(--spacing-container) * 0.75);

      @media (min-width: $breakpoint-large) {
        display: none;
      }
    }

    &-count {
      align-items: center;
      background-color: var(--theme-primary);
      border-radius: 3.125rem;
      color: var(--color-header-background);
      display: flex;
      font-size: 0.6785rem;
      font-weight: 700;
      justify-content: center;
      min-width: 1.3125rem;
      padding: 0.25rem;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__icon {
    fill: none;
    height: var(--size-header-action-icon);
    opacity: 0.85;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    width: var(--size-header-action-icon);

    * {
      stroke: var(--color-header-text);
    }
  }

  &__item {
    position: relative;
  }

  &__list {
    @include reset-list;

    align-items: center;
    display: flex;
    justify-content: space-evenly;

    @media (min-width: $breakpoint-xsmall) {
      justify-content: flex-start;
    }
  }
}

.popout {
  background-color: var(--color-popout-background);
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  box-shadow: var(--elevation-8);
  left: var(--spacing-container);
  min-width: 12rem;
  position: fixed;
  right: var(--spacing-container);
  top: var(--spacing-container);
  z-index: 98;

  @media (min-width: $breakpoint-small) {
    box-shadow: var(--elevation-8);
    left: auto;
    position: absolute;
    transform-origin: top right;
  }

  &__action {
    @include reset-button();

    background-color: transparent;
    color: var(--color-text-primary);
    display: block;
    padding: var(--spacing-header-actions);
    text-align: left;
    text-decoration: none;
    transition: background-color 300ms ease;
    width: 100%;

    &:focus,
    &:hover {
      background-color: rgba(0, 0, 0, 0.05);
    }

    &-count {
      opacity: 0.4;
    }
  }

  &__item {
    &:not(:last-child) {
      border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    }
  }

  &__list {
    @include reset-list();
  }

  &__underlay {
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;
  }
}
</style>
