<template>
  <div v-if="blockData && blockData.scripts" v-html="blockData.scripts"></div>
</template>

<script>
export default {
  name: "ScriptGridElement",
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>
