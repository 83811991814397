<template>
  <div
    v-if="blockData && blockData.size"
    class="block block--spacer"
    :class="`block--spacer-${blockData.size.toLowerCase().replace(/ /g, '-')}`"
  ></div>
</template>

<script>
export default {
  name: "SpacingGridElement",
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>
