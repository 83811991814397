<template>
  <div
    v-if="
      substitutionVariantsForCurrentVariant &&
        substitutionVariantsForCurrentVariant.length
    "
    class="substitutes"
  >
    <Stack>
      <p class="substitutes__body-text">
        {{ getDictionaryEntry("Product.Substitutes.BodyText") }}
      </p>
      <h3 class="substitutes__title">
        {{ getDictionaryEntry("Product.Substitutes.Title") }}
      </h3>
    </Stack>
    <Stack>
      <Flicking
        :options="options"
        :plugins="plugins"
        class="slider slider--products slider--short"
      >
        <div
          v-for="variant in substitutionVariantsForCurrentVariant"
          :key="variant.primary.variantId"
          class="substitutes__item slide slide--small"
        >
          <article>
            <Stack>
              <div class="block__product-image-container">
                <ProductImage
                  :variant="variant.primary"
                  class="block__product-image"
                />
              </div>
            </Stack>
            <Stack class="substitutes__item-text">
              <Stack v-if="isUserDealer" :quarter="true">
                <h2 class="substitutes__variant-id">
                  {{ variant.primary.productId }}
                </h2>
              </Stack>
              <Stack :quarter="true">
                <h1 class="slide__title">
                  {{ variant.primary.productName }} -
                  {{ variant.primary.variantName }}
                </h1>
              </Stack>
              <Stack v-if="variant.primary.minPrice" :quarter="true">
                <Price
                  :price="variant.primary.minPrice"
                  :show-from-label="true"
                />
              </Stack>
            </Stack>
            <div class="substitutes__colors">
              <h3 class="substitutes__colors-title">
                {{ getDictionaryEntry("Product.Substitutes.ColorsTitle") }}
              </h3>
              <ol
                v-if="variant.secondary && variant.secondary.length"
                class="substitutes__colors-list"
              >
                <li
                  v-for="secondaryVariant in variant.secondary.slice(0, 5)"
                  :key="secondaryVariant.variantId"
                  class="substitutes__color"
                >
                  <ProductImage
                    :variant="secondaryVariant"
                    class="substitutes__color-image"
                  />
                  <router-link
                    :to="{
                      query: {
                        mode: 'details',
                        productId: secondaryVariant.productId,
                        variantId: secondaryVariant.variantId,
                      },
                    }"
                    :aria-label="
                      `${secondaryVariant.productName} - ${secondaryVariant.variantName}`
                    "
                    class="substitutes__link"
                  />
                </li>
                <li
                  v-if="variant.secondary.length > 5"
                  class="substitutes__color"
                >
                  +{{ getAmountOfSurplusVariants(variant.secondary) }}
                  <router-link
                    :to="{
                      query: {
                        mode: 'details',
                        productId: variant.primary.productId,
                        variantId: variant.primary.variantId,
                      },
                    }"
                    :aria-label="
                      `${variant.primary.productName} - ${variant.primary.variantName}`
                    "
                    class="substitutes__link"
                  />
                </li>
              </ol>
            </div>
          </article>
          <router-link
            :to="{
              query: {
                mode: 'details',
                productId: variant.primary.productId,
                variantId: variant.primary.variantId,
              },
            }"
            :aria-label="
              `${variant.primary.productName} - ${variant.primary.variantName}`
            "
            class="substitutes__link"
          />
        </div>
        <button
          slot="viewport"
          aria-label="previous"
          class="slider__navigation slider__navigation--prev"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="slider__navigation-icon"
            viewBox="0 0 24 24"
          >
            <defs />
            <path d="M19 12H5M12 19l-7-7 7-7" />
          </svg>
        </button>
        <div slot="viewport" class="substitutes__overlay-container">
          <button
            aria-label="next"
            class="slider__navigation slider__navigation--next"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="slider__navigation-icon"
              viewBox="0 0 24 24"
            >
              <defs />
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </button>
          <div class="substitutes__overlay"></div>
        </div>
      </Flicking>
    </Stack>
  </div>
</template>

<script>
import isEqual from "lodash.isequal";
import { Arrow } from "@egjs/flicking-plugins";
import { mapActions, mapGetters } from "vuex";
import Price from "@/components/Price";
import ProductImage from "@/components/ProductImage";
import Stack from "@/components/Stack";

export default {
  name: "SubstituteProducts",
  components: {
    Price,
    ProductImage,
    Stack,
  },
  data() {
    return {
      options: {
        align: "prev",
        autoResize: true,
        bound: true,
        noPanelStyleOverride: true,
      },
      plugins: [
        new Arrow({
          disabledClass: "slider__navigation--disabled",
          nextElSelector: ".substitutes__overlay-container",
          prevElSelector: ".slider__navigation--prev",
        }),
      ],
    };
  },
  computed: {
    ...mapGetters("authentication", ["isUserDealer"]),
    ...mapGetters("products", [
      "currentColorCode",
      "substitutionVariantsForCurrentProduct",
      "substitutionVariantsForCurrentVariant",
    ]),
  },
  watch: {
    currentColorCode(newValue, oldValue) {
      if (isEqual(newValue, oldValue)) return;

      this.getSubstitutions();
    },
    substitutionVariantsForCurrentProduct(newValue, oldValue) {
      if (isEqual(newValue, oldValue)) return;

      this.getSubstitutions();
    },
  },
  created() {
    this.getSubstitutions();
  },
  destroyed() {
    this.RESET_SUBSTITUTIONS();
  },
  methods: {
    ...mapActions("products", [
      "GET_SUBSTITUTIONS_FOR_PRODUCT",
      "RESET_SUBSTITUTIONS",
    ]),
    getAmountOfSurplusVariants(variants) {
      if (!variants || !variants.length || variants.length <= 5) return null;

      return variants.length - 5;
    },
    getSubstitutions() {
      const {
        GET_SUBSTITUTIONS_FOR_PRODUCT,
        currentColorCode,
        substitutionVariantsForCurrentProduct,
      } = this;

      if (!substitutionVariantsForCurrentProduct.length || !currentColorCode)
        return;

      GET_SUBSTITUTIONS_FOR_PRODUCT({
        colorNumber: currentColorCode,
        variantIds: substitutionVariantsForCurrentProduct,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.substitutes {
  &__body-text {
    font-size: 0.875rem;
    margin: 0;
  }

  &__color {
    align-items: center;
    background-color: var(--color-product-image-background);
    display: flex;
    flex-basis: calc((100% / 6) - (0.25rem * (5 / 6)));
    flex-grow: 0;
    flex-shrink: 0;
    font-size: 0.875rem;
    justify-content: center;
    position: relative;

    &:not(:last-child) {
      margin-right: 0.25rem;
    }
  }

  &__colors {
    background-color: #ffffff;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    transform: translateY(100%);
    transition: transform 300ms ease;
    z-index: 4;

    .substitutes__item:hover & {
      transform: translateY(0);
    }

    &-list {
      @include reset-list();

      display: flex;
    }

    &-title {
      font-family: var(--font-family-roboto);
      font-size: 0.875rem;
      font-weight: 700;
      margin: 2rem 0 1rem;
      text-transform: none;
    }
  }

  &__item {
    overflow: hidden;
    position: relative;

    &:hover {
      .block__product-image {
        transform: translateY(1rem) scale(1.5);
      }
    }

    &-text {
      transition: opacity 300ms ease;

      .substitutes__item:hover & {
        opacity: 0;
      }
    }
  }

  &__link {
    &::after {
      bottom: 0;
      content: "";
      left: 0;
      pointer-events: auto;
      position: absolute;
      right: 0;
      top: 0;
    }
  }

  &__navigation {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;
  }

  &__overlay {
    background: linear-gradient(
      -90deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 0.75) 75%,
      rgba(255, 255, 255, 0) 100%
    );
    content: "";
    position: absolute;
    bottom: -100vh;
    right: 0;
    top: -100vh;
    position: absolute;
    width: 6.875rem;
    z-index: 1;

    @media (min-width: 80rem) {
      width: 13.75rem;
    }

    &-container {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      transition: opacity 300ms ease;
      z-index: 2;
    }
  }

  &__title {
    font-size: 1.75rem;
    margin-top: 0;
  }

  &__variant-id {
    font-size: 0.9375rem;
    font-weight: 500;
    margin: 0;
    opacity: 0.3;
  }
}
</style>
