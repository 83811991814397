<template>
  <div class="certificates">
    <Stack v-if="certificates.description">
      {{ certificates.description }}
    </Stack>
    <Stack>
      <ul v-if="certificatesWithoutImage" class="certificates__list">
        <li
          v-for="(certificateWithoutImage, index) in certificatesWithoutImage"
          :key="'withoutImage' + certificateWithoutImage.id + index"
          class="certificates__item"
        >
          {{ certificateWithoutImage.name }}
        </li>
      </ul>
    </Stack>
    <Stack>
      <ul
        v-if="certificatesWithImage"
        class="certificates__list certificates__list--icons"
      >
        <li
          v-for="(certificateWithImage, index) in certificatesWithImage"
          :key="'withImage' + certificateWithImage.id + index"
          class="certificates__item certificates__item--icon"
        >
          <a
            v-if="
              certificateWithImage.image &&
                certificateWithImage.image.externalLink
            "
            :href="certificateWithImage.image.externalLink"
            class="certificates__link"
            target="_blank"
          >
            <div class="certificates__icon-container">
              <img
                v-if="
                  certificateWithImage.image && certificateWithImage.image.src
                "
                :src="certificateWithImage.image.src"
                :alt="certificateWithImage.image.alt"
                class="certificates__icon"
              />
            </div>
            <div class="certificates__name">
              {{ certificateWithImage.name }}
            </div>
          </a>
          <div
            v-else-if="
              certificateWithImage.image &&
                certificateWithImage.image.src &&
                !certificateWithImage.image.externalLink
            "
            class="certificates__link"
          >
            <div class="certificates__icon-container">
              <img
                v-if="
                  certificateWithImage.image && certificateWithImage.image.src
                "
                :src="certificateWithImage.image.src"
                :alt="certificateWithImage.image.alt"
                class="certificates__icon"
              />
            </div>
            <div class="certificates__name">
              {{ certificateWithImage.name }}
            </div>
          </div>
        </li>
      </ul>
    </Stack>
  </div>
</template>

<script>
import Stack from "@/components/Stack";

export default {
  name: "ProductCertificates",
  components: {
    Stack,
  },
  props: {
    certificates: {
      required: true,
      type: Array,
    },
  },
  computed: {
    certificatesWithImage() {
      return this.certificates.filter(certificate => certificate.image.src);
    },
    certificatesWithoutImage() {
      return this.certificates.filter(certificate => !certificate.image.src);
    },
  },
};
</script>

<style lang="scss" scoped>
.certificates {
  &__icon {
    margin: 0 auto;
    max-height: 15rem;
    padding-bottom: 1rem;

    &-container {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      margin: 0 auto;
    }
  }

  &__item {
    margin-bottom: 1rem;

    &--icon {
      border-radius: 0.375rem;
      border: 1px solid var(--color-product-features-border);
      flex-basis: calc(50% - 0.5rem);
      flex-grow: 0;
      flex-shrink: 0;
      line-height: 1;
      text-align: center;
    }
  }

  &__link {
    color: inherit;
    display: block;
    text-decoration: none;

    .certificates__item--icon & {
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 1rem;
    }
  }

  &__list {
    list-style-type: disc;
    margin: 0;
    padding: 0 0 0 1.25rem;

    &--icons {
      @include reset-list();

      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
    }
  }

  &__name {
    padding-bottom: 1rem;
    word-break: break-word;
  }
}
</style>
