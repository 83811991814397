<template>
  <div class="textarea" :class="{ 'textarea--error': hasError }">
    <label
      :for="id"
      class="textarea__label"
      :class="{ 'textarea__label--read-only': readOnly }"
      v-bind="$attrs"
      :required="required"
    >
      {{ label }}</label
    >
    <template v-if="readOnly">
      <div class="textarea__value">{{ internalValue }}</div>
    </template>
    <template v-else>
      <textarea
        :id="id"
        v-model="internalValue"
        :type="text"
        class="textarea__field"
        v-bind="$attrs"
        :disabled="disabled"
        :required="required"
        :placeholder="placeholder"
        @blur="$emit('blur', $event)"
      />
      <Error :message="errorMessage" :visible="hasError" />
    </template>
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import Error from "@/components/Error";
import { uuid } from "@/helpers/uuid";

export default {
  name: "Textarea",
  components: {
    Error,
  },
  inheritAttrs: false,
  model: {
    event: "input",
  },
  props: {
    errorMessage: {
      default: "",
      type: String,
    },
    hasError: {
      default: false,
      type: Boolean,
    },
    label: {
      required: true,
      type: String,
    },
    readOnly: {
      default: false,
      type: Boolean,
    },
    placeholder: {
      default: "",
      type: String,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    required: {
      default: false,
      type: Boolean,
    },
    value: {
      default: null,
      validator: prop => {
        if (prop === null) return true;
        return (
          typeof prop === "number" ||
          typeof prop === "object" ||
          typeof prop === "string"
        );
      },
    },
  },
  data() {
    const value = cloneDeep(this.value);

    return {
      id: uuid(),
      initialValue: value,
      internalValue: value,
      text: "",
    };
  },
  watch: {
    internalValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue, oldValue) {
      const { initialValue } = this;

      if (newValue !== oldValue) {
        if (typeof newValue === "string") {
          this.internalValue =
            newValue || (initialValue === null ? initialValue : "");
        } else if (newValue && newValue.target) {
          this.internalValue =
            newValue.target.value ||
            (initialValue === null ? initialValue : "");
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.textarea {
  position: relative;
  transition: padding 300ms ease;

  &--error {
    padding-bottom: var(--spacing-error-message);
  }

  &__field {
    appearance: none;
    background-color: var(--color-input-background);
    border: 1px solid var(--color-input-border);
    border-radius: 0.25rem;
    font: inherit;
    font-size: 1rem;
    height: 100%;
    line-height: 1.5;
    margin: 0;
    padding: var(--spacing-input-field);
    position: relative;
    resize: vertical;
    transition: background-color 300ms ease, border-color 300ms ease;
    width: 100%;
    z-index: 2;

    &:disabled {
      background-color: var(--color-input-background-disabled) !important;
      border: none !important;
    }

    &:focus,
    &:hover {
      background-color: var(--color-input-background-active);
      border-color: var(--color-input-border-active);
      outline: none;
    }

    .textarea--error & {
      border-color: var(--color-error);
    }
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    &[required] {
      &::after {
        content: "*";
        margin-left: 0.125rem;
      }
    }

    &--read-only {
      cursor: default;
      font-weight: 700;
      margin-bottom: 0.125rem;

      &::after {
        display: none;
      }
    }

    &--hidden {
      @include screen-reader-only();
    }
  }

  &__value {
    font-size: 0.9375rem;
    min-height: 1.125rem;
    white-space: pre-line;
  }
}
</style>
