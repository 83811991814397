<template>
  <div v-if="hasAccessToShop" class="quick-basket">
    <Sidebar
      :title="
        `${getDictionaryEntry('Common.Basket')} (${numberOfItemsInBasket} ${
          numberOfItemsInBasket === 1
            ? getDictionaryEntry('Common.Item').toLowerCase()
            : getDictionaryEntry('Common.Items').toLowerCase()
        })`
      "
      :right="true"
      :visible="visible"
      @close="close"
    >
      <template #body>
        <BasketLineListForShoppingToEmployees
          v-if="isShopToState"
          :basket-lines="basketLines"
        />
        <BasketLineListForEndCustomers
          v-else-if="isUserEndCustomer"
          :basket-lines="basketLines"
        />
        <BasketLineListForCustomersWithEndCustomers
          v-else-if="
            isUserDealer && childrenToShopFor && childrenToShopFor.length
          "
          :basket-lines="basketLines"
        />
        <BasketLineList v-else :basket-lines="basketLines" />
      </template>
      <template #footer>
        <FreeFreightInformation />
        <h3 v-if="totalPrice" class="quick-basket__total">
          <span>{{ getDictionaryEntry("Common.Labels.Total") }}:</span>
          <span> {{ formatPriceCurrency(totalPrice.price) }} {{ totalPrice.currency }} </span>
        </h3>
        <router-link
          :class="{
            'button--disabled': isTotalPriceGreaterThanWalletBalance,
          }"
          :to="{ name: 'checkout' }"
          class="button button--dark button--round quick-basket__checkout"
          @click.native="close"
          >{{ linkTextForCheckout }}
        </router-link>
      </template>
    </Sidebar>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import BasketLineList from "@/components/BasketLineList";
import BasketLineListForCustomersWithEndCustomers from "@/components/BasketLineListForCustomersWithEndCustomers";
import BasketLineListForEndCustomers from "@/components/BasketLineListForEndCustomers";
import BasketLineListForShoppingToEmployees from "@/components/BasketLineListForShoppingToEmployees";
import FreeFreightInformation from "@/components/FreeFreightInformation";
import Sidebar from "@/components/Sidebar";

export default {
  name: "QuickBasket",
  components: {
    BasketLineList,
    BasketLineListForCustomersWithEndCustomers,
    BasketLineListForEndCustomers,
    BasketLineListForShoppingToEmployees,
    FreeFreightInformation,
    Sidebar,
  },
  props: {
    close: {
      required: true,
      type: Function,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("authentication", [
      "isShopToState",
      "isUserDealer",
      "isUserEndCustomer",
      "userConfiguration",
    ]),
    ...mapGetters("basket", [
      "basketLines",
      "isTotalPriceGreaterThanWalletBalance",
      "numberOfItemsInBasket",
      "totalPrice",
    ]),
    ...mapGetters("customers", ["childrenToShopFor"]),
    linkTextForCheckout() {
      const {
        getDictionaryEntry,
        isShopToState,
        isTotalPriceGreaterThanWalletBalance,
      } = this;

      if (!isShopToState && isTotalPriceGreaterThanWalletBalance) {
        return getDictionaryEntry("Checkout.LimitExceeded");
      }

      return getDictionaryEntry("Checkout.GoToCheckout");
    },
  },
};
</script>

<style lang="scss" scoped>
.quick-basket {
  &__checkout {
    margin: 0 var(--spacing-sidebar) var(--spacing-sidebar);
    width: auto;
  }

  &__total {
    display: flex;
    font-size: 1rem;
    justify-content: space-between;
    margin: 0;
    padding: var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding: var(--spacing-sidebar-large);
    }
  }
}
</style>
