<template>
  <Container v-if="blockData">
    <div
      class="block block--cta"
      :class="{ 'block--tall': blockData.increaseHeight }"
    >
      <div class="block__100-percent block__media-container">
        <div
          class="block__content block__content--center block__content--middle"
        >
          <ImageFromUmbraco
            v-if="blockData.image"
            :id="blockData.image._system.id"
            :alt="blockData.image.alternateText"
            :height="blockData.image.umbracoHeight"
            :keys-and-sizes="{
              s: 'block-regular-small',
              m: blockData.increaseHeight
                ? 'block-cta-tall-medium'
                : 'block-cta-medium',
              l: blockData.increaseHeight
                ? 'block-cta-tall-large'
                : 'block-cta-large',
              xl: blockData.increaseHeight
                ? 'block-cta-tall-extra-large'
                : 'block-cta-extra-large',
            }"
            :width="blockData.image.umbracoWidth"
            class="block__image"
          />
          <article class="block__content-text">
            <div v-if="blockData.caption" class="block__subtitle">
              {{ blockData.caption }}
            </div>
            <Heading
              v-if="blockData.headline"
              :tag="blockData.headlineTag"
              class="block__title"
            >
              {{ blockData.headline }}
            </Heading>
            <ConditionalRouterLink
              v-if="blockData.cTA && blockData.cTA.name && blockData.cTA.url"
              :target="blockData.cTA.target"
              :to="blockData.cTA.url"
              :type="blockData.cTA.type"
              class="block__button"
              >{{ blockData.cTA.name }}</ConditionalRouterLink
            >
          </article>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "CTAGridElement",
  components: {
    ConditionalRouterLink,
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>
