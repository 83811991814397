export default [
  {
    path: "user-groups/:userGroupId",
    name: "customer.userGroup",
    component: () =>
      import(
        /* webpackChunkName: "customerUserGroup" */ "../views/CustomerUserGroup.vue"
      ),
    meta: {
      breadcrumbs: [
        { path: { name: "customers" }, title: "_Navigation.Customers" },
        {
          path: {
            name: "customer",
            params: { customerId: null },
          },
          title: "customers/nameOfCurrentCustomer",
        },
        {
          path: { name: "customer.userGroups" },
          title: "_Navigation.UserGroups",
        },
        { title: "userGroups/nameOfCurrentUserGroup" },
      ],
      isAuthenticationRequired: true,
    },
    redirect: { name: "customer.userGroup.information" },
    children: [
      {
        path: "addresses",
        name: "customer.userGroup.addresses",
        component: () =>
          import(
            /* webpackChunkName: "customerUserGroupAddress" */ "../views/CustomerUserGroupAddresses.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            {
              path: { name: "customer.userGroups" },
              title: "_Navigation.UserGroups",
            },
            {
              path: {
                name: "customer.userGroup",
                params: { userGroupId: null },
              },
              title: "userGroups/nameOfCurrentUserGroup",
            },
            { title: "_Administration.Addresses" },
          ],
          isAuthenticationRequired: true,
        },
        children: [
          {
            path: ":addressId",
            name: "customer.userGroup.address",
            component: () =>
              import(
                /* webpackChunkName: "customerUserGroupAddress" */ "../views/CustomerUserGroupAddress.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.userGroups" },
                  title: "_Navigation.UserGroups",
                },
                {
                  path: {
                    name: "customer.userGroup",
                    params: { userGroupId: null },
                  },
                  title: "userGroups/nameOfCurrentUserGroup",
                },
                {
                  path: "customer.userGroup.addresses",
                  title: "_Administration.Addresses",
                },
                { title: "_Administration.Address" },
              ],
              isAuthenticationRequired: true,
            },
          },
        ],
      },
      {
        path: "administrators",
        name: "customer.userGroup.administrators",
        component: () =>
          import(
            /* webpackChunkName: "customerUserGroupAdministrators" */ "../views/CustomerUserGroupAdministrators.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            {
              path: { name: "customer.userGroups" },
              title: "_Navigation.UserGroups",
            },
            {
              path: {
                name: "customer.userGroup",
                params: { userGroupId: null },
              },
              title: "userGroups/nameOfCurrentUserGroup",
            },
            { title: "_Common.Administrators" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "information",
        name: "customer.userGroup.information",
        component: () =>
          import(
            /* webpackChunkName: "customerUserGroupInformation" */ "../views/CustomerUserGroupInformation.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            {
              path: { name: "customer.userGroups" },
              title: "_Navigation.UserGroups",
            },
            {
              path: {
                name: "customer.userGroup",
                params: { userGroupId: null },
              },
              title: "userGroups/nameOfCurrentUserGroup",
            },
            { title: "_Administration.Information" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "purchase-limitations",
        name: "customer.userGroup.purchaseLimitations",
        component: () =>
          import(
            /* webpackChunkName: "customerUserGroupPurchaseLimitations" */ "../views/CustomerUserGroupPurchaseLimitations.vue"
          ),
        redirect: {
          name: "customer.userGroup.purchaseLimitationsList",
        },
        children: [
          {
            path: "",
            name: "customer.userGroup.purchaseLimitationsList",
            component: () =>
              import(
                /* webpackChunkName: "customerUserGroupPurchaseLimitations" */ "../views/CustomerUserGroupPurchaseLimitationsList.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.userGroups" },
                  title: "_Navigation.UserGroups",
                },
                {
                  path: {
                    name: "customer.userGroup",
                    params: { userGroupId: null },
                  },
                  title: "userGroups/nameOfCurrentUserGroup",
                },
                { title: "_Administration.PurchaseLimitations" },
              ],
              isAuthenticationRequired: true,
            },
          },
          {
            path: ":purchaseLimitationId",
            name: "customer.userGroup.purchaseLimitation",
            component: () =>
              import(
                /* webpackChunkName: "customerUserGroupPurchaseLimitation" */ "../views/CustomerUserGroupPurchaseLimitation.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "customers" }, title: "_Navigation.Customers" },
                {
                  path: {
                    name: "customer",
                    params: { customerId: null },
                  },
                  title: "customers/nameOfCurrentCustomer",
                },
                {
                  path: { name: "customer.userGroups" },
                  title: "_Navigation.UserGroups",
                },
                {
                  path: {
                    name: "customer.userGroup",
                    params: { userGroupId: null },
                  },
                  title: "userGroups/nameOfCurrentUserGroup",
                },
                {
                  path: { name: "customer.userGroup.purchaseLimitations" },
                  title: "_Administration.PurchaseLimitations",
                },
                {
                  title: "purchaseLimitations/nameOfCurrentPurchaseLimitation",
                },
              ],
              isAuthenticationRequired: true,
            },
          },
        ],
      },
      {
        path: "users",
        name: "customer.userGroup.users",
        component: () =>
          import(
            /* webpackChunkName: "customerUserGroupUsers" */ "../views/CustomerUserGroupUsers.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            {
              path: { name: "customer.userGroups" },
              title: "_Navigation.UserGroups",
            },
            {
              path: {
                name: "customer.userGroup",
                params: { userGroupId: null },
              },
              title: "userGroups/nameOfCurrentUserGroup",
            },
            { title: "_Common.Users" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "wallet-renewal",
        name: "customer.userGroup.walletRenewal",
        component: () =>
          import(
            /* webpackChunkName: "customerUserGroupWallet" */ "../views/CustomerUserGroupWalletRenewal.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "customers" }, title: "_Navigation.Customers" },
            {
              path: {
                name: "customer",
                params: { customerId: null },
              },
              title: "customers/nameOfCurrentCustomer",
            },
            {
              path: { name: "customer.userGroups" },
              title: "_Navigation.UserGroups",
            },
            {
              path: {
                name: "customer.userGroup",
                params: { userGroupId: null },
              },
              title: "userGroups/nameOfCurrentUserGroup",
            },
            { title: "_Wallet.WalletRenewal" },
          ],
          isAuthenticationRequired: true,
        },
      },
    ],
  },
];
