<template>
  <div
    v-if="hasAccessToProductSheet"
    class="catalogues"
    :class="`catalogues--step-${currentStep}`"
  >
    <Sidebar
      :right="true"
      :title="getDictionaryEntry('ProductSheet.YourCatalogue')"
      :visible="visible"
      :wide="true"
      @close="close"
    >
      <template #body>
        <DynamicData :loading="isLoading || isSaving">
          <transition mode="out-in" name="fade">
            <CataloguesIntroduction
              v-if="currentStep === 1"
              key="introduction"
              :go-to-step="goToStep"
            />
            <CataloguesPrevious
              v-else-if="currentStep === 2"
              key="previous"
              :previous="$v.previousCatalogues"
            />
            <CataloguesBasket
              v-else-if="currentStep === 3"
              key="basket"
              @catalogue-update="updateCatalogue"
            />
            <CataloguesInformation
              v-else-if="currentStep === 4"
              key="information"
              :information="$v.catalogueInformation"
              @reset-end-customer="
                catalogueInformation.selectedEndCustomer = null
              "
              @reset-dealer-name="catalogueInformation.dealerName = ''"
              @reset-end-customer-name="
                catalogueInformation.endCustomerName = ''
              "
            />
            <CataloguesGeneration
              v-else-if="currentStep === 5"
              key="generation"
            />
            <CataloguesDownloads
              v-else-if="currentStep === 6"
              key="downloads"
              :go-to-step="goToStep"
            />
          </transition>
        </DynamicData>
      </template>
      <template #footer>
        <div class="catalogues__button-container">
          <Button
            v-if="currentStep === 3"
            :limited-width="true"
            :secondary="true"
            @click="goToStep(1)"
            >{{ getDictionaryEntry("ProductSheet.Return") }}</Button
          >
          <Button
            :disabled="shouldDisablePreviousButton"
            :limited-width="true"
            :secondary="true"
            @click="previousButtonAction"
          >
            {{ previousButtonText() }}
          </Button>
          <Button
            v-if="currentStep === 2"
            :limited-width="true"
            :secondary="true"
            @click="deleteButtonAction"
          >
            {{ getDictionaryEntry("ProductSheet.DeleteCatalogue") }}
          </Button>
          <Button
            v-if="currentStep === 3"
            :limited-width="true"
            :secondary="true"
            @click="toggleSortingOverlay"
          >
            Sortér
          </Button>
        </div>
        <div class="catalogues__button-container">
          <Button
            v-if="currentStep < totalSteps"
            :disabled="shouldDisableNextButton"
            :limited-width="true"
            :show-loader="isWaitingForNextStep"
            @click="nextButtonAction"
          >
            {{ nextButtonText() }}
          </Button>
        </div>
      </template>
    </Sidebar>
    <CatalogueSortingModal
      :visible="isSortingModalOpen"
      :handle-close="toggleSortingOverlay"
    />
  </div>
</template>

<script>
import cloneDeep from "lodash.clonedeep";
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";
import { required, requiredIf } from "vuelidate/lib/validators";
import Button from "@/components/Button";
import CataloguesBasket from "@/components/CataloguesBasket";
import CataloguesDownloads from "@/components/CataloguesDownloads";
import CataloguesGeneration from "@/components/CataloguesGeneration";
import CataloguesInformation from "@/components/CataloguesInformation";
import CataloguesIntroduction from "@/components/CataloguesIntroduction";
import CataloguesPrevious from "@/components/CataloguesPrevious";
import DynamicData from "@/components/DynamicData";
import Sidebar from "@/components/Sidebar";
import CatalogueSortingModal from "@/components/CatalogueSortingModal";
import { catalogueStatuses } from "@/helpers/catalogueStatuses";
import { alertTypes } from "@/helpers/alertTypes";
import { statuses } from "@/helpers/statuses";

export default {
  name: "Catalogues",
  components: {
    Button,
    CataloguesBasket,
    CataloguesDownloads,
    CataloguesGeneration,
    CataloguesInformation,
    CataloguesIntroduction,
    CataloguesPrevious,
    DynamicData,
    Sidebar,
    CatalogueSortingModal,
  },
  props: {
    close: {
      required: true,
      type: Function,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      catalogueInformation: {
        category: null,
        dealerAddress: "",
        dealerEmail: "",
        dealerName: "",
        dealerPhone: "",
        dealerPostCity: "",
        endCustomerAddress: "",
        endCustomerEmail: "",
        endCustomerName: "",
        endCustomerPhone: "",
        endCustomerPostCity: "",
        name: "",
        selectedDealer: "",
        selectedEndCustomer: null,
        selectedLanguage: "en",
        selectedSizeChart: "da",
        shouldUseCurrency: true,
        currency: null,
      },
      currentStep: 1,
      internalCurrentCatalogue: {},
      isWaitingForNextStep: false,
      isWaitingForPreviousStep: false,
      previousCatalogues: {
        selected: "",
      },
      totalSteps: 5,
      isSortingModalOpen: false,
    };
  },
  validations: {
    catalogueInformation: {
      category: { required },
      dealerAddress: {},
      dealerEmail: {},
      dealerName: {},
      dealerPhone: {},
      dealerPostCity: {},
      endCustomerAddress: {},
      endCustomerEmail: {},
      endCustomerName: {},
      endCustomerPhone: {},
      endCustomerPostCity: {},
      name: { required },
      selectedDealer: {},
      selectedEndCustomer: {},
      selectedLanguage: {},
      selectedSizeChart: {},
      shouldUseCurrency: { required },
      currency: {
        required: requiredIf(function() {
          return this.catalogueInformation.shouldUseCurrency;
        }),
      },
    },
    previousCatalogues: {
      selected: { required },
    },
  },
  computed: {
    ...mapGetters("catalogues", [
      "catalogueBuildProcess",
      "catalogues",
      "categories",
      "dealers",
      "currentCatalogue",
      "isPreviouslyCreatedCatalogueLoaded",
      "numberOfItemsInCatalogue",
      "statusOfCatalogues",
    ]),
    ...mapGetters("customers", ["childrenOfCurrentCustomer"]),
    ...mapGetters("products", ["statusOfProducts"]),
    isLoading() {
      return (
        this.statusOfCatalogues === statuses.LOADING ||
        this.statusOfProducts === statuses.LOADING
      );
    },
    isSaving() {
      return this.statusOfCatalogues === statuses.SAVING;
    },
    shouldDisableNextButton() {
      const { $v, currentStep, internalCurrentCatalogue } = this;

      if (currentStep === 2) {
        return $v.previousCatalogues.$invalid;
      }

      if (currentStep === 3) {
        if (!this.numberOfItemsInCatalogue) return true;
        if (
          internalCurrentCatalogue.shouldUseCurrency &&
          !internalCurrentCatalogue.currency
        )
          return true;
      }

      if (currentStep === 4) {
        return $v.catalogueInformation.$invalid;
      }

      return false;
    },
    shouldDisablePreviousButton() {
      const { currentStep, numberOfItemsInCatalogue } = this;

      if (currentStep === 3) {
        return !numberOfItemsInCatalogue;
      }

      return false;
    },
  },
  watch: {
    catalogueBuildProcess: {
      handler: function(newValue, oldValue) {
        if (isEqual(newValue, oldValue)) return;

        if (newValue.status === catalogueStatuses.DONE) {
          this.goToStep(6);

          if (!this.visible) {
            this.OPEN_ALERT({
              key: "ProductSheet.YourCatalogueIsReady",
              type: alertTypes.SUCCESS,
            });
          }

          this.catalogueInformation = {
            category: null,
            dealerAddress: "",
            dealerEmail: "",
            dealerName: "",
            dealerPhone: "",
            dealerPostCity: "",
            endCustomerAddress: "",
            endCustomerEmail: "",
            endCustomerName: "",
            endCustomerPhone: "",
            endCustomerPostCity: "",
            name: "",
            selectedDealer: "",
            selectedEndCustomer: null,
            selectedLanguage: "",
            selectedSizeChart: "da",
            shouldUseCurrency: false,
          };

          this.$v.catalogueInformation.$reset();
        } else if (newValue.status === catalogueStatuses.FAILED) {
          this.goToStep(1);
        }
      },
      deep: true,
    },
    categories(newValue) {
      const {
        categories,
        internalCurrentCatalogue: { categoryKey },
      } = this;

      if (newValue) {
        const matchingCategory = categories.find(
          category => category.key === categoryKey,
        );

        if (!matchingCategory) return;

        this.catalogueInformation.category = categoryKey;
      }
    },
    childrenOfCurrentCustomer(newValue) {
      const {
        childrenOfCurrentCustomer,
        internalCurrentCatalogue: { selectedEndCustomer },
      } = this;

      if (newValue) {
        const matchingChild = childrenOfCurrentCustomer.find(
          children => children.id === selectedEndCustomer,
        );

        if (!matchingChild) return;

        this.catalogueInformation.selectedEndCustomer = selectedEndCustomer;
      }
    },
    currentCatalogue(newValue, oldValue) {
      if (newValue && !isEqual(newValue, oldValue)) {
        const {
          categoryKey,
          dealerName,
          endCustomerName,
          name,
          selectedDealer,
          selectedEndCustomer,
          selectedLanguage,
          selectedSizeChart,
          shouldUseCurrency,
          currency,
        } = newValue;

        this.internalCurrentCatalogue = cloneDeep(newValue);
        this.catalogueInformation.category = categoryKey;
        this.catalogueInformation.dealerName = dealerName;
        this.catalogueInformation.endCustomerName = endCustomerName;
        this.catalogueInformation.name = name;
        this.catalogueInformation.selectedDealer = selectedDealer;
        this.catalogueInformation.selectedEndCustomer = selectedEndCustomer;
        this.catalogueInformation.selectedLanguage = selectedLanguage;
        this.catalogueInformation.shouldUseCurrency = shouldUseCurrency;
        this.catalogueInformation.currency = currency;
        this.catalogueInformation.selectedSizeChart = selectedSizeChart || "da";
      }
    },
    dealers(newValue) {
      const {
        dealers,
        internalCurrentCatalogue: { selectedDealer },
      } = this;

      if (newValue) {
        const matchingDealer = dealers.find(
          dealer => dealer.id === selectedDealer,
        );

        if (!matchingDealer) return;

        this.catalogueInformation.selectedDealer = selectedDealer;
      }
    },
    isPreviouslyCreatedCatalogueLoaded(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        this.currentStep = 3;
      }
    },
    visible(newValue) {
      if (newValue) {
        this.GET_CATALOGUES();

        if (!this.currentCatalogue) {
          this.GET_CURRENT_CATALOGUE();
        }
      }
    },
  },
  created() {
    const {
      GET_STATUS_OF_CATALOGUE_UNTIL_COMPLETE,
      goToStep,
      isPreviouslyCreatedCatalogueLoaded,
    } = this;

    if (this.hasAccessToProductSheet) {
      this.GET_CURRENT_CATALOGUE();
    }

    if (isPreviouslyCreatedCatalogueLoaded) {
      this.currentStep = 3;
    }

    const catalogueBuildProcessString = localStorage.getItem(
      "catalogueBuildProcess",
    );

    if (catalogueBuildProcessString) {
      const catalogueBuildProcess = JSON.parse(catalogueBuildProcessString);

      goToStep(5);

      GET_STATUS_OF_CATALOGUE_UNTIL_COMPLETE({
        id: catalogueBuildProcess.id,
      });
    }
  },
  methods: {
    ...mapActions("catalogues", [
      "ADD_EXISTING_CATALOGUE_TO_CURRENT",
      "BUILD_CATALOGUE",
      "DELETE_CATALOGUE",
      "GET_CATALOGUES",
      "GET_CURRENT_CATALOGUE",
      "GET_STATUS_OF_CATALOGUE_UNTIL_COMPLETE",
      "RESET_CATALOGUE",
      "UPDATE_CATALOGUE",
    ]),
    ...mapActions("alerts", ["OPEN_ALERT"]),
    deleteButtonAction() {
      const { DELETE_CATALOGUE, GET_CATALOGUES, previousCatalogues } = this;

      DELETE_CATALOGUE({
        catalogueId: previousCatalogues.selected,
      }).then(() => {
        GET_CATALOGUES();
      });
    },
    goToStep(step) {
      if (!step) return;

      this.currentStep = step;
    },
    nextButtonAction() {
      const {
        ADD_EXISTING_CATALOGUE_TO_CURRENT,
        BUILD_CATALOGUE,
        GET_STATUS_OF_CATALOGUE_UNTIL_COMPLETE,
        GET_CURRENT_CATALOGUE,
        UPDATE_CATALOGUE,
        catalogueInformation,
        currentStep,
        goToStep,
        previousCatalogues,
      } = this;

      if (currentStep === 2) {
        this.isWaitingForNextStep = true;

        ADD_EXISTING_CATALOGUE_TO_CURRENT({
          catalogueId: previousCatalogues.selected,
        }).then(() => {
          this.isWaitingForNextStep = false;
          goToStep(3);
        });
      }

      if (currentStep === 3) {
        const {
          currency,
          globalDiscountPercent,
          shouldUseCurrency,
        } = this.internalCurrentCatalogue;

        this.isWaitingForNextStep = true;

        UPDATE_CATALOGUE({
          currency,
          discountPercent: globalDiscountPercent,
          shouldUseCurrency,
        }).then(() => {
          this.isWaitingForNextStep = false;
          goToStep(4);
        });
      }

      if (currentStep === 4) {
        this.isWaitingForNextStep = true;
        BUILD_CATALOGUE(catalogueInformation).then(id => {
          this.isWaitingForNextStep = false;
          goToStep(5);
          GET_CURRENT_CATALOGUE();
          window.setTimeout(() => {
            GET_STATUS_OF_CATALOGUE_UNTIL_COMPLETE({ id });
          }, 10000);
        });
      }
    },
    nextButtonText() {
      const { currentStep, getDictionaryEntry } = this;

      if (currentStep <= 2) {
        return getDictionaryEntry("ProductSheet.ChooseCatalogue");
      }

      if (currentStep === 3) {
        return getDictionaryEntry("ProductSheet.SaveItems");
      }

      if (currentStep === 4) {
        return getDictionaryEntry("ProductSheet.GenerateCatalogue");
      }

      return "";
    },
    previousButtonAction() {
      const { currentStep, goToStep, RESET_CATALOGUE } = this;

      if (currentStep === 2) {
        goToStep(1);
      }

      if (currentStep === 3) {
        this.isWaitingForPreviousStep === true;

        RESET_CATALOGUE().then(() => {
          this.isWaitingForPreviousStep = false;
          goToStep(1);
        });
      }

      if (currentStep === 4) {
        goToStep(3);
      }
    },
    previousButtonText() {
      const { currentStep, getDictionaryEntry } = this;

      if (currentStep <= 2) {
        return getDictionaryEntry("ProductSheet.Return");
      }

      if (currentStep === 3) {
        return getDictionaryEntry("ProductSheet.RemoveAllItems");
      }

      if (currentStep === 4) {
        return getDictionaryEntry("ProductSheet.Return");
      }

      return "";
    },
    updateCatalogue(catalogue) {
      this.internalCurrentCatalogue = catalogue;
    },
    toggleSortingOverlay() {
      this.isSortingModalOpen = !this.isSortingModalOpen;
    },
  },
};
</script>

<style lang="scss" scoped>
.catalogues {
  &__button-container {
    display: flex;
    gap: var(--spacing-modal);

    @media (min-width: 48rem) {
      gap: var(--spacing-modal-large);
    }
  }
}
</style>
