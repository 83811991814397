import { mapGetters } from "vuex";
import store from "@/store";

let keysBeingFetched = [];

export default {
  computed: {
    ...mapGetters("siteSettings", ["dictionaryEntries"]),
    getDictionaryEntry() {
      const { dictionaryEntries } = this;

      return key => {
        if (!dictionaryEntries) return "";
        if (key && !dictionaryEntries[key] && key.startsWith("ErrorMessage")) {
          if (!keysBeingFetched.includes(key)) {
            keysBeingFetched.push(key);

            store
              .dispatch(
                "siteSettings/GET_DICTIONARY_ENTRY",
                { key },
                { root: true },
              )
              .then(() => {
                keysBeingFetched = keysBeingFetched.filter(
                  keyBeingFetched => keyBeingFetched !== key,
                );

                return dictionaryEntries[key] || key;
              })
              .catch(() => {
                return `MISSING DICTIONARY ENTRY: ${key}`;
              });
          } else {
            return `MISSING DICTIONARY ENTRY: ${key}`;
          }
        }

        return dictionaryEntries[key] || key;
      };
    },
  },
};
