<template>
  <div class="dealer-product-product">
    <div class="dealer-product-product-image-container">
      <div class="dealer-product-product--badges">
        <div
          v-if="currentVariant.minPrice && currentVariant.minPrice.isDiscounted"
          class="dealer-product-product--badges-badge
        dealer-product-product--badges-badge--discount"
        >
          {{ getDictionaryEntry('Product.DiscountLabel') }}
        </div>
        <div
          v-if="currentVariant.isPreorder"
          class="dealer-product-product--badges-badge
        dealer-product-product--badges-badge--pre-order"
        >
          {{ getDictionaryEntry('Product.PreOrderLabel') }}
        </div>
        <div
          v-if="isVariantAssemblyItem"
          class="dealer-product-product--badges-badge
        dealer-product-product--badges-badge--vas"
        >
          {{ getDictionaryEntry('Product.VASLabel') }}
        </div>
      </div>
      <img
        :key="image ? image.src : productFallbackImage"
        :alt="image ? image.alt : 'product image'"
        :height="image ? image.height : null"
        :src="image ? image.src : productFallbackImage"
        :width="image ? image.width : null"
        class="dealer-product-product-image-container--image"
        decoding="async"
        loading="lazy"
      />
    </div>
    <div v-if="!quickBuyMode" class="dealer-product-product-actions">
      <ProductVariantBlockActions
        :product-variant="{ ...currentVariant, productId: product.id }"
        :can-quick-buy="false"
        :vertical-on-mobile="true"
      />
    </div>
    <div class="dealer-product-product-description">
      <div>
        <h1 class="dealer-product-product-description--name">
          {{ product.name }} -
          {{ currentVariant.name }}
        </h1>
        <h2 class="dealer-product-product-description--sku">
          {{ currentVariant.id }}
        </h2>
      </div>
      <div class="dealer-product-product-description--variants">
        <h3 class="dealer-product-product-description--variants__label">
          {{ getDictionaryEntry('Common.Labels.Color') }}
          <span
            class="dealer-product-product-description--variants__label--slim"
          >- {{ currentVariant.swatch.alt }}</span
          >
        </h3>

        <Swatches
          v-if="!quickBuyMode"
          round
          :product="product"
          :active-variant-index="activeVariantIndex"
          @variant-changed="$emit('update:activeVariantIndex', $event)"
        />
      </div>
      <div class="dealer-product-product-description--sizes-tabs">
        <SizeTabSelector
          :active-size-group-index="activeSizeGroupIndex"
          :internal-size-groups="internalSizeGroups"
          @update:activeSizeGroupIndex="
            $emit('update:activeSizeGroupIndex', $event)
          "
        />
      </div>
    </div>
  </div>
</template>
<script>
import ProductVariantBlockActions from '@/components/ProductVariantBlockActions.vue';
import SizeTabSelector from '@/components/SizeTabSelector.vue';
import Swatches from '@/components/Swatches.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'DealerProductCard',
  components: { ProductVariantBlockActions, SizeTabSelector, Swatches },
  props: {
    activeSizeGroupIndex: {
      required: true,
      type: Number
    },
    activeVariantIndex: {
      default: 0,
      type: Number
    },
    currentVariant: {
      required: true,
      type: Object
    },
    internalSizeGroups: {
      required: true,
      type: Array
    },
    product: {
      required: true,
      type: Object
    },
    quickBuyMode: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    ...mapGetters('branding', ['productFallbackImage']),
    isVariantAssemblyItem() {
      return this.currentVariant?.id?.includes('_A');
    },
    image() {
      const { images } = this.currentVariant;

      if (!images || !images.length) return null;

      return images[0].thumbnail;
    }
  }
};
</script>
<style lang="scss" scoped>
.dealer-product {
  &-product {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: var(--color-neutral-10);
    padding: 0.75rem;
    display: flex;
    flex-direction: row;

    @media (min-width: 64rem) {
      width: 14.375rem;
      height: 100%;
      max-height: 30rem;
      flex-direction: column;
    }

    &--badges {
      position: absolute;
      top: 0.75rem;
      right: 0.75rem;
      z-index: 1;

      &-badge {
        border-radius: 0.25rem;
        padding: 0.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--color-white);
        font-family: var(--font-family-roboto);
        font-size: 0.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 100%; /* 0.75rem */
        text-transform: uppercase;
        height: 1.625rem;

        @media (min-width: 64rem) {
          border-radius: 50%;
          width: calc(var(--size-product-label) * 0.98);
          height: calc(var(--size-product-label) * 0.98);
          text-align: center;
          white-space: pre-line;
          aspect-ratio: 1;
        }

        &--discount {
          background-color: var(--color-product-label-discount);
        }

        &--pre-order {
          background-color: var(--color-product-label-pre-order);
        }

        &--vas {
          background-color: var(--color-product-label-vas);
        }
      }
    }

    &-image-container {
      align-self: stretch;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      &--image {
        object-fit: cover;
        margin: 0 auto;

        @media (max-width: 64rem) {
          max-width: 11.875rem;
          max-height: 10.5rem;
        }
      }
    }

    &-description {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      margin-left: 0.75rem;

      @media (min-width: 64rem) {
        margin-left: 0;
      }

      &--name {
        @include reset-heading();
        color: #131823;
        font-family: var(--font-family-inter);
        font-style: normal;
        font-size: 14px;
        font-weight: 700;
        line-height: 140%; /* 1.225rem */
      }

      &--sku {
        @include reset-heading();
        color: #afafaf;
        font-family: var(--font-family-roboto);
        font-style: normal;
        font-size: 14px;
        font-weight: 400;
        line-height: 140%; /* 1.225rem */
      }

      &--variants {
        &__label {
          @include reset-heading();
          font-size: 0.9375rem;
          margin: 0 0 calc(var(--spacing-stack) * 0.5);

          &--slim {
            font-weight: 400;
          }
        }
      }

      &--sizes-tabs {
        display: block;
        @media (min-width: 64rem) {
          display: none;
        }
      }
    }

    &-actions {
      position: absolute;
      z-index: 1;
      right: 0;
      top: 50%;
      transform: translate(-50%, -50%);

      @media (min-width: 64rem) {
        position: relative;
        top: unset;
        right: unset;
        transform: none;
        z-index: 0;
        display: flex;
        justify-content: flex-end;
        padding: 0.75rem 0;
      }
    }
  }
}
</style>
