import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  freightInfo: null,
  status: "",
});

const getters = {
  amountUntilFreeFreight: state => {
    if (!state.freightInfo) return null;

    return state.freightInfo.amountUntilFreeFreight;
  },
  currentFreightCost: state => {
    if (!state.freightInfo) return null;

    return state.freightInfo.currentFreightCost;
  },
  statusOfShipping: state => state.status,
};

const actions = {
  GET_FREIGHT_INFO({ commit }, payload) {
    commit("SET_SHIPPING_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/shipping/freightinfo?customerId=${payload.customerId}&shippingCountry=${payload.country}`,
      )
      .then(({ data }) => {
        commit("SET_SHIPPING_STATUS", { status: statuses.SUCCESS });
        commit("SET_FREIGHT_INFO", { freightInfo: data });
      })
      .catch(error => {
        if (error.response.status === 404) {
          commit("SET_SHIPPING_STATUS", {
            status: statuses.SUCCESS,
          });

          commit("SET_FREIGHT_INFO", {
            freightInfo: {
              amountUntilFreeFreight: 0,
              currentFreightCost: 0,
            },
          });

          return;
        }

        commit("SET_SHIPPING_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_FREIGHT_INFO(state, payload) {
    state.freightInfo = payload.freightInfo;
  },
  SET_SHIPPING_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
