<template>
  <div class="quick-buy-product-details">
    <SlideIn
      :visible="endCustomerQuickBuyOpen"
      has-bottom-button
      @close="TOGGLE_END_CUSTOMER_QUICK_BUY"
    >
      <template #body>
        <DynamicData :loading="isLoading" transition="fade">
          <Stack>
            <div
              v-if="currentProduct && currentVariant"
              class="quick-buy-product-details__block"
            >
              <div class="quick-buy-product-details__content">
                <Stack v-if="isDiscounted" :half="true">
                  {{ getDictionaryEntry("Product.DiscountLabel") }}
                </Stack>
                <Stack>
                  <InformationList
                    :information="{
                      ...currentProduct,
                      id: currentVariant.id,
                    }"
                  />
                </Stack>
                <Stack v-if="currentProduct.name" :half="true">
                  <h2 class="quick-buy-product-details__title">
                    {{ currentProduct.name }}
                  </h2>
                </Stack>
                <Stack v-if="selectedSize && selectedSize.price" :half="true">
                  <Price :price="selectedSize.price" />
                </Stack>
                <Stack
                  v-if="
                    currentProduct.serviceItems &&
                      currentProduct.serviceItems.length
                  "
                >
                  <ol class="quick-buy-product-details__service-items">
                    <li
                      v-for="serviceItem in currentProduct.serviceItems"
                      :key="serviceItem.id"
                      class="quick-buy-product-details__service-item"
                    >
                      + {{ serviceItem.productNumber }}
                      {{ serviceItem.name }}
                    </li>
                  </ol>
                </Stack>
                <Stack
                  v-if="currentVariant.swatch && currentVariant.swatch.alt"
                  :one-and-half="true"
                >
                  <h3 class="quick-buy-product-details__label">
                    {{ getDictionaryEntry("Common.Labels.SelectColor") }}
                    <span
                      class="quick-buy-product-details__label quick-buy-product-details__label--slim"
                      >- {{ currentVariant.swatch.alt }}</span
                    >
                  </h3>
                  <Swatches
                    :product="currentProduct"
                    end-customer-quick-buy-mode
                  />
                </Stack>
                <Stack
                  v-if="
                    currentVariant.purchaseLimitationDescriptions &&
                      currentVariant.purchaseLimitationDescriptions.length
                  "
                  :one-and-half="true"
                >
                  <h3 class="quick-buy-product-details__label">
                    {{
                      getDictionaryEntry("Administration.PurchaseLimitations")
                    }}
                  </h3>
                </Stack>
                <Stack v-if="isShopToState">
                  <ShopToSizeSelectorQuickBuy
                    :sizes="ungroupedSizes"
                    :add-to-basket-clicked="addToBasketClicked"
                    @set-footer-text-label="handleFooterText"
                    @set-footer-button-disabled="handleFooterDisabled"
                    @set-footer-button-loading="handleFooterButtonLoading"
                  />
                </Stack>
                <Stack v-else :one-and-half="true">
                  <SizeSelectorQuickBuy
                    :handle-size-select="selectSize"
                    :has-size-groups="hasSizeGroups"
                    :selected-size="selectedSize"
                    :sizes="sizes"
                    :add-to-basket-clicked="addToBasketClicked"
                    @set-footer-text-label="handleFooterText"
                    @set-footer-button-disabled="handleFooterDisabled"
                    @set-footer-button-loading="handleFooterButtonLoading"
                  />
                </Stack>
              </div>
            </div>
          </Stack>
        </DynamicData>
      </template>

      <template v-if="!isLoading" #footer>
        <Button
          :buy="true"
          :disabled="shouldDisableFooterButton"
          :round="true"
          :show-loader="isLoadingFooterButton"
          @click="addToBasket"
        >
          {{ footerButtonLabel || getDictionaryEntry("Product.AddToBasket") }}
        </Button>
      </template>
    </SlideIn>
  </div>
</template>
<script>
import SlideIn from "@/components/SlideIn.vue";
import { mapActions, mapGetters } from "vuex";
import DynamicData from "@/components/DynamicData.vue";
import Stack from "@/components/Stack.vue";
import SizeSelectorQuickBuy from "@/components/SizeSelectorQuickBuy.vue";
import InformationList from "@/components/InformationList.vue";
import Swatches from "@/components/Swatches.vue";
import Price from "@/components/Price.vue";
import ShopToSizeSelectorQuickBuy from "@/components/ShopToSizeSelectorQuickBuy.vue";
import { statuses } from "@/helpers/statuses";
import isEqual from "lodash.isequal";
import Button from "@/components/Button.vue";

export default {
  name: "EndCustomerQuickBuy",
  components: {
    Button,
    ShopToSizeSelectorQuickBuy,
    Price,
    Swatches,
    InformationList,
    SizeSelectorQuickBuy,
    Stack,
    DynamicData,
    SlideIn,
  },
  data() {
    return {
      selectedSize: null,
      footerButtonLabel: "",
      shouldDisableFooterButton: true,
      isLoadingFooterButton: false,
      addToBasketLoading: false,
      addToBasketClicked: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["isShopToState", "isUserDealer"]),
    ...mapGetters("branding", ["productFallbackImage"]),
    ...mapGetters("products", [
      "currentProduct",
      "currentVariant",
      "statusOfProducts",
      "endCustomerQuickBuyOpen",
    ]),
    alternativeVariants() {
      if (!this.currentVariant) return [];
      return this.currentVariant.alternativeVariants;
    },
    combinesWithVariants() {
      if (!this.currentVariant) return [];
      return this.currentVariant.combinesWithVariants;
    },
    hasSizeGroups() {
      return this.currentVariant.sizeGroups.length > 1;
    },
    isDiscounted() {
      if (!this.currentVariant) return false;

      const { sizeGroups } = this.currentVariant;

      if (!sizeGroups || !sizeGroups.length) return false;

      return sizeGroups
        .map(sizeGroup => sizeGroup.sizes)
        .flat()
        .map(size => size.price)
        .filter(price => price)
        .some(price => price.isDiscounted);
    },
    isLoading() {
      return this.statusOfProducts === statuses.LOADING;
    },
    sizes() {
      const { currentVariant } = this;
      let sizes = [];

      if (!currentVariant) return sizes;

      if (currentVariant.sizeGroups.length > 1) {
        sizes = currentVariant.sizeGroups;
      } else {
        sizes = currentVariant.sizeGroups
          .map(sizeGroup => sizeGroup.sizes)
          .flat();
      }

      return sizes;
    },
    ungroupedSizes() {
      if (!this.currentVariant || !this.currentVariant.sizeGroups) return [];

      return this.currentVariant.sizeGroups
        .map(sizeGroup => sizeGroup.sizes)
        .flat();
    },
  },
  watch: {
    endCustomerQuickBuyOpen: {
      handler: function(newValue) {
        if (newValue) return;
        const {
          $route: { query },
          $router,
        } = this;

        const { mode, productId, variantId, ...remainingQueryParams } = query; //eslint-disable-line

        $router.push({ query: remainingQueryParams });
      },
      deep: true,
    },
    "$route.query": {
      handler: function(newValue, oldValue) {
        if (!isEqual(newValue.productId, oldValue.productId)) {
          this.getProduct();
        }

        if (!isEqual(newValue.variantId, oldValue.variantId)) {
          this.UPDATE_CURRENT_VARIANT_ID({ id: newValue.variantId });
          this.selectedSize = null;
        }
      },
      deep: true,
    },
  },
  created() {
    if (!this.hasAccessToShop) {
      this.redirectToNoAccessView();
      return;
    }

    this.getProduct();

    const { variantId } = this.$route.query;

    if (!variantId) return;

    this.GET_VARIANTS_FOR_PRODUCT_BLOCK({ variantIds: [variantId] });
  },
  destroyed() {
    this.UPDATE_CURRENT_PRODUCT({ product: null });
  },
  methods: {
    ...mapActions("imageGallery", ["UPDATE_IMAGES"]),
    ...mapActions("products", [
      "GET_PRODUCT",
      "UPDATE_CURRENT_PRODUCT",
      "UPDATE_CURRENT_VARIANT_ID",
      "GET_VARIANTS_FOR_PRODUCT_BLOCK",
      "TOGGLE_END_CUSTOMER_QUICK_BUY",
    ]),
    selectSize(size) {
      this.selectedSize = size;
    },
    getProduct() {
      const { productId, variantId } = this.$route.query;

      this.UPDATE_CURRENT_PRODUCT({ product: {} });
      this.GET_PRODUCT({ id: productId });
      this.UPDATE_CURRENT_VARIANT_ID({ id: variantId });
    },
    handleFooterText(text) {
      this.footerButtonLabel = text;
    },
    handleFooterDisabled(disabled) {
      this.shouldDisableFooterButton = disabled;
    },
    handleFooterButtonLoading(loading) {
      this.isLoadingFooterButton = loading;
    },
    addToBasket() {
      this.addToBasketClicked = true;
    },
  },
};
</script>
<style scoped lang="scss">
.quick-buy-product-details {
  &__block {
    margin-bottom: 0;
  }

  &__blocks {
    @media (min-width: 64rem) {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
    }
  }

  &__close {
    @include reset-button();

    display: none;

    @media (min-width: 64rem) {
      display: block;
      position: absolute;
      right: calc(var(--spacing-container) * 0.5);
      top: var(--size-modal-icon);
      z-index: 1;
    }

    &,
    &-icon {
      height: var(--size-modal-icon);
      width: var(--size-modal-icon);
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
    }
  }

  &__content {
    position: relative;
  }

  &__description {
    font-size: 0.9375rem;
    line-height: 1.6;
    max-width: 40rem;
    white-space: pre-line;
  }

  &__discount-label {
    align-items: center;
    background-color: var(--color-product-label);
    border-radius: 50%;
    color: var(--color-product-label-text);
    display: flex;
    font-size: 0.6875rem;
    font-weight: 600;
    height: var(--size-product-label);
    justify-content: center;
    text-transform: uppercase;
    width: var(--size-product-label);
  }

  &__go-to-checkout {
    @media (min-width: 64rem) {
      display: none;
    }
  }

  &__label {
    font-size: 0.9375rem;
    margin: 0 0 calc(var(--spacing-stack) * 0.5);

    &--slim {
      font-weight: 400;
    }
  }

  &__read-more {
    overflow: hidden;
    position: relative;

    &--collapsed {
      cursor: pointer;
      height: 8rem; /* three lines of text above */

      &::after,
      &::before {
        display: block !important;
      }
    }

    &::after {
      content: attr(title);
      font-size: 0.9375rem;
      font-weight: 700;
      line-height: 1.5;
    }

    &::after,
    &::before {
      bottom: 0;
      display: none;
      position: absolute;
    }

    &::before {
      background: linear-gradient(
        to bottom,
        rgba(var(--color-product-text-background), 0) 0%,
        rgba(var(--color-product-text-background), 1) 70%
      );
      content: "";
      height: 8rem; /* three lines of text above */
      left: 0;
      right: 0;
    }
  }

  &__service-item {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__service-items {
    @include reset-list();
  }

  &__title {
    font-family: var(--font-family-inter);
    font-size: 1.5rem;
    font-weight: 900;
    margin: 0;

    @media (min-width: 31.25rem) {
      font-size: 1.75rem;
    }

    @media (min-width: 100rem) {
      font-size: 2.125rem;
    }
  }
}
</style>
