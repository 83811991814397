<template>
  <div class="back-to-top">
    <transition name="fade">
      <button
        v-show="isVisible"
        class="back-to-top__button"
        @click="scrollToTop"
      >
        {{ getDictionaryEntry("Common.BackToTop") }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="back-to-top__icon"
          viewBox="0 0 24 24"
        >
          <defs />
          <path d="M12 19V5M5 12l7-7 7 7" />
        </svg>
      </button>
    </transition>
    <div ref="indicator" class="back-to-top__indicator"></div>
  </div>
</template>

<script>
export default {
  name: "BackToTop",
  data() {
    return {
      isVisible: false,
      observer: null,
    };
  },
  created() {
    this.observer = new IntersectionObserver(entries =>
      entries.forEach(entry => {
        this.isVisible = !entry.isIntersecting;
      }),
    );
  },
  destroyed() {
    this.observer.disconnect();
  },
  mounted() {
    this.observer.observe(this.$refs.indicator);
  },
  methods: {
    scrollToTop() {
      window.scrollTo({
        behavior: "smooth",
        top: 0,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.back-to-top {
  &__button {
    @include reset-button();

    align-items: center;
    background-color: rgba(0, 0, 0, 0.65);
    border-radius: 4rem;
    bottom: calc(
      var(--spacing-container) * 2 + var(--size-sales-representative-trigger)
    );
    color: var(--color-button-text);
    display: flex;
    font-size: 0.8125rem;
    letter-spacing: 0.025rem;
    padding: var(--spacing-button-small);
    position: fixed;
    right: var(--spacing-container);
    text-transform: uppercase;
    z-index: 95;

    @media (min-width: 48rem) {
      bottom: calc(
        var(--spacing-container) * 3 + var(--size-sales-representative-trigger)
      );
      right: calc(var(--spacing-container) * 2);
    }

    .navigation-sidebar & {
      bottom: var(--spacing-container);
      right: var(--spacing-container);

      @media (min-width: 48rem) {
        bottom: var(--spacing-container);
        right: var(--spacing-container);
      }

      @media (min-width: 64rem) {
        bottom: var(--spacing-container-with-navigation-sidebar);
        right: var(--spacing-container-with-navigation-sidebar);
      }
    }
  }

  &__icon {
    fill: none;
    height: var(--size-back-to-top-icon);
    margin-left: calc(var(--size-back-to-top-icon) * 0.25);
    stroke: var(--color-button-text);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2px;
    width: var(--size-back-to-top-icon);
  }

  &__indicator {
    bottom: 0;
    left: 0;
    pointer-events: none;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
  }
}
</style>
