import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("branding", ["productFallbackImage"]),
  },
  methods: {
    doesProductImageExist(product) {
      return (
        product.variants &&
        product.variants.length &&
        product.variants[0].images &&
        product.variants[0].images.length &&
        product.variants[0].images[0] &&
        product.variants[0].images[0].thumbnail
      );
    },
    doesVariantImageExist(variant) {
      return (
        variant &&
        variant.images &&
        variant.images.length &&
        variant.images[0].thumbnail
      );
    },
    getProductImageAttribute(product, attribute) {
      if (!attribute || !this.doesProductImageExist(product)) return "";

      const attributeOfProductImage =
        product.variants[0].images[0].thumbnail[attribute];

      if (attribute === "src") {
        return attributeOfProductImage || this.productFallbackImage;
      }

      return attributeOfProductImage;
    },
    getVariantImageAttribute(variant, attribute) {
      if (!attribute || !this.doesVariantImageExist(variant)) return "";

      const attributeOfVariantImage = variant.images[0].thumbnail[attribute];

      if (attribute === "src") {
        return attributeOfVariantImage || this.productFallbackImage;
      }

      return attributeOfVariantImage;
    },
  },
};
