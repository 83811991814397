import axios from "axios";
import router from "@/router";
import store from "@/store";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { getOidcClient } from "@/helpers/oidc-client";
import { statuses } from "@/helpers/statuses";
import { ProductViewMode } from "@/models/productViewMode";

const oidcClient = getOidcClient();

oidcClient.events.addAccessTokenExpired(() => {
  store.dispatch("authentication/LOGOUT", null, { root: true });
});

oidcClient.events.addUserLoaded((user) => {
  store.dispatch(
    "authentication/CHECK_AUTHENTICATION",
    { user },
    { root: true },
  );
});

const state = () => ({
  accessToken: null,
  isAuthenticated: false,
  status: "",
  userConfiguration: null,
  userId: null,
});

const getters = {
  accessToken: (state) => state.accessToken,
  allowDiscountOnCheckout: (state) => {
    return state?.userConfiguration?.allowDiscountOnCheckout || false;
  },
  allowShopAs: (state) => {
    return state?.userConfiguration?.allowShopAs || false;
  },
  contextSizeChart: (state) => {
    return state?.userConfiguration?.contextSizeChart || null;
  },
  customerId: (state) => {
    return state?.userConfiguration?.contextInternalCustomerId || null;
  },
  customerName: (state) => {
    return state?.userConfiguration?.customerName || null;
  },
  divisionCode: (state) => {
    return state?.userConfiguration?.divisionCode || null;
  },
  doesUserHaveShoppingContextType: (state) => {
    if (!state?.userConfiguration?.shoppingContextType) return false;

    return state.userConfiguration.shoppingContextType.toLowerCase() !== "none";
  },
  isPreOrderState: (state) => {
    return state?.userConfiguration?.isPreOrderState || false;
  },
  isShopToState: (state) => {
    return state?.userConfiguration?.isShopToState || false;
  },
  isShopToStateAllowed: (state) => {
    return state?.userConfiguration?.shopToStateAllowed || false;
  },
  isUserAuthenticated: (state) => state.isAuthenticated,
  isUserCustomerRepresentative: (state) => {
    return state?.userConfiguration?.customerService || false;
  },
  isUserDealer: (state) => {
    return state?.userConfiguration?.isDealer || false;
  },
  isUserEndCustomer: (state) => {
    return state?.userConfiguration?.isEndCustomer || false;
  },
  isUserSalesperson: (state) => {
    return state?.userConfiguration?.salesPerson || false;
  },
  isUserSystemadministrator: (state) => {
    return state?.userConfiguration?.sysAdmin || false;
  },
  isUserSystemUser: (state, getters) => {
    return (
      getters.isUserCustomerRepresentative ||
      getters.isUserSalesperson ||
      getters.isUserSystemadministrator
    );
  },
  onBehalfSizeChart: (state) => {
    return state?.userConfiguration?.onBehalfSizeChart || null;
  },
  onBehalfUserName: (state) => {
    return state?.userConfiguration?.onBehalfUserName || null;
  },
  preOrderStateAllowed: (state) => {
    return state?.userConfiguration?.preOrderStateAllowed || false;
  },
  shouldUseWallet: (state) => {
    return state?.userConfiguration?.useWallet || false;
  },
  siteLanguage: (state) => {
    return state?.userConfiguration?.contextSiteLanguage || null;
  },
  statusOfAuthentication: (state) => state.status,
  userConfiguration: (state) => state.userConfiguration,
  userName: (state) => {
    return state?.userConfiguration?.userName || null;
  },
  userId: (state) => state.userId,
};

const actions = {
  CHECK_AUTHENTICATION({ commit }, payload) {
    commit("SET_AUTHENTICATION_STATUS", { status: statuses.LOADING });

    if (payload && payload.user) {
      commit("SET_AUTHENTICATION_STATUS", { status: statuses.SUCCESS });
      commit("SET_AUTHENTICATION", {
        accessToken: payload.user.access_token,
        isAuthenticated: true,
        userId: payload.user.profile.sub,
      });
    } else {
      return oidcClient
        .getUser()
        .then((user) => {
          commit("SET_AUTHENTICATION_STATUS", { status: statuses.SUCCESS });
          if (user) {
            commit("SET_AUTHENTICATION", {
              accessToken: user.access_token,
              isAuthenticated: true,
              userId: user.profile.sub,
            });
          } else {
            commit("SET_AUTHENTICATION", {
              accessToken: null,
              isAuthenticated: false,
              userId: null,
            });
            errorAlert();
          }
        })
        .catch(() => {
          commit("SET_AUTHENTICATION_STATUS", { status: statuses.FAILURE });
          commit("SET_AUTHENTICATION", { isAuthenticated: false, user: {} });
          errorAlert();
        });
    }
  },
  GET_USER_CONFIGURATION({ commit, dispatch, getters }) {
    commit("SET_AUTHENTICATION_STATUS", { status: statuses.LOADING });

    const userId = getters.userId;

    return axios
      .get(`${endpoints.ECOMMERCE}/users/${userId}/configuration`)
      .then(({ data: userConfiguration }) => {
        commit("SET_AUTHENTICATION_STATUS", { status: statuses.SUCCESS });
        commit("SET_USER_CONFIGURATION", { userConfiguration });

        dispatch("siteSettings/GET_DICTIONARY", {}, { root: true });
        dispatch("addresses/RESET_ADDRESSES", {}, { root: true });
        dispatch("checkout/RESET_DELIVERY_INFORMATION", {}, { root: true });
        dispatch("checkout/RESET_STEPS", {}, { root: true });

        if (userConfiguration.allowShopAs) {
          dispatch("shopAsUser/GET_STATE_OF_SHOP_AS", null, {
            root: true,
          });
        } else if (getters.shouldUseWallet) {
          dispatch(
            "wallets/GET_BALANCE_OF_WALLET",
            { id: userId },
            { root: true },
          );
        }

        if (userConfiguration?.isDealer) {
          dispatch("products/PREPARE_DEALER_PRICES", null, { root: true });
        }
      })
      .catch((error) => {
        commit("SET_AUTHENTICATION_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  LOGIN({ commit }) {
    commit("SET_AUTHENTICATION_STATUS", { status: statuses.LOADING });
    return oidcClient.signinRedirect();
  },
  LOGIN_REDIRECT_CALLBACK({ commit, dispatch }) {
    commit("SET_AUTHENTICATION_STATUS", { status: statuses.LOADING });

    return oidcClient
      .signinRedirectCallback()
      .then((user) => {
        //Avoid going to the Callback route when using the "back"-button
        window.history.replaceState(
          {},
          window.document.title,
          `${window.location.origin}/#/`,
        );

        commit("SET_AUTHENTICATION_STATUS", { status: statuses.SUCCESS });
        commit("SET_AUTHENTICATION", {
          accessToken: user.access_token,
          isAuthenticated: true,
          userId: user.profile.sub,
        });

        dispatch("branding/GET_BRANDS", null, { root: true }).then(() => {
          dispatch("GET_USER_CONFIGURATION");
        });
      })
      .catch(() => {
        commit("SET_AUTHENTICATION_STATUS", { status: statuses.FAILURE });
        router.push({ name: "login" });
      });
  },
  LOGOUT({ commit }) {
    commit("SET_AUTHENTICATION_STATUS", { status: statuses.LOADING });

    return oidcClient
      .signoutRedirect()
      .then(() => {
        commit("SET_AUTHENTICATION", {
          accessToken: null,
          isAuthenticated: false,
          userId: null,
        });
        localStorage.removeItem("redirect_after_login");
      })
      .catch(() => {
        commit("SET_AUTHENTICATION_STATUS", { status: statuses.FAILURE });
        commit("SET_AUTHENTICATION", {
          accessToken: null,
          isAuthenticated: false,
          userId: null,
        });
      });
  },
  UPDATE_CURRENT_LANGUAGE({ commit, dispatch, getters }, payload) {
    commit("SET_AUTHENTICATION_STATUS", { status: statuses.LOADING });

    const userConfiguration = {
      ...getters.userConfiguration,
      contextSiteLanguage: payload.languageCode,
    };

    commit("SET_USER_CONFIGURATION", { userConfiguration });
    dispatch("siteSettings/GET_DICTIONARY", null, {
      root: true,
    });

    commit("SET_AUTHENTICATION_STATUS", { status: statuses.SUCCESS });
  },
  UPDATE_PRE_ORDER_STATE({ commit }, payload) {
    commit("SET_PRE_ORDER_STATE", { value: payload.value });
  },
  UPDATE_SHOP_TO_STATE({ commit }, payload) {
    commit("SET_SHOP_TO_STATE", { value: payload.value });
  },
  UPDATE_LIST_VIEW_MODE({ commit, getters }, payload) {
    commit("SET_AUTHENTICATION_STATUS", { status: statuses.LOADING });

    const userId = getters.userId;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/users/${userId}/updateListViewMode/${payload.mode}`,
      )
      .then(() => {
        commit("SET_AUTHENTICATION_STATUS", { status: statuses.SUCCESS });
        const userConfiguration = {
          ...getters.userConfiguration,
          userListViewModeConfiguration:
            payload.mode === ProductViewMode.ListViewMode.value
              ? ProductViewMode.ListViewMode.key
              : ProductViewMode.CardViewMode.key,
        };
        commit("SET_USER_CONFIGURATION", { userConfiguration });
      })
      .catch(() => {
        commit("SET_AUTHENTICATION_STATUS", { status: statuses.FAILURE });
      });
  },
};

const mutations = {
  SET_AUTHENTICATION(state, payload) {
    const { accessToken, isAuthenticated, userId } = payload;

    state.accessToken = accessToken;
    state.isAuthenticated = isAuthenticated;
    state.userId = userId;

    axios.defaults.headers.common["Authorization"] = accessToken
      ? `Bearer ${accessToken}`
      : "";
  },
  SET_AUTHENTICATION_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_PRE_ORDER_STATE(state, payload) {
    state.userConfiguration = {
      ...state.userConfiguration,
      isPreOrderState: payload.value,
    };
  },
  SET_SHOP_TO_STATE(state, payload) {
    state.userConfiguration = {
      ...state.userConfiguration,
      isShopToState: payload.value,
    };
  },
  SET_USER_CONFIGURATION(state, payload) {
    const { userConfiguration } = payload;

    state.userConfiguration = userConfiguration;

    const language = userConfiguration.contextSiteLanguage;

    axios.defaults.headers.common["Accept-Language"] = language;
    axios.defaults.headers.common["Fengel-Culture"] = language;
    axios.defaults.headers.common["Fengel-UICulture"] = language;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
