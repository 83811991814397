<template>
  <img
    :attrs="$attrs"
    :data-src="src"
    :data-srcset="srcset"
    class="lazyload"
    data-sizes="auto"
    decoding="async"
    loading="lazy"
  />
</template>

<script>
import { endpoints } from "@/helpers/endpoints";

export default {
  name: "ImageFromUmbraco",
  props: {
    id: {
      required: true,
      type: String,
    },
    keysAndSizes: {
      default: null,
      type: Object,
    },
  },
  data() {
    return {
      viewportWidth: window.innerWidth,
      widths: {
        s: 375,
        m: 1024,
        l: 1366,
        xl: 1920,
        xxl: 2560,
      },
    };
  },
  computed: {
    idealWidth() {
      const { viewportWidth } = this;

      const keys = Object.values(this.widths);
      const values = Object.values(this.widths);

      const indexOfDirectMatch = values.findIndex(
        value => value === viewportWidth,
      );

      if (indexOfDirectMatch !== -1) return keys[indexOfDirectMatch];

      const indexOfClosestLargerMatch = values.findIndex(
        value => value > viewportWidth,
      );

      if (indexOfClosestLargerMatch === -1) return keys[keys.length - 1];

      const closestSmallerMatch = values[indexOfClosestLargerMatch - 1];
      const closestLargerMatch = values[indexOfClosestLargerMatch];

      const differenceToSmallerMatch = Math.abs(
        viewportWidth - closestSmallerMatch,
      );
      const differenceToLargerMatch = Math.abs(
        viewportWidth - closestLargerMatch,
      );

      let indexOfBestMatch = null;

      if (differenceToSmallerMatch === differenceToLargerMatch) {
        indexOfBestMatch = indexOfClosestLargerMatch;
      } else if (differenceToSmallerMatch > differenceToLargerMatch) {
        indexOfBestMatch = indexOfClosestLargerMatch;
      } else {
        indexOfBestMatch = indexOfClosestLargerMatch - 1;
      }

      return keys[indexOfBestMatch];
    },
    src() {
      const { id, idealWidth, keysAndSizes } = this;

      if (!keysAndSizes) {
        return `${endpoints.CONTENT}/media/${id}`;
      }

      const matchingKey = keysAndSizes[idealWidth];

      if (!matchingKey) return `${endpoints.CONTENT}/media/${id}`;

      return `${endpoints.CONTENT}/media/${id}?key=${matchingKey}`;
    },
    srcset() {
      const { id, keysAndSizes, widths } = this;

      if (!keysAndSizes) return null;

      const isDisplayHighDensity = window.devicePixelRatio > 1.5;
      const keys = Object.keys(keysAndSizes);
      const widthKeys = Object.keys(widths);
      const widthValues = keys.map(key => widths[key]);

      const parts = keys
        .map(key => {
          const indexOfMatchingWidthKey = widthKeys.findIndex(
            widthKey => widthKey === key,
          );

          if (indexOfMatchingWidthKey === -1) return;

          return `${endpoints.CONTENT}/media/${id}?key=${keysAndSizes[key]}${
            isDisplayHighDensity ? "-2x" : ""
          } ${widthValues[indexOfMatchingWidthKey]}w`;
        })
        .filter(part => part);

      if (!parts.length) return null;

      return parts.join(",");
    },
  },
};
</script>
