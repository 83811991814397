<template>
  <transition name="error-animation">
    <div v-if="visible" class="error">
      <svg
        class="error__icon"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          d="M16.971 0h-9.942l-7.029 7.029v9.941l7.029 7.03h9.941l7.03-7.029v-9.942l-7.029-7.029zm-1.402 16.945l-3.554-3.521-3.518 3.568-1.418-1.418 3.507-3.566-3.586-3.472 1.418-1.417 3.581 3.458 3.539-3.583 1.431 1.431-3.535 3.568 3.566 3.522-1.431 1.43z"
        />
      </svg>
      <span>{{ message }}</span>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Error",
  props: {
    message: {
      default: "",
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.error-animation {
  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.error {
  $error-icon-size: 1rem;

  align-items: center;
  color: var(--color-error);
  display: flex;
  font-size: 0.875rem;
  left: 0;
  margin-top: calc(var(--spacing-stack) * 0.25);
  position: absolute;
  z-index: 1;

  .error-summary & {
    position: static;
  }

  &__icon {
    fill: var(--color-error);
    flex: 0 0 auto;
    height: $error-icon-size;
    margin-right: $error-icon-size * 0.4;
    width: $error-icon-size;
  }
}
</style>
