<template>
  <div>
    <div class="images">
      <div class="images__slider">
        <div class="images__selected-container">
          <button
            class="images__action images__action--previous"
            :aria-label="getDictionaryEntry('Common.Previous')"
            :disabled="images.length <= 1"
            @click="SELECT_PREVIOUS_IMAGE"
          >
            <svg class="images__action-icon" viewBox="0 0 24 24">
              <defs />
              <path d="M19 12H5M12 19l-7-7 7-7" />
            </svg>
          </button>
          <img
            :src="
              numberOfImages && selectedImage.src
                ? selectedImage.src
                : productFallbackImage
            "
            :alt="numberOfImages && selectedImage.src ? selectedImage.alt : ''"
            class="images__selected"
            :class="{
              'images__selected--gallery-available':
                numberOfImages && selectedImage.src,
            }"
            @click="
              numberOfImages && selectedImage.src
                ? openImageGallery(selectedImage)
                : null
            "
          />
          <button
            class="images__action images__action--next"
            :aria-label="getDictionaryEntry('Common.Next')"
            :disabled="images.length <= 1"
            @click="SELECT_NEXT_IMAGE"
          >
            <svg class="images__action-icon" viewBox="0 0 24 24">
              <defs />
              <path d="M5 12h14M12 5l7 7-7 7" />
            </svg>
          </button>
        </div>
        <transition-group
          appear=""
          class="images__slider-list"
          name="fade-slide"
          tag="ol"
        >
          <li
            v-for="(image, index) in images"
            :key="image.thumbnail.src"
            class="images__slider-item"
            :style="{ '--index': index }"
          >
            <div
              class="image__container"
              :class="{
                'image__container--interactive': image.large,
                'image__container--selected':
                  image.large.src === selectedImage.src,
              }"
              @click="SELECT_IMAGE({ image: image.large })"
            >
              <img
                :alt="image.thumbnail.alt"
                :height="image.thumbnail.height"
                :src="image.thumbnail.src || productFallbackImage"
                :width="image.thumbnail.width"
                class="image"
                decoding="async"
                loading="lazy"
              />
            </div>
          </li>
        </transition-group>
      </div>
      <div class="images__container" @scroll="handleScroll">
        <transition-group
          appear=""
          class="images__list"
          name="fade-slide"
          tag="ol"
        >
          <li
            v-for="(image, index) in images"
            :key="image.thumbnail.src"
            class="images__item"
            :style="{ '--index': index }"
          >
            <div
              class="image__container"
              :class="{
                'image__container--interactive': image.large,
              }"
              @click="
                image.large
                  ? openImageGallery(image)
                  : openImageGallery({
                      image: { alt: '', src: productFallbackImage },
                    })
              "
            >
              <img
                :alt="image.thumbnail.alt"
                :height="image.thumbnail.height"
                :src="image.thumbnail.src || productFallbackImage"
                :width="image.thumbnail.width"
                class="image"
                decoding="async"
                loading="lazy"
              />
            </div>
          </li>
          <li key="empty-1" class="images__item images__item--empty"></li>
          <li key="empty-2" class="images__item images__item--empty"></li>
          <li key="empty-3" class="images__item images__item--empty"></li>
        </transition-group>
      </div>
      <div class="images__indicator-container">
        <div class="images__indicator">
          <div
            class="images__indicator-line"
            :style="{
              left: `${leftValueForIndicator}%`,
              width: `${100 / numberOfImages}%`,
            }"
          ></div>
        </div>
      </div>
    </div>
    <div v-if="detailImages.length" class="detail-images">
      <ol class="detail-images__list">
        <li
          v-for="image in detailImages"
          :key="image.src"
          class="detail-images__item"
        >
          <div class="detail-images__image-container">
            <img
              :alt="image.large.alt"
              :height="image.large.height"
              :src="image.large.src"
              :width="image.large.width"
              class="detail-images__image"
              decoding="async"
              loading="lazy"
            />
          </div>
        </li>
      </ol>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ProductImages",
  props: {
    images: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      percentageScrolledOfImages: 0,
    };
  },
  computed: {
    ...mapGetters("branding", ["productFallbackImage"]),
    ...mapGetters("imageGallery", ["selectedImage"]),
    detailImages() {
      const { images } = this;

      if (!images || !images.length) return [];

      return images
        .filter(
          image =>
            image.perspective && image.perspective.toLowerCase() === "detail",
        )
        .slice(0, 2);
    },
    leftValueForIndicator() {
      const indicatorWidth = 100 / this.numberOfImages;

      return this.percentageScrolledOfImages * ((100 - indicatorWidth) / 100);
    },
    numberOfImages() {
      return this.images ? this.images.length : 0;
    },
  },
  watch: {
    images() {
      this.setDefaultImage();
    },
  },
  created() {
    this.setDefaultImage();
  },
  methods: {
    ...mapActions("imageGallery", [
      "OPEN_IMAGE_GALLERY",
      "SELECT_IMAGE",
      "SELECT_NEXT_IMAGE",
      "SELECT_PREVIOUS_IMAGE",
    ]),
    handleScroll(event) {
      const { target } = event;

      this.percentageScrolledOfImages =
        (target.scrollLeft / (target.scrollWidth - target.clientWidth)) * 100;
    },
    openImageGallery(image) {
      this.SELECT_IMAGE({ image: image.large || image });
      this.OPEN_IMAGE_GALLERY();
    },
    setDefaultImage() {
      const { SELECT_IMAGE, images } = this;

      if (images && images.length) {
        SELECT_IMAGE({ image: images[0].large });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
$product-images-slider-item-size: 2.9375rem;

.detail-images {
  &__item {
    margin-top: calc(var(--spacing-stack) * 0.75);

    @media (min-width: 48rem) {
      flex-basis: calc(50% - (var(--spacing-stack) * 0.75 * 0.5));
      flex-grow: 0;
      flex-shrink: 0;
    }
  }

  &__image {
    display: block;
    object-fit: contain;

    &-container {
      @include aspect-ratio(1000, 1000);

      background-color: var(--color-product-image-background);
    }
  }

  &__list {
    @include reset-list();

    @media (min-width: 48rem) {
      display: flex;
      justify-content: space-between;
    }
  }
}

.fade-slide {
  &-enter-active {
    transition-delay: calc(100ms * var(--index)) !important;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease, transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }

  &-leave-active {
    position: absolute;
  }
}

.image {
  display: block;
  margin: 0 auto;
  max-height: 50vh;
  max-width: 100%;
  object-fit: contain;

  &__container {
    height: 100%;
    position: relative;
    width: 100%;

    @media (min-width: 64rem) {
      border: 1px solid rgba(0, 0, 0, 0.2);
      padding: 0.25rem;
      transition: border-color 300ms ease;
    }

    &--interactive {
      cursor: pointer;
    }

    &--selected {
      border-color: rgba(0, 0, 0, 0.5);
    }
  }
}

.images {
  position: relative;

  @media (min-width: 64rem) {
    @include aspect-ratio(1244, 790);

    min-height: 30rem;
  }

  &::before {
    background-color: var(--color-product-image-background);
    bottom: 0;
    content: "";
    left: calc(var(--spacing-container) * -1);
    position: absolute;
    right: calc(var(--spacing-container) * -1);
    top: 0;

    @media (min-width: 64rem) {
      left: calc(var(--spacing-container) * -1);
      right: 0;
    }
  }

  &__action {
    @include reset-button();

    background-color: var(--color-body-background-light);
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.06);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(var(--size-product-action-icon) * 2.6);
    width: calc(var(--size-product-action-icon) * 2.6);
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    &[disabled] {
      cursor: not-allowed;
      opacity: 0.25;
    }

    &--previous {
      left: var(--size-product-action-icon);

      @media (min-width: 110.625rem) {
        left: 11.125rem;
      }
    }

    &--next {
      right: var(--size-product-action-icon);

      @media (min-width: 110.625rem) {
        right: 11.125rem;
      }
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      height: var(--size-product-action-icon);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
      stroke-width: 2;
      width: var(--size-product-action-icon);
    }
  }

  &__container {
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    padding-bottom: calc(var(--spacing-container) * 3);
    overflow-x: scroll;
    scroll-behavior: smooth;
    scroll-snap-type: x mandatory;
    scrollbar-width: none;

    &::-webkit-scrollbar {
      display: none;
    }

    @media (min-width: 64rem) {
      display: none;
    }
  }

  &__indicator {
    background-color: rgba(0, 0, 0, 0.2);
    height: 0.25rem;
    max-width: 25rem;
    position: relative;
    width: 100%;

    &-container {
      bottom: calc((var(--spacing-container) * 1.5) - 0.125rem);
      display: flex;
      justify-content: center;
      left: calc(var(--spacing-container) * 1.5);
      position: absolute;
      right: calc(var(--spacing-container) * 1.5);

      @media (min-width: 64rem) {
        display: none;
      }
    }

    &-line {
      background-color: rgba(0, 0, 0, 0.4);
      height: 100%;
      position: absolute;
    }
  }

  &__item {
    flex: 0 0 100%;
    scroll-snap-align: start;

    @media (min-width: 48rem) {
      margin-bottom: var(--spacing-container);
    }

    &--empty {
      height: 0;
      margin: 0 !important;
      width: 0;
    }
  }

  &__list {
    @include reset-list();

    display: flex;

    @media (min-width: 64rem) {
      background-color: transparent;
      display: block;
      scroll-snap-type: none;
    }

    .modal & {
      @media (min-width: 64rem) {
        display: flex;
        flex-flow: row wrap;
        justify-content: space-between;
      }
    }
  }

  &__selected {
    display: block;
    height: 100%;
    height: calc(100% - #{$product-images-slider-item-size * 4});
    max-height: 37.5rem !important;
    max-width: 31.4375rem !important;
    object-fit: contain;
    width: 100%;

    @media (min-width: 110.625rem) {
      height: 100%;
    }

    &--gallery-available {
      cursor: pointer;
    }

    &-container {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      justify-content: center;
      overflow: hidden;
      position: relative;
    }
  }

  &__slider {
    display: none;

    @media (min-width: 64rem) {
      display: flex;
      flex-direction: column;
      height: 100%;
      position: absolute;
    }

    &-item {
      height: $product-images-slider-item-size;
      width: $product-images-slider-item-size;

      &:not(:last-child) {
        margin-right: 0.625rem;

        @media (min-width: 110.625rem) {
          margin: 0 0 0.625rem;
        }
      }
    }

    &-list {
      @include reset-list;

      bottom: var(--size-product-action-icon);
      display: flex;
      left: 50%;
      position: absolute;
      transform: translateX(-50%);

      @media (min-width: 110.625rem) {
        bottom: auto;
        display: block;
        left: 3.625rem;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}
</style>
