import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { alertTypes } from "@/helpers/alertTypes";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentSystemadministrator: null,
  currentSystemadministratorId: null,
  isModalForCreatingSystemadministratorOpen: false,
  selectedSystemadministrators: [],
  status: "",
  systemadministrators: [],
});

const getters = {
  currentSystemadministrator: state => state.currentSystemadministrator,
  currentSystemadministratorId: state => state.currentSystemadministratorId,
  isModalForCreatingSystemadministratorOpen: state =>
    state.isModalForCreatingSystemadministratorOpen,
  nameOfCurrentSystemadministrator: state => {
    const { currentSystemadministrator } = state;
    if (!currentSystemadministrator) return null;

    return currentSystemadministrator.name
      ? currentSystemadministrator.name
      : null;
  },
  selectedSystemadministrators: state => state.selectedSystemadministrators,
  statusOfSystemadministrators: state => state.status,
  systemadministrators: state => state.systemadministrators,
};

const actions = {
  CLOSE_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR", {
      value: false,
    });
  },
  CREATE_SYSTEMADMINISTRATOR({ commit, dispatch }, payload) {
    commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/createSysadmin`, {
          ...payload.systemadministrator,
        })
        .then(({ data }) => {
          commit("SET_SYSTEMADMINISTRATORS_STATUS", {
            status: statuses.SUCCESS,
          });
          dispatch("CLOSE_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR");
          resolve(data);
        })
        .catch(error => {
          commit("SET_SYSTEMADMINISTRATORS_STATUS", {
            status: statuses.FAILURE,
          });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_SELECTED_SYSTEMADMINISTRATORS({ commit, dispatch, state }) {
    commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/users/delete-multiple`, {
        identifiers: state.selectedSystemadministrators.map(
          systemadministrator => systemadministrator.id,
        ),
      })
      .then(() => {
        commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_SYSTEMADMINISTRATORS");
        commit("DESELECT_SYSTEMADMINISTRATORS");
      })
      .catch(error => {
        commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SYSTEMADMINISTRATOR({ commit }, payload) {
    commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/users/${payload.id}`)
      .then(({ data }) => {
        commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_SYSTEMADMINISTRATOR", {
          systemadministrator: data,
        });
      })
      .catch(error => {
        commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SYSTEMADMINISTRATORS({ commit }) {
    commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/users/listsystemroles?filterType=SysAdmin`)
      .then(({ data }) => {
        commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SYSTEMADMINISTRATORS", { systemadministrators: data });
      })
      .catch(error => {
        commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR", {
      value: true,
    });
  },
  TOGGLE_SYSTEMADMINISTRATOR({ commit }, payload) {
    commit("SET_SELECTED_SYSTEMADMINISTRATOR", payload);
  },
  TOGGLE_SYSTEMADMINISTRATORS({ commit }, payload) {
    commit("SET_SELECTED_SYSTEMADMINISTRATORS", payload);
  },
  UPDATE_CURRENT_SYSTEMADMINISTRATOR_ID({ commit }, payload) {
    commit("SET_CURRENT_SYSTEMADMINISTRATOR_ID", payload);
    commit("SET_SELECTED_SYSTEMADMINISTRATORS");
  },
  UPDATE_SYSTEMADMINISTRATOR({ commit, dispatch }, payload) {
    commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.SAVING });
    const { id, systemadministrator } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/users/${id}`, systemadministrator)
      .then(({ data }) => {
        commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SYSTEMADMINISTRATOR", { systemadministrator: data });
        commit("SET_CURRENT_SYSTEMADMINISTRATOR", {
          systemadministrator: data,
        });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "Common.YourChangesWereSaved",
            type: alertTypes.SUCCESS,
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  WELCOME_SELECTED_SYSTEMADMINISTRATOR({ commit, dispatch, state }) {
    commit("SET_SYSTEMADMINISTRATORS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/send-welcomemail`, {
          identifiers: state.selectedSystemadministrators.map(
            admin => admin.id,
          ),
        })
        .then(response => {
          commit("SET_SYSTEMADMINISTRATORS_STATUS", {
            status: statuses.SUCCESS,
          });
          commit("DESELECT_SYSTEMADMINISTRATORS");
          dispatch("GET_SYSTEMADMINISTRATORS");
          dispatch(
            "alerts/OPEN_ALERT",
            {
              key: "Administration.WelcomeEmailsSuccessfullySent",
              type: alertTypes.SUCCESS,
            },
            {
              root: true,
            },
          );
          resolve(response);
        })
        .catch(error => {
          commit("SET_SYSTEMADMINISTRATORS_STATUS", {
            status: statuses.FAILURE,
          });
          errorAlert(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  DESELECT_SYSTEMADMINISTRATORS(state) {
    state.selectedSystemadministrators = [];
  },
  SET_CURRENT_SYSTEMADMINISTRATOR(state, payload) {
    state.currentSystemadministrator = payload.systemadministrator;
  },
  SET_CURRENT_SYSTEMADMINISTRATOR_ID(state, payload) {
    state.currentSystemadministratorId = payload.id;
  },
  SET_SELECTED_SYSTEMADMINISTRATOR(state, payload) {
    const { row: systemadministrator, value } = payload;

    if (value) {
      if (
        state.selectedSystemadministrators.some(
          entry => entry.id === systemadministrator.id,
        )
      ) {
        return;
      }

      state.selectedSystemadministrators.push(systemadministrator);
    } else {
      state.selectedSystemadministrators = state.selectedSystemadministrators.filter(
        selectedSystemadministrator =>
          selectedSystemadministrator.id !== systemadministrator.id,
      );
    }
  },
  SET_SELECTED_SYSTEMADMINISTRATORS(state, payload) {
    if (!payload) {
      state.selectedSystemadministrators = [];
      return;
    }

    const { rows: systemadministrators, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedSystemadministrators.map(
          selectedSystemadministrator => selectedSystemadministrator.id,
        ),
      );

      state.selectedSystemadministrators = [
        ...state.selectedSystemadministrators,
        ...systemadministrators
          .filter(systemadministrator => !systemadministrator.disableSelection)
          .filter(
            systemadministrator => !identifiers.has(systemadministrator.id),
          ),
      ];
    } else {
      state.selectedSystemadministrators = state.selectedSystemadministrators.filter(
        selectedSystemadministrator =>
          !systemadministrators.some(
            entry => entry.id === selectedSystemadministrator.id,
          ),
      );
    }
  },
  SET_SYSTEMADMINISTRATORS(state, payload) {
    state.systemadministrators = payload.systemadministrators;
  },
  SET_STATE_OF_MODAL_FOR_CREATING_SYSTEMADMINISTRATOR(state, payload) {
    state.isModalForCreatingSystemadministratorOpen = payload.value;
  },
  SET_SYSTEMADMINISTRATORS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
