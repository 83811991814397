<template>
  <div class="product-details">
    <Modal
      :arrow-as-close-icon="true"
      :close-from-parent="closeModalFromParent"
      :title="currentProduct ? currentProduct.name : ''"
      :visible="true"
      @close="handleClose"
    >
      <template #body>
        <DynamicData :loading="isLoading" transition="fade">
          <Stack>
            <Container>
              <div
                v-if="currentProduct && currentVariant"
                class="product-details__blocks"
              >
                <div class="product-details__block">
                  <ProductImages
                    :images="currentVariant.images ? currentVariant.images : []"
                  />
                </div>
                <div class="product-details__block">
                  <button
                    class="product-details__close"
                    :aria-label="getDictionaryEntry('Common.Close')"
                    @click="closeModal"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 21 21"
                      class="product-details__close-icon"
                    >
                      <defs />
                      <g>
                        <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
                      </g>
                    </svg>
                  </button>
                  <div class="product-details__content">
                    <Stack
                      v-if="isDiscounted"
                      :half="true"
                      class="product-details__discount-label"
                    >
                      {{ getDictionaryEntry("Product.DiscountLabel") }}
                    </Stack>
                    <Stack>
                      <InformationList
                        :information="{
                          ...currentProduct,
                          id: currentVariant.id,
                        }"
                      />
                    </Stack>
                    <Stack v-if="currentProduct.name" :half="true">
                      <h2 class="product-details__title">
                        {{ currentProduct.name }}
                      </h2>
                    </Stack>
                    <Stack
                      v-if="selectedSize && selectedSize.price"
                      :half="true"
                    >
                      <Price :price="selectedSize.price" />
                    </Stack>
                    <Stack
                      v-if="
                        currentProduct.serviceItems &&
                          currentProduct.serviceItems.length
                      "
                    >
                      <ol class="product-details__service-items">
                        <li
                          v-for="serviceItem in currentProduct.serviceItems"
                          :key="serviceItem.id"
                          class="product-details__service-item"
                        >
                          + {{ serviceItem.productNumber }}
                          {{ serviceItem.name }}
                        </li>
                      </ol>
                    </Stack>
                    <Stack
                      v-if="currentVariant.swatch && currentVariant.swatch.alt"
                      :one-and-half="true"
                    >
                      <h3 class="product-details__label">
                        {{ getDictionaryEntry("Common.Labels.SelectColor") }}
                        <span
                          class="product-details__label product-details__label--slim"
                          >- {{ currentVariant.swatch.alt }}</span
                        >
                      </h3>
                      <Swatches :product="currentProduct" />
                    </Stack>
                    <Stack
                      v-if="
                        currentVariant.purchaseLimitationDescriptions &&
                          currentVariant.purchaseLimitationDescriptions.length
                      "
                      :one-and-half="true"
                    >
                      <h3 class="product-details__label">
                        {{
                          getDictionaryEntry(
                            "Administration.PurchaseLimitations",
                          )
                        }}
                      </h3>
                      <div
                        ref="readMoreLimitations"
                        class="product-details__read-more"
                        :class="{
                          'product-details__read-more--collapsed': shouldShowReadMoreLimitations,
                        }"
                        :title="
                          shouldShowReadMoreLimitations
                            ? getDictionaryEntry('Common.ShowMore')
                            : null
                        "
                        @click="
                          shouldShowReadMoreLimitations
                            ? expandReadMoreLimitations()
                            : null
                        "
                      >
                        <p
                          v-for="(description,
                          index) in currentVariant.purchaseLimitationDescriptions"
                          :key="index"
                          class="product-details__description"
                        >
                          {{ description }}
                        </p>
                      </div>
                    </Stack>
                    <Stack :one-and-half="true">
                      <h3
                        v-if="
                          currentVariant.purchaseLimitationDescriptions &&
                            currentVariant.purchaseLimitationDescriptions.length
                        "
                        class="product-details__label"
                      >
                        {{ getDictionaryEntry("Product.Description") }}
                      </h3>
                      <div
                        ref="readMoreDescription"
                        class="product-details__read-more"
                        :class="{
                          'product-details__read-more--collapsed': shouldShowReadMoreDescription,
                        }"
                        :title="
                          shouldShowReadMoreDescription
                            ? getDictionaryEntry('Common.ShowMore')
                            : null
                        "
                        @click="
                          shouldShowReadMoreDescription
                            ? expandReadMoreDescription()
                            : null
                        "
                      >
                        <Stack
                          v-if="currentProduct.shortDescription"
                          :one-and-half="true"
                        >
                          <p class="product-details__description">
                            {{ currentProduct.shortDescription }}
                          </p>
                        </Stack>
                        <Stack
                          v-if="currentProduct.usp && currentProduct.usp.length"
                        >
                          <UspList :items="currentProduct.usp" />
                        </Stack>
                      </div>
                    </Stack>
                    <Stack v-if="isUserDealer" :one-and-half="true">
                      <router-link
                        class="button button--buy button--round"
                        :to="quickBuyLink"
                      >
                        {{ getDictionaryEntry("Product.BulkPurchase") }}
                      </router-link>
                    </Stack>
                    <Stack v-else-if="isShopToState" :one-and-half="true">
                      <ShopToSizeSelector :sizes="ungroupedSizes" />
                    </Stack>
                    <Stack v-else :one-and-half="true">
                      <h3 class="product-details__label">
                        {{ getDictionaryEntry("Common.Labels.SelectSize") }}
                      </h3>
                      <SizeSelector
                        :handle-size-select="selectSize"
                        :has-size-groups="hasSizeGroups"
                        :selected-size="selectedSize"
                        :sizes="sizes"
                      />
                    </Stack>
                    <Stack :half="true" class="product-details__go-to-checkout">
                      <router-link
                        :to="{ name: 'checkout' }"
                        class="button button--dark button--round"
                        @click.native="close"
                        >{{ getDictionaryEntry("Checkout.GoToCheckout") }}
                      </router-link>
                    </Stack>
                    <Stack :one-and-half="true">
                      <ProductInformation
                        :product="currentProduct"
                        :variant="currentVariant"
                      />
                    </Stack>
                  </div>
                </div>
              </div>
            </Container>
          </Stack>
          <Stack v-if="currentProduct" :triple="true">
            <Container :medium="true">
              <div class="block__content">
                <div>
                  <div v-if="currentProduct.brand" class="block__keywords">
                    {{ currentProduct.brand }}
                  </div>
                  <h2 v-if="currentProduct.name" class="block__title">
                    {{ currentProduct.name }}
                  </h2>
                  <p
                    v-if="currentProduct.description"
                    class="block__description"
                  >
                    {{ currentProduct.description }}
                  </p>
                </div>
              </div>
            </Container>
          </Stack>
          <Stack
            v-if="alternativeVariants && alternativeVariants.length"
            :triple="true"
          >
            <RelatedProducts
              :parent-id="currentVariant.id"
              :variant-ids="alternativeVariants"
              :title="getDictionaryEntry('Product.RelatedProducts')"
            />
          </Stack>
          <Stack
            v-if="combinesWithVariants && combinesWithVariants.length"
            :triple="true"
          >
            <RelatedProducts
              :parent-id="currentVariant.id"
              :variant-ids="combinesWithVariants"
              :title="getDictionaryEntry('Product.CombinesWith')"
            />
          </Stack>
        </DynamicData>
      </template>
    </Modal>
    <ImageGallery />
  </div>
</template>

<script>
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";
import Container from "@/components/Container";
import DynamicData from "@/components/DynamicData";
import ImageGallery from "@/components/ImageGallery";
import InformationList from "@/components/InformationList";
import Modal from "@/components/Modal";
import Price from "@/components/Price";
import ProductImages from "@/components/ProductImages";
import ProductInformation from "@/components/ProductInformation";
import RelatedProducts from "@/components/RelatedProducts";
import ShopToSizeSelector from "@/components/ShopToSizeSelector";
import SizeSelector from "@/components/SizeSelector";
import Stack from "@/components/Stack";
import Swatches from "@/components/Swatches";
import UspList from "@/components/UspList";
import { pushToDataLayer } from "@/helpers/tracking";
import { slideDown } from "@/helpers/slide";
import { statuses } from "@/helpers/statuses";

export default {
  name: "Product",
  components: {
    Container,
    DynamicData,
    ImageGallery,
    InformationList,
    Modal,
    Price,
    ProductImages,
    ProductInformation,
    RelatedProducts,
    ShopToSizeSelector,
    SizeSelector,
    Stack,
    Swatches,
    UspList,
  },
  data() {
    return {
      closeModalFromParent: false,
      selectedSize: null,
      shouldShowReadMoreDescription: false,
      shouldShowReadMoreLimitations: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["isShopToState", "isUserDealer"]),
    ...mapGetters("branding", ["productFallbackImage"]),
    ...mapGetters("products", [
      "currentProduct",
      "currentVariant",
      "statusOfProducts",
    ]),
    alternativeVariants() {
      if (!this.currentVariant) return [];
      return this.currentVariant.alternativeVariants;
    },
    combinesWithVariants() {
      if (!this.currentVariant) return [];
      return this.currentVariant.combinesWithVariants;
    },
    hasSizeGroups() {
      return this.currentVariant.sizeGroups.length > 1;
    },
    isDiscounted() {
      if (!this.currentVariant) return false;

      const { sizeGroups } = this.currentVariant;

      if (!sizeGroups || !sizeGroups.length) return false;

      return sizeGroups
        .map(sizeGroup => sizeGroup.sizes)
        .flat()
        .map(size => size.price)
        .filter(price => price)
        .some(price => price.isDiscounted);
    },
    isLoading() {
      return this.statusOfProducts === statuses.LOADING;
    },
    quickBuyLink() {
      const { $route, currentProduct, currentVariant } = this;

      if (!currentProduct || !currentVariant) return null;

      return {
        query: {
          ...$route.query,
          mode: "details_quick-buy",
          productId: currentProduct.id,
          variantId: currentVariant.id,
        },
      };
    },

    shouldAccountForSizeGroups() {
      const { currentVariant } = this;

      if (
        !currentVariant ||
        !currentVariant.sizeGroups ||
        !currentVariant.sizeGroups ||
        currentVariant.sizeGroups.length < 2
      ) {
        return false;
      }

      return true;
    },
    sizes() {
      const { currentVariant } = this;
      let sizes = [];

      if (!currentVariant) return sizes;

      if (currentVariant.sizeGroups.length > 1) {
        sizes = currentVariant.sizeGroups;
      } else {
        sizes = currentVariant.sizeGroups
          .map(sizeGroup => sizeGroup.sizes)
          .flat();
      }

      return sizes;
    },
    ungroupedSizes() {
      if (!this.currentVariant || !this.currentVariant.sizeGroups) return [];

      return this.currentVariant.sizeGroups
        .map(sizeGroup => sizeGroup.sizes)
        .flat();
    },
  },
  watch: {
    "$route.query": {
      handler: function(newValue, oldValue) {
        if (!isEqual(newValue.productId, oldValue.productId)) {
          this.getProduct();
        }

        if (!isEqual(newValue.variantId, oldValue.variantId)) {
          this.trackDetailView();
          this.UPDATE_CURRENT_VARIANT_ID({ id: newValue.variantId });
        }
      },
      deep: true,
    },
    currentVariant(newValue) {
      if (newValue) {
        this.selectSize(null);

        this.UPDATE_IMAGES({
          images: newValue.images
            ? newValue.images.map(image => image.large)
            : [],
        });
      }
    },
    selectedSizeGroup(newValue) {
      if (!this.shouldAccountForSizeGroups) return;

      const matchingSizeGroup = this.currentVariant.sizeGroups.find(
        sizeGroup => sizeGroup.title === newValue,
      );

      if (!matchingSizeGroup) return;

      this.selectDefaultSize(matchingSizeGroup);
    },
  },
  created() {
    if (!this.hasAccessToShop) {
      this.redirectToNoAccessView();
      return;
    }

    this.getProduct();

    const { variantId } = this.$route.query;

    if (!variantId) return;

    this.GET_VARIANTS_FOR_PRODUCT_BLOCK({ variantIds: [variantId] });
  },
  destroyed() {
    this.UPDATE_CURRENT_PRODUCT({ product: null });
  },
  methods: {
    ...mapActions("imageGallery", ["UPDATE_IMAGES"]),
    ...mapActions("products", [
      "GET_PRODUCT",
      "UPDATE_CURRENT_PRODUCT",
      "UPDATE_CURRENT_VARIANT_ID",
      "GET_VARIANTS_FOR_PRODUCT_BLOCK",
    ]),
    closeModal() {
      this.closeModalFromParent = true;
    },
    expandReadMoreDescription() {
      this.shouldShowReadMoreDescription = false;
      slideDown(this.$refs.readMoreDescription);
    },
    expandReadMoreLimitations() {
      this.shouldShowReadMoreLimitations = false;
      slideDown(this.$refs.readMoreLimitations);
    },
    getProduct() {
      const { productId, variantId } = this.$route.query;

      this.UPDATE_CURRENT_PRODUCT({ product: {} });
      this.GET_PRODUCT({ id: productId }).then(() => {
        this.trackDetailView();

        this.$nextTick(() => {
          this.shouldShowReadMoreDescription =
            this.$refs.readMoreDescription.offsetHeight > 63; // three lines of text

          if (this.$refs.readMoreLimitations) {
            this.shouldShowReadMoreLimitations =
              this.$refs.readMoreLimitations.offsetHeight > 63; // three lines of text
          }
        });
      });
      this.UPDATE_CURRENT_VARIANT_ID({ id: variantId });
    },
    handleClose() {
      const {
        $route: { query },
        $router,
      } = this;

      const { mode, productId, variantId, ...remainingQueryParams } = query; //eslint-disable-line

      $router.push({ query: remainingQueryParams });
    },
    selectSize(size) {
      this.selectedSize = size;
    },
    trackDetailView() {
      const { currentProduct, currentVariant } = this;

      const detailView = {
        ecommerce: {
          detail: {
            products: [
              {
                name: currentProduct.name,
                id: currentProduct.id,
                brand: currentProduct.brand,
                category: currentVariant.category,
                variant: currentVariant.id,
              },
            ],
          },
        },
      };

      pushToDataLayer(detailView);
    },
  },
};
</script>

<style lang="scss">
.product-details {
  &__block {
    margin-bottom: var(--spacing-stack);

    @media (min-width: 64rem) {
      flex: 1 1 auto;
      margin-bottom: 0;
      min-width: 0;
    }

    &:first-child {
      @media (min-width: 110.625rem) {
        flex: 0 0 77.75rem;
        width: 77.75rem;
      }
    }

    &:last-child {
      @media (min-width: 64rem) {
        flex: 0 0 35rem;
        width: 35rem;
      }

      @media (min-width: 110.625rem) {
        flex: 1 1 auto;
      }
    }
  }

  &__blocks {
    @media (min-width: 64rem) {
      align-items: flex-start;
      display: flex;
      justify-content: space-between;
    }
  }

  &__close {
    @include reset-button();

    display: none;

    @media (min-width: 64rem) {
      display: block;
      position: absolute;
      right: calc(var(--spacing-container) * 0.5);
      top: var(--size-modal-icon);
      z-index: 1;
    }

    &,
    &-icon {
      height: var(--size-modal-icon);
      width: var(--size-modal-icon);
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
    }
  }

  &__content {
    padding-top: var(--spacing-container);
    position: relative;

    @media (min-width: 64rem) {
      padding: calc(var(--spacing-container) * 2 + var(--size-modal-icon) * 2) 0
        calc(var(--spacing-container) * 2) calc(var(--spacing-container) * 2);
    }

    @media (min-width: 90rem) {
      padding: calc(var(--spacing-container) * 5) 0
        calc(var(--spacing-container) * 4) calc(var(--spacing-container) * 6);
    }
  }

  &__description {
    font-size: 0.9375rem;
    line-height: 1.6;
    max-width: 40rem;
    white-space: pre-line;
  }

  &__discount-label {
    align-items: center;
    background-color: var(--color-product-label);
    border-radius: 50%;
    color: var(--color-product-label-text);
    display: flex;
    font-size: 0.6875rem;
    font-weight: 600;
    height: var(--size-product-label);
    justify-content: center;
    text-transform: uppercase;
    width: var(--size-product-label);
  }

  &__go-to-checkout {
    @media (min-width: 64rem) {
      display: none;
    }
  }

  &__label {
    font-size: 0.9375rem;
    margin: 0 0 calc(var(--spacing-stack) * 0.5);

    &--slim {
      font-weight: 400;
    }
  }

  &__read-more {
    overflow: hidden;
    position: relative;

    &--collapsed {
      cursor: pointer;
      height: 8rem; /* three lines of text above */

      &::after,
      &::before {
        display: block !important;
      }
    }

    &::after {
      content: attr(title);
      font-size: 0.9375rem;
      font-weight: 700;
      line-height: 1.5;
    }

    &::after,
    &::before {
      bottom: 0;
      display: none;
      position: absolute;
    }

    &::before {
      background: linear-gradient(
        to bottom,
        rgba(var(--color-product-text-background), 0) 0%,
        rgba(var(--color-product-text-background), 1) 70%
      );
      content: "";
      height: 8rem; /* three lines of text above */
      left: 0;
      right: 0;
    }
  }

  &__service-item {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__service-items {
    @include reset-list();
  }

  &__title {
    font-family: var(--font-family-inter);
    font-size: 1.5rem;
    font-weight: 900;
    margin: 0;

    @media (min-width: 31.25rem) {
      font-size: 1.75rem;
    }

    @media (min-width: 100rem) {
      font-size: 2.125rem;
    }
  }
}
</style>
