var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"stack",class:{
    'stack--center-horizontally': _vm.centerHorizontally,
    'stack--center-vertically': _vm.centerVertically,
    'stack--double-spacing': _vm.double,
    'stack--half-spacing': _vm.half,
    'stack--no-spacing': _vm.noSpacing,
    'stack--one-and-a-half-spacing': _vm.oneAndHalf,
    'stack--quarter-spacing': _vm.quarter,
    'stack--space-between': _vm.spaceBetween,
    'stack--triple-spacing': _vm.triple,
  }},[_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }