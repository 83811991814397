import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { alertTypes } from "@/helpers/alertTypes";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  profile: null,
  status: "",
});

const getters = {
  profile: state => state.profile,
  statusOfProfile: state => state.status,
};

const actions = {
  GET_PROFILE({ commit, dispatch }, payload) {
    commit("SET_PROFILE_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) =>
      axios
        .get(`${endpoints.ECOMMERCE}/users/${payload.id}`)
        .then(({ data }) => {
          commit("SET_PROFILE_STATUS", { status: statuses.SUCCESS });
          commit("SET_PROFILE", { profile: data });
          dispatch(
            "users/UPDATE_CURRENT_USER_ID",
            { id: data.id },
            { root: true },
          );
          resolve(data);
        })
        .catch(error => {
          commit("SET_PROFILE_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        }),
    );
  },
  UPDATE_PROFILE({ commit, dispatch }, payload) {
    commit("SET_PROFILE_STATUS", { status: statuses.SAVING });

    const { id, profile } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/users/updateprofile/${id}`, {
        ...profile,
        languageCode: Array.isArray(profile.languageCode)
          ? null
          : profile.languageCode,
        sizeSystem: Array.isArray(profile.sizeSystem)
          ? null
          : profile.sizeSystem,
        timeZone: Array.isArray(profile.timeZone) ? null : profile.timeZone,
      })
      .then(({ data }) => {
        commit("SET_PROFILE_STATUS", { status: statuses.SUCCESS });
        commit("SET_PROFILE", { profile: data });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "Common.YourChangesWereSaved",
            type: alertTypes.SUCCESS,
          },
          {
            root: true,
          },
        );
        dispatch("authentication/GET_USER_CONFIGURATION", {}, { root: true });
      })
      .catch(error => {
        commit("SET_PROFILE_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_USERS_PROFILE({ commit, dispatch }, payload) {
    commit("SET_PROFILE_STATUS", { status: statuses.SAVING });

    const { id, languageCode, sizeSystem } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/users/updateotherprofile/${id}`, {
        languageCode,
        sizeSystem,
      })
      .then(({ data }) => {
        commit("SET_PROFILE_STATUS", { status: statuses.SUCCESS });
        commit("SET_PROFILE", { profile: data });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "Common.YourChangesWereSaved",
            type: alertTypes.SUCCESS,
          },
          {
            root: true,
          },
        );
        dispatch("authentication/GET_USER_CONFIGURATION", {}, { root: true });
      })
      .catch(error => {
        commit("SET_PROFILE_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_PROFILE(state, payload) {
    state.profile = { ...payload.profile, password: "", confirmPassword: "" };
  },
  SET_PROFILE_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
