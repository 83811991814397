<template>
  <DynamicData
    class="basket-line-list"
    :class="{ 'basket-line-list--read-only': readOnly }"
    :loading="isLoading || isUpdatingBasket"
  >
    <Accordion>
      <AccordionItem
        v-for="(category, categoryKey, categoryIndex) in groupedByCategory"
        :key="'category_' + categoryIndex"
      >
        <template #accordion-header>
          <Checkbox
            v-if="category.length > 1 && shouldShowCheckbox"
            class="product__checkbox"
            :value="isAllVariantsInCategorySelected(categoryKey)"
            @input="
              toggleCategoryLines({
                value: $event,
                categoryKey,
                category,
              })
            "
          />
          <span class="basket-line-list__category-title"
            >{{ categoryKey }} ({{ getCategoryGroupLength(category) }})</span
          >
        </template>
        <template #accordion-body>
          <transition-group
            tag="ul"
            class="basket-line-list__list"
            name="fade-slide"
            appear=""
          >
            <li
              v-for="(basketLine, index) in category"
              :key="basketLine.variantId"
              class="basket-line-list__item"
              :style="{ '--index': index }"
            >
              <article>
                <div class="product">
                  <Checkbox
                    v-if="shouldShowCheckbox"
                    class="product__checkbox"
                    :value="isVariantSelected(basketLine.variantId)"
                    @input="
                      toggleBasketLine({
                        id: basketLine.variantId,
                        value: $event,
                        categoryIndex,
                        categoryKey,
                      })
                    "
                  />
                  <div class="product__image-container">
                    <img
                      :src="basketLine.image.src"
                      :alt="basketLine.image.alt"
                      class="product__image"
                      decoding="async"
                      loading="lazy"
                    />
                  </div>
                  <div class="product__text">
                    <Stack :quarter="true">
                      <h1 class="product__title">
                        {{ basketLine.productName }}
                      </h1>
                    </Stack>
                    <Stack :quarter="true">
                      <InformationList
                        :information="{ id: basketLine.variantId }"
                      />
                    </Stack>
                    <Stack v-if="basketLine.swatch" :quarter="true">
                      <div class="product__swatch">
                        <img
                          v-if="basketLine.swatch.src"
                          :src="basketLine.swatch.src"
                          :alt="basketLine.swatch.alt"
                          class="product__swatch-image"
                          height="50"
                          width="50"
                        />
                        <span class="product__swatch-text">{{
                          basketLine.swatch.alt
                        }}</span>
                      </div>
                    </Stack>
                    <Stack :half="true">
                      <ol class="product__sizes">
                        <li
                          v-for="size in basketLine.sizes"
                          :key="size.sku"
                          class="product__size"
                          :title="
                            `${size.totalLinePrice.price} ${size.totalLinePrice.currency}`
                          "
                        >
                          <div class="product__size-title">
                            {{ getPreferredSizeChart(size.sizeChart) }}
                          </div>
                          <div class="product__size-quantity">
                            {{ size.quantity }}
                          </div>
                        </li>
                      </ol>
                    </Stack>
                  </div>

                  <div class="product__inputs">
                    <!-- <div v-if="shouldShowDatepicker" class="product__input">
                      <Datepicker
                        :disabled="isUpdatingBasket"
                        :error-message="
                          getDictionaryEntry(
                            'ErrorMessage.Checkout.DeliveryDateRequired',
                          )
                        "
                        :has-error="!basketLine.requestedDeliveryDate"
                        :min-date="dateForTomorrow"
                        :required="true"
                        :value="basketLine.requestedDeliveryDate"
                        @blur="handleDayChange($event, basketLine.variantId)"
                      />
                    </div> -->
                    <div
                      v-if="shouldShowDiscountInput"
                      class="product__input product__input--discount"
                    >
                      <Input
                        :disabled="isUpdatingBasket"
                        :hide-label="true"
                        :label="getDictionaryEntry('Common.Labels.Discount')"
                        :placeholder="
                          getDictionaryEntry('Common.Placeholders.Discount')
                        "
                        suffix="%"
                        type="number"
                        :value="basketLine.discountPercent"
                        @blur="
                          handleDiscountChange(
                            $event.target.value,
                            basketLine.variantId,
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="product__numbers">
                    <ol class="product__sizes product__sizes--mobile-only">
                      <li
                        v-for="size in basketLine.sizes"
                        :key="size.sku"
                        class="product__size"
                        :title="
                          `${size.totalLinePrice.price} ${size.totalLinePrice.currency}`
                        "
                      >
                        <div class="product__size-title">
                          {{ getPreferredSizeChart(size.sizeChart) }}
                        </div>
                        <div class="product__size-quantity">
                          {{ size.quantity }}
                        </div>
                      </li>
                    </ol>
                    <Stack :half="true" class="product__numbers-stack">
                      <div v-if="basketLine.unitPrice" class="product__price">
                        {{ basketLine.quantity }}×{{
                          formatPriceCurrency(basketLine.unitPrice.price)
                        }}
                        {{ basketLine.unitPrice.currency }}
                      </div>
                      <div
                        v-if="basketLine.totalLinePrice"
                        class="product__price product__price--sum"
                      >
                        <span
                          v-if="basketLine.discountPercent > 0"
                          class="product__price product__price--previous"
                          >{{
                            formatPriceCurrency(
                              basketLine.totalLinePrice.normalPrice,
                            )
                          }}
                          {{ basketLine.totalLinePrice.currency }}</span
                        >
                        {{
                          formatPriceCurrency(basketLine.totalLinePrice.price)
                        }}
                        {{ basketLine.totalLinePrice.currency }}
                        <span
                          v-if="basketLine.discountPercent > 0"
                          class="product__price product__price--after"
                          >{{
                            formatPriceCurrency(
                              basketLine.totalLinePrice.normalPrice,
                            )
                          }}
                          {{ basketLine.totalLinePrice.currency }}</span
                        >
                      </div>
                      <div class="product__unit-prices">
                        <div
                          v-for="unitPrice in basketLine.groupedUnitPrices"
                          :key="unitPrice"
                          class="product__price product__price--unit"
                        >
                          {{ unitPrice }}
                        </div>
                      </div>
                      <div
                        v-if="basketLine.discountPercent > 0"
                        class="product__price--discount"
                      >
                        {{ getDictionaryEntry("Common.Labels.Discount") }}:
                        {{ basketLine.discountPercent }}%
                      </div>
                    </Stack>
                  </div>
                </div>
                <div class="product__icons">
                  <router-link
                    class="product__edit"
                    :to="
                      linkForVariant(basketLine.productId, basketLine.variantId)
                    "
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="product__icon product__icon--edit"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                      />
                      <path
                        d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                      />
                    </svg>
                  </router-link>
                  <button
                    class="product__remove"
                    :aria-label="
                      interpolateString(
                        getDictionaryEntry('Checkout.RemoveProductFromBasket'),
                        ['productName'],
                        [basketLine.productName],
                      )
                    "
                    :label="
                      interpolateString(
                        getDictionaryEntry('Checkout.RemoveProductFromBasket'),
                        ['productName'],
                        [basketLine.productName],
                      )
                    "
                    @click="REMOVE_VARIANT_FROM_BASKET({ basketLine })"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="product__icon product__icon--remove"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"
                      />
                    </svg>
                  </button>
                </div>
              </article>
            </li>
          </transition-group>
        </template>
      </AccordionItem>
    </Accordion>
    <div
      v-if="shouldShowDatepicker && basketLines && basketLines.length"
      class="basket-line-list__date"
    >
      <Datepicker
        :disabled="isUpdatingBasket"
        :error-message="
          getDictionaryEntry('ErrorMessage.Checkout.DeliveryDateRequired')
        "
        :has-error="!basketLines[0].requestedDeliveryDate"
        :min-date="dateForTomorrow"
        :required="true"
        :value="basketLines[0].requestedDeliveryDate"
        @blur="handleOrderDayChange($event)"
      />
    </div>
    <transition name="slide-height">
      <div v-if="selectedVariants.length" class="actions">
        <div class="actions__block">
          <div class="actions__count">
            {{ selectedVariants.length }}
            {{
              selectedVariants.length === 1
                ? getDictionaryEntry("PreOrder.SelectedRowsSingular")
                : getDictionaryEntry("PreOrder.SelectedRowsPlural")
            }}
          </div>
          <button
            class="actions__reset"
            :aria-label="getDictionaryEntry('PreOrder.DeselectAllRows')"
            @click="deselectVariants"
          >
            <svg
              class="actions__reset-icon"
              height="21"
              viewBox="0 0 21 21"
              width="21"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g transform="translate(2 2)">
                <circle cx="8.5" cy="8.5" r="8" />
                <g transform="matrix(0 1 -1 0 17 0)">
                  <path d="m5.5 11.5 6-6" />
                  <path d="m5.5 5.5 6 6" />
                </g>
              </g>
            </svg>
          </button>
        </div>
        <div class="actions__block">
          <ol class="actions__list">
            <!-- <li v-if="shouldShowDatepicker" class="actions__item">
              <Datepicker
                :disabled="isUpdatingBasket"
                :min-date="dateForTomorrow"
                @blur="handleGroupedDayChange($event)"
              />
            </li> -->
            <li v-if="shouldShowDiscountInput" class="actions__item">
              <Input
                :disabled="isUpdatingBasket"
                :hide-label="true"
                :label="getDictionaryEntry('Common.Labels.Discount')"
                :loading="isUpdatingBasket"
                :placeholder="
                  getDictionaryEntry('Common.Placeholders.Discount')
                "
                suffix="%"
                type="number"
                @blur="handleGroupedDiscountChange($event.target.value)"
              />
            </li>
          </ol>
        </div>
      </div>
    </transition>
  </DynamicData>
</template>

<script>
import groupBy from "lodash.groupby";
import { mapActions, mapGetters } from "vuex";
import Accordion from "@/components/Accordion";
import AccordionItem from "@/components/AccordionItem";
import Checkbox from "@/components/Checkbox";
import Datepicker from "@/components/Datepicker";
import DynamicData from "@/components/DynamicData";
import InformationList from "@/components/InformationList";
import Input from "@/components/Input";
import Stack from "@/components/Stack";
import { orderOfSizes } from "@/helpers/orderOfSizes";
import { statuses } from "@/helpers/statuses";

export default {
  name: "BasketLineList",
  components: {
    Accordion,
    AccordionItem,
    Checkbox,
    Datepicker,
    DynamicData,
    InformationList,
    Input,
    Stack,
  },
  props: {
    basketLines: {
      required: true,
      type: Array,
    },
    readOnly: {
      defualt: false,
      type: Boolean,
    },
  },
  data() {
    return {
      requestedDeliveryDate: null,
      selectedVariants: [],
      updated: false,
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "allowDiscountOnCheckout",
      "isPreOrderState",
      "isUserSystemUser",
      "userConfiguration",
    ]),
    ...mapGetters("basket", ["statusOfBasket"]),
    dateForTomorrow() {
      let dateForTomorrow = new Date();
      dateForTomorrow.setDate(dateForTomorrow.getDate() + 1);

      return dateForTomorrow.toISOString().slice(0, 10);
    },
    groupedBasketLines() {
      const {
        basketLines,
        convertNumberToLocaleString,
        getPreferredSizeChart,
        formatPriceCurrency,
      } = this;

      if (!basketLines || !basketLines.length) return [];

      const basketLinesGroupedByVariants = groupBy(basketLines, "variantId");

      return Object.values(basketLinesGroupedByVariants).map(
        basketLinesGroup => {
          const {
            categoryName,
            currency,
            discountPercent,
            fit,
            gender,
            image,
            productId,
            productName,
            requestedDeliveryDate,
            swatch,
            variantId,
            variantName,
          } = basketLinesGroup[0];

          const pricesInGroup = basketLinesGroup.map(
            ({ quantity, unitPrice }) => ({
              quantity,
              price: unitPrice.price,
            }),
          );

          const groupedPricesInGroup = groupBy(pricesInGroup, "price");

          const groupedUnitPrices = Object.keys(groupedPricesInGroup).map(
            key => {
              const totalQuantity = groupedPricesInGroup[key].reduce(
                (sum, entry) => {
                  return sum + entry.quantity;
                },
                0,
              );

              return `${totalQuantity} x ${formatPriceCurrency(
                key,
              )} ${currency}`;
            },
          );

          const sizes = basketLinesGroup
            .map(({ quantity, sizeChart, sku, totalLinePrice }) => ({
              quantity,
              sizeChart,
              sku,
              totalLinePrice,
            }))
            .sort((a, b) => {
              const sizeA = getPreferredSizeChart(a.sizeChart);
              const sizeB = getPreferredSizeChart(b.sizeChart);

              if (
                orderOfSizes.includes(sizeA.toUpperCase()) &&
                orderOfSizes.includes(sizeB.toUpperCase())
              ) {
                return (
                  orderOfSizes.indexOf(sizeA.toUpperCase()) -
                  orderOfSizes.indexOf(sizeB.toUpperCase())
                );
              }

              return sizeA - sizeB;
            });

          let totalLinePrice = {
            amountOfDiscount: "",
            currency,
            isDiscounted: false,
            normalPrice: "",
            normalPriceValue: 0,
            price: "",
            priceValue: 0,
          };

          basketLinesGroup.forEach(basketLine => {
            totalLinePrice.normalPriceValue +=
              basketLine.totalLinePrice.normalPriceValue;
            totalLinePrice.priceValue += basketLine.totalLinePrice.priceValue;

            if (
              !totalLinePrice.isDiscounted &&
              basketLine.totalLinePrice.isDiscounted
            ) {
              totalLinePrice.isDiscounted = true;
            }
          });

          if (!totalLinePrice.isDiscounted) {
            totalLinePrice.amountOfDiscount = convertNumberToLocaleString(
              totalLinePrice.normalPriceValue - totalLinePrice.priceValue,
            );
          }

          totalLinePrice.normalPrice = convertNumberToLocaleString(
            totalLinePrice.normalPriceValue,
          );
          totalLinePrice.price = convertNumberToLocaleString(
            totalLinePrice.priceValue,
          );

          return {
            categoryName,
            currency,
            discountPercent,
            fit,
            gender,
            groupedUnitPrices,
            image,
            productId,
            productName,
            requestedDeliveryDate,
            sizes,
            swatch,
            totalLinePrice,
            variantId,
            variantName,
          };
        },
      );
    },
    groupedByCategory() {
      const basketLinesGroupedByCategoryName = groupBy(
        this.groupedBasketLines,
        "categoryName",
      );

      return basketLinesGroupedByCategoryName;
    },
    isLoading() {
      if (this.readOnly) return false;

      return this.statusOfBasket === statuses.LOADING;
    },
    isUpdatingBasket() {
      return this.statusOfBasket === statuses.SAVING;
    },
    shouldShowCheckbox() {
      return this.shouldShowDatepicker || this.shouldShowDiscountInput;
    },
    shouldShowDatepicker() {
      return this.isPreOrderState && this.isUserSystemUser;
    },
    shouldShowDiscountInput() {
      return this.isPreOrderState && this.allowDiscountOnCheckout;
    },
  },
  methods: {
    ...mapActions("basket", [
      "REMOVE_VARIANT_FROM_BASKET",
      // "UPDATE_DELIVERY_DATE_OF_BASKET_LINES",
      "UPDATE_DELIVERY_DATE_OF_ORDER",
      "UPDATE_DISCOUNT_OF_BASKET_LINES",
    ]),
    deselectVariants() {
      this.selectedVariants = [];
    },
    getCategoryGroupLength(category) {
      let items = 0;

      category.map(item => {
        item.sizes.map(size => {
          items += size.quantity;
        });
      });

      return items;
    },
    // handleDayChange(value, variantId) {
    //   const currentLineInStore = this.basketLines.length
    //     ? this.basketLines.find(variant => variant.variantId === variantId)
    //     : null;
    //   let dateInStore = this.convertDateToUTC(
    //     currentLineInStore.requestedDeliveryDate,
    //   );
    //   let changedDate = this.convertDateToUTC(value);

    //   if (
    //     currentLineInStore &&
    //     dateInStore.toString() === changedDate.toString()
    //   ) {
    //     return;
    //   }

    //   this.UPDATE_DELIVERY_DATE_OF_BASKET_LINES({
    //     deliveries: [{ value: changedDate, variantId }],
    //   });
    // },
    handleDiscountChange(value, variantId) {
      this.UPDATE_DISCOUNT_OF_BASKET_LINES({
        discounts: [{ value, variantId }],
      });
    },
    // handleGroupedDayChange(value) {
    //   const date = this.convertDateToUTC(value);

    //   this.UPDATE_DELIVERY_DATE_OF_BASKET_LINES({
    //     deliveries: this.selectedVariants.map(variant => ({
    //       value: date,
    //       variantId: variant.id,
    //     })),
    //   });
    // },
    handleGroupedDiscountChange(value) {
      this.UPDATE_DISCOUNT_OF_BASKET_LINES({
        discounts: this.selectedVariants.map(variant => ({
          value: value,
          variantId: variant.id,
        })),
      });
    },
    handleOrderDayChange(value) {
      const date = this.convertDateToUTC(value);

      this.UPDATE_DELIVERY_DATE_OF_ORDER({ requestedDeliveryDate: date });
    },
    isAllVariantsInCategorySelected(categoryKey) {
      const numberOfItemsInCategory = this.groupedByCategory[categoryKey]
        .length;

      return (
        this.numberOfSelectedVariants(categoryKey) === numberOfItemsInCategory
      );
    },
    isVariantSelected(id) {
      if (!id) return false;

      return this.selectedVariants.some(e => e.id === id);
    },
    linkForVariant(productId, variantId) {
      const { mode } = this.$route.query;

      if (mode && mode.includes("details")) {
        return {
          query: {
            mode: "details_quick-buy",
            productId,
            variantId,
          },
        };
      }

      return {
        query: { mode: "quick-buy", productId, variantId },
      };
    },
    numberOfSelectedVariants(categoryKey) {
      return this.selectedVariants.filter(
        item => item.categoryKey === categoryKey,
      ).length;
    },
    toggleBasketLine({ id, value, categoryIndex, categoryKey }) {
      if (value) {
        this.selectedVariants.push({ id, categoryIndex, categoryKey });
      } else {
        this.selectedVariants = this.selectedVariants.filter(
          selectedBasketLine => selectedBasketLine.id !== id,
        );
      }
    },
    toggleCategoryLines({ value, categoryKey, category }) {
      if (value) {
        category.map((item, index) => {
          this.selectedVariants.push({
            id: item.variantId,
            categoryIndex: index,
            categoryKey,
          });
        });
      } else {
        this.selectedVariants = this.selectedVariants.filter(
          selectedBasketLine => selectedBasketLine.categoryKey !== categoryKey,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  align-items: center;
  background-color: var(--color-table-actions-background);
  bottom: 0;
  color: var(--color-table-actions-text);
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  height: 5rem;
  justify-content: space-between;
  left: calc(var(--spacing-card) * -1);
  margin-left: calc(var(--spacing-card) * -1);
  position: sticky;
  right: calc(var(--spacing-card) * -1);
  text-transform: uppercase;
  width: calc(100% + var(--spacing-card) * 2);
  z-index: 4;

  @media (min-width: 48rem) {
    margin-left: calc(var(--spacing-card-large) * -1);
    width: calc(100% + var(--spacing-card-large) * 2);
  }

  &.slide-height-enter-active,
  &.slide-height-leave-active {
    overflow: hidden;
  }

  &__block {
    &:first-child {
      align-items: center;
      display: flex;
    }
  }

  &__count {
    margin: 0 0.5rem 0 1rem;
  }

  &__item {
    transition: opacity 300ms ease;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    .slide-height-enter &,
    .slide-height-leave-to & {
      opacity: 0;
    }
  }

  &__list {
    @include reset-list();

    display: flex;
    margin-right: 1rem;
  }

  &__reset {
    @include reset-button();

    & {
      margin-right: var(--size-table-action-icon);
    }

    &,
    &-icon {
      height: var(--size-table-action-icon);
      width: var(--size-table-action-icon);
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
      stroke: currentColor;
    }
  }
}

.basket-line-list {
  &__category-title {
    margin-left: 1rem;
  }

  &__date {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--spacing-stack);

    .sidebar & {
      display: none;
    }
  }

  &__item {
    position: relative;

    &::before {
      border-top: 1px solid var(--color-table-border);
      top: -1px;
      content: "";
      height: 1px;
      left: var(--spacing-card);
      position: absolute;
      right: var(--spacing-card);

      @media (min-width: 48rem) {
        left: var(--spacing-card-large);
        right: var(--spacing-card-large);
      }
    }

    &:first-child::before {
      border-top: 0;
    }

    &:first-child {
      .receipt & {
        border-top: 1px solid rgba(0, 0, 0, 0.075);
      }
    }

    &:last-child {
      .receipt & {
        margin-bottom: calc(var(--spacing-stack) * 0.75);
      }
    }
  }

  &__list {
    @include reset-list();

    .card & {
      margin-left: calc(var(--spacing-card) * -1);
      width: calc(100% + var(--spacing-card) * 2);

      @media (min-width: 48rem) {
        margin-left: calc(var(--spacing-card-large) * -1);
        width: calc(100% + var(--spacing-card-large) * 2);
      }
    }
  }
}

.fade-slide {
  &-enter-active {
    transition-delay: calc(50ms * var(--index)) !important;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease, transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.product {
  align-items: flex-start;
  color: inherit;
  display: flex;
  flex-flow: row wrap;
  font-size: 0.875rem;
  font-weight: 400;
  position: relative;
  text-decoration: none;

  @media (min-width: 48rem) {
    flex-flow: nowrap;
  }

  .basket-line-list--read-only & {
    font-size: 0.8125rem;
  }

  .card .basket-line-list__item:first-child & {
    &::before {
      content: "";
      height: 1px;
      left: var(--spacing-card);
      position: absolute;
      right: var(--spacing-card);
      top: 0;

      @media (min-width: 48rem) {
        left: var(--spacing-card-large);
        right: var(--spacing-card-large);
      }
    }
  }

  .card & {
    padding: calc(var(--spacing-stack) * 0.75) var(--spacing-card);

    @media (min-width: 48rem) {
      padding: var(--spacing-card-large) var(--spacing-card-large)
        calc(var(--spacing-card-large) * 2);
    }

    @media (min-width: 93.75rem) {
      padding-bottom: calc(var(--spacing-card-large) * 0.5);
    }
  }

  .sidebar & {
    padding: calc(var(--spacing-stack) * 0.75) var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding-left: var(--spacing-sidebar-large);
      padding-right: var(--spacing-sidebar-large);
    }
  }

  .checkout__error-modal .basket-line-list__list & {
    display: grid;
    grid-template-columns: 5rem 1fr auto auto;
    padding-top: 1rem;
  }

  &__checkbox {
    .sidebar & {
      display: none;
    }
  }

  &__edit {
    display: inline-block;
    height: var(--size-basket-icon);
    margin-right: calc(var(--size-basket-icon) * 0.5);
    text-decoration: none;
    width: var(--size-basket-icon);
  }

  &__icon {
    fill: none;
    height: var(--size-basket-icon);
    opacity: 0.75;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-text-primary);
    transition: opacity 300ms ease;
    width: var(--size-basket-icon);

    &:hover {
      opacity: 1;
    }
  }

  &__icons {
    display: flex;
    align-items: center;
    position: absolute;
    z-index: 1;

    .card & {
      right: var(--spacing-card);
      top: calc(var(--spacing-stack) * 0.75);

      @media (min-width: 48rem) {
        bottom: var(--spacing-card-large);
        right: var(--spacing-card-large);
        top: auto;
      }
    }

    .sidebar & {
      bottom: var(--spacing-sidebar);
      right: var(--spacing-sidebar);

      @media (min-width: 64rem) {
        bottom: var(--spacing-sidebar-large);
        right: var(--spacing-sidebar-large);
      }
    }
  }

  &__image {
    display: block;
    font-family: "object-fit: contain;";
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &-container {
      background-color: var(--color-products-image-background);
      flex: 0 0 4.1875rem;
      margin: 0 0.5rem;
      order: 1;
      position: relative;

      @media (min-width: 64rem) {
        flex-basis: 5rem;
      }

      .basket-line-list--read-only & {
        flex-basis: 4rem;
      }

      .sidebar & {
        margin-left: 0;
      }

      &::before {
        content: "";
        display: block;
        padding-top: calc(1 / 1) * 100%;
        width: 100%;
      }
    }
  }

  &__input {
    &:not(:last-child) {
      margin: 0 0 1rem;

      @media (min-width: 115.25rem) {
        margin: 0 2rem 0 0;
      }
    }

    &--discount {
      margin-right: calc(var(--size-datepicker-icon) * 0.8 + 0.75rem);

      @media (min-width: 90rem) {
        margin-right: 0;
        width: 13.125rem;
      }
    }
  }

  &__inputs {
    flex: 0 0 15rem;
    margin: 1rem 0;
    order: 5;

    @media (min-width: 48rem) {
      margin: 0 var(--spacing-stack);
    }

    @media (min-width: 115.25rem) {
      display: flex;
      flex-basis: 31.25rem;
      justify-content: flex-end;
      margin: 0 3.75rem 0 1rem;
      max-width: none;
    }

    .sidebar & {
      display: none;
    }
  }

  &__numbers {
    display: flex;
    flex: 0 0 100%;
    justify-content: space-between;
    order: 3;
    text-align: right;

    @media (min-width: 48rem) {
      display: block;
      flex: 0 0 auto;
      margin: 0 0 0 auto;
      order: 5;
    }

    &-stack {
      @media (max-width: 47.9375rem) {
        margin: 0 !important;
      }
    }
  }

  &__price {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 500;

    &--after {
      display: none;
      text-decoration: line-through;
      margin-top: 0.4rem;

      .checkout & {
        display: block;

        @media (min-width: 90rem) {
          display: none;
        }
      }

      .sidebar & {
        @media (min-width: 48rem) {
          display: block;
        }
      }
    }

    &--discount {
      color: var(--color-error);
      margin-top: 0.5rem;

      .sidebar & {
        margin-top: 0.2rem;
      }
    }

    &--previous {
      display: none;
      margin-bottom: calc(var(--spacing-stack) * 0.1);
      text-decoration: line-through;

      @media (min-width: 90rem) {
        display: inline;
      }

      .checkout & {
        @media (min-width: 90rem) {
          display: inline;
          margin: 0 0.5rem 0 0;
        }
      }

      .sidebar & {
        display: none;
      }
    }

    &--unit {
      &:not(:last-child) {
        margin-bottom: 0.125rem;
      }
    }

    &--units {
      margin-top: 0.5rem;
    }

    &--sum {
      color: var(--color-text);
      display: block;
      font-size: 0.875rem;
      font-weight: 700;

      @media (min-width: 23.4375rem) {
        font-size: 1rem;
      }
    }
  }

  &__remove {
    @include reset-button();

    height: var(--size-basket-icon);
    width: var(--size-basket-icon);
  }

  &__size {
    margin-bottom: 0.75rem;
    text-align: center;

    &:not(:last-child) {
      margin-right: 0.5rem;
      padding-right: 0.5rem;
      position: relative;

      &::after {
        background-color: rgba(0, 0, 0, 0.075);
        bottom: 0;
        content: "";
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
      }
    }

    &-title {
      font-weight: 700;
      text-transform: lowercase;
    }
  }

  &__sizes {
    @include reset-list();

    display: none;
    flex-flow: row wrap;
    position: relative;

    @media (min-width: 48rem) {
      display: flex;
    }

    &--mobile-only {
      display: flex;
      margin-left: 0.5rem;

      @media (min-width: 48rem) {
        display: none;
      }
    }
  }

  &__swatch {
    align-items: center;
    display: flex;

    &-image {
      border-radius: calc(var(--size-product-swatch) * 0.2);
      height: var(--size-product-swatch);
      margin-right: calc(var(--size-product-swatch) * 0.35);
      width: var(--size-product-swatch);
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__text {
    flex-basis: calc(
      100% - 5.1875rem - var(--size-checkbox) - (var(--size-basket-icon) * 3.5)
    );
    flex-grow: 0;
    flex-shrink: 0;
    order: 2;
    overflow: hidden;

    @media (min-width: 48rem) {
      flex-basis: 7rem;
    }

    @media (min-width: 77.5rem) {
      flex: unset;
      flex-basis: 14rem;
      margin: 0 var(--spacing-stack);

      .basket-line-list--read-only & {
        margin: 0 calc(var(--spacing-stack) * 0.5);
      }
    }

    .sidebar & {
      @media (min-width: 48rem) {
        margin: 0 var(--spacing-stack) 0 calc(var(--spacing-stack) * 0.5);
      }
    }
  }

  &__title {
    font-size: 0.9375rem;
    line-height: 1.15;
    margin: 0;

    .basket-line-list--read-only & {
      font-size: 0.875rem;
    }
  }

  &__unit-prices {
    margin-top: calc(var(--spacing-stack) * 0.25);
  }
}

.slide-height {
  &-enter-active,
  &-leave-active {
    max-height: 5rem;
    transition: color 300ms ease, max-height 600ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  &-enter,
  &-leave-to {
    color: transparent;
    max-height: 0;
  }
}
</style>
