export function uuid() {
  const url = URL.createObjectURL(new Blob());

  const id = url
    .toString()
    .split("/")
    .reverse()[0];

  URL.revokeObjectURL(url);

  return id;
}
