<template>
  <DynamicData
    class="basket-line-list"
    :class="{ 'basket-line-list--read-only': readOnly }"
    :loading="isLoading || isUpdatingBasket"
  >
    <Accordion>
      <AccordionItem
        v-for="(category,
        categoryKey,
        categoryIndex) in basketLinesGroupedByCategory"
        :key="categoryKey"
      >
        <template #accordion-header>
          <Checkbox
            v-if="category.length > 1 && shouldShowCheckbox"
            class="product__checkbox"
            :value="isAllVariantsInCategorySelected(categoryKey)"
            @input="
              toggleCategoryLines({
                value: $event,
                categoryKey,
                category,
              })
            "
          />
          <span class="basket-line-list__category-title"
            >{{ categoryKey }} ({{
              getTotalQuantityOfCategory(category)
            }})</span
          >
        </template>
        <template #accordion-body>
          <transition-group
            tag="ul"
            class="basket-line-list__list"
            name="fade-slide"
            appear=""
          >
            <li
              v-for="(basketLine, index) in category"
              :key="basketLine.id"
              class="basket-line-list__item"
              :class="{
                'basket-line-list__item--has-checkbox-and-inputs': shouldShowCheckbox,
              }"
              :style="{ '--index': index }"
            >
              <article>
                <div class="product">
                  <Checkbox
                    v-if="shouldShowCheckbox"
                    class="product__checkbox"
                    :value="isVariantSelected(basketLine.id)"
                    @input="
                      toggleVariant({
                        id: basketLine.id,
                        value: $event,
                        categoryIndex,
                        categoryKey,
                      })
                    "
                  />
                  <div class="product__image">
                    <BasketLineProductImage :image="basketLine.image" />
                  </div>
                  <div class="product__information">
                    <Stack :quarter="true">
                      <h1 class="product__title">
                        {{ basketLine.productName }}
                      </h1>
                    </Stack>
                    <Stack :quarter="true">
                      <InformationList :information="{ id: basketLine.id }" />
                    </Stack>
                    <Stack v-if="basketLine.swatch" :quarter="true">
                      <BasketLineSwatch :swatch="basketLine.swatch" />
                    </Stack>
                    <Stack
                      :half="true"
                      class="product__sizes-and-quantities product__sizes-and-quantities--desktop-only"
                    >
                      <BasketLineSizes
                        :desktop-only="true"
                        :sizes-with-quantities="basketLine.sizesWithQuantities"
                      />
                    </Stack>
                  </div>
                  <div
                    class="product__sizes-and-quantities product__sizes-and-quantities--mobile-only"
                  >
                    <BasketLineSizes
                      :mobile-only="true"
                      :sizes-with-quantities="basketLine.sizesWithQuantities"
                    />
                  </div>
                  <div v-if="basketLine.totalPrice" class="product__numbers">
                    <Stack>
                      <div class="product__price">
                        <div class="product__price product__price--sum">
                          {{ formatPriceCurrency(basketLine.totalPrice) }}
                          {{ basketLine.currency }}
                        </div>
                        <div
                          v-if="
                            basketLine.totalPrice !==
                              basketLine.totalPriceBeforeDiscount
                          "
                          class="product__price product__price--previous"
                        >
                          {{
                            formatPriceCurrency(
                              basketLine.totalPriceBeforeDiscount,
                            )
                          }}
                          {{ basketLine.currency }}
                        </div>
                      </div>
                    </Stack>
                    <Stack
                      v-if="
                        !basketLine.customersToShopFor &&
                          !basketLine.customersToShopFor.length
                      "
                      :half="true"
                    >
                      <div class="product__price--discount">
                        {{ getDictionaryEntry("Common.Labels.Discount") }}:
                        {{ basketLine.discountPercent }}%
                      </div>
                    </Stack>
                    <Stack :half="true">
                      <div
                        v-for="unitPrice in basketLine.pricesWithQuantities"
                        :key="unitPrice"
                        class="product__unit-with-price"
                      >
                        {{ formatPriceCurrency(unitPrice) }}
                      </div>
                    </Stack>
                  </div>
                  <div
                    v-if="
                      !basketLine.customersToShopFor &&
                        !basketLine.customersToShopFor.length
                    "
                    class="product__actions"
                  >
                    <router-link
                      class="product__edit"
                      :to="linkForVariant(basketLine.productId, basketLine.id)"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="product__icon product__icon--edit"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                        />
                        <path
                          d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                        />
                      </svg>
                    </router-link>
                    <button
                      class="product__remove"
                      :aria-label="
                        interpolateString(
                          getDictionaryEntry(
                            'Checkout.RemoveProductFromBasket',
                          ),
                          ['productName'],
                          [basketLine.productName],
                        )
                      "
                      :label="
                        interpolateString(
                          getDictionaryEntry(
                            'Checkout.RemoveProductFromBasket',
                          ),
                          ['productName'],
                          [basketLine.productName],
                        )
                      "
                      @click="REMOVE_VARIANT_FROM_BASKET({ basketLine })"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="product__icon product__icon--remove"
                        viewBox="0 0 24 24"
                      >
                        <path
                          d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"
                        />
                      </svg>
                    </button>
                  </div>
                  <div v-if="shouldShowDiscountInput" class="product__inputs">
                    <!-- <div v-if="shouldShowDatepicker" class="product__input">
                      <Datepicker
                        :disabled="isUpdatingBasket"
                        :error-message="
                          getDictionaryEntry(
                            'ErrorMessage.Checkout.DeliveryDateRequired',
                          )
                        "
                        :has-error="!basketLine.requestedDeliveryDate"
                        :min-date="dateForTomorrow"
                        :required="true"
                        :value="basketLine.requestedDeliveryDate"
                        @blur="
                          handleDayChange(
                            $event,
                            basketLine.variantId
                              ? basketLine.variantId
                              : basketLine.id,
                          )
                        "
                      />
                    </div> -->
                    <div
                      v-if="shouldShowDiscountInput"
                      class="product__input product__input--discount"
                    >
                      <Input
                        :disabled="isUpdatingBasket"
                        :hide-label="true"
                        :label="getDictionaryEntry('Common.Labels.Discount')"
                        :placeholder="
                          getDictionaryEntry('Common.Placeholders.Discount')
                        "
                        suffix="%"
                        type="number"
                        :value="basketLine.discountPercent"
                        @blur="
                          handleDiscountChange(
                            $event.target.value,
                            basketLine.variantId,
                          )
                        "
                      />
                    </div>
                  </div>
                  <div class="product__customers">
                    <BasketLineCustomers :basket-line="basketLine" />
                  </div>
                </div>
              </article>
            </li>
          </transition-group>
        </template>
      </AccordionItem>
    </Accordion>
    <div
      v-if="shouldShowDatepicker && basketLines && basketLines.length"
      class="basket-line-list__date"
    >
      <Datepicker
        :disabled="isUpdatingBasket"
        :error-message="
          getDictionaryEntry('ErrorMessage.Checkout.DeliveryDateRequired')
        "
        :has-error="!basketLines[0].requestedDeliveryDate"
        :min-date="dateForTomorrow"
        :required="true"
        :value="basketLines[0].requestedDeliveryDate"
        @blur="handleOrderDayChange($event)"
      />
    </div>
    <BasketLineListActions
      v-if="shouldShowCheckbox"
      :deselect-variants="deselectVariants"
      :should-show-datepicker="false"
      :should-show-discount="shouldShowDiscountInput"
      :variants="selectedVariants"
    />
  </DynamicData>
</template>

<script>
import groupBy from "lodash.groupby";
import { mapActions, mapGetters } from "vuex";
import Accordion from "@/components/Accordion";
import AccordionItem from "@/components/AccordionItem";
import BasketLineCustomers from "@/components/BasketLineCustomers";
import BasketLineListActions from "@/components/BasketLineListActions";
import BasketLineProductImage from "@/components/BasketLineProductImage";
import BasketLineSizes from "@/components/BasketLineSizes";
import BasketLineSwatch from "@/components/BasketLineSwatch";
import Checkbox from "@/components/Checkbox";
import Datepicker from "@/components/Datepicker";
import DynamicData from "@/components/DynamicData";
import InformationList from "@/components/InformationList";
import Input from "@/components/Input";
import Stack from "@/components/Stack";
import { orderOfSizes } from "@/helpers/orderOfSizes";
import { statuses } from "@/helpers/statuses";

export default {
  name: "BasketLineListForCustomersWithEndCustomers",
  components: {
    Accordion,
    AccordionItem,
    BasketLineCustomers,
    BasketLineListActions,
    BasketLineProductImage,
    BasketLineSizes,
    BasketLineSwatch,
    Checkbox,
    Datepicker,
    DynamicData,
    InformationList,
    Input,
    Stack,
  },
  props: {
    basketLines: {
      required: true,
      type: Array,
    },
    readOnly: {
      defualt: false,
      type: Boolean,
    },
  },
  data() {
    return {
      selectedVariants: [],
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "allowDiscountOnCheckout",
      "isPreOrderState",
      "isUserSystemUser",
    ]),
    ...mapGetters("basket", ["statusOfBasket"]),
    basketLinesGroupedByCategory() {
      const {
        basketLines,
        getPreferredSizeChart,
        getPricesWithQuantities,
        getSizesWithQuantities,
      } = this;

      if (!basketLines || !basketLines.length) return [];

      const basketLinesGroupedByVariants = groupBy(
        basketLines.map(basketLine => ({
          ...basketLine,
          sizeChart: getPreferredSizeChart(basketLine.sizeChart),
        })),
        "variantId",
      );

      const variants = [];

      Object.entries(basketLinesGroupedByVariants).forEach(
        ([variantId, basketLinesForVariant]) => {
          const {
            categoryName,
            currency,
            discountPercent,
            fit,
            gender,
            image,
            productId,
            productName,
            removable,
            requestedDeliveryDate,
            sku,
            swatch,
            variantName,
          } = basketLinesForVariant[0];

          const basketLinesGroupedByCustomerToShopFor = groupBy(
            basketLinesForVariant,
            "shopToCustomerId",
          );

          const customersToShopFor = [];

          Object.entries(basketLinesGroupedByCustomerToShopFor).forEach(
            ([customerId, basketLinesForCustomer]) => {
              const totalPrice = this.getTotalPrice(basketLinesForCustomer);
              const totalPriceBeforeDiscount = this.getTotalPriceBeforeDiscount(
                basketLinesForCustomer,
              );

              customersToShopFor.push({
                discountPercent: basketLinesForCustomer[0].discountPercent.toFixed(
                  2,
                ),
                id: customerId,
                name: basketLinesForCustomer[0].shopToCustomerName,
                pricesWithQuantities: getPricesWithQuantities(
                  basketLinesForCustomer,
                ),
                sizesWithQuantities: getSizesWithQuantities(
                  basketLinesForCustomer,
                ),
                totalPrice,
                totalPriceBeforeDiscount,
              });
            },
          );

          const totalPrice = this.getTotalPrice(basketLinesForVariant);
          const totalPriceBeforeDiscount = this.getTotalPriceBeforeDiscount(
            basketLinesForVariant,
          );

          variants.push({
            category: categoryName,
            currency,
            customersToShopFor,
            discountPercent,
            fit,
            gender,
            id: variantId,
            image,
            name: variantName,
            productId,
            pricesWithQuantities: getPricesWithQuantities(
              basketLinesForVariant,
            ),
            productName,
            removable,
            requestedDeliveryDate,
            sizesWithQuantities: getSizesWithQuantities(basketLinesForVariant),
            sku,
            swatch,
            totalPrice,
            totalPriceBeforeDiscount,
          });
        },
      );

      return groupBy(variants, "category");
    },
    dateForTomorrow() {
      let dateForTomorrow = new Date();
      dateForTomorrow.setDate(dateForTomorrow.getDate() + 1);

      return dateForTomorrow.toISOString().slice(0, 10);
    },
    isLoading() {
      if (this.readOnly) return false;

      return this.statusOfBasket === statuses.LOADING;
    },
    isUpdatingBasket() {
      return this.statusOfBasket === statuses.SAVING;
    },
    shouldShowCheckbox() {
      return this.shouldShowDatepicker || this.shouldShowDiscountInput;
    },
    shouldShowDatepicker() {
      return this.isPreOrderState && this.isUserSystemUser;
    },
    shouldShowDiscountInput() {
      return this.isPreOrderState && this.allowDiscountOnCheckout;
    },
  },
  methods: {
    ...mapActions("basket", [
      "REMOVE_VARIANT_FROM_BASKET",
      "UPDATE_DELIVERY_DATE_OF_BASKET_LINES",
      "UPDATE_DELIVERY_DATE_OF_ORDER",
      "UPDATE_DISCOUNT_OF_BASKET_LINES",
    ]),
    deselectVariants() {
      this.selectedVariants = [];
    },
    getPricesWithQuantities(basketLines) {
      if (!basketLines || !basketLines.length) return [];

      const groupedPricesAndQuantities = groupBy(
        basketLines.map(({ quantity, unitPrice: { price, priceValue } }) => ({
          price,
          priceValue,
          quantity,
        })),
        "price",
      );

      const combinedPricesAndQuantities = [];

      Object.entries(groupedPricesAndQuantities).forEach(
        ([price, pricesAndQuantities]) => {
          const quantity = pricesAndQuantities.reduce((sum, { quantity }) => {
            return sum + quantity;
          }, 0);

          combinedPricesAndQuantities.push({
            quantity,
            price,
            priceValue: pricesAndQuantities[0].priceValue,
          });
        },
      );

      return combinedPricesAndQuantities
        .sort((a, b) => {
          return a.priceValue - b.priceValue;
        })
        .map(
          priceAndQuantity =>
            `${priceAndQuantity.quantity} x ${priceAndQuantity.price} ${basketLines[0].currency}`,
        );
    },
    getSizesWithQuantities(basketLines) {
      if (!basketLines || !basketLines.length) return [];

      const basketLinesGroupedBySizes = groupBy(
        basketLines.filter(basketLine => basketLine.includeInQuantity),
        "sizeChart",
      );

      const combinedSizesAndQuantities = [];

      Object.entries(basketLinesGroupedBySizes).forEach(
        ([size, basketLines]) => {
          const quantity = basketLines.reduce((sum, { quantity }) => {
            return sum + quantity;
          }, 0);

          combinedSizesAndQuantities.push({
            quantity,
            size,
          });
        },
      );

      return combinedSizesAndQuantities.sort((a, b) => {
        const sizeA = a.size;
        const sizeB = b.size;

        if (
          orderOfSizes.includes(sizeA.toUpperCase()) &&
          orderOfSizes.includes(sizeB.toUpperCase())
        ) {
          return (
            orderOfSizes.indexOf(sizeA.toUpperCase()) -
            orderOfSizes.indexOf(sizeB.toUpperCase())
          );
        }

        return sizeA - sizeB;
      });
    },
    getTotalQuantityOfCategory(basketLines) {
      if (!basketLines || !basketLines.length) return 0;

      return basketLines
        .map(basketLine => basketLine.sizesWithQuantities)
        .flat()
        .map(sizeWithQuantity => sizeWithQuantity.quantity)
        .reduce((sum, quantity) => {
          return sum + quantity;
        }, 0);
    },
    handleDayChange(value, variantId) {
      const currentLineInStore = this.basketLines.length
        ? this.basketLines.find(variant => variant.variantId === variantId)
        : null;
      let dateInStore = "";

      if (currentLineInStore && currentLineInStore.requestedDeliveryDate) {
        dateInStore = this.convertDateToUTC(
          currentLineInStore.requestedDeliveryDate,
        );
      }

      let changedDate = this.convertDateToUTC(value);

      if (
        changedDate &&
        currentLineInStore &&
        dateInStore &&
        dateInStore.toString() === changedDate.toString()
      ) {
        return;
      }

      this.UPDATE_DELIVERY_DATE_OF_BASKET_LINES({
        deliveries: [{ value: changedDate, variantId }],
      });
    },
    handleDiscountChange(value, variantId) {
      this.UPDATE_DISCOUNT_OF_BASKET_LINES({
        discounts: [{ value, variantId }],
      });
    },
    handleOrderDayChange(value) {
      const date = this.convertDateToUTC(value);

      this.UPDATE_DELIVERY_DATE_OF_ORDER({ requestedDeliveryDate: date });
    },
    isAllVariantsInCategorySelected(categoryKey) {
      if (!categoryKey) return false;

      const matchingVariants = this.basketLinesGroupedByCategory[categoryKey];

      if (!matchingVariants || !matchingVariants.length) return false;

      return matchingVariants.every(variant =>
        this.selectedVariants.some(
          selectedVariant => selectedVariant.id === variant.id,
        ),
      );
    },
    isVariantSelected(id) {
      if (!id) return false;

      return this.selectedVariants.some(variant => variant.id === id);
    },
    linkForVariant(productId, variantId) {
      const { mode } = this.$route.query;

      if (mode && mode.includes("details")) {
        return {
          query: {
            mode: "details_quick-buy",
            productId,
            variantId,
          },
        };
      }

      return {
        query: {
          mode: "quick-buy",
          productId,
          variantId,
        },
      };
    },
    numberOfSelectedVariants(categoryKey) {
      return this.selectedVariants.filter(
        item => item.categoryKey === categoryKey,
      ).length;
    },
    toggleCategoryLines({ value, categoryKey, category }) {
      if (value) {
        this.selectedVariants = category.map(({ id }, index) => ({
          id,
          categoryIndex: index,
          categoryKey,
        }));
      } else {
        this.selectedVariants = this.selectedVariants.filter(
          selectedBasketLine => selectedBasketLine.categoryKey !== categoryKey,
        );
      }
    },
    toggleVariant({ id, value, categoryIndex, categoryKey }) {
      if (value) {
        this.selectedVariants.push({ id, categoryIndex, categoryKey });
      } else {
        this.selectedVariants = this.selectedVariants.filter(
          selectedBasketLine => selectedBasketLine.id !== id,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.basket-line-list {
  &__date {
    display: flex;
    justify-content: flex-end;
    margin-bottom: var(--spacing-stack);

    .sidebar & {
      display: none;
    }
  }

  &__item {
    position: relative;

    &::before {
      border-top: 1px solid var(--color-table-border);
      content: "";
      height: 1px;
      left: var(--spacing-card);
      position: absolute;
      right: var(--spacing-card);
      top: -1px;

      @media (min-width: 48rem) {
        left: var(--spacing-card-large);
        right: var(--spacing-card-large);
      }
    }

    &:first-child::before {
      border-top: 0;
    }

    &:first-child {
      .receipt & {
        border-top: 1px solid rgba(0, 0, 0, 0.075);
      }
    }

    &:last-child {
      .receipt & {
        margin-bottom: calc(var(--spacing-stack) * 0.75);
      }
    }
  }

  &__list {
    @include reset-list();

    .card & {
      margin-left: calc(var(--spacing-card) * -1);
      width: calc(100% + var(--spacing-card) * 2);

      @media (min-width: 48rem) {
        margin-left: calc(var(--spacing-card-large) * -1);
        width: calc(100% + var(--spacing-card-large) * 2);
      }
    }
  }
}

.fade-slide {
  &-enter-active {
    transition-delay: calc(50ms * var(--index)) !important;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease, transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.product {
  color: inherit;
  display: flex;
  flex-flow: row wrap;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;

  .card & {
    padding: calc(var(--spacing-stack) * 0.75) var(--spacing-card);

    @media (min-width: 48rem) {
      padding: var(--spacing-card-large);
    }
  }

  .sidebar & {
    padding: calc(var(--spacing-stack) * 0.75) var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding-left: var(--spacing-sidebar-large);
      padding-right: var(--spacing-sidebar-large);
    }
  }

  &__actions {
    align-items: center;
    display: flex;
    flex: 0 0 3.125rem;
    justify-content: flex-end;
    margin: calc(var(--spacing-stack) * 0.5) 0;

    @media (min-width: 48rem) {
      flex: 0 0 100%;
    }

    .card & {
      @media (min-width: 48rem) {
        order: 5;
      }
    }
  }

  &__checkbox {
    margin-right: 1rem;

    .card & {
      @media (min-width: 48rem) {
        order: 0;
      }
    }

    .sidebar & {
      display: none;
    }
  }

  &__customers {
    flex: 0 0 100%;

    @media (min-width: 48rem) {
      flex: 0 0 calc(100% - 5rem - 2rem);
      margin-left: calc(5rem + 2rem);
      order: 6;
    }

    .card .basket-line-list__item--has-checkbox-and-inputs & {
      @media (min-width: 48rem) {
        flex: 0 0 calc(100% - 1.5rem - 1rem - 5rem - 2rem);
        margin-left: calc(1.5rem + 1rem + 5rem + 2rem);
      }
    }

    .sidebar & {
      margin-top: 1rem;
    }
  }

  &__edit {
    display: inline-block;
    height: var(--size-basket-icon);
    margin-right: calc(var(--size-basket-icon) * 0.5);
    text-decoration: none;
    width: var(--size-basket-icon);
  }

  &__icon {
    fill: none;
    height: var(--size-basket-icon);
    opacity: 0.75;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-text-primary);
    transition: opacity 300ms ease;
    width: var(--size-basket-icon);

    &:hover {
      opacity: 1;
    }
  }

  &__image {
    flex: 0 0 auto;
    margin-right: 1rem;
    width: 4.1875rem;

    @media (min-width: 64rem) {
      width: 5rem;
    }

    .basket-line-list--read-only & {
      width: 4rem;
    }

    .card & {
      @media (min-width: 48rem) {
        margin-right: 2rem;
        order: 1;
      }
    }

    .sidebar & {
      @media (min-width: 48rem) {
        margin-right: 2rem;
      }
    }
  }

  &__information {
    flex: 0 0 calc(100% - 4.1875rem - 1rem);
    overflow: hidden;

    @media (min-width: 48rem) {
      flex-basis: calc(100% - 4.1875rem - 2rem - 7.5rem - 1rem);
      margin-right: 1rem;
    }

    @media (min-width: 64rem) {
      flex-basis: calc(100% - 5rem - 2rem - 7.5rem - 1rem);
    }

    .card & {
      @media (min-width: 48rem) {
        flex-basis: calc(100% - 4.1875rem - 2rem - 7.5rem - 2rem);
        margin-right: 2rem;
        order: 2;
      }

      @media (min-width: 64rem) {
        flex-basis: calc(100% - 5rem - 2rem - 7.5rem - 2rem);
      }

      @media (min-width: 100rem) {
        flex-basis: calc(100% - 5rem - 2rem - 7.5rem - 2rem);
      }
    }

    .card .basket-line-list__item--has-checkbox-and-inputs & {
      flex-basis: calc(100% - 1.5rem - 1rem - 4.1875rem - 1rem);

      @media (min-width: 48rem) {
        flex-basis: calc(
          100% - 1.5rem - 1rem - 4.1875rem - 2rem - 15rem - 2rem - 7.5rem - 2rem
        );
      }

      @media (min-width: 64rem) {
        flex-basis: calc(
          100% - 1.5rem - 1rem - 5rem - 2rem - 15rem - 2rem - 7.5rem - 2rem
        );
      }

      @media (min-width: 100rem) {
        flex-basis: calc(
          100% - 1.5rem - 1rem - 5rem - 2rem - 31.25rem - 4rem - 7.5rem - 2rem
        );
      }
    }
  }

  &__input {
    &:not(:last-child) {
      margin: 0 0 1rem;

      @media (min-width: 100rem) {
        margin: 0 2rem 0 0;
      }
    }

    &--discount {
      margin-right: calc(var(--size-datepicker-icon) * 0.8 + 0.75rem);

      @media (min-width: 90rem) {
        margin-right: 0;
        width: 13.125rem;
      }
    }
  }

  &__inputs {
    flex: 0 0 100%;

    .card & {
      margin-bottom: calc(var(--spacing-stack) * 0.75);

      @media (min-width: 48rem) {
        flex-basis: 15rem;
        margin: 0 2rem 0 0;
        order: 3;
      }

      @media (min-width: 100rem) {
        display: flex;
        flex-basis: 31.25rem;
        justify-content: flex-end;
        margin-right: 4rem;
        max-width: none;
      }
    }

    .sidebar & {
      display: none;
    }
  }

  &__numbers {
    flex: 0 0 7.5rem;
    text-align: right;

    .card & {
      margin: calc(var(--spacing-stack) * 0.75) 0;

      @media (min-width: 48rem) {
        margin: 0;
        order: 4;
      }
    }
  }

  &__price {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;

    &--discount {
      color: var(--color-error);
      margin-top: 0.5rem;
    }

    &--previous {
      margin-top: 0.125rem;
      text-decoration: line-through;
    }

    &--sum {
      color: var(--color-text-primary);
      display: block;
      font-size: 0.875rem;
      font-weight: 700;

      @media (min-width: 23.4375rem) {
        font-size: 1rem;
      }
    }
  }

  &__remove {
    @include reset-button();

    height: var(--size-basket-icon);
    width: var(--size-basket-icon);
  }

  &__sizes-and-quantities {
    flex: 0 0 calc(100% - 3.125rem - 1rem);

    .card & {
      margin: calc(var(--spacing-stack) * 0.75) 1rem
        calc(var(--spacing-stack) * 0.75) 0;
    }

    &--desktop-only {
      display: none;

      @media (min-width: 48rem) {
        display: block;
      }
    }

    &--mobile-only {
      flex: 0 0 calc(100% - 7.5rem - 1rem);

      @media (min-width: 48rem) {
        display: none;
      }
    }
  }

  &__title {
    font-size: 0.9375rem;
    line-height: 1.15;
    margin: 0;

    .basket-line-list--read-only & {
      font-size: 0.875rem;
    }
  }

  &__unit-with-price {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 500;
    text-align: right;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 0.125rem;
    }
  }
}
</style>
