<template>
  <Container :small="true">
    <section class="contact">
      <Heading
        v-if="blockData.headline"
        :tag="blockData.headlineTag"
        class="block__title"
      >
        {{ blockData.headline }}
      </Heading>
      <div
        v-if="blockData.description"
        class="block__rich-text"
        v-html="blockData.description"
      ></div>
      <Stack class="contact__form-container">
        <form class="contact__form" @submit.prevent="sendMessage">
          <Stack>
            <Input
              v-model.trim="$v.name.$model"
              :error-message="
                getDictionaryEntry('ErrorMessage.Checkout.NameRequired')
              "
              :has-error="$v.name.$error"
              :label="getDictionaryEntry('Common.Labels.Name')"
              autocomplete="given-name"
              required
              type="text"
            />
          </Stack>
          <Stack>
            <Input
              v-model.trim="$v.phone.$model"
              :label="getDictionaryEntry('Common.Labels.Phone')"
              autocomplete="tel"
              type="tel"
            />
          </Stack>
          <Stack>
            <Input
              v-model.trim="$v.email.$model"
              :error-message="
                getDictionaryEntry('ErrorMessage.Checkout.EmailRequired')
              "
              :has-error="$v.email.$error"
              :label="getDictionaryEntry('Common.Labels.Email')"
              autocomplete="email"
              required
              type="email"
              @blur="$v.email.$touch()"
              @input="$v.email.$reset()"
            />
          </Stack>
          <Stack>
            <Textarea
              v-model.trim="$v.message.$model"
              :error-message="
                getDictionaryEntry('ErrorMessage.Common.FieldRequired')
              "
              :has-error="$v.message.$error"
              :label="getDictionaryEntry('Common.Labels.Message')"
              required
            />
          </Stack>
          <Stack :double="true">
            <Button
              class="contact__submit"
              :disabled="$v.$invalid"
              :limited-width="true"
              :show-loader="isLoading"
              :round="true"
              >{{ getDictionaryEntry("Common.Send") }}</Button
            >
          </Stack>
        </form>
      </Stack>
      <transition name="fade">
        <Stack
          v-if="hasMessageBeenSent"
          ref="successMessage"
          :double="true"
          class="contact__success"
        >
          <h3 class="contact__success-title">
            {{ getDictionaryEntry("SuccessMessage.MessageSent.Title") }}
          </h3>
          <p class="contact__success-description">
            {{ getDictionaryEntry("SuccessMessage.MessageSent.Description") }}
          </p>
        </Stack>
      </transition>
    </section>
  </Container>
</template>

<script>
import { email, required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import Input from "@/components/Input";
import Stack from "@/components/Stack";
import Textarea from "@/components/Textarea";
import { statuses } from "@/helpers/statuses";

export default {
  name: "ContactGridElement",
  components: {
    Button,
    Container,
    Heading,
    Input,
    Stack,
    Textarea,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      email: "",
      hasMessageBeenSent: false,
      message: "",
      name: "",
      phone: "",
    };
  },
  validations: {
    email: { email, required },
    message: { required },
    name: { required },
    phone: {},
  },
  computed: {
    ...mapGetters("contact", ["statusOfContact"]),
    isLoading() {
      return this.statusOfContact === statuses.LOADING;
    },
  },
  methods: {
    ...mapActions("contact", ["SEND_MESSAGE"]),
    sendMessage() {
      const {
        SEND_MESSAGE,
        blockData: { emailRecipient },
        email,
        message,
        name,
        phone,
      } = this;

      SEND_MESSAGE({
        email,
        message,
        name,
        phone,
        emailRecipient,
      }).then(() => {
        this.email = "";
        this.message = "";
        this.name = "";
        this.phone = "";

        this.hasMessageBeenSent = true;

        this.$nextTick(() => {
          this.$v.$reset();

          window.setTimeout(() => {
            this.$refs.successMessage.$el.scrollIntoView(
              {
                behavior: "smooth",
              },
              300,
            );
          });
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.contact {
  &__form {
    margin-top: calc(var(--spacing-stack) * 2);
    max-width: 33.9375rem;
  }

  &__submit {
    min-width: 9.375rem;
  }

  &__success {
    max-width: 33.9375rem;
    scroll-margin-top: calc(var(--size-header-fixed) + var(--spacing-stack));

    &-description {
      margin: 0;
    }

    &-title {
      font-family: var(--font-family-inter);
      font-size: 1.5rem;
      margin: 0 0 calc(var(--spacing-stack) * 0.5);
    }
  }
}
</style>
