<template>
  <div
    class="container"
    :class="{ 'container--small': small, 'container--medium': medium }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Container",
  props: {
    medium: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  margin: 0 auto;
  max-width: var(--width-container);
  padding: 0 var(--spacing-container);
  width: 100%;

  .block & {
    @media (min-width: 64rem) {
      display: flex;
    }
  }

  .block--text-and-media & {
    display: flex;
    flex-direction: column;
    height: 100%;

    @media (min-width: 64rem) {
      flex-direction: row;
    }
  }

  .navigation-sidebar & {
    @media (min-width: 64rem) {
      margin: 0;
      padding: 0 var(--spacing-container-with-navigation-sidebar);
    }
  }

  &--medium {
    max-width: var(--width-container-medium);
  }

  &--medium,
  &--small {
    @media (min-width: 64rem) {
      padding: 0 calc(var(--spacing-container) * 1.25) !important;
    }
  }

  &--small {
    max-width: var(--width-container-small);
  }
}
</style>
