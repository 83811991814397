<template>
  <div class="downloads">
    <div v-if="catalogueBuildProcess" class="downloads__content">
      <Stack>
        <h3 class="downloads__title">
          {{ getDictionaryEntry("ProductSheet.Download.Title") }}
        </h3>
      </Stack>
      <Stack :half="true">
        <p class="downloads__description">
          {{ getDictionaryEntry("ProductSheet.Download.Description") }}
        </p>
      </Stack>
      <Stack :one-and-half="true" class="introduction__actions">
        <Stack>
          <a
            class="downloads__link"
            :href="catalogueBuildProcess.generatedUrl"
            target="_blank"
            rel="noreferrer"
            >{{ getDictionaryEntry("ProductSheet.OpenCatalogue") }}</a
          >
        </Stack>
        <Stack :half="true">
          <Button :secondary="true" @click="goToFirstStep">{{
            getDictionaryEntry("ProductSheet.CreateNewCatalogue")
          }}</Button>
        </Stack>
      </Stack>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "@/components/Button";
import Stack from "@/components/Stack";

export default {
  name: "CataloguesDownloads",
  components: {
    Button,
    Stack,
  },
  props: {
    goToStep: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters("catalogues", ["catalogueBuildProcess"]),
  },
  methods: {
    goToFirstStep() {
      this.goToStep(1);
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width-wide: 43rem;

.downloads {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 35rem;
  text-align: center;

  &__content {
    width: 100%;
  }

  &__description {
    font-size: 0.9375rem;
    line-height: 1.65;
    margin: 0;
  }

  &__link {
    align-items: center;
    background-color: var(--color-button-background);
    border-radius: 0.25rem;
    box-shadow: var(--elevation-4);
    color: var(--color-button-text);
    display: block;
    font-size: 0.875rem;
    font-weight: 500;
    justify-content: center;
    padding: var(--spacing-button);
    text-align: center;
    text-decoration: none;
    transition: background-color 300ms ease;
    width: 100%;

    &:hover {
      background-color: var(--color-button-background-active);
    }
  }

  &__title {
    font-family: var(--font-family-inter);
    font-size: 1.875rem;
    font-weight: 900;
    line-height: 1.13;
    margin: 0;
    text-transform: uppercase;

    @media (min-width: $sidebar-width-wide) {
      font-size: 2.125rem;
    }
  }
}
</style>
