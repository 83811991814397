<template>
  <transition name="slide-height">
    <div v-if="variants.length" class="actions">
      <div class="actions__block">
        <div class="actions__count">
          {{ variants.length }}
          {{
            variants.length === 1
              ? getDictionaryEntry("PreOrder.SelectedRowsSingular")
              : getDictionaryEntry("PreOrder.SelectedRowsPlural")
          }}
        </div>
        <button
          class="actions__reset"
          :aria-label="getDictionaryEntry('PreOrder.DeselectAllRows')"
          @click="deselectVariants"
        >
          <svg
            class="actions__reset-icon"
            height="21"
            viewBox="0 0 21 21"
            width="21"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g transform="translate(2 2)">
              <circle cx="8.5" cy="8.5" r="8" />
              <g transform="matrix(0 1 -1 0 17 0)">
                <path d="m5.5 11.5 6-6" />
                <path d="m5.5 5.5 6 6" />
              </g>
            </g>
          </svg>
        </button>
      </div>
      <div class="actions__block">
        <ol class="actions__list">
          <li v-if="shouldShowDatepicker" class="actions__item">
            <Datepicker
              :disabled="isUpdatingBasket"
              :min-date="dateForTomorrow"
              @blur="handleDayChange($event)"
            />
          </li>
          <li v-if="shouldShowDiscount" class="actions__item">
            <Input
              :disabled="isUpdatingBasket"
              :hide-label="true"
              :label="getDictionaryEntry('Common.Labels.Discount')"
              :loading="isUpdatingBasket"
              :placeholder="getDictionaryEntry('Common.Placeholders.Discount')"
              suffix="%"
              type="number"
              @blur="handleDiscountChange($event.target.value)"
            />
          </li>
        </ol>
      </div>
    </div>
  </transition>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Datepicker from "@/components/Datepicker";
import Input from "@/components/Input";
import { statuses } from "@/helpers/statuses";

export default {
  name: "BasketLineListActions",
  components: {
    Datepicker,
    Input,
  },
  props: {
    deselectVariants: {
      required: true,
      type: Function,
    },
    shouldShowDatepicker: {
      default: false,
      type: Boolean,
    },
    shouldShowDiscount: {
      default: false,
      type: Boolean,
    },
    variants: {
      required: true,
      type: Array,
    },
  },
  computed: {
    ...mapGetters("basket", ["statusOfBasket"]),
    dateForTomorrow() {
      let dateForTomorrow = new Date();
      dateForTomorrow.setDate(dateForTomorrow.getDate() + 1);

      return dateForTomorrow.toISOString().slice(0, 10);
    },
    isUpdatingBasket() {
      return this.statusOfBasket === statuses.SAVING;
    },
  },
  methods: {
    ...mapActions("basket", [
      "UPDATE_DELIVERY_DATE_OF_BASKET_LINES",
      "UPDATE_DISCOUNT_OF_BASKET_LINES",
    ]),
    handleDayChange(value) {
      const date = value ? this.convertDateToUTC(value) : null;

      this.UPDATE_DELIVERY_DATE_OF_BASKET_LINES({
        deliveries: this.variants.map(variant => ({
          value: date,
          variantId: variant.id,
        })),
      });
    },
    handleDiscountChange(value) {
      this.UPDATE_DISCOUNT_OF_BASKET_LINES({
        discounts: this.variants.map(variant => ({
          value: value,
          variantId: variant.id,
        })),
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.actions {
  align-items: center;
  background-color: var(--color-table-actions-background);
  bottom: 0;
  color: var(--color-table-actions-text);
  display: flex;
  font-size: 0.75rem;
  font-weight: 500;
  height: 5rem;
  justify-content: space-between;
  left: calc(var(--spacing-card) * -1);
  margin-left: calc(var(--spacing-card) * -1);
  position: sticky;
  right: calc(var(--spacing-card) * -1);
  text-transform: uppercase;
  width: calc(100% + var(--spacing-card) * 2);
  z-index: 4;

  @media (min-width: 48rem) {
    margin-left: calc(var(--spacing-card-large) * -1);
    width: calc(100% + var(--spacing-card-large) * 2);
  }

  &.slide-height-enter-active,
  &.slide-height-leave-active {
    overflow: hidden;
  }

  &__block {
    &:first-child {
      align-items: center;
      display: flex;
    }
  }

  &__count {
    margin: 0 0.5rem 0 1rem;
  }

  &__item {
    transition: opacity 300ms ease;

    &:not(:last-child) {
      margin-right: 2rem;
    }

    .slide-height-enter &,
    .slide-height-leave-to & {
      opacity: 0;
    }
  }

  &__list {
    @include reset-list();

    display: flex;
    margin-right: 1rem;
  }

  &__reset {
    @include reset-button();
    margin-right: var(--size-table-action-icon);

    &,
    &-icon {
      height: var(--size-table-action-icon);
      width: var(--size-table-action-icon);
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2px;
      stroke: currentColor;
    }
  }
}

.slide-height {
  &-enter-active,
  &-leave-active {
    max-height: 5rem;
    transition: color 300ms ease, max-height 600ms cubic-bezier(0.25, 1, 0.5, 1);
  }

  &-enter,
  &-leave-to {
    color: transparent;
    max-height: 0;
  }
}
</style>
