<template>
  <Container v-if="blockData">
    <div class="block block--offset-media">
      <div class="block__content block__content--center block--dark-background">
        <article>
          <Heading
            v-if="blockData.headline"
            :tag="blockData.headlineTag"
            class="block__title"
          >
            {{ blockData.headline }}
          </Heading>
          <p
            v-if="blockData.description"
            class="block__description block__description--center"
          >
            {{ blockData.description }}
          </p>
          <div
            class="block__media-container block__media-container--faux"
          ></div>
        </article>
      </div>
      <div class="block__content">
        <div class="block__media-container block__media-container--faux"></div>
        <div class="block__media-container">
          <ImageFromUmbraco
            v-if="blockData.image"
            :id="blockData.image._system.id"
            :alt="blockData.image.alternateText"
            :height="blockData.image.umbracoHeight"
            :keys-and-sizes="{
              s: 'block-wide-small',
              m: 'block-regular-medium',
              l: 'block-regular-large',
              xl: 'block-regular-large',
            }"
            :width="blockData.image.umbracoWidth"
            class="block__image"
          />
          <div v-if="youTubeId" class="video-container">
            <iframe
              width="560"
              height="315"
              :src="
                `https://www.youtube-nocookie.com/embed/${youTubeId}?playlist=${youTubeId}&autoplay=1&loop=1&mute=1`
              "
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              loop="1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";
import { getYouTubeId } from "@/helpers/videoId";

export default {
  name: "OffsetTextAndMediaGridElement",
  components: {
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
  computed: {
    youTubeId() {
      const { blockData } = this;

      if (!blockData || !blockData.youTubeLink) return null;

      return getYouTubeId(blockData.youTubeLink);
    },
  },
};
</script>
