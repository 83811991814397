const fallbacks = {
  apiGatewayEndpoint: "https://fengel-b2b-apigateway-test.azurewebsites.net",
  authority: "https://fengel-b2b-identityserver-test.azurewebsites.net",
  environment: "test", //local, test, staging & production
  scope: "gateway profile openid",
};

export const shouldUseFallbacks = window.environmentVariables.apiGatewayEndpoint.startsWith("$");

export const environmentVariables = shouldUseFallbacks
  ? fallbacks
  : window.environmentVariables;
