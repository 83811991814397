import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  groupsToShopTo: [],
  status: "",
  usersToShopTo: [],
});

const getters = {
  groupsToShopTo: state => state.groupsToShopTo,
  statusOfShopTo: state => state.status,
  usersToShopTo: state => state.usersToShopTo,
};

const actions = {
  ACTIVATE_SHOP_TO_MODE({ commit, dispatch, rootGetters }) {
    commit("SET_SHOP_TO_STATUS", { status: statuses.SAVING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .post(`${endpoints.ECOMMERCE}/shopToUser/${userId}/shopToState`)
      .then(() => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.SUCCESS });
        dispatch("basket/GET_BASKET", null, { root: true });
        dispatch(
          "authentication/UPDATE_SHOP_TO_STATE",
          { value: true },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DEACTIVATE_SHOP_TO_MODE({ commit, dispatch, rootGetters }) {
    commit("SET_SHOP_TO_STATUS", { status: statuses.LOADING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .delete(`${endpoints.ECOMMERCE}/shopToUser/${userId}/shopToState`)
      .then(() => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.SUCCESS });
        dispatch("basket/GET_BASKET", null, { root: true });
        dispatch(
          "authentication/UPDATE_SHOP_TO_STATE",
          { value: false },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_GROUPS_TO_SHOP_TO({ commit, rootGetters }) {
    commit("SET_SHOP_TO_STATUS", { status: statuses.LOADING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .get(
        `${endpoints.ECOMMERCE}/shopToUser/${userId}/shop-for-usergroups-state`,
      )
      .then(({ data }) => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.SUCCESS });
        commit("SET_GROUPS_TO_SHOP_TO", { groups: data });
      })
      .catch(error => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USERS_FOR_VARIANT({ commit, rootGetters }, payload) {
    commit("SET_SHOP_TO_STATUS", { status: statuses.LOADING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .get(`${endpoints.ECOMMERCE}/shopToUser/${userId}/getUsers/${payload.id}`)
      .then(({ data }) => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.SUCCESS });
        commit("SET_USERS_TO_SHOP_TO", { users: data });
      })
      .catch(error => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_USERS_FOR_VARIANT({ commit }) {
    commit("SET_USERS_TO_SHOP_TO", { users: [] });
  },
  UPDATE_GROUPS_TO_SHOP_TO({ commit, getters, rootGetters }, payload) {
    commit("SET_SHOP_TO_STATUS", { status: statuses.SAVING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .post(
        `${endpoints.ECOMMERCE}/shopToUser/${userId}/shop-for-usergroups-state`,
        payload,
      )
      .then(({ data }) => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.SUCCESS });

        const groups = getters.groupsToShopTo.map(group => ({
          ...group,
          isSelected: data.includes(group.userGroupId),
        }));

        commit("SET_GROUPS_TO_SHOP_TO", { groups });
      })
      .catch(error => {
        commit("SET_SHOP_TO_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_GROUPS_TO_SHOP_TO(state, payload) {
    state.groupsToShopTo = payload.groups;
  },
  SET_SHOP_TO_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_USERS_TO_SHOP_TO(state, payload) {
    state.usersToShopTo = payload.users;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
