<template>
  <Card v-if="shouldShowBlock" :title="headline" class="rich-text-card">
    <template v-if="hasAccessToManageDashboards" #header>
      <EditorTrigger :content-key="contentKey" />
    </template>
    <div class="rich-text" v-html="bodyText"></div>
  </Card>
</template>

<script>
import Card from "@/components/Card";
import EditorTrigger from "@/components/EditorTrigger";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "RichTextCard",
  components: {
    Card,
    EditorTrigger,
  },
  props: {
    contentKey: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      suitableLanguageCode: null,
    };
  },
  computed: {
    ...mapGetters("authentication", ["siteLanguage"]),
    ...mapGetters("content", ["selfManagedContent"]),
    ...mapGetters("customers", ["languageOfCurrentCustomer"]),
    ...mapGetters("siteSettings", ["languageCodes"]),
    bodyText() {
      if (!this.content) return null;

      return this.content.body || null;
    },
    content() {
      const { contentKey, selfManagedContent, suitableLanguageCode } = this;

      if (!contentKey || !selfManagedContent || !suitableLanguageCode) {
        return null;
      }

      return JSON.parse(
        selfManagedContent[`${contentKey}-${suitableLanguageCode}`],
      );
    },
    headline() {
      if (!this.content) return null;

      return this.content.headline || null;
    },
    shouldShowBlock() {
      return this.hasAccessToManageDashboards || this.headline || this.bodyText;
    },
  },
  watch: {
    selfManagedContent: {
      deep: true,
      handler() {
        this.setSuitableLanguageCode();
      },
    },
  },
  created() {
    const { contentKey } = this;

    if (!contentKey) return;

    this.GET_SELF_MANAGED_CONTENT_FOR_ALL_LANGUAGES({ contentKey }).then(() => {
      this.setSuitableLanguageCode();
    });
  },
  methods: {
    ...mapActions("content", [
      "GET_SELF_MANAGED_CONTENT",
      "GET_SELF_MANAGED_CONTENT_FOR_ALL_LANGUAGES",
    ]),
    setSuitableLanguageCode() {
      /*
        The selected order of suitable language is as follows:
        1) Language of the user
        2) Language of the customer
        3) English
        4) Whatever language that has content (follows order of data from backend)
      */

      const {
        contentKey,
        languageCodes,
        languageOfCurrentCustomer,
        selfManagedContent,
        siteLanguage,
      } = this;

      const orderedLanguageCodes = Array.from(
        new Set([
          siteLanguage,
          languageOfCurrentCustomer,
          "en",
          ...languageCodes.filter(
            languageCode =>
              languageCode !== siteLanguage &&
              languageCode !== languageOfCurrentCustomer &&
              languageCode !== "en",
          ),
        ]),
      ).filter(languageCode => languageCode);

      this.suitableLanguageCode = orderedLanguageCodes.find(languageCode => {
        const content = selfManagedContent[`${contentKey}-${languageCode}`];

        if (!content) return false;

        const parsedContent = JSON.parse(content);

        return parsedContent.headline || parsedContent.body;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.rich-text {
  .card__content--with-title & {
    margin-top: 0 !important;
  }

  &-card {
    display: flex;
    flex-direction: column;
    max-height: 100%;
    overflow: hidden;
  }
}
</style>
