<template>
  <Container v-if="blockData" :medium="true">
    <div
      class="block block--offset block--tall"
      :class="{
        'block--dark-background': blockData.darkBackground,
        'block--use-right-side-layout': shouldImageBePlacedToTheRight,
      }"
    >
      <div class="block__40-percent block__media-container">
        <ImageFromUmbraco
          v-if="blockData.image"
          :id="blockData.image._system.id"
          :alt="blockData.image.alternateText"
          :height="blockData.image.umbracoHeight"
          :keys-and-sizes="{
            s: 'block-regular-small',
            m: 'block-tall-medium',
            l: 'block-extra-tall-medium',
            xl: 'block-wide-medium',
          }"
          :width="blockData.image.umbracoWidth"
          class="block__image"
        />
      </div>
      <div class="block__60-percent">
        <div
          class="block__content block__content--limited-width block__content--middle"
        >
          <div>
            <div v-if="blockData.caption" class="block__keywords">
              {{ blockData.caption }}
            </div>
            <Heading
              v-if="blockData.headline"
              :tag="blockData.headlineTag"
              class="block__title"
            >
              {{ blockData.headline }}
            </Heading>
            <p v-if="blockData.description" class="block__description">
              {{ blockData.description }}
            </p>
            <ConditionalRouterLink
              v-if="blockData.cTA && blockData.cTA.name && blockData.cTA.url"
              :class="{ 'block__button--ghost': !blockData.darkBackground }"
              :target="blockData.cTA.target"
              :to="blockData.cTA.url"
              :type="blockData.cTA.type"
              class="block__button"
              >{{ blockData.cTA.name }}</ConditionalRouterLink
            >
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "OffsetImageAndTextGridElement",
  components: {
    ConditionalRouterLink,
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
  computed: {
    shouldImageBePlacedToTheRight() {
      if (!this.blockData || !this.blockData.layout) return true;

      return this.blockData.layout.toLowerCase() === "right";
    },
  },
};
</script>
