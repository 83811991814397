import axios from "axios";
import { alertTypes } from "@/helpers/alertTypes";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  booleanFilterParams: [],
  currentExport: null,
  dateFrom: null,
  dateTo: null,
  facetFilterParams: [],
  filters: null,
  isModalForSavedExportsOpen: false,
  isModalForScheduledExportOpen: false,
  savedExports: [],
  status: "",
});

const getters = {
  booleanFilterParams: state => state.booleanFilterParams,
  currentExport: state => state.currentExport,
  dateFrom: state => state.dateFrom,
  dateTo: state => state.dateTo,
  facetFilterParams: state => state.facetFilterParams,
  filters: state => state.filters,
  isModalForSavedExportsOpen: state => state.isModalForSavedExportsOpen,
  isModalForScheduledExportOpen: state => state.isModalForScheduledExportOpen,
  savedExports: state => state.savedExports,
  statusOfExports: state => state.status,
};

const actions = {
  CLOSE_MODAL_FOR_SAVED_EXPORTS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_SAVED_EXPORTS", {
      value: false,
    });
  },
  CLOSE_MODAL_FOR_SCHEDULED_EXPORT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_SCHEDULED_EXPORT", {
      value: false,
    });
  },
  CREATE_REPORT({ commit }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.SAVING });

    const {
      booleanFilterParams,
      customerId,
      dayOfMonth,
      dayOfWeek,
      emails,
      facetFilterParams,
      id,
      monthOfYear,
      name,
      reportType,
      scheduleType,
      timeOfDay,
    } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/report`, {
        booleanFilterParams,
        customerId,
        dayOfMonth,
        dayOfWeek,
        emails,
        facetFilterParams,
        id,
        monthOfYear,
        name,
        reportType,
        scheduleType,
        timeOfDay,
      })
      .then(() => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_SAVED_ORDER_EXPORT({ commit }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    return axios
      .delete(`${endpoints.ECOMMERCE}/report/${payload.id}`)
      .then(() => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_SAVED_USER_EXPORT({ commit }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    return axios
      .delete(`${endpoints.ECOMMERCE}/report/${payload.id}`)
      .then(() => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DUPLICATE_SAVED_ORDER_EXPORT({ commit }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/report/${payload.id}/duplicate`)
      .then(() => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DUPLICATE_SAVED_USER_EXPORT({ commit }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/report/${payload.id}/duplicate`)
      .then(() => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  EXPORT_CSV({ commit, getters }, payload) {
    /* eslint-disable */
    commit("SET_EXPORTS_STATUS", { status: statuses.SAVING });

    const {
      booleanFilterParams,
      dateFrom: createDateFrom,
      dateTo: createDateTo,
      facetFilterParams,
    } = getters;

    // console.log("booleanFilterParams", booleanFilterParams);
    // console.log("facetFilterParams", facetFilterParams);
    const { customerId, reportType } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/report/exportnow`,
        {
          booleanFilterParams,
          createDateFrom,
          createDateTo,
          customerId,
          facetFilterParams,
          reportType,
        },
        {
          responseType: "blob",
        },
      )
      .then(({ data }) => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_FILTERS_FOR_ORDER_EXPORT({ commit }, payload) {
    const { customerId } = payload;

    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/order-management/customers/${customerId}/all-facets`,
      )
      .then(({ data }) => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_FILTERS", { filters: data });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_FILTERS_FOR_USER_EXPORT({ commit }, payload) {
    const { customerId } = payload;

    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${customerId}/all-facets`)
      .then(({ data }) => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_FILTERS", { filters: data });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SAVED_EXPORT({ commit }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/report/${payload.id}`)
      .then(({ data }) => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_EXPORT", { export: data });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SAVED_ORDER_EXPORTS({ commit }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    const { customerId } = payload;

    return axios
      .get(`${endpoints.ECOMMERCE}/report/order/listforuser`, {
        params: { customerId },
      })
      .then(({ data }) => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_EXPORTS", { exports: data });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SAVED_USER_EXPORTS({ commit }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    const { customerId } = payload;

    return axios
      .get(`${endpoints.ECOMMERCE}/report/user/listforuser`, {
        params: { customerId },
      })
      .then(({ data }) => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_EXPORTS", { exports: data });
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_SAVED_EXPORTS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_SAVED_EXPORTS", {
      value: true,
    });
  },
  OPEN_MODAL_FOR_SCHEDULED_EXPORT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_SCHEDULED_EXPORT", {
      value: true,
    });
  },
  RESET_CURRENT_EXPORT({ commit }) {
    commit("SET_CURRENT_EXPORT", { export: null });
  },
  RESET_DATES({ commit }) {
    commit("SET_DATE_FROM", { value: null });
    commit("SET_DATE_TO", { value: null });
  },
  RESET_EXPORTS({ commit }) {
    commit("SET_EXPORTS", { exports: [] });
  },
  RESET_FILTERS({ commit }) {
    commit("SET_FILTERS", { filters: null });
  },
  SEND_EXPORT({ commit, dispatch }, payload) {
    commit("SET_EXPORTS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/report/${payload.id}/sendnow`)
      .then(() => {
        commit("SET_EXPORTS_STATUS", { status: statuses.SUCCESS });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "TODO.ReportSentDictionary",
            type: alertTypes.SUCCESS,
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        commit("SET_EXPORTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_BOOLEAN_FILTER_PARAMS({ commit }, payload) {
    commit("SET_BOOLEAN_FILTER_PARAMS", {
      booleanFilterParams: payload.booleanFilterParams,
    });
  },
  UPDATE_DATE_FROM({ commit }, payload) {
    commit("SET_DATE_FROM", { value: payload.date });
  },
  UPDATE_DATE_TO({ commit }, payload) {
    commit("SET_DATE_TO", { value: payload.date });
  },
  UPDATE_FACET_FILTER_PARAMS({ commit }, payload) {
    commit("SET_FACET_FILTER_PARAMS", {
      facetFilterParams: payload.facetFilterParams,
    });
  },
};

const mutations = {
  SET_BOOLEAN_FILTER_PARAMS(state, payload) {
    state.booleanFilterParams = payload.booleanFilterParams;
  },
  SET_CURRENT_EXPORT(state, payload) {
    state.currentExport = payload.export;
  },
  SET_DATE_FROM(state, payload) {
    state.dateFrom = payload.value;
  },
  SET_DATE_TO(state, payload) {
    state.dateTo = payload.value;
  },
  SET_EXPORTS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_EXPORTS(state, payload) {
    state.savedExports = payload.exports;
  },
  SET_FACET_FILTER_PARAMS(state, payload) {
    state.facetFilterParams = payload.facetFilterParams;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload.filters;
  },
  SET_STATE_OF_MODAL_FOR_SAVED_EXPORTS(state, payload) {
    state.isModalForSavedExportsOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_SCHEDULED_EXPORT(state, payload) {
    state.isModalForScheduledExportOpen = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
