import { da, de, enGB as en, es, fr, nb, nl } from "date-fns/locale";
import { formatRelative, parseISO } from "date-fns";
import { mapGetters } from "vuex";

//These are the language codes used in the backend. Notice that "enGB" is imported as "en" to normalize the names.
const supportedLanguages = ["da", "de", "en", "es", "fr", "nb", "nl"];

export default {
  computed: {
    ...mapGetters("authentication", ["userConfiguration"]),
    getRelativeDate() {
      const { userConfiguration } = this;

      return date => {
        if (!date) {
          console.error("A date has to be provided.");
          return null;
        }

        let locale;

        if (userConfiguration.contextSiteLanguage) {
          const matchingLanguage = supportedLanguages.find(
            language => language === userConfiguration.contextSiteLanguage,
          );

          if (!matchingLanguage) return;

          switch (matchingLanguage) {
            case "da":
              locale = da;
              break;
            case "de":
              locale = de;
              break;
            case "en":
              locale = en;
              break;
            case "es":
              locale = es;
              break;
            case "fr":
              locale = fr;
              break;
            case "nb":
              locale = nb;
              break;
            case "nl":
              locale = nl;
              break;
            default:
              locale = en;
          }
        }

        return formatRelative(parseISO(date), new Date(), {
          locale: locale || en,
        });
      };
    },
  },
  methods: {
    convertDateToUTC(value) {
      if (!value) return null;

      const date = new Date(value);

      const utcDate = Date.UTC(
        date.getFullYear(),
        date.getMonth(),
        date.getDate(),
      );

      return new Date(utcDate);
    },
  },
};
