import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { alertTypes } from "@/helpers/alertTypes";
import { statuses } from "@/helpers/statuses";
import getFlattenUserGroups from "@/helpers/userGroups";

const state = () => ({
  currentCustomer: null,
  currentCustomerChildren: [],
  currentCustomerCustomerServiceRepresentatives: [],
  currentCustomerForCheckout: null,
  currentCustomerId: null,
  currentCustomerProduct: null,
  currentCustomerRevenue: null,
  currentCustomerRole: null,
  currentCustomerRoleId: null,
  currentCustomerSalespersons: [],
  currentCustomerUser: null,
  currentCustomerUserId: null,
  currentCustomerUserGroups: null,
  customers: [],
  dealerForDashboard: null,
  expandedCustomers: [],
  filters: {},
  highscores: [],
  isModalForCreatingCustomerRoleOpen: false,
  isModalForEditingProductOpen: false,
  pagination: {},
  selectedCustomerRoles: [],
  selectedCustomerSalespersons: [],
  selectedCustomerUserGroups: [],
  selectedCustomerUsers: [],
  status: "",
});

const getters = {
  addressOfCurrentCustomer: state => {
    if (!state.currentCustomer) return null;

    return state.currentCustomer.address || null;
  },
  addressesOfCurrentCustomer: state => {
    if (!state.currentCustomer) return [];

    return state.currentCustomer.addresses || [];
  },
  childrenOfCurrentCustomer: state => {
    if (!state.currentCustomer) return [];

    return state.currentCustomer.children || [];
  },
  childrenToShopFor: state => {
    if (!state.currentCustomer) return [];

    return state.currentCustomer.childrenToShopFor || [];
  },
  currentCustomer: state => state.currentCustomer,
  currentCustomerCustomerServiceRepresentatives: state =>
    state.currentCustomerCustomerServiceRepresentatives,
  currentCustomerForCheckout: state => state.currentCustomerForCheckout,
  currentCustomerId: state => state.currentCustomerId,
  currentCustomerProduct: state => state.currentCustomerProduct,
  currentCustomerRevenue: state => state.currentCustomerRevenue,
  currentCustomerRole: state => state.currentCustomerRole,
  currentCustomerRoleId: state => state.currentCustomerRoleId,
  currentCustomerSalespersons: state => state.currentCustomerSalespersons,
  currentCustomerUser: state => state.currentCustomerUser,
  currentCustomerUserId: state => state.currentCustomerUserId,
  currentPage: state => {
    if (!state.pagination) return 0;

    return state.pagination.currentPage || 0;
  },
  currentPrimaryCustomerCustomerServiceRepresentative: state =>
    state.currentCustomerCustomerServiceRepresentatives.find(
      salesperson => salesperson.isPrimary,
    ),
  currentPrimaryCustomerSalesperson: state =>
    state.currentCustomerSalespersons.find(
      salesperson => salesperson.isPrimary,
    ),
  customers: state => state.customers,
  dealerForDashboard: state => state.dealerForDashboard,
  divisionIdOfCurrentCustomer: state => {
    if (!state.currentCustomer || !state.currentCustomer.division) return null;

    return state.currentCustomer.division;
  },
  endCustomerTopScores: state => {
    const { highscores } = state;

    if (!highscores || !highscores.endCustomerTopScores) return [];

    return highscores.endCustomerTopScores;
  },
  expandedCustomers: state => state.expandedCustomers,
  filters: state => state.filters,
  highscores: state => state.highscores,
  isCurrentCustomerDealer: state => {
    if (!state.currentCustomer || !state.currentCustomer.customerType) {
      return false;
    }

    return state.currentCustomer.customerType.toLowerCase() === "dealer";
  },
  isCurrentCustomerDeleted: state => {
    if (!state.currentCustomer) return false;

    return state.currentCustomer.isDeleted;
  },
  isCurrentCustomerEndCustomer: state => {
    if (!state.currentCustomer || !state.currentCustomer.customerType) {
      return false;
    }

    return state.currentCustomer.customerType.toLowerCase() === "endcustomer";
  },
  isModalForCreatingCustomerOpen: state => state.isModalForCreatingCustomerOpen,
  isModalForCreatingCustomerRoleOpen: state =>
    state.isModalForCreatingCustomerRoleOpen,
  languageOfCurrentCustomer: state => {
    if (!state.currentCustomer || !state.currentCustomer.language) return null;

    return state.currentCustomer.language;
  },
  nameOfCurrentCustomer: state => {
    const { currentCustomer } = state;
    if (!currentCustomer) return null;

    const { name, name2 } = currentCustomer;

    return name ? (name2 ? `${name} - ${name2}` : name) : null;
  },
  nameOfCurrentCustomerForCheckout: state => {
    const { currentCustomerForCheckout } = state;
    if (!currentCustomerForCheckout) return null;

    const { name, name2 } = currentCustomerForCheckout;

    return name ? (name2 ? `${name} - ${name2}` : name) : null;
  },
  nameOfCurrentCustomerRole: state => {
    const { currentCustomerRole } = state;
    if (!currentCustomerRole) return null;

    return currentCustomerRole.name ? currentCustomerRole.name : null;
  },
  productsOfCurrentCustomer: state => {
    if (!state.currentCustomer || !state.currentCustomer.products) return [];

    return state.currentCustomer.products;
  },
  rolesOfCurrentCustomer: state => {
    if (!state.currentCustomer || !state.currentCustomer.roles) return [];

    return state.currentCustomer.roles;
  },
  selectedUserGroupsOfNewCustomerUser: state => {
    if (!state?.currentCustomerUserGroups) return [];

    return state.currentCustomerUserGroups;
  },
  selectedCustomerRoles: state => state.selectedCustomerRoles,
  selectedCustomerSalespersons: state => state.selectedCustomerSalespersons,
  selectedCustomerUserGroups: state => state.selectedCustomerUserGroups,
  statusOfCustomers: state => state.status,
  userGroupsOfCurrentCustomer: state => {
    if (!state.currentCustomer || !state.currentCustomer.userGroups) return [];

    return state.currentCustomer.userGroups;
  },
  totalNumberOfCustomers: state => {
    if (!state.pagination) return 0;

    return state.pagination.totalResults;
  },
  typeOfCurrentCustomer: state => {
    if (!state.currentCustomer || !state.currentCustomer.type) return null;

    return state.currentCustomer.type;
  },
};

const actions = {
  ADD_SALESPERSON_TO_CUSTOMER({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.SAVING });

    return axios
      .post(`${endpoints.ECOMMERCE}/customers/${payload.customerId}/salesRep`, {
        id: payload.userId,
      })
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SALESPERSONS_OF_CUSTOMER", {
          salesRepresentives: data,
        });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  CLOSE_MODAL_FOR_CREATING_CUSTOMER_ROLE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_CUSTOMER_ROLE", { value: false });
  },
  CLOSE_MODAL_FOR_EDITING_PRODUCT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT", { value: false });
  },
  CREATE_CUSTOMER_ROLE({ commit, dispatch }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/companies/${payload.id}/roles`, {
          ...payload.role,
        })
        .then(({ data }) => {
          commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_CUSTOMER_ROLE");
          resolve(data);
        })
        .catch(error => {
          commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_SELECTED_CUSTOMER_ROLES({ commit, state }) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/companies/${state.currentCustomerId}/roles/delete-multiple`,
        {
          identifiers: state.selectedCustomerRoles.map(role => role.id),
        },
      )
      .then(() => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("REMOVE_SELECTED_CUSTOMER_ROLES");
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CHILDREN_OF_CUSTOMER({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${payload.id}/children`)
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CHILDREN_OF_CUSTOMER", { children: data });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CHILDREN_TO_SHOP_FOR({ commit }) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/getshopforcustomer`)
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CHILDREN_TO_SHOP_FOR", { children: data });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CUSTOMER({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${payload.customerId}`)
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CUSTOMER", { customer: data });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CUSTOMER_FOR_CHECKOUT({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${payload.id}/checkout`)
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CUSTOMER_FOR_CHECKOUT", { customer: data });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CUSTOMERS({ commit }, payload) {
    const {
      booleanFilterParams,
      customerId,
      facetFilterParams,
      includeDeleted = false,
      pageNumberZeroBased,
      pageSize,
      searchPhrase,
    } = payload;

    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/customers`, {
          booleanFilterParams,
          customerId,
          facetFilterParams,
          includeDeleted,
          pageNumberZeroBased,
          pageSize,
          searchPhrase,
        })
        .then(({ data: { documents, filters, pagination } }) => {
          commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
          commit("SET_CUSTOMERS", { customers: documents });
          commit("SET_FILTERS", { filters });
          commit("SET_PAGINATION", { pagination });
          resolve({ filters });
        })
        .catch(error => {
          commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_CUSTOMERS_OF_SALESPERSON({ commit }, payload) {
    const {
      booleanFilterParams,
      customerId,
      facetFilterParams,
      includeDeleted,
      pageNumberZeroBased,
      pageSize,
      searchPhrase,
    } = payload;

    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/customers/searchForSalesperson${
            payload && payload.userId ? "/" + payload.userId : ""
          }`,
          {
            booleanFilterParams,
            customerId,
            facetFilterParams,
            includeDeleted,
            pageNumberZeroBased,
            pageSize,
            searchPhrase,
          },
        )
        .then(({ data: { documents, filters, pagination } }) => {
          commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
          commit("SET_CUSTOMERS", { customers: documents });
          commit("SET_FILTERS", { filters });
          commit("SET_PAGINATION", { pagination });
          resolve({ filters });
        })
        .catch(error => {
          commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_DEALER_FOR_DASHBOARD({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${payload.customerId}/dashboard`)
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_DEALER_FOR_DASHBOARD", { dealer: data });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_REVENUE_FOR_DEALER_WITH_ENDCUSTOMERS({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/order-management/customers/${payload.customerId}/revenuereport-for-dealer-with-endcustomers`,
      )
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_REVENUE_HIGHSCORE_CUSTOMERS", { highscores: data });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_REVENUE_OF_CUSTOMER({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/order-management/customers/${payload.customerId}/revenueReport`,
      )
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_REVENUE_OF_CUSTOMER", { revenue: data });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ROLE_OF_CUSTOMER({ commit, dispatch }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/companies/${payload.customerId}/roles/${payload.roleId}`,
      )
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CUSTOMER_ROLE", { role: data });
        dispatch("CLOSE_MODAL_FOR_CREATING_CUSTOMER_ROLE");
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ROLES_OF_CUSTOMER({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/companies/${payload.customerId}/roles`)
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_ROLES_OF_CUSTOMER", { roles: data });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SALESPERSONS_OF_CUSTOMER({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${payload.customerId}/salesRep`)
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SALESPERSONS_OF_CUSTOMER", {
          salesRepresentives: data,
        });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_CREATING_CUSTOMER_ROLE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_CUSTOMER_ROLE", { value: true });
  },
  OPEN_MODAL_FOR_EDITING_PRODUCT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT", { value: true });
  },
  REMOVE_CUSTOMER_LOGO({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.SAVING });

    return axios
      .delete(`${endpoints.ECOMMERCE}/customers/${payload.customerId}/logo`)
      .then(() => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_LOGO_KEY_OF_CURRENT_CUSTOMER", { logoMediaKey: null });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  REMOVE_SALESPERSONS_FROM_CUSTOMER({ commit, getters }) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.SAVING });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/customers/${getters.currentCustomerId}/salesRep/delete-multiple`,
        {
          identifiers: getters.selectedCustomerSalespersons.map(({ id }) => id),
        },
      )
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SALESPERSONS_OF_CUSTOMER", {
          salesRepresentives: data,
        });
        commit("RESET_SELECTED_SALESPERSONS_OF_CUSTOMER");
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_CHILDREN_OF_CUSTOMER({ commit }) {
    commit("SET_CHILDREN_OF_CUSTOMER", { children: [] });
  },
  TOGGLE_CUSTOMER_SALESPERSON({ commit }, payload) {
    commit("SET_SELECTED_CUSTOMER_SALESPERSON", payload);
  },
  TOGGLE_CUSTOMER_SALESPERSONS({ commit }, payload) {
    commit("SET_SELECTED_CUSTOMER_SALESPERSONS", payload);
  },
  TOGGLE_CUSTOMER_ROLE({ commit }, payload) {
    commit("SET_SELECTED_CUSTOMER_ROLE", payload);
  },
  TOGGLE_CUSTOMER_ROLES({ commit }, payload) {
    commit("SET_SELECTED_CUSTOMER_ROLES", payload);
  },
  UPDATE_CURRENT_CUSTOMER_ID({ commit }, payload) {
    commit("SET_CURRENT_CUSTOMER_ID", payload);

    if (payload.avoidResettingCustomer) return;

    commit("SET_CURRENT_CUSTOMER", { customer: null });
  },
  UPDATE_CURRENT_CUSTOMER_PRODUCT({ commit }, payload) {
    commit("SET_CURRENT_CUSTOMER_PRODUCT", payload);
  },
  UPDATE_CURRENT_CUSTOMER_ROLE_ID({ commit }, payload) {
    commit("SET_CURRENT_CUSTOMER_ROLE_ID", payload);
    commit("SET_CURRENT_CUSTOMER_ROLE", { role: null });
  },
  UPDATE_CUSTOMER({ commit, dispatch }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.SAVING });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/customers/${payload.customerId}`,
        payload.customer,
      )
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CUSTOMER", { customer: data });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "Common.YourChangesWereSaved",
            type: alertTypes.SUCCESS,
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_CUSTOMER_ROLE({ commit, dispatch }, payload) {
    const { customerId, role, roleId } = payload;

    commit("SET_CUSTOMERS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/companies/${customerId}/roles/${roleId}`,
          role,
        )
        .then(({ data }) => {
          commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
          commit("SET_CURRENT_CUSTOMER_ROLE", { role: data });
          dispatch(
            "alerts/OPEN_ALERT",
            {
              key: "Common.YourChangesWereSaved",
              type: alertTypes.SUCCESS,
            },
            {
              root: true,
            },
          );
          resolve(data);
        })
        .catch(error => {
          commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  UPDATE_PUBLISH_STATE_OF_CUSTOMER({ commit }, payload) {
    return new Promise(resolve => {
      commit("SET_PUBLISH_STATE_OF_CUSTOMER", payload);
      resolve();
    });
  },
  UPLOAD_CUSTOMER_LOGO({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.SAVING });

    const formData = new FormData();
    formData.append("image", payload.file);

    return axios
      .post(
        `${endpoints.ECOMMERCE}/customers/${payload.customerId}/logo`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      )
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_LOGO_KEY_OF_CURRENT_CUSTOMER", { logoMediaKey: data.id });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  // user groups
  GET_USER_GROUP_OF_CUSTOMER({ commit }, payload) {
    commit("SET_CUSTOMERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/customers/${payload.customerId}/usergroups/roles`,
      )
      .then(({ data }) => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.SUCCESS });

        const flattenGroups = getFlattenUserGroups(data?.parentGroups ?? []);

        commit("SET_CURRENT_CUSTOMER_USER_GROUPS", {
          userGroups: flattenGroups,
        });
      })
      .catch(error => {
        commit("SET_CUSTOMERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  REMOVE_SELECTED_CUSTOMER(state) {
    state.customers = state.customers.filter(
      customer =>
        !state.selectedCustomers.some(
          selectedCustomer => selectedCustomer.id === customer.id,
        ),
    );

    state.selectedCustomers = [];
  },
  REMOVE_SELECTED_CUSTOMER_ROLES(state) {
    state.currentCustomer = {
      ...state.currentCustomer,
      roles: state.currentCustomer.roles.filter(
        role =>
          !state.selectedCustomerRoles.some(
            selectedRole => selectedRole.id === role.id,
          ),
      ),
    };

    state.selectedCustomerRoles = [];
  },
  RESET_SELECTED_SALESPERSONS_OF_CUSTOMER(state) {
    state.selectedCustomerSalespersons = [];
  },
  SET_CHILDREN_OF_CUSTOMER(state, payload) {
    state.currentCustomer = {
      ...state.currentCustomer,
      children: payload.children,
    };
  },
  SET_CHILDREN_TO_SHOP_FOR(state, payload) {
    state.currentCustomer = {
      ...state.currentCustomer,
      childrenToShopFor: payload.children,
    };
  },
  SET_CURRENT_CUSTOMER(state, payload) {
    let customer = { ...payload.customer };

    if (state.currentCustomer) {
      if (state.currentCustomer.children) {
        customer = { ...customer, children: state.currentCustomer.children };
      }

      if (state.currentCustomer.roles) {
        customer = { ...customer, roles: state.currentCustomer.roles };
      }
    }

    state.currentCustomer = customer;
  },
  SET_CURRENT_CUSTOMER_FOR_CHECKOUT(state, payload) {
    state.currentCustomerForCheckout = payload.customer;
  },
  SET_CURRENT_CUSTOMER_ID(state, payload) {
    state.currentCustomerId = payload.customerId;
  },
  SET_CURRENT_CUSTOMER_PRODUCT(state, payload) {
    state.currentCustomerProduct = payload.product;
  },
  SET_CURRENT_CUSTOMER_ROLE(state, payload) {
    state.currentCustomerRole = payload.role;
  },
  SET_CURRENT_CUSTOMER_USER_GROUPS(state, payload) {
    state.currentCustomerUserGroups = payload.userGroups;
  },
  SET_CURRENT_CUSTOMER_ROLE_ID(state, payload) {
    state.currentCustomerRoleId = payload.id;
  },
  SET_CUSTOMERS(state, payload) {
    state.customers = payload.customers;
    state.pagination = payload.pagination;
  },
  SET_CUSTOMER_SERVICE_REPRESENTATIVE_OF_CUSTOMER(state, payload) {
    state.currentCustomerCustomerServiceRepresentatives =
      payload.salesRepresentives;
  },
  SET_CUSTOMERS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_DEALER_FOR_DASHBOARD(state, payload) {
    state.dealerForDashboard = payload.dealer;
  },
  SET_FILTERS(state, payload) {
    state.filters = payload.filters;
  },
  SET_REVENUE_HIGHSCORE_CUSTOMERS(state, payload) {
    state.highscores = payload.highscores;
  },
  SET_LOGO_KEY_OF_CURRENT_CUSTOMER(state, payload) {
    state.currentCustomer = {
      ...state.currentCustomer,
      logoMediaKey: payload.logoMediaKey,
    };
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload.pagination;
  },
  SET_PUBLISH_STATE_OF_CUSTOMER(state, payload) {
    const { id, value } = payload;

    const indexOfAffectedCustomer = state.customers.findIndex(
      customer => customer.id === id || state.currentCustomerId,
    );

    if (indexOfAffectedCustomer === -1) return;

    Object.assign(state.customers[indexOfAffectedCustomer], {
      published: value,
    });
  },
  SET_PRODUCTS_OF_CUSTOMER(state, payload) {
    state.currentCustomer = {
      ...state.currentCustomer,
      products: payload.products,
    };
  },
  SET_REVENUE_OF_CUSTOMER(state, payload) {
    state.currentCustomerRevenue = payload.revenue;
  },
  SET_ROLES_OF_CUSTOMER(state, payload) {
    state.currentCustomer = {
      ...state.currentCustomer,
      roles: payload.roles,
    };
  },
  SET_SALESPERSONS_OF_CUSTOMER(state, payload) {
    state.currentCustomerSalespersons = payload.salesRepresentives;
  },
  SET_SELECTED_CUSTOMER_ROLE(state, payload) {
    const { row: customerRole, value } = payload;

    if (value) {
      if (
        state.selectedCustomerRoles.some(entry => entry.id === customerRole.id)
      ) {
        return;
      }

      state.selectedCustomerRoles.push(customerRole);
    } else {
      state.selectedCustomerRoles = state.selectedCustomerRoles.filter(
        selectedCustomerRole => selectedCustomerRole.id !== customerRole.id,
      );
    }
  },
  SET_SELECTED_CUSTOMER_ROLES(state, payload) {
    const { rows: customerRoles, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedCustomerRoles.map(
          selectedCustomerRole => selectedCustomerRole.id,
        ),
      );

      state.selectedCustomerRoles = [
        ...state.selectedCustomerRoles,
        ...customerRoles
          .filter(role => !role.disableSelection)
          .filter(role => !identifiers.has(role.id)),
      ];
    } else {
      state.selectedCustomerRoles = state.selectedCustomerRoles.filter(
        selectedCustomerRole =>
          !customerRoles.some(entry => entry.id === selectedCustomerRole.id),
      );
    }
  },
  SET_SELECTED_CUSTOMER_SALESPERSON(state, payload) {
    const { row: customerSalesperson, value } = payload;

    if (value) {
      if (
        state.selectedCustomerSalespersons.some(
          entry => entry.id === customerSalesperson.id,
        )
      ) {
        return;
      }

      state.selectedCustomerSalespersons.push(customerSalesperson);
    } else {
      state.selectedCustomerSalespersons = state.selectedCustomerSalespersons.filter(
        selectedSalesperson =>
          selectedSalesperson.id !== customerSalesperson.id,
      );
    }
  },
  SET_SELECTED_CUSTOMER_SALESPERSONS(state, payload) {
    const { rows: customerSalespersons, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedCustomerSalespersons.map(
          selectedSalesperson => selectedSalesperson.id,
        ),
      );

      state.selectedCustomerSalespersons = [
        ...state.selectedCustomerSalespersons,
        ...customerSalespersons
          .filter(salesperson => !salesperson.disableSelection)
          .filter(salesperson => !identifiers.has(salesperson.id)),
      ];
    } else {
      state.selectedCustomerSalespersons = state.selectedCustomerSalespersons.filter(
        selectedSalesperson =>
          !customerSalespersons.some(
            entry => entry.id === selectedSalesperson.id,
          ),
      );
    }
  },
  SET_SELECTED_CUSTOMER_USER(state, payload) {
    const { row: customerUser, value } = payload;

    if (value) {
      if (
        state.selectedCustomerUsers.some(entry => entry.id === customerUser.id)
      ) {
        return;
      }

      state.selectedCustomerUsers.push(customerUser);
    } else {
      state.selectedCustomerUsers = state.selectedCustomerUsers.filter(
        selectedCustomerUser => selectedCustomerUser.id !== customerUser.id,
      );
    }
  },
  SET_SELECTED_CUSTOMER_USERS(state, payload) {
    const { rows: customerUsers, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedCustomerUsers.map(
          selectedCustomerUser => selectedCustomerUser.id,
        ),
      );

      state.selectedCustomerUsers = [
        ...state.selectedCustomerUsers,
        ...customerUsers
          .filter(user => !user.disableSelection)
          .filter(user => !identifiers.has(user.id)),
      ];
    } else {
      state.selectedCustomerUsers = state.selectedCustomerUsers.filter(
        selectedCustomerUser =>
          !customerUsers.some(entry => entry.id === selectedCustomerUser.id),
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_CREATING_CUSTOMER_ROLE(state, payload) {
    state.isModalForCreatingCustomerRoleOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT(state, payload) {
    state.isModalForEditingProductOpen = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
