import { render, staticRenderFns } from "./ShopToSizeSelector.vue?vue&type=template&id=2bec9200&scoped=true"
import script from "./ShopToSizeSelector.vue?vue&type=script&lang=js"
export * from "./ShopToSizeSelector.vue?vue&type=script&lang=js"
import style0 from "./ShopToSizeSelector.vue?vue&type=style&index=0&id=2bec9200&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2bec9200",
  null
  
)

export default component.exports