var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',_vm._b({staticClass:"button",class:{
    'button--buy': _vm.buy,
    'button--dark': _vm.dark,
    'button--extra-small': _vm.extraSmall,
    'button--faded': _vm.faded,
    'button--limited-width': _vm.limitedWidth,
    'button--link': _vm.link,
    'button--loading': _vm.internalShowLoader,
    'button--no-shadow': _vm.noShadow,
    'button--rotate-icon': _vm.rotateIcon,
    'button--round': _vm.round,
    'button--secondary': _vm.secondary,
    'button--small': _vm.small,
    'button--space-between': _vm.showArrow,
  },on:{"click":function($event){return _vm.$emit('click', $event)}}},'button',{ ..._vm.$attrs, ..._vm.$props },false),[_c('Loader',{attrs:{"light":!_vm.secondary,"visible":_vm.showLoader}}),_c('span',{staticClass:"button__icon-container"},[(_vm.showUser)?_c('svg',{staticClass:"button__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"}}),_c('circle',{attrs:{"cx":"12","cy":"7","r":"4"}})]):_vm._e(),_vm._t("default")],2),(_vm.showArrow)?_c('svg',{staticClass:"button__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M5 12h14M12 5l7 7-7 7"}})]):(_vm.showClose)?_c('svg',{staticClass:"button__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M18 6 6 18M6 6l12 12"}})]):(_vm.showDownload)?_c('svg',{staticClass:"button__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3"}})]):(_vm.showEye)?_c('svg',{staticClass:"button__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"}}),_c('circle',{attrs:{"cx":"12","cy":"12","r":"3"}})]):(_vm.showPlus)?_c('svg',{staticClass:"button__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M12 5v14M5 12h14"}})]):(_vm.showChevron)?_c('svg',{staticClass:"button__icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"m6 9 6 6 6-6"}})]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }