export default {
  methods: {
    getTotalPrice(basketLines) {
      if (!basketLines || !basketLines.length) return [];

      const totalPrice =
        basketLines
          .filter(basketLine => basketLine.totalLinePrice)
          .map(basketLine => basketLine.totalLinePrice.priceValue)
          .reduce((sum, price) => {
            return sum + price * 100;
          }, 0) / 100;

      return this.convertNumberToLocaleString(totalPrice);
    },
    getTotalPriceBeforeDiscount(basketLines) {
      if (!basketLines || !basketLines.length) return [];

      const totalPriceBeforeDiscount =
        basketLines
          .map(basketLine => basketLine.totalLinePrice.normalPriceValue)
          .reduce((sum, price) => {
            return sum + price * 100;
          }, 0) / 100;

      return this.convertNumberToLocaleString(totalPriceBeforeDiscount);
    },
  },
};
