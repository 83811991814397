var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.blockData)?_c('Container',{attrs:{"small":_vm.blockData.decreaseWidth}},[(_vm.blockData.headline || _vm.blockData.description)?_c('div',{staticClass:"block block--title",class:{ 'block--indent-text': _vm.blockData.indentText }},[_c('div',{staticClass:"block__content"},[_c('div',[(_vm.blockData.headline)?_c('Heading',{staticClass:"block__title block__title--with-line",attrs:{"tag":_vm.blockData.headlineTag}},[_vm._v(" "+_vm._s(_vm.blockData.headline)+" ")]):_vm._e(),(_vm.blockData.description)?_c('p',{staticClass:"block__description"},[_vm._v(" "+_vm._s(_vm.blockData.description)+" ")]):_vm._e()],1)])]):_vm._e(),_c('div',{staticClass:"block block--tall"},_vm._l((_vm.blockData.rowItems),function(item){return _c('div',{key:item._id,staticClass:"block__one-third block__media-container block__media-container--overlay",class:{
        'block--dark-background': item.darkBackground,
        'block--text-focus': item.darkBackground,
        'block__two-thirds': item.expandItem,
      }},[(item.image)?_c('ImageFromUmbraco',{staticClass:"block__image",attrs:{"id":item.image._system.id,"alt":item.image.alternateText,"height":item.image.umbracoHeight,"keys-and-sizes":{
          s: 'block-regular-small',
          m: 'block-tall-medium',
          l: 'block-tall-large',
          xl: 'block-tall-extra-large',
        },"width":item.image.umbracoWidth}}):_vm._e(),_c('div',{staticClass:"block__content block__content--bottom"},[_c('article',[(item.caption)?_c('div',[(item.darkBackground)?_c('div',{staticClass:"block__keywords"},[_vm._v(" "+_vm._s(item.caption)+" ")]):_c('div',{staticClass:"block__subtitle"},[_vm._v(_vm._s(item.caption))])]):_vm._e(),(item.headline)?_c('h2',{staticClass:"block__title"},[_vm._v(" "+_vm._s(item.headline)+" ")]):_vm._e(),(
              item.cTA && item.cTA.name && item.cTA.url && !item.hideButton
            )?_c('ConditionalRouterLink',{staticClass:"block__button",attrs:{"target":item.cTA.target,"to":item.cTA.url,"type":item.cTA.type}},[_vm._v(_vm._s(item.cTA.name))]):_vm._e()],1),(item.cTA && item.cTA.name && item.cTA.url && item.hideButton)?_c('ConditionalRouterLink',{staticClass:"block__cover-link",attrs:{"aria-label":item.cTA.name,"target":item.cTA.target,"to":item.cTA.url,"type":item.cTA.type}}):_vm._e()],1)],1)}),0)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }