<template>
  <Container v-if="blockData" :small="true">
    <div
      class="block block--extra-tall"
      :class="{ 'block--use-right-side-layout': shouldImageBePlacedToTheRight }"
    >
      <div class="block__55-percent block__media-container">
        <ImageFromUmbraco
          v-if="blockData.image"
          :id="blockData.image._system.id"
          :alt="blockData.image.alternateText"
          :height="blockData.image.umbracoHeight"
          :keys-and-sizes="{
            s: 'block-regular-small',
            m: 'block-tall-medium',
            l: 'block-extra-tall-medium',
            xl: 'block-extra-tall-medium',
          }"
          :width="blockData.image.umbracoWidth"
          class="block__image"
        />
      </div>
      <div class="block__45-percent">
        <div class="block__content block__content--limited-width">
          <div>
            <div v-if="blockData.caption" class="block__keywords">
              {{ blockData.caption }}
            </div>
            <Heading
              v-if="blockData.headline"
              :tag="blockData.headlineTag"
              class="block__title"
            >
              {{ blockData.headline }}
            </Heading>
            <p v-if="blockData.description" class="block__description">
              {{ blockData.description }}
            </p>
            <ConditionalRouterLink
              v-if="blockData.cTA && blockData.cTA.name && blockData.cTA.url"
              :target="blockData.cTA.target"
              :to="blockData.cTA.url"
              :type="blockData.cTA.type"
              class="block__button block__button--ghost"
              >{{ blockData.cTA.name }}</ConditionalRouterLink
            >
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "LargeImageAndTextGridElement",
  components: {
    ConditionalRouterLink,
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
  computed: {
    shouldImageBePlacedToTheRight() {
      if (!this.blockData || !this.blockData.layout) return true;

      return this.blockData.layout.toLowerCase() === "right";
    },
  },
};
</script>
