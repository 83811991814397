<template>
  <section>
    <Container v-if="blockData.headline || blockData.description" :small="true">
      <div
        class="block block--title"
        :class="{ 'block--indent-text': blockData.indentText }"
      >
        <div class="block__content">
          <div>
            <Heading
              v-if="blockData.headline"
              :tag="blockData.headlineTag"
              class="block__title block__title--with-line"
            >
              {{ blockData.headline }}
            </Heading>
            <p v-if="blockData.description" class="block__description">
              {{ blockData.description }}
            </p>
          </div>
        </div>
      </div>
    </Container>
    <div class="block block--tall">
      <div
        v-for="(item, index) in blockData.rowItems"
        :key="item._id"
        class="block__media-container block__media-container--overlay"
        :class="{
          'block__one-half': index === 0,
          'block__one-fourth': index !== 0,
        }"
      >
        <ImageFromUmbraco
          v-if="item.image"
          :id="item.image._system.id"
          :alt="item.image.alternateText"
          :height="item.image.umbracoHeight"
          :keys-and-sizes="{
            s: 'block-wide-small',
            m: 'block-tall-medium',
            l: 'block-tall-medium',
            xl: 'block-tall-large',
          }"
          :width="item.image.umbracoWidth"
          class="block__image"
        />
        <div class="block__content block__content--bottom">
          <article>
            <div v-if="item.caption" class="block__subtitle">
              {{ item.caption }}
            </div>
            <Heading
              v-if="item.headline"
              :tag="item.headlineTag"
              class="block__title"
            >
              {{ item.headline }}
            </Heading>
            <ConditionalRouterLink
              v-if="item.cTA && item.cTA.name && item.cTA.url"
              :target="item.cTA.target"
              :to="item.cTA.url"
              :type="item.cTA.type"
              class="block__button"
              >{{ item.cTA.name }}</ConditionalRouterLink
            >
          </article>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "CTARowGridElement",
  components: {
    ConditionalRouterLink,
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>
