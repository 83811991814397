<template>
  <Container :small="true">
    <div class="block block--media">
      <div class="block__content">
        <div class="block__media-container block__media-container--fill-parent">
          <ImageFromUmbraco
            v-if="blockData.image"
            :id="blockData.image._system.id"
            :alt="blockData.image.alternateText"
            :height="blockData.image.umbracoHeight"
            :keys-and-sizes="{
              s: 'block-square-small',
              m: 'block-square-medium',
              l: 'block-regular-medium',
              xl: 'block-regular-large',
            }"
            :width="blockData.image.umbracoWidth"
            class="block__image"
          />
          <div v-if="youTubeId" class="video-container">
            <iframe
              width="560"
              height="315"
              :src="
                `https://www.youtube-nocookie.com/embed/${youTubeId}?playlist=${youTubeId}&autoplay=1&loop=1&mute=1`
              "
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
              loop="1"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/Container";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";
import { getYouTubeId } from "@/helpers/videoId";

export default {
  name: "OffsetTextAndMediaGridElement",
  components: {
    Container,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
  computed: {
    youTubeId() {
      const { blockData } = this;

      if (!blockData || !blockData.youTubeLink) return null;

      return getYouTubeId(blockData.youTubeLink);
    },
  },
};
</script>
