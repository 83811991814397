var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.hasAccessToShop)?_c('div',{staticClass:"quick-basket"},[_c('Sidebar',{attrs:{"title":`${_vm.getDictionaryEntry('Common.Basket')} (${_vm.numberOfItemsInBasket} ${
        _vm.numberOfItemsInBasket === 1
          ? _vm.getDictionaryEntry('Common.Item').toLowerCase()
          : _vm.getDictionaryEntry('Common.Items').toLowerCase()
      })`,"right":true,"visible":_vm.visible},on:{"close":_vm.close},scopedSlots:_vm._u([{key:"body",fn:function(){return [(_vm.isShopToState)?_c('BasketLineListForShoppingToEmployees',{attrs:{"basket-lines":_vm.basketLines}}):(_vm.isUserEndCustomer)?_c('BasketLineListForEndCustomers',{attrs:{"basket-lines":_vm.basketLines}}):(
          _vm.isUserDealer && _vm.childrenToShopFor && _vm.childrenToShopFor.length
        )?_c('BasketLineListForCustomersWithEndCustomers',{attrs:{"basket-lines":_vm.basketLines}}):_c('BasketLineList',{attrs:{"basket-lines":_vm.basketLines}})]},proxy:true},{key:"footer",fn:function(){return [_c('FreeFreightInformation'),(_vm.totalPrice)?_c('h3',{staticClass:"quick-basket__total"},[_c('span',[_vm._v(_vm._s(_vm.getDictionaryEntry("Common.Labels.Total"))+":")]),_c('span',[_vm._v(" "+_vm._s(_vm.formatPriceCurrency(_vm.totalPrice.price))+" "+_vm._s(_vm.totalPrice.currency)+" ")])]):_vm._e(),_c('router-link',{staticClass:"button button--dark button--round quick-basket__checkout",class:{
          'button--disabled': _vm.isTotalPriceGreaterThanWalletBalance,
        },attrs:{"to":{ name: 'checkout' }},nativeOn:{"click":function($event){return _vm.close.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.linkTextForCheckout)+" ")])]},proxy:true}],null,false,4066695106)})],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }