var render = function render(){var _vm=this,_c=_vm._self._c;return _c('ol',{staticClass:"size-selector__list"},_vm._l((_vm.sizes),function(size){return _c('li',{key:size.sku,staticClass:"size-selector__item",class:{
      'size-selector__item--external-product':
        size.inventory && size.inventory.isExternalProduct,
      'size-selector__item--selected': size === _vm.selectedSize,
      'size-selector__item--selected--quick-buy':
        _vm.endCustomerQuickBuyMode && size === _vm.selectedSize,
    }},[_c('button',{staticClass:"size-selector__item-action",on:{"click":function($event){return _vm.$emit('select-size', size)}}},[_c('span',{staticClass:"size-selector__size"},[_vm._v(" "+_vm._s(_vm.getPreferredSizeChartValue(size))+" "),_c('span',{staticClass:"size-selector__date"},[_c('RestockDateInventoryWarning',{attrs:{"inventory":size.inventory}})],1)]),(size.inventory)?_c('span',{staticClass:"size-selector__stock"},[(size && size.inventory)?_c('StockIndicator',{attrs:{"indicator-only":true,"inventory":size.inventory,"ignore-show-stock":_vm.endCustomerQuickBuyMode}}):_vm._e(),(size === _vm.selectedSize)?_c('span',[(
              size.inventory.allowNegativeStock ||
                size.inventory.stock !== null
            )?_c('svg',{staticClass:"size-selector__stock-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 6 9 17l-5-5"}})]):_c('svg',{staticClass:"size-selector__stock-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M18 6 6 18M6 6l12 12"}})])]):_vm._e()],1):_vm._e()])])}),0)
}
var staticRenderFns = []

export { render, staticRenderFns }