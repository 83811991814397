<template>
  <div v-if="price">
    <div class="price">
      <span v-if="showFromLabel"
        >{{ getDictionaryEntry("Product.PricesFrom") }}
      </span>
      <span v-if="showPersonalLabel">
        {{ getDictionaryEntry("Product.YourPrice") }}:
      </span>
      {{ price.price }}
      {{ price.currency }}
      <span v-if="price.isDiscounted" class="price price--previous"
        >{{ price.normalPrice }} {{ price.currency }}</span
      >
    </div>
    <div
      v-if="price.retailPrice && !ignoreRetailPrice"
      class="price price--rrp"
    >
      {{
        getDictionaryEntry(
          shouldShowPricesWithVat
            ? "Common.Labels.RecommendedRetailPriceInclVat"
            : "Common.Labels.RecommendedRetailPriceExclVat",
        )
      }}:
      {{ price.retailPrice }}
      {{ price.currency }}
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Price",
  props: {
    ignoreRetailPrice: {
      default: false,
      type: Boolean,
    },
    price: {
      required: true,
      type: Object,
    },
    showFromLabel: {
      default: false,
      type: Boolean,
    },
    showPersonalLabel: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("authentication", ["divisionCode"]),
    shouldShowPricesWithVat() {
      if (!this.divisionCode || this.divisionCode.toLowerCase() === "fe") {
        return false;
      }

      return true;
    },
  },
};
</script>

<style lang="scss" scoped>
.price {
  font-family: var(--font-family-inter);
  font-size: 1.125rem;
  letter-spacing: -0.5px;

  @media (min-width: 64rem) {
    font-size: 1.25rem;
  }

  .product &,
  .quick-buy & {
    font-size: 0.875rem;
  }

  .product-details & {
    font-size: 1.125rem;
  }

  &:not(:last-child) {
    margin-bottom: calc(var(--spacing-stack) * 0.25);
  }

  &--previous {
    color: var(--color-error);
    font-size: 1rem;
    text-decoration: line-through;

    @media (min-width: 64rem) {
      font-size: 1.125rem;
    }

    .product &,
    .quick-buy & {
      font-size: 0.8125rem;
    }

    .product-details & {
      font-size: 0.9375rem;
    }
  }

  &--rrp {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.8125rem;
    font-weight: 400;

    .product-details & {
      font-size: 0.875rem;
    }
  }
}
</style>
