<template>
  <div v-if="customers" class="customers">
    <button
      class="customers__toggle"
      :class="{
        'customers__toggle--open': isOpen,
      }"
      @click="toggle"
    >
      <h2 class="customers__title">
        {{ getDictionaryEntry("Common.Labels.CompaniesBeingShoppedTo") }}
      </h2>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="customers__toggle-icon"
        viewBox="0 0 24 24"
      >
        <path d="m6 9 6 6 6-6" />
      </svg>
    </button>
    <ol ref="content" class="customers__list">
      <li
        v-for="customer in customers"
        :key="customer.id"
        class="customers__item"
      >
        <div class="customer">
          <div class="customer__block">
            <h3 class="customer__name">
              {{ customer.name }}
            </h3>
            <BasketLineSizes
              :sizes-with-quantities="customer.sizesWithQuantities"
            />
          </div>
          <div class="customer__block">
            <div>
              <div
                v-if="customer.discountPercent > 0"
                class="customer__discount"
              >
                {{ getDictionaryEntry("Common.Labels.Discount") }}:
                {{ customer.discountPercent }}%
              </div>
              <div
                v-for="unitPrice in customer.pricesWithQuantities"
                :key="unitPrice"
                class="customer__unit-with-price"
              >
                {{ unitPrice }}
              </div>
            </div>
            <router-link class="customer__edit" :to="getLink(customer.id)">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="customer__edit-icon"
                viewBox="0 0 24 24"
              >
                <path
                  d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"
                />
                <path
                  d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"
                />
              </svg>
            </router-link>
            <button
              class="customer__remove"
              @click="
                REMOVE_VARIANT_FROM_BASKET({
                  basketLine,
                  customerId: customer.id,
                })
              "
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="customer__remove-icon"
                viewBox="0 0 24 24"
              >
                <path
                  d="M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"
                />
              </svg>
            </button>
          </div>
        </div>
      </li>
    </ol>
  </div>
</template>

<script>
import BasketLineSizes from "@/components/BasketLineSizes";
import { slideDown, slideUp } from "@/helpers/slide";
import { mapActions } from "vuex";

export default {
  name: "BasketLineCustomers",
  components: {
    BasketLineSizes,
  },
  props: {
    basketLine: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    customers() {
      const { basketLine } = this;

      if (
        !basketLine ||
        !basketLine.customersToShopFor ||
        !basketLine.customersToShopFor.length
      ) {
        return [];
      }

      return basketLine.customersToShopFor;
    },
    productId() {
      if (!this.basketLine) return null;

      return this.basketLine.productId;
    },
    variantId() {
      if (!this.basketLine) return null;

      return this.basketLine.id;
    },
  },
  methods: {
    ...mapActions("basket", ["REMOVE_VARIANT_FROM_BASKET"]),
    close() {
      slideUp(this.$refs.content);
      this.isOpen = false;
    },
    getLink(customerId) {
      const {
        $route: {
          query: { mode },
        },
        productId,
        variantId,
      } = this;

      if (mode && mode.includes("details")) {
        return {
          query: {
            mode: "details_quick-buy",
            customerId,
            productId,
            variantId,
          },
        };
      }

      return {
        query: { mode: "quick-buy", customerId, productId, variantId },
      };
    },
    open() {
      slideDown(this.$refs.content);
      this.isOpen = true;
    },
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.customer {
  display: flex;
  justify-content: space-between;

  &__block {
    &:last-child {
      display: flex;
      justify-content: space-between;
      margin-left: 1.25rem;
    }
  }

  &__discount {
    text-align: right;
  }

  &__discount,
  &__name {
    font-size: 0.875rem;
    font-weight: 400;
    margin: 0 0 0.25rem;
  }

  &__edit {
    margin-left: var(--size-basket-icon);
  }

  &__edit,
  &__remove {
    display: inline-block;
    height: var(--size-basket-icon);
    text-decoration: none;
    width: var(--size-basket-icon);

    &-icon {
      fill: none;
      height: var(--size-basket-icon);
      opacity: 0.75;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: var(--color-text-primary);
      transition: opacity 300ms ease;
      width: var(--size-basket-icon);

      &:hover {
        opacity: 1;
      }
    }
  }

  &__remove {
    @include reset-button();

    margin-left: calc(var(--size-basket-icon) * 0.5);
  }

  &__unit-with-price {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 500;
    text-align: right;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 0.125rem;
    }
  }
}

.customers {
  &__item {
    padding-bottom: 1.5rem;

    &:first-child {
      padding-top: 0.5rem;
    }

    &:last-child {
      padding-bottom: 0.5rem;
    }
  }

  &__list {
    @include reset-list();

    display: none;
    overflow: hidden;
  }

  &__title {
    font-size: 0.9375rem;
    font-weight: 700;
    margin: 0;
  }

  &__toggle {
    @include reset-button();

    align-items: center;
    border-width: 1px 0;
    display: flex;
    padding: 0.5rem 0;
    width: 100%;

    &-icon {
      fill: none;
      height: 1.25rem;
      margin-left: 0.5rem;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: var(--color-text-primary);
      transition: transform 300ms ease;
      width: 1.25rem;

      .customers__toggle--open & {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
