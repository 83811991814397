<template>
  <ul class="employee__information">
    <template v-if="shouldUseWallet">
      <li class="employee__information-block">
        <div class="employee__information-title">
          {{ getDictionaryEntry("Common.Labels.WalletBalance") }}
        </div>
      </li>
      <li class="employee__information-block">
        <div class="employee__information-title">
          {{ formatPriceCurrency(user.walletBalance ?? 0) }} {{ user.currency }}
        </div>
      </li>
      <li class="employee__information-block">
        <div class="employee__information-title">
          {{ getDictionaryEntry("Common.Labels.WalletRemainingBalance") }}
        </div>
      </li>

      <li class="employee__information-block">
        <div
          class="employee__information-title"
          :class="{
            'wallet-error': hasWalletError,
          }"
        >
          {{ remainingBudget }}
        </div>
      </li>
    </template>

    <li class="employee__information-block">
      <div class="employee__information-title">
        {{ getDictionaryEntry("Common.Labels.TopPartSize") }}
      </div>
      <div class="employee__information-value">
        {{ user.topPartSize }}
      </div>
    </li>
    <li class="employee__information-block">
      <div class="employee__information-title">
        {{ getDictionaryEntry("Common.Labels.Waist") }}
      </div>
      <div class="employee__information-value">
        {{ user.waist }}
      </div>
    </li>
    <li class="employee__information-block">
      <div class="employee__information-title">
        {{ getDictionaryEntry("Common.Labels.TrousersSize") }}
      </div>
      <div class="employee__information-value">
        {{ user.trousersSize }}
      </div>
    </li>
    <li class="employee__information-block">
      <div class="employee__information-title">
        {{ getDictionaryEntry("Common.Labels.Inseam") }}
      </div>
      <div class="employee__information-value">
        {{ user.inseam }}
      </div>
    </li>
    <li class="employee__information-block">
      <div class="employee__information-title">
        {{ getDictionaryEntry("Common.Labels.Neck") }}
      </div>
      <div class="employee__information-value">
        {{ user.neck }}
      </div>
    </li>
    <li class="employee__information-block">
      <div class="employee__information-title">
        {{ getDictionaryEntry("Common.Labels.OtherSizes") }}
      </div>
      <div class="employee__information-value">
        {{ user.otherSize }}
      </div>
    </li>
    <li class="employee__information-block">
      <div class="employee__information-title">
        {{ getDictionaryEntry("Common.Labels.Chest") }}
      </div>
      <div class="employee__information-value">
        {{ user.chest }}
      </div>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "EmployeesInformationList",
  props: {
    user: {
      type: Object,
      required: true,
    },
    hasWalletError: {
      type: Boolean,
      required: true,
    },
    remainingBudget: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters("authentication", ["shouldUseWallet"]),
  },
};
</script>

<style lang="scss" scoped>
.employee__information {
  @include reset-list();

  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;

  &-block {
    flex: 0 0 calc(50% - 1rem);
    margin-top: 1.75rem;

    &:last-child {
      margin-bottom: 1.75rem;
    }
  }

  &-title {
    font-size: 0.8125rem;
  }

  &-value {
    color: #7a7a7a;
    font-size: 0.8125rem;
    margin-top: 0.25rem;
  }
}

.wallet-error {
  color: var(--color-error);
}
</style>
