var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.blockData)?_c('Container',[_c('div',{staticClass:"block block--cta",class:{ 'block--tall': _vm.blockData.increaseHeight }},[_c('div',{staticClass:"block__100-percent block__media-container"},[_c('div',{staticClass:"block__content block__content--center block__content--middle"},[(_vm.blockData.image)?_c('ImageFromUmbraco',{staticClass:"block__image",attrs:{"id":_vm.blockData.image._system.id,"alt":_vm.blockData.image.alternateText,"height":_vm.blockData.image.umbracoHeight,"keys-and-sizes":{
            s: 'block-regular-small',
            m: _vm.blockData.increaseHeight
              ? 'block-cta-tall-medium'
              : 'block-cta-medium',
            l: _vm.blockData.increaseHeight
              ? 'block-cta-tall-large'
              : 'block-cta-large',
            xl: _vm.blockData.increaseHeight
              ? 'block-cta-tall-extra-large'
              : 'block-cta-extra-large',
          },"width":_vm.blockData.image.umbracoWidth}}):_vm._e(),_c('article',{staticClass:"block__content-text"},[(_vm.blockData.caption)?_c('div',{staticClass:"block__subtitle"},[_vm._v(" "+_vm._s(_vm.blockData.caption)+" ")]):_vm._e(),(_vm.blockData.headline)?_c('Heading',{staticClass:"block__title",attrs:{"tag":_vm.blockData.headlineTag}},[_vm._v(" "+_vm._s(_vm.blockData.headline)+" ")]):_vm._e(),(_vm.blockData.cTA && _vm.blockData.cTA.name && _vm.blockData.cTA.url)?_c('ConditionalRouterLink',{staticClass:"block__button",attrs:{"target":_vm.blockData.cTA.target,"to":_vm.blockData.cTA.url,"type":_vm.blockData.cTA.type}},[_vm._v(_vm._s(_vm.blockData.cTA.name))]):_vm._e()],1)],1)])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }