var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"matrix"},[(_vm.hasBlurEffect && _vm.hasHorizontalScroll && !_vm.isScrolledToRight)?_c('div',{staticClass:"matrix__blur"}):_vm._e(),_c('div',{ref:"fixedCol",staticClass:"matrix__fixed-col"},[_c('div',{staticClass:"matrix__cell matrix__cell-header"},[_c('p',[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.Labels.Size"))+" ")])]),_c('div',{staticClass:"matrix__cell matrix__cell--quantity"},[_c('p',[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.Labels.Quantity"))+" ")])]),_c('div',{staticClass:"matrix__cell"},[_c('p',[_vm._v(_vm._s(_vm.getDictionaryEntry("Common.Labels.Price")))])]),(_vm.hasRetailPrice)?_c('div',{staticClass:"matrix__cell"},[_c('p',[_vm._v(" "+_vm._s(_vm.shouldShowPricesWithVat ? _vm.getDictionaryEntry("Common.Labels.ApproximatePriceWithVat") : _vm.getDictionaryEntry("Common.Labels.ApproximatePriceWithoutVat"))+" ")])]):_vm._e(),_c('div',{staticClass:"matrix__cell"},[_c('p',[_vm._v(_vm._s(_vm.getDictionaryEntry("Common.Labels.Stock")))])])]),_c('div',{ref:"scrollableElement",staticClass:"matrix__content",style:({
      marginLeft: `${_vm.fixedColSize}px`,
    }),on:{"scroll":_vm.handleScroll}},_vm._l((_vm.selectedSizeGroup?.sizes),function(size,index){return _c('div',{key:`size-${index}-${size.sku}`,staticClass:"matrix__col"},[_c('div',{staticClass:"matrix__cell matrix__cell-header matrix__cell-header--content"},[_c('p',[_vm._v(" "+_vm._s(_vm.getPreferredSizeChartValue(size))+" ")])]),_c('div',{staticClass:"matrix__cell matrix__cell--quantity"},[_c('div',{staticClass:"matrix__content__input-container"},[_c('input',{staticClass:"matrix__content__input-container--input",class:{
              'matrix__content__input-container--input--in-stock': _vm.checkInStock(
                index,
              ),
              'matrix__content__input-container--input--saving': _vm.isUpdatingBasket,
            },attrs:{"min":"0","placeholder":"0","type":"number"},domProps:{"value":size.quantity},on:{"input":function($event){return _vm.updateSizeQuantity(index, $event)}}}),(_vm.isUpdatingBasket && _vm.updatedSizeIndex === index)?_c('div',{staticClass:"matrix__content__input-container--input-loader"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"10","height":"10","viewBox":"0 0 10 10","fill":"none"}},[_c('path',{attrs:{"d":"M2.66895 2.00617C3.26026 1.59219 3.95752 1.35572 4.67868 1.3246C5.39983 1.29348 6.11488 1.46899 6.73966 1.83049C7.60097 2.32777 8.22947 3.14684 8.48688 4.10751C8.74429 5.06819 8.60953 6.09177 8.11225 6.95309C7.61497 7.8144 6.7959 8.4429 5.83523 8.70031C4.87456 8.95772 3.85097 8.82296 2.98966 8.32568C2.3642 7.96535 1.85468 7.43387 1.52105 6.79377C1.18743 6.15367 1.04358 5.43159 1.10645 4.7125","stroke":"white","stroke-linecap":"round","stroke-linejoin":"round"}})])]):_vm._e()])]),_c('div',{staticClass:"matrix__cell"},[(_vm.isPriceFromSizeWithDiscount(size))?_c('div',{staticClass:"matrix__cell__price-container"},[_c('span',{staticClass:"matrix__cell--previous-price"},[_vm._v(" "+_vm._s(_vm.getNormalPriceFromSize(size))+" ")]),_c('span',{staticClass:"matrix__cell--new-price"},[_vm._v(" "+_vm._s(_vm.getPriceFromSize(size))+" ")])]):_c('p',[_vm._v(" "+_vm._s(_vm.getPriceFromSize(size))+" ")])]),(_vm.hasRetailPrice)?_c('div',{staticClass:"matrix__cell"},[_c('p',[_vm._v(" "+_vm._s(_vm.getRetailPriceFromSize(size))+" ")])]):_vm._e(),_c('div',{staticClass:"matrix__cell matrix__cell--stock",class:{
          'matrix__cell--stock-info':
            _vm.shouldShowRestockDate(size.inventory) ||
            _vm.shouldShowMessage(size.inventory),
        }},[(size.inventory)?_c('p',[_c('StockIndicatorBar',{attrs:{"status":_vm.getStockStatus(size.inventory.level)}}),(size.inventory.showStock && size.inventory.stock > 0)?[_vm._v(" "+_vm._s(size.inventory.stock || 0)+" ")]:(_vm.shouldShowRestockDate(size.inventory))?[_vm._v(" "+_vm._s(size.inventory.restockDate)+" ")]:(_vm.shouldShowMessage(size.inventory))?[_vm._v(" "+_vm._s(_vm.getDictionaryEntry(size.inventory.stockMessage))+" ")]:_vm._e()],2):_vm._e()])])}),0)])
}
var staticRenderFns = []

export { render, staticRenderFns }