<template>
  <div
    :class="{
      'block--darken-text': blockData.darkenText,
      'block block--hero block__media-container': blockData.fullWidthImage,
      'block__media-container--overlay':
        blockData.fullWidthImage && !blockData.darkenText,
    }"
  >
    <ImageFromUmbraco
      v-if="blockData.image && blockData.fullWidthImage"
      :id="blockData.image._system.id"
      :alt="blockData.image.alternateText"
      :height="blockData.image.umbracoHeight"
      :keys-and-sizes="{
        s: 'block-regular-small',
        m: 'block-hero-wide-medium',
        l: 'block-hero-wide-large',
        xl: 'block-hero-wide-extra-large',
        xxl: 'block-hero-wide-extra-extra-large',
      }"
      :width="blockData.image.umbracoWidth"
      class="block__image"
    />
    <Container v-if="blockData">
      <div
        class="block block--hero"
        :class="{
          'block--darken-text': blockData.darkenText,
          'block__media-container': !blockData.fullWidthImage,
          'block__media-container--overlay':
            !blockData.fullWidthImage && !blockData.darkenText,
        }"
      >
        <ImageFromUmbraco
          v-if="blockData.image && !blockData.fullWidthImage"
          :id="blockData.image._system.id"
          :alt="blockData.image.alternateText"
          :height="blockData.image.umbracoHeight"
          :keys-and-sizes="{
            s: 'block-regular-small',
            m: 'block-hero-medium',
            l: 'block-hero-large',
            xl: 'block-hero-extra-large',
          }"
          :width="blockData.image.umbracoWidth"
          class="block__image"
        />
        <div class="block__100-percent">
          <div
            class="block__content block__content--limited-width"
            :class="{
              'block__content--bottom': !blockData.centerText,
              'block__content--middle': blockData.centerText,
            }"
          >
            <div>
              <div v-if="blockData.caption" class="block__keywords">
                {{ blockData.caption }}
              </div>
              <Heading
                v-if="blockData.headline"
                :tag="blockData.headlineTag"
                class="block__title"
              >
                {{ blockData.headline }}
              </Heading>
              <p v-if="blockData.description" class="block__description">
                {{ blockData.description }}
              </p>
              <ConditionalRouterLink
                v-if="blockData.cTA && blockData.cTA.name && blockData.cTA.url"
                :target="blockData.cTA.target"
                :to="blockData.cTA.url"
                :type="blockData.cTA.type"
                class="block__button"
                :class="{
                  'block__button--ghost': blockData.darkenText,
                }"
                >{{ blockData.cTA.name }}</ConditionalRouterLink
              >
            </div>
          </div>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "HeroGridElement",
  components: {
    ConditionalRouterLink,
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>
