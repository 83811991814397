import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { alertTypes } from "@/helpers/alertTypes";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  assignableUserGroupsOfCurrentWardrobe: [],
  currentUserGroup: null,
  currentUserGroupId: null,
  expandedUserGroups: [],
  isModalForCreatingUserGroupsOpen: false,
  selectedUserGroups: [],
  status: "",
  userGroupParentCandidates: [],
  userGroupsOfCurrentCustomer: [],
});

const getters = {
  assignableUserGroupsOfCurrentWardrobe: state =>
    state.assignableUserGroupsOfCurrentWardrobe,
  currentUserGroup: state => state.currentUserGroup,
  currentUserGroupId: state => state.currentUserGroupId,
  expandedUserGroups: state => state.expandedUserGroups,
  isModalForCreatingUserGroupsOpen: state =>
    state.isModalForCreatingUserGroupsOpen,
  nameOfCurrentUserGroup: state => {
    const { currentUserGroup } = state;
    if (!currentUserGroup) return null;

    return currentUserGroup.name ? currentUserGroup.name : null;
  },
  selectedUserGroups: state => state.selectedUserGroups,
  statusOfUserGroups: state => state.statusOfUserGroups,
  userGroupParentCandidates: state => state.userGroupParentCandidates,
  userGroupsOfCurrentCustomer: state => state.userGroupsOfCurrentCustomer,
};

const actions = {
  CLOSE_MODAL_FOR_CREATING_USER_GROUP({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_USER_GROUP", { value: false });
  },
  CREATE_USER_GROUP({ commit, dispatch }, payload) {
    commit("SET_USER_GROUPS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/usergroups`, {
          ...payload.userGroup,
        })
        .then(({ data }) => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_USER_GROUP");
          resolve(data);
        })
        .catch(error => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_SELECTED_USER_GROUPS({ commit, state }) {
    commit("SET_USER_GROUPS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/usergroups/delete-multiple`, {
          identifiers: state.selectedUserGroups.map(
            selectedUserGroup => selectedUserGroup.id,
          ),
        })
        .then(response => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.SUCCESS });
          commit("DESELECT_USER_GROUPS");
          resolve(response);
        })
        .catch(error => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  GET_ASSIGNABLE_USER_GROUPS_FOR_WARDROBE({ commit }, payload) {
    commit("SET_USER_GROUPS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${endpoints.ECOMMERCE}/wardrobes/${payload.wardrobeId}/assignable-usergroups`,
        )
        .then(({ data }) => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.SUCCESS });
          commit("SET_ASSIGNABLE_USER_GROUPS_OF_WARDROBE", {
            userGroups: data,
          });
          resolve(data);
        })
        .catch(error => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  GET_USER_GROUP({ commit, dispatch }, payload) {
    commit("SET_USER_GROUPS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.ECOMMERCE}/usergroups/${payload.id}`)
        .then(({ data }) => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.SUCCESS });
          commit("SET_USER_GROUP_OF_CURRENT_CUSTOMER", { userGroup: data });
          commit("SET_CURRENT_USER_GROUP", { userGroup: data });
          dispatch("CLOSE_MODAL_FOR_CREATING_USER_GROUP");
          resolve(data);
        })
        .catch(error => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_USER_GROUP_PARENT_CANDIDATES({ commit }, payload) {
    commit("SET_USER_GROUPS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${endpoints.ECOMMERCE}/usergroups/${payload.userGroupId}/usergroup-parent-candidates`,
        )
        .then(({ data }) => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.SUCCESS });
          commit("SET_USER_GROUP_PARENT_CANDIDATES", { userGroups: data });
          resolve(data);
        })
        .catch(error => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  GET_USER_GROUPS_OF_CUSTOMER({ commit }, payload) {
    commit("SET_USER_GROUPS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${endpoints.ECOMMERCE}/customers/${payload.customerId}/usergroups`,
        )
        .then(({ data }) => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.SUCCESS });
          commit("SET_USER_GROUPS_OF_CURRENT_CUSTOMER", { userGroups: data });
          resolve(data);
        })
        .catch(error => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  OPEN_MODAL_FOR_CREATING_USER_GROUP({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_USER_GROUP", { value: true });
  },
  RESET_EXPANDED_USER_GROUPS({ commit }) {
    commit("SET_EXPANDED_USER_GROUPS", { userGroups: [] });
  },
  TOGGLE_USER_GROUP({ commit }, payload) {
    commit("SET_SELECTED_USER_GROUP", payload);
  },
  TOGGLE_USER_GROUP_CHILDREN({ commit }, payload) {
    commit("SET_EXPANDED_USER_GROUP", payload);
  },
  TOGGLE_USER_GROUPS({ commit }, payload) {
    commit("SET_SELECTED_USER_GROUPS", payload);
  },
  UPDATE_CURRENT_USER_GROUP_ID({ commit }, payload) {
    commit("SET_CURRENT_USER_GROUP_ID", payload);
    commit("SET_SELECTED_USER_GROUPS");
  },
  UPDATE_USER_GROUP({ commit, dispatch }, payload) {
    commit("SET_USER_GROUPS_STATUS", { status: statuses.SAVING });
    const { id, userGroup } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/usergroups/${id}`, {
          ...userGroup,
          parentId: Array.isArray(userGroup.parentId)
            ? null
            : userGroup.parentId,
        })
        .then(({ data }) => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.SUCCESS });
          commit("SET_USER_GROUP_OF_CURRENT_CUSTOMER", { userGroup: data });
          commit("SET_CURRENT_USER_GROUP", { userGroup: data });
          dispatch(
            "alerts/OPEN_ALERT",
            {
              key: "Common.YourChangesWereSaved",
              type: alertTypes.SUCCESS,
            },
            {
              root: true,
            },
          );
          resolve(data);
        })
        .catch(error => {
          commit("SET_USER_GROUPS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  DESELECT_USER_GROUPS(state) {
    state.userGroupsOfCurrentCustomer = state.userGroupsOfCurrentCustomer.filter(
      userGroup =>
        !state.selectedUserGroups.some(entry => entry.id === userGroup.id),
    );

    state.selectedUserGroups = [];
  },
  SET_ASSIGNABLE_USER_GROUPS_OF_WARDROBE(state, payload) {
    state.assignableUserGroupsOfCurrentWardrobe = payload.userGroups;
  },
  SET_CURRENT_USER_GROUP(state, payload) {
    state.currentUserGroup = payload.userGroup;
  },
  SET_CURRENT_USER_GROUP_ID(state, payload) {
    state.currentUserGroupId = payload.id;
  },
  SET_EXPANDED_USER_GROUP(state, payload) {
    const { row: userGroup, value } = payload;

    if (value) {
      if (state.expandedUserGroups.some(entry => entry.id === userGroup.id)) {
        return;
      }

      state.expandedUserGroups.push(userGroup);
    } else {
      state.expandedUserGroups = state.expandedUserGroups.filter(
        expandedUserGroup => expandedUserGroup.id !== userGroup.id,
      );
    }
  },
  SET_EXPANDED_USER_GROUPS(state, payload) {
    state.expandedUserGroups = payload.userGroups;
  },
  SET_SELECTED_USER_GROUP(state, payload) {
    const { row: userGroup, value } = payload;

    if (value) {
      if (state.selectedUserGroups.some(entry => entry.id === userGroup.id)) {
        return;
      }

      state.selectedUserGroups.push(userGroup);
    } else {
      state.selectedUserGroups = state.selectedUserGroups.filter(
        selectedUserGroup => selectedUserGroup.id !== userGroup.id,
      );
    }
  },
  SET_SELECTED_USER_GROUPS(state, payload) {
    if (!payload) {
      state.selectedUserGroups = [];
      return;
    }

    const { rows: userGroups, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedUserGroups.map(selectedUserGroup => selectedUserGroup.id),
      );

      state.selectedUserGroups = [
        ...state.selectedUserGroups,
        ...userGroups
          .filter(userGroup => !userGroup.disableSelection)
          .filter(userGroup => !identifiers.has(userGroup.id)),
      ];
    } else {
      state.selectedUserGroups = state.selectedUserGroups.filter(
        selectedUserGroup =>
          !userGroups.some(entry => entry.id === selectedUserGroup.id),
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_CREATING_USER_GROUP(state, payload) {
    state.isModalForCreatingUserGroupsOpen = payload.value;
  },
  SET_USER_GROUP_OF_CURRENT_CUSTOMER(state, payload) {
    const { userGroup } = payload;

    const userGroupExists = state.userGroupsOfCurrentCustomer.find(
      currentUser => currentUser.id === userGroup.id,
    );

    if (userGroupExists) {
      const userGroups = [...state.userGroupsOfCurrentCustomer];
      const indexOfMatchingUser = userGroups.findIndex(
        currentUser => currentUser.id === userGroup.id,
      );

      userGroups[indexOfMatchingUser] = userGroup;

      state.userGroupsOfCurrentCustomer = userGroups;
    } else {
      state.userGroupsOfCurrentCustomer.push(userGroup);
    }
  },
  SET_USER_GROUP_PARENT_CANDIDATES(state, payload) {
    state.userGroupParentCandidates = payload.userGroups;
  },
  SET_USER_GROUPS_OF_CURRENT_CUSTOMER(state, payload) {
    state.userGroupsOfCurrentCustomer = payload.userGroups;
  },
  SET_USER_GROUPS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
