<template>
  <div class="price-summary">
    <div class="price-summary__box">
      <Stack v-if="subtotal" :quarter="true">
        <h2 class="price-summary__title price-summary__title--subtotal">
          <span
            >{{ getDictionaryEntry("Common.Labels.PriceExcludingVat") }}:</span
          >
          <span>
            {{ formatPriceCurrency(subtotal) }}
            {{ currency }}
          </span>
        </h2>
      </Stack>
      <Stack v-if="discount" :quarter="true">
        <h2 class="price-summary__title price-summary__title--discount">
          <span v-if="showDiscountPercentage">
            {{
              interpolateString(
                getDictionaryEntry("Common.Labels.DiscountWithPercentage"),
                ["percentage"],
                [getPercentageDiscount(discount, subtotal)],
              )
            }}:
          </span>
          <span v-else>
            {{ getDictionaryEntry("Common.Labels.TotalDiscount") }}:
          </span>
          <span>
            -{{ formatPriceCurrency(discount) }}
            {{ currency }}
          </span>
        </h2>
      </Stack>
      <Stack :half="true">
        <h2 class="price-summary__title">
          <span
            >{{ getDictionaryEntry("Common.Labels.TotalExcludingVat") }}:</span
          >
          <span>
            {{ formatPriceCurrency(total) }}
            {{ currency }}
          </span>
        </h2>
      </Stack>
    </div>
  </div>
</template>

<script>
import Stack from "@/components/Stack";

export default {
  name: "PriceSummary",
  components: {
    Stack,
  },
  props: {
    currency: {
      required: true,
      type: String,
    },
    discount: {
      default: 0,
      type: [Number, String],
    },
    quantity: {
      required: true,
      type: Number,
    },
    showDiscountPercentage: {
      default: false,
      type: Boolean,
    },
    subtotal: {
      default: 0,
      type: [Number, String],
    },
    total: {
      required: true,
      type: [Number, String],
    },
  },
  methods: {
    getPercentageDiscount(discount, subtotal) {
      if (!discount || !subtotal) return 0;

      return Math.round((parseFloat(discount) / parseFloat(subtotal)) * 100);
    },
  },
};
</script>

<style lang="scss" scoped>
.price-summary {
  display: flex;
  flex: 0 0 100%;
  justify-content: flex-end;

  &__box {
    background-color: var(--color-price-summary-background);
    padding: var(--spacing-modal);

    @media (min-width: 48rem) {
      padding: var(--spacing-modal-large);
    }
  }

  &__title {
    display: flex;
    font-size: 1rem;
    font-weight: 700;
    justify-content: space-between;
    margin: 0;

    @media print {
      font-size: 0.875rem;
    }

    &--discount {
      span:last-child {
        color: var(--color-error);
      }
    }

    &--discount,
    &--subtotal {
      font-size: 0.875rem;
      font-weight: 500;

      @media print {
        font-size: 0.8125rem;
        font-weight: 400;
      }
    }

    span:first-child {
      margin-right: var(--spacing-modal);

      @media (min-width: 48rem) {
        margin-right: var(--spacing-modal-large);
      }
    }
  }
}
</style>
