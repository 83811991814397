<template>
  <ol class="navigation__list">
    <li
      v-for="(item, index) in internalItems"
      :key="item.pageId"
      class="navigation__item"
      :style="{ '--index': index }"
    >
      <div class="navigation__link-container">
        <router-link :to="item.url" class="navigation__link"
          >{{ item.name }}
        </router-link>
        <button
          v-if="item.items.length"
          class="navigation__link-toggle"
          :aria-label="
            item.isExpanded
              ? getDictionaryEntry('Common.Collapse')
              : getDictionaryEntry('Common.Expand')
          "
          @click="toggle(item)"
        >
          <svg
            class="navigation__link-toggle-icon"
            :class="{ 'navigation__link-toggle-icon--active': item.isExpanded }"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="m6 9 6 6 6-6" />
          </svg>
        </button>
      </div>
      <div ref="listContainers" class="navigation__list-container">
        <ul
          v-if="item.items.length"
          class="navigation__list navigation__list--sub"
        >
          <li
            v-for="child in item.items"
            :key="child.pageId"
            class="navigation__item"
          >
            <router-link :to="child.url" class="navigation__link">{{
              child.name
            }}</router-link>
          </li>
        </ul>
      </div>
    </li>
  </ol>
</template>

<script>
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  name: "MobileNavigation",
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      internalItems: [],
    };
  },
  watch: {
    items(newValue) {
      this.internalItems = this.addToggleStateToNavigationItems(newValue);
    },
  },
  mounted() {
    this.internalItems = this.addToggleStateToNavigationItems(this.items);
  },
  methods: {
    addToggleStateToNavigationItems(navigationItems) {
      if (!navigationItems || !navigationItems.length) return [];

      const {
        $nextTick,
        $refs,
        $route: { path },
      } = this;

      return navigationItems.map(item => {
        const isActive = item.url === path;
        const hasActiveChildren = item.items.some(child => child.url === path);

        if (isActive || hasActiveChildren) {
          const indexOfMatchingItem = navigationItems.findIndex(
            navigationItem => navigationItem.pageId === item.pageId,
          );

          if (indexOfMatchingItem === -1) return;

          $nextTick(() => {
            const contentElement = $refs.listContainers[indexOfMatchingItem];
            slideDown(contentElement);
          });
        }

        return {
          ...item,
          isExpanded: isActive || hasActiveChildren,
        };
      });
    },
    toggle({ isExpanded, pageId }) {
      const items = [...this.items];

      const indexOfMatchingItem = items.findIndex(
        item => item.pageId === pageId,
      );

      if (indexOfMatchingItem === -1) return;

      items[indexOfMatchingItem].isExpanded = !isExpanded;
      this.internalItems = items;

      const contentElement = this.$refs.listContainers[indexOfMatchingItem];

      if (!isExpanded) {
        slideDown(contentElement);
      } else {
        slideUp(contentElement);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  &__item {
    display: block;
  }

  &__link {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 0.9375rem;
    padding: calc(var(--spacing-sidebar) * 0.5) var(--spacing-sidebar);
    text-decoration: none;

    &.router-link-active {
      font-weight: 500;
      text-decoration: underline;
    }

    .navigation__list--sub & {
      &.router-link-active {
        text-decoration: underline;
      }
    }

    &-container {
      align-items: center;
      display: flex;
      justify-content: space-between;
    }

    &-toggle {
      @include reset-button();

      display: flex;
      flex-basis: calc(var(--size-mobile-navigation-icon) * 2);
      flex-grow: 0;
      flex-shrink: 0;
      justify-content: flex-end;

      &-icon {
        fill: none;
        height: var(--size-mobile-navigation-icon);
        stroke-linecap: round;
        stroke-linejoin: round;
        stroke-width: 2;
        stroke: var(--color-text-primary);
        transition: transform 300ms ease;
        width: var(--size-mobile-navigation-icon);

        &--active {
          transform: rotate(180deg);
        }
      }
    }
  }

  &__list {
    @include reset-list;

    padding-top: calc(var(--spacing-sidebar) * 0.5);

    &--sub {
      padding: 0 0 0 1rem;
    }

    &-container {
      display: none;
      overflow: hidden;
    }
  }
}
</style>
