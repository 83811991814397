<template>
  <div>
    <button class="sizes__toggle" @click="toggleSizes">
      <StockIndicator
        :ignore-show-stock="true"
        :indicator-only="true"
        :inventory="temporaryBasket[user.userId].size.inventory"
        class="indicator"
      />
      <span class="sizes__label">
        {{ getDictionaryEntry("Common.Labels.SizeAbbreviation") }}
      </span>
      <span
        v-if="getPreferredSizeChartValue(temporaryBasket[user.userId].size)"
        class="sizes__label"
      >
        {{ getPreferredSizeChartValue(temporaryBasket[user.userId].size) }}
      </span>
      <span v-if="shouldShowRestockDate" class="sizes__date">
        {{
          temporaryBasket[user.userId]?.size?.inventory?.restockDate
            ? `(${temporaryBasket[user.userId].size.inventory.restockDate})`
            : ""
        }}
      </span>

      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="sizes__icon"
        :class="{
          'sizes__icon--active': isSizesOpen && activeUser === user.userId,
        }"
        viewBox="0 0 24 24"
      >
        <path d="m6 9 6 6 6-6" />
      </svg>
    </button>
  </div>
</template>
<script>
import StockIndicator from "@/components/StockIndicator.vue";

export default {
  name: "ChangeSizeToggle",
  components: { StockIndicator },
  props: {
    activeUser: {
      required: true,
      type: String,
    },
    hasRestockDates: {
      required: true,
      type: Boolean,
    },
    isSizesOpen: {
      required: true,
      type: Boolean,
    },
    temporaryBasket: {
      required: true,
      type: Object,
    },
    toggleSizes: {
      required: true,
      type: Function,
    },
    user: {
      required: true,
      type: Object,
    },
  },
  computed: {
    shouldShowRestockDate() {
      return (
        (this.hasRestockDates && this.isSizesOpen) ||
        (!this.isSizesOpen &&
          this.temporaryBasket[this.user.userId].size.inventory.restockDate &&
          this.temporaryBasket[this.user.userId].size.inventory.level === "red")
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.sizes {
  &__toggle {
    @include reset-button();

    & {
      align-items: center;
      background-color: #ffffff;
      border-radius: 0.25rem;
      border: 1px solid #dfdfdf;
      display: flex;
      padding: 0.5rem;
    }
  }

  &__label {
    display: block;
    margin-right: 0.5rem;
  }

  &__date {
    min-width: 3.125rem;
  }

  &__icon {
    fill: none;
    height: var(--size-dropdown-icon);
    margin-left: 0.75rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: #b6b6b6;
    transition: transform 300ms ease;
    width: var(--size-dropdown-icon);

    &--active {
      transform: rotate(180deg);
    }
  }
}

.indicator {
  margin-right: 0.5rem;
}
</style>
