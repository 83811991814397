<template>
  <nav class="navigation">
    <ul class="navigation__list">
      <li v-for="item in items" :key="item.pageId" class="navigation__item">
        <div v-if="item.items.length">
          <span class="navigation__link navigation__link--has-children">
            {{ item.name }}
            <svg
              class="navigation__link-icon"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <defs />
              <path d="M6 9l6 6 6-6" />
            </svg>
          </span>
          <div class="navigation__tooltip">
            <ul class="navigation__list navigation__list--sub">
              <li
                v-for="child in item.items"
                :key="child.pageId"
                class="navigation__item"
              >
                <router-link
                  v-if="isInternalLink(child.url)"
                  :to="child.url"
                  class="navigation__link"
                  >{{ child.name }}</router-link
                >
                <a
                  v-else
                  :href="child.url"
                  :rel="child.target === '_blank' ? 'noreferrer' : null"
                  :target="child.target"
                  class="navigation__link"
                  >{{ child.name }}</a
                >
              </li>
            </ul>
          </div>
        </div>
        <div v-else>
          <router-link
            v-if="isInternalLink(item.url)"
            :to="item.url"
            class="navigation__link"
            >{{ item.name }}
          </router-link>
          <a
            v-else
            :href="item.url"
            :rel="item.target === '_blank' ? 'noreferrer' : null"
            :target="item.target"
            class="navigation__link"
            >{{ item.name }}</a
          >
        </div>
      </li>
      <li v-if="hasAccessToOrderManagement" class="navigation__item">
        <router-link :to="{ name: 'orders' }" class="navigation__link">{{
          getDictionaryEntry("Navigation.Orders")
        }}</router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    items: {
      default: () => [],
      type: Array,
    },
  },
  methods: {
    isInternalLink(url) {
      return url.startsWith("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.navigation {
  display: none;

  @media (min-width: 64rem) {
    display: block;
  }

  &__item {
    margin-right: 1.5rem;
    position: relative;

    @media (min-width: 80rem) {
      margin-right: 2.5rem;
    }

    &:hover {
      .navigation__tooltip {
        opacity: 1 !important;
        pointer-events: auto !important;
        transform: translate(-50%, 100%) !important;
        visibility: visible !important;
      }
    }

    .navigation__list--sub & {
      margin: 0;

      &:not(:last-child) {
        margin-bottom: 0.5rem;
      }
    }
  }

  &__link {
    align-items: center;
    color: inherit;
    display: flex;
    font-size: 0.875rem;
    padding: calc(0.25rem + 1px) 0 0.25rem;
    position: relative;
    text-decoration: none;

    &:hover,
    &.router-link-active {
      &::after {
        transform: scale3d(1, 1, 1);
      }
    }

    &::after {
      background-color: var(--theme-primary);
      bottom: 0;
      content: "";
      height: 2px;
      left: 0;
      position: absolute;
      right: 0;
      transform: scale3d(0, 1, 1);
      transition: transform 300ms ease;
    }

    .navigation__item:first-child & {
      padding-left: 0;

      &::after {
        left: 0;
      }
    }

    .navigation__list--sub & {
      &.router-link-active {
        font-weight: 700;
      }

      &::after {
        display: none;
      }
    }

    &--has-children {
      &:hover {
        &::after {
          content: none;
        }
      }
    }

    &-icon {
      fill: none;
      height: var(--size-header-link-icon);
      margin-left: calc(var(--size-header-link-icon) * 0.25);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: currentColor;
      transition: transform 300ms ease;
      width: var(--size-header-link-icon);
    }
  }

  &__list {
    @include reset-list;

    align-items: center;
    display: flex;

    &--sub {
      background-color: var(--color-navigation-dropdown-background);
      border-radius: 0.25rem;
      box-shadow: var(--elevation-8);
      display: block;
      max-width: 20rem;
      min-width: 12.5rem;
      padding: 0.75rem;
      position: relative;

      &::before {
        border-bottom: 0.5rem solid var(--color-navigation-dropdown-background);
        border-left: 0.5rem solid transparent;
        border-right: 0.5rem solid transparent;
        content: "";
        height: 0;
        left: 50%;
        position: absolute;
        top: 0;
        transform: translate(-50%, -100%) translateY(0.0625rem);
        width: 0;
        z-index: 1;
      }
    }
  }

  &__tooltip {
    bottom: 0;
    left: 50%;
    opacity: 0;
    padding-top: 0.5rem;
    pointer-events: none;
    position: absolute;
    transform: translate(-50%, 100%) translateY(1rem);
    transform-origin: top center;
    transition: opacity 300ms ease, transform 300ms ease, visibility 300ms ease;
    visibility: hidden;
    z-index: 2;
  }
}
</style>
