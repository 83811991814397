<template>
  <section
    v-if="customerService || currentPrimaryCustomerSalesperson"
    class="help"
    :class="{
      'help--size-selector': shouldAccountForSizeSelector,
    }"
  >
    <transition mode="out-in" name="fade-slide-from-bottom">
      <button v-if="!isOpen" class="help__trigger" @click="open">
        {{ getDictionaryEntry("SalesRepresentative.NeedHelp") }}
      </button>
      <Card v-else class="help__box">
        <button class="help__close" @click="close">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="help__icon help__icon--close"
            viewBox="0 0 24 24"
          >
            <path d="M18 6 6 18M6 6l12 12" />
          </svg>
        </button>
        <div class="help__box-content">
          <div v-if="customerService" class="help__block">
            <Stack v-if="customerService.title" :quarter="true">
              <div class="help__caption">
                {{ customerService.title }}
              </div>
            </Stack>
            <Stack v-if="customerService.name" :quarter="true">
              <h1 class="help__title">
                {{ customerService.name }}
              </h1>
            </Stack>
            <Stack
              v-if="customerService.phone || customerService.email"
              :quarter="true"
            >
              <div v-if="customerService.phone">
                {{ getDictionaryEntry("Common.Labels.Phone") }}:
                {{ customerService.phone }}
              </div>
              <div v-if="customerService.email">
                {{ getDictionaryEntry("Common.Labels.Email") }}:
                <a
                  :href="`mailto:${customerService.email.replace(/\s+/g, '')}`"
                  class="help__link"
                  >{{ customerService.email }}</a
                >
              </div>
            </Stack>
            <Stack v-if="customerService.text" :half="true">
              <p class="help__body-text">
                {{ customerService.text }}
              </p>
            </Stack>
          </div>
          <div
            v-if="currentPrimaryCustomerSalesperson"
            class="help__block help__block--sales-representative"
          >
            <div
              v-if="currentPrimaryCustomerSalesperson.photo"
              class="help__block-image"
            >
              <div class="help__image-container">
                <ImageFromUmbraco
                  :id="currentPrimaryCustomerSalesperson.photo"
                  class="help__image"
                />
              </div>
            </div>
            <div class="help__block-text">
              <Stack
                v-if="currentPrimaryCustomerSalesperson.title"
                :quarter="true"
              >
                <div class="help__caption">
                  {{ getDictionaryEntry("SalesRepresentative.Contact") }}
                  {{ currentPrimaryCustomerSalesperson.title }}
                </div>
              </Stack>
              <Stack
                v-if="currentPrimaryCustomerSalesperson.name"
                :quarter="true"
              >
                <h1 class="help__title">
                  {{ currentPrimaryCustomerSalesperson.name }}
                </h1>
              </Stack>
              <Stack
                v-if="
                  currentPrimaryCustomerSalesperson.phone ||
                    currentPrimaryCustomerSalesperson.email
                "
                :half="true"
              >
                <Stack
                  v-if="currentPrimaryCustomerSalesperson.phone"
                  :quarter="true"
                  class="help__contact"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="help__icon"
                    viewBox="0 0 24 24"
                  >
                    <path
                      d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"
                    />
                  </svg>
                  {{ currentPrimaryCustomerSalesperson.phone }}
                </Stack>
                <Stack
                  v-if="currentPrimaryCustomerSalesperson.email"
                  :quarter="true"
                >
                  <a
                    :href="
                      `mailto:${currentPrimaryCustomerSalesperson.email.replace(
                        /\s+/g,
                        '',
                      )}`
                    "
                    class="help__link help__contact"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="help__icon"
                      viewBox="0 0 24 24"
                    >
                      <path
                        d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z"
                      />
                      <path d="m22 6-10 7L2 6" />
                    </svg>
                    <span class="help__link-text">
                      {{ currentPrimaryCustomerSalesperson.email }}</span
                    ></a
                  >
                </Stack>
              </Stack>
            </div>
          </div>
          <div
            v-if="currentPrimaryCustomerSalesperson"
            class="help__block"
          ></div>
        </div>
      </Card>
    </transition>
    <transition name="fade">
      <div
        v-if="isOpen"
        class="sales-representative__underlay"
        @click="close"
      />
    </transition>
  </section>
</template>

<script>
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";
import Card from "@/components/Card";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";
import Stack from "@/components/Stack";

export default {
  name: "Help",
  components: {
    Card,
    ImageFromUmbraco,
    Stack,
  },
  data() {
    return {
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters("authentication", ["userConfiguration"]),
    ...mapGetters("content", ["customerService"]),
    ...mapGetters("customers", [
      "currentPrimaryCustomerSalesperson",
      "statusOfCustomers",
    ]),
    shouldAccountForSizeSelector() {
      const {
        $route: { query },
      } = this;

      if (!query || !query.mode) {
        return false;
      }

      return query.mode.includes("details") && query.mode.includes("quick-buy");
    },
  },
  watch: {
    userConfiguration(newValue, oldValue) {
      if (
        newValue &&
        newValue.contextInternalCustomerId &&
        !isEqual(newValue, oldValue)
      ) {
        this.GET_SALESPERSONS_OF_CUSTOMER({
          customerId: newValue.contextInternalCustomerId,
        });
      }
    },
  },
  created() {
    const { GET_SALESPERSONS_OF_CUSTOMER, userConfiguration } = this;

    if (userConfiguration && userConfiguration.contextInternalCustomerId) {
      GET_SALESPERSONS_OF_CUSTOMER({
        customerId: userConfiguration.contextInternalCustomerId,
      });
    }
  },
  methods: {
    ...mapActions("customers", ["GET_SALESPERSONS_OF_CUSTOMER"]),
    close() {
      this.isOpen = false;
    },
    open() {
      this.isOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.help {
  bottom: var(--spacing-container);
  color: #000000;
  font-size: 0.9375rem;
  position: fixed;
  right: var(--spacing-container);
  z-index: 94;

  @media (min-width: 48rem) {
    bottom: calc(var(--spacing-container) * 2);
    right: calc(var(--spacing-container) * 2);
  }

  @media print {
    display: none;
  }

  &--size-selector {
    display: none;

    @media (min-width: 48rem) {
      display: block;
    }
  }

  .navigation-sidebar & {
    bottom: auto;
    right: var(--spacing-container);
    top: var(--spacing-container);

    @media (min-width: 48rem) {
      bottom: auto;
      right: var(--spacing-container);
      top: var(--spacing-container);
    }
  }

  &__block {
    &:not(:first-child) {
      border-top: 1px solid rgba(19, 24, 35, 0.15);
      margin-top: var(--spacing-stack);
      padding-top: var(--spacing-stack);
    }

    &--sales-representative {
      @media (min-width: 48rem) {
        align-items: center;
        display: flex;
      }
    }

    &-image {
      display: flex;
      justify-content: center;
      margin-bottom: var(--spacing-stack);

      @media (min-width: 48rem) {
        margin: 0 var(--spacing-stack) 0 0;
      }
    }
  }

  &__body-text {
    margin: 1rem 0 0;
  }

  &__box {
    margin-right: var(--spacing-container);
    max-width: 30rem;
    width: calc(100vw - (var(--spacing-container) * 4));
    z-index: 3;

    @media (min-width: 48rem) {
      margin: 0;
    }

    .navigation-sidebar & {
      bottom: calc(var(--spacing-container) * -1);

      @media (min-width: 48rem) {
        bottom: calc(var(--spacing-container) * -1.5);
        right: calc(var(--spacing-container) * 1.5);
      }
    }

    &-content {
      padding: 1rem;
    }
  }

  &__box,
  &__trigger {
    bottom: 0;
    position: absolute;
    right: 0;

    .navigation-sidebar & {
      transform: translateY(100%);
    }
  }

  &__caption {
    opacity: 0.5;
  }

  &__close {
    @include reset-button();

    align-items: center;
    background-color: var(--theme-primary);
    border-radius: 50%;
    display: flex;
    height: calc(var(--size-help-icon) * 2.8);
    justify-content: center;
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(10%, -50%);
    width: calc(var(--size-help-icon) * 2.8);
  }

  &__contact {
    align-items: center;
    display: flex;
  }

  &__icon {
    fill-rule: evenodd;
    fill: none;
    height: var(--size-help-icon);
    margin-right: calc(var(--size-help-icon) * 0.5);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--theme-primary);
    width: var(--size-help-icon);

    &--close {
      height: calc(var(--size-help-icon) * 1.1);
      margin: 0;
      stroke: var(--color-button-text);
      width: calc(var(--size-help-icon) * 1.1);
    }
  }

  &__image {
    display: block;
    height: 100%;
    left: 50%;
    object-fit: cover;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &-block {
      display: flex;
      justify-content: center;
    }

    &-container {
      border-radius: 50%;
      box-shadow: var(--elevation-64);
      flex: 0 0 var(--size-help-image);
      height: var(--size-help-image);
      overflow: hidden;
      position: relative;
      width: var(--size-help-image);

      @media (min-width: 48rem) {
        flex: 0 0 var(--size-help-image-large);
        height: var(--size-help-image-large);
        width: var(--size-help-image-large);
      }
    }
  }

  &__link {
    color: var(--theme-primary);
    text-decoration: none;
  }

  &__title {
    font-family: var(--font-family-inter);
    font-size: 1rem;
    font-weight: 900;
    margin: 0;

    @media (min-width: 48rem) {
      font-size: 1.375rem;
    }
  }

  &__trigger {
    @include reset-button();

    background-color: var(--theme-primary);
    border-radius: 3rem;
    color: var(--color-button-text);
    font-size: 0.875rem;
    font-weight: 600;
    padding: 1rem 1.5rem;
    white-space: nowrap;
    z-index: 2;
  }

  &__underlay {
    background-color: rgba(0, 0, 0, 0.15);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
