export default {
  methods: {
    interpolateString(
      string,
      wordsToReplace,
      replacements,
      highlightReplacedWords,
    ) {
      if (!string || typeof string !== "string") return "";

      if (
        !wordsToReplace ||
        !wordsToReplace.length ||
        !replacements ||
        !replacements.length
      ) {
        return string;
      }

      let interpolatedString = string;

      wordsToReplace.forEach((word, index) => {
        interpolatedString = interpolatedString.replace(
          new RegExp("{" + wordsToReplace[index] + "}", "gi"),
          highlightReplacedWords
            ? `<strong>${replacements[index]}</strong>`
            : replacements[index],
        );
      });

      return interpolatedString;
    },
  },
};
