<template>
  <div
    v-if="allowedDivisionBrands && allowedDivisionBrands.length > 1"
    :class="{ 'division-selector--open': isSelectorOpen }"
    class="division-selector"
  >
    <button class="division-selector__trigger" @click="toggleSelector">
      {{ getDictionaryEntry("Common.Labels.ChangeDivision") }}
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="division-selector__icon"
        viewBox="0 0 24 24"
      >
        <path d="m6 9 6 6 6-6" />
      </svg>
    </button>
    <ol ref="divisions" class="division-selector__list">
      <li
        v-for="brand in allowedDivisionBrands"
        :key="brand.id"
        :class="{ 'division--current': isCurrentBrand(brand.id) }"
        class="division"
      >
        <button class="division__link" @click="goToDivision(brand)">
          <img
            :src="brand.logo"
            :alt="`${brand.name} logo`"
            class="division__image"
            decoding="async"
            loading="lazy"
            @error="handleDivisionLogoError($event, brand)"
          />
          <transition name="fade">
            <svg
              v-if="isCurrentBrand(brand.id)"
              xmlns="http://www.w3.org/2000/svg"
              class="division__icon"
              viewBox="0 0 24 24"
            >
              <path d="M20 6 9 17l-5-5" />
            </svg>
          </transition>
        </button>
      </li>
    </ol>
    <transition name="fade">
      <div
        v-if="isSelectorOpen"
        class="division-selector__underlay"
        @click="closeSelector"
      ></div>
    </transition>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { environmentVariables } from "@/helpers/environmentVariables";
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  name: "DivisionSelector",
  data() {
    return {
      isSelectorOpen: false,
    };
  },
  methods: {
    ...mapActions("catalogues", ["REMOVE_ALL_VARIANTS_FROM_CURRENT_CATALOGUE"]),
    closeSelector() {
      if (!this.isSelectorOpen) return;

      slideUp(this.$refs.divisions);

      this.isSelectorOpen = false;
    },
    getUrl(brand) {
      if (!brand) return null;
      if (!brand.hostNames || !brand.hostNames.length) return null;

      const suitableProtocolForEnvironment =
        environmentVariables.environment === "local" ? "http" : "https";

      return `${suitableProtocolForEnvironment}://${brand.hostNames[0]}`;
    },
    goToDivision(brand) {
      if (!brand) return;

      const url = this.getUrl(brand);

      if (!url) return;

      this.REMOVE_ALL_VARIANTS_FROM_CURRENT_CATALOGUE().then(() => {
        window.location.href = url;
      });
    },
    isCurrentBrand(id) {
      const { brand } = this;

      if (!id || !brand || !brand.id) return false;

      return id === brand.id;
    },
    openSelector() {
      if (this.isSelectorOpen) return;

      slideDown(this.$refs.divisions);

      this.isSelectorOpen = true;
    },
    toggleSelector() {
      if (this.isSelectorOpen) {
        this.closeSelector();
      } else {
        this.openSelector();
      }
    },
    handleDivisionLogoError(e, brand) {
      const { name } = brand;
      const parentNode = e.target.parentNode;
      e.target.style.display = "none";

      const span = document.createElement("span");
      span.innerHTML = name;
      parentNode.prepend(span);
    },
  },
};
</script>

<style lang="scss" scoped>
.division {
  &:not(:last-child) {
    border-bottom: 1px solid #ebebeb;
  }

  &__icon {
    fill: none;
    flex: 0 0 auto;
    height: var(--size-dropdown-icon);
    margin-left: 2rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-success);
    width: var(--size-dropdown-icon);
  }

  &__image {
    height: 100%;
    max-width: 5.625rem;
    width: 100%;
  }

  &__link {
    @include reset-button();

    align-items: center;
    display: flex;
    height: 3.5rem;
    justify-content: space-between;
    padding: 0.5rem var(--spacing-container);

    .division--current > & > :first-child {
      opacity: 0.3;
    }
  }
}

.division-selector {
  position: relative;

  &__icon {
    fill: none;
    height: var(--size-dropdown-icon);
    margin-left: 0.5rem;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-text-primary);
    transition: transform 300ms ease;
    width: var(--size-dropdown-icon);

    .division-selector--open & {
      transform: rotate(180deg);
    }
  }

  &__list {
    @include reset-list();

    background-color: #ffffff;
    box-shadow: var(--elevation-8);
    display: none;
    margin-left: calc(var(--spacing-container) * -1);
    overflow: hidden;
    position: absolute;
    width: 100vw;
    z-index: 3;

    @media (min-width: 64rem) {
      max-width: 17.5rem;
    }
  }

  &__trigger {
    @include reset-button();

    align-items: center;
    display: flex;
    font-size: 0.875rem;
    font-weight: 700;
    height: 100%;
    line-height: 1.45;
    min-height: calc(var(--size-service-bar) * 1.25);
    width: 100%;

    @media (min-width: 64rem) {
      height: var(--size-service-bar);
      min-height: auto;
    }
  }

  &__underlay {
    background-color: rgba(0, 0, 0, 0.75);
    bottom: 0;
    cursor: pointer;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: -1;

    @media (min-width: 64rem) {
      background-color: transparent;
      z-index: 2;
    }
  }
}
</style>
