<template>
  <div>
    <div class="service-bar">
      <Container>
        <div class="service-bar__blocks">
          <div class="service-bar__block">
            <DivisionSelector class="service-bar__divisions" />
            <CurrentBalance
              v-if="!isShopToState"
              :link="true"
              class="service-bar__balance service-bar__balance--mobile-only"
            />
          </div>
          <div class="service-bar__block">
            <ShopToGroupSelector
              v-if="isShopToStateAllowed"
              class="service-bar__toggle"
            />
            <ToggleSwitch
              v-if="preOrderStateAllowed"
              ref="preOrderSwitch"
              class="service-bar__toggle"
              :label="getDictionaryEntry('Common.Labels.PreOrders')"
              :value="isPreOrderState"
              @input="togglePreOrders"
            />
            <CurrentBalance
              v-if="!isShopToState"
              :link="true"
              class="service-bar__balance service-bar__balance--desktop-only"
            />
          </div>
        </div>
      </Container>
    </div>
    <Modal
      v-if="isPreOrdersModalOpen"
      :title="getDictionaryEntry('PreOrder.EmptyBasketModal.Title')"
      :visible="isPreOrdersModalOpen"
      class="checkout__error-modal"
      @close="closePreOrdersModal"
    >
      <template #body>
        <div class="block__rich-text">
          <p>
            {{ getDictionaryEntry("PreOrder.EmptyBasketModal.Description") }}
          </p>
        </div>
      </template>
      <template #footer>
        <Button
          :limited-width="true"
          :secondary="true"
          @click="closePreOrdersModal"
        >
          {{ getDictionaryEntry("Common.Cancel") }}
        </Button>
        <Button
          :limited-width="true"
          :show-loader="isUpdatingState"
          @click="emptyBasketAndEnablePreOrders"
          >{{
            getDictionaryEntry("PreOrder.EmptyBasketModal.ContinueButton")
          }}</Button
        >
      </template>
    </Modal>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import Container from "@/components/Container";
import CurrentBalance from "@/components/CurrentBalance";
import DivisionSelector from "@/components/DivisionSelector";
import Modal from "@/components/Modal";
import ShopToGroupSelector from "@/components/ShopToGroupSelector";
import ToggleSwitch from "@/components/ToggleSwitch";
import { statuses } from "@/helpers/statuses";

export default {
  name: "ServiceBar",
  components: {
    Button,
    Container,
    CurrentBalance,
    DivisionSelector,
    Modal,
    ShopToGroupSelector,
    ToggleSwitch,
  },
  data() {
    return {
      isPreOrdersModalOpen: false,
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "isPreOrderState",
      "isShopToState",
      "isShopToStateAllowed",
      "preOrderStateAllowed",
    ]),
    ...mapGetters("basket", ["numberOfItemsInBasket"]),
    ...mapGetters("users", ["statusOfUsers"]),
    isUpdatingState() {
      return this.statusOfUsers === statuses.LOADING;
    },
  },
  methods: {
    ...mapActions("users", [
      "ACTIVATE_PRE_ORDER_MODE",
      "DEACTIVATE_PRE_ORDER_MODE",
    ]),
    closePreOrdersModal() {
      this.isPreOrdersModalOpen = false;
    },
    closeShopToModal() {
      this.isShopToModalOpen = false;
    },
    emptyBasketAndEnablePreOrders() {
      if (this.isPreOrderState) {
        this.DEACTIVATE_PRE_ORDER_MODE().then(() => {
          this.closePreOrdersModal();
        });
      } else {
        this.ACTIVATE_PRE_ORDER_MODE().then(() => {
          this.closePreOrdersModal();
        });
      }
    },
    emptyBasketAndEnableShopTo() {
      if (this.isPreOrderState) {
        this.DEACTIVATE_SHOP_TO_MODE().then(() => {
          this.closeShopToModal();
        });
      } else {
        this.ACTIVATE_SHOP_TO_MODE().then(() => {
          this.closeShopToModal();
        });
      }
    },
    openPreOrdersModal() {
      this.isPreOrdersModalOpen = true;
    },
    openShopToModal() {
      this.isShopToModalOpen = true;
    },
    togglePreOrders() {
      const {
        $refs,
        ACTIVATE_PRE_ORDER_MODE,
        DEACTIVATE_PRE_ORDER_MODE,
        isPreOrderState,
        numberOfItemsInBasket,
        openPreOrdersModal,
      } = this;

      if (numberOfItemsInBasket === 0) {
        if (isPreOrderState) {
          DEACTIVATE_PRE_ORDER_MODE();
        } else {
          ACTIVATE_PRE_ORDER_MODE();
        }
      } else {
        //Manually force the checkbox UI to match the ToggleSwitch state
        $refs.preOrderSwitch.$el.querySelector(
          "input[type='checkbox']",
        ).checked = isPreOrderState;

        openPreOrdersModal();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.service-bar {
  background-color: #faf9f8;
  width: 100%;

  &__balance {
    &--desktop-only {
      display: none;

      @media (min-width: 64rem) {
        display: flex;
      }
    }

    &--mobile-only {
      flex: 0 0 100%;

      &:not(:first-child) {
        border-left: 1px solid #dfdedd;
        flex: 0 0 50%;
        padding-left: 1rem;
      }

      @media (min-width: 64rem) {
        display: none;
      }
    }
  }

  &__block {
    &:first-child {
      display: flex;

      @media (min-width: 64rem) {
        display: block;
        margin-right: 2rem;
      }
    }

    &:last-child {
      align-content: center;
      display: flex;
    }
  }

  &__blocks {
    @media (min-width: 64rem) {
      align-items: center;
      display: flex;
      flex-flow: nowrap;
      height: var(--size-service-bar);
      justify-content: space-between;
    }
  }

  &__divisions {
    flex: 0 0 50%;
    padding-right: 1rem;
  }

  &__toggle {
    flex: 0 0 100%;
    height: 3.3125rem;
    order: 1;

    @media (min-width: 64rem) {
      flex: 0 0 auto;
      height: 2.5rem;
      order: 0;
    }

    &:not(:first-child) {
      @media (min-width: 64rem) {
        border-left: none !important;
      }
    }

    &:not(:last-child) {
      position: relative;

      @media (min-width: 64rem) {
        border: 1px solid #dfdedd;
        border-width: 0 1px;
        margin-right: 1rem;
        padding: 0 1rem;
      }

      &::after {
        border-top: 1px solid #dfdedd;
        content: "";
        left: calc(var(--spacing-container) * -1);
        position: absolute;
        top: 0;
        width: calc(100% + var(--spacing-container) * 2);

        @media (min-width: 64rem) {
          content: none;
        }
      }
    }
  }
}
</style>
