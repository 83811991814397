<template>
  <a
    v-if="!isRoutingAvailable || (type && type.toLowerCase() === 'external')"
    :href="to"
    :rel="target === '_blank' ? 'noreferrer' : null"
    :target="target"
    v-bind="{ ...$attrs, ...$props }"
  >
    <slot />
  </a>
  <router-link v-else v-bind="{ ...$attrs, ...$props }" :to="to">
    <slot />
  </router-link>
</template>

<script>
export default {
  name: "ConditionalRouterLink",
  props: {
    target: {
      default: null,
      type: String,
    },
    to: {
      default: null,
      type: [Object, String],
    },
    type: {
      default: null,
      type: String,
    },
  },
};
</script>
