<template>
  <div
    v-tooltip="tooltipContent"
    class="wrapper"
    :class="{
      'wrapper--disabled': disabled,
    }"
    @click="$emit('click', $event)"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ActionIcon",
  props: {
    disabled: {
      type: Boolean,
    },
    tooltipContent: {
      default: "",
      type: String,
    },
  },
};
</script>

<style scoped lang="scss">
.wrapper {
  display: flex;
  width: 42px;
  height: 42px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #dedede;
  background: #fff;
  cursor: pointer;

  &--disabled {
    cursor: not-allowed;
  }
}
</style>
