<template>
  <div class="usp">
    <ul class="usp__list">
      <li v-for="item in items" :key="item" class="usp__item">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "UspList",
  props: {
    items: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.usp {
  &__item {
    font-size: 0.9375rem;

    &:not(:last-child) {
      margin-bottom: calc(var(--spacing-stack) * 0.5);
    }
  }

  &__list {
    margin: 0;
    padding: 0 0 0 1.25rem;
  }
}
</style>
