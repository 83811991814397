import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("authentication", ["contextSizeChart", "onBehalfSizeChart"]),
  },
  methods: {
    getPreferredSizeChart(sizeCharts) {
      if (!sizeCharts) return null;

      const chartMatchingContext = sizeCharts[this.contextSizeChart];
      const chartMatchingDanish = sizeCharts["da"];
          const chartMatchingOnBehalf = sizeCharts[this.onBehalfSizeChart];
          const chartMatchingEnglish = sizeCharts["en"];

      return (
        chartMatchingContext ||
        chartMatchingOnBehalf ||
        chartMatchingDanish ||
        chartMatchingEnglish ||
        ""
      );
    },
    getPreferredSizeChartLabel(size) {
      if (!size || !size.sizeChart || !size.sizeChart.length) return null;

      const chartMatchingContext = size.sizeChart.find(
        entry => entry.chart === this.contextSizeChart,
      );

      if (chartMatchingContext) return chartMatchingContext.chart;

      const chartMatchingOnBehalf = size.sizeChart.find(
        entry => entry.chart === this.onBehalfSizeChart,
      );

      if (chartMatchingOnBehalf) return chartMatchingOnBehalf.chart;

      const chartMatchingDanish = size.sizeChart.find(
        entry => entry.chart === "da",
      );

      if (chartMatchingDanish) return chartMatchingDanish.chart;

      return size.sizeChart[0].chart;
    },
    getPreferredSizeChartValue(size) {
      if (!size || !size.sizeChart || !size.sizeChart.length) return null;

      const chartMatchingContext = size.sizeChart.find(
        entry => entry.chart === this.contextSizeChart,
      );

      if (chartMatchingContext) return chartMatchingContext.value;

      const chartMatchingOnBehalf = size.sizeChart.find(
        entry => entry.chart === this.onBehalfSizeChart,
      );

      if (chartMatchingOnBehalf) return chartMatchingOnBehalf.value;

      const chartMatchingDanish = size.sizeChart.find(
        entry => entry.chart === "da",
      );

      if (chartMatchingDanish) return chartMatchingDanish.value;

      return size.sizeChart[0].value;
    },
  },
};
