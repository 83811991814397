import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { alertTypes } from "@/helpers/alertTypes";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  activeImport: "",
  currentJobId: "",
  imports: [],
  status: "",
});

const getters = {
  activeImport: state => state.activeImport,
  awaitingImports: state => {
    if (!state.imports || !state.imports.length) return [];

    return state.imports.filter(
      entry =>
        entry.status.toLowerCase() !== "finished" &&
        entry.status.toLowerCase() !== "failed",
    );
  },
  finishedImports: state => {
    if (!state.imports || !state.imports.length) return [];

    return state.imports.filter(
      entry =>
        entry.status.toLowerCase() === "finished" ||
        entry.status.toLowerCase() === "failed",
    );
  },
  imports: state => state.imports,
  statusOfImports: state => state.status,
};

const actions = {
  ACTIVATE_IMPORT({ commit }, payload) {
    commit("SET_ACTIVE_IMPORT", { value: payload.value });
  },
  DEACTIVATE_IMPORT({ commit }) {
    commit("SET_ACTIVE_IMPORT", { value: "" });
  },
  DELETE_CUSTOMER_SHIPPING_ADDRESSES_IMPORT({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId, jobId } = payload;

    return axios
      .delete(`${endpoints.ECOMMERCE}/customerShippingAddressImport/${jobId}`)
      .then(() => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        dispatch(
          "GET_CUSTOMER_SHIPPING_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE",
          { customerId },
        );
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "SuccessMessage.ImportDeleted",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_ORDERS_IMPORT({ commit, dispatch, rootGetters }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId, jobId } = payload;
    const currentCustomerType = rootGetters[
      "customers/typeOfCurrentCustomer"
    ].toLowerCase();

    return axios
      .delete(`${endpoints.ECOMMERCE}/orderimport/${currentCustomerType}/${jobId}`)
      .then(() => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_ORDERS_IMPORT_STATUS_UNTIL_COMPLETE", { customerId });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "SuccessMessage.ImportDeleted",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_USER_ADDRESSES_IMPORT({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId, jobId } = payload;

    return axios
      .delete(`${endpoints.ECOMMERCE}/userAddressImport/${jobId}`)
      .then(() => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_USER_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE", {
          customerId,
        });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "SuccessMessage.ImportDeleted",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_USER_GROUP_ADDRESSES_IMPORT({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId, jobId } = payload;

    return axios
      .delete(`${endpoints.ECOMMERCE}/userGroupAddressImport/${jobId}`)
      .then(() => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_USER_GROUP_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE", {
          customerId,
        });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "SuccessMessage.ImportDeleted",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_USERS_IMPORT({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId, jobId } = payload;

    return axios
      .delete(`${endpoints.ECOMMERCE}/userimport/${jobId}`)
      .then(() => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_USERS_IMPORT_STATUS_UNTIL_COMPLETE", { customerId });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "SuccessMessage.ImportDeleted",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_WALLET_TRANSACTIONS_IMPORT({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId, jobId } = payload;

    return axios
      .delete(`${endpoints.ECOMMERCE}/walletTransactionImport/${jobId}`)
      .then(() => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_WALLET_TRANSACTIONS_IMPORT_STATUS_UNTIL_COMPLETE", {
          customerId,
        });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "SuccessMessage.ImportDeleted",
            type: alertTypes.SUCCESS,
          },
          { root: true },
        );
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CUSTOMER_SHIPPING_ADDRESSES_CSV_TEMPLATE({ commit }) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/customerShippingAddressImport/getCsvTemplate`,
        {
          responseType: "blob",
        },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CUSTOMER_SHIPPING_ADDRESSES_IMPORT_RESULT({ commit }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/customerShippingAddressImport/result/${payload.jobId}`,
        {
          responseType: "blob",
        },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CUSTOMER_SHIPPING_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE(
    { commit, dispatch },
    payload,
  ) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    const { customerId } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/customerShippingAddressImport/${customerId}/status`,
      )
      .then(({ data }) => {
        commit("SET_IMPORTS", { imports: data });

        const hasUnfinishedImports = !data.every(
          entry =>
            entry.status.toLowerCase() === "failed" ||
            entry.status.toLowerCase() === "finished",
        );

        if (hasUnfinishedImports) {
          window.setTimeout(() => {
            dispatch(
              "GET_CUSTOMER_SHIPPING_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE",
              { customerId },
            );
          }, 250);
        } else {
          commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        }
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ORDERS_CSV_TEMPLATE({ commit, rootGetters }) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    const currentCustomerType = rootGetters[
      "customers/typeOfCurrentCustomer"
    ].toLowerCase();

    return axios
      .get(`${endpoints.ECOMMERCE}/orderimport/${currentCustomerType}/getCsvTemplate`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ORDERS_IMPORT_RESULT({ commit, rootGetters }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    const currentCustomerType = rootGetters[
      "customers/typeOfCurrentCustomer"
    ].toLowerCase();

    return axios
      .get(
        `${endpoints.ECOMMERCE}/orderimport/${currentCustomerType}/result/${payload.jobId}`,
        {
          responseType: "blob",
        },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ORDERS_IMPORT_STATUS_UNTIL_COMPLETE({ commit, dispatch, rootGetters }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    const { customerId } = payload;
    const currentCustomerType = rootGetters[
      "customers/typeOfCurrentCustomer"
    ].toLowerCase();

    return axios
      .post(`${endpoints.ECOMMERCE}/orderimport/${currentCustomerType}/${customerId}/status`)
      .then(({ data }) => {
        commit("SET_IMPORTS", { imports: data });

        const hasUnfinishedImports = !data.every(
          entry =>
            entry.status.toLowerCase() === "failed" ||
            entry.status.toLowerCase() === "finished",
        );

        if (hasUnfinishedImports) {
          window.setTimeout(() => {
            dispatch("GET_ORDERS_IMPORT_STATUS_UNTIL_COMPLETE", { customerId });
          }, 250);
        } else {
          commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        }
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USER_ADDRESSES_CSV_TEMPLATE({ commit }) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/userAddressImport/getCsvTemplate`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USER_ADDRESSES_IMPORT_RESULT({ commit }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/userAddressImport/result/${payload.jobId}`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USER_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE(
    { commit, dispatch },
    payload,
  ) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    const { customerId } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/userAddressImport/${customerId}/status`)
      .then(({ data }) => {
        commit("SET_IMPORTS", { imports: data });

        const hasUnfinishedImports = !data.every(
          entry =>
            entry.status.toLowerCase() === "failed" ||
            entry.status.toLowerCase() === "finished",
        );

        if (hasUnfinishedImports) {
          window.setTimeout(() => {
            dispatch("GET_USER_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE", {
              customerId,
            });
          }, 250);
        } else {
          commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        }
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USER_GROUP_ADDRESSES_CSV_TEMPLATE({ commit }) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/userGroupAddressImport/getCsvTemplate`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USER_GROUP_ADDRESSES_IMPORT_RESULT({ commit }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/userGroupAddressImport/result/${payload.jobId}`,
        {
          responseType: "blob",
        },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USER_GROUP_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE(
    { commit, dispatch },
    payload,
  ) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    const { customerId } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/userGroupAddressImport/${customerId}/status`,
      )
      .then(({ data }) => {
        commit("SET_IMPORTS", { imports: data });

        const hasUnfinishedImports = !data.every(
          entry =>
            entry.status.toLowerCase() === "failed" ||
            entry.status.toLowerCase() === "finished",
        );

        if (hasUnfinishedImports) {
          window.setTimeout(() => {
            dispatch("GET_USER_GROUP_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE", {
              customerId,
            });
          }, 250);
        } else {
          commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        }
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USERS_CSV_TEMPLATE({ commit }) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/userimport/getCsvTemplate`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USERS_IMPORT_RESULT({ commit }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/userimport/result/${payload.jobId}`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USERS_IMPORT_STATUS_UNTIL_COMPLETE({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    const { customerId } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/userimport/${customerId}/status`)
      .then(({ data }) => {
        commit("SET_IMPORTS", { imports: data });

        const hasUnfinishedImports = !data.every(
          entry =>
            entry.status.toLowerCase() === "failed" ||
            entry.status.toLowerCase() === "finished",
        );

        if (hasUnfinishedImports) {
          window.setTimeout(() => {
            dispatch("GET_USERS_IMPORT_STATUS_UNTIL_COMPLETE", { customerId });
          }, 250);
        } else {
          commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        }
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_WALLET_TRANSACTIONS_CSV_TEMPLATE({ commit }) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/walletTransactionImport/getCsvTemplate`, {
        responseType: "blob",
      })
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_WALLET_TRANSACTIONS_IMPORT_RESULT({ commit }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/walletTransactionImport/result/${payload.jobId}`,
        {
          responseType: "blob",
        },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        const file = window.URL.createObjectURL(data);
        window.location.assign(file);
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_WALLET_TRANSACTIONS_IMPORT_STATUS_UNTIL_COMPLETE(
    { commit, dispatch },
    payload,
  ) {
    commit("SET_IMPORT_STATUS", { status: statuses.LOADING });

    const { customerId, userId } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/walletTransactionImport/${customerId}/status`,
      )
      .then(({ data }) => {
        commit("SET_IMPORTS", { imports: data });

        const hasUnfinishedImports = !data.every(
          entry =>
            entry.status.toLowerCase() === "failed" ||
            entry.status.toLowerCase() === "finished",
        );

        if (hasUnfinishedImports) {
          window.setTimeout(() => {
            dispatch("GET_WALLET_TRANSACTIONS_IMPORT_STATUS_UNTIL_COMPLETE", {
              customerId,
              userId,
            });
          }, 250);
        } else {
          commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });

          if (userId) {
            dispatch(
              "wallets/GET_TRANSACTIONS_OF_WALLET",
              { id: userId },
              { root: true },
            );
          }
        }
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_IMPORTS({ commit }) {
    commit("SET_ACTIVE_IMPORT", { value: "" });
    commit("SET_IMPORTS", { imports: [] });
    commit("SET_IMPORT_STATUS", { status: "" });
  },
  UPLOAD_CUSTOMER_SHIPPING_ADDRESSES_IMPORT_FILE(
    { commit, dispatch },
    payload,
  ) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId } = payload;

    const formData = new FormData();
    formData.append("file", payload.file);

    return axios
      .post(
        `${endpoints.ECOMMERCE}/customerShippingAddressImport/${customerId}/upload`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_JOB_ID", { id: data });
        dispatch(
          "GET_CUSTOMER_SHIPPING_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE",
          { customerId },
        );
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_ORDERS_IMPORT_FILE({ commit, dispatch, rootGetters }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId } = payload;
    const currentCustomerType = rootGetters[
      "customers/typeOfCurrentCustomer"
    ].toLowerCase();

    const formData = new FormData();
    formData.append("file", payload.file);

    return axios
      .post(
        `${endpoints.ECOMMERCE}/orderimport/${currentCustomerType}/${customerId}/upload`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_JOB_ID", { id: data });
        dispatch("GET_ORDERS_IMPORT_STATUS_UNTIL_COMPLETE", { customerId });
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_USER_ADDRESSES_IMPORT_FILE({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId } = payload;

    const formData = new FormData();
    formData.append("file", payload.file);

    return axios
      .post(
        `${endpoints.ECOMMERCE}/userAddressImport/${customerId}/upload`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_JOB_ID", { id: data });
        dispatch("GET_USER_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE", {
          customerId,
        });
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_USER_GROUP_ADDRESSES_IMPORT_FILE({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId } = payload;

    const formData = new FormData();
    formData.append("file", payload.file);

    return axios
      .post(
        `${endpoints.ECOMMERCE}/userGroupAddressImport/${customerId}/upload`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_JOB_ID", { id: data });
        dispatch("GET_USER_GROUP_ADDRESSES_IMPORT_STATUS_UNTIL_COMPLETE", {
          customerId,
        });
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_USERS_IMPORT_FILE({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId } = payload;

    const formData = new FormData();
    formData.append("file", payload.file);

    return axios
      .post(
        `${endpoints.ECOMMERCE}/userimport/${customerId}/upload`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_JOB_ID", { id: data });
        dispatch("GET_USERS_IMPORT_STATUS_UNTIL_COMPLETE", { customerId });
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_WALLET_TRANSACTIONS_IMPORT_FILE({ commit, dispatch }, payload) {
    commit("SET_IMPORT_STATUS", { status: statuses.SAVING });

    const { customerId } = payload;

    const formData = new FormData();
    formData.append("file", payload.file);

    return axios
      .post(
        `${endpoints.ECOMMERCE}/walletTransactionImport/${customerId}/upload`,
        formData,
        { headers: { "Content-Type": "multipart/form-data" } },
      )
      .then(({ data }) => {
        commit("SET_IMPORT_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_JOB_ID", { id: data });
        dispatch("GET_WALLET_TRANSACTIONS_IMPORT_STATUS_UNTIL_COMPLETE", {
          customerId,
        });
      })
      .catch(error => {
        commit("SET_IMPORT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_ACTIVE_IMPORT(state, payload) {
    state.activeImport = payload.value;
  },
  SET_CURRENT_JOB_ID(state, payload) {
    state.currentJobId = payload.id;
  },
  SET_IMPORT_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_IMPORTS(state, payload) {
    state.imports = payload.imports;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
