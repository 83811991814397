import axios from "axios";
import { alertTypes } from "@/helpers/alertTypes";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentPurchaseLimitation: null,
  currentPurchaseLimitationId: null,
  currentPurchaseLimitationProductId: null,
  isModalForAddingProductsOpen: false,
  isModalForCreatingPurchaseLimitationOpen: false,
  isModalForEditingProductOpen: false,
  productsOfCurrentPurchaseLimitation: [],
  purchaseLimitations: [],
  selectedProductsToAdd: [],
  selectedPurchaseLimitations: [],
  selectedVariantsToAdd: [],
  status: "",
});

const getters = {
  currentPurchaseLimitation: state => state.currentPurchaseLimitation,
  currentPurchaseLimitationId: state => state.currentPurchaseLimitationId,
  currentPurchaseLimitationProduct: state =>
    state.productsOfCurrentPurchaseLimitation.find(
      product => product.id === state.currentPurchaseLimitationProductId,
    ),
  currentPurchaseLimitationProductId: state =>
    state.currentPurchaseLimitationProductId,
  isModalForAddingProductsOpen: state => state.isModalForAddingProductsOpen,
  isModalForCreatingPurchaseLimitationOpen: state =>
    state.isModalForCreatingPurchaseLimitationOpen,
  isModalForEditingProductOpen: state => state.isModalForEditingProductOpen,
  nameOfCurrentPurchaseLimitation: state => {
    if (!state.currentPurchaseLimitation) return null;

    return state.currentPurchaseLimitation.name;
  },
  nameOfCurrentPurchaseLimitationProduct: (state, getters) => {
    const {
      currentPurchaseLimitationId,
      currentPurchaseLimitationProductId,
      purchaseLimitations,
    } = state;

    if (
      !purchaseLimitations.length ||
      !currentPurchaseLimitationId ||
      !currentPurchaseLimitationProductId
    )
      return null;

    const matchingProduct = getters.purchaseLimitationProductById({
      productId: currentPurchaseLimitationProductId,
      purchaseLimitationId: currentPurchaseLimitationId,
    });

    return matchingProduct ? matchingProduct.title : null;
  },
  productsOfCurrentPurchaseLimitation: state =>
    state.productsOfCurrentPurchaseLimitation,
  purchaseLimitationById: state => id =>
    state.purchaseLimitations.find(
      purchaseLimitation => purchaseLimitation.id === id,
    ),
  purchaseLimitationProductById: (state, getters) => ({
    productId,
    purchaseLimitationId,
  }) => {
    if (
      !productId ||
      !purchaseLimitationId ||
      !state.purchaseLimitations.length
    )
      return null;

    const matchingPurchaseLimitation = getters.purchaseLimitationById(
      purchaseLimitationId,
    );

    if (!matchingPurchaseLimitation || !matchingPurchaseLimitation.products)
      return null;

    return matchingPurchaseLimitation.products.find(
      product => product.id === productId,
    );
  },
  purchaseLimitations: state => state.purchaseLimitations,
  selectedProducts: state => state.selectedProducts,
  selectedProductsToAdd: state => state.selectedProductsToAdd,
  selectedVariantsToAdd: state => state.selectedVariantsToAdd,
  selectedPurchaseLimitations: state => state.selectedPurchaseLimitations,
  statusOfPurchaseLimitations: state => state.status,
};

const actions = {
  ADD_VARIANTS_TO_PURCHASE_LIMITATION({ commit, dispatch }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SAVING });

    const { purchaseLimitationId, userGroupId, variantsToAdd } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/purchaseLimit/${purchaseLimitationId}/add-multiple-variants`,
          variantsToAdd,
        )
        .then(() => {
          commit("SET_PURCHASE_LIMITATIONS_STATUS", {
            status: statuses.SUCCESS,
          });
          dispatch("CLOSE_MODAL_FOR_ADDING_PRODUCTS");
          dispatch("CLOSE_MODAL_FOR_EDITING_PRODUCT");
          commit("DESELECT_PRODUCTS_TO_ADD");
          commit("DESELECT_VARIANTS_TO_ADD");
          dispatch("GET_PRODUCTS_OF_PURCHASE_LIMITATION", {
            purchaseLimitationId,
            userGroupId,
          });
          resolve();
        })
        .catch(error => {
          commit("SET_PURCHASE_LIMITATIONS_STATUS", {
            status: statuses.FAILURE,
          });
          errorAlert(error);
          reject();
        });
    });
  },
  CLOSE_MODAL_FOR_ADDING_PRODUCTS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ADDING_PRODUCTS", { value: false });
    commit("SET_CURRENT_PURCHASE_LIMITATION_PRODUCT_ID", { productId: null });
    commit("SET_SELECTED_VARIANTS_TO_ADD", { variants: [] });
  },
  CLOSE_MODAL_FOR_CREATING_PURCHASE_LIMITATION({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_PURCHASE_LIMITATION", {
      value: false,
    });
  },
  CLOSE_MODAL_FOR_EDITING_PRODUCT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT", { value: false });
  },
  CREATE_PURCHASE_LIMITATION({ commit, dispatch }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SAVING });

    const { customerId, name, userGroupId } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/purchaseLimit`,
          {
            customerId,
            name,
            userGroupId,
          },
        )
        .then(({ data }) => {
          commit("SET_PURCHASE_LIMITATIONS_STATUS", {
            status: statuses.SUCCESS,
          });
          dispatch("CLOSE_MODAL_FOR_CREATING_PURCHASE_LIMITATION");
          resolve({ id: data });
        })
        .catch(error => {
          commit("SET_PURCHASE_LIMITATIONS_STATUS", {
            status: statuses.FAILURE,
          });
          errorAlert(error);
          reject();
        });
    });
  },
  DELETE_SELECTED_PURCHASE_LIMITATIONS({ commit, dispatch, state }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SAVING });

    const { userGroupId } = payload;

    axios
      .post(
        `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/purchaseLimit/delete-multiple`,
        {
          identifiers: state.selectedPurchaseLimitations.map(
            purchaseLimitation => purchaseLimitation.id,
          ),
        },
      )
      .then(() => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SUCCESS });
        commit("DESELECT_PURCHASE_LIMITATIONS");
        dispatch("GET_PURCHASE_LIMITATIONS", { userGroupId });
      })
      .catch(error => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCTS_OF_PURCHASE_LIMITATION({ commit }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/usergroups/${payload.userGroupId}/purchaseLimit/${payload.purchaseLimitationId}/products`,
      )
      .then(({ data }) => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_PRODUCTS_OF_CURRENT_PURCHASE_LIMITATION", {
          products: data,
        });
      })
      .catch(error => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PURCHASE_LIMITATION({ commit }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/usergroups/${payload.userGroupId}/purchaseLimit/${payload.purchaseLimitationId}`,
      )
      .then(({ data }) => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_PURCHASE_LIMITATION", { purchaseLimitation: data });
      })
      .catch(error => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PURCHASE_LIMITATIONS({ commit }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/usergroups/${payload.userGroupId}/purchaseLimit`,
      )
      .then(({ data }) => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_PURCHASE_LIMITATIONS", {
          purchaseLimitations: data,
        });
      })
      .catch(error => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_ADDING_PRODUCTS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ADDING_PRODUCTS", { value: true });
  },
  OPEN_MODAL_FOR_CREATING_PURCHASE_LIMITATION({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_PURCHASE_LIMITATION", {
      value: true,
    });
  },
  OPEN_MODAL_FOR_EDITING_PRODUCT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT", { value: true });
  },
  REMOVE_PRODUCT_FROM_PURCHASE_LIMITATION({ commit, dispatch }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.LOADING });

    const { productId, purchaseLimitationId, userGroupId } = payload;

    return axios
      .delete(
        `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/purchaseLimit/${purchaseLimitationId}/remove-product/${productId}`,
      )
      .then(() => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_PRODUCTS_OF_PURCHASE_LIMITATION", {
          purchaseLimitationId,
          userGroupId,
        });
      })
      .catch(error => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  REMOVE_VARIANTS_FROM_PURCHASE_LIMITATION({ commit, dispatch }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SAVING });

    const { purchaseLimitationId, userGroupId, variants } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/purchaseLimit/${purchaseLimitationId}/remove-multiple-variants`,
        variants,
      )
      .then(() => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SUCCESS });
        dispatch("CLOSE_MODAL_FOR_ADDING_PRODUCTS");
        dispatch("CLOSE_MODAL_FOR_EDITING_PRODUCT");
        dispatch("GET_PRODUCTS_OF_PURCHASE_LIMITATION", {
          purchaseLimitationId,
          userGroupId,
        });
      })
      .catch(error => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_CURRENT_PURCHASE_LIMITATION({ commit, dispatch }) {
    commit("SET_CURRENT_PURCHASE_LIMITATION", { purchaseLimitation: {} });
    dispatch("UPDATE_CURRENT_PURCHASE_LIMITATION_ID", {
      purchaseLimitationId: null,
    });
    dispatch("RESET_PRODUCTS_OF_CURRENT_PURCHASE_LIMITATION");
  },
  RESET_PRODUCTS_OF_CURRENT_PURCHASE_LIMITATION({ commit }) {
    commit("SET_PRODUCTS_OF_CURRENT_PURCHASE_LIMITATION", { products: [] });
  },
  RESET_SELECTED_PRODUCTS_TO_ADD({ commit }) {
    commit("DESELECT_PRODUCTS_TO_ADD");
  },
  RESET_SELECTED_VARIANTS_TO_ADD({ commit }) {
    commit("DESELECT_VARIANTS_TO_ADD");
  },
  TOGGLE_PRODUCT_TO_ADD({ commit }, payload) {
    commit("SET_SELECTED_PRODUCT_TO_ADD", payload);
  },
  TOGGLE_VARIANT_TO_ADD({ commit }, payload) {
    commit("SET_SELECTED_VARIANT_TO_ADD", payload);
  },
  TOGGLE_PURCHASE_LIMITATION({ commit }, payload) {
    commit("SET_SELECTED_PURCHASE_LIMITATION", payload);
  },
  TOGGLE_PURCHASE_LIMITATIONS({ commit }, payload) {
    commit("SET_SELECTED_PURCHASE_LIMITATIONS", payload);
  },
  UPDATE_CURRENT_PURCHASE_LIMITATION_ID({ commit }, payload) {
    commit("SET_CURRENT_PURCHASE_LIMITATION_ID", payload);
  },
  UPDATE_CURRENT_PURCHASE_LIMITATION_PRODUCT_ID({ commit }, payload) {
    commit("SET_CURRENT_PURCHASE_LIMITATION_PRODUCT_ID", payload);
  },
  UPDATE_VARIANTS_IN_PURCHASE_LIMITATION({ commit, dispatch }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SAVING });

    const {
      purchaseLimitationId,
      userGroupId,
      variantsToAdd,
      variantsToRemove,
    } = payload;

    const addVariants = variantsToAdd?.length
      ? axios.post(
          `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/purchaseLimit/${purchaseLimitationId}/add-multiple-variants`,
          variantsToAdd,
        )
      : null;

    const removeVariants = variantsToRemove?.length
      ? axios.post(
          `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/purchaseLimit/${purchaseLimitationId}/remove-multiple-variants`,
          variantsToRemove,
        )
      : null;

    return axios
      .all([addVariants, removeVariants])
      .then(() => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_PRODUCTS_OF_PURCHASE_LIMITATION", {
          purchaseLimitationId,
          userGroupId,
        });
        dispatch("CLOSE_MODAL_FOR_EDITING_PRODUCT");
        commit("DESELECT_PRODUCTS_TO_ADD");
        commit("DESELECT_VARIANTS_TO_ADD");
      })
      .catch(error => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_VARIANTS_TO_ADD({ commit }, payload) {
    commit("SET_SELECTED_VARIANTS_TO_ADD", payload);
  },
  UPDATE_PURCHASE_LIMITATION({ commit, dispatch }, payload) {
    commit("SET_PURCHASE_LIMITATIONS_STATUS", { status: statuses.SAVING });

    const {
      active,
      description,
      id,
      maxAmount,
      name,
      nextRenewalDate,
      renewalMonthInterval,
      userGroupId,
    } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/purchaseLimit/${id}`,
        {
          active,
          description,
          id,
          maxAmount,
          name,
          nextRenewalDate,
          renewalMonthInterval,
        },
      )
      .then(() => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", {
          status: statuses.SUCCESS,
        });
        commit("SET_CURRENT_PURCHASE_LIMITATION", {
          purchaseLimitation: payload,
        });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "Common.YourChangesWereSaved",
            type: alertTypes.SUCCESS,
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        commit("SET_PURCHASE_LIMITATIONS_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
};

const mutations = {
  DESELECT_PRODUCTS_TO_ADD(state) {
    state.selectedProductsToAdd = [];
  },
  DESELECT_VARIANTS_TO_ADD(state) {
    state.selectedVariantsToAdd = [];
  },
  DESELECT_PURCHASE_LIMITATIONS(state) {
    state.selectedPurchaseLimitations = [];
  },
  SET_CURRENT_PURCHASE_LIMITATION(state, payload) {
    state.currentPurchaseLimitation = payload.purchaseLimitation;
  },
  SET_CURRENT_PURCHASE_LIMITATION_ID(state, payload) {
    state.currentPurchaseLimitationId = payload.purchaseLimitationId;
  },
  SET_CURRENT_PURCHASE_LIMITATION_PRODUCT_ID(state, payload) {
    state.currentPurchaseLimitationProductId = payload.id;
  },
  SET_PRODUCTS_OF_CURRENT_PURCHASE_LIMITATION(state, payload) {
    state.productsOfCurrentPurchaseLimitation = payload.products;
  },
  SET_SELECTED_PRODUCT_TO_ADD(state, payload) {
    const { product, value } = payload;

    if (value) {
      if (state.selectedProductsToAdd.some(entry => entry.id === product.id)) {
        return;
      }

      state.selectedProductsToAdd.push(product);
    } else {
      state.selectedProductsToAdd = state.selectedProductsToAdd.filter(
        entry => entry.id !== product.id,
      );
    }
  },
  SET_SELECTED_VARIANT_TO_ADD(state, payload) {
    const { variant, value } = payload;

    if (value) {
      if (state.selectedVariantsToAdd.some(entry => entry.id === variant.id)) {
        return;
      }

      state.selectedVariantsToAdd.push(variant);
    } else {
      state.selectedVariantsToAdd = state.selectedVariantsToAdd.filter(
        entry => entry.id !== variant.id,
      );
    }
  },
  SET_SELECTED_VARIANTS_TO_ADD(state, payload) {
    state.selectedVariantsToAdd = payload.variants;
  },
  SET_SELECTED_PURCHASE_LIMITATION(state, payload) {
    const { row: purchaseLimitation, value } = payload;

    if (value) {
      if (
        state.selectedPurchaseLimitations.some(
          entry => entry.id === purchaseLimitation.id,
        )
      ) {
        return;
      }

      state.selectedPurchaseLimitations.push(purchaseLimitation);
    } else {
      state.selectedPurchaseLimitations = state.selectedPurchaseLimitations.filter(
        entry => entry.id !== purchaseLimitation.id,
      );
    }
  },
  SET_SELECTED_PURCHASE_LIMITATIONS(state, payload) {
    const { rows: purchaseLimitations, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedPurchaseLimitations.map(
          selectedPurchaseLimitation => selectedPurchaseLimitation.id,
        ),
      );

      state.selectedPurchaseLimitations = [
        ...state.selectedPurchaseLimitations,
        ...purchaseLimitations
          .filter(purchaseLimitation => !purchaseLimitation.disableSelection)
          .filter(
            purchaseLimitation => !identifiers.has(purchaseLimitation.id),
          ),
      ];
    } else {
      state.selectedPurchaseLimitations = state.selectedPurchaseLimitations.filter(
        selectedPurchaseLimitation =>
          !purchaseLimitations.some(
            entry => entry.id === selectedPurchaseLimitation.id,
          ),
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_ADDING_PRODUCTS(state, payload) {
    state.isModalForAddingProductsOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_CREATING_PURCHASE_LIMITATION(state, payload) {
    state.isModalForCreatingPurchaseLimitationOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT(state, payload) {
    state.isModalForEditingProductOpen = payload.value;
  },
  SET_PURCHASE_LIMITATIONS(state, payload) {
    state.purchaseLimitations = payload.purchaseLimitations;
  },
  SET_PURCHASE_LIMITATIONS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
