<template>
  <div v-if="currentCatalogue">
    <h3 class="customer-logo__label">
      {{ getDictionaryEntry("Common.Labels.Logo") }}
    </h3>
    <Stack v-if="currentDealerLogo">
      <div class="customer-logo__image-container">
        <ImageFromUmbraco
          :id="currentDealerLogo"
          class="customer-logo__image"
        />
        <button
          class="customer-logo__action"
          :aria-label="getDictionaryEntry('Common.Remove')"
          :title="getDictionaryEntry('Common.Remove')"
          @click="REMOVE_DEALER_LOGO"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 21 21"
            class="customer-logo__action-icon"
          >
            <defs />
            <g>
              <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
            </g>
          </svg>
        </button>
      </div>
    </Stack>
    <div v-else>
      <Stack v-if="currentCatalogue.customDealerLogoMediaKey || preview">
        <transition mode="out-in" name="fade">
          <div v-if="preview" class="customer-logo__image-container">
            <img :src="preview" alt="" class="customer-logo__image" />
            <button
              class="customer-logo__action"
              :aria-label="getDictionaryEntry('Common.Remove')"
              :title="getDictionaryEntry('Common.Remove')"
              @click="removePreview(preview)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 21"
                class="customer-logo__action-icon"
              >
                <defs />
                <g>
                  <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
                </g>
              </svg>
            </button>
          </div>
          <div v-else class="customer-logo__image-container">
            <ImageFromUmbraco
              v-if="currentCatalogue.customDealerLogoMediaKey"
              :id="currentCatalogue.customDealerLogoMediaKey"
              class="customer-logo__image"
            />
            <button
              class="customer-logo__action"
              :aria-label="getDictionaryEntry('Common.Remove')"
              :title="getDictionaryEntry('Common.Remove')"
              @click="REMOVE_DEALER_LOGO"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 21"
                class="customer-logo__action-icon"
              >
                <defs />
                <g>
                  <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
                </g>
              </svg>
            </button>
          </div>
        </transition>
      </Stack>
      <Stack :half="true">
        <transition name="fade">
          <Button
            v-if="!currentCatalogue.customDealerLogoMediaKey"
            :limited-width="true"
            :secondary="true"
            :show-loader="isSaving"
            @click="triggerFileSelection"
          >
            {{ getDictionaryEntry("Administration.SelectImageToUpload") }}
          </Button>
        </transition>
        <input
          ref="input"
          class="customer-logo__input"
          type="file"
          @input="selectFile"
        />
      </Stack>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";
import Stack from "@/components/Stack";
import { statuses } from "@/helpers/statuses";

export default {
  name: "CataloguesDealerLogo",
  components: {
    Button,
    ImageFromUmbraco,
    Stack,
  },
  data() {
    return {
      preview: null,
    };
  },
  computed: {
    ...mapGetters("catalogues", [
      "currentCatalogue",
      "currentDealerLogo",
      "statusOfCatalogues",
    ]),
    isSaving() {
      return this.statusOfCatalogues === statuses.SAVING;
    },
  },
  methods: {
    ...mapActions("catalogues", ["REMOVE_DEALER_LOGO", "UPLOAD_DEALER_LOGO"]),
    clearPreview() {
      if (this.$refs.input) {
        this.$refs.input.value = "";
      }

      this.preview = null;
    },
    removePreview() {
      this.clearPreview();
    },
    selectFile() {
      const {
        $refs: {
          input: { files },
        },
        uploadImage,
      } = this;

      const reader = new FileReader();
      reader.onload = event => {
        this.preview = event.target.result;
      };
      reader.readAsDataURL(files[0]);

      uploadImage();
    },
    triggerFileSelection() {
      this.$refs.input.click();
    },
    uploadImage() {
      const { $refs, UPLOAD_DEALER_LOGO } = this;

      UPLOAD_DEALER_LOGO({
        file: $refs.input.files[0],
      }).then(() => {
        this.clearPreview();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.customer-logo {
  &__action {
    @include reset-button();

    align-items: center;
    background-color: var(--color-card-background);
    border-radius: 50%;
    box-shadow: var(--elevation-8);
    display: flex;
    justify-content: center;
    padding: calc(var(--size-product-image-preview-icon) * 0.25);
    position: absolute;
    transform: translate(50%, -50%);
    right: 0;
    top: 0;

    &-icon {
      fill-rule: evenodd;
      fill: none;
      height: var(--size-product-image-preview-icon);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
      width: var(--size-product-image-preview-icon);
    }
  }

  &__image {
    display: block;
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &-container {
      border: 1px solid var(--color-input-border);
      height: 0;
      margin-bottom: calc(var(--spacing-stack) * 0.25);
      padding-top: 100%;
      position: relative;
    }
  }

  &__input {
    @include screen-reader-only();
  }

  &__label {
    display: block;
    font-size: 0.875rem;
    font-weight: 700;
    margin: 0 0 0.5rem;
  }
}
</style>
