var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"certificates"},[(_vm.certificates.description)?_c('Stack',[_vm._v(" "+_vm._s(_vm.certificates.description)+" ")]):_vm._e(),_c('Stack',[(_vm.certificatesWithoutImage)?_c('ul',{staticClass:"certificates__list"},_vm._l((_vm.certificatesWithoutImage),function(certificateWithoutImage,index){return _c('li',{key:'withoutImage' + certificateWithoutImage.id + index,staticClass:"certificates__item"},[_vm._v(" "+_vm._s(certificateWithoutImage.name)+" ")])}),0):_vm._e()]),_c('Stack',[(_vm.certificatesWithImage)?_c('ul',{staticClass:"certificates__list certificates__list--icons"},_vm._l((_vm.certificatesWithImage),function(certificateWithImage,index){return _c('li',{key:'withImage' + certificateWithImage.id + index,staticClass:"certificates__item certificates__item--icon"},[(
            certificateWithImage.image &&
              certificateWithImage.image.externalLink
          )?_c('a',{staticClass:"certificates__link",attrs:{"href":certificateWithImage.image.externalLink,"target":"_blank"}},[_c('div',{staticClass:"certificates__icon-container"},[(
                certificateWithImage.image && certificateWithImage.image.src
              )?_c('img',{staticClass:"certificates__icon",attrs:{"src":certificateWithImage.image.src,"alt":certificateWithImage.image.alt}}):_vm._e()]),_c('div',{staticClass:"certificates__name"},[_vm._v(" "+_vm._s(certificateWithImage.name)+" ")])]):(
            certificateWithImage.image &&
              certificateWithImage.image.src &&
              !certificateWithImage.image.externalLink
          )?_c('div',{staticClass:"certificates__link"},[_c('div',{staticClass:"certificates__icon-container"},[(
                certificateWithImage.image && certificateWithImage.image.src
              )?_c('img',{staticClass:"certificates__icon",attrs:{"src":certificateWithImage.image.src,"alt":certificateWithImage.image.alt}}):_vm._e()]),_c('div',{staticClass:"certificates__name"},[_vm._v(" "+_vm._s(certificateWithImage.name)+" ")])]):_vm._e()])}),0):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }