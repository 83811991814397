<template>
  <Container v-if="variants && variants.length" :medium="true">
    <Stack>
      <div class="block">
        <h3 class="block__title block__title--with-line">
          {{ title }}
        </h3>
      </div>
    </Stack>
    <Stack :one-and-half="true">
      <Flicking
        :options="options"
        :plugins="plugins"
        class="slider slider--products slider--short"
      >
        <div
          v-for="(variant, index) in variants"
          :key="'related-products_' + parentId + variant.variantId + index"
          class="slide slide--small"
        >
          <article class="slide__content">
            <Stack>
              <div class="block__product-image-container">
                <ProductImage :variant="variant" class="block__product-image" />
              </div>
            </Stack>
            <Stack>
              <h1 class="slide__title">
                {{ variant.productName }} - {{ variant.variantName }}
              </h1>
            </Stack>
            <Stack
              v-if="variant && variant.minPrice && variant.minPrice.price"
              :quarter="true"
            >
              <div class="slide__price">
                {{ getDictionaryEntry("Product.PricesFrom") }}
                {{ variant.minPrice.price }}
                {{ variant.minPrice.currency }}
              </div>
            </Stack>
          </article>
          <router-link
            :to="{
              query: {
                mode: 'details',
                productId: variant.productId,
                variantId: variant.variantId,
              },
            }"
            :aria-label="`${variant.productName} - ${variant.variantName}`"
            class="block__cover-link"
          />
        </div>
        <button
          slot="viewport"
          aria-label="previous"
          class="slider__navigation slider__navigation--prev"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="slider__navigation-icon"
            viewBox="0 0 24 24"
          >
            <defs />
            <path d="M19 12H5M12 19l-7-7 7-7" />
          </svg>
        </button>
        <button
          slot="viewport"
          aria-label="next"
          class="slider__navigation slider__navigation--next"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="slider__navigation-icon"
            viewBox="0 0 24 24"
          >
            <defs />
            <path d="M5 12h14M12 5l7 7-7 7" />
          </svg>
        </button>
      </Flicking>
    </Stack>
  </Container>
</template>

<script>
import isEqual from "lodash.isequal";
import { Arrow } from "@egjs/flicking-plugins";
import { mapActions, mapGetters } from "vuex";
import Container from "@/components/Container";
import ProductImage from "@/components/ProductImage";
import Stack from "@/components/Stack";

export default {
  name: "RelatedProducts",
  components: {
    Container,
    ProductImage,
    Stack,
  },
  props: {
    parentId: {
      required: true,
      type: String,
    },
    title: {
      default: "",
      type: String,
    },
    variantIds: {
      default: () => [],
      type: Array,
    },
  },
  data() {
    return {
      options: {
        align: "prev",
        autoResize: true,
        bound: true,
        noPanelStyleOverride: true,
      },
      plugins: [
        new Arrow({
          disabledClass: "slider__navigation--disabled",
          nextElSelector: ".slider__navigation--next",
          prevElSelector: ".slider__navigation--prev",
        }),
      ],
    };
  },
  computed: {
    ...mapGetters("products", [
      "statusOfProducts",
      "variantsForProductBlockByIds",
    ]),
    variants() {
      const { variantIds } = this;

      if (!variantIds || !variantIds.length) return [];

      return this.variantsForProductBlockByIds(variantIds);
    },
  },
  watch: {
    variantIds(newValue, oldValue) {
      if (!newValue || !newValue.length) return;
      if (isEqual(newValue, oldValue)) return;

      this.GET_VARIANTS_FOR_PRODUCT_BLOCK({
        variantIds: newValue,
      });
    },
  },
  created() {
    const { GET_VARIANTS_FOR_PRODUCT_BLOCK, variantIds } = this;

    if (!variantIds || !variantIds.length) return;

    GET_VARIANTS_FOR_PRODUCT_BLOCK({ variantIds });
  },
  methods: {
    ...mapActions("products", ["GET_VARIANTS_FOR_PRODUCT_BLOCK"]),
  },
};
</script>
