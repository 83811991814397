<template>
  <div class="size-selector">
    <Stack :half="true">
      <Button
        :no-shadow="true"
        :round="true"
        :secondary="true"
        :show-arrow="true"
        @click="openSelector"
      >
        <span v-if="selectedSize" class="size-selector__selected"
          >{{ getPreferredSizeChartValue(selectedSize) }}
          <div
            v-if="selectedSize.inventory"
            class="size-selector__stock"
            :aria-hidden="selectedSize !== selectedSize"
          >
            <StockIndicator
              v-if="selectedSize && selectedSize.inventory"
              :indicator-only="true"
              :inventory="selectedSize.inventory"
            />
          </div>
        </span>
        <span v-else class="size-selector__button-text">{{
          getDictionaryEntry("Product.SelectSize")
        }}</span>
      </Button>
    </Stack>
    <Stack :half="true">
      <Button
        :buy="true"
        :disabled="shouldDisableButton"
        :round="true"
        :show-loader="isLoading"
        @click="addToBasket"
        >{{ getDictionaryEntry("Product.AddToBasket") }}</Button
      >
    </Stack>
    <transition name="fade">
      <div v-if="isOpen" class="size-selector__box">
        <div class="product-details__content size-selector__box-content">
          <Stack>
            <div class="size-selector__header">
              <h2 class="product-details__title size-selector__title">
                {{ getDictionaryEntry("Product.SelectSize") }}
              </h2>
              <button
                class="size-selector__close"
                :aria-label="getDictionaryEntry('Common.Close')"
                @click="closeSelector"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 21 21"
                  class="size-selector__close-icon"
                >
                  <defs />
                  <g>
                    <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
                  </g>
                </svg>
              </button>
            </div>
          </Stack>
          <Stack v-if="hasSizeGroups && isUserEndCustomer" :double="true">
            <div v-for="(sizeGroup, index) in sizes" :key="sizeGroup.title">
              <Accordion>
                <AccordionItem
                  :secondary="true"
                  :is-accordion-open="isAccordionOpen(sizeGroup)"
                >
                  <template #accordion-header>
                    <div class="size-selector__accordion-header">
                      <span>{{
                        getDictionaryEntry("SizeGroup.B2B." + sizeGroup.title)
                      }}</span>
                      <span class="size-selector__size-span">{{
                        getSizeSpan(sizeGroup)
                      }}</span>
                    </div>
                  </template>
                  <template #accordion-body>
                    <SizeSelectorList
                      :key="'groupSize_' + index"
                      :sizes="sizeGroup.sizes"
                      :selected-size="selectedSize"
                      @select-size="selectSize"
                    />
                  </template>
                </AccordionItem>
              </Accordion>
            </div>
          </Stack>
          <Stack v-else :double="true">
            <SizeSelectorList
              :sizes="sizes"
              :selected-size="selectedSize"
              @select-size="selectSize"
            />
          </Stack>
        </div>
      </div>
    </transition>
    <div
      v-if="isOpen"
      class="size-selector__underlay"
      @click="closeSelector"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Accordion from "@/components/Accordion";
import AccordionItem from "@/components/AccordionItem";
import Button from "@/components/Button";
import SizeSelectorList from "@/components/SizeSelectorList";
import Stack from "@/components/Stack";
import StockIndicator from "@/components/StockIndicator";
import { stockStatuses } from "@/helpers/stockStatuses";

export default {
  name: "SizeSelector",
  components: {
    Accordion,
    AccordionItem,
    Button,
    SizeSelectorList,
    Stack,
    StockIndicator,
  },
  props: {
    hasSizeGroups: {
      type: Boolean,
      default: false,
    },
    handleSizeSelect: {
      required: true,
      type: Function,
    },
    selectedSize: {
      type: Object,
      default: null,
    },
    sizes: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      isLoading: false,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters("basket", ["basketLineQuantity"]),
    ...mapGetters("authentication", ["isUserEndCustomer"]),
    quantityInBasket() {
      if (!this.selectedSize) return 0;

      return this.basketLineQuantity(this.selectedSize.sku);
    },
    shouldDisableButton() {
      if (!this?.selectedSize?.inventory) return true;

      const {
        quantityInBasket,
        selectedSize: {
          inventory: { allowNegativeStock, stock, stockStatus },
        },
      } = this;

      if (typeof stock === "number") {
        if (allowNegativeStock) {
          return false;
        } else {
          return stock === 0 || quantityInBasket >= stock;
        }
      } else {
        return stockStatus === stockStatuses.OUT_OF_STOCK;
      }
    },
  },
  methods: {
    ...mapActions("basket", ["UPDATE_BASKET_LINE_QUANTITY"]),
    addToBasket() {
      this.isLoading = true;

      this.UPDATE_BASKET_LINE_QUANTITY({
        previousQuantity: this.quantityInBasket,
        quantity: 1 + this.quantityInBasket,
        sku: this.selectedSize.sku,
      })
        .then(() => {
          this.isLoading = false;
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    closeSelector() {
      this.isOpen = false;
    },
    getSizeSpan(sizeGroup) {
      const first = this.getPreferredSizeChartValue(sizeGroup.sizes[0]);
      const last = this.getPreferredSizeChartValue(
        sizeGroup.sizes[sizeGroup.sizes.length - 1],
      );

      return `${first} - ${last}`;
    },
    isAccordionOpen(sizeGroup) {
      if (!this.selectedSize) return false;

      if (sizeGroup.sizes.some(size => size.sku === this.selectedSize.sku)) {
        return true;
      }

      return false;
    },
    openSelector() {
      this.isOpen = true;
    },
    selectSize(size) {
      this.handleSizeSelect(size);
      this.closeSelector();
    },
  },
};
</script>

<style lang="scss" scoped>
.size-selector {
  &__box {
    background-color: var(--color-card-background);
    bottom: 0;
    left: 0;
    overflow-y: auto;
    padding: 4.0625rem var(--spacing-container) var(--spacing-container);
    position: fixed;
    right: 0;
    top: 0;
    z-index: 2;

    @media (min-width: 48rem) {
      padding-top: 5.0625rem;
    }

    @media (min-width: 64rem) {
      height: calc(100vh - var(--size-header-fixed));
      left: calc(100vw - 2rem - 35rem);
      padding: 0;
    }

    @media (min-width: 110.625rem) {
      left: calc(100vw - (100vw - 77.75rem - 1rem));
    }

    @media (min-width: 122rem) {
      left: calc(
        77.75rem + (100vw - 120rem) / 2 - (var(--scrollbar-width) / 2)
      );
    }

    .has-service-bar & {
      @media (min-width: 64rem) {
        height: calc(
          100vh - var(--size-header-fixed) - var(--size-service-bar)
        );
      }
    }

    &-content {
      @media (min-width: 64rem) {
        width: calc(35rem + 1rem);
      }

      @media (min-width: 110.625rem) {
        width: calc(100vw - 77.75rem - 2rem);
      }

      @media (min-width: 122rem) {
        width: calc(120rem - 77.5rem - 1rem);
      }
    }
  }

  &__accordion-header {
    width: 100%;
    display: flex;
    gap: 1rem;
    font-size: 0.9375rem;
  }

  &__button-text {
    text-transform: uppercase;
    font-weight: 700;
  }

  &__close {
    @include reset-button();

    background-color: var(--color-body-background-light);
    box-shadow: 0px 1px 16px rgba(0, 0, 0, 0.12);
    border-radius: 50%;
    display: flex;
    align-items: center;
    flex-grow: 0;
    flex-shrink: 0;
    justify-content: center;
    height: calc(var(--size-product-action-icon) * 2.6);
    width: calc(var(--size-product-action-icon) * 2.6);

    &-icon {
      fill-rule: evenodd;
      fill: none;
      height: var(--size-product-action-icon);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
      stroke-width: 2;
      width: var(--size-product-action-icon);
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &__item {
    position: relative;

    &::before {
      background-color: var(--color-product-selected-size-background);
      border-radius: 2rem;
      bottom: 0;
      content: "";
      left: 0;
      opacity: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: opacity 300ms ease;
      z-index: -1;
    }

    &--selected {
      &::before {
        opacity: 1;
      }
    }

    &-action {
      @include reset-button();

      align-items: center;
      display: flex;
      justify-content: space-between;
      padding: 1rem 1.25rem;
      width: 100%;

      .size-selector__item--out-of-stock & {
        cursor: not-allowed;
      }
    }
  }

  &__list {
    @include reset-list();
  }

  &__selected {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    font-weight: 700;
  }

  &__size {
    font-weight: 700;

    .size-selector__item--out-of-stock & {
      font-weight: 400;
      opacity: 0.5;
      text-decoration: line-through;
    }
  }

  &__size-guide {
    display: flex;
    justify-content: flex-end;

    &-link {
      color: inherit;
      font-size: 0.9375rem;
    }
  }

  &__size-span {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
  }

  &__stock {
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
    display: flex;
    font-size: 0.8125rem;
    margin-left: var(--size-product-stock-icon);
    opacity: 0;
    text-transform: lowercase;
    transition: opacity 300ms ease;
    white-space: nowrap;

    .size-selector__item--external-product &,
    .size-selector__item--low-in-stock &,
    .size-selector__item--out-of-stock &,
    .size-selector__item--selected &,
    .size-selector__selected & {
      opacity: 1;
    }

    &-icon {
      display: none;
      fill: none;
      height: var(--size-product-stock-icon);
      margin-left: calc(var(--size-product-stock-icon) * 0.75);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 3;
      width: var(--size-product-stock-icon);

      .size-selector__item--selected & {
        display: block;
      }

      * {
        stroke: var(--color-text-primary);
      }
    }
  }

  &__title {
    flex: 1 1 auto;
    margin-right: var(--spacing-stack);
    min-width: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__underlay {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    z-index: 1;
  }
}
</style>
