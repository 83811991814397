import axios from "axios";
import { alertTypes } from "@/helpers/alertTypes";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentSalesperson: null,
  currentSalespersonId: null,
  isModalForCreatingSalespersonOpen: false,
  salespersons: [],
  selectedSalespersons: [],
  status: "",
});

const getters = {
  currentSalesperson: state => state.currentSalesperson,
  currentSalespersonId: state => state.currentSalespersonId,
  isModalForCreatingSalespersonOpen: state =>
    state.isModalForCreatingSalespersonOpen,
  nameOfCurrentSalesperson: state => {
    const { currentSalesperson } = state;
    if (!currentSalesperson) return null;

    return currentSalesperson.name ? currentSalesperson.name : null;
  },
  salespersons: state => state.salespersons,
  selectedSalespersons: state => state.selectedSalespersons,
  statusOfSalespersons: state => state.status,
};

const actions = {
  CLOSE_MODAL_FOR_CREATING_SALESPERSON({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_SALESPERSON", {
      value: false,
    });
  },
  CREATE_SALESPERSON({ commit, dispatch }, payload) {
    commit("SET_SALESPERSONS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/createSalesperson`, {
          ...payload.salesperson,
        })
        .then(({ data }) => {
          commit("SET_SALESPERSONS_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_SALESPERSON");
          resolve(data);
        })
        .catch(error => {
          commit("SET_SALESPERSONS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_SELECTED_SALESPERSONS({ commit, dispatch, state }) {
    commit("SET_SALESPERSONS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/users/delete-multiple`, {
        identifiers: state.selectedSalespersons.map(
          salesperson => salesperson.id,
        ),
      })
      .then(() => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_SALESPERSONS");
        commit("DESELECT_SALESPERSONS");
      })
      .catch(error => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SALESPERSON({ commit }, payload) {
    commit("SET_SALESPERSONS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/users/${payload.id}`)
      .then(({ data: salesperson }) => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.SUCCESS });

        commit("SET_CURRENT_SALESPERSON", {
          salesperson,
        });
      })
      .catch(error => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SALESPERSONS({ commit }) {
    commit("SET_SALESPERSONS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/users/listsystemroles?filterType=SalesPerson`,
      )
      .then(({ data }) => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SALESPERSONS", { salespersons: data });
      })
      .catch(error => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_CREATING_SALESPERSON({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_SALESPERSON", {
      value: true,
    });
  },
  REMOVE_SALESPERSON_IMAGE({ commit }, payload) {
    commit("SET_SALESPERSONS_STATUS", { status: statuses.SAVING });

    const { photoId, userId } = payload;

    return axios
      .delete(`${endpoints.ECOMMERCE}/users/${userId}/photo/${photoId}`)
      .then(() => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_SALESPERSON_IMAGE", { image: null });
      })
      .catch(error => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  TOGGLE_SALESPERSON({ commit }, payload) {
    commit("SET_SELECTED_SALESPERSON", payload);
  },
  TOGGLE_SALESPERSONS({ commit }, payload) {
    commit("SET_SELECTED_SALESPERSONS", payload);
  },
  UPDATE_CURRENT_SALESPERSON_ID({ commit }, payload) {
    commit("SET_CURRENT_SALESPERSON_ID", payload);
    commit("SET_SELECTED_SALESPERSONS");
  },
  UPDATE_SALESPERSON({ commit, dispatch }, payload) {
    commit("SET_SALESPERSONS_STATUS", { status: statuses.SAVING });
    const { id, salesperson } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/users/${id}`, salesperson)
      .then(({ data }) => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SALESPERSON", { salesperson: data });
        commit("SET_CURRENT_SALESPERSON", {
          salesperson: data,
        });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "Common.YourChangesWereSaved",
            type: alertTypes.SUCCESS,
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_SALESPERSON_IMAGE({ commit, getters }, payload) {
    commit("SET_SALESPERSONS_STATUS", { status: statuses.SAVING });

    const userId = getters["currentSalespersonId"];

    const formData = new FormData();
    formData.append("image", payload.file);

    return axios
      .post(`${endpoints.ECOMMERCE}/users/${userId}/photo`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_SALESPERSON_IMAGE", { image: data.id });
      })
      .catch(error => {
        commit("SET_SALESPERSONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  WELCOME_SELECTED_SALESPERSONS({ commit, dispatch, state }) {
    commit("SET_SALESPERSONS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/send-welcomemail`, {
          identifiers: state.selectedSalespersons.map(
            salesPerson => salesPerson.id,
          ),
        })
        .then(response => {
          commit("SET_SALESPERSONS_STATUS", { status: statuses.SUCCESS });
          commit("DESELECT_SALESPERSONS");
          dispatch("GET_SALESPERSONS");
          dispatch(
            "alerts/OPEN_ALERT",
            {
              key: "Administration.WelcomeEmailsSuccessfullySent",
              type: alertTypes.SUCCESS,
            },
            {
              root: true,
            },
          );
          resolve(response);
        })
        .catch(error => {
          commit("SET_SALESPERSONS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  DESELECT_SALESPERSONS(state) {
    state.selectedSalespersons = [];
  },
  SET_CURRENT_SALESPERSON(state, payload) {
    state.currentSalesperson = payload.salesperson;
  },
  SET_CURRENT_SALESPERSON_ID(state, payload) {
    state.currentSalespersonId = payload.id;
  },
  SET_CURRENT_SALESPERSON_IMAGE(state, payload) {
    const { currentSalesperson } = state;

    state.currentSalesperson = { ...currentSalesperson, photo: payload.image };
  },
  SET_SALESPERSON(state, payload) {
    const { salesperson } = payload;

    const salespersonExists = state.salespersons.find(
      currentSalesperson => currentSalesperson.id === salesperson.id,
    );

    if (salespersonExists) {
      const salespersons = [...state.salespersons];
      const indexOfMatchingSalesperson = salespersons.findIndex(
        currentSalesperson => currentSalesperson.id === salesperson.id,
      );

      salespersons[indexOfMatchingSalesperson] = salesperson;

      state.salespersons = salespersons;
    } else {
      state.salespersons.push(salesperson);
    }
  },
  SET_SALESPERSONS(state, payload) {
    state.salespersons = payload.salespersons;
  },
  SET_SALESPERSONS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_SELECTED_SALESPERSON(state, payload) {
    const { row: salesperson, value } = payload;

    if (value) {
      if (
        state.selectedSalespersons.some(entry => entry.id === salesperson.id)
      ) {
        return;
      }

      state.selectedSalespersons.push(salesperson);
    } else {
      state.selectedSalespersons = state.selectedSalespersons.filter(
        selectedSalesperson => selectedSalesperson.id !== salesperson.id,
      );
    }
  },
  SET_SELECTED_SALESPERSONS(state, payload) {
    if (!payload) {
      state.selectedSalespersons = [];
      return;
    }

    const { rows: salespersons, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedSalespersons.map(
          selectedSalesperson => selectedSalesperson.id,
        ),
      );

      state.selectedSalespersons = [
        ...state.selectedSalespersons,
        ...salespersons
          .filter(salesperson => !salesperson.disableSelection)
          .filter(salesperson => !identifiers.has(salesperson.id)),
      ];
    } else {
      state.selectedSalespersons = state.selectedSalespersons.filter(
        selectedSalesperson =>
          !salespersons.some(entry => entry.id === selectedSalesperson.id),
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_CREATING_SALESPERSON(state, payload) {
    state.isModalForCreatingSalespersonOpen = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
