<template>
  <div class="generation">
    <div class="generation__content">
      <Stack>
        <h3 class="generation__title">
          {{ getDictionaryEntry("ProductSheet.Generation.Title") }}
        </h3>
      </Stack>
      <Stack :half="true">
        <p class="generation__description">
          {{ getDictionaryEntry("ProductSheet.Generation.Description") }}
        </p>
      </Stack>
      <Stack :double="true">
        <Loader :large="true" :visible="true" />
      </Stack>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import Stack from "@/components/Stack";

export default {
  name: "CataloguesGeneration",
  components: {
    Loader,
    Stack,
  },
  methods: {
    createNew() {
      this.goToStep(3);
    },
    showCatalogues() {
      this.goToStep(2);
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width-wide: 43rem;

.generation {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 35rem;
  text-align: center;

  &__content {
    width: 100%;
  }

  &__description {
    font-size: 0.9375rem;
    line-height: 1.65;
    margin: 0;
  }

  &__title {
    font-family: var(--font-family-inter);
    font-size: 1.875rem;
    font-weight: 900;
    line-height: 1.13;
    margin: 0;
    text-transform: uppercase;

    @media (min-width: $sidebar-width-wide) {
      font-size: 2.125rem;
    }
  }
}
</style>
