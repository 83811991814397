<template>
  <div
    class="card"
    :class="{
      'card--expandable': expandable,
      'card--expanded': expanded,
      'card--no-spacing': noSpacing,
    }"
  >
    <div class="card__header">
      <div>
        <h2 v-if="title" class="card__title">
          {{ title }}
        </h2>
      </div>
      <div v-if="hasHeaderSlot" class="card__header-slot">
        <slot name="header" />
      </div>
    </div>
    <div
      ref="content"
      class="card__content"
      :aria-hidden="expandable ? !expanded : null"
      :class="{
        'card__content--expandable': expandable,
        'card__content--hide-overflow': hideOverflow,
        'card__content--fill-height': fillHeight,
        'card__content--with-title': title,
      }"
    >
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  name: "Card",
  props: {
    expandable: {
      default: false,
      type: Boolean,
    },
    expanded: {
      default: false,
      type: Boolean,
    },
    fillHeight: {
      default: false,
      type: Boolean,
    },
    noSpacing: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
      type: String,
    },
  },
  data() {
    return {
      hideOverflow: false,
    };
  },
  computed: {
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
  },
  watch: {
    expanded(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          slideDown(this.$refs.content, this.setOverflowToVisible);
        } else {
          this.setOverflowToHidden();
          slideUp(this.$refs.content);
        }
      }
    },
  },
  created() {
    this.hideOverflow = this.expandable;
  },
  mounted() {
    if (this.expanded) {
      slideDown(this.$refs.content, this.setOverflowToVisible);
    }
  },
  methods: {
    setOverflowToHidden() {
      this.hideOverflow = true;
    },
    setOverflowToVisible() {
      this.hideOverflow = false;
    },
  },
};
</script>

<style lang="scss">
.card {
  background-color: var(--color-card-background);
  border-radius: 0.25rem;
  box-shadow: var(--elevation-4);
  padding: var(--spacing-card);
  position: relative;

  @media (min-width: 48rem) {
    padding: var(--spacing-card-large);
  }

  .block--dashboard-rich-text &,
  .dashboard-cards & {
    padding: 0;
  }

  .dashboard & {
    border-radius: 0.625rem;
    box-shadow: 0px 3px 10px 0px rgba(0, 0, 0, 0.03);
  }

  .receipt & {
    border-radius: 0;
    box-shadow: none;
    padding: 0;
  }

  &--expandable {
    opacity: 0.5;
    transition: opacity 300ms ease;
  }

  &--expanded {
    opacity: 1;
  }

  &--no-spacing {
    padding: 0;
  }

  &__content {
    .card__header + & > *:first-child {
      margin-top: var(--spacing-stack) !important;

      @media print {
        margin-top: calc(var(--spacing-stack) * 0.5) !important;
      }

      &.connected-customers__table-container {
        margin-top: calc(var(--spacing-stack) * 0.75) !important;
      }

      &.yearly-revenue__card-highscore {
        margin-top: 0 !important;
      }
    }

    &:not(.card__content--with-title) > *:first-child {
      .block--dashboard-rich-text &,
      .card--no-spacing &,
      .dashboard-cards &,
      .order &,
      .yearly-revenue__card-highscore &,
      &.card__section {
        margin-top: 0 !important;
      }
    }

    .block--dashboard-rich-text &,
    .dashboard-cards & {
      flex: 1 1 auto;
      overflow-y: auto;
      padding: var(--spacing-card);

      @media (min-width: 48rem) {
        padding: var(--spacing-card-large);
      }
    }

    &--expandable {
      display: none;
    }

    &--hide-overflow {
      overflow: hidden;
    }

    &--fill-height {
      height: 100%;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    width: 100%;

    .block--dashboard-rich-text &,
    .dashboard-cards__block--rich-text & {
      flex: 0 0 auto;
      position: relative;
      padding: calc(var(--spacing-card) * 0.5) var(--spacing-card);

      @media (min-width: 48rem) {
        padding: calc(var(--spacing-card-large) * 0.5) var(--spacing-card-large);
      }

      &::after {
        background-color: rgba(0, 0, 0, 0.1);
        bottom: 0;
        content: "";
        height: 0.0625rem;
        left: 0;
        position: absolute;
        right: 0;
      }
    }

    &-slot {
      flex: 0 0 auto;
      margin-left: var(--spacing-card);

      @media (min-width: 48rem) {
        margin-left: var(--spacing-card-large);
      }
    }
  }

  &__section {
    &:not(:last-child) {
      padding-bottom: calc(var(--spacing-stack) * 2.25);
      position: relative;

      &::after {
        background-color: rgba(0, 0, 0, 0.1);
        bottom: calc(var(--spacing-stack));
        content: "";
        height: 0.0625rem;
        left: 0;
        margin-left: calc(var(--spacing-card) * -1);
        position: absolute;
        width: calc(100% + (var(--spacing-card) * 2));

        @media (min-width: 48rem) {
          margin-left: calc(var(--spacing-card-large) * -1);
          width: calc(100% + (var(--spacing-card-large) * 2));
        }
      }
    }
  }

  &__title {
    flex: 1 1 auto;
    font-size: 1.25rem;
    margin: 0;

    @media print {
      font-size: 0.875rem;
    }

    .block--dashboard-rich-text & {
      flex: 0 0 auto;
    }

    .connected-customers & {
      font-size: 0.875rem;
    }
  }
}
</style>
