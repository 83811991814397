<template>
  <section v-if="blockData" class="slider slider--center">
    <Container
      v-if="blockData.headline || blockData.description || blockData.cTA"
      :small="true"
    >
      <Heading
        v-if="blockData.headline"
        :tag="blockData.headlineTag"
        class="slider__title"
      >
        {{ blockData.headline }}
      </Heading>
      <p v-if="blockData.description" class="slider__description">
        {{ blockData.description }}
      </p>
      <div v-if="blockData.cTA" class="slider__action">
        <ConditionalRouterLink
          v-if="blockData.cTA && blockData.cTA.name && blockData.cTA.url"
          :target="blockData.cTA.target"
          :to="blockData.cTA.url"
          :type="blockData.cTA.type"
          class="slider__link"
          >{{ blockData.cTA.name }}</ConditionalRouterLink
        >
      </div>
    </Container>
    <Flicking :options="options" :plugins="plugins" class="slider__flicker">
      <div
        v-for="slide in blockData.linkedContent"
        :key="`${slide.headline}-${slide.url}`"
        class="slide"
      >
        <ImageFromUmbraco
          v-if="slide.image"
          :id="slide.image._system.id"
          :alt="slide.image.alternateText"
          :height="slide.image.umbracoHeight"
          :keys-and-sizes="{
            s: 'block-tall-medium',
            m: 'block-regular-medium',
            l: 'block-regular-medium',
            xl: 'block-regular-medium',
          }"
          :width="slide.image.umbracoWidth"
          class="block__image"
        />
        <article class="slide__content">
          <Heading
            v-if="slide.headline"
            :tag="slide.headlineTag"
            class="slide__title slide__title--small"
          >
            {{ slide.headline }}
          </Heading>
        </article>
        <ConditionalRouterLink
          v-if="slide.url"
          :aria-label="slide.headline"
          :to="slide.url"
          class="block__cover-link"
        ></ConditionalRouterLink>
      </div>
      <button
        slot="viewport"
        aria-label="previous"
        class="slider__navigation slider__navigation--prev"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="slider__navigation-icon"
          viewBox="0 0 24 24"
        >
          <defs />
          <path d="M19 12H5M12 19l-7-7 7-7" />
        </svg>
      </button>
      <button
        slot="viewport"
        aria-label="next"
        class="slider__navigation slider__navigation--next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="slider__navigation-icon"
          viewBox="0 0 24 24"
        >
          <defs />
          <path d="M5 12h14M12 5l7 7-7 7" />
        </svg>
      </button>
    </Flicking>
  </section>
</template>

<script>
import { Arrow } from "@egjs/flicking-plugins";
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "LinkedContentListGridElement",
  components: {
    ConditionalRouterLink,
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      options: {
        align: "prev",
        autoResize: true,
        bound: true,
        noPanelStyleOverride: true,
      },
      plugins: [
        new Arrow({
          disabledClass: "slider__navigation--disabled",
          nextElSelector: ".slider__navigation--next",
          prevElSelector: ".slider__navigation--prev",
        }),
      ],
    };
  },
};
</script>
