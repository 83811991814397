<template>
  <div></div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "Login",
  computed: {
    ...mapGetters("authentication", ["isUserAuthenticated"]),
  },
  created() {
    if (this.isUserAuthenticated) {
      this.$router.push({
        name: this.getStartPageForCurrentUser(),
      });
    } else {
      this.LOGIN();
    }
  },
  methods: {
    ...mapActions("authentication", ["LOGIN"]),
  },
};
</script>
