<template>
  <div class="tabs-container">
    <div v-if="internalSizeGroups.length" class="matrix-size-select-tabs">
      <div
        v-for="(group, index) in internalSizeGroups"
        :key="group.type"
        class="matrix-size-select-tabs--button"
        :class="{
          'matrix-size-select-tabs--button--active':
            activeSizeGroupIndex === index,
        }"
        @click="$emit('update:activeSizeGroupIndex', index)"
      >
        <span class="matrix-size-select-tabs--button--label">
          {{ group?.title ? getDictionaryEntry('SizeGroup.B2B.' + group.title) : group.title }}
          ({{ getTotalOfGroup(group) }})
        </span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'SizeTabSelector',
  props: {
    activeSizeGroupIndex: {
      default: 0,
      required: true,
      type: Number
    },
    internalSizeGroups: {
      required: true,
      type: Array
    }
  },
  methods: {
    getTotalOfGroup(group) {
      if (!group || !group.sizes || !group.sizes.length) return 0;

      let totalQuantity = 0;

      group.sizes.forEach(size => {
        totalQuantity += size.quantity || 0;
      });

      return totalQuantity;
    }
  }
};
</script>

<style lang="scss" scoped>
.tabs-container {
  display: inline-flex;
}

.matrix-size-select-tabs {
  border-radius: 0.25rem;
  border: 1px solid var(--color-neutral-15);
  padding: 0.25rem;
  height: 2.25rem;
  display: flex;
  align-items: stretch;

  &--button {
    background: var(--color-white);
    color: var(--color-black);
    cursor: pointer;
    height: 100%;
    padding: 0 0.75rem;
    justify-content: center;
    display: flex;
    align-items: center;
    border-radius: 0.25rem;

    &--label {
      font-family: var(--font-family-roboto);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 500;
      line-height: 100%; /* 0.875rem */
      word-wrap: normal;
    }

    &--active {
      background: #000;
      color: var(--color-white);
    }
  }
}
</style>
