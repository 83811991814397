<template>
  <div class="datepicker" :class="{ 'datepicker--error': hasError }">
    <label
      v-if="label"
      :for="id"
      class="datepicker__label"
      :required="required"
      >{{ label }}</label
    >
    <input
      v-model="internalValue"
      :disabled="disabled"
      :min="minDate"
      :required="required"
      class="datepicker__input"
      type="date"
      @blur="handleBlur"
    />
    <Error :message="errorMessage" :visible="hasError" />
  </div>
</template>

<script>
import { uuid } from "@/helpers/uuid";
import Error from "@/components/Error";

export default {
  name: "Datepicker",
  components: {
    Error,
  },
  props: {
    disabled: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    hasError: {
      default: false,
      type: Boolean,
    },
    label: {
      default: "",
      type: String,
    },
    minDate: {
      default: null,
      type: String,
    },
    required: {
      default: false,
      type: Boolean,
    },
    value: {
      default: null,
      type: [Date, String],
    },
  },
  data() {
    return {
      id: uuid(),
      internalValue: null,
    };
  },
  watch: {
    internalValue(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.internalValue = newValue;
      }
    },
  },
  created() {
    this.internalValue = this.value || null;
  },
  methods: {
    handleBlur() {
      const { internalValue } = this;

      if (internalValue && this.minDate) {
        const minimumDate = new Date(this.minDate);
        const currentDate = new Date(internalValue);

        if (currentDate <= minimumDate) {
          this.internalValue = this.minDate;
        }
      }

      this.$emit("blur", this.internalValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.datepicker {
  position: relative;
  text-transform: none;

  &--error {
    padding-bottom: var(--spacing-error-message);
  }

  &__input {
    appearance: none;
    background-color: var(--color-input-background);
    border: 1px solid var(--color-input-border);
    border-radius: 0.25rem;
    font-size: 1rem;
    height: 100%;
    margin: 0;
    min-height: 2.8125rem;
    padding: var(--spacing-input-field);
    position: relative;
    transition: background-color 300ms ease, border-color 300ms ease;
    width: 100%;
    z-index: 2;

    &:disabled {
      background-color: var(--color-input-background-disabled) !important;
      border-color: transparent !important;
      cursor: text;
    }

    &:focus {
      z-index: 3;
    }

    &:focus,
    &:hover {
      background-color: var(--color-input-background-active);
      border-color: var(--color-input-border-active);
      outline: none;
    }

    &::placeholder {
      opacity: 0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:-ms-input-placeholder {
      opacity: 0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &::-ms-input-placeholder {
      opacity: 0.5;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &-container {
      position: relative;
      align-items: center;
      display: flex;
    }

    .actions & {
      background-color: rgba(255, 255, 255, 0.15) !important;
      border-color: rgba(255, 255, 255, 0.3) !important;
      color: var(--color-table-actions-text) !important;
      font-size: 0.9375rem;

      &::placeholder {
        color: var(--color-table-actions-text);
        opacity: 0.75;
      }

      &:-ms-input-placeholder {
        color: var(--color-table-actions-text);
        opacity: 0.75;
      }

      &::-ms-input-placeholder {
        color: var(--color-table-actions-text);
        opacity: 0.75;
      }
    }

    .actions & {
      width: 13.125rem;
    }

    .basket-line-list & {
      @media (min-width: 90rem) {
        width: 13.125rem;
      }
    }

    .datepicker--error & {
      border-color: var(--color-error);
    }
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    &[required] {
      &::after {
        content: "*";
        margin-left: 0.125rem;
      }
    }
  }
}
</style>
