import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  permissionsForDealers: [],
  permissionsForEndCustomers: [],
  status: "",
});

const getters = {
  permissionsForDealers: state => state.permissionsForDealers,
  permissionsForEndCustomers: state => state.permissionsForEndCustomers,
  statusOfPermissions: state => state.status,
};

const actions = {
  GET_PERMISSIONS_FOR_DEALERS({ commit }) {
    commit("SET_PERMISSIONS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/permission/v2/dealer`)
      .then(({ data }) => {
        commit("SET_PERMISSIONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_PERMISSIONS_FOR_DEALERS", { permissions: data });
      })
      .catch(error => {
        commit("SET_PERMISSIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PERMISSIONS_FOR_END_CUSTOMERS({ commit }) {
    commit("SET_PERMISSIONS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/permission/v2/endCustomer`)
      .then(({ data }) => {
        commit("SET_PERMISSIONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_PERMISSIONS_FOR_END_CUSTOMERS", { permissions: data });
      })
      .catch(error => {
        commit("SET_PERMISSIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_PERMISSIONS_FOR_DEALERS(state, payload) {
    state.permissionsForDealers = Object.freeze(payload.permissions);
  },
  SET_PERMISSIONS_FOR_END_CUSTOMERS(state, payload) {
    state.permissionsForEndCustomers = Object.freeze(payload.permissions);
  },
  SET_PERMISSIONS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
