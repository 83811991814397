import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  balance: null,
  balances: [],
  currentTransactionId: null,
  isModalForUpdatingBalanceOpen: false,
  isModalForViewingTransactionDetailsOpen: false,
  pagination: {},
  renewalSettings: null,
  status: "",
  totalNumberOfTransactions: 0,
  transactions: [],
});

const getters = {
  balance: state => state.balance,
  balances: state => state.balances,
  currency: state => {
    const { balance, balances, transactions } = state;

    if (balance && balance.currency) return balance.currency;
    if (balances[0] && balances[0].currency) return balances[0].currency;
    if (transactions[0] && transactions[0].currency) {
      return transactions[0].currency;
    }

    return "";
  },
  currentPage: state => {
    if (!state.pagination) return 0;

    return state.pagination.currentPage || 0;
  },
  currentTransaction: state => {
    const matchingTransaction = state.transactions.find(
      transaction => transaction.id === state.currentTransactionId,
    );

    return matchingTransaction || null;
  },
  currentTransactionId: state => state.currentTransactionId,
  isModalForUpdatingBalanceOpen: state => state.isModalForUpdatingBalanceOpen,
  isModalForViewingTransactionDetailsOpen: state =>
    state.isModalForViewingTransactionDetailsOpen,
  renewalSettings: state => state.renewalSettings,
  statusOfWallets: state => state.status,
  totalNumberOfTransactions: state => {
    if (!state.pagination) return 0;

    return state.pagination.totalResults;
  },
  transactions: state => state.transactions,
};

const actions = {
  ADJUST_BALANCE_OF_WALLET({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.SAVING });

    const { amount, comment, userId } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/wallets/adjustBalance`, {
          amount,
          comment,
          userId,
        })
        .then(() => {
          commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
          resolve();
        })
        .catch(error => {
          commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  ADJUST_BALANCE_OF_WALLETS({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/wallets/adjustBalance-multiple`, payload)
        .then(() => {
          commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
          resolve();
        })
        .catch(error => {
          commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  CLOSE_MODAL_FOR_UPDATING_BALANCE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_UPDATING_BALANCE", { value: false });
    commit("SET_BALANCE", { balance: null });
  },
  GET_BALANCE_OF_WALLET({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/wallets/balance/${payload.id}`, {})
      .then(({ data }) => {
        commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
        commit("SET_BALANCE", { balance: data });
      })
      .catch(error => {
        commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_BALANCE_OF_WALLETS({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/wallets/balance-multiple-users`, payload)
      .then(({ data }) => {
        commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
        commit("SET_BALANCES", { balances: data });
      })
      .catch(error => {
        commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_TRANSACTIONS_OF_WALLET({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.LOADING });

    const { id, pageNumberZeroBased, pageSize } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/wallets/transactions/${id}`, {
        pageNumberZeroBased: pageNumberZeroBased || 0,
        pageSize: pageSize || 20,
      })
      .then(({ data: { documents, pagination } }) => {
        commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
        commit("SET_TRANSACTIONS", { transactions: documents });
        commit("SET_PAGINATION", { pagination });
      })
      .catch(error => {
        commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_WALLET_RENEWAL_SETTINGS({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/usergroups/${payload.id}/walletrenewalsettings`,
      )
      .then(({ data }) => {
        commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
        commit("SET_RENEWAL_SETTINGS", { renewalSettings: data });
      })
      .catch(error => {
        commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_UPDATING_BALANCE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_UPDATING_BALANCE", { value: true });
  },
  UPDATE_BALANCE_OF_WALLET({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.SAVING });

    const { amount, comment, userId } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/wallets/setBalance`, {
          amount,
          comment,
          userId,
        })
        .then(() => {
          commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
          resolve();
        })
        .catch(error => {
          commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  UPDATE_BALANCE_OF_WALLETS({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/wallets/setBalance-multiple`, payload)
        .then(() => {
          commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
          resolve();
        })
        .catch(error => {
          commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  UPDATE_CURRENT_TRANSACTION_ID({ commit }, payload) {
    commit("SET_CURRENT_TRANSACTION_ID", payload);
  },
  UPDATE_WALLET_RENEWAL_SETTINGS({ commit }, payload) {
    commit("SET_WALLETS_STATUS", { status: statuses.SAVING });
    const {
      automaticRefillEnabled,
      maxTransferAmount,
      nextRenewalDate,
      renewalAction,
      renewalAmount,
      renewalMonthInterval,
      userGroupId,
    } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/usergroups/${userGroupId}/walletrenewalsettings`,
        {
          automaticRefillEnabled,
          maxTransferAmount,
          nextRenewalDate,
          renewalAction,
          renewalAmount,
          renewalMonthInterval,
          userGroupId,
        },
      )
      .then(({ data }) => {
        commit("SET_WALLETS_STATUS", { status: statuses.SUCCESS });
        commit("SET_RENEWAL_SETTINGS", { renewalSettings: data });
      })
      .catch(error => {
        commit("SET_WALLETS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_BALANCE(state, payload) {
    state.balance = payload.balance;
  },
  SET_BALANCES(state, payload) {
    state.balances = payload.balances;
  },
  SET_CURRENT_TRANSACTION_ID(state, payload) {
    state.currentTransactionId = payload.transactionId;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload.pagination;
  },
  SET_RENEWAL_SETTINGS(state, payload) {
    state.renewalSettings = payload.renewalSettings;
  },
  SET_STATE_OF_MODAL_FOR_UPDATING_BALANCE(state, payload) {
    state.isModalForUpdatingBalanceOpen = payload.value;
  },
  SET_TRANSACTIONS(state, payload) {
    state.transactions = payload.transactions;
  },
  SET_WALLETS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
