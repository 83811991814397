<template>
  <Modal
    v-if="visible"
    :required="true"
    :title="getDictionaryEntry('User.SelectPreferences')"
    :visible="visible"
    class="user-configurator"
  >
    <template #body>
      <Stack>
        <p class="user-configurator__description">
          {{ getDictionaryEntry("User.SelectPreferencesDescription") }}
        </p>
      </Stack>
      <Stack>
        <RadioGroup
          v-model="selectedLanguage"
          :label="getDictionaryEntry('Common.Labels.SiteLanguage')"
          :options="languages"
          name="preferred-language"
        />
      </Stack>
      <Stack v-if="!isUserSystemUser">
        <RadioGroup
          v-model="selectedSizeSystem"
          :label="getDictionaryEntry('Common.Labels.SizeType')"
          :options="sizeSystems"
          name="preferred-size-chart"
        />
      </Stack>
    </template>
    <template #footer>
      <Button
        :limited-width="true"
        :show-loader="isLoading"
        @click="selectPreferences"
        >{{ getDictionaryEntry("Common.SaveChanges") }}</Button
      >
    </template>
  </Modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import Modal from "@/components/Modal";
import RadioGroup from "@/components/RadioGroup";
import Stack from "@/components/Stack";
import { statuses } from "@/helpers/statuses";

export default {
  name: "UserConfigurator",
  components: {
    Button,
    Modal,
    RadioGroup,
    Stack,
  },
  props: {
    defaultLanguage: {
      default: "da",
      type: String,
    },
    defaultSizeChart: {
      default: "da",
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      selectedLanguage: {},
      selectedSizeSystem: {},
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "isUserSystemUser",
      "userConfiguration",
      "userId",
      "statusOfAuthentication",
    ]),
    ...mapGetters("profile", ["statusOfProfile"]),
    ...mapGetters("shopAsUser", ["shopAsUserId"]),
    ...mapGetters("siteSettings", ["languages", "sizeSystems"]),
    ...mapGetters("users", ["statusOfUsers"]),

    isLoading() {
      const { SAVING } = statuses;

      return (
        this.statusOfAuthentication === SAVING ||
        this.statusOfProfile === SAVING ||
        this.statusOfUsers === SAVING
      );
    },
  },
  watch: {
    languages(newValue) {
      if (newValue) {
        this.setDefaultLanguage();
      }
    },
    sizeSystems(newValue) {
      if (newValue) {
        this.setDefaultSizeSystem();
      }
    },
  },
  created() {
    this.setDefaultLanguage();
    this.setDefaultSizeSystem();
  },
  methods: {
    ...mapActions("authentication", [
      "GET_USER_CONFIGURATION",
      "UPDATE_CURRENT_LANGUAGE",
    ]),
    ...mapActions("profile", ["UPDATE_USERS_PROFILE"]),
    setDefaultLanguage() {
      const { defaultLanguage, languages, userConfiguration } = this;

      if (!languages || !languages.length) return;
      if (userConfiguration && userConfiguration.contextSiteLanguage) {
        this.selectedLanguage = userConfiguration.contextSiteLanguage;
        return;
      }

      const matchingLanguage = languages.find(
        language => language.value === defaultLanguage,
      );

      if (!matchingLanguage) return;

      this.selectedLanguage = matchingLanguage.value;
    },
    setDefaultSizeSystem() {
      const { sizeSystems, userConfiguration } = this;

      if (!sizeSystems || !sizeSystems.length) return;
      if (userConfiguration && userConfiguration.contextSizeChart) {
        this.selectedSizeSystem = userConfiguration.contextSizeChart;
        return;
      }

      this.selectedSizeSystem = sizeSystems[0].value;
    },
    selectPreferences() {
      const {
        GET_USER_CONFIGURATION,
        UPDATE_CURRENT_LANGUAGE,
        UPDATE_USERS_PROFILE,
        selectedLanguage,
        selectedSizeSystem,
        shopAsUserId,
        userId,
      } = this;

      UPDATE_USERS_PROFILE({
        id: shopAsUserId || userId,
        languageCode: selectedLanguage,
        sizeSystem: selectedSizeSystem,
      }).then(() => {
        UPDATE_CURRENT_LANGUAGE({ languageCode: selectedLanguage }).then(() => {
          GET_USER_CONFIGURATION();
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.user-configurator {
  &__description {
    margin: 0;
    white-space: pre-line;
  }
}
</style>
