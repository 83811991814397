import store from "@/store";
import { isString } from "@/helpers/string";

const defaultErrorKey = "ErrorMessage.Common.AnErrorOccured";

function dispatchAlert({ key }) {
  store.dispatch(
    "alerts/OPEN_ALERT",
    { key },
    {
      root: true,
    },
  );
}

export function errorAlert(error) {
  let key;

  const dictionaryEntries = store.getters["siteSettings/dictionaryEntries"];

  if (!error || !dictionaryEntries) return;

  const { response } = error;

  if (!response || !response.data) {
    key = "ErrorMessage.Common.AnErrorOccured";
  } else if (response.data.status === 401 || response.data.status === 403) {
    key = "ErrorMessage.Common.NoAccess";
  } else if (isString(response.data)) {
    key = response.data;
  } else if (response.data.errors) {
    key = Object.values(response.data.errors)[0];
  } else if (response.data.detail) {
    key = response.data.detail;
  } else {
    key = defaultErrorKey;
  }

  if (!isString(key)) {
    key = defaultErrorKey;
  }

  if (!dictionaryEntries[key]) {
    store
      .dispatch("siteSettings/GET_DICTIONARY_ENTRY", { key }, { root: true })
      .then(() => {
        dispatchAlert({ key });
      })
      .catch(() => {
        key = "ErrorMessage.Common.AnErrorOccured";
        if (!dictionaryEntries[key]) {
          store
            .dispatch(
              "siteSettings/GET_DICTIONARY_ENTRY",
              { key },
              { root: true },
            )
            .then(() => {
              dispatchAlert({ key });
            });

          return;
        }

        dispatchAlert({ key });
      });

    return;
  }

  dispatchAlert({ key });
}
