const state = () => ({
  images: [],
  isImageGalleryOpen: false,
  selectedImage: {},
});

const getters = {
  images: state => state.images,
  isImageGalleryOpen: state => state.isImageGalleryOpen,
  selectedImage: state => state.selectedImage,
};

const actions = {
  CLOSE_IMAGE_GALLERY({ commit }) {
    commit("SET_STATE_OF_IMAGE_GALLERY", { value: false });
  },
  OPEN_IMAGE_GALLERY({ commit }) {
    commit("SET_STATE_OF_IMAGE_GALLERY", { value: true });
  },
  SELECT_IMAGE({ commit }, payload) {
    commit("SET_SELECTED_IMAGE", { image: payload.image });
  },
  SELECT_NEXT_IMAGE({ commit, state }) {
    const { images, selectedImage } = state;

    const indexOfSelectedImage = images.findIndex(
      image => image.src === selectedImage.src,
    );

    let image;

    if (indexOfSelectedImage === -1) return;
    if (indexOfSelectedImage === images.length - 1) {
      image = images[0];
    } else {
      image = images[indexOfSelectedImage + 1];
    }

    commit("SET_SELECTED_IMAGE", { image });
  },
  SELECT_PREVIOUS_IMAGE({ commit, state }) {
    const { images, selectedImage } = state;

    const indexOfSelectedImage = images.findIndex(
      image => image.src === selectedImage.src,
    );

    let image;

    if (indexOfSelectedImage === -1) return;
    if (indexOfSelectedImage === 0) {
      image = images[images.length - 1];
    } else {
      image = images[indexOfSelectedImage - 1];
    }

    commit("SET_SELECTED_IMAGE", { image });
  },
  UPDATE_IMAGES({ commit }, payload) {
    commit("SET_IMAGES", { images: payload.images });
  },
};

const mutations = {
  SET_IMAGES(state, payload) {
    state.images = payload.images;
  },
  SET_SELECTED_IMAGE(state, payload) {
    state.selectedImage = payload.image;
  },
  SET_STATE_OF_IMAGE_GALLERY(state, payload) {
    state.isImageGalleryOpen = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
