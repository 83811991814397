/**
 * Check if an element has horizontal scroll.
 * This function determines whether the specified element has a horizontal scrollbar by comparing its scroll width to its client width.
 *
 * @param {HTMLElement} el - The DOM element to check for horizontal scroll.
 * @returns {boolean} True if the element has horizontal scroll, false otherwise.
 */
export const hasHorizontalScroll = el => el.scrollWidth > el.clientWidth;

/**
 * Check if an element is scrolled to the right.
 * This function checks whether the specified element is scrolled to the right by comparing its scroll position and client width
 * with its scroll width, considering an optional threshold.
 *
 * @param {HTMLElement} el - The DOM element to check.
 * @param {number} [threshold=50] - The threshold value to consider when determining if the element is scrolled to the right.
 * @returns {boolean} True if the element is scrolled to the right, false otherwise.
 */
export const checkIfIsScrolledToRight = (el, threshold = 50) => {
  return el.scrollLeft + el.clientWidth + threshold >= el.scrollWidth;
};
