<template>
  <div v-if="blockData" class="block block--split-hero">
    <div v-if="blockData.image" class="block__media-container">
      <ImageFromUmbraco
        v-if="blockData.image"
        :id="blockData.image._system.id"
        :alt="blockData.image.alternateText"
        :height="blockData.image.umbracoHeight"
        :keys-and-sizes="{
          s: 'block-wide-small',
          m: 'block-regular-medium',
          l: 'block-regular-medium',
          xl: 'block-regular-large',
        }"
        :width="blockData.image.umbracoWidth"
        class="block__image"
      />
    </div>
    <Container :medium="true">
      <div class="block__content block__content--middle">
        <div>
          <Heading
            v-if="blockData.headline"
            :tag="blockData.headlineTag"
            class="block__title"
          >
            {{ blockData.headline }}
          </Heading>
          <p v-if="blockData.description" class="block__description">
            {{ blockData.description }}
          </p>
        </div>
      </div>
    </Container>
  </div>
</template>

<script>
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "ProductListHeroGridElement",
  components: {
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>
