var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"dropdown",class:{
    'dropdown--borderless': !_vm.hasBorder,
    'dropdown--error': _vm.hasError,
    'dropdown--labeled': _vm.labelText,
  }},[(_vm.labelText)?_c('label',_vm._b({staticClass:"dropdown__label",attrs:{"for":_vm.id}},'label',_vm.$attrs,false),[_vm._v(_vm._s(_vm.labelText))]):_vm._e(),_c('Multiselect',_vm._b({ref:"multiselect",attrs:{"id":_vm.id,"max-height":280,"options":_vm.optionsWithAbilityToClear,"show-labels":false,"track-by":"value"},on:{"close":function($event){return _vm.$emit('close', $event)},"open":function($event){return _vm.handleOpen($event)},"select":function($event){return _vm.$emit('select', $event)}},scopedSlots:_vm._u([{key:"selection",fn:function({ isOpen, values }){return (_vm.multiple)?[(values.length)?_c('span',[(!isOpen)?_c('span',{staticClass:"multiselect__single"},[_vm._v(_vm._s(values.length)+" "+_vm._s(values.length === 1 ? _vm.getDictionaryEntry( "Common.Dropdown.SelectedSingular", ).toLowerCase() : _vm.getDictionaryEntry( "Common.Dropdown.SelectedPlural", ).toLowerCase()))]):_vm._e()]):_vm._e()]:undefined}},{key:"option",fn:function({ option }){return [_c('div',{staticClass:"dropdown__option"},[_c('div',{staticClass:"dropdown__option-text",class:{
            'dropdown__option-text--has-parent':
              _vm.showParentSpace && option.parentId,
            'dropdown__option-text--is-parent':
              _vm.showParentSpace && !option.parentId,
          }},[_vm._v(" "+_vm._s(option.label)+" ")]),_c('svg',{staticClass:"dropdown__option-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M20 6 9 17l-5-5"}})])])]}}],null,true),model:{value:(_vm.internalValue),callback:function ($$v) {_vm.internalValue=$$v},expression:"internalValue"}},'Multiselect',{ ..._vm.$attrs, ..._vm.$props },false),[_c('template',{slot:"caret"},[_c('svg',{staticClass:"multiselect__icon",attrs:{"viewBox":"0 0 20 20"}},[_c('path',{attrs:{"fill-rule":"evenodd","d":"M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z","clip-rule":"evenodd"}})])]),_c('span',{attrs:{"slot":"noResult"},slot:"noResult"},[_vm._v(_vm._s(_vm.getDictionaryEntry("Common.Dropdown.NoMatchingResults")))]),_c('span',{attrs:{"slot":"noOptions"},slot:"noOptions"},[_vm._v(_vm._s(_vm.getDictionaryEntry("Common.Dropdown.NoOptions")))])],2),_c('Error',{attrs:{"message":_vm.errorMessage,"visible":_vm.hasError}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }