import { SortDirection } from "@/models/sortDirection";

export const ProductSortParam = {
  Number: "Number",
  Name: "Name",
  CreationDate: "CreationDate",
};

export const ProductSortOptions = {
  id: "sort",
  name: "ProductFilters.Names.Sort",
  values: [
    {
      value: ProductSortParam.CreationDate,
      direction: SortDirection.Desc,
      label: "ProductFilters.Names.SortByMostRecent",
      isSelected: false,
    },
    {
      value: ProductSortParam.Number,
      direction: SortDirection.Asc,
      label: "ProductFilters.Names.SortByNumberAsc",
      isSelected: false,
    },
    {
      value: ProductSortParam.Number,
      direction: SortDirection.Desc,
      label: "ProductFilters.Names.SortByNumberDesc",
      isSelected: false,
    },
    {
      value: ProductSortParam.Name,
      direction: SortDirection.Asc,
      label: "ProductFilters.Names.SortByNameAsc",
      isSelected: false,
    },
    {
      value: ProductSortParam.Name,
      direction: SortDirection.Desc,
      label: "ProductFilters.Names.SortByNameDesc",
      isSelected: false,
    },
  ],
};
