var render = function render(){var _vm=this,_c=_vm._self._c;return _c('DynamicData',{staticClass:"basket-line-list",class:{ 'basket-line-list--read-only': _vm.readOnly },attrs:{"loading":_vm.isLoading || _vm.isUpdatingBasket}},[_c('Accordion',_vm._l((_vm.basketLinesGroupedByCategory),function(category,categoryKey,categoryIndex){return _c('AccordionItem',{key:categoryKey,scopedSlots:_vm._u([{key:"accordion-header",fn:function(){return [(category.length > 1 && _vm.shouldShowCheckbox)?_c('Checkbox',{staticClass:"product__checkbox",attrs:{"value":_vm.isAllVariantsInCategorySelected(categoryKey)},on:{"input":function($event){return _vm.toggleCategoryLines({
              value: $event,
              categoryKey,
              category,
            })}}}):_vm._e(),_c('span',{staticClass:"basket-line-list__category-title"},[_vm._v(_vm._s(categoryKey)+" ("+_vm._s(_vm.getTotalQuantityOfCategory(category))+")")])]},proxy:true},{key:"accordion-body",fn:function(){return [_c('transition-group',{staticClass:"basket-line-list__list",attrs:{"tag":"ul","name":"fade-slide","appear":""}},_vm._l((category),function(basketLine,index){return _c('li',{key:basketLine.id,staticClass:"basket-line-list__item",class:{
              'basket-line-list__item--has-checkbox-and-inputs': _vm.shouldShowCheckbox,
            },style:({ '--index': index })},[_c('article',[_c('div',{staticClass:"product"},[(_vm.shouldShowCheckbox)?_c('Checkbox',{staticClass:"product__checkbox",attrs:{"value":_vm.isVariantSelected(basketLine.id)},on:{"input":function($event){return _vm.toggleVariant({
                      id: basketLine.id,
                      value: $event,
                      categoryIndex,
                      categoryKey,
                    })}}}):_vm._e(),_c('div',{staticClass:"product__image"},[_c('BasketLineProductImage',{attrs:{"image":basketLine.image}})],1),_c('div',{staticClass:"product__information"},[_c('Stack',{attrs:{"quarter":true}},[_c('h1',{staticClass:"product__title"},[_vm._v(" "+_vm._s(basketLine.productName)+" ")])]),_c('Stack',{attrs:{"quarter":true}},[_c('InformationList',{attrs:{"information":{ id: basketLine.id }}})],1),(basketLine.swatch)?_c('Stack',{attrs:{"quarter":true}},[_c('BasketLineSwatch',{attrs:{"swatch":basketLine.swatch}})],1):_vm._e(),_c('Stack',{staticClass:"product__sizes-and-quantities product__sizes-and-quantities--desktop-only",attrs:{"half":true}},[_c('BasketLineSizes',{attrs:{"desktop-only":true,"sizes-with-quantities":basketLine.sizesWithQuantities}})],1)],1),_c('div',{staticClass:"product__sizes-and-quantities product__sizes-and-quantities--mobile-only"},[_c('BasketLineSizes',{attrs:{"mobile-only":true,"sizes-with-quantities":basketLine.sizesWithQuantities}})],1),(basketLine.totalPrice)?_c('div',{staticClass:"product__numbers"},[_c('Stack',[_c('div',{staticClass:"product__price"},[_c('div',{staticClass:"product__price product__price--sum"},[_vm._v(" "+_vm._s(_vm.formatPriceCurrency(basketLine.totalPrice))+" "+_vm._s(basketLine.currency)+" ")]),(
                          basketLine.totalPrice !==
                            basketLine.totalPriceBeforeDiscount
                        )?_c('div',{staticClass:"product__price product__price--previous"},[_vm._v(" "+_vm._s(_vm.formatPriceCurrency( basketLine.totalPriceBeforeDiscount, ))+" "+_vm._s(basketLine.currency)+" ")]):_vm._e()])]),(
                      !basketLine.customersToShopFor &&
                        !basketLine.customersToShopFor.length
                    )?_c('Stack',{attrs:{"half":true}},[_c('div',{staticClass:"product__price--discount"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.Labels.Discount"))+": "+_vm._s(basketLine.discountPercent)+"% ")])]):_vm._e(),_c('Stack',{attrs:{"half":true}},_vm._l((basketLine.pricesWithQuantities),function(unitPrice){return _c('div',{key:unitPrice,staticClass:"product__unit-with-price"},[_vm._v(" "+_vm._s(_vm.formatPriceCurrency(unitPrice))+" ")])}),0)],1):_vm._e(),(
                    !basketLine.customersToShopFor &&
                      !basketLine.customersToShopFor.length
                  )?_c('div',{staticClass:"product__actions"},[_c('router-link',{staticClass:"product__edit",attrs:{"to":_vm.linkForVariant(basketLine.productId, basketLine.id)}},[_c('svg',{staticClass:"product__icon product__icon--edit",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"}}),_c('path',{attrs:{"d":"M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"}})])]),_c('button',{staticClass:"product__remove",attrs:{"aria-label":_vm.interpolateString(
                        _vm.getDictionaryEntry(
                          'Checkout.RemoveProductFromBasket',
                        ),
                        ['productName'],
                        [basketLine.productName],
                      ),"label":_vm.interpolateString(
                        _vm.getDictionaryEntry(
                          'Checkout.RemoveProductFromBasket',
                        ),
                        ['productName'],
                        [basketLine.productName],
                      )},on:{"click":function($event){return _vm.REMOVE_VARIANT_FROM_BASKET({ basketLine })}}},[_c('svg',{staticClass:"product__icon product__icon--remove",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"M3 6h18M19 6v14a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2V6m3 0V4a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v2M10 11v6M14 11v6"}})])])],1):_vm._e(),(_vm.shouldShowDiscountInput)?_c('div',{staticClass:"product__inputs"},[(_vm.shouldShowDiscountInput)?_c('div',{staticClass:"product__input product__input--discount"},[_c('Input',{attrs:{"disabled":_vm.isUpdatingBasket,"hide-label":true,"label":_vm.getDictionaryEntry('Common.Labels.Discount'),"placeholder":_vm.getDictionaryEntry('Common.Placeholders.Discount'),"suffix":"%","type":"number","value":basketLine.discountPercent},on:{"blur":function($event){return _vm.handleDiscountChange(
                          $event.target.value,
                          basketLine.variantId,
                        )}}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"product__customers"},[_c('BasketLineCustomers',{attrs:{"basket-line":basketLine}})],1)],1)])])}),0)]},proxy:true}],null,true)})}),1),(_vm.shouldShowDatepicker && _vm.basketLines && _vm.basketLines.length)?_c('div',{staticClass:"basket-line-list__date"},[_c('Datepicker',{attrs:{"disabled":_vm.isUpdatingBasket,"error-message":_vm.getDictionaryEntry('ErrorMessage.Checkout.DeliveryDateRequired'),"has-error":!_vm.basketLines[0].requestedDeliveryDate,"min-date":_vm.dateForTomorrow,"required":true,"value":_vm.basketLines[0].requestedDeliveryDate},on:{"blur":function($event){return _vm.handleOrderDayChange($event)}}})],1):_vm._e(),(_vm.shouldShowCheckbox)?_c('BasketLineListActions',{attrs:{"deselect-variants":_vm.deselectVariants,"should-show-datepicker":false,"should-show-discount":_vm.shouldShowDiscountInput,"variants":_vm.selectedVariants}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }