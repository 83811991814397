<template>
  <ul v-if="information" class="information-list">
    <li v-for="item in list" :key="item" class="information-list__item">
      {{ item }}
    </li>
  </ul>
</template>

<script>
export default {
  name: "InformationList",
  props: {
    information: {
      required: true,
      type: Object,
    },
  },
  computed: {
    list() {
      const { brand, fit, id, gender, sku } = this.information;

      const list = [];

      if (sku) {
        list.push(sku);
      } else if (id) {
        list.push(id);
      }

      if (brand) list.push(brand);
      if (gender) list.push(gender);
      if (fit) list.push(fit);

      return list;
    },
  },
};
</script>

<style lang="scss" scoped>
.information-list {
  @include reset-list();

  align-items: center;
  display: flex;
  flex-flow: row wrap;

  &__item {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 500;
    text-transform: uppercase;
    word-break: break-word;

    .product-details & {
      font-size: 0.8125rem;
    }

    &:not(:last-child) {
      margin-right: 0.3rem;

      &::after {
        content: "/";
      }
    }
  }
}
</style>
