var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"images"},[_c('div',{staticClass:"images__slider"},[_c('div',{staticClass:"images__selected-container"},[_c('button',{staticClass:"images__action images__action--previous",attrs:{"aria-label":_vm.getDictionaryEntry('Common.Previous'),"disabled":_vm.images.length <= 1},on:{"click":_vm.SELECT_PREVIOUS_IMAGE}},[_c('svg',{staticClass:"images__action-icon",attrs:{"viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M19 12H5M12 19l-7-7 7-7"}})])]),_c('img',{staticClass:"images__selected",class:{
            'images__selected--gallery-available':
              _vm.numberOfImages && _vm.selectedImage.src,
          },attrs:{"src":_vm.numberOfImages && _vm.selectedImage.src
              ? _vm.selectedImage.src
              : _vm.productFallbackImage,"alt":_vm.numberOfImages && _vm.selectedImage.src ? _vm.selectedImage.alt : ''},on:{"click":function($event){_vm.numberOfImages && _vm.selectedImage.src
              ? _vm.openImageGallery(_vm.selectedImage)
              : null}}}),_c('button',{staticClass:"images__action images__action--next",attrs:{"aria-label":_vm.getDictionaryEntry('Common.Next'),"disabled":_vm.images.length <= 1},on:{"click":_vm.SELECT_NEXT_IMAGE}},[_c('svg',{staticClass:"images__action-icon",attrs:{"viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M5 12h14M12 5l7 7-7 7"}})])])]),_c('transition-group',{staticClass:"images__slider-list",attrs:{"appear":"","name":"fade-slide","tag":"ol"}},_vm._l((_vm.images),function(image,index){return _c('li',{key:image.thumbnail.src,staticClass:"images__slider-item",style:({ '--index': index })},[_c('div',{staticClass:"image__container",class:{
              'image__container--interactive': image.large,
              'image__container--selected':
                image.large.src === _vm.selectedImage.src,
            },on:{"click":function($event){return _vm.SELECT_IMAGE({ image: image.large })}}},[_c('img',{staticClass:"image",attrs:{"alt":image.thumbnail.alt,"height":image.thumbnail.height,"src":image.thumbnail.src || _vm.productFallbackImage,"width":image.thumbnail.width,"decoding":"async","loading":"lazy"}})])])}),0)],1),_c('div',{staticClass:"images__container",on:{"scroll":_vm.handleScroll}},[_c('transition-group',{staticClass:"images__list",attrs:{"appear":"","name":"fade-slide","tag":"ol"}},[_vm._l((_vm.images),function(image,index){return _c('li',{key:image.thumbnail.src,staticClass:"images__item",style:({ '--index': index })},[_c('div',{staticClass:"image__container",class:{
              'image__container--interactive': image.large,
            },on:{"click":function($event){image.large
                ? _vm.openImageGallery(image)
                : _vm.openImageGallery({
                    image: { alt: '', src: _vm.productFallbackImage },
                  })}}},[_c('img',{staticClass:"image",attrs:{"alt":image.thumbnail.alt,"height":image.thumbnail.height,"src":image.thumbnail.src || _vm.productFallbackImage,"width":image.thumbnail.width,"decoding":"async","loading":"lazy"}})])])}),_c('li',{key:"empty-1",staticClass:"images__item images__item--empty"}),_c('li',{key:"empty-2",staticClass:"images__item images__item--empty"}),_c('li',{key:"empty-3",staticClass:"images__item images__item--empty"})],2)],1),_c('div',{staticClass:"images__indicator-container"},[_c('div',{staticClass:"images__indicator"},[_c('div',{staticClass:"images__indicator-line",style:({
            left: `${_vm.leftValueForIndicator}%`,
            width: `${100 / _vm.numberOfImages}%`,
          })})])])]),(_vm.detailImages.length)?_c('div',{staticClass:"detail-images"},[_c('ol',{staticClass:"detail-images__list"},_vm._l((_vm.detailImages),function(image){return _c('li',{key:image.src,staticClass:"detail-images__item"},[_c('div',{staticClass:"detail-images__image-container"},[_c('img',{staticClass:"detail-images__image",attrs:{"alt":image.large.alt,"height":image.large.height,"src":image.large.src,"width":image.large.width,"decoding":"async","loading":"lazy"}})])])}),0)]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }