<template>
  <ul
    v-if="product"
    class="swatches"
    :class="{
      'swatches--round': round,
    }"
  >
    <li
      v-for="(variant, index) in variants"
      :key="'swatches_' + variant.id"
      class="swatch"
    >
      <div
        v-if="round"
        class="swatch__trigger--round"
        :class="{
          'swatch__trigger--round--active': activeVariantIndex === index,
        }"
        @click="$emit('variant-changed', index)"
      >
        <img
          :alt="variant.swatch.alt"
          :src="getImage(variant)"
          :title="variant.swatch.alt"
          class="swatch__image--round"
          decoding="async"
          loading="lazy"
        />
      </div>

      <router-link
        v-else
        class="swatch__trigger"
        :title="variant.alt"
        :to="detailsLink(product.id, variant.id)"
      >
        <img
          :alt="variant.swatch.alt"
          :src="getImage(variant)"
          :title="variant.swatch.alt"
          class="swatch__image"
          decoding="async"
          height="50"
          loading="lazy"
          width="50"
        />
      </router-link>
    </li>
  </ul>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Swatches",
  props: {
    product: {
      required: true,
      type: Object,
    },
    endCustomerQuickBuyMode: {
      type: Boolean,
      default: false,
    },
    round: {
      type: Boolean,
      default: false,
    },
    activeVariantIndex: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    ...mapGetters("branding", ["productFallbackImage"]),
    ...mapGetters("products", ["currentVariant"]),
    variants() {
      if (!this.product) return [];

      return this.product.variants;
    },
  },
  methods: {
    detailsLink(productId, variantId) {
      if (this.endCustomerQuickBuyMode) {
        return {
          query: {
            ...this.$route.query,
            mode: "end-customer-quick-buy",
            productId: productId,
            variantId: variantId,
          },
        };
      }

      return {
        query: {
          ...this.$route.query,
          mode: "details",
          productId,
          variantId,
        },
      };
    },
    getImage(variant) {
      if (!variant) return "";

      const { images, swatch } = variant;

      if (swatch && swatch.src) return swatch.src;

      if (!images || !images.length || !images[0].thumbnail) {
        return this.productFallbackImage;
      }

      return images[0].thumbnail.src || this.productFallbackImage;
    },
  },
};
</script>

<style lang="scss" scoped>
$variant-size: 4rem;
$variant-size-round: 1.25rem;
$variant-size-trigger-round: 2rem;

.swatch {
  &__image {
    display: block;
    height: 100%;
    left: 50%;
    max-height: $variant-size * 0.75 !important;
    max-width: $variant-size * 0.75 !important;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &--round {
      border-radius: 50%;
      height: $variant-size-round;
      width: $variant-size-round;
      border: 1px solid var(--color-neutral-15);
    }
  }

  &__trigger {
    align-items: center;
    display: flex;
    height: $variant-size;
    justify-content: center;
    position: relative;
    width: $variant-size;

    &--round {
      border-radius: 50%;
      height: $variant-size-trigger-round;
      width: $variant-size-trigger-round;
      align-items: center;
      justify-content: center;
      display: flex;
      border: 1px solid #dedede;
      cursor: pointer;

      &--active {
        border: 1px solid #000;
      }
    }

    &::before {
      border: 1px solid rgba(0, 0, 0, 0.1);
      bottom: 0;
      content: "";
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      transition: border-color 300ms ease;
    }

    &.router-link-exact-active {
      &::before {
        border-color: rgba(0, 0, 0, 0.75);
      }
    }
  }
}

.swatches {
  @include reset-list();

  display: flex;
  flex-flow: row wrap;
  gap: 0.625rem;

  &--round {
    gap: 0.5rem;
  }
}
</style>
