<template>
  <div v-if="swatch" class="swatch">
    <img
      v-if="swatch.src"
      :src="swatch.src"
      :alt="swatch.alt"
      class="swatch__image"
      height="50"
      width="50"
    />
    <span class="swatch__text">{{ swatch.alt }}</span>
  </div>
</template>

<script>
export default {
  name: "BasketLineSwatch",
  props: {
    swatch: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.swatch {
  align-items: center;
  display: flex;

  &__image {
    border-radius: calc(var(--size-product-swatch) * 0.2);
    height: var(--size-product-swatch);
    margin-right: calc(var(--size-product-swatch) * 0.35);
    width: var(--size-product-swatch);
  }

  &__text {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
