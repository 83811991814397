import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { keysForInitialBooleanFilterParams } from "@/helpers/keysForInitialBooleanFilterParams";
import { keysForInitialFacetFilterParams } from "@/helpers/keysForInitialFacetFilterParams";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  activeEditorContentKey: "",
  activeEditorLanguage: null,
  content: {},
  fingerprint: "",
  isModalForEditingContentOpen: false,
  navigation: [],
  pickup: null,
  selfManagedContent: {},
  status: "",
});

const getters = {
  activeEditorContentKey: state => state.activeEditorContentKey,
  activeEditorLanguage: state => state.activeEditorLanguage,
  content: state => state.content,
  customerService: (state, getters) => {
    if (!getters.content) return null;

    const {
      customerServiceEmail,
      customerServiceName,
      customerServicePhone,
      customerServiceText,
      customerServiceTitle,
    } = getters.content;

    if (
      !customerServiceEmail &&
      !customerServiceName &&
      !customerServicePhone &&
      !customerServiceText &&
      !customerServiceTitle
    ) {
      return null;
    }

    return {
      email: customerServiceEmail,
      name: customerServiceName,
      phone: customerServicePhone,
      text: customerServiceText,
      title: customerServiceTitle,
    };
  },
  fingerprint: state => state.fingerprint,
  initialBooleanFilterParams: (state, getters) => {
    if (!getters.content) return [];

    return Object.keys(getters.content)
      .filter(key => keysForInitialBooleanFilterParams.includes(key))
      .filter(key => getters.content[key])
      .map(key => ({ key }));
  },
  initialFacetFilterParams: (state, getters) => {
    if (!getters.content) return [];

    return Object.keys(getters.content)
      .filter(key => keysForInitialFacetFilterParams.includes(key))
      .map(key => ({
        key,
        values: getters.content[key],
      }));
  },
  isModalForEditingContentOpen: state => state.isModalForEditingContentOpen,
  isProductList: (state, getters) => {
    if (!getters.content || !getters.content._system) return false;

    return getters.content._system.contentType === "productList";
  },
  navigation: state => state.navigation,
  pickup: state => state.pickup,
  selfManagedContent: state => state.selfManagedContent,
  statusOfContent: state => state.status,
};

const actions = {
  CLOSE_MODAL_FOR_EDITING_CONTENT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_EDITING_CONTENT", { value: false });
    commit("SET_ACTIVE_EDITOR_CONTENT_KEY", { contentKey: null });
  },
  GET_CONTENT({ commit, rootGetters }, payload) {
    commit("SET_CONTENT_STATUS", {
      status: statuses.LOADING,
    });

    const contentRoot = rootGetters["branding/contentRoot"];

    return new Promise((resolve, reject) => {
      axios
        .get(
          `${endpoints.CONTENT}/content/${contentRoot}/url?url=${payload.url}&urlPrefixSegment=/shop/`,
        )
        .then(({ data: { data } }) => {
          commit("SET_CONTENT_STATUS", {
            status: statuses.SUCCESS,
          });
          commit("SET_CONTENT", { content: data });
          commit("SET_FINGERPRINT", { fingerprint: data._system.Fingerprint });
          resolve(data);
        })
        .catch(error => {
          commit("SET_CONTENT_STATUS", {
            status: statuses.FAILURE,
          });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_NAVIGATION({ commit, rootGetters }) {
    commit("SET_CONTENT_STATUS", { status: statuses.LOADING });

    const contentRoot = rootGetters["branding/contentRoot"];

    return axios
      .get(
        `${endpoints.ECOMMERCE}/navigation/${contentRoot}/10?urlPrefixSegment=/shop/`,
      )
      .then(({ data }) => {
        commit("SET_CONTENT_STATUS", { status: statuses.SUCCESS });
        commit("SET_NAVIGATION", { navigation: data });
      })
      .catch(error => {
        commit("SET_CONTENT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PICKUP({ commit }) {
    const pickup = {
      city: "Odense SV",
      countryCode: "DK",
      id: "99588",
      name: "Meny Mågebakken",
      openingHours: [
        {
          dayOfWeek: "Common.Weekday.Monday",
          openFrom: "07:00",
          openTo: "21:00",
        },
        {
          dayOfWeek: "Common.Weekday.Tuesday",
          openFrom: "07:00",
          openTo: "21:00",
        },
        {
          dayOfWeek: "Common.Weekday.Wednesday",
          openFrom: "07:00",
          openTo: "21:00",
        },
        {
          dayOfWeek: "Common.Weekday.Thursday",
          openFrom: "07:00",
          openTo: "21:00",
        },
        {
          dayOfWeek: "Common.Weekday.Friday",
          openFrom: "07:00",
          openTo: "21:00",
        },
        {
          dayOfWeek: "Common.Weekday.Saturday",
          openFrom: "07:00",
          openTo: "21:00",
        },
        {
          dayOfWeek: "Common.Weekday.Sunday",
          openFrom: "07:00",
          openTo: "21:00",
        },
      ],
      position: { lat: 55.3655, lng: 10.3433 },
      postalCode: "5250",
      street: "Mågebakken 4-6",
    };

    commit("SET_PICKUP", { pickup });
  },
  GET_SELF_MANAGED_CONTENT({ commit, rootGetters }, payload) {
    commit("SET_CONTENT_STATUS", { status: statuses.LOADING });

    const siteLanguage = rootGetters["authentication/siteLanguage"];

    const language = payload.language || siteLanguage;
    const contentKey = `${payload.contentKey}-${language}`;

    if (payload.language) {
      commit("SET_ACTIVE_EDITOR_LANGUAGE", { language: payload.language });
    }

    return axios
      .get(
        `${endpoints.ECOMMERCE}/dashboard/selfManagedContent?contentKey=${contentKey}`,
      )
      .then(({ data }) => {
        commit("SET_CONTENT_STATUS", { status: statuses.SUCCESS });
        commit("SET_SELF_MANAGED_CONTENT", {
          content: data.content,
          contentKey,
        });
      })
      .catch(error => {
        commit("SET_CONTENT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SELF_MANAGED_CONTENT_FOR_ALL_LANGUAGES({ commit, rootGetters }, payload) {
    commit("SET_CONTENT_STATUS", { status: statuses.LOADING });

    const { contentKey } = payload;
    const languageCodes = rootGetters["siteSettings/languageCodes"];
    const requests = languageCodes.map(languageCode =>
      axios.get(
        `${endpoints.ECOMMERCE}/dashboard/selfManagedContent?contentKey=${contentKey}-${languageCode}`,
      ),
    );

    return axios
      .all(requests)
      .then(
        axios.spread((...responses) => {
          commit("SET_CONTENT_STATUS", { status: statuses.SUCCESS });

          responses.map(({ data: { content, contentKey } }) =>
            commit("SET_SELF_MANAGED_CONTENT", {
              content,
              contentKey,
            }),
          );
        }),
      )
      .catch(error => {
        commit("SET_CONTENT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_EDITING_CONTENT({ commit }, payload) {
    const { contentKey, language } = payload;

    commit("SET_ACTIVE_EDITOR_CONTENT_KEY", { contentKey });
    commit("SET_ACTIVE_EDITOR_LANGUAGE", { language });
    commit("SET_STATE_OF_MODAL_FOR_EDITING_CONTENT", { value: true });
  },
  RESET_CONTENT({ commit }) {
    commit("SET_CONTENT", { content: {} });
  },
  UPDATE_SELF_MANAGED_CONTENT({ commit, state }, payload) {
    commit("SET_CONTENT_STATUS", { status: statuses.SAVING });

    const contentKey = `${state.activeEditorContentKey}-${state.activeEditorLanguage}`;

    return axios
      .post(`${endpoints.ECOMMERCE}/dashboard/selfManagedContent`, {
        content: payload.content,
        contentKey,
      })
      .then(({ data }) => {
        commit("SET_CONTENT_STATUS", { status: statuses.SUCCESS });
        commit("SET_SELF_MANAGED_CONTENT", {
          content: data.content,
          contentKey,
        });
      })
      .catch(error => {
        commit("SET_CONTENT_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_ACTIVE_EDITOR_CONTENT_KEY(state, payload) {
    state.activeEditorContentKey = payload.contentKey;
  },
  SET_ACTIVE_EDITOR_LANGUAGE(state, payload) {
    state.activeEditorLanguage = payload.language;
  },
  SET_CONTENT(state, payload) {
    state.content = payload.content;
  },
  SET_CONTENT_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_FINGERPRINT(state, payload) {
    state.fingerprint = payload.fingerprint;
  },
  SET_NAVIGATION(state, payload) {
    state.navigation = payload.navigation;
  },
  SET_PICKUP(state, payload) {
    state.pickup = payload.pickup;
  },
  SET_SELF_MANAGED_CONTENT(state, payload) {
    const selfManagedContent = { ...state.selfManagedContent };

    selfManagedContent[payload.contentKey] = payload.content;

    state.selfManagedContent = selfManagedContent;
  },
  SET_STATE_OF_MODAL_FOR_EDITING_CONTENT(state, payload) {
    state.isModalForEditingContentOpen = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
