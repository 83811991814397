<template>
  <div
    v-if="
      isUserDealer &&
        amountUntilFreeFreight &&
        totalPrice &&
        basketLines.length > 0
    "
    class="information"
  >
    <p class="information__text">
      {{
        interpolateString(
          getDictionaryEntry("Checkout.BuyForAmountToGetFreeShipping"),
          ["amount", "currency"],
          [
            formatPriceCurrency(amountUntilFreeFreight),
            totalPrice.currency,
          ],
        )
      }}
    </p>
    <a
      v-if="showLink"
      class="information__link"
      :href="shopMoreLink ? `/#/shop${shopMoreLink}` : '/#/'"
      >{{ getDictionaryEntry("Receipt.ContinueShopping") }}</a
    >
  </div>
</template>

<script>
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "FreeFreightInformation",
  props: {
    showLink: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("authentication", ["isUserDealer", "userConfiguration"]),
    ...mapGetters("basket", ["basketLines", "totalPrice"]),
    ...mapGetters("branding", ["shopMoreLink"]),
    ...mapGetters("customers", ["currentCustomerForCheckout"]),
    ...mapGetters("shipping", ["amountUntilFreeFreight"]),
  },
  watch: {
    totalPrice: {
      handler(newValue, oldValue) {
        if (this.isUserDealer && !isEqual(newValue, oldValue)) {
          this.getFreightInformation();
        }
      },
      deep: true,
    },
  },
  created() {
    this.GET_CUSTOMER_FOR_CHECKOUT({
      id: this.userConfiguration.contextInternalCustomerId,
    }).then(() => {
      if (this.isUserDealer) {
        this.getFreightInformation();
      }
    });
  },
  methods: {
    ...mapActions("customers", ["GET_CUSTOMER_FOR_CHECKOUT"]),
    ...mapActions("shipping", ["GET_FREIGHT_INFO"]),
    getFreightInformation() {
      const { country, id } = this.currentCustomerForCheckout;

      this.GET_FREIGHT_INFO({
        country,
        customerId: id,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.information {
  background-color: rgba(37, 172, 37, 0.1);
  border: 1px solid rgba(37, 172, 37, 0.35);
  border-radius: 0.25rem;
  color: var(--color-status-indicator-green);
  font-size: 0.9375rem;
  line-height: 1.45;
  padding: 1rem;

  .checkout .summary & {
    margin-bottom: var(--spacing-stack);
  }

  .quick-basket & {
    margin: var(--spacing-sidebar) var(--spacing-sidebar) 0;

    @media (min-width: 64rem) {
      margin: var(--spacing-sidebar-large) var(--spacing-sidebar-large) 0;
    }
  }

  &__link {
    color: inherit;
  }

  &__text {
    margin: 0;

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }
}
</style>
