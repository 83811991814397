<template>
  <div class="introduction">
    <div class="introduction__content">
      <Stack>
        <h3 class="introduction__title">
          {{ textForTitle }}
        </h3>
      </Stack>
      <Stack :half="true">
        <p class="introduction__description">
          {{ textForDescription }}
        </p>
      </Stack>
      <Stack :one-and-half="true" class="introduction__actions">
        <Stack>
          <Button @click="createNew">{{
            getDictionaryEntry("ProductSheet.CreateNewCatalogue")
          }}</Button>
        </Stack>
        <Stack v-if="catalogues && catalogues.length" :half="true">
          <Button :secondary="true" @click="showCatalogues">{{
            getDictionaryEntry("ProductSheet.ChooseExistingCatalogue")
          }}</Button>
        </Stack>
      </Stack>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import Stack from "@/components/Stack";

export default {
  name: "CataloguesIntroduction",
  components: {
    Button,
    Stack,
  },
  props: {
    goToStep: {
      required: true,
      type: Function,
    },
  },
  computed: {
    ...mapGetters("catalogues", ["catalogues", "numberOfItemsInCatalogue"]),
    textForDescription() {
      const {
        getDictionaryEntry,
        interpolateString,
        numberOfItemsInCatalogue,
      } = this;

      if (numberOfItemsInCatalogue) {
        return interpolateString(
          getDictionaryEntry(
            "ProductSheet.Introduction.DescriptionWithSelectedProducts",
          ),
          ["numberOfItems"],
          [numberOfItemsInCatalogue],
        );
      }

      return getDictionaryEntry("ProductSheet.Introduction.Description");
    },
    textForTitle() {
      const {
        getDictionaryEntry,
        interpolateString,
        numberOfItemsInCatalogue,
      } = this;

      if (numberOfItemsInCatalogue) {
        return interpolateString(
          getDictionaryEntry(
            "ProductSheet.Introduction.TitleWithSelectedProducts",
          ),
          ["numberOfItems"],
          [numberOfItemsInCatalogue],
        );
      }

      return getDictionaryEntry("ProductSheet.Introduction.Title");
    },
  },
  methods: {
    ...mapActions("catalogues", ["UNLOAD_CATALOGUE"]),
    createNew() {
      this.UNLOAD_CATALOGUE();
      this.goToStep(3);
    },
    showCatalogues() {
      this.goToStep(2);
    },
  },
};
</script>

<style lang="scss" scoped>
$sidebar-width-wide: 43rem;

.introduction {
  align-items: center;
  display: flex;
  height: 100%;
  margin: 0 auto;
  max-width: 35rem;
  text-align: center;

  &__content {
    width: 100%;
  }

  &__actions {
    margin: 0 auto;
    max-width: 20rem;
  }

  &__description {
    font-size: 0.9375rem;
    line-height: 1.65;
    margin: 0;
  }

  &__title {
    font-family: var(--font-family-inter);
    font-size: 1.875rem;
    font-weight: 900;
    line-height: 1.13;
    margin: 0;
    text-transform: uppercase;

    @media (min-width: $sidebar-width-wide) {
      font-size: 2.125rem;
    }
  }
}
</style>
