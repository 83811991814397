import {
  environmentVariables
} from "@/helpers/environmentVariables";

const { apiGatewayEndpoint } = environmentVariables;

export const endpoints = {
  CONTENT: `${apiGatewayEndpoint}/cms-api`,
  ECOMMERCE: `${apiGatewayEndpoint}/ecommerce-api`,
  IDENTITY_SERVER: `${apiGatewayEndpoint}/ids-api`,
};