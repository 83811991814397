import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentExternalProduct: null,
  currentExternalProductId: null,
  currentExternalProductPrices: [],
  currentExternalVariants: [],
  externalProducts: [],
  images: [],
  isModalForCreatingExternalProductOpen: false,
  isModalForCreatingExternalVariantOpen: false,
  selectedExternalProducts: [],
  skus: [],
  status: "",
});

const getters = {
  currentExternalProduct: state => state.currentExternalProduct,
  currentExternalProductId: state => state.currentExternalProductId,
  currentExternalProductPrices: state => state.currentExternalProductPrices,
  currentExternalVariants: state => state.currentExternalVariants,
  externalProducts: state => state.externalProducts,
  imagesOfVariant: state => variantId => {
    const { images } = state;

    if (!images || !images.length) return [];

    const matchingVariantImages = images.find(
      image => image.variantId === variantId,
    );

    return matchingVariantImages ? matchingVariantImages.ids : [];
  },
  isModalForCreatingExternalProductOpen: state =>
    state.isModalForCreatingExternalProductOpen,
  isModalForCreatingExternalVariantOpen: state =>
    state.isModalForCreatingExternalVariantOpen,
  nameOfCurrentExternalProduct: state => {
    const { currentExternalProduct, currentExternalProductId } = state;

    if (!currentExternalProduct) return currentExternalProductId;

    return currentExternalProduct.name
      ? currentExternalProduct.name
      : currentExternalProductId;
  },
  selectedExternalProducts: state => state.selectedExternalProducts,
  statusOfExternalProducts: state => state.status,
};

const actions = {
  CLOSE_MODAL_FOR_CREATING_EXTERNAL_PRODUCT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_EXTERNAL_PRODUCT", {
      value: false,
    });
  },
  CLOSE_MODAL_FOR_CREATING_EXTERNAL_VARIANT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_EXTERNAL_PRODUCT_VARIANT", {
      value: false,
    });
  },
  CREATE_EXTERNAL_PRODUCT({ commit, dispatch }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SAVING });

    const { customerId, name, productCode } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/externalProducts/products`, {
          customerId,
          name,
          productCode,
        })
        .then(({ data }) => {
          commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_EXTERNAL_PRODUCT");
          resolve(data);
        })
        .catch(error => {
          commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  CREATE_EXTERNAL_VARIANT({ commit, dispatch }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SAVING });

    const { colorGroupIds, externalProductId, name, variantCode } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/externalProducts/variants`, {
          colorGroupIds,
          externalProductId,
          name,
          variantCode,
        })
        .then(({ data }) => {
          commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_EXTERNAL_VARIANT");
          resolve(data);
        })
        .catch(error => {
          commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_EXTERNAL_VARIANT({ commit, dispatch }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SAVING });

    const { externalProductId, variantId } = payload;

    return axios
      .delete(`${endpoints.ECOMMERCE}/externalProducts/variants/${variantId}`)
      .then(() => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_EXTERNAL_PRODUCT_AND_VARIANTS", { externalProductId });
      })
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  DELETE_SELECTED_EXTERNAL_PRODUCTS({ commit, dispatch, state }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.LOADING });

    const requests = state.selectedExternalProducts.map(externalProduct =>
      axios.delete(
        `${endpoints.ECOMMERCE}/externalProducts/${externalProduct.id}`,
      ),
    );

    return axios
      .all(requests)
      .then(() => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("DESELECT_EXTERNAL_PRODUCTS");
        dispatch("GET_EXTERNAL_PRODUCTS", { customerId: payload.customerId });
      })
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_EXTERNAL_PRODUCT_AND_VARIANTS({ commit, rootGetters }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.LOADING });

    const colorGroups = rootGetters["siteSettings/colorGroups"];

    let images;
    let inventory;
    let skus;
    let variants;

    return axios
      .get(
        `${endpoints.ECOMMERCE}/externalProducts/products/${payload.externalProductId}`,
      )
      .then(({ data }) => {
        commit("SET_CURRENT_EXTERNAL_PRODUCT", { externalProduct: data });

        return axios.get(
          `${endpoints.ECOMMERCE}/externalProducts/products/${payload.externalProductId}/variants`,
        );
      })
      .then(({ data }) => {
        variants = data;

        const imageRequests = variants.map(variant =>
          axios.get(
            `${endpoints.ECOMMERCE}/externalProducts/variants/${variant.id}/images`,
          ),
        );

        const inventoryRequests = variants.map(variant =>
          axios.get(
            `${endpoints.ECOMMERCE}/inventory/GetExternalProductInventory/${variant.id}`,
          ),
        );

        const skuRequests = variants.map(variant =>
          axios.get(
            `${endpoints.ECOMMERCE}/externalProducts/variants/${variant.id}/skus`,
          ),
        );

        return axios.all([
          ...imageRequests,
          ...inventoryRequests,
          ...skuRequests,
        ]);
      })
      .then(
        axios.spread((...responses) => {
          images = responses
            .slice(0, variants.length)
            .map(response => response.data);

          inventory = responses
            .slice(variants.length, variants.length * 2)
            .map(response => response.data)
            .flat();

          skus = responses
            .slice(variants.length * 2, variants.length * 3)
            .map(response => response.data)
            .flat();

          commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });

          const enrichedVariants = variants.map(variant => {
            const skusForVariant = skus
              .filter(sku => sku.externalVariantId === variant.id)
              .map(sku => {
                let quantity = null;

                const matchingInventory = inventory.find(
                  entry => entry.sku === sku.skuId,
                );

                if (matchingInventory) {
                  quantity = matchingInventory.quantity;
                }

                return {
                  ...sku,
                  quantity,
                };
              });

            //The groups are sorted alphabetically serverside based on "name"
            const sortOrderOfColorGroups = colorGroups.map(
              colorGroup => colorGroup.id,
            );

            return {
              ...variant,
              colorGroupIds: variant.colorGroupIds.sort(
                (a, b) =>
                  sortOrderOfColorGroups.indexOf(a) -
                  sortOrderOfColorGroups.indexOf(b),
              ),
              skus: skusForVariant,
            };
          });

          commit("SET_IMAGES_OF_EXTERNAL_PRODUCT_VARIANTS", {
            images: variants.map((variant, index) => ({
              ids: images[index],
              variantId: variant.variantId,
            })),
          });

          commit("SET_CURRENT_EXTERNAL_VARIANTS", {
            externalVariants: enrichedVariants,
          });
        }),
      )
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_EXTERNAL_PRODUCTS({ commit }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/externalProducts/customer/${payload.customerId}/products`,
      )
      .then(({ data }) => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_EXTERNAL_PRODUCTS", { externalProducts: data });
      })
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_EXTERNAL_PRODUCT_PRICES({ commit }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/price/getExternalProductRetailPrices/${payload.externalProductId}`,
      )
      .then(({ data }) => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_EXTERNAL_PRODUCT_PRICES", { prices: data });
      })
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_EXTERNAL_VARIANT({ commit, rootGetters }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.LOADING });

    const colorGroups = rootGetters["siteSettings/colorGroups"];

    //The groups are sorted alphabetically serverside based on "name"
    const sortOrderOfColorGroups = colorGroups.map(colorGroup => colorGroup.id);

    return axios
      .get(
        `${endpoints.ECOMMERCE}/externalProducts/variants/${payload.externalVariantId}`,
      )
      .then(({ data }) => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", {
          status: statuses.SUCCESS,
        });
        commit("SET_CURRENT_EXTERNAL_VARIANT", {
          externalVariant: {
            ...data,
            colorGroupIds: data.colorGroupIds.sort(
              (a, b) =>
                sortOrderOfColorGroups.indexOf(a) -
                sortOrderOfColorGroups.indexOf(b),
            ),
            skus: [],
          },
        });
      })
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_CREATING_EXTERNAL_PRODUCT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_EXTERNAL_PRODUCT", { value: true });
  },
  OPEN_MODAL_FOR_CREATING_EXTERNAL_VARIANT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_EXTERNAL_PRODUCT_VARIANT", {
      value: true,
    });
  },
  REMOVE_IMAGE_FROM_EXTERNAL_PRODUCT_VARIANT({ commit }, payload) {
    const { imageId, variantId, variantSystemId } = payload;

    return axios
      .delete(
        `${endpoints.ECOMMERCE}/externalProducts/variants/${variantSystemId}/images/${imageId}`,
      )
      .then(({ data }) => {
        commit("SET_IMAGES_OF_EXTERNAL_PRODUCT_VARIANT", {
          ids: data,
          variantId,
        });
      })
      .catch(error => {
        errorAlert(error);
      });
  },
  TOGGLE_EXTERNAL_PRODUCT({ commit }, payload) {
    commit("SET_SELECTED_EXTERNAL_PRODUCT", payload);
  },
  TOGGLE_EXTERNAL_PRODUCTS({ commit }, payload) {
    commit("SET_SELECTED_EXTERNAL_PRODUCTS", payload);
  },
  UPDATE_CURRENT_EXTERNAL_PRODUCT({ commit }, payload) {
    commit("SET_CURRENT_EXTERNAL_PRODUCT", payload);
  },
  UPDATE_CURRENT_EXTERNAL_PRODUCT_ID({ commit }, payload) {
    commit("SET_CURRENT_EXTERNAL_PRODUCT_ID", payload);
  },
  UPDATE_EXTERNAL_PRODUCT({ commit }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SAVING });

    const {
      categoryId,
      customerId,
      description,
      endCustomerSystemNumbers,
      externalProductId,
      fitId,
      genderId,
      name,
      productCode,
      productId,
      usps,
    } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/externalProducts/products/${externalProductId}`,
        {
          categoryId: Array.isArray(categoryId) ? null : categoryId,
          customerId,
          description,
          endCustomerSystemNumbers,
          fitId: Array.isArray(fitId) ? null : fitId,
          genderId: Array.isArray(genderId) ? null : genderId,
          id: externalProductId,
          name,
          productCode,
          productId,
          usps,
        },
      )
      .then(({ data }) => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_EXTERNAL_PRODUCT", { externalProduct: data });
      })
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_EXTERNAL_PRODUCT_PRICES({ commit, dispatch }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SAVING });

    const { externalProductId, prices } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/price/setExternalProductRetailPrices`, {
        externalProductId,
        prices,
      })
      .then(() => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_EXTERNAL_PRODUCT_PRICES", { externalProductId });
      })
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_EXTERNAL_VARIANT({ commit }, payload) {
    commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SAVING });

    const {
      colorGroupIds,
      externalProductId,
      id,
      name,
      skus,
      variantCode,
      variantId,
    } = payload;

    const updateSkus = axios.post(
      `${endpoints.ECOMMERCE}/externalProducts/skus`,
      {
        externalVariantId: id,
        sizeCodes: skus.length
          ? skus
              .sort((a, b) => a.orderNum - b.orderNum)
              .map(({ sizeCode, sizeCodeSystemId }) => {
                return {
                  sizeCode,
                  sizeCodeSystemId,
                };
              })
              .filter(sizeCodeSystemId => sizeCodeSystemId)
          : [],
      },
    );

    const updateVariant = axios.post(
      `${endpoints.ECOMMERCE}/externalProducts/variants/${id}`,
      {
        colorGroupIds,
        externalProductId,
        id,
        name,
        variantCode,
        variantId,
      },
    );

    let updatedSkus;
    let updatedVariant;

    return axios
      .all([updateVariant, updateSkus])
      .then(
        axios.spread((...responses) => {
          commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.SUCCESS });

          updatedSkus = responses[1].data;
          updatedVariant = responses[0].data;

          const updatedSkusWithQuantity = updatedSkus.map(updatedSku => {
            let quantity = null;

            const matchingSku = skus.find(sku => {
              if (!sku.skuId) {
                //When the SKU is being created we have to match using the name/size code
                //since there are no other unique identifiers and the size code isn't guaranteed
                //but we have to assume that the user doesn't duplicate names as it would ruin UX
                return sku.sizeCode === updatedSku.sizeCode;
              }

              return sku.skuId === updatedSku.skuId;
            });

            if (matchingSku) quantity = matchingSku.quantity;

            return {
              ...updatedSku,
              quantity,
            };
          });

          return axios.post(
            `${endpoints.ECOMMERCE}/inventory/SetExternalProductInventory`,
            {
              externalVariantId: id,
              skus: updatedSkusWithQuantity.map(({ quantity, skuId }) => ({
                sku: skuId,
                quantity: quantity === "" ? null : parseInt(quantity),
              })),
            },
          );
        }),
      )
      .then(({ data }) => {
        const inventory = data;

        const skusWithInventory = updatedSkus.map(sku => {
          let quantity = null;

          const matchingInventory = inventory.find(
            entry => entry.sku === sku.skuId,
          );

          if (matchingInventory) {
            quantity = matchingInventory.quantity;
          }

          return {
            ...sku,
            quantity,
          };
        });

        const externalVariant = { ...updatedVariant, skus: skusWithInventory };

        commit("SET_CURRENT_EXTERNAL_VARIANT", { externalVariant });
      })
      .catch(error => {
        commit("SET_EXTERNAL_PRODUCTS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_EXTERNAL_VARIANT_IMAGE({ commit }, payload) {
    const { file, variantId, variantSystemId } = payload;

    const formData = new FormData();
    formData.append("image", file);

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/externalProducts/variants/${variantSystemId}/images`,
          formData,
          { headers: { "Content-Type": "multipart/form-data" } },
        )
        .then(({ data }) => {
          commit("SET_IMAGES_OF_EXTERNAL_PRODUCT_VARIANT", {
            ids: data,
            variantId,
          });
          resolve();
        })
        .catch(error => {
          errorAlert(error);
          reject();
        });
    });
  },
};

const mutations = {
  DESELECT_EXTERNAL_PRODUCTS(state) {
    state.selectedExternalProducts = [];
  },
  SET_CURRENT_EXTERNAL_PRODUCT(state, payload) {
    state.currentExternalProduct = payload.externalProduct;
  },
  SET_CURRENT_EXTERNAL_PRODUCT_ID(state, payload) {
    state.currentExternalProductId = payload.externalProductId;
  },
  SET_CURRENT_EXTERNAL_VARIANT(state, payload) {
    const { externalVariant } = payload;

    const externalVariantExists = state.currentExternalVariants.find(
      currentProductVariant => currentProductVariant.id === externalVariant.id,
    );

    if (externalVariantExists) {
      const externalVariants = [...state.currentExternalVariants];
      const indexOfMatchingVariant = externalVariants.findIndex(
        productVariant => productVariant.id === externalVariant.id,
      );

      externalVariants[indexOfMatchingVariant] = externalVariant;

      state.currentExternalVariants = externalVariants;
    } else {
      state.currentExternalVariants.push(externalVariant);
    }
  },
  SET_CURRENT_EXTERNAL_VARIANTS(state, payload) {
    state.currentExternalVariants = payload.externalVariants;
  },
  SET_EXTERNAL_PRODUCTS(state, payload) {
    state.externalProducts = payload.externalProducts;
  },
  SET_EXTERNAL_PRODUCTS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_EXTERNAL_PRODUCT_PRICES(state, payload) {
    state.currentExternalProductPrices = payload.prices;
  },
  SET_IMAGES_OF_EXTERNAL_PRODUCT_VARIANT(state, payload) {
    const { ids, variantId } = payload;

    const indexOfMatchingVariant = state.images.findIndex(
      image => image.variantId === variantId,
    );

    const images = [...state.images];

    if (indexOfMatchingVariant === -1) {
      images.push({ ids, variantId });
    } else {
      images[indexOfMatchingVariant].ids = ids;
    }

    state.images = images;
  },
  SET_IMAGES_OF_EXTERNAL_PRODUCT_VARIANTS(state, payload) {
    state.images = payload.images;
  },
  SET_SELECTED_EXTERNAL_PRODUCT(state, payload) {
    const { row: externalProduct, value } = payload;

    if (value) {
      if (
        state.selectedExternalProducts.some(
          entry => entry.id === externalProduct.id,
        )
      ) {
        return;
      }

      state.selectedExternalProducts.push(externalProduct);
    } else {
      state.selectedExternalProducts = state.selectedExternalProducts.filter(
        selectedExternalProduct =>
          selectedExternalProduct.id !== externalProduct.id,
      );
    }
  },
  SET_SELECTED_EXTERNAL_PRODUCTS(state, payload) {
    const { rows: products, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedExternalProducts.map(
          selectedExternalProduct => selectedExternalProduct.id,
        ),
      );

      state.selectedExternalProducts = [
        ...state.selectedExternalProducts,
        ...products
          .filter(product => !product.disableSelection)
          .filter(externalProduct => !identifiers.has(externalProduct.id)),
      ];
    } else {
      state.selectedExternalProducts = state.selectedExternalProducts.filter(
        selectedExternalProduct =>
          !products.some(entry => entry.id === selectedExternalProduct.id),
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_CREATING_EXTERNAL_PRODUCT(state, payload) {
    state.isModalForCreatingExternalProductOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_CREATING_EXTERNAL_PRODUCT_VARIANT(state, payload) {
    state.isModalForCreatingExternalVariantOpen = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
