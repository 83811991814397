<template>
  <div class="information">
    <ul class="information__list">
      <li
        v-for="item in items"
        :key="item.id"
        class="information__item"
        :aria-expanded="item.isExpanded"
        :aria-labelledby="item.id"
        role="region"
      >
        <button
          class="information__toggle"
          :aria-label="
            item.isExpanded
              ? getDictionaryEntry('Common.Collapse')
              : getDictionaryEntry('Common.Expand')
          "
          @click="toggle(item)"
        >
          <div class="information__indicator">
            <svg
              v-if="item.id === 'compositions'"
              xmlns="http://www.w3.org/2000/svg"
              class="information__indicator-icon"
              viewBox="0 0 24 24"
            >
              <defs />
              <path
                d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"
              />
            </svg>
            <svg
              v-else-if="item.id === 'wash-care'"
              xmlns="http://www.w3.org/2000/svg"
              class="information__indicator-icon"
              viewBox="0 0 24 24"
            >
              <defs />
              <path d="M12 2.69l5.66 5.66a8 8 0 11-11.31 0z" />
            </svg>
            <svg
              v-else-if="item.id === 'features'"
              xmlns="http://www.w3.org/2000/svg"
              class="information__indicator-icon"
              viewBox="0 0 24 24"
            >
              <defs />
              <path d="M22 11.08V12a10 10 0 11-5.93-9.14" />
              <path d="M22 4L12 14.01l-3-3" />
            </svg>
            <svg
              v-else-if="item.id === 'certificates'"
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
              class="information__indicator-icon"
            >
              <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14" />
              <polyline points="22 4 12 14.01 9 11.01" />
            </svg>
            <svg
              v-else-if="item.id === 'certification-info'"
              xmlns="http://www.w3.org/2000/svg"
              class="information__indicator-icon"
              viewBox="0 0 512 512"
            >
              <path
                fill="#000"
                d="M248.5 499.6c-13.2-1.2-26.3-4.5-37.6-11.3-1.5-1-3.1-1.9-4.6-3.1-9.5-7-16.8-16.6-20.8-27.7-2.7-7.5-3.8-15.5-3.8-23.4.2-22.5 5.9-44 11.8-65.4 8.8-31.6 18.8-62.9 25.7-95 2.2-10.3 3.7-20.6 1.9-31.2-2.4-14.2-8.4-19.4-22.7-21.3-14.8-1.9-28.7 1.2-42.5 6-2 .7-3.9 1.8-6.7 1.5 2.1-8.5 7.6-27.7 9-28.3 21.7-8.2 43-17.3 66-21.5 22.8-4.2 45.3-3.7 66.7 6.4 23.1 11 35.1 35.1 33.8 60.7-1.6 32.1-12.4 61.8-20.4 92.3-5.9 22.1-13.3 43.7-17.2 66.3-1.7 10.4-3.5 20.6-1.5 31.3 1.8 10 7.4 16.1 17.3 18.5 13.3 3.2 26.5 1.9 39.6-1.7 5.2-1.4 10.3-3.2 16.1-5-2.2 9.1-8.2 28.2-9.3 28.7-8.1 3.1-16 6.2-24.1 9.3-3.7 1.4-7.5 2.6-11.1 4.1-3.6 1.5-7.4 2.9-11.1 4.1-15.1 4.8-31 6.9-46.8 6.3-2.7-.2-5.2-.4-7.7-.6zM313.3 11.7c6.4.9 12.8 2.6 18.6 5.4 8.3 3.9 15.6 9.8 21.2 17.1 5.5 7.1 9.3 15.5 10.9 24.3 1.7 9 1.2 18.3-1.8 27.1-.4 1.2-.9 2.3-1.2 3.5-8.7 21.4-31.3 35.8-56.4 36.1-26.8.3-51.3-16.3-59-40-2.8-8.4-3.4-17.6-1.7-26.4 1.6-8.6 5.3-16.9 10.6-23.9 5.4-7.2 12.5-13.1 20.5-17.1 10.4-5.2 22.6-7.3 34.1-6.4 1.4 0 2.7.1 4.2.3z"
              />
            </svg>
            <svg
              v-else-if="item.id === 'size-guide'"
              xmlns="http://www.w3.org/2000/svg"
              class="information__indicator-icon"
              viewBox="0 0 512 512"
            >
              <g
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                stroke="#000"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="17"
              >
                <ellipse cx="194.5" cy="196.5" rx="48.5" ry="23.4" />
                <ellipse
                  cx="194.5"
                  cy="194.2"
                  class="st0"
                  rx="180.1"
                  ry="79.4"
                />
                <path
                  d="M194.5 273.7H499v129.2H194.6s-180.2-4.3-180.2-75.8v-133M374.6 194.2v79.5M78.9 385v-65.7M154.4 395v-42.8M223.8 327.8v75.1M292.4 352.2v46.4M362.5 327.8v75.1M433.9 356.4v46.5"
                  class="st0"
                />
              </g>
            </svg>
            <svg
              v-else-if="item.id === 'logo-placement'"
              xmlns="http://www.w3.org/2000/svg"
              class="information__indicator-icon"
              viewBox="0 0 512 512"
            >
              <path
                fill="#000"
                d="M17.9 74.6c.4-6.2-.6-13.7.7-21.2 3.5-19.6 20-34.5 39.8-35.2 11.8-.4 23.7-.2 35.5-.1 5.6 0 9.1 3.5 9.2 8.4.1 5.2-3.5 8.5-9.5 8.6-10.5.1-21 0-31.5 0-16.3.1-27 10.9-27.1 27.4v31c0 6.1-3.1 9.7-8.3 9.8-5.4.1-8.7-3.5-8.7-9.9-.1-5.9-.1-11.7-.1-18.8zM439.3 18c6.2.4 13.7-.6 21.2.7 19.6 3.5 34.5 19.9 35.2 39.8.5 11.8.2 23.7.1 35.5 0 5.6-3.5 9.1-8.4 9.2-5.2.1-8.5-3.5-8.6-9.5-.1-10.5 0-21 0-31.5-.1-16.4-10.9-27.1-27.4-27.2h-31c-6.2 0-9.7-3.1-9.8-8.3-.1-5.4 3.5-8.7 9.9-8.7h18.8zM74.2 496c-6.1-.4-13.4.6-20.7-.7-20.1-3.6-34.9-20.3-35.4-40.7-.3-11.5-.2-23-.1-34.5 0-5.9 3.6-9.4 8.8-9.3 5 .1 8.2 3.6 8.2 9.3.1 10.5 0 21 0 31.5.1 16.5 10.8 27.2 27.2 27.3 10.3.1 20.7 0 31 0 6.4 0 10 3.3 9.9 8.6-.1 5.1-3.7 8.3-9.7 8.4-6 .1-12 .1-19.2.1zM495.9 439.3c-.4 6.2.6 13.7-.7 21.2-3.5 20.1-20.3 34.9-40.7 35.4-11.5.3-23 .2-34.5.1-5.9 0-9.5-3.6-9.3-8.8.1-5 3.6-8.2 9.3-8.2 10.5-.1 21 0 31.5 0 16.4-.1 27.2-10.8 27.3-27.2.1-10.3 0-20.7 0-31 0-6.4 3.3-10 8.6-9.9 5.1.1 8.3 3.7 8.4 9.7.1 5.7.1 11.5.1 18.7z"
              />
              <path
                fill="none"
                d="M163.3 111.9c-28.4-.1-51.3 22.6-51.5 50.8-.1 28.4 22.6 51.4 50.8 51.4 28.8.2 51.5-22.5 51.5-51.2 0-28-22.8-50.9-50.8-51zm-.2 85.3c-18.9 0-34.1-15-34.2-33.8-.1-18.9 15.3-34.4 34.3-34.4 18.8.1 33.9 15.3 33.9 34.1-.1 19-15 34-34 34.1z"
              />
              <path
                fill="#000"
                d="M315 316.4c1.5 1.8 3.6 3.7 6.4 5.3l13.6 7.5 8.6-12.8H315zM77.7 315.4c4.7 0 8.5-3.8 8.5-8.5V115.2c0-18.7 10.3-29 29.1-29h283.5c18.5 0 28.9 10.4 28.9 28.7v191.9c0 4.7 3.8 8.5 8.5 8.5s8.5-3.8 8.5-8.5V114.9c0-27.8-18-45.7-45.9-45.7H115.3c-17.5 0-27.9 6.8-33.6 12.5-5.7 5.7-12.5 16.1-12.5 33.5v191.6c0 4.8 3.9 8.6 8.5 8.6z"
              />
              <path
                fill="#000"
                d="M163 214.1c28.6 0 51.1-22.6 51.1-51.2 0-28-22.8-50.9-50.8-51h-.2c-28.3 0-51.1 22.7-51.3 50.8-.1 28.4 22.6 51.4 50.8 51.4h.4zm-34.2-51.3c.1-9.1 3.7-17.6 10.1-24 6.5-6.4 15.1-9.9 24.2-9.9h.1c18.7.1 33.9 15.3 33.9 34 0 19.2-15 34.2-34.1 34.2h-.5c-9 0-17.5-3.5-23.8-9.9-6.3-6.4-9.9-15.1-9.9-24.4zM170.4 316.4c.1.2.3.4.4.5l14.8 21.3 13.5-21.9h-28.7z"
              />
              <path
                fill="#000"
                d="M256.9 222.8c1.4 0 2.8 1.7 4.7 5 38.8 65 77.7 130 116.5 195 3.6 4.9-1.9 4.9-2.8 4.9h-237c-2.5.2-4.8-1.4-3.5-3.5 0 0 1.1-1.8 1.2-2 38.7-64.9 77.4-129.7 116.2-194.6 2-3.2 3.3-4.8 4.7-4.8m0-17c-11.4 0-17.1 9.5-19.2 13.1C196 288.6 158 352.3 121.4 413.5l-.1.2v.1c-.1.1-.1.2-.2.3-1.3 2-3.9 6.1-4.4 12.5 0 9.5 7.3 17.4 16.7 18.2h.2c1.8 0 3.5 0 5.3-.1 0 0 237.8.3 239.2.3 6.8 0 13-3.4 16.4-9.1 2-3.3 5.8-12.1-2-22.1-.1-.1-.1-.2-.2-.3-19.3-32.3-38.6-64.6-57.9-96.8l-58.2-97.5c-2.1-3.7-7.8-13.4-19.3-13.4z"
              />
              <path
                fill="#000"
                d="M340.5 321c5.2-7.3 10.2-14.8 15.3-22.2 1.4-2 2.5-3 3.6-3s2.2 1 3.5 2.9c18.6 27.1 37.2 54.3 55.8 81.4 3.3 4.8 10.3 4.9 13.7.2 0-.1.1-.1.1-.2 2.1-2.9 2.2-6.8.2-9.7-8.1-11.8-16.2-23.6-24.1-35.2-10.3-15.1-21-30.7-31.6-46-5.7-8.5-12.7-10.3-17.5-10.3-4.8 0-11.7 1.8-17.6 10.3l-5.1 7.5c-3.3 4.8-6.6 9.7-10 14.4-5.3 7.3-5.6 16.1-.9 24l.1.2c13.3 22.2 26.9 45 40.5 67.8 2.8 4.7 9.2 5.5 13.1 1.7l.2-.2c2.8-2.7 3.3-7 1.4-10.3-13.5-22.7-27-45.3-40.6-67.9-1.4-2.1-1.5-3.5-.1-5.4zM187.4 311.3l-.1-.1c-3.2-4.6-6.4-9.3-9.5-13.8-1.6-2.3-3.2-4.7-4.8-7-2.4-3.4-7.9-11.4-18.4-11.4-10.4 0-16.1 8.1-18.5 11.5v.2c-4.5 6.6-9.1 13.2-13.6 19.9-13.1 19.1-26.5 38.7-39.9 58.1-1.8 2.6-2 6-.5 8.7 0 .1.1.1.1.2 3 5.6 10.8 6 14.4.8 12.7-18.6 38.7-56.4 53.5-78.1 2-2.8 3.2-4.2 4.5-4.2 1.3 0 2.5 1.4 4.4 4.1 4.8 7 9.5 14 14.4 21 1.3 1.9 1.5 3.3.3 5.3-14.5 24-28.9 48.1-43.2 72.2-2.1 3.5-1.4 7.9 1.6 10.6l.1.1c3.9 3.5 10.1 2.5 12.8-2 15.2-25.5 29.4-49.3 43.2-72.2 4.6-7.8 4.3-16.4-.8-23.9z"
              />
            </svg>
            <svg
              v-else-if="item.id === 'declaration-of-conformity'"
              class="information__indicator-icon"
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
              viewBox="0 0 512 512"
            >
              <path
                d="M75 90h355M75 147h160M271 142h172M486 30H26v276h125.7c-.8-3.3-.6-6.7.8-9.9 4.2-9.4 15.5-12.8 20.9-21.3 6.3-9.9 4.3-22.6 5.6-33.7.7-5.4 1.9-11.7 5.7-15.9 4.2-4.5 11.3-4 17-4.1 4.3 0 9 .1 13.5-.2.5-.1.9-.2 1.4-.3 6.1-1.3 12-3.6 17.3-6.9 2.6-1.6 5.1-3.4 7.4-5.5 2.3-2 4.4-4.1 6.8-5.9 5-3.7 10.9-4.5 16.8-2.5 6 2.1 10.9 6.2 15.7 10.3 4.6 3.9 9.5 8.1 15.5 9.7 3.4.9 6.8.5 10.3.1 3-.3 6-.4 8.9.2 10.3 2.2 19 11.4 20.7 21.9.4 2.8.3 5.6-.2 8.4-.6 3.5-1.7 7-1.5 10.5.2 4.3 2.3 8.2 4.8 11.7 5.1 7 12.6 12.7 15.8 21 1.6 4 1.5 8.3-.1 12.3H486V30z"
                fill="none"
                stroke="#000"
                stroke-width="17"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              />
              <path
                d="M352.5 306.4c-2.6 5-7.3 8.6-11 12.8-4 4.5-7.1 9.9-9 15.7-2 5.9-2.6 12-2.2 18.2.4 6 1.5 12.8-2.2 18.1-3 4.3-8.2 6.1-13.1 6.9-5.5.9-11 .3-16.5.3-5.6-.1-11.4.3-16.4 3-5.3 2.9-7.7 8.4-12.2 12.2-7.4 6.2-18.8 6.9-27.2 2.4-2.1-1.1-4-2.5-5.6-4.2-2-2.1-3.4-4.6-4.9-7-2.9-4.9-6-9.5-11.4-11.8-5.1-2.2-10.8-2.1-16.3-1.7-3 .2-6 .6-9 .8-2.7.2-5.5.6-8.1 0-1.2-.3-2.5-.8-3.3-1.8-1.1-1.3-1.2-3.2-1-4.8.3-3 .9-5.8 1-8.8.1-5.9-1.2-11.7-3.5-17.1-2.5-5.6-6.2-10.4-10.8-14.5-4.9-4.4-10.4-8.2-13.9-14-1-1.6-1.7-3.3-2.1-5.1h-2.1c.5 2.2 1.5 4.4 2.7 6.4 3.5 5.7 9 9.6 14 14 4.8 4.3 8.6 9.3 11 15.4 2.2 5.7 3.1 11.9 2.6 18-.3 3-1.3 6.1-.6 9.1.6 2.6 2.7 4.3 5.3 5 2.7.7 5.5.5 8.2.3 3-.2 6-.6 9-.8 5.6-.5 11.8-.8 17 1.8 5.7 2.8 8.2 8.4 11.5 13.5 2.8 4.4 6.4 7.8 11.2 9.9 4.4 1.9 9.3 2.7 14.1 2.1 4.7-.5 9.3-2.2 13.1-5.1 4.7-3.7 7.2-9.6 12.5-12.5 5.3-2.8 11.6-2.9 17.4-2.7 5.5.1 11 .5 16.5-.6 4.9-1 9.7-3.2 12.7-7.3 3.7-5.1 3.2-11.7 2.8-17.7-.5-6.5-.2-12.7 1.8-18.9 2-6.1 5.3-11.6 9.7-16.3 3.8-4.1 8.4-7.8 10.8-13.1.1-.1.1-.3.2-.4H353c-.4 0-.4.2-.5.3z"
                fill="none"
                stroke="#000"
                stroke-width="17"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              />
              <path
                d="M355 293.7c-3.2-8.2-10.7-13.9-15.8-21-2.5-3.4-4.6-7.4-4.8-11.7-.2-3.6.9-7.1 1.5-10.5.5-2.8.6-5.6.2-8.4-1.6-10.4-10.3-19.7-20.7-21.9-3-.6-5.9-.6-8.9-.2-3.5.4-6.9.7-10.3-.1-6-1.6-10.9-5.7-15.5-9.7-4.8-4.1-9.7-8.2-15.7-10.3-5.9-2-11.8-1.2-16.8 2.5-2.4 1.8-4.6 3.9-6.8 5.9-2.3 2-4.8 3.9-7.4 5.5-5.3 3.3-11.2 5.6-17.3 6.9-.5.1-.9.2-1.4.3-4.6.3-9.2.2-13.5.2-5.6 0-12.8-.5-17 4.1-3.8 4.2-5 10.5-5.7 15.9-1.4 11.1.6 23.8-5.6 33.7-5.4 8.5-16.7 11.9-20.9 21.3-1.4 3.2-1.6 6.6-.8 9.9h2.1c-.9-3.7-.6-7.6 1.6-11 2.6-4.1 6.8-7 10.6-9.9 4-3.1 7.8-6.5 10.2-11.1 2.5-4.9 3.3-10.3 3.8-15.7.4-5.7.4-11.5 1.1-17.2.3-2.8.9-5.5 1.8-8.2.9-2.8 2.3-5.8 4.7-7.6 2.6-1.9 6-2 9.1-2.1h1.2c.1.1.3.2.5.3 5.8.7 11.7.5 17.4-.6 2.2-.2 4.4-.4 6.5-.8 5.3-1 10.2-3 14.3-6.5.4-.4.4-.8.1-1.2 1.4-.9 2.7-1.9 4-2.9 2.3-1.8 4.4-3.9 6.6-5.8 2.4-2 5-3.8 8.1-4.5 3-.7 6.1-.2 8.9.8 11.7 4 18.6 16.2 30.5 19.8 3 .9 6.1 1 9.2.7 3.2-.3 6.5-.8 9.7-.3 5.3.9 10.1 3.7 13.7 7.5 3.6 3.9 6.2 8.9 6.7 14.3.6 6.2-2.5 12.2-1.8 18.4 1 8.7 7.8 15.2 13.2 21.4 2.7 3.1 5.5 6.4 7.1 10.2 1.7 4 1.7 8.3-.2 12.3h2.2c1.8-4.4 1.9-8.7.3-12.7zM269.9 393.5 307 490l22-48.2 61 15.2-57.5-122.2M242.7 395.9 204 490l-25-47-52 15 53.5-118.4"
                fill="none"
                stroke="#000"
                stroke-width="17"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              />
              <path d="m225 299.8 27.5 26.2 46.5-54" />
            </svg>
            <svg
              v-else-if="item.id === 'guidebook'"
              class="information__indicator-icon"
              xmlns="http://www.w3.org/2000/svg"
              xml:space="preserve"
              viewBox="0 0 512 512"
            >
              <path
                d="M445.9 110.9h51.8V445c-153.2-48.6-241.7 0-241.7 0s-62.2-44.9-239.6 0V110.9h45.3"
                fill="none"
                stroke="#000"
                stroke-width="17"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              />
              <path
                d="M255.9 445c54-95 189.9-82 189.9-82V57.9c-146.8-8.3-189.9 75.2-189.9 75.2-89.6-105.7-185.6-69-185.6-69V363s147.8 1.1 185.6 82zM255.9 122.3v314.4"
                fill="none"
                stroke="#000"
                stroke-width="17"
                stroke-linejoin="round"
                stroke-miterlimit="10"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="information__indicator-icon"
              viewBox="0 0 512 512"
            >
              <path
                fill="currentColor"
                d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"
              />
            </svg>
          </div>
          <h3 :id="item.id" class="information__title">
            {{ item.title }}
          </h3>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="information__toggle-icon"
            viewBox="0 0 24 24"
          >
            <defs />
            <path d="M6 9l6 6 6-6" />
          </svg>
        </button>
        <div ref="contentBlocks" class="information__content-container">
          <div :class="`information__content information__content--${item.id}`">
            <div v-if="item.id === 'compositions'">
              <Compositions :compositions="compositions" />
            </div>
            <div v-else-if="item.id === 'wash-care'">
              <CareInstructions :instructions="item.entries" />
            </div>
            <div v-else-if="item.id === 'features'">
              <ProductFeatures :features="item.entries" />
            </div>
            <div v-else-if="item.id === 'certificates'">
              <ProductCertificates :certificates="item.entries" />
            </div>
            <!-- <div v-else-if="item.id === 'certification-info'">
              <ProductFiles :files="item.entries" />
            </div>
            <div v-else-if="item.id === 'size-guide'">
              <ProductFiles :files="item.entries" />
            </div>
            <div v-else-if="item.id === 'logo-placement'">
              <ProductFiles :files="item.entries" />
            </div> -->
            <div v-else>
              <ProductFiles :files="item.entries" />
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import CareInstructions from "@/components/CareInstructions";
import Compositions from "@/components/Compositions";
import ProductCertificates from "@/components/ProductCertificates";
import ProductFeatures from "@/components/ProductFeatures";
import ProductFiles from "@/components/ProductFiles";
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  name: "ProductInformation",
  components: {
    CareInstructions,
    Compositions,
    ProductCertificates,
    ProductFeatures,
    ProductFiles,
  },
  props: {
    product: {
      required: true,
      type: Object,
    },
    variant: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    certificates() {
      const { certificates } = this.product;

      if (!certificates || !certificates.length) return [];

      return certificates;
    },
    features() {
      const { features } = this.product;

      if (!features || !features.length) return [];

      return features;
    },
    itemFiles() {
      const { productFileCategory } = this.product;

      if (!productFileCategory || !productFileCategory.length) return [];

      return productFileCategory;
    },
    compositions() {
      const { variant } = this;

      if (!variant || !variant.compositions || !variant.compositions.length) {
        return [];
      }

      return variant.compositions.map(composition => {
        if (!composition.includes(":")) {
          return { description: "", value: composition.trim() };
        }

        const parts = composition.split(":");

        return {
          description: parts[0].trim(),
          value: parts[1].trim(),
        };
      });
    },
    washCareEntriesWithoutSymbols() {
      const { washCareWithoutSymbols } = this.product;

      if (!washCareWithoutSymbols || !washCareWithoutSymbols.length) {
        return [];
      }

      return washCareWithoutSymbols;
    },
    washCareEntriesWithSymbols() {
      const { washCareWithSymbols } = this.product;

      if (!washCareWithSymbols || !washCareWithSymbols.length) {
        return [];
      }

      return washCareWithSymbols;
    },
  },
  watch: {
    dictionaryEntries: function(newValue) {
      if (newValue) {
        this.updateItems();
      }
    },
    product() {
      this.updateItems();
    },
    variant() {
      this.updateItems();
    },
  },
  created() {
    this.updateItems();
  },
  methods: {
    toggle({ id, isExpanded }) {
      const items = [...this.items];

      const indexOfMatchingItem = items.findIndex(item => item.id === id);

      if (indexOfMatchingItem === -1) return;

      items[indexOfMatchingItem].isExpanded = !isExpanded;
      this.items = items;

      const contentElement = this.$refs.contentBlocks[indexOfMatchingItem];

      if (!isExpanded) {
        slideDown(contentElement);
      } else {
        slideUp(contentElement);
      }
    },
    updateItems() {
      const {
        certificates,
        compositions,
        features,
        getDictionaryEntry,
        product,
        variant,
        washCareEntriesWithSymbols,
        washCareEntriesWithoutSymbols,
        itemFiles,
      } = this;

      if (!product || !variant) return;

      const items = [];

      if (compositions.length) {
        items.push({
          entries: compositions,
          id: "compositions",
          isExpanded: false,
          title: getDictionaryEntry("Product.Compositions"),
        });
      }

      if (
        washCareEntriesWithSymbols.length ||
        washCareEntriesWithoutSymbols.length
      ) {
        items.push({
          entries: {
            washCareEntriesWithSymbols,
            washCareEntriesWithoutSymbols,
          },
          id: "wash-care",
          isExpanded: false,
          title: getDictionaryEntry("Product.CareInstructions"),
        });
      }

      if (features.length) {
        items.push({
          entries: features,
          id: "features",
          isExpanded: false,
          title: getDictionaryEntry("Product.Features"),
        });
      }

      if (certificates.length) {
        items.push({
          entries: certificates,
          id: "certificates",
          isExpanded: false,
          title: getDictionaryEntry("Product.Certificates"),
        });
      }

      if (itemFiles.length) {
        itemFiles.map(file => {
          const id = file.categoryKey.replace(
            /[A-Z]/g,
            letter => `-${letter.toLowerCase()}`,
          );

          items.push({
            entries: file.productFiles,
            id,
            isExpanded: false,
            title: getDictionaryEntry(file.categoryName),
          });
        });
      }

      this.items = items;
    },
  },
};
</script>

<style lang="scss" scoped>
.information {
  &__content {
    font-size: 0.9375rem;
    line-height: 1.5;
    padding: var(--spacing-container);

    &-container {
      display: none;
      overflow: hidden;
    }
  }

  &__indicator {
    &-icon {
      fill: none;
      flex: 0 0 auto;
      height: var(--size-product-feature-icon);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: var(--color-text-primary);
      width: var(--size-product-feature-icon);
    }
  }

  &__item {
    border: 1px solid var(--color-product-features-border);
    border-width: 0 0 1px;

    &:first-child {
      border-top-width: 1px;
    }
  }

  &__list {
    @include reset-list();
  }

  &__title {
    flex: 1 1 auto;
    font-size: 1rem;
    font-weight: 400;
    margin: 0 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__toggle {
    @include reset-button();

    align-items: center;
    bottom: 0;
    display: flex;
    padding: var(--spacing-container) calc(var(--spacing-container) * 0.5);
    text-align: left;
    width: 100%;

    &-icon {
      fill: none;
      flex: 0 0 auto;
      height: var(--size-product-feature-icon);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: var(--color-text-primary);
      transition: transform 300ms ease;
      width: var(--size-product-feature-icon);

      [aria-expanded="true"] & {
        transform: rotate(180deg);
      }
    }
  }
}
</style>
