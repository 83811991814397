import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  colorGroups: [],
  countries: [],
  currencies: [],
  dictionary: null,
  fits: [],
  gaMeasurementId: null,
  genders: [],
  googleMapsKey: null,
  languages: [],
  nonVirtualCurrencies: [],
  productCategories: [],
  sizeSystems: [],
  status: "",
  timeZones: [],
});

const getters = {
  colorGroups: state => state.colorGroups,
  countries: state => state.countries,
  countryNameByCode: state => code => {
    if (!code || !state.countries) return null;

    const matchingCountry = state.countries.find(
      country => country.value.toLowerCase() === code.toLowerCase(),
    );

    return matchingCountry ? matchingCountry.label : null;
  },
  currencies: state => state.currencies,
  dictionaryEntries: state => state.dictionary,
  fits: state => state.fits,
  gaMeasurementId: state => state.gaMeasurementId,
  genders: state => state.genders,
  googleMapsKey: state => state.googleMapsKey,
  languageCodes: state => {
    if (!state.languages) return [];

    return state.languages.map(languageAsOption => languageAsOption.value);
  },
  languages: state => state.languages,
  nonVirtualCurrencies: state => state.nonVirtualCurrencies,
  productCategories: state => state.productCategories,
  sizeSystems: state => state.sizeSystems,
  statusOfSiteSettings: state => state.status,
  timeZones: state => state.timeZones,
};

const actions = {
  GET_COLOR_GROUPS({ commit }) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/productTags/colorgroup`)
      .then(({ data }) => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
        commit("SET_COLOR_GROUPS", { colorGroups: data });
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_COUNTRIES({ commit, getters }) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/sitesettings/countries`)
      .then(({ data }) => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
        commit("SET_COUNTRIES", {
          countries: data.map(country => ({
            ...country,
            label: getters["dictionaryEntries"][country.label] || country.value,
          })),
        });
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_DICTIONARY({ commit, dispatch }) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/dictionary`)
      .then(({ data }) => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
        commit("SET_DICTIONARY", { dictionary: data });
        dispatch("GET_SITE_SETTINGS");
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_DICTIONARY_ENTRY({ commit }, payload) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .get(`${endpoints.ECOMMERCE}/dictionary/entry/${payload.key}`)
        .then(({ data }) => {
          const key = Object.keys(data.entries)[0];
          const value = Object.values(data.entries)[0];

          commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
          commit("SET_DICTIONARY_ENTRY", { key, value });

          resolve(value);
        })
        .catch(error => {
          //It will return 404 if there are no matching entries
          if (error.response.status === 404) return;

          commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  GET_FITS({ commit }) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/productTags/fit`)
      .then(({ data }) => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
        commit("SET_FITS", { fits: data });
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_GENDERS({ commit }) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/productTags/gender`)
      .then(({ data }) => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
        commit("SET_GENDERS", { genders: data });
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_LANGUAGES({ commit, getters }, payload) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });
    commit("SET_LANGUAGES", { languages: [] });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/sitesettings/languages/${payload.divisionCode}`,
      )
      .then(({ data }) => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
        commit("SET_LANGUAGES", {
          languages: data.map(language => ({
            ...language,
            label: getters["dictionaryEntries"][language.label],
          })),
        });
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCT_CATEGORIES({ commit }, payload) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/productCategories/${payload.divisionId}`)
      .then(({ data }) => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
        commit("SET_PRODUCT_CATEGORIES", { productCategories: data });
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SITE_SETTINGS({ commit, getters, rootGetters }) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/sitesettings/${rootGetters["authentication/divisionCode"]}`,
      )
      .then(
        ({
          data: {
            currencies,
            gaMeasurementId,
            languages,
            nonVirtualCurrencies,
            sizeSystems,
          },
        }) => {
          commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
          commit("SET_CURRENCIES", { currencies });
          commit("SET_GA_MEASUREMENT_ID", { gaMeasurementId });
          commit("SET_GOOGLE_MAPS_KEY", {
            key: "AIzaSyBvmF_ZU47bitUDnRT6nLhr7G8qzjaTrug",
          });
          commit("SET_LANGUAGES", {
            languages: languages.map(language => ({
              ...language,
              label: getters["dictionaryEntries"][language.label],
            })),
          });
          commit("SET_NON_VIRTUAL_CURRENCIES", { nonVirtualCurrencies });
          commit("SET_SIZE_SYSTEMS", { sizeSystems: sizeSystems || [] });
        },
      )
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_SIZE_SYSTEMS({ commit }, payload) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });
    commit("SET_SIZE_SYSTEMS", { sizeSystems: [] });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/sitesettings/sizesystems/${payload.divisionCode}`,
      )
      .then(({ data }) => {
        commit("SET_SIZE_SYSTEMS", { sizeSystems: data || [] });
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_TIME_ZONES({ commit }) {
    commit("SET_SITE_SETTINGS_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/sitesettings/timezones`)
      .then(({ data }) => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.SUCCESS });
        commit("SET_TIME_ZONES", {
          timeZones: data.map(timeZone => ({
            label: timeZone.displayName,
            value: timeZone.id,
          })),
        });
      })
      .catch(error => {
        commit("SET_SITE_SETTINGS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_COLOR_GROUPS(state, payload) {
    state.colorGroups = Object.freeze(payload.colorGroups);
  },
  SET_COUNTRIES(state, payload) {
    state.countries = Object.freeze(payload.countries);
  },
  SET_CURRENCIES(state, payload) {
    state.currencies = Object.freeze(payload.currencies);
  },
  SET_DICTIONARY(state, payload) {
    state.dictionary = payload.dictionary.entries;
  },
  SET_DICTIONARY_ENTRY(state, payload) {
    const dictionary = { ...state.dictionary };

    dictionary[payload.key] = payload.value;
    state.dictionary = dictionary;
  },
  SET_FITS(state, payload) {
    state.fits = Object.freeze(payload.fits);
  },
  SET_GA_MEASUREMENT_ID(state, payload) {
    state.gaMeasurementId = Object.freeze(payload.gaMeasurementId);
  },
  SET_GENDERS(state, payload) {
    state.genders = Object.freeze(payload.genders);
  },
  SET_GOOGLE_MAPS_KEY(state, payload) {
    state.googleMapsKey = Object.freeze(payload.key);
  },
  SET_LANGUAGES(state, payload) {
    state.languages = Object.freeze(payload.languages);
  },
  SET_NON_VIRTUAL_CURRENCIES(state, payload) {
    state.nonVirtualCurrencies = Object.freeze(payload.nonVirtualCurrencies);
  },
  SET_PRODUCT_CATEGORIES(state, payload) {
    state.productCategories = Object.freeze(payload.productCategories);
  },
  SET_SITE_SETTINGS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_SIZE_SYSTEMS(state, payload) {
    state.sizeSystems = Object.freeze(payload.sizeSystems);
  },
  SET_TIME_ZONES(state, payload) {
    state.timeZones = Object.freeze(payload.timeZones);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
