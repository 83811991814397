var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"toggle-switch",class:{
    'toggle-switch--disabled': _vm.disabled,
    'toggle-switch--label-on-right-side': _vm.labelOnRightSide,
  }},[_c('input',{staticClass:"toggle-switch__input",attrs:{"id":_vm.id,"disabled":_vm.disabled,"type":"checkbox"},domProps:{"checked":_vm.value},on:{"change":function($event){return _vm.$emit('input', $event.target.checked)}}}),_c('label',{staticClass:"toggle-switch__label",class:{},attrs:{"for":_vm.id}},[_c('span',{staticClass:"toggle-switch__label-text",class:{ 'toggle-switch__label-text--disabled': _vm.secondLabel && _vm.value }},[_vm._v(_vm._s(_vm.label)),(_vm.description)?_c('span',{staticClass:"toggle-switch__label-description"},[_vm._v(_vm._s(_vm.description))]):_vm._e()]),_c('span',{staticClass:"toggle-switch__knob",class:{
        'toggle-switch__knob--multiple-texts': _vm.secondLabel,
      }}),(_vm.secondLabel)?_c('span',{staticClass:"toggle-switch__label-text",class:{
        'toggle-switch__label-text--disabled': _vm.secondLabel && !_vm.value,
      }},[_vm._v(_vm._s(_vm.secondLabel))]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }