import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  status: "",
});

const getters = {
  statusOfContact: state => state.status,
};

const actions = {
  SEND_MESSAGE({ commit, rootGetters }, payload) {
    commit("SET_CONTACT_STATUS", { status: statuses.LOADING });

    const { email, name, phone, message, emailRecipient } = payload;
    const division = rootGetters["branding/divisionId"];

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/contact/send`, {
          division,
          email,
          emailRecipient,
          message,
          name,
          phone,
        })
        .then(() => {
          commit("SET_CONTACT_STATUS", { status: statuses.SUCCESS });
          resolve();
        })
        .catch(error => {
          commit("SET_CONTACT_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
};

const mutations = {
  SET_CONTACT_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
