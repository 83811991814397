<template>
  <li
    class="accordion-item"
    :class="{
      'accordion-item--disabled': disabled,
      'accordion-item--secondary': secondary,
      'accordion-item--active': isOpen,
    }"
  >
    <div class="accordion-item__header-container" @click="toggle">
      <div class="accordion-item__header">
        <slot name="accordion-header" />
      </div>
      <div class="accordion-item__trigger">
        <div class="accordion-item__trigger-wrapper">
          <div class="accordion-item__trigger-icon">
            <svg v-if="secondary" width="100%" viewBox="0 0 20 20">
              <path
                fill="currentColor"
                fill-rule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clip-rule="evenodd"
              />
            </svg>
            <svg
              v-else
              role="img"
              width="100%"
              viewBox="0 0 20 20"
              stroke="currentColor"
              stroke-width="2px"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="10"
                y1="0"
                x2="10"
                y2="20"
                class="accordion-item__trigger-icon-line"
              />
              <line x1="0" y1="10" x2="20" y2="10" />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <div ref="content" class="accordion-item__content-container">
      <div class="accordion-item__content">
        <slot name="accordion-body" />
      </div>
    </div>
  </li>
</template>

<script>
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    isAccordionOpen: {
      type: Boolean,
      default: true,
    },
    secondary: {
      type: Boolean,
      default: false,
    },
  },
  inject: ["Accordion"],
  data() {
    return {
      isOpen: this.isAccordionOpen,
      index: null,
    };
  },
  methods: {
    close() {
      slideUp(this.$refs.content);
      this.isOpen = false;
    },
    open() {
      slideDown(this.$refs.content);
      this.isOpen = true;
    },
    toggle() {
      if (this.isOpen) {
        this.close();
      } else {
        this.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.accordion-item {
  position: relative;
  margin-bottom: 1.5rem;

  &--disabled {
    opacity: 0.1;
  }
}

.accordion-item__header-container {
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 0.5625rem 0.9375rem 0.5rem 0.625rem;
  background-color: #faf9f8;
  border-bottom: 1px solid var(--color-table-border);

  .accordion-item--secondary & {
    border-radius: 2rem;
    padding: 0.8125rem 3.3rem 0.8125rem 1.1875rem;
    background-color: white;
    transition: box-shadow 300ms ease-in-out;
    border: 0;
    box-shadow: 0px 0px 0px 1px var(--color-input-border-active);
    margin-bottom: 1.25rem;
    position: relative;

    &:hover {
      box-shadow: 0px 0px 0px 2px var(--color-text-primary);
    }
  }

  .accordion-item--secondary.accordion-item--active & {
    box-shadow: 0px 0px 0px 2px var(--color-text-primary);
  }

  .sidebar & {
    padding-left: var(--spacing-sidebar);
    padding-right: var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding-left: var(--spacing-sidebar-large);
      padding-right: var(--spacing-sidebar-large);
    }
  }
}

.accordion-item__header {
  width: 100%;
  display: flex;
  align-items: center;
  font-weight: 700;
}

.accordion-item__trigger {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .accordion-item--secondary & {
    position: absolute;
    right: 1.25rem;
    top: 0.2rem;
  }
}

.accordion-item__trigger-wrapper {
  width: 1.1875rem;
  height: 1.1875rem;
  display: flex;
  justify-content: center;
  align-items: center;
  outline: #3f4e56 solid 2px;
  border-radius: 50%;

  .accordion-item--secondary & {
    outline: 0;
    width: 2rem;
    height: 2rem;
  }
}

.accordion-item__trigger-icon {
  align-items: center;
  color: #3f4e56;
  display: flex;
  justify-content: center;
  width: 0.8rem;

  .sidebar & {
    color: var(--color-text-primary);
    width: 0.7rem;
  }

  .accordion-item--secondary & {
    width: 100%;
    transform: rotate(0);
    transition: transform 300ms ease;
    transform-origin: 50% 45%;
  }

  .accordion-item--active & {
    transform: rotate(180deg);
  }
}

.accordion-item__trigger-icon-line {
  transition: transform 300ms ease-in-out;
  transform-origin: center;

  .accordion-item--active & {
    transform: rotate(90deg);
  }
}

.accordion-item__content {
  padding: 0 10px;

  .basket & {
    padding: 0 0.625rem;
  }

  .quick-basket & {
    padding: 0;
  }

  &-container {
    overflow: hidden;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 300ms ease, opacity 300ms ease;
  overflow: hidden;
}

.accordion-enter-from,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
