var render = function render(){var _vm=this,_c=_vm._self._c;return _c('FocusTrap',{attrs:{"escape-deactivates":false},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('transition',{attrs:{"name":"fade-scale-subtle","appear":""}},[(_vm.isImageGalleryOpen)?_c('div',{staticClass:"image-gallery"},[_c('div',{staticClass:"image-gallery__header"},[_c('div',{staticClass:"image-gallery__count"},[_vm._v(" "+_vm._s(_vm.currentIndex + 1)+"/"+_vm._s(_vm.images.length)+" ")]),_c('button',{staticClass:"image-gallery__button image-gallery__button--close",attrs:{"aria-label":_vm.getDictionaryEntry('Common.Close')},on:{"click":_vm.handleClose}},[_c('svg',{staticClass:"image-gallery__button-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 21 21"}},[_c('defs'),_c('g',[_c('path',{attrs:{"d":"M5.5 15.5l10-10M15.5 15.5l-10-10z"}})])])])]),_c('transition-group',{staticClass:"image-gallery__list",attrs:{"name":"fade","tag":"ol"}},_vm._l((_vm.images),function(image,index){return _c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.currentIndex === index),expression:"currentIndex === index"}],key:image.src,staticClass:"image-gallery__item"},[_c('img',{staticClass:"image-gallery__image",attrs:{"src":image.src,"alt":image.alt,"decoding":"async","loading":"lazy"},on:{"mousemove":_vm.handleMouseMove,"touchend":_vm.openMagnifier}}),_c('div',{staticClass:"image-gallery__magnifier",class:{ 'image-gallery__magnifier--active': _vm.isMagnifierOpen },style:({
              backgroundImage: `url(${_vm.images[_vm.currentIndex].src})`,
              backgroundPositionX: _vm.magnifierStyles.backgroundX,
              backgroundPositionY: _vm.magnifierStyles.backgroundY,
              left: _vm.magnifierStyles.left,
              top: _vm.magnifierStyles.top,
            })})])}),0),_c('div',{staticClass:"image-gallery__footer"},[_c('button',{staticClass:"image-gallery__button image-gallery__button--previous",attrs:{"aria-label":_vm.getDictionaryEntry('Common.Previous')},on:{"click":_vm.SELECT_PREVIOUS_IMAGE}},[_c('svg',{staticClass:"image-gallery__button-icon",attrs:{"viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M15 18l-6-6 6-6"}})])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isMagnifierOpen)?_c('button',{staticClass:"image-gallery__button image-gallery__button--zoom",on:{"click":_vm.closeMagnifier}},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.CloseZoom"))+" ")]):_vm._e()]),_c('button',{staticClass:"image-gallery__button image-gallery__button--next",attrs:{"aria-label":_vm.getDictionaryEntry('Common.Next')},on:{"click":_vm.SELECT_NEXT_IMAGE}},[_c('svg',{staticClass:"image-gallery__button-icon",attrs:{"viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M9 18l6-6-6-6"}})])])],1)],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }