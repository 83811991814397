<template>
  <div
    class="block block--dashboard block--dashboard-rich-text block__media-container rte-cards"
  >
    <article class="block__50-percent rte-cards__block">
      <RichTextCard content-key="self-managed-block-first" />
    </article>
    <article class="block__50-percent rte-cards__block">
      <RichTextCard content-key="self-managed-block-second" />
    </article>
  </div>
</template>

<script>
import RichTextCard from "@/components/RichTextCard";

export default {
  name: "DashboardSelfManagedRTEBlock",
  components: {
    RichTextCard,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.rte-cards {
  background-color: transparent;

  &__block {
    background-color: #f8f8f8;
    overflow: hidden;
    padding: 1rem;

    @media (min-width: 64rem) {
      padding: var(--spacing-stack);
    }

    &:not(:last-child) {
      @media (min-width: 64rem) {
        margin-right: var(--spacing-stack);
      }
    }
  }
}
</style>
