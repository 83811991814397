import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentWardrobe: null,
  currentWardrobeId: null,
  currentWardrobeProductId: null,
  isModalForAddingProductsOpen: false,
  isModalForCreatingWardrobeOpen: false,
  isModalForEditingProductOpen: false,
  productsOfCurrentWardrobe: [],
  selectedUserGroups: [],
  selectedProductsToAdd: [],
  selectedVariantsToAdd: [],
  selectedWardrobes: [],
  wardrobes: [],
  status: "",
});

const getters = {
  currentWardrobe: state => state.currentWardrobe,
  currentWardrobeId: state => state.currentWardrobeId,
  currentWardrobeProduct: state =>
    state.productsOfCurrentWardrobe.find(
      product => product.id === state.currentWardrobeProductId,
    ),
  currentWardrobeProductId: state => state.currentWardrobeProductId,
  isModalForAddingProductsOpen: state => state.isModalForAddingProductsOpen,
  isModalForCreatingWardrobeOpen: state => state.isModalForCreatingWardrobeOpen,
  isModalForEditingProductOpen: state => state.isModalForEditingProductOpen,
  nameOfCurrentWardrobe: state => {
    if (!state.currentWardrobe) return null;

    return state.currentWardrobe.name;
  },
  nameOfCurrentWardrobeProduct: (state, getters) => {
    const { currentWardrobeId, currentWardrobeProductId, wardrobes } = state;

    if (!wardrobes.length || !currentWardrobeId || !currentWardrobeProductId)
      return null;

    const matchingProduct = getters.wardrobeProductById({
      productId: currentWardrobeProductId,
      wardrobeId: currentWardrobeId,
    });

    return matchingProduct ? matchingProduct.title : null;
  },
  productsOfCurrentWardrobe: state => state.productsOfCurrentWardrobe,
  selectedProducts: state => state.selectedProducts,
  selectedProductsToAdd: state => state.selectedProductsToAdd,
  selectedUserGroups: state => state.selectedUserGroups,
  selectedVariantsToAdd: state => state.selectedVariantsToAdd,
  selectedWardrobes: state => state.selectedWardrobes,
  statusOfWardrobes: state => state.status,
  wardrobeById: state => id =>
    state.wardrobes.find(wardrobe => wardrobe.id === id),
  wardrobeProductById: (state, getters) => ({ productId, wardrobeId }) => {
    if (!productId || !wardrobeId || !state.wardrobes.length) return null;

    const matchingWardrobe = getters.wardrobeById(wardrobeId);

    if (!matchingWardrobe || !matchingWardrobe.products) return null;

    return matchingWardrobe.products.find(product => product.id === productId);
  },
  wardrobes: state => state.wardrobes,
};

const actions = {
  ADD_VARIANTS_TO_WARDROBE({ commit, dispatch }, payload) {
    commit("SET_WARDROBES_STATUS", { status: statuses.SAVING });

    const { variantsToAdd, wardrobeId } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(
          `${endpoints.ECOMMERCE}/wardrobes/${wardrobeId}/add-multiple-variants`,
          variantsToAdd,
        )
        .then(() => {
          commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_ADDING_PRODUCTS");
          dispatch("CLOSE_MODAL_FOR_EDITING_PRODUCT");
          commit("DESELECT_PRODUCTS_TO_ADD");
          commit("DESELECT_VARIANTS_TO_ADD");
          dispatch("GET_PRODUCTS_OF_WARDROBE", { wardrobeId });
          resolve();
        })
        .catch(error => {
          commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  CLOSE_MODAL_FOR_ADDING_PRODUCTS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ADDING_PRODUCTS", { value: false });
    commit("SET_CURRENT_WARDROBE_PRODUCT_ID", { productId: null });
    commit("SET_SELECTED_VARIANTS_TO_ADD", { variants: [] });
  },
  CLOSE_MODAL_FOR_CREATING_WARDROBE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_WARDROBE", { value: false });
  },
  CLOSE_MODAL_FOR_EDITING_PRODUCT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT", { value: false });
  },
  CREATE_WARDROBE({ commit, dispatch }, payload) {
    commit("SET_WARDROBES_STATUS", { status: statuses.SAVING });

    const { customerId, name } = payload;

    return new Promise((resolve, reject) => {
      axios
        .put(`${endpoints.ECOMMERCE}/wardrobes/`, { customerId, name })
        .then(({ data }) => {
          commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_WARDROBE");
          resolve({ id: data });
        })
        .catch(error => {
          commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  DELETE_SELECTED_WARDROBES({ commit, dispatch, state }) {
    commit("SET_WARDROBES_STATUS", { status: statuses.SAVING });

    axios
      .post(`${endpoints.ECOMMERCE}/wardrobes/delete-multiple`, {
        identifiers: state.selectedWardrobes.map(wardrobe => wardrobe.id),
      })
      .then(() => {
        commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
        commit("DESELECT_WARDROBES");
        dispatch("GET_WARDROBES");
      })
      .catch(error => {
        commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_PRODUCTS_OF_WARDROBE({ commit }, payload) {
    commit("SET_WARDROBES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/wardrobes/${payload.wardrobeId}/products`)
      .then(({ data }) => {
        commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
        commit("SET_PRODUCTS_OF_CURRENT_WARDROBE", {
          products: data,
        });
      })
      .catch(error => {
        commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_WARDROBE({ commit }, payload) {
    commit("SET_WARDROBES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/wardrobes/${payload.wardrobeId}`)
      .then(({ data }) => {
        commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_WARDROBE", { wardrobe: data });
      })
      .catch(error => {
        commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_WARDROBES({ commit, rootGetters }) {
    commit("SET_WARDROBES_STATUS", { status: statuses.LOADING });

    const customerId = rootGetters["customers/currentCustomerId"];

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${customerId}/wardrobes`)
      .then(({ data }) => {
        commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
        commit("SET_WARDROBES", {
          wardrobes: data,
        });
      })
      .catch(error => {
        commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_ADDING_PRODUCTS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ADDING_PRODUCTS", { value: true });
  },
  OPEN_MODAL_FOR_CREATING_WARDROBE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_WARDROBE", { value: true });
  },
  OPEN_MODAL_FOR_EDITING_PRODUCT({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT", { value: true });
  },
  REMOVE_PRODUCT_FROM_WARDROBE({ commit, dispatch }, payload) {
    commit("SET_WARDROBES_STATUS", { status: statuses.LOADING });

    const { productId, wardrobeId } = payload;

    return axios
      .delete(
        `${endpoints.ECOMMERCE}/wardrobes/${wardrobeId}/remove-product/${productId}`,
      )
      .then(() => {
        commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_PRODUCTS_OF_WARDROBE", { wardrobeId });
      })
      .catch(error => {
        commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  REMOVE_VARIANTS_FROM_WARDROBE({ commit, dispatch }, payload) {
    commit("SET_WARDROBES_STATUS", { status: statuses.SAVING });

    const { variants, wardrobeId } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/wardrobes/${wardrobeId}/remove-multiple-variants`,
        variants,
      )
      .then(() => {
        commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
        dispatch("CLOSE_MODAL_FOR_ADDING_PRODUCTS");
        dispatch("CLOSE_MODAL_FOR_EDITING_PRODUCT");
        dispatch("GET_PRODUCTS_OF_WARDROBE", { wardrobeId });
      })
      .catch(error => {
        commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_SELECTED_PRODUCTS_TO_ADD({ commit }) {
    commit("DESELECT_PRODUCTS_TO_ADD");
  },
  RESET_SELECTED_VARIANTS_TO_ADD({ commit }) {
    commit("DESELECT_VARIANTS_TO_ADD");
  },
  TOGGLE_PRODUCT_TO_ADD({ commit }, payload) {
    commit("SET_SELECTED_PRODUCT_TO_ADD", payload);
  },
  TOGGLE_VARIANT_TO_ADD({ commit }, payload) {
    commit("SET_SELECTED_VARIANT_TO_ADD", payload);
  },
  TOGGLE_WARDROBE({ commit }, payload) {
    commit("SET_SELECTED_WARDROBE", payload);
  },
  TOGGLE_USER_GROUP({ commit }, payload) {
    commit("SET_SELECTED_USER_GROUP", payload);
  },
  TOGGLE_WARDROBES({ commit }, payload) {
    commit("SET_SELECTED_WARDROBES", payload);
  },
  UPDATE_CURRENT_WARDROBE_ID({ commit }, payload) {
    commit("SET_CURRENT_WARDROBE_ID", payload);
  },
  UPDATE_CURRENT_WARDROBE_PRODUCT_ID({ commit }, payload) {
    commit("SET_CURRENT_WARDROBE_PRODUCT_ID", payload);
  },
  UPDATE_SELECTED_USER_GROUPS({ commit }, payload) {
    commit("SET_SELECTED_USER_GROUPS", payload);
  },
  UPDATE_VARIANTS_IN_WARDROBE({ commit, dispatch }, payload) {
    commit("SET_WARDROBES_STATUS", { status: statuses.SAVING });

    const { variantsToAdd, variantsToRemove, wardrobeId } = payload;

    const addVariants = variantsToAdd?.length
      ? axios.post(
          `${endpoints.ECOMMERCE}/wardrobes/${wardrobeId}/add-multiple-variants`,
          variantsToAdd,
        )
      : null;

    const removeVariants = variantsToRemove?.length
      ? axios.post(
          `${endpoints.ECOMMERCE}/wardrobes/${wardrobeId}/remove-multiple-variants`,
          variantsToRemove,
        )
      : null;

    return axios
      .all([addVariants, removeVariants])
      .then(() => {
        commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_PRODUCTS_OF_WARDROBE", { wardrobeId });
        dispatch("CLOSE_MODAL_FOR_EDITING_PRODUCT");
        commit("DESELECT_PRODUCTS_TO_ADD");
        commit("DESELECT_VARIANTS_TO_ADD");
      })
      .catch(error => {
        commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_VARIANTS_TO_ADD({ commit }, payload) {
    commit("SET_SELECTED_VARIANTS_TO_ADD", payload);
  },
  UPDATE_WARDROBE({ commit }, payload) {
    commit("SET_WARDROBES_STATUS", { status: statuses.SAVING });

    const { id, name, published, userGroupIds } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/wardrobes/${id}`, {
          id,
          name,
          published,
          userGroupIds,
        })
        .then(({ data }) => {
          commit("SET_WARDROBES_STATUS", { status: statuses.SUCCESS });
          commit("SET_CURRENT_WARDROBE", { wardrobe: data });
          resolve(data);
        })
        .catch(error => {
          commit("SET_WARDROBES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
};

const mutations = {
  DESELECT_PRODUCTS_TO_ADD(state) {
    state.selectedProductsToAdd = [];
  },
  DESELECT_VARIANTS_TO_ADD(state) {
    state.selectedVariantsToAdd = [];
  },
  DESELECT_WARDROBES(state) {
    state.selectedWardrobes = [];
  },
  SET_CURRENT_WARDROBE(state, payload) {
    state.currentWardrobe = payload.wardrobe;
  },
  SET_CURRENT_WARDROBE_ID(state, payload) {
    state.currentWardrobeId = payload.wardrobeId;
  },
  SET_CURRENT_WARDROBE_PRODUCT_ID(state, payload) {
    state.currentWardrobeProductId = payload.id;
  },
  SET_PRODUCTS_OF_CURRENT_WARDROBE(state, payload) {
    state.productsOfCurrentWardrobe = payload.products;
  },
  SET_SELECTED_PRODUCT_TO_ADD(state, payload) {
    const { product, value } = payload;

    if (value) {
      if (state.selectedProductsToAdd.some(entry => entry.id === product.id)) {
        return;
      }

      state.selectedProductsToAdd.push(product);
    } else {
      state.selectedProductsToAdd = state.selectedProductsToAdd.filter(
        entry => entry.id !== product.id,
      );
    }
  },
  SET_SELECTED_USER_GROUP(state, payload) {
    const { userGroup, value } = payload;

    if (value) {
      if (state.selectedUserGroups.includes(userGroup)) return;

      state.selectedUserGroups.push(userGroup);
    } else {
      state.selectedUserGroups = state.selectedUserGroups.filter(
        selectedUserGroup => selectedUserGroup !== userGroup,
      );
    }
  },
  SET_SELECTED_USER_GROUPS(state, payload) {
    state.selectedUserGroups = payload.userGroups;
  },
  SET_SELECTED_VARIANT_TO_ADD(state, payload) {
    const { variant, value } = payload;

    if (value) {
      if (state.selectedVariantsToAdd.some(entry => entry.id === variant.id)) {
        return;
      }

      state.selectedVariantsToAdd.push(variant);
    } else {
      state.selectedVariantsToAdd = state.selectedVariantsToAdd.filter(
        entry => entry.id !== variant.id,
      );
    }
  },
  SET_SELECTED_VARIANTS_TO_ADD(state, payload) {
    state.selectedVariantsToAdd = payload.variants;
  },
  SET_SELECTED_WARDROBE(state, payload) {
    const { row: wardrobe, value } = payload;

    if (value) {
      if (state.selectedWardrobes.some(entry => entry.id === wardrobe.id)) {
        return;
      }

      state.selectedWardrobes.push(wardrobe);
    } else {
      state.selectedWardrobes = state.selectedWardrobes.filter(
        entry => entry.id !== wardrobe.id,
      );
    }
  },
  SET_SELECTED_WARDROBES(state, payload) {
    const { rows: wardrobes, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedWardrobes.map(selectedWardrobe => selectedWardrobe.id),
      );

      state.selectedWardrobes = [
        ...state.selectedWardrobes,
        ...wardrobes
          .filter(wardrobe => !wardrobe.disableSelection)
          .filter(wardrobe => !identifiers.has(wardrobe.id)),
      ];
    } else {
      state.selectedWardrobes = state.selectedWardrobes.filter(
        selectedWardrobe =>
          !wardrobes.some(entry => entry.id === selectedWardrobe.id),
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_ADDING_PRODUCTS(state, payload) {
    state.isModalForAddingProductsOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_CREATING_WARDROBE(state, payload) {
    state.isModalForCreatingWardrobeOpen = payload.value;
  },
  SET_STATE_OF_MODAL_FOR_EDITING_PRODUCT(state, payload) {
    state.isModalForEditingProductOpen = payload.value;
  },
  SET_WARDROBES(state, payload) {
    state.wardrobes = payload.wardrobes;
  },
  SET_WARDROBES_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
