import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  inventory: [],
  status: "",
});

const getters = {
  inventory: state => state.inventory,
  inventoryForSku: state => sku => {
    const { inventory } = state;

    if (!inventory || !inventory[sku]) return {};

    return inventory[sku];
  },
  statusOfInventory: state => state.status,
};

const actions = {
  GET_INVENTORY({ commit }, payload) {
    commit("SET_INVENTORY_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/inventory/get-by-sku/${payload.sku}`)
      .then(({ data }) => {
        commit("SET_INVENTORY_STATUS", { status: statuses.SUCCESS });
        commit("SET_INVENTORY", { inventory: data });
      })
      .catch(error => {
        commit("SET_INVENTORY_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_INVENTORY_LIST({ commit }, payload) {
    commit("SET_INVENTORY_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/inventory/list-by-sku`, {
        sku: payload.list,
      })
      .then(({ data }) => {
        commit("SET_INVENTORY_STATUS", { status: statuses.SUCCESS });
        commit("SET_INVENTORY_LIST", { inventory: data });
      })
      .catch(error => {
        commit("SET_INVENTORY_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_INVENTORY(state, payload) {
    const inventory = { ...state.inventory };
    inventory[Object.keys(payload.inventory)[0]] = Object.values(
      payload.inventory,
    )[0];

    state.inventory = inventory;
  },
  SET_INVENTORY_LIST(state, payload) {
    const inventory = { ...state.inventory };

    for (const [key, value] of Object.entries(payload.inventory)) {
      inventory[key] = value;
    }

    state.inventory = inventory;
  },
  SET_INVENTORY_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
