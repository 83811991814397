<template>
  <div
    class="dynamic-data"
    :class="{ 'dynamic-data--loading': loading, 'dynamic-data--small': small }"
  >
    <Loader :large="true" :visible="loading" />
    <transition appear name="transition">
      <div v-if="hasFinishedInitialLoad" class="dynamic-data__content">
        <slot />
      </div>
    </transition>
  </div>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: "DynamicData",
  components: {
    Loader,
  },
  props: {
    loading: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
    transition: {
      default: "fade-slide",
      type: String,
    },
  },
  data() {
    return {
      hasFinishedInitialLoad: false,
      wasLoadingOnCreation: false,
    };
  },
  watch: {
    loading(newValue) {
      if (!this.hasFinishedInitialLoad) {
        if (this.wasLoadingOnCreation) {
          this.hasFinishedInitialLoad = true;
          return;
        }
        if (newValue) {
          this.hasFinishedInitialLoad = true;
        }
      }
    },
  },
  created() {
    this.wasLoadingOnCreation = this.loading;

    if (!this.loading) {
      this.hasFinishedInitialLoad = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.dynamic-data {
  position: relative;
  width: 100%;

  .block--product & {
    align-items: center;
    display: flex;
    height: 100%;
  }

  .block &,
  .catalogues &,
  .dashboard__block:last-child & {
    &,
    & > *:not(.loader) {
      height: 100%;
      width: 100%;
    }
  }

  .dashboard__block--blocks & {
    @media (min-width: 80rem) {
      &,
      & > *:not(.loader) {
        height: 100%;
      }
    }
  }

  &--loading {
    min-height: calc(var(--size-loader-large) * 3);

    &.dynamic-data--small {
      min-height: calc(var(--size-loader) * 3);
    }
  }

  &__content {
    width: 100%;
  }
}
</style>
