import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("branding", ["brand", "brands"]),
    ...mapGetters("users", ["allowedDivisionsForCurrentUser"]),
    allowedDivisionBrands() {
      if (!this.allowedDivisionsForCurrentUser || !this.brands) return [];

      const allowedDivisonsInLowerCase = this.allowedDivisionsForCurrentUser
        .flat()
        .filter(division => division)
        .map(division => division.toLowerCase());

      if (!allowedDivisonsInLowerCase || !allowedDivisonsInLowerCase.length) {
        return [];
      }

      return this.brands
        .filter(brand =>
          allowedDivisonsInLowerCase.includes(brand.divisionId.toLowerCase()),
        )
        .sort((a, b) => a.divisionId.localeCompare(b.divisionId));
    },
  },
};
