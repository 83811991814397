import { mapActions, mapGetters } from "vuex";
import { environmentVariables } from "@/helpers/environmentVariables";
import { startPages } from "@/helpers/startPages";

export default {
  computed: {
    ...mapGetters("authentication", [
      "divisionCode",
      "doesUserHaveShoppingContextType",
      "isUserCustomerRepresentative",
      "isUserDealer",
      "isUserEndCustomer",
      "isUserSalesperson",
      "isUserSystemadministrator",
      "onBehalfUserName",
      "userConfiguration",
    ]),
    ...mapGetters("branding", ["canonicalWebsiteUrl", "divisionId"]),
    ...mapGetters("customers", [
      "currentCustomerId",
      "isCurrentCustomerDealer",
      "isCurrentCustomerEndCustomer",
    ]),
    hasAccessToAutomaticDeliveryAddress() {
      return (
        this.userHasPermissionTo("ManageEndCustomer") && !this.isUserEndCustomer
      );
    },
    hasAccessToChangeCheckoutAddress() {
      return this.userHasPermissionTo("ChangeCheckoutAddress");
    },
    hasAccessToCreateDealerAddress() {
      return this.userHasPermissionTo("ManageCustomerAddress");
    },
    hasAccessToCreateEndCustomerAddress() {
      return this.userHasPermissionTo("ManageCustomerAddress");
    },
    hasAccessToCreateUserGroup() {
      return this.userHasPermissionTo("ManageAllGroups");
    },
    hasAccessToCreateUserGroupAddress() {
      return this.userHasPermissionTo("ManageGroupAddress");
    },
    hasAccessToCustomer() {
      return (
        this.isUserSalesperson ||
        this.userHasPermissionTo("ManageDealer") ||
        this.userHasPermissionTo("ManageEndCustomer")
      );
    },
    hasAccessToCustomerAddresses() {
      return this.userHasPermissionTo("ManageCustomerAddress");
    },
    hasAccessToCustomerGroups() {
      return (
        this.isCurrentCustomerEndCustomer &&
        (this.userHasPermissionTo("ManageAllGroups") ||
          this.userHasPermissionTo("ManageSpecificGroups"))
      );
    },
    hasAccessToCustomerOrders() {
      return (
        this.userHasPermissionTo("ManageOrders") ||
        this.userHasPermissionTo("ManageGroupOrders")
      );
    },
    hasAccessToCustomerPrices() {
      return (
        this.isCurrentCustomerEndCustomer &&
        this.userHasPermissionTo("ManagePrices")
      );
    },
    hasAccessToCustomerRoles() {
      if (this.isUserSalesperson || this.isUserSystemadministrator) return true;

      if (
        this.userConfiguration &&
        this.userConfiguration.contextInternalCustomerId ===
          this.currentCustomerId
      ) {
        return false;
      }

      return (
        this.userHasPermissionTo("ManageDealer") ||
        this.userHasPermissionTo("ManageEndCustomer")
      );
    },
    hasAccessToCustomerService() {
      return this.isUserSystemadministrator;
    },
    hasAccessToCustomerUsers() {
      return this.userHasPermissionTo("ManageUser");
    },
    hasAccessToCustomerWardrobes() {
      return (
        this.isCurrentCustomerEndCustomer &&
        this.userHasPermissionTo("ManageWardrobes")
      );
    },
    hasAccessToDashboardForEndCustomerAdministrators() {
      return (
        this.isUserEndCustomer && this.userHasPermissionTo("AccessDashboards")
      );
    },
    hasAccessToEndCustomers() {
      return this.isUserDealer || this.isCurrentCustomerDealer;
    },
    hasAccessToExternalProducts() {
      return (
        (this.isUserDealer || this.isCurrentCustomerDealer) &&
        this.userHasPermissionTo("ManageProducts")
      );
    },
    hasAccessToImportCustomerAddressses() {
      return (
        this.userHasPermissionTo("ManageDealer") ||
        this.userHasPermissionTo("ManageEndCustomer")
      );
    },
    hasAccessToImportOrders() {
      return this.userHasPermissionTo("ManageOrders");
    },
    hasAccessToImportUserAddresses() {
      return this.userHasPermissionTo("ManageUser");
    },
    hasAccessToImportUserGroupAddresses() {
      return this.userHasPermissionTo("ManageAllGroups");
    },
    hasAccessToImportUsers() {
      return this.userHasPermissionTo("ManageUser");
    },
    hasAccessToImportWalletTransactions() {
      return this.userHasPermissionTo("ManageUser");
    },
    hasAccessToManageDashboards() {
      return this.userHasPermissionTo("ManageDashboards");
    },
    hasAccessToManageUserAddresses() {
      return this.userHasPermissionTo("ManageUserAddress");
    },
    hasAccessToOrderManagement() {
      return (
        this.userHasPermissionTo("ManageOrders") ||
        this.userHasPermissionTo("ManageGroupOrders")
      );
    },
    hasAccessToOrderNotifications() {
      return (
        ((this.isUserDealer || this.isUserEndCustomer) &&
          this.userHasPermissionTo("ManageOrders")) ||
        this.userHasPermissionTo("ManageGroupOrders")
      );
    },
    hasAccessToProductSheet() {
      return this.userHasPermissionTo("ManageProductSheet");
    },
    hasAccessToRoleTemplates() {
      return this.isUserSystemadministrator;
    },
    hasAccessToSalespersons() {
      return (
        this.isUserCustomerRepresentative || this.isUserSystemadministrator
      );
    },
    hasAccessToShop() {
      return this.doesUserHaveShoppingContextType;
    },
    hasAccessToSystemadministrators() {
      return this.isUserSystemadministrator;
    },
    hasAccessToUserAddresses() {
      return this.userHasPermissionTo("ManageUserAddress");
    },
    isEnvironmentLocal() {
      return environmentVariables.environment === "local";
    },
    isEnvironmentProduction() {
      return environmentVariables.environment === "production";
    },
    isEnvironmentStaging() {
      return environmentVariables.environment === "staging";
    },
    isEnvironmentTest() {
      return environmentVariables.environment === "test";
    },
    userHasPermissionTo() {
      return permission => {
        const { userConfiguration } = this;

        if (
          !permission ||
          !userConfiguration ||
          !userConfiguration.permissions
        ) {
          return false;
        }

        if (this.isUserSystemadministrator) return true;

        return userConfiguration.permissions.some(
          permissionEntry => permissionEntry === permission,
        );
      };
    },
  },
  methods: {
    ...mapActions("branding", ["GET_BRAND_BY_DIVISION"]),
    getStartPageForCurrentUser() {
      if (this.onBehalfUserName) return startPages.USER;
      if (this.isUserSystemadministrator) return startPages.SYSTEMADMINISTRATOR;
      if (this.isUserSalesperson) return startPages.SALESPERSON;
      if (this.isUserCustomerRepresentative) {
        return startPages.CUSTOMER_REPRESENTATIVE;
      }

      return startPages.USER;
    },
    isEnvironment(environment) {
      //Available environments: local, test, staging, production
      return environment === environmentVariables.environment;
    },
    redirectToMatchingDivision() {
      const {
        $route,
        GET_BRAND_BY_DIVISION,
        canonicalWebsiteUrl,
        divisionCode,
        divisionId,
        isEnvironmentLocal,
        isUserSalesperson,
        isUserSystemadministrator,
        onBehalfUserName,
        redirectToNoAccessView,
      } = this;
      if (!$route || !$route.name) return;
      if (onBehalfUserName) {
        if (
          (isUserSalesperson || isUserSystemadministrator) &&
          $route.name.includes("administration")
        ) {
          return;
        }
      } else {
        if (isUserSalesperson || isUserSystemadministrator) return;
      }
      if ($route.name.includes("noAccess")) return;
      if (!divisionId || !divisionCode || !canonicalWebsiteUrl) return;
      if (
        divisionId.toLowerCase() === divisionCode.toLowerCase() &&
        canonicalWebsiteUrl === window.location.host
      ) {
        return;
      }
      GET_BRAND_BY_DIVISION({ division: divisionCode })
        .then(data => {
          if (!data.canonicalWebsiteUrl) {
            redirectToNoAccessView();
            return;
          }
          window.location.replace(
            `${isEnvironmentLocal ? "https" : "https"}://${
              data.canonicalWebsiteUrl
            }`,
          );
        })
        .catch(() => {
          redirectToNoAccessView();
        });
    },
    redirectToNoAccessView() {
      if (this.$route.path.toLowerCase().startsWith("/administration")) {
        this.$router.replace({ name: "administration.noAccess" });
      } else {
        this.$router.replace({ name: "noAccess" });
      }
    },
  },
};
