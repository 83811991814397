import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  parcelShops: [],
  status: "",
});

const getters = {
  parcelShopById: state => id =>
    state.parcelShops.find(parcelShop => parcelShop.id === id),
  parcelShops: state => state.parcelShops,
  statusOfParcelShops: state => state.status,
};

const actions = {
  GET_PARCEL_SHOPS({ commit }, payload) {
    commit("SET_PARCEL_SHOPS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/parcelshop/search`, {
        countryCode: payload.countryCode,
        street: payload.street,
        postCode: payload.postalCode,
        parcelShopType: "gls",
      })
      .then(({ data }) => {
        commit("SET_PARCEL_SHOPS_STATUS", { status: statuses.SUCCESS });
        commit("SET_PARCEL_SHOPS", {
          parcelShops: data,
        });
      })
      .catch(error => {
        commit("SET_PARCEL_SHOPS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_PARCEL_SHOPS(state, payload) {
    state.parcelShops = payload.parcelShops;
  },
  SET_PARCEL_SHOPS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
