<template>
  <button
    class="button"
    :class="{
      'button--buy': buy,
      'button--dark': dark,
      'button--extra-small': extraSmall,
      'button--faded': faded,
      'button--limited-width': limitedWidth,
      'button--link': link,
      'button--loading': internalShowLoader,
      'button--no-shadow': noShadow,
      'button--rotate-icon': rotateIcon,
      'button--round': round,
      'button--secondary': secondary,
      'button--small': small,
      'button--space-between': showArrow,
    }"
    v-bind="{ ...$attrs, ...$props }"
    @click="$emit('click', $event)"
  >
    <Loader :light="!secondary" :visible="showLoader" />
    <span class="button__icon-container">
      <svg
        v-if="showUser"
        xmlns="http://www.w3.org/2000/svg"
        class="button__icon"
        viewBox="0 0 24 24"
      >
        <path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2" />
        <circle cx="12" cy="7" r="4" />
      </svg>
      <slot />
    </span>
    <svg
      v-if="showArrow"
      xmlns="http://www.w3.org/2000/svg"
      class="button__icon"
      viewBox="0 0 24 24"
    >
      <defs />
      <path d="M5 12h14M12 5l7 7-7 7" />
    </svg>
    <svg
      v-else-if="showClose"
      xmlns="http://www.w3.org/2000/svg"
      class="button__icon"
      viewBox="0 0 24 24"
    >
      <path d="M18 6 6 18M6 6l12 12" />
    </svg>
    <svg
      v-else-if="showDownload"
      xmlns="http://www.w3.org/2000/svg"
      class="button__icon"
      viewBox="0 0 24 24"
    >
      <path
        d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4M7 10l5 5 5-5M12 15V3"
      />
    </svg>
    <svg
      v-else-if="showEye"
      xmlns="http://www.w3.org/2000/svg"
      class="button__icon"
      viewBox="0 0 24 24"
    >
      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z" />
      <circle cx="12" cy="12" r="3" />
    </svg>
    <svg
      v-else-if="showPlus"
      xmlns="http://www.w3.org/2000/svg"
      class="button__icon"
      viewBox="0 0 24 24"
    >
      <defs />
      <path d="M12 5v14M5 12h14" />
    </svg>
    <svg
      v-else-if="showChevron"
      xmlns="http://www.w3.org/2000/svg"
      class="button__icon"
      viewBox="0 0 24 24"
    >
      <path d="m6 9 6 6 6-6" />
    </svg>
  </button>
</template>

<script>
import Loader from "@/components/Loader";

export default {
  name: "Button",
  components: {
    Loader,
  },
  props: {
    buy: {
      default: false,
      type: Boolean,
    },
    dark: {
      default: false,
      type: Boolean,
    },
    extraSmall: {
      default: false,
      type: Boolean,
    },
    faded: {
      default: false,
      type: Boolean,
    },
    flat: {
      default: false,
      type: Boolean,
    },
    limitedWidth: {
      default: false,
      type: Boolean,
    },
    link: {
      default: false,
      type: Boolean,
    },
    noShadow: {
      default: false,
      type: Boolean,
    },
    rotateIcon: {
      default: false,
      type: Boolean,
    },
    round: {
      default: false,
      type: Boolean,
    },
    secondary: {
      default: false,
      type: Boolean,
    },
    showArrow: {
      default: false,
      type: Boolean,
    },
    showChevron: {
      default: false,
      type: Boolean,
    },
    showClose: {
      default: false,
      type: Boolean,
    },
    showDownload: {
      default: false,
      type: Boolean,
    },
    showEye: {
      default: false,
      type: Boolean,
    },
    showLoader: {
      default: false,
      type: Boolean,
    },
    showPlus: {
      default: false,
      type: Boolean,
    },
    showUser: {
      default: false,
      type: Boolean,
    },
    small: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      internalShowLoader: false,
      interval: null,
    };
  },
  watch: {
    showLoader(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.interval = window.setInterval(() => {
          this.internalShowLoader = true;
        }, 100);
      } else if (!newValue) {
        window.clearInterval(this.interval);
        this.internalShowLoader = false;
      }
    },
  },
};
</script>

<style lang="scss">
.button {
  @include reset-button();

  align-items: center;
  background-color: var(--color-button-background);
  border: 1px solid var(--color-button-border);
  border-radius: 0.25rem;
  box-shadow: var(--elevation-4);
  color: var(--color-button-text);
  display: flex;
  font-size: 0.875rem;
  font-weight: 500;
  justify-content: center;
  padding: var(--spacing-button);
  position: relative;
  text-align: center;
  text-decoration: none;
  transition: background-color 300ms ease, border-color 300ms ease,
    box-shadow 300ms ease, color 300ms ease, opacity 300ms ease;
  width: 100%;

  .action-footer & {
    &:not(:first-child) {
      margin-left: var(--spacing-container);
    }
  }

  .header__actions & {
    white-space: nowrap;

    &:not(:first-child) {
      margin-left: var(--spacing-container);
    }
  }

  .modal__footer & {
    &:not(:first-child) {
      margin-left: var(--spacing-modal);

      @media (min-width: 48rem) {
        margin-left: var(--spacing-modal-large);
      }
    }
  }

  &:disabled {
    background-color: var(--color-button-background-disabled) !important;
    border-color: var(--color-button-border-disabled) !important;
    opacity: 0.75;
    pointer-events: none;
  }

  &:focus {
    box-shadow: var(--elevation-8);
    outline: none;
  }

  &:hover {
    background-color: var(--color-button-background-active);
    border-color: var(--color-button-border-active);
  }

  &--buy {
    background-color: var(--color-button-buy-background);
    border: 1px solid var(--color-button-buy-border);

    .brand-dh & {
      background-color: var(--color-button-buy-background-deerhunter);
    }

    &:disabled {
      background-color: var(--color-button-buy-background-disabled) !important;
      border-color: var(--color-button-buy-border-disabled) !important;
      opacity: 0.75;
      pointer-events: none;
    }

    &:hover {
      background-color: var(--color-button-buy-background-active);
      border-color: var(--color-button-buy-border-active);

      .brand-dh & {
        background-color: var(--color-button-buy-background-deerhunter-active);
      }
    }
  }

  &--dark {
    background-color: var(--color-button-dark-background);
    border: 1px solid var(--color-button-dark-border);
    box-shadow: none;

    &:disabled {
      background-color: var(--color-button-dark-background-disabled) !important;
      border-color: var(--color-button-dark-border-disabled) !important;
      opacity: 0.75;
      pointer-events: none;
    }

    &:hover {
      background-color: var(--color-button-dark-background-active);
      border-color: var(--color-button-dark-border-active);
    }
  }

  &--extra-small {
    font-size: 0.8125rem;
    padding: var(--spacing-button-extra-small);
  }

  &--faded {
    background-color: transparent !important;
  }

  &--limited-width {
    width: auto;
  }

  &--link {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: inherit !important;
    font-weight: 700;
    padding: 0;
    transition: none;
    width: auto;
  }

  &--loading {
    color: transparent !important;
  }

  &--no-shadow {
    box-shadow: none !important;
  }

  &--round {
    border-radius: 2rem;
    box-shadow: none !important;
  }

  &--secondary {
    background-color: var(--color-button-secondary-background);
    border-color: var(--color-button-secondary-border);
    box-shadow: var(--elevation-2);
    color: var(--color-button-secondary-text);

    &:disabled {
      background-color: var(
        --color-button-secondary-background-disabled
      ) !important;
      border-color: var(--color-button-secondary-border-disabled) !important;
    }

    &:focus {
      box-shadow: var(--elevation-2);
    }

    &:focus,
    &:hover {
      background-color: var(--color-button-secondary-background-active);
      border-color: var(--color-button-secondary-border-active);
    }

    &.button--no-shadow {
      border-color: var(--color-button-background);

      &:hover {
        border-color: var(--color-button-background-active);
      }
    }
  }

  &--small {
    padding: var(--spacing-button-small);
  }

  &--space-between {
    justify-content: space-between;
  }

  &__icon {
    fill: none;
    height: 1rem;
    margin: -1px 0.625rem 0;
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 3;
    stroke: var(--color-button-text);
    transition: stroke 300ms ease, transform 300ms ease;
    width: 1rem;

    &:first-child {
      margin: -1px 0.625rem 0 0;
    }

    &:last-child {
      margin: -1px 0 0 0.625rem;
    }

    .button--link &,
    .button--secondary & {
      stroke: var(--color-button-secondary-text);
    }

    .button--loading & {
      stroke: transparent;
    }

    .button--rotate-icon & {
      transform: rotate(180deg);
    }

    &-container {
      align-items: center;
      display: flex;
    }
  }
}
</style>
