<template>
  <DynamicData
    class="basket-line-list"
    :class="{ 'basket-line-list--read-only': readOnly }"
    :loading="isLoading || isUpdatingBasket"
  >
    <transition-group
      tag="ul"
      class="basket-line-list__list"
      name="fade-slide"
      appear=""
    >
      <li
        v-for="(basketLine, index) in basketLinesGroupedByVariantsWithShopTo"
        :key="basketLine.id"
        class="basket-line-list__item"
        :style="{ '--index': index }"
      >
        <article v-if="basketLine.removable">
          <div class="product">
            <div class="product__image">
              <BasketLineProductImage :image="basketLine.image" />
            </div>
            <div class="product__information">
              <Stack :quarter="true">
                <h1 class="product__title">
                  {{ basketLine.productName }}
                </h1>
              </Stack>
              <Stack :quarter="true">
                <InformationList :information="{ id: basketLine.id }" />
              </Stack>
              <Stack v-if="basketLine.swatch" :quarter="true">
                <BasketLineSwatch :swatch="basketLine.swatch" />
              </Stack>
              <Stack
                :half="true"
                class="product__sizes-and-quantities product__sizes-and-quantities--desktop-only"
              >
                <BasketLineSizes
                  :desktop-only="true"
                  :sizes-with-quantities="basketLine.sizesWithQuantities"
                />
              </Stack>
            </div>
            <div
              class="product__sizes-and-quantities product__sizes-and-quantities--mobile-only"
            >
              <BasketLineSizes
                :mobile-only="true"
                :sizes-with-quantities="basketLine.sizesWithQuantities"
              />
            </div>
            <div v-if="basketLine.totalPrice" class="product__numbers">
              <Stack>
                <div class="product__price">
                  <div class="product__price product__price--sum">
                    {{ formatPriceCurrency(basketLine.totalPrice) }}
                    {{ basketLine.currency }}
                  </div>
                </div>
              </Stack>
              <Stack :half="true">
                <div
                  v-for="unitPrice in basketLine.pricesWithQuantities"
                  :key="unitPrice"
                  class="product__unit-with-price"
                >
                  {{ unitPrice }}
                </div>
              </Stack>
            </div>
            <ol
              v-if="basketLine.references && basketLine.references.length"
              class="product__references"
            >
              <li
                v-for="reference in basketLine.references"
                :key="basketLine.id + reference.id"
                class="product__reference"
              >
                + {{ reference.sku }} {{ reference.productName }}
              </li>
            </ol>
            <div class="product__employees">
              <BasketLineEmployees :employees="basketLine.usersToShopTo" />
            </div>
          </div>
        </article>

        <article v-else class="product">
          <div v-if="basketLine.image" class="product__icon-container">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="product__icon"
              viewBox="0 0 24 24"
            >
              <path
                d="m16.5 9.4-9-5.19M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z"
              />
              <path d="M3.27 6.96 12 12.01l8.73-5.05M12 22.08V12" />
            </svg>
          </div>
          <div class="product__text">
            <div>
              <Stack :quarter="true">
                <h1 class="product__title">{{ basketLine.productName }}</h1>
              </Stack>
              <Stack :quarter="true">
                <InformationList :information="basketLine" />
              </Stack>
            </div>
          </div>
          <div class="product__numbers">
            <Stack :half="true" class="product__numbers-stack">
              <div
                v-if="basketLine.totalLinePrice"
                class="product__price product__price--sum"
              >
                {{ formatPriceCurrency(basketLine.totalLinePrice.price) }}
                {{ basketLine.totalLinePrice.currency }}
              </div>
            </Stack>
          </div>
        </article>
      </li>
    </transition-group>
  </DynamicData>
</template>

<script>
import groupBy from "lodash.groupby";
import { mapGetters } from "vuex";
import BasketLineEmployees from "@/components/BasketLineEmployees";
import BasketLineProductImage from "@/components/BasketLineProductImage";
import BasketLineSizes from "@/components/BasketLineSizes";
import BasketLineSwatch from "@/components/BasketLineSwatch";
import DynamicData from "@/components/DynamicData";
import InformationList from "@/components/InformationList";
import Stack from "@/components/Stack";
import { orderOfSizes } from "@/helpers/orderOfSizes";
import { statuses } from "@/helpers/statuses";

export default {
  name: "BasketLinelistForShoppingToEmployees",
  components: {
    BasketLineEmployees,
    BasketLineProductImage,
    BasketLineSizes,
    BasketLineSwatch,
    DynamicData,
    InformationList,
    Stack,
  },
  props: {
    basketLines: {
      required: true,
      type: Array,
    },
    readOnly: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      selectedVariants: [],
    };
  },
  computed: {
    ...mapGetters("basket", ["statusOfBasket"]),
    basketLinesGroupedByVariantsWithShopTo() {
      const {
        basketLines,
        getPricesWithQuantities,
        getSizesWithQuantities,
      } = this;

      if (!basketLines || !basketLines.length) return [];

      const nonReferenceLines = basketLines.filter(
        basketLine => !basketLine.referenceId,
      );
      const referenceLines = basketLines.filter(
        basketLine => basketLine.referenceId,
      );

      const linesWithGroupedReferences = nonReferenceLines.map(line => ({
        ...line,
        references: referenceLines.filter(
          referenceLine => referenceLine.referenceId === line.id,
        ),
      }));

      const basketLinesGroupedByVariants = groupBy(
        linesWithGroupedReferences,
        "variantId",
      );

      const variants = [];

      Object.entries(basketLinesGroupedByVariants).forEach(
        ([variantId, basketLinesForVariant]) => {
          const {
            currency,
            fit,
            gender,
            image,
            productId,
            productName,
            references,
            removable,
            swatch,
            variantName,
          } = basketLinesForVariant[0];

          const basketLineGroupedByUserToShopTo = groupBy(
            basketLinesForVariant,
            "shopToUserId",
          );

          const usersToShopTo = [];

          Object.entries(basketLineGroupedByUserToShopTo).forEach(
            ([userId, basketLinesForUserToShopTo]) => {
              const totalPrice = this.getTotalPrice(basketLinesForUserToShopTo);

              const {
                shopToUserEmployeeNumber,
                shopToUserGroupName,
                shopToUserUserName,
              } = basketLinesForUserToShopTo[0];

              usersToShopTo.push({
                groupName: shopToUserGroupName,
                id: userId,
                name: shopToUserUserName,
                number: shopToUserEmployeeNumber,
                pricesWithQuantities: getPricesWithQuantities(
                  basketLinesForUserToShopTo,
                ),
                sizesWithQuantities: getSizesWithQuantities(
                  basketLinesForUserToShopTo,
                ),
                unitPrice: basketLinesForUserToShopTo[0].unitPrice,
                totalPrice,
              });
            },
          );

          const totalPrice = this.getTotalPrice(basketLinesForVariant);

          variants.push({
            currency,
            fit,
            gender,
            id: variantId || productId,
            image,
            name: variantName,
            productId,
            pricesWithQuantities: getPricesWithQuantities(
              basketLinesForVariant,
            ),
            productName,
            references,
            removable,
            sizesWithQuantities: getSizesWithQuantities(basketLinesForVariant),
            swatch,
            totalPrice,
            usersToShopTo,
          });
        },
      );

      return variants;
    },
    isLoading() {
      if (this.readOnly) return false;

      return this.statusOfBasket === statuses.LOADING;
    },
    isUpdatingBasket() {
      return this.statusOfBasket === statuses.SAVING;
    },
  },
  methods: {
    getPricesWithQuantities(basketLines) {
      if (!basketLines || !basketLines.length) return [];

      const groupedPricesAndQuantities = groupBy(
        basketLines
          .filter(basketLine => basketLine.removable)
          .map(({ quantity, unitPrice: { price, priceValue } }) => ({
            price,
            priceValue,
            quantity,
          })),
        "price",
      );

      const combinedPricesAndQuantities = [];

      Object.entries(groupedPricesAndQuantities).forEach(
        ([price, pricesAndQuantities]) => {
          const quantity = pricesAndQuantities.reduce((sum, { quantity }) => {
            return sum + quantity;
          }, 0);

          combinedPricesAndQuantities.push({
            quantity,
            price,
            priceValue: pricesAndQuantities[0].priceValue,
          });
        },
      );

      return combinedPricesAndQuantities
        .sort((a, b) => {
          return a.priceValue - b.priceValue;
        })
        .map(
          priceAndQuantity =>
            `${priceAndQuantity.quantity} x ${this.formatPriceCurrency(
              priceAndQuantity.price,
            )} ${basketLines[0].currency}`,
        );
    },
    getSizesWithQuantities(basketLines) {
      if (!basketLines || !basketLines.length) return [];

      return basketLines
        .filter(basketLine => basketLine.removable)
        .map(({ id, quantity, sizeChart, sku }) => ({
          basketLineId: id,
          isUpdating: false,
          quantity,
          size: this.getPreferredSizeChart(sizeChart),
          sku,
        }))
        .sort((a, b) => {
          const sizeA = a.size || "";
          const sizeB = b.size || "";

          if (
            orderOfSizes.includes(sizeA.toUpperCase()) &&
            orderOfSizes.includes(sizeB.toUpperCase())
          ) {
            return (
              orderOfSizes.indexOf(sizeA.toUpperCase()) -
              orderOfSizes.indexOf(sizeB.toUpperCase())
            );
          }

          return sizeA - sizeB;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.basket-line-list {
  &__item {
    position: relative;

    &::before {
      border-top: 1px solid var(--color-table-border);
      content: "";
      height: 1px;
      left: var(--spacing-card);
      position: absolute;
      right: var(--spacing-card);
      top: -1px;

      @media (min-width: 48rem) {
        left: var(--spacing-card-large);
        right: var(--spacing-card-large);
      }
    }

    &:first-child::before {
      border-top: 0;
    }

    &:first-child {
      .receipt & {
        border-top: 1px solid rgba(0, 0, 0, 0.075);
      }
    }

    &:last-child {
      .receipt & {
        margin-bottom: calc(var(--spacing-stack) * 0.75);
      }
    }
  }

  &__list {
    @include reset-list();

    .card & {
      margin-left: calc(var(--spacing-card) * -1);
      width: calc(100% + var(--spacing-card) * 2);

      @media (min-width: 48rem) {
        margin-left: calc(var(--spacing-card-large) * -1);
        width: calc(100% + var(--spacing-card-large) * 2);
      }
    }
  }
}

.fade-slide {
  &-enter-active {
    transition-delay: calc(50ms * var(--index)) !important;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease, transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.product {
  color: inherit;
  display: flex;
  flex-flow: row wrap;
  font-size: 0.875rem;
  font-weight: 400;
  text-decoration: none;

  .card & {
    padding: calc(var(--spacing-stack) * 0.75) var(--spacing-card);

    @media (min-width: 48rem) {
      padding: var(--spacing-card-large);
    }
  }

  .sidebar & {
    padding: calc(var(--spacing-stack) * 0.75) var(--spacing-sidebar);

    @media (min-width: 64rem) {
      padding-left: var(--spacing-sidebar-large);
      padding-right: var(--spacing-sidebar-large);
    }
  }

  &__employees {
    flex: 0 0 100%;
    max-width: 60ch;

    @media (min-width: 48rem) {
      flex: 0 0 calc(100% - 5rem - 1rem);
      margin-left: calc(5rem + 1rem);
      order: 6;
    }

    .basket-line-list--read-only & {
      @media (min-width: 48rem) {
        flex: 0 0 calc(100% - 4rem - 1rem);
        margin-left: calc(4rem + 1rem);
      }
    }

    .sidebar & {
      margin-top: 1rem;
    }
  }

  &__image {
    flex: 0 0 auto;
    margin-right: 1rem;
    width: 4.1875rem;

    @media (min-width: 64rem) {
      width: 5rem;
    }

    .basket-line-list--read-only & {
      width: 4rem;
    }

    .card & {
      @media (min-width: 48rem) {
        order: 1;
      }
    }
  }

  &__information {
    flex: 0 0 calc(100% - 4.1875rem - 1rem);
    overflow: hidden;

    @media (min-width: 48rem) {
      flex-basis: calc(100% - 4.1875rem - 2rem - 7.5rem - 1rem);
      margin-right: 1rem;
    }

    @media (min-width: 64rem) {
      flex-basis: calc(100% - 5rem - 2rem - 7.5rem - 1rem);
    }

    .card & {
      @media (min-width: 48rem) {
        flex-basis: calc(100% - 4.1875rem - 2rem - 7.5rem - 2rem);
        margin-right: 2rem;
        order: 2;
      }

      @media (min-width: 64rem) {
        flex-basis: calc(100% - 5rem - 2rem - 7.5rem - 2rem);
      }

      @media (min-width: 100rem) {
        flex-basis: calc(100% - 5rem - 2rem - 7.5rem - 2rem);
      }
    }
  }

  &__numbers {
    flex: 0 0 100%;
    text-align: right;

    @media (min-width: 48rem) {
      flex-basis: 7.5rem;
    }

    @media (min-width: 64rem) {
      flex-basis: 9.5rem;
    }

    .card & {
      margin: calc(var(--spacing-stack) * 0.75) 0;

      @media (min-width: 48rem) {
        margin: 0;
        order: 4;
      }
    }

    .sidebar & {
      @media (min-width: 48rem) {
        flex-basis: 8.5rem;
      }
    }
  }

  &__price {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 500;
    white-space: nowrap;

    &--sum {
      color: var(--color-text-primary);
      display: block;
      font-size: 0.875rem;
      font-weight: 700;

      @media (min-width: 23.4375rem) {
        font-size: 1rem;
      }
    }
  }

  &__reference {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }
  }

  &__references {
    @include reset-list();

    font-size: 0.8125rem;
    margin-top: calc(var(--spacing-stack) * 0.5);

    @media (min-width: 48rem) {
      margin-left: calc(5rem + 1rem);
    }

    .basket-line-list--read-only & {
      margin-bottom: calc(var(--spacing-stack) * 0.5);

      @media (min-width: 48rem) {
        flex: 0 0 100%;
        margin-left: calc(4rem + 1rem);
        order: 5;
      }
    }
  }

  &__sizes-and-quantities {
    flex: 0 0 calc(100% - 3.125rem - 1rem);

    .card & {
      margin: calc(var(--spacing-stack) * 0.75) 1rem
        calc(var(--spacing-stack) * 0.75) 0;
    }

    &--desktop-only {
      display: none;

      @media (min-width: 48rem) {
        display: block;
      }
    }

    &--mobile-only {
      flex: 0 0 calc(100% - 7.5rem - 1rem);

      @media (min-width: 48rem) {
        display: none;
      }
    }
  }

  &__title {
    font-size: 0.9375rem;
    line-height: 1.15;
    margin: 0;

    .basket-line-list--read-only & {
      font-size: 0.875rem;
    }
  }

  &__unit-with-price {
    color: rgba(0, 0, 0, 0.5);
    font-size: 0.875rem;
    font-weight: 500;
    text-align: right;
    white-space: nowrap;

    &:not(:last-child) {
      margin-bottom: 0.125rem;
    }
  }
}
</style>
