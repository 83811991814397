var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:{
    'block--darken-text': _vm.blockData.darkenText,
    'block block--hero block__media-container': _vm.blockData.fullWidthImage,
    'block__media-container--overlay':
      _vm.blockData.fullWidthImage && !_vm.blockData.darkenText,
  }},[(_vm.blockData.image && _vm.blockData.fullWidthImage)?_c('ImageFromUmbraco',{staticClass:"block__image",attrs:{"id":_vm.blockData.image._system.id,"alt":_vm.blockData.image.alternateText,"height":_vm.blockData.image.umbracoHeight,"keys-and-sizes":{
      s: 'block-regular-small',
      m: 'block-hero-wide-medium',
      l: 'block-hero-wide-large',
      xl: 'block-hero-wide-extra-large',
      xxl: 'block-hero-wide-extra-extra-large',
    },"width":_vm.blockData.image.umbracoWidth}}):_vm._e(),(_vm.blockData)?_c('Container',[_c('div',{staticClass:"block block--hero",class:{
        'block--darken-text': _vm.blockData.darkenText,
        'block__media-container': !_vm.blockData.fullWidthImage,
        'block__media-container--overlay':
          !_vm.blockData.fullWidthImage && !_vm.blockData.darkenText,
      }},[(_vm.blockData.image && !_vm.blockData.fullWidthImage)?_c('ImageFromUmbraco',{staticClass:"block__image",attrs:{"id":_vm.blockData.image._system.id,"alt":_vm.blockData.image.alternateText,"height":_vm.blockData.image.umbracoHeight,"keys-and-sizes":{
          s: 'block-regular-small',
          m: 'block-hero-medium',
          l: 'block-hero-large',
          xl: 'block-hero-extra-large',
        },"width":_vm.blockData.image.umbracoWidth}}):_vm._e(),_c('div',{staticClass:"block__100-percent"},[_c('div',{staticClass:"block__content block__content--limited-width",class:{
            'block__content--bottom': !_vm.blockData.centerText,
            'block__content--middle': _vm.blockData.centerText,
          }},[_c('div',[(_vm.blockData.caption)?_c('div',{staticClass:"block__keywords"},[_vm._v(" "+_vm._s(_vm.blockData.caption)+" ")]):_vm._e(),(_vm.blockData.headline)?_c('Heading',{staticClass:"block__title",attrs:{"tag":_vm.blockData.headlineTag}},[_vm._v(" "+_vm._s(_vm.blockData.headline)+" ")]):_vm._e(),(_vm.blockData.description)?_c('p',{staticClass:"block__description"},[_vm._v(" "+_vm._s(_vm.blockData.description)+" ")]):_vm._e(),(_vm.blockData.cTA && _vm.blockData.cTA.name && _vm.blockData.cTA.url)?_c('ConditionalRouterLink',{staticClass:"block__button",class:{
                'block__button--ghost': _vm.blockData.darkenText,
              },attrs:{"target":_vm.blockData.cTA.target,"to":_vm.blockData.cTA.url,"type":_vm.blockData.cTA.type}},[_vm._v(_vm._s(_vm.blockData.cTA.name))]):_vm._e()],1)])])],1)]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }