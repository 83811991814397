<template>
  <FocusTrap v-model="isOpen" :escape-deactivates="false">
    <transition name="fade-scale-subtle" appear="">
      <div v-if="isImageGalleryOpen" class="image-gallery">
        <div class="image-gallery__header">
          <div class="image-gallery__count">
            {{ currentIndex + 1 }}/{{ images.length }}
          </div>
          <button
            class="image-gallery__button image-gallery__button--close"
            :aria-label="getDictionaryEntry('Common.Close')"
            @click="handleClose"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 21 21"
              class="image-gallery__button-icon"
            >
              <defs />
              <g>
                <path d="M5.5 15.5l10-10M15.5 15.5l-10-10z" />
              </g>
            </svg>
          </button>
        </div>
        <transition-group name="fade" tag="ol" class="image-gallery__list">
          <li
            v-for="(image, index) in images"
            v-show="currentIndex === index"
            :key="image.src"
            class="image-gallery__item"
          >
            <img
              :src="image.src"
              :alt="image.alt"
              class="image-gallery__image"
              decoding="async"
              loading="lazy"
              @mousemove="handleMouseMove"
              @touchend="openMagnifier"
            />
            <div
              class="image-gallery__magnifier"
              :class="{ 'image-gallery__magnifier--active': isMagnifierOpen }"
              :style="{
                backgroundImage: `url(${images[currentIndex].src})`,
                backgroundPositionX: magnifierStyles.backgroundX,
                backgroundPositionY: magnifierStyles.backgroundY,
                left: magnifierStyles.left,
                top: magnifierStyles.top,
              }"
            />
          </li>
        </transition-group>
        <div class="image-gallery__footer">
          <button
            class="image-gallery__button image-gallery__button--previous"
            :aria-label="getDictionaryEntry('Common.Previous')"
            @click="SELECT_PREVIOUS_IMAGE"
          >
            <svg class="image-gallery__button-icon" viewBox="0 0 24 24">
              <defs />
              <path d="M15 18l-6-6 6-6" />
            </svg>
          </button>
          <transition name="fade">
            <button
              v-if="isMagnifierOpen"
              class="image-gallery__button image-gallery__button--zoom"
              @click="closeMagnifier"
            >
              {{ getDictionaryEntry("Common.CloseZoom") }}
            </button>
          </transition>
          <button
            class="image-gallery__button image-gallery__button--next"
            :aria-label="getDictionaryEntry('Common.Next')"
            @click="SELECT_NEXT_IMAGE"
          >
            <svg class="image-gallery__button-icon" viewBox="0 0 24 24">
              <defs />
              <path d="M9 18l6-6-6-6" />
            </svg>
          </button>
        </div>
      </div>
    </transition>
  </FocusTrap>
</template>

<script>
import { FocusTrap } from "focus-trap-vue";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "ImageGallery",
  components: {
    FocusTrap,
  },
  data() {
    return {
      isOpen: false,
      magnifierStyles: {
        backgroundX: 0,
        backgroundY: 0,
        left: 0,
        top: 0,
      },
      isMagnifierOpen: false,
    };
  },
  computed: {
    ...mapGetters("imageGallery", [
      "images",
      "isImageGalleryOpen",
      "selectedImage",
    ]),
    currentIndex() {
      const { images, selectedImage } = this;

      if (!selectedImage) return 0;

      const indexOfSelectedImage = images.findIndex(
        image => image.src === selectedImage.src,
      );

      if (indexOfSelectedImage === -1) return 0;

      return indexOfSelectedImage;
    },
  },
  watch: {
    isImageGalleryOpen(newValue) {
      this.isOpen = newValue;

      if (newValue) {
        if (!window.modals) window.modals = [];

        const componentId = this._uid;
        window.modals.push(componentId);
      }
    },
  },
  created() {
    const { SELECT_NEXT_IMAGE, SELECT_PREVIOUS_IMAGE, handleClose } = this;
    const componentId = this._uid;

    const handleEscape = event => {
      if (
        this.isImageGalleryOpen &&
        event.keyCode === 27 &&
        window.modals[window.modals.length - 1] === componentId
      ) {
        handleClose();
      }
    };

    const handleLeftArrow = event => {
      if (this.isImageGalleryOpen && event.keyCode === 37) {
        SELECT_PREVIOUS_IMAGE();
      }
    };

    const handleRightArrow = event => {
      if (this.isImageGalleryOpen && event.keyCode === 39) {
        SELECT_NEXT_IMAGE();
      }
    };

    document.addEventListener("keydown", handleEscape);
    document.addEventListener("keydown", handleLeftArrow);
    document.addEventListener("keydown", handleRightArrow);

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", handleEscape);
    });

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", handleLeftArrow);
    });

    this.$once("hook:destroyed", () => {
      document.removeEventListener("keydown", handleRightArrow);
    });
  },
  methods: {
    ...mapActions("imageGallery", [
      "CLOSE_IMAGE_GALLERY",
      "SELECT_NEXT_IMAGE",
      "SELECT_PREVIOUS_IMAGE",
    ]),
    closeMagnifier() {
      this.isMagnifierOpen = false;
    },
    handleClose() {
      this.CLOSE_IMAGE_GALLERY();

      const componentId = this._uid;
      window.modals = window.modals.filter(modal => modal !== componentId);
    },
    handleMouseMove(event) {
      const {
        srcElement: { height, width },
      } = event;
      const boundingClientRect = event.currentTarget.getBoundingClientRect();
      const sizeOfMagnifier = 250;

      const mouseCoordinates = {
        x: event.clientX - boundingClientRect.left,
        y: event.clientY - boundingClientRect.top,
      };

      const magnifierCoordinates = {
        x: boundingClientRect.left + mouseCoordinates.x,
        y: boundingClientRect.top + mouseCoordinates.y,
      };

      const percentageCoordinates = {
        x: (mouseCoordinates.x / width) * 100,
        y: (mouseCoordinates.y / height) * 100,
      };

      this.magnifierStyles = {
        backgroundX: `${percentageCoordinates.x}%`,
        backgroundY: `${percentageCoordinates.y}%`,
        left: `${magnifierCoordinates.x - sizeOfMagnifier / 2}px`,
        top: `${magnifierCoordinates.y - sizeOfMagnifier / 2}px`,
      };
    },
    openMagnifier() {
      this.isMagnifierOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
$image-gallery-icon-size: 2.25rem;

.image-gallery {
  background-color: var(--color-modal-background);
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  will-change: transform;
  z-index: 99;

  &__button {
    @include reset-button();

    transition: opacity 300ms ease;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &--zoom {
      font-size: 0.875rem;
      text-transform: uppercase;
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      height: $image-gallery-icon-size;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
      width: $image-gallery-icon-size;

      .image-gallery__button--next &,
      .image-gallery__button--previous & {
        stroke-width: 2;
      }
    }
  }

  &__count {
    font-size: 1.5rem;
  }

  &__footer {
    bottom: 0;
  }

  &__footer,
  &__header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    left: 0;
    padding: var(--spacing-container);
    position: absolute;
    right: 0;
    z-index: 1;
  }

  &__header {
    top: 0;
  }

  &__image {
    height: auto;
    left: 50%;
    max-height: calc(
      100vh - (4.5rem * 2)
    ); //account for modal header and footer
    max-width: calc(100vw - (var(--spacing-container) * 4));
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
  }

  &__list {
    @include reset-list();
  }

  &__magnifier {
    background-color: var(--color-modal-background);
    background-position: left top;
    background-repeat: no-repeat;
    border-radius: 50%;
    border: 6px solid var(--color-magnifier-border);
    box-shadow: var(--elevation-16);
    height: 250px;
    opacity: 0;
    pointer-events: none;
    position: absolute;
    transition: opacity 300ms ease, transform 300ms ease;
    transform: scale(0.25);
    width: 250px;
    z-index: 98;

    &--active,
    .image-gallery__image:active + &,
    .image-gallery__image:hover + & {
      opacity: 1;
      transform: scale(1);
    }
  }
}
</style>
