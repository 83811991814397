<template>
  <div
    class="stack"
    :class="{
      'stack--center-horizontally': centerHorizontally,
      'stack--center-vertically': centerVertically,
      'stack--double-spacing': double,
      'stack--half-spacing': half,
      'stack--no-spacing': noSpacing,
      'stack--one-and-a-half-spacing': oneAndHalf,
      'stack--quarter-spacing': quarter,
      'stack--space-between': spaceBetween,
      'stack--triple-spacing': triple,
    }"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "Stack",
  props: {
    centerHorizontally: {
      default: false,
      type: Boolean,
    },
    centerVertically: {
      default: false,
      type: Boolean,
    },
    double: {
      default: false,
      type: Boolean,
    },
    half: {
      default: false,
      type: Boolean,
    },
    noSpacing: {
      default: false,
      type: Boolean,
    },
    oneAndHalf: {
      default: false,
      type: Boolean,
    },
    quarter: {
      default: false,
      type: Boolean,
    },
    spaceBetween: {
      default: false,
      type: Boolean,
    },
    triple: {
      default: false,
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.stack {
  & + &,
  .resize-sensor + & {
    margin-top: var(--spacing-stack);

    @media print {
      margin-top: calc(var(--spacing-stack) * 0.5);
    }

    .block & {
      @media (min-width: 80rem) and (max-height: 64rem) {
        margin-top: calc(var(--spacing-stack) * 0.75);
      }
    }
  }

  .dashboard__block--blocks & {
    @media (min-width: 80rem) {
      &,
      & > *:not(.loader) {
        height: calc(50% - (var(--spacing-stack) * 0.5));
      }
    }
  }

  &--center {
    &-horizontally {
      display: flex;
      justify-content: center;
    }

    &-vertically {
      align-items: center;
      display: flex;
    }
  }

  &--double-spacing {
    .resize-sensor + &,
    .stack + & {
      margin-top: calc(var(--spacing-stack) * 2);

      @media print {
        margin-top: var(--spacing-stack);
      }
    }
  }

  &--half-spacing {
    .resize-sensor + &,
    .stack + & {
      margin-top: calc(var(--spacing-stack) * 0.5);

      .block & {
        @media (min-width: 80rem) and (max-height: 64rem) {
          margin-top: calc(var(--spacing-stack) * 0.25);
        }
      }
    }
  }

  &--no-spacing {
    .resize-sensor + &,
    .stack + & {
      margin-bottom: 0;
      margin-top: 0;
    }
  }

  &--one-and-a-half-spacing {
    .resize-sensor + &,
    .stack + & {
      margin-top: calc(var(--spacing-stack) * 1.5);

      @media print {
        margin-top: calc(var(--spacing-stack) * 0.75);
      }
    }
  }

  &--quarter-spacing {
    .resize-sensor + &,
    .stack + & {
      margin-top: calc(var(--spacing-stack) * 0.25);

      @media print {
        margin-top: calc(var(--spacing-stack) * 0.125);
      }

      .basket-line-list--read-only & {
        margin-top: calc(var(--spacing-stack) * 0.125);
      }
    }
  }

  &--space-between {
    display: flex;
    justify-content: space-between;
  }

  &--triple-spacing {
    .resize-sensor + &,
    .stack + & {
      margin-top: calc(var(--spacing-stack) * 3);

      @media print {
        margin-top: calc(var(--spacing-stack) * 2);
      }
    }
  }
}
</style>
