import Oidc from "oidc-client";
import { environmentVariables } from "@/helpers/environmentVariables";

const { authority, scope } = environmentVariables;

Oidc.Log.logger = console;
Oidc.Log.level =
  process.env.NODE_ENV === "production" ? Oidc.Log.ERROR : Oidc.Log.WARN;

let instance;

export function getOidcClient() {
  if (instance) return instance;

  instance = new Oidc.UserManager({
    authority,
    automaticSilentRenew: true,
    client_id: "spa",
    filterProtocolClaims: true,
    loadUserInfo: true,
    post_logout_redirect_uri: window.location.origin,
    redirect_uri: window.location.origin + "/#/callback",
    response_type: "code",
    scope,
    silent_redirect_uri: window.location.origin + "/silent_refresh.html",
  });

  return instance;
}
