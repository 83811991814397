/*
  Example usage:

  <div v-infinite-scroll="getMore"
       :infinite-scroll-disabled="isLoading || !hasMorePages"
       infinite-scroll-distance="250"
  </div>
*/

let observableElement = null;
let observer = null;

export default {
  inserted(el, binding) {
    const { value } = binding;

    if (!value || !value.constructor || !value.call || !value.apply) {
      console.warn("A callback needs to be defined");
      return;
    }

    const distance = el.getAttribute("infinite-scroll-distance");

    observableElement = document.createElement("div");

    observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          const isDisabled = !!el.getAttribute("infinite-scroll-disabled");

          if (isDisabled || !entry.isIntersecting) return;

          value();
        });
      },
      {
        rootMargin: `${distance ? parseInt(distance) : 100}px 0px 0px 0px`,
      },
    );

    el.parentNode.appendChild(observableElement);
    observer.observe(observableElement);
  },
  unbind() {
    observer.unobserve(observableElement);
  },
};
