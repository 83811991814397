<template>
  <div class="care-instructions">
    <Stack>
      <ul
        v-if="
          instructions.washCareEntriesWithoutSymbols &&
            instructions.washCareEntriesWithoutSymbols.length
        "
        class="care-instructions__list"
      >
        <li
          v-for="washCare in instructions.washCareEntriesWithoutSymbols"
          :key="washCare"
          class="care-instructions__item"
        >
          {{ washCare }}
        </li>
      </ul>
    </Stack>
    <Stack>
      <ul
        v-if="
          instructions.washCareEntriesWithSymbols &&
            instructions.washCareEntriesWithSymbols.length
        "
        class="care-instructions__list care-instructions__list--icons"
      >
        <li
          v-for="washCare in instructions.washCareEntriesWithSymbols"
          :key="washCare.id"
          class="care-instructions__item care-instructions__item--icon"
        >
          <i class="care-instructions__icon">
            {{ washCare.font }}
          </i>
          {{ washCare.description }}
        </li>
      </ul>
    </Stack>
  </div>
</template>

<script>
import Stack from "@/components/Stack";

export default {
  name: "CareInstructions",
  components: {
    Stack,
  },
  props: {
    instructions: {
      required: true,
      type: Object,
    },
  },
};
</script>

<style lang="scss" scoped>
.care-instructions {
  &__icon {
    cursor: default;
    font-family: "Ginetex", sans-serif;
    font-size: 2rem;
    font-style: normal;
    margin-right: 0.5rem;
    width: 2rem;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    &--icon {
      align-items: center;
      display: flex;
      line-height: 1;
      margin: 0 1rem 1rem 0;
    }
  }

  &__list {
    list-style-type: disc;
    margin: 0;
    padding: 0 0 0 1.25rem;

    &--icons {
      @include reset-list();
    }
  }
}
</style>
