<template>
  <div
    v-if="childrenToShopFor && childrenToShopFor.length"
    ref="customerSelectorParent"
    class="selector"
    :class="{
      'selector--relative': !fromQuickBuy,
      'selector--quick-buy': fromQuickBuy,
    }"
  >
    <div class="selector__toggle">
      <button class="selector__toggle-action" @click="toggleSelector">
        <span class="selector__toggle-label selector__toggle-label-first">
          {{ getDictionaryEntry("Common.Labels.CompanyBeingShoppedTo") }}:
        </span>
        <span class="selector__toggle-label">
          {{ selectedCustomer ? selectedCustomer.customerName : customerName }}
        </span>

        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="selector__toggle-icon"
          :class="{ 'selector__toggle-icon--rotated': isSelectorOpen }"
          viewBox="0 0 24 24"
        >
          <path d="m6 9 6 6 6-6" />
        </svg>
      </button>
    </div>
    <div
      ref="selector"
      class="selector__box"
      :class="{
        'selector__box--quick-buy': fromQuickBuy,
      }"
    >
      <div class="selector__box-content">
        <Stack>
          <h2 class="selector__title">
            {{ getDictionaryEntry("Common.Placeholders.SelectCompany") }}
          </h2>
        </Stack>
        <Stack class="selector__list-container">
          <ul ref="box-content" class="selector__list">
            <li class="selector__item">
              <button
                class="selector__item-action"
                @click="selectCustomer(customerId)"
              >
                <span
                  class="selector__item-action-label"
                  :class="{
                    'selector__item-action-label-selected':
                      hasSelected(customerId) || !selectedCustomer,
                  }"
                >
                  {{ customerName }}
                  <svg
                    v-if="hasSelected(customerId) || !selectedCustomer"
                    class="selector__item-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_112_452)">
                      <path
                        d="M3.125 11.25L7.5 15.625L17.5 5.625"
                        stroke="#5AB858"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_112_452">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>
              </button>
            </li>
            <li
              v-for="child in childrenToShopFor"
              :key="child.customerId"
              class="selector__item"
            >
              <button
                class="selector__item-action"
                @click="selectCustomer(child.customerId)"
              >
                <span
                  class="selector__item-action-label"
                  :class="{
                    'selector__item-action-label-selected': hasSelected(
                      child.customerId,
                    ),
                  }"
                >
                  {{ child.customerName }}
                  <svg
                    v-if="hasSelected(child.customerId)"
                    class="selector__item-icon"
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <g clip-path="url(#clip0_112_452)">
                      <path
                        d="M3.125 11.25L7.5 15.625L17.5 5.625"
                        stroke="#5AB858"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_112_452">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </span>

                <span
                  v-if="child.discountPercent"
                  class="selector__item-discount"
                >
                  {{ child.discountPercent }}%
                </span>
              </button>
            </li>
          </ul>
        </Stack>
      </div>
    </div>

    <div
      v-if="isSelectorOpen && !fromQuickBuy"
      class="selector__underlay"
      @click="closeSelector"
    ></div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Stack from "@/components/Stack";
import { slideDown, slideUp } from "@/helpers/slide";

export default {
  name: "CustomerSelector",
  components: {
    Stack,
  },
  props: {
    fromQuickBuy: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isSelectorOpen: false,
      boxHeight: "100%",
    };
  },

  computed: {
    ...mapGetters("authentication", ["customerId", "customerName"]),
    ...mapGetters("basket", ["selectedCustomerForAddingToBasket"]),
    ...mapGetters("customers", ["childrenToShopFor"]),
    selectedCustomer() {
      const { childrenToShopFor, selectedCustomerForAddingToBasket } = this;

      if (
        !selectedCustomerForAddingToBasket ||
        !childrenToShopFor ||
        !childrenToShopFor.length
      ) {
        return null;
      }

      const matchingCustomer = childrenToShopFor.find(
        customer => customer.customerId === selectedCustomerForAddingToBasket,
      );

      return matchingCustomer || null;
    },
  },
  watch: {
    "$route.query.customerId"(newValue, oldValue) {
      if (
        newValue &&
        newValue !== oldValue &&
        newValue !== this.selectedCustomerForAddingToBasket
      ) {
        this.selectCustomer(newValue);
      }
    },
    isSelectorOpen(newValue) {
      this.handleBackgroundScrolling(newValue);
    },
  },
  created() {
    const {
      $route: { query },
      GET_CHILDREN_TO_SHOP_FOR,
      GET_PRODUCT,
      GET_PRODUCT_FOR_CUSTOMER,
      selectCustomer,
      selectedCustomerForAddingToBasket,
    } = this;

    if (!query.productId) return;

    if (selectedCustomerForAddingToBasket) {
      GET_PRODUCT_FOR_CUSTOMER({
        customerId: selectedCustomerForAddingToBasket,
        productId: query.productId,
      });
    } else {
      GET_PRODUCT({ id: query.productId });
    }

    GET_CHILDREN_TO_SHOP_FOR().then(() => {
      if (
        query.customerId &&
        query.customerId !== selectedCustomerForAddingToBasket
      ) {
        selectCustomer(query.customerId);
      }
    });
  },
  destroyed() {
    this.RESET_CHILDREN_OF_CUSTOMER();
  },
  methods: {
    ...mapActions("basket", ["UPDATE_CUSTOMER_FOR_ADDING_TO_BASKET"]),
    ...mapActions("customers", [
      "GET_CHILDREN_TO_SHOP_FOR",
      "RESET_CHILDREN_OF_CUSTOMER",
    ]),
    ...mapActions("products", ["GET_PRODUCT", "GET_PRODUCT_FOR_CUSTOMER"]),
    closeSelector() {
      this.isSelectorOpen = false;
      slideUp(this.$refs.selector);
      // Wait for the animation to finish before setting the height of the box to 0
      this.$nextTick(() => {
        this.boxHeight = `100%`;
        this.handleBackgroundScrolling();
      });
    },
    openSelector() {
      this.isSelectorOpen = true;
      this.updateBoxHeight();
      this.$nextTick(() => {
        slideDown(this.$refs.selector);
      });
    },
    updateBoxHeight() {
      const topPosition = this.getCustomerSelectorTopPosition();
      const availableHeight = this.calculateAvailableHeight(topPosition);
      this.boxHeight = `${availableHeight}px`;
    },
    getCustomerSelectorTopPosition() {
      return this.$refs.customerSelectorParent.getBoundingClientRect().top;
    },
    // 80% of the window height minus the top position of the customer selector
    calculateAvailableHeight(topPosition) {
      const heightFactor = 0.8;
      return window.innerHeight * heightFactor - topPosition;
    },

    selectCustomer(id) {
      if (!id) return;
      if (this.fromQuickBuy) {
        this.$router.push({
          query: {
            ...this.$route.query,
            customerId: id,
          },
        });
      }

      this.UPDATE_CUSTOMER_FOR_ADDING_TO_BASKET({ customerId: id });

      if (this.fromQuickBuy) {
        this.GET_PRODUCT_FOR_CUSTOMER({
          customerId: id,
          productId: this.$route.query.productId,
        });
      }
      this.closeSelector();
    },
    toggleSelector() {
      if (this.isSelectorOpen) {
        this.closeSelector();
      } else {
        this.openSelector();
      }
    },
    hasSelected(customerId) {
      return this.selectedCustomer?.customerId === customerId;
    },
    handleBackgroundScrolling(disableScroll = false) {
      const scrollableElements = document.querySelectorAll("body");
      scrollableElements.forEach(el =>
        disableScroll
          ? el.classList.add("locked")
          : el.classList.remove("locked"),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.selector {
  &--relative {
    position: relative;
    height: v-bind(boxHeight) !important;
  }

  &__underlay {
    background-color: rgba(0, 0, 0, 0.5);
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: -1;
    height: 100vh;
  }

  &--quick-buy {
    margin-bottom: calc(var(--spacing-modal-large) * -1);
    margin-left: calc(var(--spacing-modal-large) * -1);
    margin-top: var(--spacing-modal-large);
    width: calc(100% + var(--spacing-modal) * 2);

    @media (min-width: 48rem) {
      margin-bottom: calc(var(--spacing-modal-large) * -1);
      margin-left: calc(var(--spacing-modal-large) * -1);
      margin-top: var(--spacing-modal-large);
      width: calc(100% + var(--spacing-modal-large) * 2);
    }
  }

  &__box {
    background-color: var(--color-neutral-10);
    bottom: 0;
    display: none;
    left: 0;
    overflow: hidden;
    position: absolute;
    right: 0;
    top: 2.5rem;
    z-index: 94;

    &--quick-buy {
      top: 7rem;

      @media (min-width: 64rem) {
        top: 7.8rem;
      }
    }

    &-content {
      @include scrollbars($height: 10px, $width: 3px);

      height: 100%;
      overflow-y: auto;
      padding: calc(var(--spacing-modal) * 2);
      padding-right: 1rem;
      max-width: 700px;
      margin: 0 auto;
    }
  }

  &__item {
    width: 100%;
    padding: 1.25rem 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);

    &:first-child {
      border-top: 1px solid rgba(0, 0, 0, 0.05);
    }

    &-action {
      @include reset-button();

      align-content: center;
      display: flex;
      font-size: 0.9375rem;
      justify-content: space-between;
      text-align: left;
      width: 100%;

      &-label {
        color: var(--color-black);
        font-family: var(--font-family-roboto);
        font-size: 0.875rem;
        font-style: normal;
        font-weight: 400;
        line-height: 100%; /* 0.875rem */
        &-selected {
          font-weight: 700;
        }
      }
    }

    &-icon {
      height: 0.875rem;
    }

    &-discount {
      color: var(--color-neutral-50);
      font-family: var(--font-family-roboto);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 0.875rem */
    }
  }

  &__list {
    @include reset-list();

    display: table;
    width: 100%;

    &-container {
      position: relative;
    }
  }

  &__title {
    color: var(--color-black);
    font-family: var(--font-family-roboto);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: 100%; /* 1.5rem */
    margin: 0 0 1.5rem 0;
  }

  &__toggle {
    align-items: center;
    background-color: var(--theme-primary);
    display: flex;
    padding: 0.625rem 0.75rem;
    justify-content: center;
    max-height: 2.5rem;

    &-action {
      @include reset-button();

      align-items: center;
      display: flex;
    }

    &-icon {
      fill: none;
      height: var(--size-customer-selector-icon);
      margin-left: 0.75rem;
      opacity: 0.75;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 1.2;
      stroke: var(--color-white);
      transition: transform 300ms ease;
      width: var(--size-customer-selector-icon);

      &--rotated {
        transform: rotate(180deg);
      }
    }

    &-label {
      color: var(--color-white);
      font-family: var(--font-family-roboto);
      font-size: 0.875rem;
      font-style: normal;
      font-weight: 400;
      line-height: 100%; /* 0.875rem */
      &-first {
        margin-right: 4px;
      }
    }
  }
}
</style>
