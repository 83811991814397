<template>
  <section
    v-if="blockData"
    class="slider slider--tall"
    :class="{
      'slider--expand-first-slide': blockData.expandFirstItem,
    }"
  >
    <Flicking :options="options" :plugins="plugins">
      <div
        v-for="slide in blockData.linkElements"
        :key="slide._id"
        class="slide"
      >
        <ImageFromUmbraco
          v-if="slide.image"
          :id="slide.image._system.id"
          :alt="slide.image.alternateText"
          :height="slide.image.umbracoHeight"
          :keys-and-sizes="{
            s: 'block-regular-small',
            m: 'block-regular-medium',
            l: 'block-regular-large',
            xl: 'block-regular-extra-large',
          }"
          :width="slide.image.umbracoWidth"
          class="slide__image"
        />
        <article class="slide__content">
          <div v-if="slide.caption" class="slide__subtitle">
            {{ slide.caption }}
          </div>
          <Heading
            v-if="slide.headline"
            :tag="slide.headlineTag"
            class="slide__title"
          >
            {{ slide.headline }}
          </Heading>
          <ConditionalRouterLink
            v-if="slide.cTA && slide.cTA.name && slide.cTA.url"
            :target="slide.cTA.target"
            :to="slide.cTA.url"
            :type="slide.cTA.type"
            class="block__button"
            >{{ slide.cTA.name }}</ConditionalRouterLink
          >
        </article>
      </div>
      <button
        slot="viewport"
        aria-label="previous"
        class="slider__navigation slider__navigation--prev"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="slider__navigation-icon"
          viewBox="0 0 24 24"
        >
          <defs />
          <path d="M19 12H5M12 19l-7-7 7-7" />
        </svg>
      </button>
      <button
        slot="viewport"
        aria-label="next"
        class="slider__navigation slider__navigation--next"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="slider__navigation-icon"
          viewBox="0 0 24 24"
        >
          <defs />
          <path d="M5 12h14M12 5l7 7-7 7" />
        </svg>
      </button>
    </Flicking>
  </section>
</template>

<script>
import { Arrow } from "@egjs/flicking-plugins";
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "CTAListGridElement",
  components: {
    ConditionalRouterLink,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      options: {
        align: "prev",
        autoResize: true,
        bound: true,
        noPanelStyleOverride: true,
      },
      plugins: [
        new Arrow({
          disabledClass: "slider__navigation--disabled",
          nextElSelector: ".slider__navigation--next",
          prevElSelector: ".slider__navigation--prev",
        }),
      ],
    };
  },
};
</script>
