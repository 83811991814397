<template>
  <div v-if="information" class="information">
    <Stack class="information__row">
      <Stack class="information__column information__column--half">
        <Input
          v-model.trim="information.name.$model"
          :error-message="
            getDictionaryEntry('ErrorMessage.Checkout.NameRequired')
          "
          :has-error="information.name.$error"
          :label="getDictionaryEntry('Common.Labels.Name')"
          required
          type="text"
          @blur="information.name.$touch()"
          @input="information.name.$reset()"
        />
      </Stack>
      <Stack class="information__column information__column--half">
        <Dropdown
          v-model.trim="information.selectedLanguage.$model"
          :close-on-select="true"
          :error-message="
            getDictionaryEntry('ErrorMessage.ProductSheet.LanguageIsRequired')
          "
          :has-error="information.selectedLanguage.$error"
          :label-text="getDictionaryEntry('Common.Labels.Language')"
          :options="languagesAsOptions"
          :placeholder="
            getDictionaryEntry('Common.Placeholders.SelectLanguage')
          "
          required
          @close="information.selectedLanguage.$touch()"
          @input="information.selectedLanguage.$reset()"
          @open="information.selectedLanguage.$reset()"
        />
      </Stack>
    </Stack>
    <Stack>
      <Dropdown
        v-model.trim="information.category.$model"
        :close-on-select="true"
        :error-message="
          getDictionaryEntry('ErrorMessage.ProductSheet.CategoryIsRequired')
        "
        :has-error="information.category.$error"
        :label-text="getDictionaryEntry('Common.Labels.Category')"
        :options="categoriesAsOptions"
        :placeholder="getDictionaryEntry('Common.Placeholders.SelectCategory')"
        required
        @close="information.category.$touch()"
        @input="information.category.$reset()"
        @open="information.category.$reset()"
      />
    </Stack>
    <Stack>
      <Dropdown
        v-model.trim="information.selectedSizeChart.$model"
        :close-on-select="true"
        :error-message="
          getDictionaryEntry('ErrorMessage.ProductSheet.SizeChartIsRequired')
        "
        :has-error="information.selectedSizeChart.$error"
        :label-text="getDictionaryEntry('Common.Labels.SizeSystemKey')"
        :options="sizeSystems"
        :placeholder="
          getDictionaryEntry('Common.Placeholders.SelectSizeSystemKey')
        "
        required
        @close="information.selectedSizeChart.$touch()"
        @input="information.selectedSizeChart.$reset()"
        @open="information.selectedSizeChart.$reset()"
      />
    </Stack>
    <Stack
      v-if="
        isUserSalesperson ||
          isUserCustomerRepresentative ||
          isUserSystemadministrator
      "
      :triple="true"
    >
      <h3 class="information__title">
        {{ getDictionaryEntry("ProductSheet.DealerInformation") }}
      </h3>
      <div class="information__row">
        <Stack class="information__column">
          <Dropdown
            v-model.trim="information.selectedDealer.$model"
            :allow-empty="true"
            :close-on-select="true"
            :label-text="getDictionaryEntry('Common.Labels.Dealer')"
            :options="dealersAsOptions"
            :placeholder="
              getDictionaryEntry('Common.Placeholders.SelectDealer')
            "
          />
        </Stack>
      </div>
      <div class="information__row">
        <Stack class="information__column">
          <Input
            v-model.trim="information.dealerName.$model"
            :disabled="isDealerSelected"
            :label="getDictionaryEntry('Common.Labels.Name')"
            type="text"
          />
        </Stack>
      </div>
      <div class="information__row">
        <Stack class="information__column information__column--half">
          <CataloguesDealerLogo />
        </Stack>
      </div>
    </Stack>

    <Stack v-if="!isUserEndCustomer" :triple="true">
      <h3 class="information__title">
        {{ getDictionaryEntry("ProductSheet.EndCustomerInformation") }}
      </h3>
      <div class="information__row">
        <Stack class="information__column">
          <Dropdown
            v-model.trim="information.selectedEndCustomer.$model"
            :allow-empty="true"
            :close-on-select="true"
            :label-text="getDictionaryEntry('Common.Labels.EndCustomer')"
            :options="endCustomersAsOptions"
            :placeholder="
              getDictionaryEntry('Common.Placeholders.SelectEndCustomer')
            "
          />
        </Stack>
      </div>
      <div class="information__row">
        <Stack class="information__column">
          <Input
            v-model.trim="information.endCustomerName.$model"
            :disabled="isEndCustomerSelected"
            :label="getDictionaryEntry('Common.Labels.Name')"
            type="text"
          />
        </Stack>
      </div>
      <div class="information__row">
        <Stack class="information__column information__column--half">
          <CataloguesEndCustomerLogo />
        </Stack>
      </div>
    </Stack>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CataloguesDealerLogo from "@/components/CataloguesDealerLogo";
import CataloguesEndCustomerLogo from "@/components/CataloguesEndCustomerLogo";
import Dropdown from "@/components/Dropdown";
import Input from "@/components/Input";
import Stack from "@/components/Stack";

export default {
  name: "CataloguesInformation",
  components: {
    CataloguesDealerLogo,
    CataloguesEndCustomerLogo,
    Dropdown,
    Input,
    Stack,
  },
  props: {
    information: {
      required: true,
      type: Object,
    },
  },
  computed: {
    ...mapGetters("authentication", [
      "divisionCode",
      "isUserDealer",
      "isUserEndCustomer",
      "isUserSalesperson",
      "siteLanguage",
    ]),
    ...mapGetters("catalogues", ["categories", "currentCatalogue", "dealers"]),
    ...mapGetters("customers", ["childrenOfCurrentCustomer"]),
    ...mapGetters("siteSettings", ["languages", "sizeSystems"]),
    categoriesAsOptions() {
      const { categories } = this;

      if (!categories?.length) return [];

      return categories
        .filter(
          ({ cultureCode }) =>
            cultureCode === this.information.selectedLanguage.$model,
        )
        .map(({ key, name }) => ({
          label: name,
          value: key,
        }));
    },
    dealersAsOptions() {
      const { dealers } = this;

      if (!dealers?.length) return [];

      return dealers.map(({ division, id, name, name2 }) => ({
        label: `${name} ${name2} (${division})`,
        value: id,
      }));
    },
    endCustomersAsOptions() {
      const { childrenOfCurrentCustomer } = this;

      if (!childrenOfCurrentCustomer?.length) {
        return [];
      }

      return childrenOfCurrentCustomer.map(({ division, id, name, name2 }) => ({
        label: `${name} ${name2} (${division})`,
        value: id,
      }));
    },
    isDealerSelected() {
      const { information } = this;

      if (!information?.selectedDealer?.$model) {
        return false;
      }

      return !!information.selectedDealer.$model.length;
    },
    isEndCustomerSelected() {
      const { information } = this;

      if (!information?.selectedEndCustomer?.$model) {
        return false;
      }

      return !!information.selectedEndCustomer.$model.length;
    },
    languagesAsOptions() {
      const { categories, languages } = this;

      if (!categories?.length || !languages?.length) return [];

      return (
        languages.filter(language =>
          categories.some(({ cultureCode }) => cultureCode === language.value),
        ) || []
      );
    },
  },
  watch: {
    "information.category.$model"(newValue, oldValue) {
      if (!newValue || newValue === oldValue) return;

      const matchingLanguage = this.languagesAsOptions.find(
        ({ value }) => value === newValue.split("_")[1],
      );

      if (
        !matchingLanguage?.value ||
        this.information.selectedLanguage.$model === matchingLanguage.value
      ) {
        return;
      }

      this.information.selectedLanguage.$model = matchingLanguage.value;
    },
    "information.selectedLanguage.$model"(newValue, oldValue) {
      if (newValue === oldValue) return;

      const { categories, information } = this;

      let matchingCategory;

      if (information.category.$model) {
        const categoryWithoutSuffix = information.category.$model.split("_")[0];
        const matchingCategories = categories.filter(({ key }) =>
          key.startsWith(categoryWithoutSuffix),
        );

        if (matchingCategories?.length) {
          const matchingCategoryInMatchingLanguage = matchingCategories.find(
            ({ key }) => key.endsWith(newValue),
          );

          if (matchingCategoryInMatchingLanguage) {
            matchingCategory = matchingCategoryInMatchingLanguage;
          }
        }
      }

      if (!matchingCategory?.key) {
        matchingCategory = categories.find(({ cultureCode, key }) =>
          key.endsWith(`_${cultureCode}`),
        );

        if (!matchingCategory?.key) return;
      }

      if (this.information.category.$model === matchingCategory.key) return;

      this.information.category.$model = matchingCategory.key;
    },
    "information.selectedDealer.$model": {
      handler: function(newValue, oldValue) {
        const {
          selectedDealer,
          selectedEndCustomer,
          dealerName,
        } = this.information;

        if (newValue === null) {
          if (selectedEndCustomer.$model) {
            this.RESET_CURRENT_END_CUSTOMER_LOGO();
            this.$emit("reset-end-customer");
          }

          this.RESET_CHILDREN_OF_CUSTOMER();
          this.RESET_CURRENT_DEALER_LOGO();
        }

        if (newValue && newValue !== oldValue) {
          this.RESET_CURRENT_DEALER_LOGO();
          this.GET_CURRENT_DEALER_LOGO({
            customerId: selectedDealer.$model,
          });

          this.GET_CHILDREN_OF_CUSTOMER({ id: selectedDealer.$model });
          if (dealerName.$model) this.$emit("reset-dealer-name");
          if (selectedEndCustomer.$model) this.$emit("reset-end-customer");
        }
      },
      immediate: true,
    },
    "information.selectedEndCustomer.$model": {
      handler: function(newValue, oldValue) {
        if (newValue && newValue !== oldValue) {
          this.RESET_CURRENT_END_CUSTOMER_LOGO();
          this.GET_CURRENT_END_CUSTOMER_LOGO({
            customerId: this.information.selectedEndCustomer.$model,
          });
        }
      },
      immediate: true,
    },
    isEndCustomerSelected(newValue, oldValue) {
      const { endCustomerName } = this.information;

      if (newValue && newValue !== oldValue) {
        if (endCustomerName.$model) this.$emit("reset-end-customer-name");

        this.GET_CURRENT_END_CUSTOMER_LOGO({
          customerId: this.information.selectedEndCustomer.$model,
        });
      } else {
        this.RESET_CURRENT_END_CUSTOMER_LOGO();
      }
    },
  },
  created() {
    const {
      GET_CATEGORIES,
      GET_DEALERS,
      categories,
      getLanguagesAndSetInitialLanguage,
      isUserDealer,
      isUserEndCustomer,
    } = this;

    if (!categories || !categories.length) {
      GET_CATEGORIES().then(() => {
        getLanguagesAndSetInitialLanguage();
      });
    } else {
      getLanguagesAndSetInitialLanguage();
    }

    if (!isUserDealer && !isUserEndCustomer) {
      GET_DEALERS();
    }
  },
  methods: {
    ...mapActions("catalogues", [
      "GET_CATEGORIES",
      "GET_CURRENT_DEALER_LOGO",
      "GET_CURRENT_END_CUSTOMER_LOGO",
      "GET_DEALERS",
      "RESET_CURRENT_DEALER_LOGO",
      "RESET_CURRENT_END_CUSTOMER_LOGO",
    ]),
    ...mapActions("customers", [
      "GET_CHILDREN_OF_CUSTOMER",
      "RESET_CHILDREN_OF_CUSTOMER",
    ]),
    ...mapActions("siteSettings", ["GET_LANGUAGES"]),
    getLanguagesAndSetInitialLanguage() {
      const {
        GET_LANGUAGES,
        currentCatalogue,
        divisionCode,
        information,
        languagesAsOptions,
        siteLanguage,
      } = this;

      GET_LANGUAGES({ divisionCode }).then(() => {
        if (!siteLanguage) return;

        const matchingLanguage =
          currentCatalogue?.selectedLanguage ||
          languagesAsOptions.find(({ value }) => value === siteLanguage);

        if (!matchingLanguage) return;

        information.selectedLanguage.$model = matchingLanguage;

        const matchingCategory = currentCatalogue?.categoryKey;

        if (matchingCategory) {
          information.category.$model = `${matchingCategory}_${matchingLanguage}`;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.information {
  padding: 0 var(--spacing-sidebar) var(--spacing-stack);

  @media (min-width: 64rem) {
    padding: 0 var(--spacing-sidebar-large) var(--spacing-stack);
  }

  &__column {
    margin-top: var(--spacing-stack);

    @media (min-width: 48rem) {
      flex: 0 0 100%;
      width: 100%;
    }

    &--one-third {
      @media (min-width: 48rem) {
        flex-basis: calc((100% * (1 / 3)) - (var(--spacing-stack) * 0.5));
        flex-grow: 0;
        flex-shrink: 0;
        width: calc((100% * (1 / 3)) - (var(--spacing-stack) * 0.5));
      }
    }

    &--half {
      @media (min-width: 48rem) {
        flex-basis: calc((100% * (1 / 2)) - (var(--spacing-stack) * 0.5));
        flex-grow: 0;
        flex-shrink: 0;
        width: calc((100% * (1 / 2)) - (var(--spacing-stack) * 0.5));
      }
    }

    &--read-only {
      margin-top: calc(var(--spacing-stack) * 0.5);
    }

    &--two-thirds {
      @media (min-width: 48rem) {
        flex-basis: calc((100% * (2 / 3)) - (var(--spacing-stack) * 0.5));
        flex-grow: 0;
        flex-shrink: 0;
        width: calc((100% * (2 / 3)) - (var(--spacing-stack) * 0.5));
      }
    }
  }

  &__row {
    @media (min-width: 48rem) {
      display: flex;
      justify-content: space-between;
    }

    .delivery--confirmed & {
      @media (min-width: 48rem) {
        display: block !important;
      }
    }
  }

  &__title {
    font-family: var(--font-family-inter);
    font-size: 1.125rem;
    font-weight: 900;
    line-height: 1.13;
    margin: 0;
    text-transform: uppercase;
  }
}
</style>
