<template>
  <FocusTrap v-model="isOpen" :escape-deactivates="false">
    <transition
      name="fade"
      appear=""
      @enter="isBoxVisible = true"
      @after-leave="$emit('close')"
    >
      <div v-if="isOpen" class="slide-in" role="dialog">
        <transition name="slide-in-from-right" @leave="isOpen = false">
          <div
            v-if="isBoxVisible"
            :class="{ 'slide-in__box--large': large }"
            class="slide-in__box"
            tabindex="-1"
          >
            <div
              v-if="title || hasHeaderSlot"
              class="slide-in__header"
              :class="{ 'slide-in__header--slotted': hasHeaderSlot }"
            >
              <h2 class="slide-in__header-title">{{ title }}</h2>
              <div v-if="hasHeaderSlot" class="slide-in__header-divider">
                <slot name="header" />
              </div>
            </div>
            <div
              class="slide-in__body"
              :class="{
                'slide-in__body--no-padding-bottom': hasFooterSlot,
              }"
            >
              <DynamicData :loading="isLoading">
                <slot name="body" />
              </DynamicData>
            </div>
            <div class="slide-in__footer">
              <slot name="footer" />
            </div>
            <button
              class="slide-in__close"
              :aria-label="getDictionaryEntry('B2C.Common.Close')"
              :class="{ 'slide-in__close--large': large }"
              @click="handleClose"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 21 21"
                class="slide-in__close-icon"
              >
                <defs data-v-6566dc36=""></defs>
                <g data-v-6566dc36="">
                  <path
                    data-v-6566dc36=""
                    d="M5.5 15.5l10-10M15.5 15.5l-10-10z"
                  ></path>
                </g>
              </svg>
            </button>
          </div>
        </transition>
        <div class="slide-in__underlay" @click="handleClose"></div>
      </div>
    </transition>
  </FocusTrap>
</template>

<script>
import { mapGetters } from "vuex";
import { FocusTrap } from "focus-trap-vue";
import DynamicData from "@/components/DynamicData";
import { statuses } from "@/helpers/statuses";

export default {
  name: "SlideIn",
  components: {
    DynamicData,
    FocusTrap,
  },
  props: {
    large: {
      default: false,
      type: Boolean,
    },
    title: {
      default: "",
      type: String,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isBoxVisible: false,
      isOpen: false,
    };
  },
  computed: {
    ...mapGetters("content", ["statusOfContent"]),
    hasHeaderSlot() {
      return !!this.$slots.header;
    },
    hasFooterSlot() {
      return !!this.$slots.footer;
    },
    isLoading() {
      return this.statusOfContent === statuses.LOADING;
    },
  },
  watch: {
    visible(newValue) {
      this.isOpen = newValue;
    },
  },
  created() {
    document.addEventListener("keydown", this.handleEscape);

    this.isOpen = this.visible;
    if (this.isOpen) this.handleBackgroundScrolling(true);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.handleEscape);

    this.handleBackgroundScrolling();
  },
  methods: {
    handleEscape(event) {
      if (this.isOpen && event.keyCode === 27) {
        this.handleClose();
      }
    },
    handleClose() {
      this.isBoxVisible = false;
    },
    handleBackgroundScrolling(disableScroll = false) {
      const scrollableElements = document.querySelectorAll("body");
      scrollableElements.forEach(el =>
        disableScroll
          ? el.classList.add("modal-open")
          : el.classList.remove("modal-open"),
      );
    },
  },
};
</script>

<style lang="scss" scoped>
$slide-in-width: 37.5rem;
$slide-in-width-large: 69rem;
$spacing-slide-in-large: 3.5rem;

.slide-in {
  bottom: 0;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  will-change: transform;
  z-index: 100; // Ensure the slide-in is always on top of header

  &__body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 0 var(--spacing-slide-in) var(--spacing-slide-in);

    @media (min-width: 64rem) {
      padding: 0 var(--spacing-slide-in-large) var(--spacing-slide-in-large);
    }

    &:first-child {
      padding-top: calc(var(--spacing-slide-in) * 1.5) !important;
    }

    &--no-padding-bottom {
      padding-bottom: 0 !important;
    }
  }

  &__box {
    background-color: var(--color-sidebar-background);
    bottom: 0;
    display: flex;
    flex-direction: column;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    z-index: 2;

    &:not(.slide-in__box--large) {
      @media (min-width: $slide-in-width) {
        box-shadow: var(--elevation-2);
        left: auto;
        max-width: $slide-in-width;
        right: 0;
        width: 100%;
      }
    }

    &--large {
      @media (min-width: $slide-in-width-large) {
        box-shadow: var(--elevation-2);
        left: auto;
        max-width: $slide-in-width-large;
        right: 0;
        width: 100%;
      }
    }

    &:focus {
      outline: none;
    }
  }

  &__close {
    @include reset-button();

    align-items: center;
    background-color: var(--color-sidebar-background);
    border: 1px solid #868a91;
    border-radius: 50%;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    position: fixed;
    top: var(--spacing-slide-in);
    right: var(--spacing-slide-in);
    width: 2.5rem;

    &:not(.slide-in__close--large) {
      @media (min-width: $slide-in-width + 6.5rem) {
        height: 3.5rem;
        left: 0;
        position: absolute;
        right: auto;
        top: 50%;
        transform: translate(-150%, -50%);
        width: 3.5rem;
      }
    }

    &--large {
      @media (min-width: $slide-in-width-large + 6.5rem) {
        height: 3.5rem;
        left: 0;
        position: absolute;
        right: auto;
        top: 50%;
        transform: translate(-150%, -50%);
        width: 3.5rem;
      }
    }

    &-icon {
      fill-rule: evenodd;
      fill: none;
      height: var(--size-sidebar-icon);
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke: var(--color-text-primary);
      width: var(--size-sidebar-icon);
    }
  }

  &__footer {
    background-color: var(--color-sidebar-background);
    flex-shrink: 0;
    padding: var(--spacing-slide-in);
  }

  &__header {
    flex-shrink: 0;
    justify-content: space-between;
    padding: calc(var(--spacing-slide-in) * 1.5) var(--spacing-slide-in)
      var(--spacing-slide-in);

    @media (min-width: 64rem) {
      padding: calc(var(--spacing-slide-in-large) * 1.5)
        var(--spacing-slide-in-large) var(--spacing-slide-in-large);
    }

    &--slotted {
      padding-bottom: calc(var(--spacing-slide-in) * 0.25);

      @media (min-width: 64rem) {
        padding-bottom: calc(var(--spacing-slide-in-large) * 0.25);
      }
    }

    &-divider {
      border-bottom: 1px solid #eaebee;
      margin-top: calc(var(--spacing-stack) * 0.5);
      padding-bottom: calc(var(--spacing-stack) * 1.25);
    }

    &-title {
      font-family: var(--font-family-inter);
      font-size: 1.75rem;
      font-weight: 900;
      text-transform: uppercase;
      word-break: break-all;
    }
  }

  &__underlay {
    backdrop-filter: blur(0.375rem) opacity(1);
    background-color: #1318231a;
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    transition: all 300ms ease;
    z-index: 1;

    .slide-in[class*="fade-"] & {
      backdrop-filter: blur(0.375rem) opacity(0);
      background-color: #13182300;
    }
  }
}
</style>
