<template>
  <div class="size-selector">
    <h3 class="size-selector--title">
      {{ getDictionaryEntry("Common.Labels.SelectSize") }}
    </h3>

    <div class="size-selector__box">
      <div class="size-selector__box-content">
        <Stack v-if="hasSizeGroups && isUserEndCustomer" :double="true">
          <div v-for="(sizeGroup, index) in sizes" :key="sizeGroup.title">
            <div
              class="size-selector__size-group-header"
              :class="{
                'size-selector__size-group-header--not-first': index !== 0,
              }"
            >
              <span>{{
                getDictionaryEntry("SizeGroup.B2B." + sizeGroup.title)
              }}</span>
              <span class="size-selector__size-span">{{
                getSizeSpan(sizeGroup)
              }}</span>
            </div>

            <SizeSelectorList
              :key="'groupSize_' + index"
              :sizes="sizeGroup.sizes"
              :selected-size="selectedSize"
              end-customer-quick-buy-mode
              @select-size="selectSize"
            />
          </div>
        </Stack>
        <Stack v-else :double="true">
          <SizeSelectorList
            :sizes="sizes"
            :selected-size="selectedSize"
            end-customer-quick-buy-mode
            @select-size="selectSize"
          />
        </Stack>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import SizeSelectorList from "@/components/SizeSelectorList";
import Stack from "@/components/Stack";
import { stockStatuses } from "@/helpers/stockStatuses";

export default {
  name: "SizeSelectorQuickBuy",
  components: {
    SizeSelectorList,
    Stack,
  },
  props: {
    hasSizeGroups: {
      type: Boolean,
      default: false,
    },
    handleSizeSelect: {
      required: true,
      type: Function,
    },
    selectedSize: {
      type: Object,
      default: null,
    },
    sizes: {
      required: true,
      type: Array,
    },
    addToBasketClicked: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("basket", ["basketLineQuantity"]),
    ...mapGetters("authentication", ["isUserEndCustomer"]),
    quantityInBasket() {
      if (!this.selectedSize) return 0;

      return this.basketLineQuantity(this.selectedSize.sku);
    },
    shouldDisableButton() {
      if (!this?.selectedSize?.inventory) return true;

      const {
        quantityInBasket,
        selectedSize: {
          inventory: { allowNegativeStock, stock, stockStatus },
        },
      } = this;

      if (typeof stock === "number") {
        if (allowNegativeStock) {
          return false;
        } else {
          return stock === 0 || quantityInBasket >= stock;
        }
      } else {
        return stockStatus === stockStatuses.OUT_OF_STOCK;
      }
    },
  },
  watch: {
    isLoading(newValue) {
      if (newValue) {
        this.$emit("set-footer-button-loading", true);
      } else {
        this.$emit("set-footer-button-loading", false);
      }
    },
    shouldDisableButton(newValue) {
      if (newValue) {
        this.$emit("set-footer-button-disabled", true);
      } else {
        this.$emit("set-footer-button-disabled", false);
      }
    },
    addToBasketClicked() {
      this.addToBasket();
    },
  },
  created() {
    this.$emit(
      "set-footer-text-label",
      this.getDictionaryEntry("Product.AddToBasket"),
    );
  },
  methods: {
    ...mapActions("basket", ["UPDATE_BASKET_LINE_QUANTITY"]),
    ...mapActions("products", ["TOGGLE_END_CUSTOMER_QUICK_BUY"]),
    addToBasket() {
      this.isLoading = true;

      this.UPDATE_BASKET_LINE_QUANTITY({
        previousQuantity: this.quantityInBasket,
        quantity: 1 + this.quantityInBasket,
        sku: this.selectedSize.sku,
      })
        .then(() => {
          this.isLoading = false;
          this.TOGGLE_END_CUSTOMER_QUICK_BUY();
        })
        .catch(() => {
          this.isLoading = false;
        });
    },
    getSizeSpan(sizeGroup) {
      const first = this.getPreferredSizeChartValue(sizeGroup.sizes[0]);
      const last = this.getPreferredSizeChartValue(
        sizeGroup.sizes[sizeGroup.sizes.length - 1],
      );

      return `${first} - ${last}`;
    },
    selectSize(size) {
      this.handleSizeSelect(size);
    },
  },
};
</script>

<style lang="scss" scoped>
.size-selector {
  border-top: 1px solid #eaebee;
  padding-top: var(--spacing-stack);

  &--title {
    font-size: 0.9375rem;
    margin: 0 0 var(--spacing-stack);
  }

  &__box {
    background-color: var(--color-card-background);
  }

  &__size-group-header {
    align-items: center;
    display: flex;
    gap: 0.5rem;
    margin-bottom: calc(var(--spacing-stack) * 0.5);

    &--not-first {
      margin: calc(var(--spacing-stack) * 0.5) 0;
    }

    &--title {
      font-size: 0.875rem;
      font-weight: 700;
    }
  }

  &__size-span {
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
  }
}
</style>
