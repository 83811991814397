import axios from "axios";
import { debounce } from "debounce";
import { mapGetters } from "vuex";
import { endpoints } from "@/helpers/endpoints";

export default {
  data() {
    return {
      customersWhereTheEmailAlreadyExists: [],
      customersWhereTheLoginNameAlreadyExists: [],
      emailAvailabilityMessage: "",
      idOfUserWithAlreadyUsedEmail: "",
      idOfUserWithAlreadyUsedLoginName: "",
      isCheckingAvailabilityOfEmail: false,
      isCheckingAvailabilityOfInitials: false,
      isCheckingAvailabilityOfLoginName: false,
      loginNameAvailabilityMessage: "",
      nameOfUserWithAlreadyUsedEmail: "",
      nameOfUserWithAlreadyUsedLoginName: "",
    };
  },
  watch: {
    "$route.path": function(newValue, oldValue) {
      if (newValue === oldValue) return;

      this.customersWhereTheEmailAlreadyExists = [];
      this.customersWhereTheLoginNameAlreadyExists = [];
      this.emailAvailabilityMessage = "";
      this.idOfUserWithAlreadyUsedEmail = "";
      this.idOfUserWithAlreadyUsedLoginName = "";
      this.isCheckingAvailabilityOfEmail = false;
      this.isCheckingAvailabilityOfInitials = false;
      this.isCheckingAvailabilityOfLoginName = false;
      this.loginNameAvailabilityMessage = "";
      this.nameOfUserWithAlreadyUsedEmail = "";
      this.nameOfUserWithAlreadyUsedLoginName = "";
    },
  },
  computed: {
    ...mapGetters("authentication", ["isUserSystemUser"]),
    listOfCusomersWhereEmailAlreadyExists() {
      const { customersWhereTheEmailAlreadyExists } = this;

      if (!customersWhereTheEmailAlreadyExists?.length) {
        return null;
      }

      return customersWhereTheEmailAlreadyExists
        .map(({ divisionId, name }) => `${name} (${divisionId})`)
        .join(", ");
    },
    listOfCusomersWhereLoginNameAlreadyExists() {
      const { customersWhereTheLoginNameAlreadyExists } = this;

      if (!customersWhereTheLoginNameAlreadyExists?.length) {
        return null;
      }

      return customersWhereTheLoginNameAlreadyExists
        .map(({ divisionId, name }) => `${name} (${divisionId})`)
        .join(", ");
    },
    messageForEmailAvailability() {
      const {
        emailAvailabilityMessage,
        getDictionaryEntry,
        listOfCusomersWhereEmailAlreadyExists,
      } = this;

      if (listOfCusomersWhereEmailAlreadyExists) {
        return `${getDictionaryEntry(
          emailAvailabilityMessage,
        )}: ${listOfCusomersWhereEmailAlreadyExists}`;
      }

      return getDictionaryEntry(emailAvailabilityMessage);
    },
    messageForLoginNameAvailability() {
      const {
        loginNameAvailabilityMessage,
        getDictionaryEntry,
        listOfCusomersWhereLoginNameAlreadyExists,
      } = this;

      if (listOfCusomersWhereLoginNameAlreadyExists) {
        return `${getDictionaryEntry(
          loginNameAvailabilityMessage,
        )}: ${listOfCusomersWhereLoginNameAlreadyExists}`;
      }

      return getDictionaryEntry(loginNameAvailabilityMessage);
    },
  },
  methods: {
    debounceInputForValidation: debounce(function(object, key, value) {
      if (typeof value === "number" || typeof value === "string") {
        object[key].$model = value || "";
      } else if (value && value.target) {
        object[key].$model = value.target.value || "";
      }
    }, 500),
    isEmailAvailable(email, userId = "") {
      if (!email || typeof email !== "string") return false;

      this.isCheckingAvailabilityOfEmail = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${
              endpoints.ECOMMERCE
            }/users/v2/is-email-in-use?email=${encodeURIComponent(email)}${
              userId ? "&userIdToTest=" + encodeURIComponent(userId) : ""
            }`,
          )
          .then(
            ({
              data: { customers, emailInUse, message, userId, userName },
            }) => {
              this.customersWhereTheEmailAlreadyExists = customers || [];
              this.emailAvailabilityMessage = message;
              this.idOfUserWithAlreadyUsedEmail = userId;
              this.isCheckingAvailabilityOfEmail = false;

              if (this.isUserSystemUser) {
                this.nameOfUserWithAlreadyUsedEmail = userName;
              }

              resolve(!emailInUse);
            },
          )
          .catch(error => {
            this.isCheckingAvailabilityOfEmail = false;
            reject(error);
          });
      });
    },
    isInitialsAvailable(initials, userId = "") {
      if (!initials || typeof initials !== "string") return false;

      this.isCheckingAvailabilityOfInitials = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${
              endpoints.ECOMMERCE
            }/users/is-initials-valid?initials=${encodeURIComponent(initials)}${
              userId ? "&userIdToTest=" + encodeURIComponent(userId) : ""
            }`,
          )
          .then(() => {
            this.isCheckingAvailabilityOfInitials = false;
            resolve(true);
          })
          .catch(error => {
            this.isCheckingAvailabilityOfInitials = false;
            reject(error);
          });
      });
    },
    isLoginNameAvailable(username, userId = "") {
      if (!username || typeof username !== "string") return false;

      this.isCheckingAvailabilityOfLoginName = true;

      return new Promise((resolve, reject) => {
        axios
          .get(
            `${
              endpoints.ECOMMERCE
            }/users/v2/is-username-in-use?newUsername=${encodeURIComponent(
              username,
            )}${userId ? "&userIdToTest=" + encodeURIComponent(userId) : ""}`,
          )
          .then(
            ({
              data: { customers, loginNameInUse, message, userId, userName },
            }) => {
              this.customersWhereTheLoginNameAlreadyExists = customers || [];
              this.loginNameAvailabilityMessage = message;
              this.idOfUserWithAlreadyUsedLoginName = userId;
              this.isCheckingAvailabilityOfLoginName = false;

              if (this.isUserSystemUser) {
                this.nameOfUserWithAlreadyUsedLoginName = userName;
              }

              resolve(!loginNameInUse);
            },
          )
          .catch(error => {
            this.isCheckingAvailabilityOfLoginName = false;
            reject(error);
          });
      });
    },
  },
};
