import axios from "axios";
import { catalogueStatuses } from "@/helpers/catalogueStatuses";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";
import cloneDeep from "lodash.clonedeep";

const state = () => ({
  catalogueBuildProcess: null,
  catalogues: [],
  categories: [],
  currentCatalogue: null,
  currentDealerLogo: null,
  currentEndCustomerLogo: null,
  dealers: [],
  status: "",
});

const getters = {
  catalogueBuildProcess: state => state.catalogueBuildProcess,
  catalogues: state => state.catalogues,
  categories: state => state.categories,
  currentCatalogue: state => state.currentCatalogue,
  currentDealerLogo: state => state.currentDealerLogo,
  currentEndCustomerLogo: state => state.currentEndCustomerLogo,
  dealers: state => state.dealers,
  isPreviouslyCreatedCatalogueLoaded: state => {
    if (!state.currentCatalogue) return false;

    return state.currentCatalogue.loadedProductSheet;
  },
  isVariantInCurrentCatalogue: state => variantId => {
    if (!state.currentCatalogue) return false;

    const { basketLines } = state.currentCatalogue;

    if (!basketLines || !basketLines.length) return false;

    return basketLines.some(basketLine => basketLine.variantId === variantId);
  },
  numberOfItemsInCatalogue: state =>
    state.currentCatalogue && state.currentCatalogue.basketLines
      ? state.currentCatalogue.basketLines.length
      : 0,
  statusOfCatalogues: state => state.status,
};

const actions = {
  ADD_DISCOUNT_TO_VARIANT_IN_CATALOGUE({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    const { discountPercent, variantId } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/productsheet/active/variant/${variantId}`, {
        discountPercent,
      })
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  ADD_EXISTING_CATALOGUE_TO_CURRENT({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/productsheet/active/load/${payload.catalogueId}`,
      )
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  ADD_VARIANT_TO_CURRENT_CATALOGUE({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    const { variantId } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/productsheet/active/variant`, {
        variantId,
      })
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  BUILD_CATALOGUE({ commit, dispatch, rootGetters }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    const divisionId = rootGetters["branding/divisionId"];
    const {
      category,
      dealerAddress,
      dealerEmail,
      dealerName,
      dealerPhone,
      dealerPostCity,
      endCustomerAddress,
      endCustomerEmail,
      endCustomerName,
      endCustomerPhone,
      endCustomerPostCity,
      name,
      selectedDealer,
      selectedEndCustomer,
      selectedLanguage,
      selectedSizeChart,
      shouldUseCurrency,
    } = payload;

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/productsheet/build`, {
          category: category.split("_")[0],
          dealerAddress,
          dealerEmail,
          dealerName,
          dealerPhone,
          dealerPostCity,
          division: divisionId,
          endCustomerAddress,
          endCustomerEmail,
          endCustomerName,
          endCustomerPhone,
          endCustomerPostCity,
          name,
          selectedDealer: Array.isArray(selectedDealer) ? "" : selectedDealer,
          selectedEndCustomer: Array.isArray(selectedEndCustomer)
            ? ""
            : selectedEndCustomer,
          selectedLanguage,
          selectedSizeChart,
          shouldUseCurrency,
        })
        .then(({ data }) => {
          commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
          commit("SET_CATALOGUE_BUILD_PROCESS", { catalogueProcess: data });
          dispatch("RESET_CURRENT_DEALER_LOGO");
          dispatch("RESET_CURRENT_END_CUSTOMER_LOGO");
          resolve(data.id);
        })
        .catch(error => {
          commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
  DELETE_CATALOGUE({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.LOADING });

    return axios
      .delete(`${endpoints.ECOMMERCE}/productsheet/${payload.catalogueId}`)
      .then(() => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CATALOGUES({ commit }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/productsheet/list`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CATALOGUES", { catalogues: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CATEGORIES({ commit, rootGetters }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.LOADING });

    const divisionId = rootGetters["branding/divisionId"];

    return axios
      .get(
        `${endpoints.ECOMMERCE}/productsheet-categories/v2/${divisionId}/list`,
      )
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CATEGORIES", {
          categories: data.map(category => ({
            ...category,
            key: `${category.key}_${category.cultureCode}`,
          })),
        });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CURRENT_CATALOGUE({ commit }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/productsheet/active`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_CURRENT_DEALER_LOGO({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${payload.customerId}/logo`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_DEALER_LOGO", { logo: data.id });
      })
      .catch(error => {
        //It will return 404 if there is no logo
        if (error.response.status === 404) {
          commit("SET_CATALOGUES_STATUS", {
            status: statuses.success,
          });

          commit("SET_CURRENT_DEALER_LOGO", { logo: null });
          return;
        }

        commit("SET_CATALOGUES_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  GET_CURRENT_END_CUSTOMER_LOGO({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/${payload.customerId}/logo`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_END_CUSTOMER_LOGO", { logo: data.id });
      })
      .catch(error => {
        //It will return 404 if there is no logo
        if (error.response.status === 404) {
          commit("SET_CATALOGUES_STATUS", {
            status: statuses.success,
          });

          commit("SET_CURRENT_END_CUSTOMER_LOGO", { logo: null });
          return;
        }

        commit("SET_CATALOGUES_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  GET_DEALERS({ commit, rootGetters }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.LOADING });

    const divisionId = rootGetters["branding/divisionId"];

    return axios
      .get(`${endpoints.ECOMMERCE}/customers/listdealers/${divisionId}`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_DEALERS", { dealers: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_STATUS_OF_CATALOGUE_UNTIL_COMPLETE({ commit, dispatch }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.LOADING });

    const { id } = payload;

    return axios
      .get(`${endpoints.ECOMMERCE}/productsheet/${id}/status`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CATALOGUE_BUILD_PROCESS", { catalogueProcess: data });

        const { DONE, FAILED } = catalogueStatuses;

        if (data.status === DONE || data.status === FAILED) {
          localStorage.removeItem("catalogueBuildProcess");

          if (data.status === DONE) {
            dispatch("GET_CATALOGUES");
          }

          if (data.status === FAILED) {
            commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
            errorAlert({});
          }
        } else {
          localStorage.setItem("catalogueBuildProcess", JSON.stringify(data));

          window.setTimeout(() => {
            dispatch("GET_STATUS_OF_CATALOGUE_UNTIL_COMPLETE", { id });
          }, 10000);
        }
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  REMOVE_ALL_VARIANTS_FROM_CURRENT_CATALOGUE({ commit }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    return axios
      .delete(`${endpoints.ECOMMERCE}/productsheet/active/remove-all-variant`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  REMOVE_DEALER_LOGO({ commit, dispatch }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    return (
      axios
        .delete(`${endpoints.ECOMMERCE}/productsheet/active/dealerlogo`)
        // .then(({ data }) => {
        .then(() => {
          commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
          // commit("SET_CURRENT_CATALOGUE", { catalogue: data });
          dispatch("RESET_CURRENT_DEALER_LOGO");
        })
        .catch(error => {
          commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
        })
    );
  },
  REMOVE_END_CUSTOMER_LOGO({ commit, dispatch }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    return (
      axios
        .delete(`${endpoints.ECOMMERCE}/productsheet/active/customerlogo`)
        // .then(({ data }) => {
        .then(() => {
          commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
          // commit("SET_CURRENT_CATALOGUE", { catalogue: data }); FIXME: remove this, right?
          dispatch("RESET_CURRENT_END_CUSTOMER_LOGO");
        })
        .catch(error => {
          commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
        })
    );
  },
  REMOVE_VARIANT_FROM_CURRENT_CATALOGUE({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    return axios
      .delete(
        `${endpoints.ECOMMERCE}/productsheet/active/variant/${payload.variantId}`,
      )
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_CATALOGUE({ commit }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    return axios
      .delete(`${endpoints.ECOMMERCE}/productsheet/active`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_CURRENT_DEALER_LOGO({ commit }) {
    commit("SET_CURRENT_DEALER_LOGO", { logo: null });
  },
  RESET_CURRENT_END_CUSTOMER_LOGO({ commit }) {
    commit("SET_CURRENT_END_CUSTOMER_LOGO", { logo: null });
  },
  SORT_CATALOGUE({ commit, dispatch }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });
    const { basketLines = [] } = payload;
    return axios
      .post(`${endpoints.ECOMMERCE}/productsheet/active/sort`, {
        basketLines,
      })
      .then(() => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        dispatch("SORT_CURRENT_CATALOGUE", { sortedBasketLines: basketLines });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  /* eslint-disable */
  SORT_CURRENT_CATALOGUE({ commit, getters }, payload) {
    const { sortedBasketLines = [] } = payload;
    const currentBasketLines = cloneDeep(getters.currentCatalogue.basketLines);

    sortedBasketLines.map(sortedBasketLine => {
      const currentBasketLine = currentBasketLines.find(
        currentBasketLine =>
          currentBasketLine.variantId === sortedBasketLine.variantId,
      );

      if (sortedBasketLine.orderNum !== currentBasketLine.orderNum) {
        currentBasketLine.orderNum = sortedBasketLine.orderNum;
      }
    });

    const sortedCurrentBasketLines = currentBasketLines.sort((a, b) => {
      return a.orderNum - b.orderNum;
    });

    commit("SET_CURRENT_BASKETLINES", {
      basketLines: sortedCurrentBasketLines,
    });
  },
  UNLOAD_CATALOGUE({ commit }) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });
    return axios
      .post(`${endpoints.ECOMMERCE}/productsheet/active/unload`)
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPDATE_CATALOGUE({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    const { currency, discountPercent, shouldUseCurrency } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/productsheet/active/update`, {
        currency,
        discountPercent,
        shouldUseCurrency,
      })
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_CATALOGUE", { catalogue: data });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_DEALER_LOGO({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    const formData = new FormData();
    formData.append("image", payload.file);

    return axios
      .post(`${endpoints.ECOMMERCE}/productsheet/active/dealerlogo`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_DEALER_LOGO", {
          logo: data.customDealerLogoMediaKey,
        });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  UPLOAD_END_CUSTOMER_LOGO({ commit }, payload) {
    commit("SET_CATALOGUES_STATUS", { status: statuses.SAVING });

    const formData = new FormData();
    formData.append("image", payload.file);

    return axios
      .post(
        `${endpoints.ECOMMERCE}/productsheet/active/customerlogo`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        },
      )
      .then(({ data }) => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.SUCCESS });
        commit("SET_CURRENT_END_CUSTOMER_LOGO", {
          logo: data.customCustomerLogoMediaKey,
        });
      })
      .catch(error => {
        commit("SET_CATALOGUES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
};

const mutations = {
  SET_CATALOGUES(state, payload) {
    state.catalogues = payload.catalogues;
  },
  SET_CATALOGUES_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_CATALOGUE_BUILD_PROCESS(state, payload) {
    state.catalogueBuildProcess = payload.catalogueProcess;
  },
  SET_CATEGORIES(state, payload) {
    state.categories = payload.categories;
  },
  SET_CURRENT_CATALOGUE(state, payload) {
    state.currentCatalogue = payload.catalogue;
  },
  SET_CURRENT_CATALOGUE_DEALER_LOGO(state, payload) {
    state.currentCatalogue.customDealerLogoMediaKey = payload.logo;
  },
  SET_CURRENT_CATALOGUE_END_CUSTOMER_LOGO(state, payload) {
    state.currentCatalogue.customCustomerLogoMediaKey = payload.logo;
  },
  SET_CURRENT_DEALER_LOGO(state, payload) {
    state.currentDealerLogo = payload.logo;
  },
  SET_CURRENT_END_CUSTOMER_LOGO(state, payload) {
    state.currentEndCustomerLogo = payload.logo;
  },
  SET_CURRENT_BASKETLINES(state, payload) {
    state.currentCatalogue.basketLines = payload.basketLines;
  },
  SET_DEALERS(state, payload) {
    state.dealers = payload.dealers;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
