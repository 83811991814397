import { render, staticRenderFns } from "./RichTextCard.vue?vue&type=template&id=2be4fbf4&scoped=true"
import script from "./RichTextCard.vue?vue&type=script&lang=js"
export * from "./RichTextCard.vue?vue&type=script&lang=js"
import style0 from "./RichTextCard.vue?vue&type=style&index=0&id=2be4fbf4&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2be4fbf4",
  null
  
)

export default component.exports