<template>
  <button class="size__action" @click="$emit('select-size')">
    <StockIndicator
      :ignore-show-stock="true"
      :indicator-only="true"
      :inventory="size.inventory"
      class="indicator"
    />
    <span class="size__label">{{
      getDictionaryEntry("Common.Labels.SizeAbbreviation")
    }}</span>
    {{ getPreferredSizeChartValue(size) }}
    <RestockDateInventoryWarning
      v-if="size.inventory"
      :inventory="size.inventory"
    />
  </button>
</template>
<script>
import StockIndicator from "@/components/StockIndicator";
import RestockDateInventoryWarning from "@/components/RestockDateInventoryWarning.vue";

export default {
  name: "SizeSelectorOption",
  components: { RestockDateInventoryWarning, StockIndicator },
  props: {
    size: {
      required: true,
      type: Object,
    },
    user: {
      required: true,
      type: Object,
    },
  },
  emits: ["select-size"],
};
</script>
<style lang="scss" scoped>
.size {
  &__action {
    @include reset-button();

    align-items: center;
    display: flex;
    padding: 0.5rem;
  }

  &__label {
    color: transparent;
    display: block;
    margin-right: 0.5rem;
  }
}

.indicator {
  margin-right: 0.5rem;
}
</style>
