<template>
  <div v-if="features.length" class="features">
    <ul class="features__list">
      <li v-for="feature in features" :key="feature.id" class="features__item">
        <article class="feature">
          <div class="feature__header">
            <div
              v-if="feature.image && feature.image.src"
              class="feature__image-container"
            >
              <img
                :src="feature.image.src"
                :alt="feature.image.alt"
                class="feature__image"
              />
            </div>
            <h4 :id="feature.id" class="feature__title">
              {{ feature.name }}
            </h4>
          </div>
          <p class="feature__description">
            {{ feature.description }}
          </p>
        </article>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "ProductFeatures",
  props: {
    features: {
      required: true,
      type: Array,
    },
  },
};
</script>

<style lang="scss" scoped>
.feature {
  &__description {
    margin: 0;
    max-width: 40rem;
  }

  &__header {
    align-items: center;
    display: flex;
    margin-bottom: var(--spacing-container);
  }

  &__image {
    display: block;
    height: 100%;
    left: 50%;
    object-fit: contain;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;

    &-container {
      flex-basis: var(--size-product-feature-image);
      flex-grow: 0;
      flex-shrink: 0;
      height: var(--size-product-feature-image);
      margin-right: var(--spacing-container);
      position: relative;
      width: var(--size-product-feature-image);
    }
  }

  &__title {
    flex: 1 1 auto;
    font-size: 1rem;
    margin: 0 1rem 0 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.features {
  &__item {
    &:not(:last-child) {
      margin-bottom: calc(var(--spacing-stack) * 1.5);
    }
  }

  &__list {
    @include reset-list();
  }
}
</style>
