<template>
  <div
    class="checkbox"
    :class="{
      'checkbox--button-style': buttonStyle,
      'checkbox--error': hasError,
      'checkbox--disabled': disabled,
      'checkbox--round': round,
    }"
    @click.stop
  >
    <input
      :id="id"
      :checked="value"
      class="checkbox__input"
      type="checkbox"
      @change="$emit('input', $event.target.checked)"
    />
    <label
      :for="id"
      class="checkbox__label"
      :class="{ 'checkbox__label--empty': !label }"
      ><span v-if="label" :title="label">{{ label }}</span></label
    >
    <Error :message="errorMessage" :visible="hasError" />
  </div>
</template>

<script>
import Error from "@/components/Error";
import { uuid } from "@/helpers/uuid";

export default {
  name: "Checkbox",
  components: {
    Error,
  },
  model: {
    event: "input",
  },
  props: {
    buttonStyle: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    hasError: {
      default: false,
      type: Boolean,
    },
    label: {
      default: "",
      type: String,
    },
    round: {
      default: false,
      type: Boolean,
    },
    value: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      id: uuid(),
    };
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  position: relative;
  text-transform: none;
  transition: opacity 300ms ease, padding 300ms ease;

  &--button-style {
    overflow: hidden;
  }

  &--disabled {
    opacity: 0.25;
    pointer-events: none;
  }

  &--error {
    padding-bottom: var(--spacing-error-message);
  }

  &__input {
    height: 0;
    left: 0;
    margin: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 0;
  }

  &__label {
    align-items: center;
    background-color: #ffffff;
    border-radius: 0.25rem;
    cursor: pointer;
    display: flex;
    font-size: 0.875rem;
    line-height: var(--size-checkbox);
    min-height: var(--size-checkbox);
    min-width: var(--size-checkbox);
    padding-left: calc(var(--size-checkbox) * 1.4);
    position: relative;
    z-index: 2;

    .checkbox--button-style & {
      background-color: var(--color-checkbox-background);
      border: 1px solid var(--color-checkbox-border);
      border-radius: 2rem;
      display: block;
      overflow: hidden;
      padding: 0.375rem 0.625rem;
      text-align: center;
      text-overflow: ellipsis;
      transition: background-color 300ms ease, border-color 300ms ease,
        box-shadow 300ms ease;
      white-space: nowrap;

      &::before,
      &::after {
        content: none;
      }
    }

    .checkbox--button-style .checkbox__input:checked + & {
      background-color: var(--color-checkbox-background-active);
      border-color: var(--color-checkbox-border-active);
      color: #ffffff;
    }

    .checkbox--round & {
      border-radius: 50%;
    }

    .filters__box & {
      @media (min-width: 90rem) {
        white-space: nowrap;
      }
    }

    .group-selector & {
      background-color: transparent;
    }

    &::before,
    &::after {
      content: "";
      height: var(--size-checkbox);
      left: 0;
      margin-top: calc((var(--size-checkbox) * -0.5));
      position: absolute;
      top: 50%;
      width: var(--size-checkbox);
    }

    &::before {
      background-color: var(--color-checkbox-background);
      border: 1px solid var(--color-checkbox-border);
      border-radius: 0.25rem;
      transition: background-color 300ms ease, border-color 300ms ease,
        box-shadow 300ms ease;

      .checkbox--error & {
        border-color: var(--color-error);
      }

      .checkbox--round & {
        background-color: transparent !important;
        border-radius: 50%;
        border-color: var(--color-checkbox-border-active);
      }

      .checkbox__input:checked + & {
        background-color: var(--color-checkbox-background-active);
        border-color: var(--color-checkbox-border-active);
      }

      .group-selector & {
        background-color: #ffffff;
      }
    }

    &::after {
      background-image: url("../assets/checkmark.svg");
      background-position: center;
      background-repeat: no-repeat;
      background-size: 57.5%;
      opacity: 0;
      transition: opacity 300ms ease;
      transform-origin: center;

      .checkbox--round & {
        background-image: url("../assets/checkmark-dark.svg");
        background-size: 52.5%;
      }

      .checkbox__input:checked + & {
        opacity: 1;
      }
    }

    &--empty {
      padding: 0;
      width: var(--size-checkbox);
    }

    .checkboxes__list & {
      background-color: transparent;
      padding-bottom: calc(var(--size-checkbox) * (1 / 3));
      padding-top: calc(var(--size-checkbox) * (1 / 3));
    }
  }
}
</style>
