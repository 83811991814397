<template>
  <span v-if="shouldShowRestockDate">
    {{ restockDateWarning }}
  </span>
</template>
<script>
export default {
  name: "RestockDateInventoryWarning",
  props: {
    inventory: {
      required: true,
      type: Object,
    },
  },
  computed: {
    shouldShowRestockDate() {
      return this.inventory?.restockDate && this.inventory.level === "red";
    },
    restockDateWarning() {
      return `${this.getDictionaryEntry("Common.Labels.RestockDateMessage")} (${
        this.inventory?.restockDate
      })`;
    },
  },
};
</script>
