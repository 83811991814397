import store from "@/store";

export function loadGoogleTagManager() {
  if (document.querySelector("[src*='googletagmanager'")) return;

  const gaMeasurementId = store.getters["siteSettings/gaMeasurementId"];

  if (!gaMeasurementId) {
    console.warn("Missing GA_MEASUREMENT_ID.");
    return;
  }

  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": new Date().getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = "https://www.googletagmanager.com/gtm.js?id=" + i + dl;
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", gaMeasurementId);
}

export function pushToDataLayer(data) {
  const gaMeasurementId = store.getters["siteSettings/gaMeasurementId"];

  if (!data) {
    console.warn("No data was provided.");
    return;
  } else if (!gaMeasurementId) {
    console.warn("Missing GA_MEASUREMENT_ID.");
    return;
  } else if (!window.dataLayer) {
    console.warn("Data Layer doesn't exist.");
    return;
  }

  window.dataLayer.push(data);
}
