<template>
  <Container v-if="blockData" :small="blockData.decreaseWidth">
    <div
      v-if="blockData.headline || blockData.description"
      class="block block--title"
      :class="{ 'block--indent-text': blockData.indentText }"
    >
      <div class="block__content">
        <div>
          <Heading
            v-if="blockData.headline"
            :tag="blockData.headlineTag"
            class="block__title block__title--with-line"
          >
            {{ blockData.headline }}
          </Heading>
          <p v-if="blockData.description" class="block__description">
            {{ blockData.description }}
          </p>
        </div>
      </div>
    </div>
    <div class="block block--tall">
      <div
        v-for="item in blockData.rowItems"
        :key="item._id"
        class="block__one-third block__media-container block__media-container--overlay"
        :class="{
          'block--dark-background': item.darkBackground,
          'block--text-focus': item.darkBackground,
          'block__two-thirds': item.expandItem,
        }"
      >
        <ImageFromUmbraco
          v-if="item.image"
          :id="item.image._system.id"
          :alt="item.image.alternateText"
          :height="item.image.umbracoHeight"
          :keys-and-sizes="{
            s: 'block-regular-small',
            m: 'block-tall-medium',
            l: 'block-tall-large',
            xl: 'block-tall-extra-large',
          }"
          :width="item.image.umbracoWidth"
          class="block__image"
        />
        <div class="block__content block__content--bottom">
          <article>
            <div v-if="item.caption">
              <div v-if="item.darkBackground" class="block__keywords">
                {{ item.caption }}
              </div>
              <div v-else class="block__subtitle">{{ item.caption }}</div>
            </div>
            <h2 v-if="item.headline" class="block__title">
              {{ item.headline }}
            </h2>
            <ConditionalRouterLink
              v-if="
                item.cTA && item.cTA.name && item.cTA.url && !item.hideButton
              "
              :target="item.cTA.target"
              :to="item.cTA.url"
              :type="item.cTA.type"
              class="block__button"
              >{{ item.cTA.name }}</ConditionalRouterLink
            >
          </article>
          <ConditionalRouterLink
            v-if="item.cTA && item.cTA.name && item.cTA.url && item.hideButton"
            :aria-label="item.cTA.name"
            :target="item.cTA.target"
            :to="item.cTA.url"
            :type="item.cTA.type"
            class="block__cover-link"
          ></ConditionalRouterLink>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import Container from "@/components/Container";
import Heading from "@/components/Heading";
import ImageFromUmbraco from "@/components/ImageFromUmbraco";

export default {
  name: "ImageAndTextRowGridGridElement",
  components: {
    ConditionalRouterLink,
    Container,
    Heading,
    ImageFromUmbraco,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
};
</script>
