<template>
  <div
    v-if="blockData"
    class="block block--dashboard block--dashboard-product block--product block__media-container"
  >
    <div class="block__40-percent">
      <DynamicData :loading="loading">
        <div
          v-if="variant"
          class="block__content block__content--middle block__content--center block__content--product-image"
        >
          <div class="block__product">
            <ProductImage :variant="variant" class="block__product-image" />
          </div>
        </div>
      </DynamicData>
    </div>
    <div class="block__60-percent">
      <div v-if="variant" class="block__content block__content--middle">
        <div>
          <ConditionalRouterLink
            v-if="blockData.link && blockData.link.name && blockData.link.url"
            :target="blockData.link.target"
            :to="blockData.link.url"
            :type="blockData.link.type"
            class="block__link"
            >{{ blockData.link.name }}</ConditionalRouterLink
          >
          <!-- <Stack :quarter="true">
            <div class="block__subtitle">
              TODO: Save up to (or similar)
            </div>
          </Stack> -->
          <Stack v-if="variant.productName">
            <h2 class="block__product-title">
              {{ blockData.variantName || variant.productName }}
            </h2>
          </Stack>
          <Stack :half="true">
            <h3 class="block__product-id">
              {{ blockData.variantId }}
            </h3>
          </Stack>
          <Stack v-if="blockData.variantDescription">
            <p class="block__product-description">
              {{ blockData.variantDescription }}
            </p>
          </Stack>
          <Stack>
            <router-link
              :to="variantLink"
              class="block__button block__button--ghost"
              >{{ getDictionaryEntry("Product.BuyNow") }}</router-link
            >
          </Stack>
          <div v-if="blockData.footernote" class="block__disclaimer">
            {{ blockData.footernote }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConditionalRouterLink from "@/components/ConditionalRouterLink";
import DynamicData from "@/components/DynamicData";
import ProductImage from "@/components/ProductImage";
import Stack from "@/components/Stack";

export default {
  name: "DashboardProductBlockGridElement",
  components: {
    ConditionalRouterLink,
    DynamicData,
    ProductImage,
    Stack,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    variants: {
      default: () => [],
      type: Array,
    },
  },
  computed: {
    variant() {
      const { variants } = this;

      if (!variants || !variants.length) return null;

      return variants[0];
    },
    variantLink() {
      const { variant } = this;

      if (!variant || !variant.productId || !variant.variantId) return null;

      return {
        query: {
          mode: "details",
          productId: variant.productId,
          variantId: variant.variantId,
        },
      };
    },
  },
};
</script>
