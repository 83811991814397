<template>
  <Container v-if="blockData">
    <div
      class="block block--product block--tall"
      :class="{
        'block--use-right-side-layout': shouldProductBePlacedToTheRight,
      }"
    >
      <div class="block__45-percent">
        <div
          class="block__content block__content--limited-width block__content--middle"
        >
          <div>
            <div v-if="blockData.caption" class="block__keywords">
              {{ blockData.caption }}
            </div>
            <Heading
              v-if="blockData.headline"
              :tag="blockData.headlineTag"
              class="block__title"
            >
              {{ blockData.headline }}
            </Heading>
            <p v-if="blockData.description" class="block__description">
              {{ blockData.description }}
            </p>
            <router-link
              v-if="blockData.cTA && blockData.cTA.name && blockData.cTA.url"
              :to="blockData.cTA.url"
              class="block__button block__button--ghost"
              >{{ blockData.cTA.name }}</router-link
            >
          </div>
        </div>
      </div>
      <article class="block__55-percent block__media-container">
        <DynamicData :loading="loading">
          <div
            v-if="variant"
            class="block__content block__content--middle block__content--center"
          >
            <div class="block__50-percent">
              <div class="block__content block__content--product-image">
                <div class="block__product">
                  <ProductImage
                    :variant="variant"
                    class="block__product-image"
                  />
                </div>
              </div>
            </div>
            <div class="block__50-percent">
              <div class="block__content">
                <div class="block__product">
                  <h1 v-if="variant.productName" class="block__product-title">
                    {{ blockData.variantName || variant.productName }}
                  </h1>
                  <h2
                    v-if="variant.minPrice && variant.minPrice.price"
                    class="block__product-price"
                  >
                    {{ getDictionaryEntry("Product.PricesFrom") }}
                    {{ variant.minPrice.price }}
                    {{ variant.minPrice.currency }}
                  </h2>
                  <p
                    v-if="blockData.variantDescription"
                    class="block__product-description"
                  >
                    {{ blockData.variantDescription }}
                  </p>
                  <router-link
                    :to="variantLink"
                    class="block__button block__button--ghost"
                    >{{ getDictionaryEntry("Product.BuyNow") }}</router-link
                  >
                </div>
              </div>
            </div>
          </div>
        </DynamicData>
      </article>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/Container";
import DynamicData from "@/components/DynamicData";
import ProductImage from "@/components/ProductImage";

export default {
  name: "ProductBlockGridElement",
  components: {
    Container,
    DynamicData,
    ProductImage,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
    loading: {
      default: false,
      type: Boolean,
    },
    variants: {
      default: () => [],
      type: Array,
    },
  },
  computed: {
    shouldProductBePlacedToTheRight() {
      if (!this.blockData || !this.blockData.layout) return true;

      return this.blockData.layout.toLowerCase() === "right";
    },
    variant() {
      const { variants } = this;

      if (!variants || !variants.length) return null;

      return variants[0];
    },
    variantLink() {
      const { variant } = this;

      if (!variant || !variant.productId || !variant.variantId) return null;

      return {
        query: {
          mode: "details",
          productId: variant.productId,
          variantId: variant.variantId,
        },
      };
    },
  },
};
</script>
