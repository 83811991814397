import Vue from "vue";
import Vuex from "vuex";
import addresses from "./modules/addresses";
import alerts from "./modules/alerts";
import authentication from "./modules/authentication";
import basket from "./modules/basket";
import branding from "./modules/branding";
import catalogues from "./modules/catalogues";
import checkout from "./modules/checkout";
import contact from "./modules/contact";
import content from "./modules/content";
import customerService from "./modules/customerService";
import customers from "./modules/customers";
import exports from "./modules/exports";
import externalProducts from "./modules/externalProducts";
import imageGallery from "./modules/imageGallery";
import imports from "./modules/imports";
import inventory from "./modules/inventory";
import orders from "./modules/orders";
import parcelShops from "./modules/parcelShops";
import permissions from "./modules/permissions";
import products from "./modules/products";
import profile from "./modules/profile";
import purchaseLimitations from "./modules/purchaseLimitations";
import roleTemplates from "./modules/roleTemplates";
import salespersons from "./modules/salespersons";
import shipping from "./modules/shipping";
import shopAsUser from "./modules/shopAsUser";
import shopToUser from "./modules/shopToUser";
import siteSettings from "./modules/siteSettings";
import suggestions from "./modules/suggestions";
import systemadministrators from "./modules/systemadministrators";
import userGroupAdministrativeUsers from "./modules/userGroupAdministrativeUsers";
import userGroupUsers from "./modules/userGroupUsers";
import userGroups from "./modules/userGroups";
import users from "./modules/users";
import wallets from "./modules/wallets";
import wardrobes from "./modules/wardrobes";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    addresses,
    alerts,
    authentication,
    basket,
    branding,
    catalogues,
    checkout,
    contact,
    content,
    customerService,
    customers,
    exports,
    externalProducts,
    imageGallery,
    imports,
    inventory,
    orders,
    parcelShops,
    permissions,
    products,
    profile,
    purchaseLimitations,
    roleTemplates,
    salespersons,
    shipping,
    shopAsUser,
    shopToUser,
    siteSettings,
    suggestions,
    systemadministrators,
    userGroupAdministrativeUsers,
    userGroupUsers,
    userGroups,
    users,
    wallets,
    wardrobes,
  },
  strict: process.env.NODE_ENV !== "production",
});
