import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { alertTypes } from "@/helpers/alertTypes";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentCustomerServiceRepresentative: null,
  currentCustomerServiceRepresentativeId: null,
  isModalForCreatingCustomerServiceRepresentativeOpen: false,
  customerServiceRepresentatives: [],
  selectedCustomerServiceRepresentatives: [],
  selectedDivisions: [],
  status: "",
});

const getters = {
  currentCustomerServiceRepresentative: state =>
    state.currentCustomerServiceRepresentative,
  currentCustomerServiceRepresentativeId: state =>
    state.currentCustomerServiceRepresentativeId,
  isModalForCreatingCustomerServiceRepresentativeOpen: state =>
    state.isModalForCreatingCustomerServiceRepresentativeOpen,
  nameOfCurrentCustomerServiceRepresentative: state => {
    const { currentCustomerServiceRepresentative } = state;

    if (!currentCustomerServiceRepresentative) return null;

    return currentCustomerServiceRepresentative.name
      ? currentCustomerServiceRepresentative.name
      : null;
  },
  customerServiceRepresentatives: state => state.customerServiceRepresentatives,
  selectedCustomerServiceRepresentatives: state =>
    state.selectedCustomerServiceRepresentatives,
  statusOfCustomerServiceRepresentatives: state => state.status,
};

const actions = {
  CLOSE_MODAL_FOR_CREATING_CUSTOMER_SERVICE_REPRESENTATIVE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_CUSTOMER_SERVICE_REPRESENTATIVE", {
      value: false,
    });
  },
  CREATE_CUSTOMER_SERVICE_REPRESENTATIVE({ commit, dispatch }, payload) {
    commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
      status: statuses.SAVING,
    });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/createCustomerService`, {
          ...payload.customerServiceRepresentative,
        })
        .then(({ data }) => {
          commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
            status: statuses.SUCCESS,
          });
          dispatch("CLOSE_MODAL_FOR_CREATING_CUSTOMER_SERVICE_REPRESENTATIVE");
          resolve(data);
        })
        .catch(error => {
          commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
            status: statuses.FAILURE,
          });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_SELECTED_CUSTOMER_SERVICE_REPRESENTATIVES({
    commit,
    dispatch,
    state,
  }) {
    commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
      status: statuses.LOADING,
    });

    return axios
      .post(`${endpoints.ECOMMERCE}/users/delete-multiple`, {
        identifiers: state.selectedCustomerServiceRepresentatives.map(
          customerServiceRepresentative => customerServiceRepresentative.id,
        ),
      })
      .then(() => {
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
          status: statuses.SUCCESS,
        });
        dispatch("GET_CUSTOMER_SERVICE_REPRESENTATIVES");
        commit("DESELECT_CUSTOMER_SERVICE_REPRESENTATIVES");
      })
      .catch(error => {
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  GET_CUSTOMER_SERVICE_REPRESENTATIVE({ commit }, payload) {
    commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
      status: statuses.LOADING,
    });

    return axios
      .get(`${endpoints.ECOMMERCE}/customerservice/${payload.id}`)
      .then(({ data: customerServiceRepresentative }) => {
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
          status: statuses.SUCCESS,
        });

        commit("SET_CURRENT_CUSTOMER_SERVICE_REPRESENTATIVE", {
          customerServiceRepresentative,
        });
      })
      .catch(error => {
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  GET_CUSTOMER_SERVICE_REPRESENTATIVES({ commit }) {
    commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
      status: statuses.LOADING,
    });

    return axios
      .get(`${endpoints.ECOMMERCE}/customerservice/list`)
      .then(({ data }) => {
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
          status: statuses.SUCCESS,
        });
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES", {
          customerServiceRepresentatives: data,
        });
      })
      .catch(error => {
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_CREATING_CUSTOMER_SERVICE_REPRESENTATIVE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_CUSTOMER_SERVICE_REPRESENTATIVE", {
      value: true,
    });
  },
  TOGGLE_CUSTOMER_SERVICE_REPRESENTATIVE({ commit }, payload) {
    commit("SET_SELECTED_CUSTOMER_SERVICE_REPRESENTATIVE", payload);
  },
  TOGGLE_CUSTOMER_SERVICE_REPRESENTATIVES({ commit }, payload) {
    commit("SET_SELECTED_CUSTOMER_SERVICE_REPRESENTATIVES", payload);
  },
  TOGGLE_DIVISION({ commit }, payload) {
    commit("SET_SELECTED_DIVISION", payload);
  },
  UPDATE_CURRENT_CUSTOMER_SERVICE_REPRESENTATIVE_ID({ commit }, payload) {
    commit("SET_CURRENT_CUSTOMER_SERVICE_REPRESENTATIVE_ID", payload);
    commit("SET_SELECTED_CUSTOMER_SERVICE_REPRESENTATIVES");
  },
  UPDATE_CUSTOMER_SERVICE_REPRESENTATIVE({ commit, dispatch }, payload) {
    commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
      status: statuses.SAVING,
    });
    const { id, customerServiceRepresentative } = payload;

    return axios
      .post(`${endpoints.ECOMMERCE}/users/${id}`, customerServiceRepresentative)
      .then(() => {
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
          status: statuses.SUCCESS,
        });
        dispatch("GET_CUSTOMER_SERVICE_REPRESENTATIVE", { id });
        dispatch(
          "alerts/OPEN_ALERT",
          {
            key: "Common.YourChangesWereSaved",
            type: alertTypes.SUCCESS,
          },
          {
            root: true,
          },
        );
      })
      .catch(error => {
        commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  WELCOME_SELECTED_CUSTOMER_SERVICE_REPRESENTATIVES({
    commit,
    dispatch,
    state,
  }) {
    commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
      status: statuses.LOADING,
    });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/users/send-welcomemail`, {
          identifiers: state.selectedCustomerServiceRepresentatives.map(
            employee => employee.id,
          ),
        })
        .then(response => {
          commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
            status: statuses.SUCCESS,
          });
          commit("DESELECT_CUSTOMER_SERVICE_REPRESENTATIVES");
          dispatch("GET_CUSTOMER_SERVICE_REPRESENTATIVES");
          dispatch(
            "alerts/OPEN_ALERT",
            {
              key: "Administration.WelcomeEmailsSuccessfullySent",
              type: alertTypes.SUCCESS,
            },
            {
              root: true,
            },
          );
          resolve(response);
        })
        .catch(error => {
          commit("SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS", {
            status: statuses.FAILURE,
          });
          errorAlert(error);
          reject(error);
        });
    });
  },
};

const mutations = {
  DESELECT_CUSTOMER_SERVICE_REPRESENTATIVES(state) {
    state.selectedCustomerServiceRepresentatives = [];
  },
  SET_CURRENT_CUSTOMER_SERVICE_REPRESENTATIVE(state, payload) {
    state.currentCustomerServiceRepresentative =
      payload.customerServiceRepresentative;
  },
  SET_CURRENT_CUSTOMER_SERVICE_REPRESENTATIVE_ID(state, payload) {
    state.currentCustomerServiceRepresentativeId = payload.id;
  },
  SET_CURRENT_CUSTOMER_SERVICE_REPRESENTATIVE_IMAGE(state, payload) {
    const { currentCustomerServiceRepresentative } = state;

    state.currentCustomerServiceRepresentative = {
      ...currentCustomerServiceRepresentative,
      photo: payload.image,
    };
  },
  SET_CUSTOMER_SERVICE_REPRESENTATIVE(state, payload) {
    const { customerServiceRepresentative } = payload;

    const customerServiceRepresentativeExists = state.customerServiceRepresentatives.find(
      currentCustomerServiceRepresentative =>
        currentCustomerServiceRepresentative.id ===
        customerServiceRepresentative.id,
    );

    if (customerServiceRepresentativeExists) {
      const customerServiceRepresentatives = [
        ...state.customerServiceRepresentatives,
      ];
      const indexOfMatchingServiceEmployee = customerServiceRepresentatives.findIndex(
        currentCustomerServiceRepresentative =>
          currentCustomerServiceRepresentative.id ===
          customerServiceRepresentative.id,
      );

      customerServiceRepresentatives[
        indexOfMatchingServiceEmployee
      ] = customerServiceRepresentatives;

      state.customerServiceRepresentatives = customerServiceRepresentatives;
    } else {
      state.customerServiceRepresentatives.push(customerServiceRepresentative);
    }
  },
  SET_CUSTOMER_SERVICE_REPRESENTATIVES(state, payload) {
    state.customerServiceRepresentatives =
      payload.customerServiceRepresentatives;
  },
  SET_CUSTOMER_SERVICE_REPRESENTATIVES_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_SELECTED_CUSTOMER_SERVICE_REPRESENTATIVE(state, payload) {
    const { row: customerServiceRepresentative, value } = payload;

    if (value) {
      if (
        state.selectedCustomerServiceRepresentatives.some(
          entry => entry.id === customerServiceRepresentative.id,
        )
      ) {
        return;
      }

      state.selectedCustomerServiceRepresentatives.push(
        customerServiceRepresentative,
      );
    } else {
      state.selectedCustomerServiceRepresentatives = state.selectedCustomerServiceRepresentatives.filter(
        selectedServiceEmployee =>
          selectedServiceEmployee.id !== customerServiceRepresentative.id,
      );
    }
  },
  SET_SELECTED_CUSTOMER_SERVICE_REPRESENTATIVES(state, payload) {
    if (!payload) {
      state.selectedCustomerServiceRepresentatives = [];
      return;
    }

    const { rows: customerServiceRepresentatives, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedCustomerServiceRepresentatives.map(
          selectedServiceEmployee => selectedServiceEmployee.id,
        ),
      );

      state.selectedCustomerServiceRepresentatives = [
        ...state.selectedCustomerServiceRepresentatives,
        ...customerServiceRepresentatives
          .filter(
            customerServiceRepresentative =>
              !customerServiceRepresentative.disableSelection,
          )
          .filter(
            customerServiceRepresentative =>
              !identifiers.has(customerServiceRepresentative.id),
          ),
      ];
    } else {
      state.selectedCustomerServiceRepresentatives = state.selectedCustomerServiceRepresentatives.filter(
        selectedServiceEmployee =>
          !customerServiceRepresentatives.some(
            entry => entry.id === selectedServiceEmployee.id,
          ),
      );
    }
  },
  SET_SELECTED_DIVISION(state, payload) {
    const { division, value } = payload;

    if (value) {
      if (state.selectedDivisions.includes(division)) return;

      state.selectedDivisions.push(division);
    } else {
      state.selectedDivisions = state.selectedDivisions.filter(
        selectedDivision => selectedDivision !== division,
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_CREATING_CUSTOMER_SERVICE_REPRESENTATIVE(
    state,
    payload,
  ) {
    state.isModalForCreatingCustomerServiceRepresentativeOpen = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
