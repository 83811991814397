import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { statuses } from "@/helpers/statuses";
import { errorAlert } from "../../helpers/errorAlert";

const state = () => ({
  lastSearchedQuery: "",
  suggestedProducts: [],
  status: "",
});

const getters = {
  lastSearchedQuery: state => state.lastSearchedQuery,
  suggestedProducts: state => state.suggestedProducts,
  statusOfSuggestions: state => state.status,
};

const actions = {
  GET_SUGGESTIONS_FOR_PRODUCTS({ commit }, payload) {
    commit("SET_SUGGESTIONS_STATUS", { status: statuses.LOADING });

    return axios
      .post(`${endpoints.ECOMMERCE}/product/SearchVariantsForCustomer`, {
        facetFilterParams: [],
        pageNumberZeroBased: 0,
        pageSize: 5,
        rangeFilterParams: [],
        searchPhrase: payload.searchQuery,
      })
      .then(({ data }) => {
        commit("SET_SUGGESTIONS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SUGGESTIONS_FOR_PRODUCTS", { products: data.products });
        commit("SET_LAST_SEARCHED_QUERY", { query: payload.searchQuery });
      })
      .catch(error => {
        commit("SET_SUGGESTIONS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  RESET_SUGGESTIONS({ commit }) {
    commit("SET_SUGGESTIONS_FOR_PRODUCTS", { products: [] });
    commit("SET_LAST_SEARCHED_QUERY", { query: "" });
  },
  UPDATE_SEARCH_QUERY({ commit }, payload) {
    commit("SET_LAST_SEARCHED_QUERY", payload);
  },
};

const mutations = {
  SET_LAST_SEARCHED_QUERY(state, payload) {
    state.lastSearchedQuery = payload.query;
  },
  SET_SUGGESTIONS_FOR_PRODUCTS(state, payload) {
    state.suggestedProducts = payload.products;
  },
  SET_SUGGESTIONS_STATUS(state, payload) {
    state.status = payload.status;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
