var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card",class:{
    'card--expandable': _vm.expandable,
    'card--expanded': _vm.expanded,
    'card--no-spacing': _vm.noSpacing,
  }},[_c('div',{staticClass:"card__header"},[_c('div',[(_vm.title)?_c('h2',{staticClass:"card__title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e()]),(_vm.hasHeaderSlot)?_c('div',{staticClass:"card__header-slot"},[_vm._t("header")],2):_vm._e()]),_c('div',{ref:"content",staticClass:"card__content",class:{
      'card__content--expandable': _vm.expandable,
      'card__content--hide-overflow': _vm.hideOverflow,
      'card__content--fill-height': _vm.fillHeight,
      'card__content--with-title': _vm.title,
    },attrs:{"aria-hidden":_vm.expandable ? !_vm.expanded : null}},[_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }