<template>
  <div v-if="shopAs" class="balance-container">
    <Container v-if="link" class="balance">
      <router-link :to="{ name: 'customers' }" class="balance__text">
        {{ getDictionaryEntry("Administration.CurrentlyShoppingAs") }}:
        <span class="balance__name">
          {{ onBehalfUserName }}
          <span v-if="shouldUseWallet && balance"
            >({{ formatPriceCurrency(balance.amount) }}
            {{ balance.currency }})</span
          >
        </span>
      </router-link>
    </Container>
    <div v-else class="balance">
      <div>
        <Stack class="balance__text">
          <div>
            {{ getDictionaryEntry("Administration.CurrentlyShoppingAs") }}:
          </div>
          <div class="balance__name">
            {{ onBehalfUserName }}
            <span v-if="shouldUseWallet && balance"
              >({{ formatPriceCurrency(balance.amount) }}
              {{ balance.currency }})</span
            >
          </div>
          <div>{{ customerName }}</div>
        </Stack>
        <Stack :quarter="true">
          <Button
            :limited-width="true"
            :show-loader="isLoading"
            :small="true"
            @click="deleteState"
            >{{
              getDictionaryEntry("Administration.EmptyBasketAndStopShopping")
            }}</Button
          >
        </Stack>
      </div>
    </div>
  </div>
  <div v-else-if="link && shouldUseWallet && balance" class="balance-container">
    <Container class="balance">
      <router-link :to="{ name: 'profile.transactions' }" class="balance__text">
        {{ getDictionaryEntry("Common.Labels.Balance") }}:
        <span
          >{{ formatPriceCurrency(balance.amount) }}
          {{ balance.currency }}</span
        >
      </router-link>
    </Container>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import Container from "@/components/Container";
import Stack from "@/components/Stack";
import { statuses } from "@/helpers/statuses";

export default {
  name: "CurrentBalance",
  components: {
    Button,
    Container,
    Stack,
  },
  props: {
    link: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      isPopoutOpen: false,
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "customerName",
      "isUserSystemadministrator",
      "onBehalfUserName",
      "shouldUseWallet",
      "userId",
    ]),
    ...mapGetters("shopAsUser", ["shopAs", "statusOfShopAsUser"]),
    ...mapGetters("wallets", ["balance"]),
    isLoading() {
      return this.statusOfShopAsUser === statuses.LOADING;
    },
  },
  methods: {
    ...mapActions("shopAsUser", ["DELETE_STATE_OF_SHOP_AS"]),
    closePopout() {
      this.isPopoutOpen = false;
    },
    deleteState() {
      if (
        window.confirm(
          this.getDictionaryEntry("Administration.ConfirmDeletion"),
        )
      ) {
        this.DELETE_STATE_OF_SHOP_AS({ userId: this.userId });
      }
    },
    openPopout() {
      this.isPopoutOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.balance {
  align-items: center;
  display: flex;
  justify-content: flex-end;
  margin: var(--spacing-stack) 0 calc(var(--spacing-stack) * 1.5);

  .end-customers & {
    margin-top: 0;
  }

  .service-bar & {
    justify-content: center;
    margin: 0;
    padding: 0;
  }

  &-container {
    align-items: center;
    display: flex;

    .customers &,
    .end-customers & {
      @media (min-width: 48rem) {
        justify-content: flex-end;
      }
    }
  }

  &__action {
    @include reset-button();

    display: flex;
    transform: translateY(-50%);
  }

  &__name {
    font-weight: 400;
  }

  &__text {
    color: inherit;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.45;
    text-decoration: none;

    .service-bar & {
      padding: calc(var(--spacing-stack) * 0.5) 0;

      @media (min-width: 64rem) {
        padding: 0;
      }
    }
  }
}
</style>
