var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.variants && _vm.variants.length)?_c('Container',{attrs:{"medium":true}},[_c('Stack',[_c('div',{staticClass:"block"},[_c('h3',{staticClass:"block__title block__title--with-line"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]),_c('Stack',{attrs:{"one-and-half":true}},[_c('Flicking',{staticClass:"slider slider--products slider--short",attrs:{"options":_vm.options,"plugins":_vm.plugins}},[_vm._l((_vm.variants),function(variant,index){return _c('div',{key:'related-products_' + _vm.parentId + variant.variantId + index,staticClass:"slide slide--small"},[_c('article',{staticClass:"slide__content"},[_c('Stack',[_c('div',{staticClass:"block__product-image-container"},[_c('ProductImage',{staticClass:"block__product-image",attrs:{"variant":variant}})],1)]),_c('Stack',[_c('h1',{staticClass:"slide__title"},[_vm._v(" "+_vm._s(variant.productName)+" - "+_vm._s(variant.variantName)+" ")])]),(variant && variant.minPrice && variant.minPrice.price)?_c('Stack',{attrs:{"quarter":true}},[_c('div',{staticClass:"slide__price"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Product.PricesFrom"))+" "+_vm._s(variant.minPrice.price)+" "+_vm._s(variant.minPrice.currency)+" ")])]):_vm._e()],1),_c('router-link',{staticClass:"block__cover-link",attrs:{"to":{
            query: {
              mode: 'details',
              productId: variant.productId,
              variantId: variant.variantId,
            },
          },"aria-label":`${variant.productName} - ${variant.variantName}`}})],1)}),_c('button',{staticClass:"slider__navigation slider__navigation--prev",attrs:{"slot":"viewport","aria-label":"previous"},slot:"viewport"},[_c('svg',{staticClass:"slider__navigation-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M19 12H5M12 19l-7-7 7-7"}})])]),_c('button',{staticClass:"slider__navigation slider__navigation--next",attrs:{"slot":"viewport","aria-label":"next"},slot:"viewport"},[_c('svg',{staticClass:"slider__navigation-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M5 12h14M12 5l7 7-7 7"}})])])],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }