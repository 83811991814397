import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  isModalForSelectingPersonToShopAsOpen: false,
  shopAs: null,
  status: "",
  usersToShopAs: [],
});

const getters = {
  isModalForSelectingPersonToShopAsOpen: state =>
    state.isModalForSelectingPersonToShopAsOpen,
  shopAs: state => state.shopAs,
  shopAsUserId: state => {
    return state?.shopAs?.onBehalfOfUser || null;
  },
  statusOfShopAsUser: state => state.status,
  usersToShopAs: state => state.usersToShopAs,
};

const actions = {
  CLOSE_MODAL_FOR_SELECTING_PERSON_TO_SHOP_AS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_SELECTING_PERSON_TO_SHOP_AS", {
      value: false,
    });
  },
  CREATE_STATE_OF_SHOP_AS({ commit, dispatch, rootGetters }, payload) {
    commit("SET_SHOP_AS_STATUS", { status: statuses.SAVING });

    const userId = rootGetters["authentication/userId"];

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/shopasuser/${userId}/activeState`, {
          onBehalfOfCustomer: payload.customerId,
          onBehalfOfUser: payload.userId,
        })
        .then(() => {
          commit("SET_SHOP_AS_STATUS", { status: statuses.SUCCESS });
          dispatch("GET_STATE_OF_SHOP_AS");
          dispatch(
            "authentication/GET_USER_CONFIGURATION",
            {},
            {
              root: true,
            },
          );

          resolve();
        })
        .catch(error => {
          commit("SET_SHOP_AS_STATUS", {
            status: statuses.FAILURE,
          });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_STATE_OF_SHOP_AS({ commit, dispatch, rootGetters }) {
    commit("SET_SHOP_AS_STATUS", { status: statuses.SAVING });

    const userId = rootGetters["authentication/userId"];

    return axios
      .delete(`${endpoints.ECOMMERCE}/shopasuser/${userId}/activeState`)
      .then(() => {
        commit("SET_SHOP_AS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SHOP_AS", { shopAs: null });
        dispatch("authentication/GET_USER_CONFIGURATION", {}, { root: true });
      })
      .catch(error => {
        commit("SET_SHOP_AS_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  GET_STATE_OF_SHOP_AS({ commit, dispatch, rootGetters }) {
    commit("SET_SHOP_AS_STATUS", { status: statuses.LOADING });

    const shouldUseWallet = rootGetters["authentication/shouldUseWallet"];
    const userId = rootGetters["authentication/userId"];

    return axios
      .get(`${endpoints.ECOMMERCE}/shopasuser/${userId}/activeState`)
      .then(({ data }) => {
        commit("SET_SHOP_AS_STATUS", { status: statuses.SUCCESS });
        commit("SET_SHOP_AS", { shopAs: data });

        if (data.onBehalfOfCustomer) {
          dispatch("basket/GET_BASKET", {}, { root: true });
        }

        if (shouldUseWallet) {
          dispatch(
            "wallets/GET_BALANCE_OF_WALLET",
            { id: data.onBehalfOfUser || userId },
            { root: true },
          );
        }
      })
      .catch(error => {
        commit("SET_SHOP_AS_STATUS", {
          status: statuses.FAILURE,
        });
        errorAlert(error);
      });
  },
  GET_USERS_TO_SHOP_AS({ commit, rootGetters }, payload) {
    commit("SET_SHOP_AS_STATUS", { status: statuses.LOADING });
    const customerId =
      payload.customerId || rootGetters["customers/currentCustomerId"];

    return axios
      .get(
        `${endpoints.ECOMMERCE}/customers/${customerId}/shopAsUsers?onlyUsersWithUserGroup=${payload.isEndCustomer}`,
      )
      .then(({ data }) => {
        commit("SET_SHOP_AS_STATUS", { status: statuses.SUCCESS });
        commit("SET_USERS_TO_SHOP_AS", { users: data });
      })
      .catch(error => {
        commit("SET_SHOP_AS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_SELECTING_PERSON_TO_SHOP_AS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_SELECTING_PERSON_TO_SHOP_AS", {
      value: true,
    });
  },
  RESET_SHOP_AS({ commit }) {
    commit("SET_SHOP_AS", { shopAs: null });
  },
  RESET_USERS_TO_SHOP_AS({ commit }) {
    commit("SET_USERS_TO_SHOP_AS", { users: [] });
  },
};

const mutations = {
  SET_SHOP_AS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_SHOP_AS(state, payload) {
    state.shopAs = payload.shopAs;
  },
  SET_STATE_OF_MODAL_FOR_SELECTING_PERSON_TO_SHOP_AS(state, payload) {
    state.isModalForSelectingPersonToShopAsOpen = payload.value;
  },
  SET_USERS_TO_SHOP_AS(state, payload) {
    state.usersToShopAs = payload.users;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
