<script>
export default {
  name: "Heading",
  props: {
    tag: {
      default: "",
      type: String,
    },
  },
  computed: {
    level() {
      if (!this.tag) return "2";

      return this.tag.replace("H", "");
    },
  },
  render: function(createElement) {
    return createElement("h" + this.level, this.$slots.default);
  },
};
</script>
