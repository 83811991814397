import axios from "axios";
import isEqual from "lodash.isequal";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  isModalForAddingUsersOpen: false,
  lastSearchedQuery: "",
  pagination: {},
  selectedUsers: [],
  selectedUsersToAdd: [],
  status: "",
  users: [],
  usersToAdd: [],
});

const getters = {
  isModalForAddingUsersOpen: state => state.isModalForAddingUsersOpen,
  pagination: state => state.pagination,
  selectedUsers: state => state.selectedUsers,
  selectedUsersToAdd: state => state.selectedUsersToAdd,
  statusOfUsers: state => state.status,
  users: state => state.users,
  usersToAdd: state => state.usersToAdd,
};

const actions = {
  ADD_SELECTED_USERS_TO_GROUP({ commit, dispatch, state, rootGetters }) {
    commit("SET_USERS_STATUS", { status: statuses.SAVING });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/usergroups/${rootGetters["userGroups/currentUserGroupId"]}/add-multiple-adminusers`,
        {
          identifiers: state.selectedUsersToAdd.map(user => user.id),
        },
      )
      .then(() => {
        commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
        commit("DESELECT_USERS_TO_ADD");
        dispatch("GET_USERS_OF_GROUP");
        dispatch("CLOSE_MODAL_FOR_ADDING_USERS");
      })
      .catch(error => {
        commit("SET_USERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  CLOSE_MODAL_FOR_ADDING_USERS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ADDING_USERS", {
      value: false,
    });
  },
  DELETE_SELECTED_USERS_FROM_GROUP({ commit, dispatch, state, rootGetters }) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    return axios
      .post(
        `${endpoints.ECOMMERCE}/usergroups/${rootGetters["userGroups/currentUserGroupId"]}/remove-multiple-adminusers`,
        {
          identifiers: state.selectedUsers.map(user => user.id),
        },
      )
      .then(() => {
        commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
        dispatch("GET_USERS_OF_GROUP");
        commit("DESELECT_USERS");
      })
      .catch(error => {
        commit("SET_USERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USERS_FOR_ADDING_TO_GROUP({ commit, rootGetters, state }, payload) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    const { pageNumberZeroBased, pageSize, searchQuery } = payload;

    return axios
      .post(
        `${endpoints.ECOMMERCE}/usergroups/${rootGetters["userGroups/currentUserGroupId"]}/search-admin-users-to-add`,
        {
          pageNumberZeroBased: pageNumberZeroBased || 0,
          pageSize: pageSize || 10,
          searchPhrase: searchQuery,
        },
      )
      .then(({ data }) => {
        commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
        const hasPageChanged = !isEqual(
          state.pagination.currentPage,
          pageNumberZeroBased,
        );

        commit("SET_PAGINATION", { pagination: data.pagination });

        const hasSearchQueryChanged = !isEqual(
          state.lastSearchedQuery,
          searchQuery,
        );

        const shouldAppend = hasPageChanged && !hasSearchQueryChanged;

        commit("SET_LAST_SEARCHED_QUERY", { query: searchQuery || "" });

        commit("SET_USERS_TO_ADD", {
          append: shouldAppend,
          usersToAdd: data.documents,
        });
      })
      .catch(error => {
        commit("SET_USERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_USERS_OF_GROUP({ commit, rootGetters }) {
    commit("SET_USERS_STATUS", { status: statuses.LOADING });

    return axios
      .get(
        `${endpoints.ECOMMERCE}/usergroups/${rootGetters["userGroups/currentUserGroupId"]}/adminusers`,
      )
      .then(({ data }) => {
        commit("SET_USERS_STATUS", { status: statuses.SUCCESS });
        commit("SET_USERS", { users: data });
        commit("DESELECT_USERS");
      })
      .catch(error => {
        commit("SET_USERS_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_ADDING_USERS({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_ADDING_USERS", {
      value: true,
    });
  },
  TOGGLE_USER({ commit }, payload) {
    commit("SET_SELECTED_USER", payload);
  },
  TOGGLE_USERS({ commit }, payload) {
    commit("SET_SELECTED_USERS", payload);
  },
  TOGGLE_USER_TO_ADD({ commit }, payload) {
    commit("SET_SELECTED_USER_TO_ADD", payload);
  },
};

const mutations = {
  DESELECT_USERS(state) {
    state.selectedUsers = [];
  },
  DESELECT_USERS_TO_ADD(state) {
    state.selectedUsersToAdd = [];
  },
  SET_LAST_SEARCHED_QUERY(state, payload) {
    state.lastSearchedQuery = payload.query;
  },
  SET_PAGINATION(state, payload) {
    state.pagination = payload.pagination;
  },
  SET_SELECTED_USER(state, payload) {
    const { row: user, value } = payload;

    if (value) {
      if (state.selectedUsers.some(entry => entry.id === user.id)) {
        return;
      }

      state.selectedUsers.push(user);
    } else {
      state.selectedUsers = state.selectedUsers.filter(
        selectedUser => selectedUser.id !== user.id,
      );
    }
  },
  SET_SELECTED_USERS(state, payload) {
    if (!payload) {
      state.selectedUsers = [];
      return;
    }

    const { rows: users, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedUsers.map(selectedUser => selectedUser.id),
      );

      state.selectedUsers = [
        ...state.selectedUsers,
        ...users
          .filter(user => !user.disableSelection)
          .filter(user => !identifiers.has(user.id)),
      ];
    } else {
      state.selectedUsers = state.selectedUsers.filter(
        selectedUser => !users.some(entry => entry.id === selectedUser.id),
      );
    }
  },
  SET_SELECTED_USER_TO_ADD(state, payload) {
    const { user, value } = payload;

    if (value) {
      if (state.selectedUsersToAdd.some(entry => entry.id === user.id)) {
        return;
      }

      state.selectedUsersToAdd.push(user);
    } else {
      state.selectedUsersToAdd = state.selectedUsersToAdd.filter(
        selectedUserToAdd => selectedUserToAdd.id !== user.id,
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_ADDING_USERS(state, payload) {
    state.isModalForAddingUsersOpen = payload.value;
  },
  SET_USERS(state, payload) {
    state.users = payload.users;
  },
  SET_USERS_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_USERS_TO_ADD(state, payload) {
    if (payload.append) {
      state.usersToAdd = [...state.usersToAdd, ...payload.usersToAdd];
      return;
    }

    state.usersToAdd = payload.usersToAdd;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
