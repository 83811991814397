<template>
  <div
    id="app"
    :class="
      (`brand-${divisionId}`,
      {
        'has-service-bar': shouldShowServiceBar,
        'is-locked': shouldLockScroll,
      })
    "
  >
    <transition name="slide-in-from-top">
      <div
        v-if="
          brand && !isWaitingForSlidingOverlay && !shouldUseSidebarNavigation
        "
        class="header-container"
      >
        <ServiceBar v-if="shouldShowServiceBar" />
        <Header />
        <CustomerSelector
          v-if="customerSelectorAllowed"
          class="header-container__customer-selector"
        />
      </div>
    </transition>
    <transition name="slide-in-from-left">
      <NavigationSidebar
        v-if="!isWaitingForSlidingOverlay && shouldUseSidebarNavigation"
      />
    </transition>
    <Breadcrumbs />
    <main
      class="main"
      :style="
        shouldLockScroll
          ? `margin-top: -${scrollPositionToRestore}px`
          : 'margin-top: 0'
      "
    >
      <PageTransition @updateWaitingState="setWaitingState">
        <router-view v-if="isRouteLoginRelated || isRequiredDataReady" />
      </PageTransition>
    </main>
    <UserConfigurator
      :default-language="preferredBrowserLanguage"
      :visible="isUserConfiguratorOpen"
    />
    <BackToTop />
    <Alerts />
    <Product v-if="shouldShowProductDetails" />
    <QuickBuy v-if="shouldShowQuickBuy" />
    <EndCustomerQuickBuy v-if="shouldShowEndCustomerQuickBuy" />
    <Help />
  </div>
</template>

<script>
import isEqual from "lodash.isequal";
import { mapActions, mapGetters } from "vuex";
import Alerts from "@/components/Alerts";
import BackToTop from "@/components/BackToTop";
import Breadcrumbs from "@/components/Breadcrumbs";
import Header from "@/components/Header";
import Help from "@/components/Help";
import NavigationSidebar from "@/components/NavigationSidebar";
import PageTransition from "@/components/PageTransition";
import Product from "@/components/Product";
import QuickBuy from "@/components/QuickBuy";
import ServiceBar from "@/components/ServiceBar";
import UserConfigurator from "@/components/UserConfigurator";
import { loadGoogleTagManager } from "./helpers/tracking";
import EndCustomerQuickBuy from "@/components/EndCustomerQuickBuy.vue";
import CustomerSelector from "@/components/CustomerSelector.vue";
import { ProductViewMode } from "@/models/productViewMode";
import { statuses } from "@/helpers/statuses";

export default {
  name: "App",
  components: {
    CustomerSelector,
    EndCustomerQuickBuy,
    BackToTop,
    Breadcrumbs,
    Header,
    Help,
    NavigationSidebar,
    Alerts,
    PageTransition,
    Product,
    QuickBuy,
    ServiceBar,
    UserConfigurator,
  },
  data() {
    return {
      isInitialLoad: true,
      isUserConfiguratorOpen: false,
      isWaitingForSlidingOverlay: true,
      preferredBrowserLanguage: null,
      scrollPositionToRestore: 0,
      shouldUseSidebarNavigation: false,
    };
  },
  computed: {
    ...mapGetters("authentication", [
      "divisionCode",
      "doesUserHaveShoppingContextType",
      "isShopToStateAllowed",
      "isUserDealer",
      "isUserSystemUser",
      "shouldUseWallet",
      "userConfiguration",
    ]),
    ...mapGetters("basket", ["totalPrice"]),
    ...mapGetters("branding", ["brand", "contentRoot", "divisionId"]),
    ...mapGetters("content", ["fingerprint"]),
    ...mapGetters("shopAsUser", ["shopAs"]),
    ...mapGetters("siteSettings", ["dictionaryEntries", "gaMeasurementId"]),
    ...mapGetters("wallets", ["balance"]),
    ...mapGetters("products", ["endCustomerQuickBuyOpen", "statusOfProducts"]),
    isRequiredDataReady() {
      const {
        dictionaryEntries,
        doesUserHaveShoppingContextType,
        totalPrice,
        userConfiguration,
      } = this;

      if (doesUserHaveShoppingContextType) {
        return dictionaryEntries && !!userConfiguration && !!totalPrice;
      }

      return dictionaryEntries && !!userConfiguration;
    },
    isRouteLoginRelated() {
      const { $route } = this;

      return (
        $route.path === "/" ||
        $route.name === "callback" ||
        $route.name === "login"
      );
    },
    shouldLockScroll() {
      return this.shouldShowProductDetails;
    },
    shouldShowProductDetails() {
      const {
        $route: { query },
      } = this;

      if (!query || !query.mode || !query.productId || !query.variantId) {
        return false;
      }

      return query.mode.includes("details");
    },
    shouldShowQuickBuy() {
      const {
        $route: { query },
        shouldShowEndCustomerQuickBuy,
      } = this;

      if (!query || !query.mode || !query.productId || !query.variantId) {
        return false;
      }

      return query.mode.includes("quick-buy") && !shouldShowEndCustomerQuickBuy;
    },
    shouldShowEndCustomerQuickBuy() {
      const {
        $route: { query },
      } = this;

      if (!query || !query.mode || !query.productId || !query.variantId) {
        return false;
      }

      return query.mode.includes("end-customer-quick-buy");
    },
    shouldShowServiceBar() {
      return (
        (this.allowedDivisionBrands && this.allowedDivisionBrands.length > 1) ||
        (this.shouldUseWallet && this.balance) ||
        this.isShopToStateAllowed ||
        this.shopAs
      );
    },
    customerSelectorAllowed() {
      const {
        $route: { query, name },
        userConfiguration,
        isUserDealer,
        statusOfProducts,
      } = this;
      return (
        (name === "shop" || name === "products") &&
        userConfiguration?.userListViewModeConfiguration ===
          ProductViewMode.ListViewMode.key &&
        isUserDealer &&
        !query.mode &&
        statusOfProducts === statuses.SUCCESS
      );
    },
  },
  watch: {
    $route: {
      handler: function(newValue, oldValue) {
        if (!isEqual(newValue, oldValue)) {
          this.redirectToMatchingDivision();
        }
      },
      deep: true,
    },
    "$route.path": function(newValue) {
      this.shouldUseSidebarNavigation = newValue
        .toLowerCase()
        .startsWith("/administration/");
    },
    contentRoot(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        if (this.userConfiguration) {
          this.GET_NAVIGATION();
        }
      }
    },
    divisionCode(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.redirectToMatchingDivision();
      }
    },
    divisionId(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.redirectToMatchingDivision();
      }
    },
    fingerprint(newValue, oldValue) {
      if (newValue && oldValue && newValue !== oldValue) {
        this.GET_NAVIGATION();
      }
    },
    gaMeasurementId(newValue, oldValue) {
      if (newValue && newValue !== oldValue) {
        loadGoogleTagManager();
      }
    },
    shouldLockScroll(newValue) {
      if (newValue) {
        this.scrollPositionToRestore = window.scrollY;
      } else {
        this.$nextTick(() => {
          window.scrollTo(0, this.scrollPositionToRestore);
        });
      }
    },
    userConfiguration(newValue, oldValue) {
      const {
        $router,
        GET_ALLOWED_DIVISIONS_FOR_USER,
        GET_BASKET,
        GET_CHILDREN_TO_SHOP_FOR,
        GET_NAVIGATION,
        RESET_CHILDREN_OF_CUSTOMER,
        UPDATE_CUSTOMER_FOR_ADDING_TO_BASKET,
        contentRoot,
        doesUserHaveShoppingContextType,
        getStartPageForCurrentUser,
        isInitialLoad,
        isUserDealer,
        isUserSystemUser,
      } = this;

      if (newValue && !isEqual(newValue, oldValue)) {
        RESET_CHILDREN_OF_CUSTOMER();

        if (isUserDealer) GET_CHILDREN_TO_SHOP_FOR();

        UPDATE_CUSTOMER_FOR_ADDING_TO_BASKET({
          customerId: newValue.contextInternalCustomerId,
        });

        if (
          (!newValue.contextSizeChart && !isUserSystemUser) ||
          !newValue.contextSiteLanguage
        ) {
          this.preferredBrowserLanguage = navigator.language
            .split("-")[0]
            .toLowerCase();

          this.isUserConfiguratorOpen = true;

          return;
        } else {
          this.isUserConfiguratorOpen = false;
        }

        if (isInitialLoad) {
          $router.push({
            name: getStartPageForCurrentUser(),
          });

          this.isInitialLoad = false;
        }

        if (contentRoot) {
          GET_NAVIGATION();
        }

        if (doesUserHaveShoppingContextType) {
          GET_BASKET();
        }

        GET_ALLOWED_DIVISIONS_FOR_USER({ id: newValue.userId });

        const cookieInformation = document.getElementById("CookieConsent");

        if (!cookieInformation) {
          const cookieScript = document.createElement("script");
          cookieScript.setAttribute("type", "text/javascript");
          cookieScript.setAttribute("id", "CookieConsent");
          cookieScript.setAttribute(
            "src",
            "https://policy.app.cookieinformation.com/uc.js",
          );
          cookieScript.setAttribute(
            "data-culture",
            this.preferredBrowserLanguage || newValue.contextSiteLanguage,
          );

          document.body.appendChild(cookieScript);
        } else {
          cookieInformation.setAttribute(
            "data-culture",
            this.preferredBrowserLanguage || newValue.contextSiteLanguage,
          );
        }
      }
    },
    shouldShowEndCustomerQuickBuy(newValue) {
      if (newValue && !this.endCustomerQuickBuyOpen) {
        this.TOGGLE_END_CUSTOMER_QUICK_BUY();
      }
    },
    shouldShowServiceBar(newValue) {
      this.UPDATE_HAS_SERVICE_BAR({ value: newValue });
    },
    customerSelectorAllowed(newValue) {
      this.UPDATE_CUSTOMER_SELECTOR_ALLOWED({ value: newValue });
    },
  },
  created() {
    this.CHECK_AUTHENTICATION();

    if (this.gaMeasurementId) {
      this.loadGoogleTagManager();
    }

    this.shouldUseSidebarNavigation = this.$route.path
      .toLowerCase()
      .startsWith("/administration/");

    document.documentElement.style.setProperty(
      "--scrollbar-width",
      window.innerWidth - document.documentElement.clientWidth + "px",
    );
  },
  methods: {
    ...mapActions("authentication", ["CHECK_AUTHENTICATION"]),
    ...mapActions("basket", [
      "GET_BASKET",
      "UPDATE_CUSTOMER_FOR_ADDING_TO_BASKET",
    ]),
    ...mapActions("catalogues", ["GET_CURRENT_CATALOGUE"]),
    ...mapActions("content", ["GET_NAVIGATION"]),
    ...mapActions("customers", [
      "GET_CHILDREN_TO_SHOP_FOR",
      "RESET_CHILDREN_OF_CUSTOMER",
    ]),
    ...mapActions("users", [
      "GET_ALLOWED_DIVISIONS_FOR_USER",
      "UPDATE_HAS_SERVICE_BAR",
      "UPDATE_CUSTOMER_SELECTOR_ALLOWED",
    ]),
    ...mapActions("products", ["TOGGLE_END_CUSTOMER_QUICK_BUY"]),
    setWaitingState(value) {
      this.isWaitingForSlidingOverlay = value;
    },
  },
};
</script>

<style lang="scss">
@import "../node_modules/@egjs/vue-flicking/dist/flicking.css";
@import "@/styles/block.scss";
@import "@/styles/fonts.scss";
@import "@/styles/slider.scss";
@import "@/styles/variables.scss";
@import "@/styles/tooltip.scss";

$filter-breakpoint: 48rem;

*,
*::before,
*::after {
  box-sizing: inherit;

  // @media (prefers-reduced-motion: reduce) {
  //   animation-delay: -1ms !important;
  //   animation-duration: 1ms !important;
  //   animation-iteration-count: 1 !important;
  //   background-attachment: initial !important;
  //   scroll-behavior: auto !important;
  //   transition-duration: 0s !important;
  //   transition-delay: 0s !important;
  // }
}

.modal-open,
.sidebar-open,
.locked {
  overflow: hidden;
}

#app {
  padding: 0 0 var(--spacing-main-content);

  @media (min-width: 64rem) {
    padding-top: calc(
      var(--size-header-fixed) + 1.75rem
    ); /* Account for some spacing for Breadcrumbs */
  }

  .catalogue-view &,
  .dashboard-view &,
  .noAccess-view &,
  .orderReceipt-view &,
  .product-view &,
  .products-view &,
  .quickBuy-view &,
  .shop-view & {
    @media print {
      padding: 0 !important;
    }
  }

  .navigation-sidebar & {
    @media (min-width: 64rem) {
      padding: 0 0 calc(var(--spacing-container-with-navigation-sidebar) * 3)
        var(--width-navigation-sidebar) !important;
    }
  }

  &.has-service-bar {
    @media (min-width: 64rem) {
      padding-top: calc(var(--size-header-fixed) + var(--size-service-bar));
    }
  }

  &.is-locked {
    overflow: hidden;
  }
}

a {
  color: #0277bd;
  text-underline-offset: 0.1875rem;
}

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  -webkit-print-color-adjust: exact !important;
  background-color: var(--color-body-background);
  color: var(--color-text-primary);
  font-family: var(--font-family-roboto);
  font-size: 1rem;
  margin: 0;
  min-height: 100%;
  overflow-x: hidden;
  transition: background-color 300ms ease;

  &.catalogue-view,
  &.dashboard-view,
  &.noAccess-view,
  &.orderReceipt-view,
  &.product-view,
  &.products-view,
  &.quickBuy-view,
  &.shop-view {
    background-color: var(--color-body-background-light);
    background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0) 80%,
      rgba(150, 150, 150, 0.1) 100%
    );
    background-repeat: no-repeat;

    @media print {
      background: none;
    }
  }
}

button,
input,
label,
select {
  font-family: inherit;
}

.fade {
  &-enter-active,
  &-leave-active {
    transition: opacity var(--transition-fade) ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.fade-fast {
  &-enter-active,
  &-leave-active {
    transition: opacity var(--transition-fade-fast) ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
  }
}

.fade-scale {
  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease, transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: scale(0.5);
  }
}

.fade-scale-subtle {
  &-enter-active,
  &-leave-active {
    transition: opacity var(--transition-fade-scale-subtle) ease,
      transform var(--transition-fade-scale-subtle) ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: scale(0.9);
  }
}

.fade-slide {
  &-enter-active,
  &-leave-active {
    transition: opacity var(--transition-fade-slide) ease,
      transform var(--transition-fade-slide) ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }
}

.fade-slide-from-bottom {
  &-enter-active,
  &-leave-active {
    transition: opacity var(--transition-fade-slide) ease,
      transform var(--transition-fade-slide) ease;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(100%);
  }
}

h1 {
  font-size: 1.6rem;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

h2 {
  font-size: 1.5rem;
}

h3 {
  font-size: 1.35rem;
}

h4 {
  font-size: 1.2rem;
}

h5 {
  font-size: 1.125rem;
}

h6 {
  font-size: 1rem;
}

.header-container {
  position: relative;
  right: 0;
  top: 0;
  z-index: 95;

  @media (min-width: 64rem) {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 99;
  }

  &__customer-selector {
    @media (max-width: 64rem) {
      display: none !important;
    }
  }
}

html {
  box-sizing: border-box;
  height: 100%;
  scroll-behavior: smooth;
}

img {
  display: block;
  height: auto;
  max-width: 100%;

  main & {
    content-visibility: auto;
  }
}

p {
  &:last-child {
    margin: 0;
  }
}

.ql-editor,
.rich-text {
  line-height: 1.45 !important;

  ol,
  ul {
    margin: 0 !important;
    padding: 0 0 0 2rem !important;
  }

  p {
    margin: 0 0 1rem !important;

    &:last-child {
      margin: 0 !important;
    }
  }
}

.slide-in-from-left {
  &-enter-active,
  &-leave-active {
    transition: transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    transform: translateX(-100%);
  }
}

.slide-in-from-right {
  &-enter-active,
  &-leave-active {
    transition: transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    transform: translateX(100%);
  }
}

.slide-in-from-top {
  &-enter-active,
  &-leave-active {
    transition: transform 300ms ease;
  }

  &-enter,
  &-leave-to {
    transform: translateY(-100%);
  }
}

.video-container {
  background-color: #f8f8f8;
  overflow: hidden;
  position: relative;
  width: 100%;

  .block__media-container & {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }

  &::before {
    content: "";
    display: block;
    padding-bottom: 9 / 16 * 100%;
  }

  iframe {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
  }
}

.warning-label {
  background-color: var(--color-warning-label-background);
  border-radius: 0.25rem;
  color: var(--color-warning-label-text);
  display: inline-block;
  font-family: var(--font-family-inter);
  font-size: 0.75rem;
  font-weight: 600;
  margin-bottom: calc(var(--spacing-stack) * 0.25);
  padding: calc(var(--spacing-container) * 0.5)
    calc(var(--spacing-container) * 0.75);
  text-transform: uppercase;
}
</style>
