import Vue from "vue";
import VueRouter from "vue-router";
import axios from "axios";
import Callback from "@/views/Callback";
import Login from "@/views/Login";
import administration from "./administration";
import store from "@/store";
// import { pushToDataLayer } from "@/helpers/tracking";

const { isNavigationFailure, NavigationFailureType } = VueRouter;

const push = VueRouter.prototype.push;
VueRouter.prototype.push = function modifiedPush(location) {
  return push.call(this, location).catch(failure => {
    if (isNavigationFailure(failure, NavigationFailureType.duplicated)) {
      //Due to the authentication flow redundant navigation sometimes occurs
      //but it pollutes the console so we intentionally suppress it
      return;
    }

    throw failure;
  });
};

Vue.use(VueRouter);

const routes = [
  {
    path: "/callback",
    name: "callback",
    component: Callback,
    meta: {
      isAuthenticationRequired: false,
    },
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    meta: {
      isAuthenticationRequired: false,
    },
  },
  ...administration,
  {
    path: "/catalogue",
    name: "catalogue",
    component: () =>
      import(/* webpackChunkName: "catalogue" */ "../views/Catalogue.vue"),
    meta: {
      breadcrumbs: [],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/checkout",
    name: "checkout",
    component: () =>
      import(/* webpackChunkName: "checkout" */ "../views/Checkout.vue"),
    meta: {
      breadcrumbs: [],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: () =>
      import(/* webpackChunkName: "dashboard" */ "../views/Dashboard.vue"),
    meta: {
      breadcrumbs: [],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/no-access",
    name: "noAccess",
    component: () =>
      import(/* webpackChunkName: "noAccess" */ "../views/NoAccess.vue"),
    meta: {
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/products",
    name: "products",
    component: () =>
      import(/* webpackChunkName: "products" */ "../views/Products.vue"),
    meta: {
      breadcrumbs: [],
      isAuthenticationRequired: true,
    },
  },
  {
    path: "/profile",
    name: "profile",
    component: () =>
      import(/* webpackChunkName: "profile" */ "../views/Profile.vue"),
    meta: {
      isAuthenticationRequired: true,
    },
    redirect: {
      name: "profile.personalInformation",
    },
    children: [
      {
        path: "addresses",
        name: "profile.addresses",
        component: () =>
          import(
            /* webpackChunkName: "profileAddresses" */ "../views/ProfileAddresses.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "profile" }, title: "_Navigation.MyProfile" },
            { title: "_Navigation.Addresses" },
          ],
          isAuthenticationRequired: true,
        },
        children: [
          {
            path: ":addressId",
            name: "profile.address",
            component: () =>
              import(
                /* webpackChunkName: "profileAddress" */ "../views/ProfileAddress.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "profile" }, title: "_Navigation.MyProfile" },
                {
                  path: { name: "profile.addresses" },
                  title: "_Navigation.Addresses",
                },
                { title: "_Administration.Address" },
              ],
              isAuthenticationRequired: true,
            },
          },
        ],
      },
      {
        path: "orders/:orderId",
        name: "profile.order",
        component: () =>
          import(
            /* webpackChunkName: "profileOrder" */ "../views/ProfileOrder.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "profile" }, title: "_Navigation.MyProfile" },
            {
              path: { name: "profile.orders" },
              title: "_Navigation.Orders",
            },
            { title: "orders/currentOrderNumber" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "orders",
        name: "profile.orders",
        component: () =>
          import(
            /* webpackChunkName: "profileOrders" */ "../views/ProfileOrders.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "profile" }, title: "_Navigation.MyProfile" },
            { title: "_Navigation.Orders" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "personal-information",
        name: "profile.personalInformation",
        component: () =>
          import(
            /* webpackChunkName: "profile" */ "../views/ProfilePersonalInformation.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "profile" }, title: "_Navigation.MyProfile" },
            { title: "_Administration.PersonalInformation" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "settings",
        name: "profile.settings",
        component: () =>
          import(
            /* webpackChunkName: "profileSettings" */ "../views/ProfileSettings.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "profile" }, title: "_Navigation.MyProfile" },
            { title: "_Administration.Settings" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "sizes-and-measurements",
        name: "profile.sizesAndMeasurements",
        component: () =>
          import(
            /* webpackChunkName: "profileSizesAndMeasurements" */ "../views/ProfileSizesAndMeasurements.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "profile" }, title: "_Navigation.MyProfile" },
            { title: "_Administration.SizesAndMeasurements" },
          ],
          isAuthenticationRequired: true,
        },
      },
      {
        path: "transactions",
        name: "profile.transactions",
        component: () =>
          import(
            /* webpackChunkName: "profileTransactions" */ "../views/ProfileTransactions.vue"
          ),
        meta: {
          breadcrumbs: [
            { path: { name: "profile" }, title: "_Navigation.MyProfile" },
            { title: "_Wallet.Transactions" },
          ],
          isAuthenticationRequired: true,
        },
        children: [
          {
            path: ":transactionId",
            name: "profile.transaction",
            component: () =>
              import(
                /* webpackChunkName: "profileTransaction" */ "../views/ProfileTransaction.vue"
              ),
            meta: {
              breadcrumbs: [
                { path: { name: "profile" }, title: "_Navigation.MyProfile" },
                {
                  path: { name: "profile.transactions" },
                  title: "_Wallet.Transactions",
                },
                { title: "_Wallet.Transaction" },
              ],
              isAuthenticationRequired: true,
            },
          },
        ],
      },
    ],
  },
  {
    path: "/order-receipt",
    name: "orderReceipt",
    component: () =>
      import(
        /* webpackChunkName: "orderReceipt" */ "../views/OrderReceipt.vue"
      ),
    meta: {
      isAuthenticationRequired: true,
    },
  },
  {
    path:
      "/shop/:level1?/:level2?/:level3?/:level4?/:level5?/:level6?/:level7?/:level8?/:level9?/:level10?",
    name: "shop",
    component: () => import(/* webpackChunkName: "shop" */ "../views/Shop.vue"),
    meta: {
      isAuthenticationRequired: true,
    },
  },
  {
    path: "*",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
    meta: {
      isAuthenticationRequired: false,
    },
  },
];

const router = new VueRouter({
  routes,
});

router.beforeEach((to, from, next) => {
  axios.defaults.headers.common["ui-current-route-path"] = to.fullPath;
  axios.defaults.headers.common["ui-current-route-name"] = to.name;

  const classList = document.body.classList;
  while (classList.length > 0) {
    classList.remove(classList.item(0));
  }

  document.body.classList.add(`${to.name}-view`);

  if (to.path.toLocaleLowerCase().startsWith("/administration/")) {
    document.body.classList.add("navigation-sidebar");
  }

  if (!store.getters["authentication/isUserAuthenticated"]) {
    if (to.matched.some(record => record.meta.isAuthenticationRequired)) {
      localStorage.setItem("redirect_after_login", to.fullPath);
      next({ name: "login" });
      return;
    } else {
      next();
      return;
    }
  }

  const redirectAfterLogin = localStorage.getItem("redirect_after_login");

  if (redirectAfterLogin) {
    localStorage.removeItem("redirect_after_login");

    if (to.fullPath !== redirectAfterLogin) {
      next({ path: redirectAfterLogin });
      return;
    }
  }

  next();
});

// router.afterEach(to => {
//   if (to.name === "callback") return;

//   const pageview = {
//     event: "pageview",
//     page: {
//       path: window.location.href,
//       title: document.title,
//       pathFragment: window.location.hash,
//     },
//   };

//   if (window.google_tag_manager) {
//     pushToDataLayer(pageview);
//   } else {
//     const waitForGoogleTagManager = window.setInterval(() => {
//       if (window.google_tag_manager) {
//         window.clearInterval(waitForGoogleTagManager);
//         pushToDataLayer(pageview);
//       }
//     }, 100);
//   }
// });

export default router;
