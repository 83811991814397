var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.childrenToShopFor && _vm.childrenToShopFor.length)?_c('div',{ref:"customerSelectorParent",staticClass:"selector",class:{
    'selector--relative': !_vm.fromQuickBuy,
    'selector--quick-buy': _vm.fromQuickBuy,
  }},[_c('div',{staticClass:"selector__toggle"},[_c('button',{staticClass:"selector__toggle-action",on:{"click":_vm.toggleSelector}},[_c('span',{staticClass:"selector__toggle-label selector__toggle-label-first"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.Labels.CompanyBeingShoppedTo"))+": ")]),_c('span',{staticClass:"selector__toggle-label"},[_vm._v(" "+_vm._s(_vm.selectedCustomer ? _vm.selectedCustomer.customerName : _vm.customerName)+" ")]),_c('svg',{staticClass:"selector__toggle-icon",class:{ 'selector__toggle-icon--rotated': _vm.isSelectorOpen },attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"d":"m6 9 6 6 6-6"}})])])]),_c('div',{ref:"selector",staticClass:"selector__box",class:{
      'selector__box--quick-buy': _vm.fromQuickBuy,
    }},[_c('div',{staticClass:"selector__box-content"},[_c('Stack',[_c('h2',{staticClass:"selector__title"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Common.Placeholders.SelectCompany"))+" ")])]),_c('Stack',{staticClass:"selector__list-container"},[_c('ul',{ref:"box-content",staticClass:"selector__list"},[_c('li',{staticClass:"selector__item"},[_c('button',{staticClass:"selector__item-action",on:{"click":function($event){return _vm.selectCustomer(_vm.customerId)}}},[_c('span',{staticClass:"selector__item-action-label",class:{
                  'selector__item-action-label-selected':
                    _vm.hasSelected(_vm.customerId) || !_vm.selectedCustomer,
                }},[_vm._v(" "+_vm._s(_vm.customerName)+" "),(_vm.hasSelected(_vm.customerId) || !_vm.selectedCustomer)?_c('svg',{staticClass:"selector__item-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20 20","fill":"none"}},[_c('g',{attrs:{"clip-path":"url(#clip0_112_452)"}},[_c('path',{attrs:{"d":"M3.125 11.25L7.5 15.625L17.5 5.625","stroke":"#5AB858","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_112_452"}},[_c('rect',{attrs:{"width":"20","height":"20","fill":"white"}})])])]):_vm._e()])])]),_vm._l((_vm.childrenToShopFor),function(child){return _c('li',{key:child.customerId,staticClass:"selector__item"},[_c('button',{staticClass:"selector__item-action",on:{"click":function($event){return _vm.selectCustomer(child.customerId)}}},[_c('span',{staticClass:"selector__item-action-label",class:{
                  'selector__item-action-label-selected': _vm.hasSelected(
                    child.customerId,
                  ),
                }},[_vm._v(" "+_vm._s(child.customerName)+" "),(_vm.hasSelected(child.customerId))?_c('svg',{staticClass:"selector__item-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"20","height":"20","viewBox":"0 0 20 20","fill":"none"}},[_c('g',{attrs:{"clip-path":"url(#clip0_112_452)"}},[_c('path',{attrs:{"d":"M3.125 11.25L7.5 15.625L17.5 5.625","stroke":"#5AB858","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_112_452"}},[_c('rect',{attrs:{"width":"20","height":"20","fill":"white"}})])])]):_vm._e()]),(child.discountPercent)?_c('span',{staticClass:"selector__item-discount"},[_vm._v(" "+_vm._s(child.discountPercent)+"% ")]):_vm._e()])])})],2)])],1)]),(_vm.isSelectorOpen && !_vm.fromQuickBuy)?_c('div',{staticClass:"selector__underlay",on:{"click":_vm.closeSelector}}):_vm._e()]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }