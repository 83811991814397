<template>
  <div class="counter" :class="{ 'counter--loading': isLoading }">
    <Button
      class="counter__button counter__button--decrement"
      :aria-label="getDictionaryEntry('Common.Labels.DecrementQuantity')"
      :dark="true"
      :disabled="disabled"
      :tabindex="allowEmpty ? -1 : 0"
      @click="decrement"
    >
      <svg
        class="counter__icon"
        height="21"
        viewBox="0 0 21 21"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="m5.029 10.429h10" />
      </svg>
    </Button>
    <input
      :value="internalQuantity"
      :aria-label="getDictionaryEntry('Common.Labels.Quantity')"
      :disabled="disabled"
      :placeholder="!internalQuantity ? '0' : ''"
      autocomplete="off"
      class="counter__input"
      min="0"
      step="1"
      type="number"
      v-bind="$attrs"
      @blur="handleBlur"
      @input="handleInput"
      @keyup.enter="handleEnter"
    />
    <Button
      class="counter__button counter__button--increment"
      :aria-label="getDictionaryEntry('Common.Labels.IncrementQuantity')"
      :dark="true"
      :disabled="disabled"
      :tabindex="allowEmpty ? -1 : 0"
      @click="increment"
    >
      <svg
        class="counter__icon"
        height="21"
        viewBox="0 0 21 21"
        width="21"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g fill-rule="evenodd">
          <path d="m5.029 10.429h10" />
          <path d="m10.029 15.429v-10.001" />
        </g>
      </svg>
    </Button>
    <Loader :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Button from "@/components/Button";
import Loader from "@/components/Loader";

export default {
  name: "Counter",
  components: {
    Button,
    Loader,
  },
  inheritAttrs: false,
  props: {
    allowEmpty: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    initialValue: {
      default: 0,
      type: [Number, String],
    },
    inputOnly: {
      default: false,
      type: Boolean,
    },
    sku: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      internalQuantity: null,
      isLoading: false,
    };
  },
  computed: {
    ...mapGetters("basket", ["basketLineQuantity", "statusOfBasket"]),
    quantity() {
      return this.basketLineQuantity(this.sku);
    },
  },
  watch: {
    internalQuantity(newValue, oldValue) {
      if (newValue < 0) {
        this.updateQuantity(0);
        return;
      } else if (
        (!newValue && !oldValue) ||
        newValue === oldValue ||
        (this.sku && newValue === this.quantity)
      ) {
        return;
      } else if (newValue === "" && oldValue) {
        this.updateQuantity(0);
        return;
      }

      this.updateQuantity(newValue);
    },
    quantity(newValue) {
      if (newValue === 0) {
        if (this.internalQuantity) return;

        this.internalQuantity = null;
      }

      this.internalQuantity = newValue;
    },
  },
  created() {
    const { initialValue, quantity } = this;

    if (initialValue) {
      this.internalQuantity = initialValue;
    } else if (quantity) {
      this.internalQuantity = quantity;
    } else {
      this.internalQuantity = null;
    }
  },
  methods: {
    ...mapActions("basket", ["ADD_BASKET_LINE", "UPDATE_BASKET_LINE_QUANTITY"]),
    decrement() {
      const value = parseInt(this.internalQuantity) || 0;

      if (!value) return;

      this.internalQuantity = value - 1;
    },
    handleBlur(event) {
      const { value } = event.target;

      if (value === "" || value === null) {
        this.internalQuantity = value;
      } else if (parseInt(value) < 0) {
        this.internalQuantity = 0;
      } else {
        this.internalQuantity = parseInt(value);
      }

      this.$forceUpdate();
    },
    handleInput(event) {
      if (this.sku) return;

      const { value } = event.target;

      if (value === "" || value === null) {
        this.internalQuantity = value;
      } else if (parseInt(value) < 0) {
        this.internalQuantity = 0;
      } else {
        this.internalQuantity = parseInt(value);
      }

      this.$forceUpdate();
    },
    handleEnter(event) {
      const { value } = event.target;

      if (value === "" || value === null) {
        this.internalQuantity = value;
      } else if (parseInt(value) < 0) {
        this.internalQuantity = 0;
      } else {
        this.internalQuantity = parseInt(value);
      }

      this.$forceUpdate();
    },
    increment() {
      const value = parseInt(this.internalQuantity) || 0;

      this.internalQuantity = value + 1;
    },
    updateQuantity(quantity) {
      if (this.sku) {
        this.isLoading = true;

        this.UPDATE_BASKET_LINE_QUANTITY({
          previousQuantity: this.quantity,
          quantity,
          sku: this.sku,
        })
          .then(() => {
            this.isLoading = false;
          })
          .catch(() => {
            this.isLoading = false;
          });
      } else {
        this.$emit("quantity-changed", quantity);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.counter {
  display: flex;
  margin-left: auto;
  position: relative;

  &--loading {
    pointer-events: none;
  }

  &__button {
    align-items: center;
    border-radius: 50%;
    box-shadow: none !important;
    display: flex;
    height: var(--size-counter-button);
    justify-content: center;
    padding: 0;
    position: relative;
    width: var(--size-counter-button);
    z-index: 2;

    .employee & {
      height: calc(var(--size-counter-button) * 0.875);
      width: calc(var(--size-counter-button) * 0.875);
    }
  }

  &__icon {
    fill: none;
    height: var(--size-counter-icon);
    stroke-linecap: round;
    stroke-linejoin: round;
    stroke-width: 2;
    stroke: var(--color-button-text);
    width: var(--size-counter-icon);

    .size-list-bulk & {
      stroke: var(--color-text-primary);
    }
  }

  &__input {
    appearance: none;
    background-color: transparent;
    border: none;
    border-radius: 0;
    font-size: 1rem;
    margin: 0;
    outline: none;
    position: relative;
    text-align: center;
    width: var(--size-counter-button);
    z-index: 2;

    &:disabled {
      background-color: var(--color-input-background-disabled) !important;
      border: none !important;
      cursor: not-allowed;

      .size-matrix & {
        background-color: var(--color-input-border) !important;
      }

      &::placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &:-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }

      &::-ms-input-placeholder {
        color: rgba(0, 0, 0, 0.5);
      }
    }

    &:focus {
      border-color: var(--color-button-background);
      outline: none;
      z-index: 2;

      &::placeholder {
        color: transparent;
      }

      &:-ms-input-placeholder {
        color: transparent;
      }

      &::-ms-input-placeholder {
        color: transparent;
      }
    }

    &[type="number"] {
      -moz-appearance: textfield;
    }

    &[type="number"]::-webkit-inner-spin-button,
    &[type="number"]::-webkit-outer-spin-button {
      -webkit-appearance: none;
    }

    .employee & {
      font-size: 0.9375rem;
      font-weight: 600;
    }

    .size-matrix & {
      background-color: transparent;
      border-color: transparent;
    }
  }
}
</style>
