var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.blockData)?_c('section',{staticClass:"slider slider--tall",class:{
    'slider--expand-first-slide': _vm.blockData.expandFirstItem,
  }},[_c('Flicking',{attrs:{"options":_vm.options,"plugins":_vm.plugins}},[_vm._l((_vm.blockData.linkElements),function(slide){return _c('div',{key:slide._id,staticClass:"slide"},[(slide.image)?_c('ImageFromUmbraco',{staticClass:"slide__image",attrs:{"id":slide.image._system.id,"alt":slide.image.alternateText,"height":slide.image.umbracoHeight,"keys-and-sizes":{
          s: 'block-regular-small',
          m: 'block-regular-medium',
          l: 'block-regular-large',
          xl: 'block-regular-extra-large',
        },"width":slide.image.umbracoWidth}}):_vm._e(),_c('article',{staticClass:"slide__content"},[(slide.caption)?_c('div',{staticClass:"slide__subtitle"},[_vm._v(" "+_vm._s(slide.caption)+" ")]):_vm._e(),(slide.headline)?_c('Heading',{staticClass:"slide__title",attrs:{"tag":slide.headlineTag}},[_vm._v(" "+_vm._s(slide.headline)+" ")]):_vm._e(),(slide.cTA && slide.cTA.name && slide.cTA.url)?_c('ConditionalRouterLink',{staticClass:"block__button",attrs:{"target":slide.cTA.target,"to":slide.cTA.url,"type":slide.cTA.type}},[_vm._v(_vm._s(slide.cTA.name))]):_vm._e()],1)],1)}),_c('button',{staticClass:"slider__navigation slider__navigation--prev",attrs:{"slot":"viewport","aria-label":"previous"},slot:"viewport"},[_c('svg',{staticClass:"slider__navigation-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M19 12H5M12 19l-7-7 7-7"}})])]),_c('button',{staticClass:"slider__navigation slider__navigation--next",attrs:{"slot":"viewport","aria-label":"next"},slot:"viewport"},[_c('svg',{staticClass:"slider__navigation-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M5 12h14M12 5l7 7-7 7"}})])])],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }