<template>
  <div
    class="container"
    :class="{
      'container--vertical': vertical,
      'container--vertical-on-mobile': verticalOnMobile,
    }"
  >
    <router-link :to="detailsLink" @click="trackProductClick">
      <ActionIcon :tooltip-content="getDictionaryEntry('Product.Tooltip.Info')">
        <svg
          class="icon icon--small"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12.8265 8.73713V19H11.1279V8.73713H12.8265ZM11 6.01491C11 5.73035 11.0822 5.49006 11.2466 5.29404C11.417 5.09801 11.6667 5 11.9954 5C12.3181 5 12.5647 5.09801 12.7352 5.29404C12.9117 5.49006 13 5.73035 13 6.01491C13 6.28681 12.9117 6.52078 12.7352 6.7168C12.5647 6.9065 12.3181 7.00136 11.9954 7.00136C11.6667 7.00136 11.417 6.9065 11.2466 6.7168C11.0822 6.52078 11 6.28681 11 6.01491Z"
            fill="black"
          />
        </svg>
      </ActionIcon>
    </router-link>
    <ActionIcon
      v-if="hasAccessToProductSheet && !onBehalfUserName"
      :disabled="isVariantInCurrentCatalogue(productVariant.id)"
      :tooltip-content="getDictionaryEntry('Product.Tooltip.Catalogue')"
      @click="addToCatalogue(productVariant.id)"
    >
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 24 24">
        <path
          d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2zM22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z"
        />
      </svg>
    </ActionIcon>
    <router-link v-if="canQuickBuy" :to="quickBuyLink">
      <ActionIcon
        v-if="isUserDealer || isUserEndCustomer"
        :tooltip-content="getDictionaryEntry('Product.Tooltip.QuickBuy')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon"
          viewBox="0 0 24 24"
        >
          <defs />
          <circle cx="9" cy="21" r="1" />
          <circle cx="20" cy="21" r="1" />
          <path
            d="M1 1h4l2.68 13.39a2 2 0 002 1.61h9.72a2 2 0 002-1.61L23 6H6"
          />
        </svg>
      </ActionIcon>
    </router-link>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { pushToDataLayer } from "@/helpers/tracking";
import { statuses } from "@/helpers/statuses";
import ActionIcon from "@/components/ActionIcon.vue";

export default {
  name: "ProductVariantBlockActions",
  components: {
    ActionIcon,
  },
  props: {
    productVariant: {
      required: true,
      type: Object,
    },
    index: {
      default: 0,
      type: Number,
    },
    canQuickBuy: {
      default: true,
      type: Boolean,
    },
    vertical: {
      default: false,
      type: Boolean,
    },
    verticalOnMobile: {
      default: false,
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("authentication", [
      "isUserDealer",
      "onBehalfUserName",
      "isUserEndCustomer",
    ]),
    ...mapGetters("catalogues", [
      "isVariantInCurrentCatalogue",
      "statusOfCatalogues",
    ]),
    ...mapGetters("content", ["isProductList"]),
    detailsLink() {
      const { $route, productVariant } = this;

      return {
        query: {
          ...$route.query,
          mode: "details",
          productId: productVariant.productId,
          variantId: productVariant.id,
        },
      };
    },
    image() {
      const {
        productVariant: { images },
      } = this;

      if (!images || !images.length) return null;

      return images[0].thumbnail;
    },
    isSaving() {
      return this.statusOfCatalogues === statuses.SAVING;
    },
    quickBuyLink() {
      const { $route, productVariant } = this;
      if (this.isUserDealer) {
        return {
          query: {
            ...$route.query,
            mode: "quick-buy",
            productId: productVariant.productId,
            variantId: productVariant.id,
          },
        };
      } else {
        return {
          query: {
            ...$route.query,
            mode: "end-customer-quick-buy",
            productId: productVariant.productId,
            variantId: productVariant.id,
          },
        };
      }
    },
  },
  methods: {
    ...mapActions("catalogues", ["ADD_VARIANT_TO_CURRENT_CATALOGUE"]),
    trackProductClick() {
      const { index, productVariant } = this;

      const productClick = {
        event: "productClick",
        ecommerce: {
          click: {
            actionField: { list: "Product list" },
            products: [
              {
                name: productVariant.productName,
                id: productVariant.id,
                price: productVariant.minPrice
                  ? productVariant.minPrice.price
                  : "",
                brand: productVariant.brand,
                variant: productVariant.variantName,
                position: index + 1,
              },
            ],
          },
        },
      };

      pushToDataLayer(productClick);
    },
    addToCatalogue(id) {
      if (this.isVariantInCurrentCatalogue(id)) return;

      this.ADD_VARIANT_TO_CURRENT_CATALOGUE({ variantId: id });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  justify-content: flex-end;
  gap: 0.5rem;

  &--vertical {
    flex-direction: column;
  }

  &--vertical-on-mobile {
    @media (max-width: 48rem) {
      flex-direction: column;
    }
  }

  a {
    height: 100‰;
    width: 100‰;
  }
}

.icon {
  fill: none;
  height: var(--size-header-action-icon);
  width: var(--size-header-action-icon);
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2;

  * {
    stroke: var(--color-header-text);
  }

  &--small {
    * {
      stroke-width: 1;
    }
  }
}
</style>
