<template>
  <div
    class="dropdown"
    :class="{
      'dropdown--borderless': !hasBorder,
      'dropdown--error': hasError,
      'dropdown--labeled': labelText,
    }"
  >
    <label v-if="labelText" class="dropdown__label" :for="id" v-bind="$attrs">{{
      labelText
    }}</label>
    <Multiselect
      :id="id"
      ref="multiselect"
      v-model="internalValue"
      :max-height="280"
      :options="optionsWithAbilityToClear"
      :show-labels="false"
      track-by="value"
      v-bind="{ ...$attrs, ...$props }"
      @close="$emit('close', $event)"
      @open="handleOpen($event)"
      @select="$emit('select', $event)"
    >
      <template slot="caret"
        ><svg class="multiselect__icon" viewBox="0 0 20 20">
          <path
            fill-rule="evenodd"
            d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
            clip-rule="evenodd"
          /></svg
      ></template>
      <template
        v-if="multiple"
        slot="selection"
        slot-scope="{ isOpen, values }"
      >
        <span v-if="values.length">
          <span v-if="!isOpen" class="multiselect__single"
            >{{ values.length }}
            {{
              values.length === 1
                ? getDictionaryEntry(
                    "Common.Dropdown.SelectedSingular",
                  ).toLowerCase()
                : getDictionaryEntry(
                    "Common.Dropdown.SelectedPlural",
                  ).toLowerCase()
            }}</span
          >
        </span>
      </template>
      <span slot="noResult">{{
        getDictionaryEntry("Common.Dropdown.NoMatchingResults")
      }}</span>
      <span slot="noOptions">{{
        getDictionaryEntry("Common.Dropdown.NoOptions")
      }}</span>
      <template slot="option" slot-scope="{ option }">
        <div class="dropdown__option">
          <div
            class="dropdown__option-text"
            :class="{
              'dropdown__option-text--has-parent':
                showParentSpace && option.parentId,
              'dropdown__option-text--is-parent':
                showParentSpace && !option.parentId,
            }"
          >
            {{ option.label }}
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="dropdown__option-icon"
            viewBox="0 0 24 24"
          >
            <path d="M20 6 9 17l-5-5" />
          </svg>
        </div>
      </template>
    </Multiselect>
    <Error :message="errorMessage" :visible="hasError" />
  </div>
</template>

<script>
import isEqual from "lodash.isequal";
import Multiselect from "vue-multiselect";
import Error from "@/components/Error";
import { uuid } from "@/helpers/uuid";

// See https://vue-multiselect.js.org/ for API and examples.
// We've set some default props to ensure a decent basic behaviour).
// NB! The default value provided as v-model has to be "null" or "[]" in order to show the placeholder.

export default {
  name: "Dropdown",
  components: {
    Error,
    Multiselect,
  },
  inheritAttrs: false,
  props: {
    allowEmpty: {
      default: false,
      type: Boolean,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
    errorMessage: {
      default: "",
      type: String,
    },
    hasBorder: {
      default: true,
      type: Boolean,
    },
    hasError: {
      default: false,
      type: Boolean,
    },
    label: {
      default: "label",
      type: String,
    },
    labelText: {
      default: "",
      type: String,
    },
    multiple: {
      default: false,
      type: Boolean,
    },
    openDirection: {
      default: "",
      type: String,
    },
    optionHeight: {
      default: 42,
      type: Number,
    },
    options: {
      required: true,
      type: Array,
    },
    placeholder: {
      default: "",
      type: String,
    },
    trackBy: {
      default: "label",
      type: String,
    },
    value: {
      default: null,
      type: [Array, Number, Object, String],
    },
    showParentSpace: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      id: uuid(),
      internalValue: {},
    };
  },
  computed: {
    optionsWithAbilityToClear() {
      const { allowEmpty, getDictionaryEntry, multiple, options } = this;

      if (allowEmpty && multiple) {
        return [
          {
            label: getDictionaryEntry("Common.ClearAllSelections"),
            value: "-1",
          },
          ...options,
        ];
      }

      return this.options;
    },
  },
  watch: {
    internalValue(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        if (newValue === null) {
          this.$emit("input", null);
        } else if (Array.isArray(newValue)) {
          this.$emit(
            "input",
            newValue.length ? newValue.map(entry => entry.value) : [],
          );
        } else if (typeof newValue === "object") {
          this.$emit(
            "input",
            newValue.value === undefined ? null : newValue.value,
          );
        } else {
          this.$emit("input", newValue === undefined ? null : newValue);
        }
      }
    },
    options(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.updateInternalValue(this.value);
      }
    },
    value(newValue, oldValue) {
      if (!isEqual(newValue, oldValue)) {
        this.updateInternalValue(newValue);
      }
    },
  },
  created() {
    this.updateInternalValue(this.value);
  },
  methods: {
    handleOpen(event) {
      this.$emit("open", event);
      this.$refs.multiselect.pointer = -1;
    },
    updateInternalValue(value) {
      const { options } = this;

      if (!options || !options.length) {
        this.internalValue = value;
        return;
      }

      const isArray = Array.isArray(value);
      const isArrayOfObjects = isArray ? typeof value[0] === "object" : false;

      if (isArray) {
        this.internalValue = options.filter(option =>
          value.some(
            entry => (isArrayOfObjects ? entry.value : entry) === option.value,
          ),
        );
      } else {
        const matchingOption = options.find(option => option.value === value);

        this.internalValue = matchingOption || null;
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.dropdown {
  position: relative;
  text-transform: none;
  transition: padding 300ms ease;

  &--error {
    padding-bottom: var(--spacing-error-message);
  }

  &__label {
    cursor: pointer;
    display: inline-block;
    font-size: 0.875rem;
    font-weight: 700;
    margin-bottom: 0.5rem;

    &[required] {
      &::after {
        content: "*";
        margin-left: 0.125rem;
      }
    }
  }

  &__option {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &-icon {
      flex-grow: 0;
      flex-shrink: 0;
      fill: none;
      height: var(--size-basket-icon);
      opacity: 0;
      stroke-linecap: round;
      stroke-linejoin: round;
      stroke-width: 2;
      stroke: var(--color-text-primary);
      transition: opacity 300ms ease;
      width: var(--size-basket-icon);

      .multiselect__option--selected & {
        opacity: 1;
      }
    }

    &-text {
      margin-right: 1rem;
      &--has-parent {
        margin-left: 1rem;
      }
      &--is-parent {
        font-weight: bold;
      }
    }
  }
}

.multiselect {
  border-radius: 0.25rem;
  color: inherit;
  position: relative;
  z-index: 3;

  &--active {
    z-index: 98;
  }

  &--disabled {
    opacity: 1;
  }

  &-enter-active,
  &-leave-active {
    transition: opacity 300ms ease, transform 300ms ease-in-out;
  }

  &-enter,
  &-leave-to {
    opacity: 0;
    transform: translateY(1rem);
  }

  &,
  * {
    font-size: 0.875rem;
  }

  &__content {
    width: 100%;

    &-wrapper {
      border-color: var(--color-input-border-active) !important;
      border-bottom-left-radius: 0.125rem;
      border-bottom-right-radius: 0.125rem;

      .dropdown--borderless & {
        margin-top: 0.0625rem;
      }

      .external-product & {
        max-height: 15rem !important;
      }
    }
  }

  &__icon {
    fill: var(--color-dropdown-border-active);
    height: var(--size-dropdown-icon);
    margin-left: 0.2rem;
    margin-top: calc(var(--size-dropdown-icon) * -0.5);
    position: absolute;
    right: 1rem;
    top: 50%;
    transition: transform 300ms ease;
    width: var(--size-dropdown-icon);
    z-index: 1;

    .multiselect--active & {
      transform: rotate(180deg);
    }

    .multiselect--disabled & {
      display: none;
    }
  }

  &__input {
    border: none;
  }

  &__input,
  &__placeholder,
  &__single {
    font-size: 1rem;
    margin: 0;
    overflow: hidden;
    padding-left: 0 !important;
    padding-right: calc(var(--size-dropdown-icon) * 1.5) !important;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__input,
  &__single {
    background-color: transparent;
    line-height: normal;
    min-height: auto;
  }

  &__option {
    cursor: pointer;
    font-size: 0.875rem;
    height: auto;
    line-height: normal;
    min-height: auto;
    overflow: hidden;
    padding: var(--spacing-input-field);
    position: relative;
    text-overflow: ellipsis;
    transition: background-color 300ms ease;
    white-space: pre-wrap;

    &::after {
      align-items: center;
      background-color: transparent !important;
      bottom: 0;
      color: inherit !important;
      content: attr(data-select);
      display: flex;
      font-size: 1rem;
      line-height: normal;
      justify-content: center;
      opacity: 0;
      padding: 0;
      position: absolute;
      right: 0.5rem;
      top: 0;
      transition: opacity 300ms ease;
      width: 1rem;
    }

    &--highlight {
      background-color: #f9f9f9 !important;
      color: inherit !important;

      &::after {
        opacity: 1;
      }
    }

    &--selected {
      background-color: #efefef !important;
      font-weight: 700;
    }
  }

  &__placeholder {
    color: #757575;
    line-height: normal;
    padding-top: 0;
  }

  &__single {
    .multiselect--disabled & {
      background-color: var(--color-input-background-disabled) !important;
      border: none !important;
      border-radius: 0.25rem;
      cursor: text;
    }
  }

  &__tag {
    background-color: var(--color-input-border);
    border-radius: 0.25rem;
    color: var(--color-text-primary);
    margin: 0 0.4rem 0.4rem 0;
    padding: 0.25rem (1.375rem + 0.25rem) 0.25rem 0.25rem;

    &-icon {
      background-color: rgba(0, 0, 0, 0);
      border-radius: 0.25rem;
      line-height: 1.375rem;
      margin: 0;
      transition: background-color 300ms ease;
      width: 1.375rem;

      &:hover {
        background-color: rgba(0, 0, 0, 0.075);

        &::after {
          color: inherit;
          font-size: 0.875rem;
        }
      }

      &::after {
        color: inherit;
      }
    }

    span {
      display: block;
      max-width: 3rem;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__tags {
    align-items: center;
    background-color: #ffffff;
    border: 1px solid var(--color-dropdown-border);
    border-radius: 0.25rem;
    display: flex;
    font-size: 1rem;
    height: 100%;
    padding: var(--spacing-input-field);
    transition: border-color 300ms ease;
    width: 100%;

    .dropdown--borderless & {
      border: none !important;
    }

    .dropdown--error & {
      border-color: var(--color-error);
    }

    .multiselect--active & {
      border-color: var(--color-dropdown-border-active);
    }

    .multiselect--disabled & {
      background-color: var(--color-input-background-disabled) !important;
      border: none !important;
      cursor: text;
    }

    .multiselect:hover & {
      background-color: var(--color-input-background-active);
      border-color: var(--color-input-border-active);
      cursor: pointer;
    }
  }
}
</style>
