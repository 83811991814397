<template>
  <div
    class="breadcrumbs"
    :class="{ 'breadcrumbs--active': breadcrumbs.length > 1 }"
  >
    <Container class="breadcrumbs__container">
      <ol class="breadcrumbs__list">
        <li
          v-for="(breadcrumb, index) in breadcrumbs"
          :key="breadcrumb.title + index"
          class="breadcrumbs__item"
        >
          <router-link
            v-if="breadcrumb.path"
            class="breadcrumbs__link"
            :to="getBreadcrumbPath(breadcrumb.path)"
            >{{ getBreadcrumbTitle(breadcrumb.title) }}</router-link
          >
          <div v-else>{{ getBreadcrumbTitle(breadcrumb.title) }}</div>
        </li>
      </ol>
    </Container>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Container from "@/components/Container";

export default {
  name: "Breadcrumbs",
  components: {
    Container,
  },
  computed: {
    ...mapGetters("customers", [
      "nameOfCurrentCustomer",
      "nameOfCurrentCustomerRole",
    ]),
    ...mapGetters("customerService", [
      "nameOfCurrentCustomerServiceRepresentative",
    ]),
    ...mapGetters("externalProducts", ["nameOfCurrentExternalProduct"]),
    ...mapGetters("orders", ["currentOrderNumber"]),
    ...mapGetters("products", ["currentProductId", "nameOfCurrentProduct"]),
    ...mapGetters("purchaseLimitations", ["nameOfCurrentPurchaseLimitation"]),
    ...mapGetters("roleTemplates", [
      "currentRoleTemplateId",
      "nameOfCurrentRoleTemplate",
    ]),
    ...mapGetters("salespersons", ["nameOfCurrentSalesperson"]),
    ...mapGetters("systemadministrators", ["nameOfCurrentSystemadministrator"]),
    ...mapGetters("userGroups", ["nameOfCurrentUserGroup"]),
    ...mapGetters("users", ["currentUserId", "nameOfCurrentUser"]),
    ...mapGetters("wardrobes", ["nameOfCurrentWardrobe"]),
    breadcrumbs() {
      const { breadcrumbs } = this.$route.meta;

      if (!breadcrumbs) return [];
      return breadcrumbs;
    },
  },
  methods: {
    getBreadcrumbPath(path) {
      if (!path) return null;

      const { params } = path;

      if (!params) return path;

      for (const key in params) {
        if (Object.prototype.hasOwnProperty.call(params, key)) {
          params[key] = this.$route.params[key];
        }
      }

      return {
        ...path,
        params,
      };
    },
    getBreadcrumbTitle(title) {
      if (!title) return null;

      if (title.startsWith("_")) {
        return this.getDictionaryEntry(title.replace("_", ""));
      }

      if (title.includes("/")) {
        return this[title.split("/")[1]];
      }

      return title;
    },
  },
};
</script>

<style lang="scss" scoped>
.breadcrumbs {
  align-items: center;
  background-color: var(--color-body-background);
  display: flex;
  height: var(--size-breadcrumbs);
  max-height: 0;
  overflow: hidden;
  position: relative;
  transition: background-color 300ms ease,
    max-height 600ms cubic-bezier(0.25, 1, 0.5, 1);
  z-index: 0;

  &--active {
    max-height: var(--size-breadcrumbs);
  }

  &::after {
    background: linear-gradient(
      to left,
      rgba(250, 249, 248, 1) 25%,
      rgba(250, 249, 248, 0.75) 75%,
      rgba(250, 249, 248, 0) 100%
    );
    bottom: 0;
    content: "";
    position: absolute;
    right: 0;
    top: 0;
    width: var(--size-breadcrumbs-overlay);
    z-index: 1;
  }

  .orderReceipt-view &,
  .product-view &,
  .products-view &,
  .quickBuy-view & {
    background-color: var(--color-body-background-light);
  }

  &__container {
    -ms-overflow-style: none;
    -webkit-overflow-scrolling: touch;
    align-items: center;
    display: flex;
    overflow-x: scroll;
    scrollbar-width: none;
    white-space: nowrap;

    &::-webkit-scrollbar {
      background: transparent;
      height: 0;
      width: 0;
    }
  }

  &__item {
    font-size: 0.875rem;
    opacity: 0.75;

    &:not(:last-child) {
      padding-right: 3rem;
      position: relative;

      &::after {
        content: "\279D";
        font-size: 1rem;
        opacity: 0.75;
        position: absolute;
        top: 50%;
        right: 1rem;
        transform: translateY(-50%) translateY(-1px);
      }
    }

    &:last-child {
      opacity: 0.5;
      padding-right: calc(var(--size-breadcrumbs-overlay) + 1rem);
    }
  }

  &__link {
    color: inherit;
    text-decoration: none;
  }

  &__list {
    @include reset-list;

    align-items: center;
    display: flex;
    opacity: 0;
    padding: var(--spacing-container) 0;

    .breadcrumbs--active & {
      opacity: 1;
    }

    .navigation-sidebar & {
      padding: var(--spacing-container-with-navigation-sidebar) 0;
    }
  }
}
</style>
