<template>
  <transition name="fade">
    <div
      v-if="internalVisible"
      class="loader"
      :class="{ 'loader--large': large, 'loader--light': light }"
    >
      <div class="loader__spinner">
        <div class="loader__clipper loader__clipper--left">
          <div class="loader__circle"></div>
        </div>
        <div class="loader__patch">
          <div class="loader__patch-circle"></div>
        </div>
        <div class="loader__clipper loader__clipper--right">
          <div class="loader__circle"></div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: "Loader",
  props: {
    large: {
      default: false,
      type: Boolean,
    },
    light: {
      default: false,
      type: Boolean,
    },
    visible: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      internalVisible: false,
      interval: null,
    };
  },
  watch: {
    visible(newValue, oldValue) {
      if (newValue && !oldValue) {
        this.interval = window.setTimeout(() => {
          this.internalVisible = true;
        }, 200);
      } else if (!newValue) {
        window.clearTimeout(this.interval);
        this.internalVisible = false;
      }
    },
  },
  created() {
    this.internalVisible = this.visible;
  },
};
</script>

<style lang="scss" scoped>
.loader {
  animation: rotate 1568ms linear infinite;
  display: inline-block;
  height: var(--size-loader);
  left: 50%;
  margin: calc(var(--size-loader) * -0.5) 0 0 calc(var(--size-loader) * -0.5);
  position: absolute;
  top: 50%;
  transition: background-color 300ms ease;
  width: var(--size-loader);
  z-index: 4;

  .barcode-scanner & {
    z-index: 1;
  }

  .catalogues--step-5 & {
    left: auto;
    margin: 0 auto;
    position: relative !important;
    top: auto;
  }

  .dynamic-data > & {
    position: absolute;

    .catalogues & {
      $sidebar-width-wide: 43rem;

      @media (min-width: $sidebar-width-wide) {
        left: auto;
        right: calc((#{$sidebar-width-wide * 0.5}) - (var(--size-loader)));
      }
    }

    .catalogues &,
    .product-details &,
    .products & {
      position: fixed;
    }
  }

  .input--visible-label & {
    margin-top: calc(var(--size-loader) * -0.5 + 0.75rem);
  }

  &--large {
    height: var(--size-loader-large);
    margin: calc(var(--size-loader-large) * -0.5) 0 0
      calc(var(--size-loader-large) * -0.5);
    width: var(--size-loader-large);
  }

  &__circle {
    border-bottom-color: transparent !important;
    border-color: inherit;
    border-radius: 50%;
    border-style: solid;
    border-width: 0.1875rem;
    bottom: 0;
    height: 100%;
    position: absolute;
    right: 0;
    top: 0;
    width: 200%;

    .loader--large & {
      border-width: 0.25rem;
    }

    .loader__clipper--left & {
      animation: left-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      border-right-color: transparent !important;
      left: 0;
      transform: rotate(129deg);
    }

    .loader__clipper--right & {
      animation: right-spin 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
      border-left-color: transparent !important;
      left: -100%;
      transform: rotate(-129deg);
    }
  }

  &__clipper {
    border-color: inherit;
    display: inline-block;
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 50%;

    &--left {
      float: left;
    }

    &--right {
      float: right;
    }
  }

  &__patch {
    border-color: inherit;
    height: 100%;
    left: 45%;
    overflow: hidden;
    position: absolute;
    top: 0;
    width: 10%;

    &-circle {
      border-radius: 50%;
      left: -450%;
      width: 1000%;
    }
  }

  &__spinner {
    animation: fill-unfill-rotate 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite
      both;
    border-color: var(--color-loader);
    height: 100%;
    position: absolute;
    width: 100%;

    .loader--light & {
      border-color: var(--color-loader-light);
    }
  }
}

@keyframes fill-unfill-rotate {
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
}

@keyframes left-spin {
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
}

@keyframes right-spin {
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
}

@keyframes rotate {
  to {
    transform: rotate(360deg);
  }
}
</style>
