export function getYouTubeId(url) {
  if (!url) return null;

  if (/youtu\.?be/.test(url)) {
    const linkPatterns = [
      /youtu\.be\/([^#\&\?]{11})/, // youtu.be/<id>
      /\?v=([^#\&\?]{11})/, // ?v=<id>
      /\&v=([^#\&\?]{11})/, // &v=<id>
      /embed\/([^#\&\?]{11})/, // embed/<id>
      /\/v\/([^#\&\?]{11})/, // /v/<id>
    ];

    for (let i = 0; i < linkPatterns.length; ++i) {
      if (linkPatterns[i].test(url)) {
        return linkPatterns[i].exec(url)[1];
      }
    }
  }

  return null;
}
