var render = function render(){var _vm=this,_c=_vm._self._c;return (
    _vm.substitutionVariantsForCurrentVariant &&
      _vm.substitutionVariantsForCurrentVariant.length
  )?_c('div',{staticClass:"substitutes"},[_c('Stack',[_c('p',{staticClass:"substitutes__body-text"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Product.Substitutes.BodyText"))+" ")]),_c('h3',{staticClass:"substitutes__title"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Product.Substitutes.Title"))+" ")])]),_c('Stack',[_c('Flicking',{staticClass:"slider slider--products slider--short",attrs:{"options":_vm.options,"plugins":_vm.plugins}},[_vm._l((_vm.substitutionVariantsForCurrentVariant),function(variant){return _c('div',{key:variant.primary.variantId,staticClass:"substitutes__item slide slide--small"},[_c('article',[_c('Stack',[_c('div',{staticClass:"block__product-image-container"},[_c('ProductImage',{staticClass:"block__product-image",attrs:{"variant":variant.primary}})],1)]),_c('Stack',{staticClass:"substitutes__item-text"},[(_vm.isUserDealer)?_c('Stack',{attrs:{"quarter":true}},[_c('h2',{staticClass:"substitutes__variant-id"},[_vm._v(" "+_vm._s(variant.primary.productId)+" ")])]):_vm._e(),_c('Stack',{attrs:{"quarter":true}},[_c('h1',{staticClass:"slide__title"},[_vm._v(" "+_vm._s(variant.primary.productName)+" - "+_vm._s(variant.primary.variantName)+" ")])]),(variant.primary.minPrice)?_c('Stack',{attrs:{"quarter":true}},[_c('Price',{attrs:{"price":variant.primary.minPrice,"show-from-label":true}})],1):_vm._e()],1),_c('div',{staticClass:"substitutes__colors"},[_c('h3',{staticClass:"substitutes__colors-title"},[_vm._v(" "+_vm._s(_vm.getDictionaryEntry("Product.Substitutes.ColorsTitle"))+" ")]),(variant.secondary && variant.secondary.length)?_c('ol',{staticClass:"substitutes__colors-list"},[_vm._l((variant.secondary.slice(0, 5)),function(secondaryVariant){return _c('li',{key:secondaryVariant.variantId,staticClass:"substitutes__color"},[_c('ProductImage',{staticClass:"substitutes__color-image",attrs:{"variant":secondaryVariant}}),_c('router-link',{staticClass:"substitutes__link",attrs:{"to":{
                    query: {
                      mode: 'details',
                      productId: secondaryVariant.productId,
                      variantId: secondaryVariant.variantId,
                    },
                  },"aria-label":`${secondaryVariant.productName} - ${secondaryVariant.variantName}`}})],1)}),(variant.secondary.length > 5)?_c('li',{staticClass:"substitutes__color"},[_vm._v(" +"+_vm._s(_vm.getAmountOfSurplusVariants(variant.secondary))+" "),_c('router-link',{staticClass:"substitutes__link",attrs:{"to":{
                    query: {
                      mode: 'details',
                      productId: variant.primary.productId,
                      variantId: variant.primary.variantId,
                    },
                  },"aria-label":`${variant.primary.productName} - ${variant.primary.variantName}`}})],1):_vm._e()],2):_vm._e()])],1),_c('router-link',{staticClass:"substitutes__link",attrs:{"to":{
            query: {
              mode: 'details',
              productId: variant.primary.productId,
              variantId: variant.primary.variantId,
            },
          },"aria-label":`${variant.primary.productName} - ${variant.primary.variantName}`}})],1)}),_c('button',{staticClass:"slider__navigation slider__navigation--prev",attrs:{"slot":"viewport","aria-label":"previous"},slot:"viewport"},[_c('svg',{staticClass:"slider__navigation-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M19 12H5M12 19l-7-7 7-7"}})])]),_c('div',{staticClass:"substitutes__overlay-container",attrs:{"slot":"viewport"},slot:"viewport"},[_c('button',{staticClass:"slider__navigation slider__navigation--next",attrs:{"aria-label":"next"}},[_c('svg',{staticClass:"slider__navigation-icon",attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 24 24"}},[_c('defs'),_c('path',{attrs:{"d":"M5 12h14M12 5l7 7-7 7"}})])]),_c('div',{staticClass:"substitutes__overlay"})])],2)],1)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }