<template>
  <div
    v-if="inventory"
    class="stock"
    :class="{ 'stock--indicator-only': indicatorOnly }"
  >
    <div v-if="!ignoreShowStock && inventory.showStock" class="stock__amount">
      <span v-if="inventory.isExternalProduct && inventory.stock === null"
        >+100 {{ getDictionaryEntry("Product.InStock") }}</span
      >
      <span v-else-if="inventory.stock === null"></span>
      <span v-else
        >{{ inventory.stock }} {{ getDictionaryEntry("Product.InStock") }}</span
      >
    </div>
    <div
      v-else
      class="stock__indicator"
      :class="`stock__indicator--${status}`"
    ></div>
  </div>
</template>

<script>
import { stockLevels } from "@/helpers/stockLevels";

export default {
  name: "StockIndicator",
  props: {
    ignoreShowStock: {
      default: false,
      type: Boolean,
    },
    indicatorOnly: {
      default: false,
      type: Boolean,
    },
    inventory: {
      required: true,
      type: Object,
    },
  },
  data() {
    return {
      status: "",
      text: "",
    };
  },
  watch: {
    inventory(newValue) {
      if (newValue) {
        this.updateStatusAndText(newValue);
      }
    },
  },
  created() {
    if (!this.inventory) return;

    this.updateStatusAndText(this.inventory);
  },
  methods: {
    updateStatusAndText({ level, stockLevel }) {
      switch (level) {
        case stockLevels.GREY:
        case stockLevels.ORANGE:
        case stockLevels.RED:
          this.status = level;
          break;
        default:
          this.status = stockLevels.GREEN;
          break;
      }

      switch (stockLevel) {
        case stockLevels.GREY:
          this.text = this.getDictionaryEntry("Product.StockDescriptionGrey");
          break;
        case stockLevels.RED:
          this.text = this.getDictionaryEntry("Product.StockDescriptionRed");
          break;
        case stockLevels.ORANGE:
          this.text = this.getDictionaryEntry("Product.StockDescriptionYellow");
          break;
        default:
          this.text = this.getDictionaryEntry("Product.StockDescriptionGreen");
          break;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.stock {
  align-items: center;
  display: flex;
  justify-content: space-between;

  &__amount {
    flex: 0 0 100%;
    font-size: 0.75rem;
    margin: 0.35rem 0;
    text-align: center;
  }

  &__indicator {
    border-radius: 50%;
    flex: 0 0 auto;
    height: var(--size-stock-indicator);
    width: var(--size-stock-indicator);

    &--green {
      background-color: var(--color-stock-indicator-green);
    }

    &--grey {
      background-color: var(--color-stock-indicator-grey);
    }

    &--orange {
      background-color: var(--color-stock-indicator-orange);
    }

    &--red {
      background-color: var(--color-stock-indicator-red);
    }
  }

  &__text {
    flex: 1 1 auto;
    font-size: 0.95rem;
  }
}
</style>
