export default function getFlattenUserGroups(parentGroups) {
  if (!parentGroups) return [];

  let flattened = [];

  function addGroupAndChildren(group) {
    // Add the parent group first
    flattened.push({
      id: group.id,
      name: group.name,
      parentId: group.parentId,
    });

    // Recursively add each child group immediately after the parent
    group.childUserGroups.forEach(childGroup =>
      addGroupAndChildren(childGroup),
    );
  }

  parentGroups.forEach(group => addGroupAndChildren(group));
  return flattened;
}
