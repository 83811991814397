<template>
  <Container v-if="blockData" :small="true">
    <div class="block block--headline-and-text">
      <Heading
        v-if="blockData.headline"
        :tag="blockData.headlineTag"
        class="block__title"
      >
        {{ blockData.headline }}
      </Heading>
      <div v-if="body" class="block__rich-text" v-html="body"></div>
    </div>
  </Container>
</template>

<script>
import Container from "@/components/Container";
import Heading from "@/components/Heading";

export default {
  name: "HeadlineAndTextGridElement",
  components: {
    Container,
    Heading,
  },
  props: {
    blockData: {
      required: true,
      type: Object,
    },
  },
  computed: {
    body() {
      const { $route, blockData } = this;

      if ($route && blockData && blockData.body) {
        const parser = new DOMParser();
        const document = parser.parseFromString(blockData.body, "text/html");
        const links = document.querySelectorAll("a");

        [...links].forEach(link => {
          const href = link.getAttribute("href");

          if (href.startsWith("/")) {
            link.setAttribute("href", `#${href}`);
          }
        });

        return document.documentElement.innerHTML;
      }

      return blockData ? blockData.body : "";
    },
  },
};
</script>
