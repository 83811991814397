import axios from "axios";
import { endpoints } from "@/helpers/endpoints";
import { errorAlert } from "@/helpers/errorAlert";
import { statuses } from "@/helpers/statuses";

const state = () => ({
  currentRoleTemplate: null,
  currentRoleTemplateId: null,
  isModalForCreatingRoleTemplateOpen: false,
  roleTemplates: [],
  selectedRoleTemplates: [],
  status: "",
});

const getters = {
  currentRoleTemplate: state => state.currentRoleTemplate,
  currentRoleTemplateId: state => state.currentRoleTemplateId,
  isModalForCreatingRoleTemplateOpen: state =>
    state.isModalForCreatingRoleTemplateOpen,
  nameOfCurrentRoleTemplate: state => {
    const { currentRoleTemplate } = state;
    if (!currentRoleTemplate) return null;

    return currentRoleTemplate.name ? currentRoleTemplate.name : null;
  },
  roleTemplates: state => state.roleTemplates,
  selectedRoleTemplates: state => state.selectedRoleTemplates,
  statusOfRoleTemplates: state => state.status,
};

const actions = {
  CLOSE_MODAL_FOR_CREATING_ROLE_TEMPLATE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_ROLE_TEMPLATE", { value: false });
  },
  CREATE_ROLE_TEMPLATE({ commit, dispatch }, payload) {
    commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/roleTemplate`, {
          name: payload.name,
          roleCompanyType: payload.type,
        })
        .then(({ data }) => {
          commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.SUCCESS });
          dispatch("CLOSE_MODAL_FOR_CREATING_ROLE_TEMPLATE");
          resolve(data);
        })
        .catch(error => {
          commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  DELETE_SELECTED_ROLE_TEMPLATES({ commit, state }) {
    commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.LOADING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/roleTemplate/delete-multiple`, {
          identifiers: state.selectedRoleTemplates.map(
            roleTemplate => roleTemplate.id,
          ),
        })
        .then(response => {
          commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.SUCCESS });
          commit("REMOVE_SELECTED_ROLE_TEMPLATES");
          resolve(response);
        })
        .catch(error => {
          commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject(error);
        });
    });
  },
  GET_ROLE_TEMPLATE({ commit, dispatch }, payload) {
    commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.LOADING });

    let roleTemplate;

    return axios
      .get(`${endpoints.ECOMMERCE}/roleTemplate/${payload.id}`)
      .then(({ data }) => {
        roleTemplate = data;

        return axios.get(
          `${endpoints.ECOMMERCE}/permission/v2/${data.roleCompanyType}`,
        );
      })
      .then(({ data }) => {
        commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.SUCCESS });

        roleTemplate.availablePermissions = data;

        commit("SET_CURRENT_ROLE_TEMPLATE", { roleTemplate });
        commit("SET_ROLE_TEMPLATE", { roleTemplate });
        dispatch("CLOSE_MODAL_FOR_CREATING_ROLE_TEMPLATE");
      })
      .catch(error => {
        commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ROLE_TEMPLATES({ commit }) {
    commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/roleTemplate`)
      .then(({ data }) => {
        commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.SUCCESS });
        commit("SET_ROLE_TEMPLATES", { roleTemplates: data });
      })
      .catch(error => {
        commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  GET_ROLE_TEMPLATES_BY_TYPE({ commit }, payload) {
    commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.LOADING });

    return axios
      .get(`${endpoints.ECOMMERCE}/roleTemplate/list/${payload.type}`)
      .then(({ data }) => {
        commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.SUCCESS });
        commit("SET_ROLE_TEMPLATES", { roleTemplates: data });
      })
      .catch(error => {
        commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.FAILURE });
        errorAlert(error);
      });
  },
  OPEN_MODAL_FOR_CREATING_ROLE_TEMPLATE({ commit }) {
    commit("SET_STATE_OF_MODAL_FOR_CREATING_ROLE_TEMPLATE", { value: true });
  },
  TOGGLE_ROLE_TEMPLATE({ commit }, payload) {
    commit("SET_SELECTED_ROLE_TEMPLATE", payload);
  },
  TOGGLE_ROLE_TEMPLATES({ commit }, payload) {
    commit("SET_SELECTED_ROLE_TEMPLATES", payload);
  },
  UPDATE_CURRENT_ROLE_TEMPLATE_ID({ commit }, payload) {
    commit("SET_CURRENT_ROLE_TEMPLATE_ID", payload);
  },
  UPDATE_ROLE_TEMPLATE({ commit, getters }, payload) {
    const { id, name, permissions } = payload.roleTemplate;
    const { availablePermissions } = getters.currentRoleTemplate;

    commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.SAVING });

    return new Promise((resolve, reject) => {
      axios
        .post(`${endpoints.ECOMMERCE}/roleTemplate/${id}`, {
          name,
          permissions,
        })
        .then(({ data }) => {
          const roleTemplate = { ...data, availablePermissions };

          commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.SUCCESS });
          commit("SET_CURRENT_ROLE_TEMPLATE", { roleTemplate });
          commit("SET_ROLE_TEMPLATE", { roleTemplate });
          resolve(roleTemplate);
        })
        .catch(error => {
          commit("SET_ROLE_TEMPLATES_STATUS", { status: statuses.FAILURE });
          errorAlert(error);
          reject();
        });
    });
  },
};

const mutations = {
  REMOVE_SELECTED_ROLE_TEMPLATES(state) {
    state.roleTemplates = state.roleTemplates.filter(
      roleTemplate =>
        !state.selectedRoleTemplates.some(
          selectedRoleTemplate => selectedRoleTemplate.id === roleTemplate.id,
        ),
    );

    state.selectedRoleTemplates = [];
  },
  SET_CURRENT_ROLE_TEMPLATE(state, payload) {
    state.currentRoleTemplate = payload.roleTemplate;
  },
  SET_CURRENT_ROLE_TEMPLATE_ID(state, payload) {
    state.currentRoleTemplateId = parseInt(payload.id);
  },
  SET_ROLE_TEMPLATE(state, payload) {
    const { roleTemplate } = payload;

    const roleTemplateExists = state.roleTemplates.find(
      currentRoleTemplate => currentRoleTemplate.id === roleTemplate.id,
    );

    if (roleTemplateExists) {
      const roleTemplates = [...state.roleTemplates];
      const indexOfMatchingRoleTemplate = roleTemplates.findIndex(
        currentRoleTemplate => currentRoleTemplate.id === roleTemplate.id,
      );

      roleTemplates[indexOfMatchingRoleTemplate] = roleTemplate;

      state.roleTemplates = roleTemplates;
    } else {
      state.roleTemplates.push(roleTemplate);
    }
  },
  SET_ROLE_TEMPLATES(state, payload) {
    state.roleTemplates = payload.roleTemplates;
  },
  SET_ROLE_TEMPLATES_STATUS(state, payload) {
    state.status = payload.status;
  },
  SET_SELECTED_ROLE_TEMPLATE(state, payload) {
    const { row: roleTemplate, value } = payload;

    if (value) {
      if (
        state.selectedRoleTemplates.some(entry => entry.id === roleTemplate.id)
      ) {
        return;
      }

      state.selectedRoleTemplates.push(roleTemplate);
    } else {
      state.selectedRoleTemplates = state.selectedRoleTemplates.filter(
        selectedRoleTemplate => selectedRoleTemplate.id !== roleTemplate.id,
      );
    }
  },
  SET_SELECTED_ROLE_TEMPLATES(state, payload) {
    const { rows: roleTemplates, value } = payload;

    if (value) {
      const identifiers = new Set(
        state.selectedRoleTemplates.map(
          selectedRoleTemplate => selectedRoleTemplate.id,
        ),
      );

      state.selectedRoleTemplates = [
        ...state.selectedRoleTemplates,
        ...roleTemplates
          .filter(product => !product.disableSelection)
          .filter(product => !identifiers.has(product.id)),
      ];
    } else {
      state.selectedRoleTemplates = state.selectedRoleTemplates.filter(
        selectedRoleTemplate =>
          !roleTemplates.some(entry => entry.id === selectedRoleTemplate.id),
      );
    }
  },
  SET_STATE_OF_MODAL_FOR_CREATING_ROLE_TEMPLATE(state, payload) {
    state.isModalForCreatingRoleTemplateOpen = payload.value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
