export const orderOfSizes = [
  "XXXXXXXS",
  "7XS",
  "XXXXXXS/XXXXXXXS",
  "6XS/7XS",
  "XXXXXXS",
  "6XS",
  "XXXXXS/XXXXXXS",
  "5XS/6XS",
  "5XS",
  "XXXXXS",
  "XXXXS/XXXXXS",
  "4XS/5XS",
  "XXXXS",
  "4XS",
  "XXXS/XXXXS",
  "3XS/4XS",
  "XXXS",
  "3XS",
  "XXS/XXXS",
  "2XS/3XS",
  "XXS",
  "2XS",
  "XS",
  "XS/S",
  "S",
  "S/M",
  "M",
  "M/L",
  "L",
  "L/XL",
  "XL",
  "XL/2XL",
  "XL/XXL",
  "2XL",
  "XXL",
  "2XL/3XL",
  "XXL/XXXL",
  "3XL",
  "XXXL",
  "3XL/4XL",
  "XXXL/XXXXL",
  "4XL",
  "XXXXL",
  "4XL/5XL",
  "XXXXL/XXXXXL",
  "5XL",
  "XXXXXL",
  "5XL/6XL",
  "XXXXXL/XXXXXXL",
  "6XL",
  "XXXXXXL",
  "6XL/7XL",
  "XXXXXXL/XXXXXXXL",
  "7XL",
  "XXXXXXXL",
];
